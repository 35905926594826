import * as browserCheck from "./browserCheck.js"



//import "core-js/shim"; // included < Stage 4 proposals
//import "regenerator-runtime/runtime";
import "promise-polyfill/src/polyfill";
import "whatwg-fetch";
import "./main.scss";
import "./Grid/Grid.scss";
import mapStyles from "./PlugMap/sass/map_min.scss";

var version = require("../package.json").version;

import { Elm } from "./PAM.elm";
import Core from "./PlugMap/Core.js";
import Search from "./PlugMap/Plugins/Search/index";
import Themes from "./PlugMap/Plugins/Themes/index";
import Drawing from "./PlugMap/Plugins/Drawing/index";
import ColorUnderPointer from "./PlugMap/Plugins/ColorUnderPointer/index";
import Identify from "./PlugMap/Plugins/Identify/index";

import registerServiceWorker from "./registerServiceWorker";
import mapConfig from "./mapConfig";
import referenceConfig from "./referenceConfig";

require("font-awesome-webpack-4");

import * as model from "./staticmodel";



var elm = {};

function init(settings) {
  mapConfig.layers = mapConfig.layers;

  //Here we are spitting out the config to the console to get the data migrated
  // console.log(JSON.stringify(mapConfig));
  // console.log(JSON.stringify(referenceConfig.fpConf));
  var flags = {
    version: version,
    settings: settings,
    size: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    browser: browserCheck.describeBrowser()
  }


  elm = Elm.PAM.init({
    node: document.getElementById("root"),
    flags: flags
  });

  safeSubscribe(elm, "preventLeave", preventLeave);
  safeSubscribe(elm, "allowLeave", allowLeave);
  
  safeSubscribe(elm, "logErrorCmd", errorWillRobinson);
  safeSubscribe(elm, "triggerAnimationCmd", triggerAnimation);
  safeSubscribe(elm, "renderMapCmd", renderMap);
  safeSubscribe(elm, "bindToAuthFrame", authBinding);
  safeSubscribe(elm, "openAuth", openAuth);

  window.app = elm;
}


function initError(error) {
  let errorDiv = document.createElement("div");
  errorDiv.innerHTML =
    "There was an error processing your request. Please try again.";
  let contentDiv = document.createElement("div");
  contentDiv.innerHTML = error.message;

  document.getElementById("root").append(errorDiv);
  document.getElementById("root").append(contentDiv);
}

function safeSubscribe(elm, port, fun) {
  if (elm) {
    if (elm.ports) {
      if (elm.ports[port]) {
        return elm.ports[port].subscribe(fun);
      } else {
        console.warn(
          "Port '" + port + "' not found. Has the code been removed?"
        );
      }
    }
  }
}

function safeSend(elm, port, data) {
  if (elm) {
    if (elm.ports) {
      if (elm.ports[port]) {
        return elm.ports[port].send(data);
      } else {
        console.warn(
          "Port '" + port + "' not found. Has the code been removed?"
        );
      }
    }
  }
}

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

function triggerAnimation(name) {
  requestAnimationFrame(() => {
    safeSend(elm, "triggerAnimationSub", name);
  });
}

function errorWillRobinson(error) {
  console.error(error);
}

function renderMap(options) {
  
    //TODO: We cant handle multiple maps...
    if (window.famMap) window.famMap.destruct();

    const core = new Core("map");
    core.init({}, window.app, options);

    core.register([
      [Themes, {}],
      [Drawing, {}],
      [Search, {}],
      // [Upload, {}],
      // [FeaturePicker, {}],
      // [Extent, {}]
      [ColorUnderPointer, {}],
      [Identify, {}]
    ]);

    window.famMap = core;
}



function preventLeave() {
  
  window.onbeforeunload = function() {
    //Uncomment if we actually need to show the inapp modal
    //safeSend(elm, 'leaveAttempted', null);
    return true;
  };
}

function allowLeave() {
  window.onbeforeunload = null;
}

registerServiceWorker();

/* 
  oAuth things (Hey move these!)
*/


function openAuth(url) {
  if(window.AuthWindow)
    window.authWindow.close();

  window.authWindow = window.open(url);
}

window.addEventListener("message", receiveMessage, false);

function receiveMessage(event) {
  if (event.data && event.data.source == "oAuth" && event.data.data) {
    switch (event.data.data.status) {
      case "Code Received":
        safeSend(elm, "oAuthCodeReceived", event.data.data.code);
        if(window.AuthWindow)
          window.authWindow.close();
        break;
      default:
        //We shouldnt get here ...
        debugger;
    }
  }
}





function authBinding(val) {

}

//Polyfill from MDN
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null && nextSource !== undefined) { 
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeDeep(target, source) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: source[key] });
        else
          output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}
let appState = "";
window
  .fetch("/appsettings.json")
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    if(myJson.development)
    {
      window
      .fetch("/appsettings.override.json")
      .then(function(resp) {
        return resp.json();
      })
      .then(function(overrideJson) {
        var merged = mergeDeep(myJson, overrideJson); //TODO: This wont work in ie11
        init(merged);
        appState = merged.appCofig.state;
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = "https://timmons-branding.s3.amazonaws.com/Utah/favicon.ico";
        if (appState != undefined && appState != ""){    
          link.href = "https://timmons-branding.s3.amazonaws.com/"+appState+"/favicon.ico";
        }     
        document.getElementsByTagName('head')[0].appendChild(link);
      })
      .catch(function(error) {
        init(myJson);
      });
    }
    else
    {
      init(myJson);
        appState = myJson.appCofig.state;
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = "https://timmons-branding.s3.amazonaws.com/Utah/favicon.ico";
        if (appState != undefined && appState != ""){         
          link.href = "https://timmons-branding.s3.amazonaws.com/"+appState+"/favicon.ico";
        }     
        document.getElementsByTagName('head')[0].appendChild(link);
    }
  })
  .catch(function(error) {
    initError(error);
  });
