(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.iT.cT === region.e0.cT)
	{
		return 'on line ' + region.iT.cT;
	}
	return 'on lines ' + region.iT.cT + ' through ' + region.e0.cT;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lj,
		impl.nP,
		impl.nn,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		ee: func(record.ee),
		iV: record.iV,
		ih: record.ih
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.ee;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.iV;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.ih) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lj,
		impl.nP,
		impl.nn,
		function(sendToApp, initialModel) {
			var view = impl.f0;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lj,
		impl.nP,
		impl.nn,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.fP && impl.fP(sendToApp)
			var view = impl.f0;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.j$);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.F) && (_VirtualDom_doc.title = title = doc.F);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.mk;
	var onUrlRequest = impl.ml;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		fP: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.mE === next.mE
							&& curr.cO === next.cO
							&& curr.mx.a === next.mx.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		lj: function(flags)
		{
			return A3(impl.lj, flags, _Browser_getUrl(), key);
		},
		f0: impl.f0,
		nP: impl.nP,
		nn: impl.nn
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { k4: 'hidden', ke: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { k4: 'mozHidden', ke: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { k4: 'msHidden', ke: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { k4: 'webkitHidden', ke: 'webkitvisibilitychange' }
		: { k4: 'hidden', ke: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		iF: _Browser_getScene(),
		jk: {
			dc: _Browser_window.pageXOffset,
			dd: _Browser_window.pageYOffset,
			eJ: _Browser_doc.documentElement.clientWidth,
			fb: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		eJ: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		fb: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			iF: {
				eJ: node.scrollWidth,
				fb: node.scrollHeight
			},
			jk: {
				dc: node.scrollLeft,
				dd: node.scrollTop,
				eJ: node.clientWidth,
				fb: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			iF: _Browser_getScene(),
			jk: {
				dc: x,
				dd: y,
				eJ: _Browser_doc.documentElement.clientWidth,
				fb: _Browser_doc.documentElement.clientHeight
			},
			bY: {
				dc: x + rect.left,
				dd: y + rect.top,
				eJ: rect.width,
				fb: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.kP.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.kP.b, xhr)); });
		$elm$core$Maybe$isJust(request.nK) && _Http_track(router, xhr, request.nK.a);

		try {
			xhr.open(request.lM, request.c8, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.c8));
		}

		_Http_configureRequest(xhr, request);

		request.j$.a && xhr.setRequestHeader('Content-Type', request.j$.a);
		xhr.send(request.j$.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.hk; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.nF.a || 0;
	xhr.responseType = request.kP.d;
	xhr.withCredentials = request.jL;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		c8: xhr.responseURL,
		ng: xhr.status,
		nh: xhr.statusText,
		hk: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			m2: event.loaded,
			aD: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			mK: event.loaded,
			aD: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.lS) { flags += 'm'; }
	if (options.kd) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $author$project$PAM$Message$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Message$UrlChanged = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.A) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.E),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.E);
		} else {
			var treeLen = builder.A * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.H) : builder.H;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.A);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.E) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.E);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{H: nodeList, A: (len / $elm$core$Array$branchFactor) | 0, E: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {e8: fragment, cO: host, cU: path, mx: port_, mE: protocol, $9: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$PAM$AppFailed = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$AppLoaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$UI$Basic$Closed = 1;
var $author$project$PAM$User$LoggedOut = {$: 0};
var $author$project$PAM$Modal$NoModal = {$: 0};
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$PAM$UI$Basic$Open = 0;
var $author$project$PAM$Message$ReceiveDate = function (a) {
	return {$: 74, a: a};
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$PAM$Message$NoOp = {$: 0};
var $author$project$PAM$Message$OAuthMsg = function (a) {
	return {$: 59, a: a};
};
var $author$project$PAM$Message$UserAuthorized = {$: 60};
var $author$project$InPageOAuth$translator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			return td.h0;
		}
	});
var $author$project$PAM$authTranslator = $author$project$InPageOAuth$translator(
	{l$: $author$project$PAM$Message$NoOp, h0: $author$project$PAM$Message$UserAuthorized, el: $author$project$PAM$Message$OAuthMsg});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Animation$Model$Property = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Animation$Model$Spring = function (a) {
	return {$: 0, a: a};
};
var $author$project$Animation$initMotion = F2(
	function (position, unit) {
		return {
			b3: $author$project$Animation$Model$Spring(
				{gR: 26, iU: 170}),
			lm: $elm$core$Maybe$Nothing,
			my: position,
			np: position,
			nO: unit,
			nT: 0
		};
	});
var $author$project$Animation$length = F3(
	function (name, val, unit) {
		return A2(
			$author$project$Animation$Model$Property,
			name,
			A2($author$project$Animation$initMotion, val, unit));
	});
var $author$project$Animation$lengthUnitName = function (unit) {
	switch (unit) {
		case 0:
			return '';
		case 1:
			return 'px';
		case 2:
			return '%';
		case 3:
			return 'rem';
		case 4:
			return 'em';
		case 5:
			return 'ex';
		case 6:
			return 'ch';
		case 7:
			return 'vh';
		case 8:
			return 'vw';
		case 9:
			return 'vmin';
		case 10:
			return 'vmax';
		case 11:
			return 'mm';
		case 12:
			return 'cm';
		case 13:
			return 'in';
		case 14:
			return 'pt';
		default:
			return 'pc';
	}
};
var $author$project$Animation$bottom = function (_v0) {
	var val = _v0.a;
	var len = _v0.b;
	return A3(
		$author$project$Animation$length,
		'bottom',
		val,
		$author$project$Animation$lengthUnitName(len));
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $author$project$Animation$Length = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Animation$Px = 1;
var $author$project$Animation$px = function (myPx) {
	return A2($author$project$Animation$Length, myPx, 1);
};
var $author$project$PAM$UI$Basic$bottomDrawerStates = function (dims) {
	var bdHeight = dims.j7 - dims.j6;
	return {
		dA: _List_fromArray(
			[
				$author$project$Animation$bottom(
				$author$project$Animation$px(-bdHeight))
			]),
		em: _List_fromArray(
			[
				$author$project$Animation$bottom(
				$author$project$Animation$px(0.0))
			])
	};
};
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$round = _Basics_round;
var $author$project$PAM$UI$Basic$responsive = F3(
	function (a, _v0, _v1) {
		var aMin = _v0.a;
		var aMax = _v0.b;
		var bMin = _v1.a;
		var bMax = _v1.b;
		if (_Utils_cmp(a, aMin) < 1) {
			return bMin;
		} else {
			if (_Utils_cmp(a, aMax) > -1) {
				return bMax;
			} else {
				var deltaA = $elm$core$Basics$round((a - aMin) / (aMax - aMin));
				return (deltaA * (bMax - bMin)) + bMin;
			}
		}
	});
var $author$project$PAM$UI$Basic$adjustOnHeight = F3(
	function (size, device, range) {
		return A3(
			$author$project$PAM$UI$Basic$responsive,
			size.fb,
			_Utils_Tuple2(600, 1200),
			range);
	});
var $author$project$PAM$UI$Basic$calculatePanelDimensions = F3(
	function (windowSize, device, adjustedHeight) {
		var calc = A2($author$project$PAM$UI$Basic$adjustOnHeight, windowSize, device);
		var headerAndFooterHeight = calc(
			_Utils_Tuple2(32, 46));
		var mainHeaderHeight = calc(
			_Utils_Tuple2(50, 60));
		var subHeaderHeight = calc(
			_Utils_Tuple2(30, 40));
		var leftSidebarBodyHeight = windowSize.fb - ((mainHeaderHeight + subHeaderHeight) + (headerAndFooterHeight * adjustedHeight));
		var bottomDrawerHeight = calc(
			_Utils_Tuple2(215, 316));
		return {
			gu: calc(
				_Utils_Tuple2(32, 40)),
			j6: headerAndFooterHeight,
			j7: bottomDrawerHeight,
			fl: leftSidebarBodyHeight,
			hG: headerAndFooterHeight,
			lE: headerAndFooterHeight,
			hH: calc(
				_Utils_Tuple2(30, 40)),
			lG: calc(
				_Utils_Tuple2(390, 480)),
			hO: mainHeaderHeight,
			nl: subHeaderHeight
		};
	});
var $mdgriffith$elm_ui$Element$BigDesktop = 3;
var $mdgriffith$elm_ui$Element$Desktop = 2;
var $mdgriffith$elm_ui$Element$Landscape = 1;
var $mdgriffith$elm_ui$Element$Phone = 0;
var $mdgriffith$elm_ui$Element$Portrait = 0;
var $mdgriffith$elm_ui$Element$Tablet = 1;
var $mdgriffith$elm_ui$Element$classifyDevice = function (window) {
	return {
		gH: (window.eJ <= 600) ? 0 : (((window.eJ > 600) && (window.eJ <= 1200)) ? 1 : (((window.eJ > 1200) && (window.eJ <= 1800)) ? 2 : 3)),
		h5: (_Utils_cmp(window.eJ, window.fb) < 0) ? 0 : 1
	};
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$InPageOAuth$NotStarted = {$: 0};
var $author$project$InPageOAuth$defaultModel = {bR: $elm$core$Maybe$Nothing, dC: $elm$core$Maybe$Nothing, bn: $elm$core$Maybe$Nothing, cj: $author$project$InPageOAuth$NotStarted, aq: $author$project$PAM$User$LoggedOut};
var $author$project$PAM$Header$defaultModel = {c9: 1};
var $author$project$PAM$Map$None = 0;
var $author$project$PAM$Map$Uninitialized = 0;
var $author$project$PlugMap$Core$Unknown = {$: 0};
var $author$project$PlugMap$Core$defaultModel = {dK: $author$project$PlugMap$Core$Unknown, ea: $elm$core$Maybe$Nothing, er: _List_Nil};
var $author$project$PlugMap$Plugins$ColorUnderPointer$Model = {};
var $author$project$PlugMap$Plugins$ColorUnderPointer$defaultModel = $author$project$PlugMap$Plugins$ColorUnderPointer$Model;
var $author$project$PlugMap$Plugins$Drawing$Model = function (wkts) {
	return function (flatWKTs) {
		return function (hasChanges) {
			return function (editing) {
				return function (enabled) {
					return function (controlEnabled) {
						return function (buffer) {
							return function (mode) {
								return function (options) {
									return function (area) {
										return function (requestedFlatWKTs) {
											return function (id) {
												return function (initialized) {
													return function (nextExpectedId) {
														return {di: area, cA: buffer, dH: controlEnabled, dP: editing, kJ: enabled, e6: flatWKTs, b1: hasChanges, r: id, d0: initialized, lP: mode, eh: nextExpectedId, h4: options, es: requestedFlatWKTs, cr: wkts};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$PlugMap$Plugins$Drawing$NotCalculated = {$: 0};
var $author$project$PlugMap$Plugins$Drawing$Polygon = 0;
var $author$project$PlugMap$Plugins$Drawing$defaultOptions = {e$: false};
var $author$project$PlugMap$Plugins$Drawing$defaultModel = $author$project$PlugMap$Plugins$Drawing$Model(_List_Nil)(_List_Nil)(false)(false)(false)(true)(5.0)(0)($author$project$PlugMap$Plugins$Drawing$defaultOptions)($author$project$PlugMap$Plugins$Drawing$NotCalculated)(false)('')(false)($elm$core$Maybe$Nothing);
var $author$project$PlugMap$Plugins$Identify$defaultModel = {eT: $elm$core$Maybe$Nothing, hE: _List_Nil, fN: $elm$core$Maybe$Nothing, fR: false};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$PlugMap$Plugins$Themes$defaultModel = {ly: _List_Nil, lz: $elm$core$Dict$empty, lB: $elm$core$Dict$empty};
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$rgb = F3(
	function (r, g, b) {
		return A4($avh4$elm_color$Color$RgbaSpace, r, g, b, 1.0);
	});
var $author$project$PAM$Map$defaultModel = {
	cu: $elm$core$Maybe$Just(
		A3($avh4$elm_color$Color$rgb, 1, 0, 0)),
	cv: '',
	V: 0,
	cB: $author$project$PlugMap$Plugins$ColorUnderPointer$defaultModel,
	at: $author$project$PlugMap$Plugins$Drawing$defaultModel,
	aO: $author$project$PlugMap$Plugins$Identify$defaultModel,
	h: $author$project$PlugMap$Core$defaultModel,
	er: _List_Nil,
	ez: $elm$core$Dict$empty,
	cj: 0,
	c$: 1,
	s: $author$project$PlugMap$Plugins$Themes$defaultModel,
	fZ: false
};
var $author$project$PlugMap$Plugins$Search$defaultModel = {iy: $krisajenkins$remotedata$RemoteData$NotAsked};
var $author$project$PAM$Route$defaultQueryParams = {eY: true, aM: 1, iP: false, s: true};
var $author$project$PAM$UI$Search$None = {$: 0};
var $author$project$PAM$UI$Search$defaultModel = {
	kF: false,
	k5: true,
	mv: $elm$core$Maybe$Just('    Enter address, place, or coordinates'),
	bd: '',
	bg: $author$project$PAM$UI$Search$None
};
var $author$project$PAM$defaultSearchModel = $author$project$PAM$UI$Search$defaultModel;
var $author$project$PAM$Flags = F4(
	function (version, settings, size, browser) {
		return {J: browser, m4: settings, aD: size, da: version};
	});
var $author$project$PAM$Settings$AppSettings = F5(
	function (api, authorization, header, appConfig, leftSearchTxt) {
		return {jQ: api, M: appConfig, jT: authorization, fa: header, lD: leftSearchTxt};
	});
var $author$project$PAM$Settings$APISettings = F5(
	function (baseURL, version, defaultMethod, useVersion, endpoints) {
		return {jW: baseURL, kB: defaultMethod, kK: endpoints, je: useVersion, da: version};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$PAM$Settings$Endpoint = F3(
	function (method, useVersion, version) {
		return {lM: method, je: useVersion, da: version};
	});
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$PAM$Settings$endpointDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'version',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'useVersion',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'method',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$PAM$Settings$Endpoint))));
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$PAM$Settings$apiSettingsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'endpoints',
	$elm$json$Json$Decode$dict($author$project$PAM$Settings$endpointDecoder),
	$elm$core$Dict$empty,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'useVersion',
		$elm$json$Json$Decode$bool,
		false,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'defaultMethod',
			$elm$json$Json$Decode$string,
			'POST',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'version',
				$elm$json$Json$Decode$string,
				'v1',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'baseURL',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$PAM$Settings$APISettings))))));
var $author$project$PAM$Settings$AppTypeConfig = function (staticLegend) {
	return function (editorTitle) {
		return function (initDrawer) {
			return function (objectDetailsEmptyViewTxt) {
				return function (leftSidebarSearchUrl) {
					return function (search) {
						return function (geometry) {
							return function (darken) {
								return function (state) {
									return function (zoomClass) {
										return {gS: darken, g4: editorTitle, k$: geometry, d$: initDrawer, lF: leftSidebarSearchUrl, hZ: objectDetailsEmptyViewTxt, cY: search, cj: state, nf: staticLegend, nW: zoomClass};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$PAM$Settings$defaultDisplayConfig = {cG: 2};
var $author$project$PAM$Settings$defaultDrawConfig = {gx: true, hK: true, hP: 5000, hR: true, $8: true, ig: true, jb: true};
var $author$project$PAM$Settings$defaultLayersConfig = {};
var $author$project$PAM$Settings$defaultUploadConfig = {};
var $author$project$PAM$Settings$defaultGeometryConfig = {eW: $author$project$PAM$Settings$defaultDisplayConfig, g3: $author$project$PAM$Settings$defaultDrawConfig, hE: $author$project$PAM$Settings$defaultLayersConfig, jd: $author$project$PAM$Settings$defaultUploadConfig};
var $author$project$PAM$Settings$defaultSearchExtent = {
	iS: $elm$core$Maybe$Just(4326),
	jr: -108.984,
	js: -114.312,
	ju: 42.02,
	jv: 36.967
};
var $author$project$PAM$Settings$defaultSearchConfig = {
	e3: $author$project$PAM$Settings$defaultSearchExtent,
	k2: _List_fromArray(
		[
			_List_fromArray(
			['You can enter an address, location, or longitude/latitude coordinates and then press Enter or click the Search button to search. Several different formats are acceptable for longitude and latitude searches: Decimal Degrees, Degrees Minutes Seconds, and Degrees Decimal Minutes.']),
			_List_fromArray(
			['']),
			_List_fromArray(
			['The following formats are supported:']),
			_List_fromArray(
			[' ', '•', '-111, 40.7']),
			_List_fromArray(
			[' ', '•', '111W, 40N']),
			_List_fromArray(
			[' ', '•', '111.89W 40.76N']),
			_List_fromArray(
			[' ', '•', 'W111, N40.7']),
			_List_fromArray(
			[' ', '•', 'X: -111 Y: 40.7']),
			_List_fromArray(
			[' ', '•', '-111.89, 40.76']),
			_List_fromArray(
			[' ', '•', '111°53\'27.6\" W 40°45\'38.9\" N']),
			_List_fromArray(
			[' ', '•', '-111°53\'27.6\",40°45\'38.9\"']),
			_List_fromArray(
			[' ', '•', 'X:-111°53\'27.6\" Y:40°45\'38.9\"']),
			_List_fromArray(
			[' ', '•', '111° 53\' 27.6\" W 40° 45\' 38.9\" N']),
			_List_fromArray(
			[' ', '•', '111d53m27.6s W 40d45m38.9s N']),
			_List_fromArray(
			[' ', '•', '-111d53m27.6s,40d45m38.9s']),
			_List_fromArray(
			[' ', '•', 'X:-111d53m27.6s Y:40d45m38.9s']),
			_List_fromArray(
			[' ', '•', '111d 53m 27.6s W 40d 45m 38.9s N']),
			_List_fromArray(
			['']),
			_List_fromArray(
			['']),
			_List_fromArray(
			[' Note: Either Longitude or Latitude may be entered first.'])
		]),
	fp: 10,
	fJ: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates',
	fV: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest'
};
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$PAM$Settings$GeometryConfig = F4(
	function (draw, upload, layers, display) {
		return {eW: display, g3: draw, hE: layers, jd: upload};
	});
var $author$project$PAM$Settings$DisplayConfig = function (decimalPlaces) {
	return {cG: decimalPlaces};
};
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$PAM$Settings$displayConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'decimalPlaces',
	$elm$json$Json$Decode$int,
	2,
	$elm$json$Json$Decode$succeed($author$project$PAM$Settings$DisplayConfig));
var $author$project$PAM$Settings$DrawConfig = F7(
	function (pointEnabled, lineEnabled, polygonEnabled, bufferEnabled, mergeShapes, unkinkShapes, maxVerticies) {
		return {gx: bufferEnabled, hK: lineEnabled, hP: maxVerticies, hR: mergeShapes, $8: pointEnabled, ig: polygonEnabled, jb: unkinkShapes};
	});
var $author$project$PAM$Settings$drawConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'maxVerticies',
	$elm$json$Json$Decode$int,
	5000,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'unkinkShapes',
		$elm$json$Json$Decode$bool,
		true,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'mergeShapes',
			$elm$json$Json$Decode$bool,
			true,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'bufferEnabled',
				$elm$json$Json$Decode$bool,
				true,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'polygonEnabled',
					$elm$json$Json$Decode$bool,
					true,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'lineEnabled',
						$elm$json$Json$Decode$bool,
						true,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'pointEnabled',
							$elm$json$Json$Decode$bool,
							true,
							$elm$json$Json$Decode$succeed($author$project$PAM$Settings$DrawConfig))))))));
var $author$project$PAM$Settings$LayersConfig = {};
var $author$project$PAM$Settings$layersConfigDecoder = $elm$json$Json$Decode$succeed($author$project$PAM$Settings$LayersConfig);
var $author$project$PAM$Settings$UploadConfig = {};
var $author$project$PAM$Settings$uploadConfigDecoder = $elm$json$Json$Decode$succeed($author$project$PAM$Settings$UploadConfig);
var $author$project$PAM$Settings$geometryConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'display',
	$author$project$PAM$Settings$displayConfigDecoder,
	$author$project$PAM$Settings$defaultDisplayConfig,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'layers',
		$author$project$PAM$Settings$layersConfigDecoder,
		$author$project$PAM$Settings$defaultLayersConfig,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'upload',
			$author$project$PAM$Settings$uploadConfigDecoder,
			$author$project$PAM$Settings$defaultUploadConfig,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'draw',
				$author$project$PAM$Settings$drawConfigDecoder,
				$author$project$PAM$Settings$defaultDrawConfig,
				$elm$json$Json$Decode$succeed($author$project$PAM$Settings$GeometryConfig)))));
var $author$project$PAM$Settings$SearchConfig = F5(
	function (help, extent, maxCandidates, searchUrl, suggestUrl) {
		return {e3: extent, k2: help, fp: maxCandidates, fJ: searchUrl, fV: suggestUrl};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$PAM$Settings$SearchExtent = F5(
	function (xmin, ymin, xmax, ymax, spatialReference) {
		return {iS: spatialReference, jr: xmax, js: xmin, ju: ymax, jv: ymin};
	});
var $author$project$PAM$Settings$searchExtentDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'spatialReference',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'ymax',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'xmax',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'ymin',
				$elm$json$Json$Decode$float,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'xmin',
					$elm$json$Json$Decode$float,
					$elm$json$Json$Decode$succeed($author$project$PAM$Settings$SearchExtent))))));
var $author$project$PAM$Settings$searchConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'suggestUrl',
	$elm$json$Json$Decode$string,
	$author$project$PAM$Settings$defaultSearchConfig.fV,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'searchUrl',
		$elm$json$Json$Decode$string,
		$author$project$PAM$Settings$defaultSearchConfig.fJ,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'maxCandidates',
			$elm$json$Json$Decode$int,
			$author$project$PAM$Settings$defaultSearchConfig.fp,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'extent',
				$author$project$PAM$Settings$searchExtentDecoder,
				$author$project$PAM$Settings$defaultSearchConfig.e3,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'help',
					$elm$json$Json$Decode$list(
						$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
					$author$project$PAM$Settings$defaultSearchConfig.k2,
					$elm$json$Json$Decode$succeed($author$project$PAM$Settings$SearchConfig))))));
var $author$project$PAM$Settings$appTypeConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'zoomClass',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'state',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'darken',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'geometry',
				$author$project$PAM$Settings$geometryConfigDecoder,
				$author$project$PAM$Settings$defaultGeometryConfig,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'search',
					$author$project$PAM$Settings$searchConfigDecoder,
					$author$project$PAM$Settings$defaultSearchConfig,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'leftSidebarSearchUrl',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'objectDetailsEmptyViewTxt',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'initDrawer',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'editorTitle',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'staticLegend',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
										$elm$core$Maybe$Nothing,
										$elm$json$Json$Decode$succeed($author$project$PAM$Settings$AppTypeConfig)))))))))));
var $author$project$PAM$Settings$AuthorizationSettings = F9(
	function (host, clientId, clientSecret, authorizationEndpoint, tokenEndpoint, userInfoEndpoint, unauthorizedRedirect, https, mode) {
		return {eM: authorizationEndpoint, a0: clientId, ki: clientSecret, cO: host, k9: https, lP: mode, nJ: tokenEndpoint, nM: unauthorizedRedirect, nS: userInfoEndpoint};
	});
var $author$project$PAM$Settings$OAuth2 = 0;
var $author$project$PAM$Settings$Demo = 1;
var $author$project$PAM$Settings$authModeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (mode) {
		return (mode === 'demo') ? $elm$json$Json$Decode$succeed(1) : ((mode === 'oauth') ? $elm$json$Json$Decode$succeed(0) : $elm$json$Json$Decode$fail('mode must be one of [ demo, oauth ]'));
	},
	$elm$json$Json$Decode$string);
var $author$project$PAM$Settings$authorizationSettingsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'mode',
	$author$project$PAM$Settings$authModeDecoder,
	0,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'useHttps',
		$elm$json$Json$Decode$bool,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'UnauthorizedRedirect',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'UserInfoEndpoint',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'TokenEndpoint',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'AuthorizationEndpoint',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'ClientSecret',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'ClientId',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'Host',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$PAM$Settings$AuthorizationSettings))))))))));
var $author$project$PAM$Settings$defaultAppTypeConfig = {gS: $elm$core$Maybe$Nothing, g4: $elm$core$Maybe$Nothing, k$: $author$project$PAM$Settings$defaultGeometryConfig, d$: $elm$core$Maybe$Nothing, lF: $elm$core$Maybe$Nothing, hZ: $elm$core$Maybe$Nothing, cY: $author$project$PAM$Settings$defaultSearchConfig, cj: $elm$core$Maybe$Nothing, nf: $elm$core$Maybe$Nothing, nW: $elm$core$Maybe$Nothing};
var $author$project$PAM$Settings$defaultHeaderSettings = {
	dY: _List_Nil,
	fn: {jM: '', fd: '', ne: ''},
	F: {ms: $elm$core$Maybe$Nothing, fT: '', F: ''},
	f$: _List_Nil
};
var $author$project$PAM$Settings$defaultLeftSearchTxt = {gX: 'Click a feature on the map to view or edit it, or use the Search tool below.', gY: '', gZ: 'Fire ID should be a combination of a year, fire code, and a 6-digit number (e.g., 2019-UTSCS-190031). ', g_: 'IRWIN ID should be a string of alphanumeric characters (e.g., 4d36a069-89b1-4ecb-8a5e-37a0452be635) as obtained from the IRWIN system.', g$: 'Fire Name should be a text value (e.g., Crossing Fire).'};
var $author$project$PAM$Settings$HeaderSettings = F4(
	function (title, logo, headerLinks, userMenuLinks) {
		return {dY: headerLinks, fn: logo, F: title, f$: userMenuLinks};
	});
var $author$project$PAM$Settings$Link = F3(
	function (name, action, requiredRoles) {
		return {S: action, fu: name, cf: requiredRoles};
	});
var $author$project$PAM$Settings$linkDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'requiredRoles',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'action',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PAM$Settings$Link))));
var $author$project$PAM$Settings$linksDecoder = $elm$json$Json$Decode$list($author$project$PAM$Settings$linkDecoder);
var $author$project$PAM$Settings$linksListDecoder = $elm$json$Json$Decode$list($author$project$PAM$Settings$linksDecoder);
var $author$project$PAM$Settings$LogoConfig = F3(
	function (src, href, alt) {
		return {jM: alt, fd: href, ne: src};
	});
var $author$project$PAM$Settings$logoDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'alt',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'href',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'src',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PAM$Settings$LogoConfig))));
var $author$project$PAM$Settings$TitleConfig = F3(
	function (title, subTitle, pageTitle) {
		return {ms: pageTitle, fT: subTitle, F: title};
	});
var $author$project$PAM$Settings$titleDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'pageTitle',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'subTitle',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'title',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PAM$Settings$TitleConfig))));
var $author$project$PAM$Settings$headerSettingsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'userMenuLinks',
	$author$project$PAM$Settings$linksListDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'headerLinks',
		$author$project$PAM$Settings$linksDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'logo',
			$author$project$PAM$Settings$logoDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'title',
				$author$project$PAM$Settings$titleDecoder,
				$elm$json$Json$Decode$succeed($author$project$PAM$Settings$HeaderSettings)))));
var $author$project$PAM$Settings$LeftSidebarSearchTxt = F5(
	function (defaultTxt1, defaultTxt2, defaultTxt3, defaultTxt4, defaultTxt5) {
		return {gX: defaultTxt1, gY: defaultTxt2, gZ: defaultTxt3, g_: defaultTxt4, g$: defaultTxt5};
	});
var $author$project$PAM$Settings$leftSearchTxtDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'defaultTxt5',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'defaultTxt4',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'defaultTxt3',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'defaultTxt2',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'defaultTxt1',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$PAM$Settings$LeftSidebarSearchTxt))))));
var $author$project$PAM$Settings$appSettingsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'LeftSidebarSearchTxt',
	$author$project$PAM$Settings$leftSearchTxtDecoder,
	$author$project$PAM$Settings$defaultLeftSearchTxt,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'appCofig',
		$author$project$PAM$Settings$appTypeConfigDecoder,
		$author$project$PAM$Settings$defaultAppTypeConfig,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'header',
			$author$project$PAM$Settings$headerSettingsDecoder,
			$author$project$PAM$Settings$defaultHeaderSettings,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'Authorization',
				$author$project$PAM$Settings$authorizationSettingsDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'api',
					$author$project$PAM$Settings$apiSettingsDecoder,
					$elm$json$Json$Decode$succeed($author$project$PAM$Settings$AppSettings))))));
var $author$project$PAM$Settings$Browser = function (type_) {
	return {bi: type_};
};
var $author$project$PAM$Settings$Chrome = 0;
var $author$project$PAM$Settings$Edge = 1;
var $author$project$PAM$Settings$Firefox = 2;
var $author$project$PAM$Settings$InternetExplorer = 5;
var $author$project$PAM$Settings$NotSupported = 6;
var $author$project$PAM$Settings$Opera = 3;
var $author$project$PAM$Settings$Safari = 4;
var $author$project$PAM$Settings$browserTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (s) {
					case 'chrome':
						return 0;
					case 'edge':
						return 1;
					case 'firefox':
						return 2;
					case 'opera':
						return 3;
					case 'safari':
						return 4;
					case 'ie':
						return 5;
					default:
						return 6;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$PAM$Settings$browserDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'type',
	$author$project$PAM$Settings$browserTypeDecoder,
	$elm$json$Json$Decode$succeed($author$project$PAM$Settings$Browser));
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$PAM$UI$Basic$WindowSize = F2(
	function (width, height) {
		return {fb: height, eJ: width};
	});
var $author$project$PAM$UI$Basic$windowSizeDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$PAM$UI$Basic$WindowSize,
	A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'height', $elm$json$Json$Decode$int));
var $author$project$PAM$flagsDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$PAM$Flags,
	A2($elm$json$Json$Decode$field, 'version', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'settings', $author$project$PAM$Settings$appSettingsDecoder),
	A2($elm$json$Json$Decode$field, 'size', $author$project$PAM$UI$Basic$windowSizeDecoder),
	A2($elm$json$Json$Decode$field, 'browser', $author$project$PAM$Settings$browserDecoder));
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$not = _Basics_not;
var $elm_community$list_extra$List$Extra$filterNot = F2(
	function (pred, list) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, pred),
			list);
	});
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {bt: frag, bF: params, bj: unvisited, aG: value, bP: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.bj;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.aG);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.aG);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.cU),
					$elm$url$Url$Parser$prepareQuery(url.$9),
					url.e8,
					$elm$core$Basics$identity)));
	});
var $author$project$PAM$Route$Blank = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$Route$EditObjectDetails = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$PAM$Route$LinkNewObject = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $author$project$PAM$Route$Map = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Route$NewObject = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$PAM$Route$ShowObjectDetails = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.bP;
			var unvisited = _v0.bj;
			var params = _v0.bF;
			var frag = _v0.bt;
			var value = _v0.aG;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$int = A2($elm$url$Url$Parser$custom, 'NUMBER', $elm$core$String$toInt);
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.bP;
		var unvisited = _v0.bj;
		var params = _v0.bF;
		var frag = _v0.bt;
		var value = _v0.aG;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.bP;
			var unvisited = _v1.bj;
			var params = _v1.bF;
			var frag = _v1.bt;
			var value = _v1.aG;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $author$project$PAM$Route$QueryParams = F4(
	function (grid, sidebar, drawer, themes) {
		return {eY: drawer, aM: grid, iP: sidebar, s: themes};
	});
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$int = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$String$toInt(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$Query$map = F2(
	function (func, _v0) {
		var a = _v0;
		return function (dict) {
			return func(
				a(dict));
		};
	});
var $elm$url$Url$Parser$Query$map4 = F5(
	function (func, _v0, _v1, _v2, _v3) {
		var a = _v0;
		var b = _v1;
		var c = _v2;
		var d = _v3;
		return function (dict) {
			return A4(
				func,
				a(dict),
				b(dict),
				c(dict),
				d(dict));
		};
	});
var $author$project$PAM$Route$queryParser = function () {
	var parseBinary = function (val) {
		switch (val) {
			case 1:
				return $elm$core$Maybe$Just(true);
			case 0:
				return $elm$core$Maybe$Just(false);
			default:
				return $elm$core$Maybe$Nothing;
		}
	};
	return A5(
		$elm$url$Url$Parser$Query$map4,
		$author$project$PAM$Route$QueryParams,
		A2(
			$elm$url$Url$Parser$Query$map,
			$elm$core$Maybe$withDefault(1),
			$elm$url$Url$Parser$Query$int('grid')),
		A2(
			$elm$url$Url$Parser$Query$map,
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Maybe$withDefault(false),
				$elm$core$Maybe$andThen(parseBinary)),
			$elm$url$Url$Parser$Query$int('sidebar')),
		A2(
			$elm$url$Url$Parser$Query$map,
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Maybe$withDefault(true),
				$elm$core$Maybe$andThen(parseBinary)),
			$elm$url$Url$Parser$Query$int('drawer')),
		A2(
			$elm$url$Url$Parser$Query$map,
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Maybe$withDefault(true),
				$elm$core$Maybe$andThen(parseBinary)),
			$elm$url$Url$Parser$Query$int('themes')));
}();
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.bP;
		var unvisited = _v1.bj;
		var params = _v1.bF;
		var frag = _v1.bt;
		var value = _v1.aG;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.bP;
		var unvisited = _v0.bj;
		var params = _v0.bF;
		var frag = _v0.bt;
		var value = _v0.aG;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$PAM$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			$author$project$PAM$Route$Blank,
			A2($elm$url$Url$Parser$questionMark, $elm$url$Url$Parser$top, $author$project$PAM$Route$queryParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$PAM$Route$Map,
			A2(
				$elm$url$Url$Parser$questionMark,
				$elm$url$Url$Parser$s('map'),
				$author$project$PAM$Route$queryParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$PAM$Route$NewObject,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('map'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$questionMark,
						$elm$url$Url$Parser$s('new'),
						$author$project$PAM$Route$queryParser)))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$PAM$Route$LinkNewObject,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('map'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$int,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s('link'),
							A2(
								$elm$url$Url$Parser$slash,
								$elm$url$Url$Parser$string,
								A2(
									$elm$url$Url$Parser$questionMark,
									$elm$url$Url$Parser$s('new'),
									$author$project$PAM$Route$queryParser))))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$PAM$Route$ShowObjectDetails,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('map'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$int,
						A2(
							$elm$url$Url$Parser$questionMark,
							$elm$url$Url$Parser$oneOf(
								_List_fromArray(
									[
										$elm$url$Url$Parser$top,
										$elm$url$Url$Parser$s('show')
									])),
							$author$project$PAM$Route$queryParser))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$PAM$Route$EditObjectDetails,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('map'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$int,
						A2(
							$elm$url$Url$Parser$questionMark,
							$elm$url$Url$Parser$s('edit'),
							$author$project$PAM$Route$queryParser)))))
		]));
var $author$project$PAM$Route$fromUrl = function (url) {
	var parts = A2(
		$elm_community$list_extra$List$Extra$filterNot,
		$elm$core$String$isEmpty,
		A2(
			$elm$core$String$split,
			'?',
			A2($elm$core$Maybe$withDefault, '', url.e8)));
	var _v0 = function () {
		if (!parts.b) {
			return _Utils_Tuple2('', $elm$core$Maybe$Nothing);
		} else {
			if (!parts.b.b) {
				var p = parts.a;
				return _Utils_Tuple2(p, $elm$core$Maybe$Nothing);
			} else {
				var p = parts.a;
				var _v2 = parts.b;
				var q = _v2.a;
				return _Utils_Tuple2(
					p,
					$elm$core$Maybe$Just(q));
			}
		}
	}();
	var path = _v0.a;
	var query = _v0.b;
	return A2(
		$elm$url$Url$Parser$parse,
		$author$project$PAM$Route$parser,
		_Utils_update(
			url,
			{e8: $elm$core$Maybe$Nothing, cU: path, $9: query}));
};
var $author$project$PAM$Route$getQueryParams = function (route) {
	switch (route.$) {
		case 0:
			var params = route.a;
			return params;
		case 1:
			var params = route.a;
			return params;
		case 2:
			var params = route.b;
			return params;
		case 3:
			var params = route.d;
			return params;
		case 4:
			var params = route.c;
			return params;
		default:
			var params = route.c;
			return params;
	}
};
var $author$project$PAM$UI$DateRangeFilter$PickerMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$UI$DateRangeFilter$ToPickerMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$DatePicker$CurrentDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$UI$DatePicker$DatePicker = $elm$core$Basics$identity;
var $author$project$PAM$UI$DatePicker$NoneSelected = {$: 0};
var $author$project$PAM$UI$DatePicker$NotEntered = {$: 0};
var $elm$time$Time$May = 4;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $author$project$PAM$UI$DatePicker$Date$initDate = A3($justinmimbs$date$Date$fromCalendarDate, 1992, 4, 31);
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.iT, posixMinutes) < 0) {
					return posixMinutes + era.lY;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		gU: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		ef: month,
		cs: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gU;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ef;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cs;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $author$project$PAM$UI$DatePicker$init = _Utils_Tuple2(
	{K: $elm$core$Maybe$Nothing, bs: false, ab: $author$project$PAM$UI$DatePicker$NotEntered, em: false, af: $author$project$PAM$UI$DatePicker$NotEntered, m0: $author$project$PAM$UI$DatePicker$NoneSelected, ah: $author$project$PAM$UI$DatePicker$Date$initDate},
	A2($elm$core$Task$perform, $author$project$PAM$UI$DatePicker$CurrentDate, $justinmimbs$date$Date$today));
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$PAM$UI$DateRangeFilter$init = function () {
	var _v0 = $author$project$PAM$UI$DatePicker$init;
	var toDatePicker = _v0.a;
	var toDatePickerFx = _v0.b;
	var _v1 = $author$project$PAM$UI$DatePicker$init;
	var datePicker = _v1.a;
	var datePickerFx = _v1.b;
	return _Utils_Tuple2(
		{dM: $elm$core$Maybe$Nothing, kw: datePicker, nH: toDatePicker},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$UI$DateRangeFilter$PickerMsg, datePickerFx),
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$UI$DateRangeFilter$ToPickerMsg, toDatePickerFx)
				])));
}();
var $author$project$Grid$Grid$Sort_None = {$: 0};
var $author$project$Grid$Grid$defaultGridModel = {bX: 1, al: 0, cK: $elm$core$Maybe$Nothing, aL: 1, am: 10, a7: 1, cY: $elm$core$Maybe$Nothing, bf: $author$project$Grid$Grid$Sort_None};
var $author$project$PAM$initGridState = {aM: $author$project$Grid$Grid$defaultGridModel, N: $elm$core$Maybe$Nothing};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$PAM$Route$getQueryParamsOrDefault = function (maybeRoute) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Route$defaultQueryParams,
		A2($elm$core$Maybe$map, $author$project$PAM$Route$getQueryParams, maybeRoute));
};
var $author$project$PAM$Route$pickQueryParams = F2(
	function (maybeParams, maybeRoute) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$PAM$Route$getQueryParamsOrDefault(maybeRoute),
			maybeParams);
	});
var $author$project$PAM$Route$initMap = F2(
	function (params, route) {
		return $author$project$PAM$Route$Map(
			A2($author$project$PAM$Route$pickQueryParams, params, route));
	});
var $author$project$Animation$height = function (_v0) {
	var val = _v0.a;
	var len = _v0.b;
	return A3(
		$author$project$Animation$length,
		'height',
		val,
		$author$project$Animation$lengthUnitName(len));
};
var $author$project$Animation$left = function (_v0) {
	var val = _v0.a;
	var len = _v0.b;
	return A3(
		$author$project$Animation$length,
		'left',
		val,
		$author$project$Animation$lengthUnitName(len));
};
var $author$project$PAM$UI$Basic$leftSidebarStates = function (dims) {
	var lsbHeight = dims.fl;
	var lsWidth = dims.lG;
	var bdhHeight = dims.j6;
	var bdHeight = dims.j7;
	return {
		dA: _List_fromArray(
			[
				$author$project$Animation$left(
				$author$project$Animation$px(-lsWidth))
			]),
		dV: _List_fromArray(
			[
				$author$project$Animation$height(
				$author$project$Animation$px(lsbHeight - bdhHeight))
			]),
		em: _List_fromArray(
			[
				$author$project$Animation$left(
				$author$project$Animation$px(0.0))
			]),
		eA: _List_fromArray(
			[
				$author$project$Animation$height(
				$author$project$Animation$px(lsbHeight - bdHeight))
			])
	};
};
var $author$project$Ports$logErrorCmd = _Platform_outgoingPort('logErrorCmd', $elm$core$Basics$identity);
var $author$project$PAM$Model$DetailLayouts$DetailLayouts = $elm$core$Basics$identity;
var $author$project$PAM$Model$DetailLayouts$new = $elm$core$Dict$empty;
var $author$project$PAM$Model$FieldChoices$FieldChoices = $elm$core$Basics$identity;
var $author$project$PAM$Model$FieldChoices$new = $elm$core$Dict$empty;
var $author$project$PAM$Model$GridLayouts$GridLayouts = $elm$core$Basics$identity;
var $author$project$PAM$Model$GridLayouts$new = $elm$core$Dict$empty;
var $author$project$PAM$Model$ObjectModels$ObjectModels = $elm$core$Basics$identity;
var $author$project$PAM$Model$ObjectModels$new = $elm$core$Dict$empty;
var $author$project$PAM$Model$ObjectTypes$ObjectTypes = $elm$core$Basics$identity;
var $author$project$PAM$Model$ObjectTypes$new = $elm$core$Dict$empty;
var $author$project$PAM$Model$ModelInfo$new = {C: $author$project$PAM$Model$DetailLayouts$new, y: $author$project$PAM$Model$FieldChoices$new, dW: $author$project$PAM$Model$GridLayouts$new, l: $author$project$PAM$Model$ObjectModels$new, c: $author$project$PAM$Model$ObjectTypes$new};
var $author$project$PAM$Object$Repo$Repo = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$PAM$Object$Repo$new = A2($author$project$PAM$Object$Repo$Repo, $elm$core$Dict$empty, $elm$core$Dict$empty);
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$PAM$User$Profile = F4(
	function (id, email, name, roles) {
		return {kI: email, r: id, fu: name, iz: roles};
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $author$project$PAM$User$openIDDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'email',
			$elm$json$Json$Decode$string,
			'error',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'sub',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$PAM$User$Profile)))));
var $author$project$PAM$User$wrapProfileDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'roles',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'email',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$PAM$User$Profile)))));
var $author$project$PAM$User$profileDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$PAM$User$wrapProfileDecoder, $author$project$PAM$User$openIDDecoder]));
var $author$project$PAM$oAuthConfig = F2(
	function (settings, origin) {
		var userInfoEndpoint = settings.jT.nS;
		var tokenEndpoint = settings.jT.nJ;
		var host = settings.jT.cO;
		var defaultHttpsUrl = {
			e8: $elm$core$Maybe$Nothing,
			cO: '',
			cU: '',
			mx: $elm$core$Maybe$Nothing,
			mE: settings.jT.k9 ? 1 : 0,
			$9: $elm$core$Maybe$Nothing
		};
		var authorizationEndpoint = settings.jT.eM;
		return {
			eM: _Utils_update(
				defaultHttpsUrl,
				{cO: host, cU: authorizationEndpoint}),
			a0: settings.jT.a0,
			kA: $author$project$PAM$User$profileDecoder,
			mD: _Utils_update(
				defaultHttpsUrl,
				{cO: host, cU: userInfoEndpoint}),
			aU: _Utils_update(
				origin,
				{e8: $elm$core$Maybe$Nothing, cU: '/oAuthHelper.html', $9: $elm$core$Maybe$Nothing}),
			cg: _List_fromArray(
				['profile', 'openid', 'WRAP_Role']),
			iH: settings.jT.ki,
			nJ: _Utils_update(
				defaultHttpsUrl,
				{cO: host, cU: tokenEndpoint})
		};
	});
var $mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Element$rgb255 = F3(
	function (red, green, blue) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, 1);
	});
var $author$project$PAM$UI$Basic$black = A3($mdgriffith$elm_ui$Element$rgb255, 0, 0, 0);
var $mdgriffith$elm_ui$Element$rgba = $mdgriffith$elm_ui$Internal$Model$Rgba;
var $avh4$elm_color$Color$toRgba = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {X: a, Y: b, aa: g, ae: r};
};
var $author$project$PAM$UI$Basic$convertColor = function (color) {
	return function (_v0) {
		var red = _v0.ae;
		var green = _v0.aa;
		var blue = _v0.Y;
		var alpha = _v0.X;
		return A4($mdgriffith$elm_ui$Element$rgba, red, green, blue, alpha);
	}(
		$avh4$elm_color$Color$toRgba(color));
};
var $avh4$elm_color$Color$hsla = F4(
	function (hue, sat, light, alpha) {
		var _v0 = _Utils_Tuple3(hue, sat, light);
		var h = _v0.a;
		var s = _v0.b;
		var l = _v0.c;
		var m2 = (l <= 0.5) ? (l * (s + 1)) : ((l + s) - (l * s));
		var m1 = (l * 2) - m2;
		var hueToRgb = function (h__) {
			var h_ = (h__ < 0) ? (h__ + 1) : ((h__ > 1) ? (h__ - 1) : h__);
			return ((h_ * 6) < 1) ? (m1 + (((m2 - m1) * h_) * 6)) : (((h_ * 2) < 1) ? m2 : (((h_ * 3) < 2) ? (m1 + (((m2 - m1) * ((2 / 3) - h_)) * 6)) : m1));
		};
		var b = hueToRgb(h - (1 / 3));
		var g = hueToRgb(h);
		var r = hueToRgb(h + (1 / 3));
		return A4($avh4$elm_color$Color$RgbaSpace, r, g, b, alpha);
	});
var $noahzgordon$elm_color_extra$Color$Manipulate$limit = A2($elm$core$Basics$clamp, 0, 1);
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $avh4$elm_color$Color$toHsla = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	var minColor = A2(
		$elm$core$Basics$min,
		r,
		A2($elm$core$Basics$min, g, b));
	var maxColor = A2(
		$elm$core$Basics$max,
		r,
		A2($elm$core$Basics$max, g, b));
	var l = (minColor + maxColor) / 2;
	var s = _Utils_eq(minColor, maxColor) ? 0 : ((l < 0.5) ? ((maxColor - minColor) / (maxColor + minColor)) : ((maxColor - minColor) / ((2 - maxColor) - minColor)));
	var h1 = _Utils_eq(maxColor, r) ? ((g - b) / (maxColor - minColor)) : (_Utils_eq(maxColor, g) ? (2 + ((b - r) / (maxColor - minColor))) : (4 + ((r - g) / (maxColor - minColor))));
	var h2 = h1 * (1 / 6);
	var h3 = $elm$core$Basics$isNaN(h2) ? 0 : ((h2 < 0) ? (h2 + 1) : h2);
	return {X: a, hn: h3, hI: l, iD: s};
};
var $noahzgordon$elm_color_extra$Color$Manipulate$darken = F2(
	function (offset, cl) {
		var _v0 = $avh4$elm_color$Color$toHsla(cl);
		var hue = _v0.hn;
		var saturation = _v0.iD;
		var lightness = _v0.hI;
		var alpha = _v0.X;
		return A4(
			$avh4$elm_color$Color$hsla,
			hue,
			saturation,
			$noahzgordon$elm_color_extra$Color$Manipulate$limit(lightness - offset),
			alpha);
	});
var $avh4$elm_color$Color$fromRgba = function (components) {
	return A4($avh4$elm_color$Color$RgbaSpace, components.ae, components.aa, components.Y, components.X);
};
var $mdgriffith$elm_ui$Element$toRgb = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {X: a, Y: b, aa: g, ae: r};
};
var $author$project$PAM$UI$Basic$darkenColor = F2(
	function (val, color) {
		var c = $mdgriffith$elm_ui$Element$toRgb(color);
		return $author$project$PAM$UI$Basic$convertColor(
			A2(
				$noahzgordon$elm_color_extra$Color$Manipulate$darken,
				val,
				$avh4$elm_color$Color$fromRgba(c)));
	});
var $author$project$PAM$UI$Basic$doveGray = A3($mdgriffith$elm_ui$Element$rgb255, 103, 103, 103);
var $author$project$PAM$UI$Basic$satinLinen = A3($mdgriffith$elm_ui$Element$rgb255, 230, 232, 214);
var $author$project$PAM$UI$Basic$white = A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255);
var $author$project$PAM$UI$Basic$willowGrove = A3($mdgriffith$elm_ui$Element$rgb255, 105, 111, 102);
var $author$project$PAM$UI$Palette$defaultAction = {
	mB: {
		G: A2($author$project$PAM$UI$Basic$darkenColor, 0.75, $author$project$PAM$UI$Basic$white),
		eE: $author$project$PAM$UI$Basic$satinLinen
	},
	mF: {G: $author$project$PAM$UI$Basic$white, eE: $author$project$PAM$UI$Basic$black},
	cd: {
		G: A2($author$project$PAM$UI$Basic$darkenColor, 0.25, $author$project$PAM$UI$Basic$willowGrove),
		eE: $author$project$PAM$UI$Basic$black
	},
	fK: {
		G: A2($author$project$PAM$UI$Basic$darkenColor, 0.5, $author$project$PAM$UI$Basic$white),
		eE: $author$project$PAM$UI$Basic$satinLinen
	},
	iI: {G: $author$project$PAM$UI$Basic$white, eE: $author$project$PAM$UI$Basic$black},
	fW: {
		G: A2($author$project$PAM$UI$Basic$darkenColor, 0.25, $author$project$PAM$UI$Basic$doveGray),
		eE: $author$project$PAM$UI$Basic$white
	}
};
var $author$project$PAM$UI$Basic$codGray = A3($mdgriffith$elm_ui$Element$rgb255, 23, 23, 23);
var $author$project$PAM$UI$Palette$defaultBackground = {
	mB: {
		G: A2($author$project$PAM$UI$Basic$darkenColor, 0.25, $author$project$PAM$UI$Basic$doveGray),
		eE: $author$project$PAM$UI$Basic$white
	},
	mF: {G: $author$project$PAM$UI$Basic$willowGrove, eE: $author$project$PAM$UI$Basic$black},
	cd: {G: $author$project$PAM$UI$Basic$willowGrove, eE: $author$project$PAM$UI$Basic$black},
	fK: {G: $author$project$PAM$UI$Basic$doveGray, eE: $author$project$PAM$UI$Basic$codGray},
	iI: {G: $author$project$PAM$UI$Basic$willowGrove, eE: $author$project$PAM$UI$Basic$black},
	fW: {
		G: A2($author$project$PAM$UI$Basic$darkenColor, 0.25, $author$project$PAM$UI$Basic$doveGray),
		eE: $author$project$PAM$UI$Basic$white
	}
};
var $author$project$PAM$UI$Palette$standardPalette = {S: $author$project$PAM$UI$Palette$defaultAction, cx: $author$project$PAM$UI$Palette$defaultBackground};
var $author$project$PAM$UI$Palette$palePalette = {bl: $author$project$PAM$UI$Palette$standardPalette, fa: $author$project$PAM$UI$Palette$standardPalette, j: $author$project$PAM$UI$Palette$standardPalette, iO: $author$project$PAM$UI$Palette$standardPalette};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $author$project$PAM$Route$queryUnparser = function (p) {
	var unparseBinary = function (val) {
		return val ? 1 : 0;
	};
	return _List_fromArray(
		[
			A2($elm$url$Url$Builder$int, 'grid', p.aM),
			A2(
			$elm$url$Url$Builder$int,
			'sidebar',
			unparseBinary(p.iP)),
			A2(
			$elm$url$Url$Builder$int,
			'drawer',
			unparseBinary(p.eY)),
			A2(
			$elm$url$Url$Builder$int,
			'themes',
			unparseBinary(p.s))
		]);
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$relative = F2(
	function (pathSegments, parameters) {
		return _Utils_ap(
			A2($elm$core$String$join, '/', pathSegments),
			$elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$PAM$Route$unparser = function (route) {
	var _v0 = function () {
		switch (route.$) {
			case 0:
				var params = route.a;
				return _Utils_Tuple2(
					_List_Nil,
					$author$project$PAM$Route$queryUnparser(params));
			case 1:
				var params = route.a;
				return _Utils_Tuple2(
					_List_fromArray(
						['map']),
					$author$project$PAM$Route$queryUnparser(params));
			case 2:
				var objTypeKey = route.a;
				var params = route.b;
				return _Utils_Tuple2(
					_List_fromArray(
						['map', objTypeKey, 'new']),
					$author$project$PAM$Route$queryUnparser(params));
			case 3:
				var parentObjTypeKey = route.a;
				var parentObjId = route.b;
				var childObjTypeKey = route.c;
				var params = route.d;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							'map',
							parentObjTypeKey,
							$elm$core$String$fromInt(parentObjId),
							'link',
							childObjTypeKey,
							'new'
						]),
					$author$project$PAM$Route$queryUnparser(params));
			case 4:
				var objTypeKey = route.a;
				var objId = route.b;
				var params = route.c;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							'map',
							objTypeKey,
							$elm$core$String$fromInt(objId),
							'show'
						]),
					$author$project$PAM$Route$queryUnparser(params));
			default:
				var objTypeKey = route.a;
				var objId = route.b;
				var params = route.c;
				return _Utils_Tuple2(
					_List_fromArray(
						[
							'map',
							objTypeKey,
							$elm$core$String$fromInt(objId),
							'edit'
						]),
					$author$project$PAM$Route$queryUnparser(params));
		}
	}();
	var pathPieces = _v0.a;
	var queryPieces = _v0.b;
	return '#' + A2($elm$url$Url$Builder$relative, pathPieces, queryPieces);
};
var $author$project$PAM$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$PAM$Route$unparser(route));
	});
var $author$project$InPageOAuth$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$InPageOAuth$Timeout = {$: 1};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$InPageOAuth$bindToAuthFrame = _Platform_outgoingPort(
	'bindToAuthFrame',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$InPageOAuth$regetAuthorization = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{cj: $author$project$InPageOAuth$NotStarted}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2(
					$elm$core$Task$perform,
					$elm$core$Basics$always(
						$author$project$InPageOAuth$Internal($author$project$InPageOAuth$Timeout)),
					$elm$core$Process$sleep(1000)),
					$author$project$InPageOAuth$bindToAuthFrame(0)
				])));
};
var $author$project$Animation$width = function (_v0) {
	var val = _v0.a;
	var len = _v0.b;
	return A3(
		$author$project$Animation$length,
		'width',
		val,
		$author$project$Animation$lengthUnitName(len));
};
var $author$project$PAM$UI$Basic$riskLegendStates = F2(
	function (windowSize, dims) {
		var winWidth = windowSize.eJ;
		var lsWidth = dims.lG;
		var rlWidth = winWidth - lsWidth;
		return {
			dV: _List_fromArray(
				[
					$author$project$Animation$width(
					$author$project$Animation$px(winWidth)),
					$author$project$Animation$left(
					$author$project$Animation$px(0))
				]),
			eA: _List_fromArray(
				[
					$author$project$Animation$width(
					$author$project$Animation$px(rlWidth)),
					$author$project$Animation$left(
					$author$project$Animation$px(lsWidth))
				])
		};
	});
var $author$project$InPageOAuth$setParameters = F3(
	function (model, oAuth, settings) {
		return _Utils_update(
			model,
			{
				bR: $elm$core$Maybe$Just(settings),
				bn: $elm$core$Maybe$Just(oAuth)
			});
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Animation$Model$Animation = $elm$core$Basics$identity;
var $author$project$Animation$initialState = function (current) {
	return {
		fh: _List_Nil,
		ex: false,
		fS: _List_Nil,
		iW: current,
		i$: {
			gQ: $elm$time$Time$millisToPosix(0),
			kH: $elm$time$Time$millisToPosix(0)
		}
	};
};
var $author$project$Animation$Model$Easing = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$pi = _Basics_pi;
var $author$project$Animation$Model$AtSpeed = function (a) {
	return {$: 2, a: a};
};
var $author$project$Animation$speed = function (speedValue) {
	return $author$project$Animation$Model$AtSpeed(speedValue);
};
var $author$project$Animation$defaultInterpolationByProperty = function (prop) {
	var linear = function (duration) {
		return $author$project$Animation$Model$Easing(
			{eZ: duration, e_: $elm$core$Basics$identity, im: 1, iT: 0});
	};
	var defaultSpring = $author$project$Animation$Model$Spring(
		{gR: 26, iU: 170});
	switch (prop.$) {
		case 0:
			return defaultSpring;
		case 1:
			return linear(
				$elm$time$Time$millisToPosix(400));
		case 2:
			return defaultSpring;
		case 3:
			return defaultSpring;
		case 4:
			return defaultSpring;
		case 5:
			var name = prop.a;
			return (name === 'rotate3d') ? $author$project$Animation$speed(
				{ic: $elm$core$Basics$pi}) : defaultSpring;
		case 6:
			return defaultSpring;
		case 7:
			return $author$project$Animation$speed(
				{ic: $elm$core$Basics$pi});
		case 8:
			return defaultSpring;
		default:
			return defaultSpring;
	}
};
var $author$project$Animation$Model$AngleProperty = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Animation$Model$ColorProperty = F5(
	function (a, b, c, d, e) {
		return {$: 1, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Animation$Model$ExactProperty = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Animation$Model$Path = function (a) {
	return {$: 9, a: a};
};
var $author$project$Animation$Model$Points = function (a) {
	return {$: 8, a: a};
};
var $author$project$Animation$Model$Property2 = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Animation$Model$Property3 = F4(
	function (a, b, c, d) {
		return {$: 5, a: a, b: b, c: c, d: d};
	});
var $author$project$Animation$Model$Property4 = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Animation$Model$ShadowProperty = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Animation$Model$AntiClockwiseArc = function (a) {
	return {$: 17, a: a};
};
var $author$project$Animation$Model$ClockwiseArc = function (a) {
	return {$: 16, a: a};
};
var $author$project$Animation$Model$Close = {$: 18};
var $author$project$Animation$Model$Curve = function (a) {
	return {$: 8, a: a};
};
var $author$project$Animation$Model$CurveTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Animation$Model$Horizontal = function (a) {
	return {$: 4, a: a};
};
var $author$project$Animation$Model$HorizontalTo = function (a) {
	return {$: 5, a: a};
};
var $author$project$Animation$Model$Line = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Animation$Model$LineTo = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Animation$Model$Move = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Animation$Model$MoveTo = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Animation$Model$Quadratic = function (a) {
	return {$: 10, a: a};
};
var $author$project$Animation$Model$QuadraticTo = function (a) {
	return {$: 11, a: a};
};
var $author$project$Animation$Model$Smooth = function (a) {
	return {$: 14, a: a};
};
var $author$project$Animation$Model$SmoothQuadratic = function (a) {
	return {$: 12, a: a};
};
var $author$project$Animation$Model$SmoothQuadraticTo = function (a) {
	return {$: 13, a: a};
};
var $author$project$Animation$Model$SmoothTo = function (a) {
	return {$: 15, a: a};
};
var $author$project$Animation$Model$Vertical = function (a) {
	return {$: 6, a: a};
};
var $author$project$Animation$Model$VerticalTo = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Animation$Model$mapPathMotion = F2(
	function (fn, cmd) {
		var mapCoords = function (coords) {
			return A2(
				$elm$core$List$map,
				function (_v1) {
					var x = _v1.a;
					var y = _v1.b;
					return _Utils_Tuple2(
						fn(x),
						fn(y));
				},
				coords);
		};
		switch (cmd.$) {
			case 0:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$author$project$Animation$Model$Move,
					fn(m1),
					fn(m2));
			case 1:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$author$project$Animation$Model$MoveTo,
					fn(m1),
					fn(m2));
			case 2:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$author$project$Animation$Model$Line,
					fn(m1),
					fn(m2));
			case 3:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$author$project$Animation$Model$LineTo,
					fn(m1),
					fn(m2));
			case 4:
				var motion = cmd.a;
				return $author$project$Animation$Model$Horizontal(
					fn(motion));
			case 5:
				var motion = cmd.a;
				return $author$project$Animation$Model$HorizontalTo(
					fn(motion));
			case 6:
				var motion = cmd.a;
				return $author$project$Animation$Model$Vertical(
					fn(motion));
			case 7:
				var motion = cmd.a;
				return $author$project$Animation$Model$VerticalTo(
					fn(motion));
			case 8:
				var control1 = cmd.a.cE;
				var control2 = cmd.a.cF;
				var point = cmd.a.aB;
				return $author$project$Animation$Model$Curve(
					{
						cE: _Utils_Tuple2(
							fn(control1.a),
							fn(control1.b)),
						cF: _Utils_Tuple2(
							fn(control2.a),
							fn(control2.b)),
						aB: _Utils_Tuple2(
							fn(point.a),
							fn(point.b))
					});
			case 9:
				var control1 = cmd.a.cE;
				var control2 = cmd.a.cF;
				var point = cmd.a.aB;
				return $author$project$Animation$Model$CurveTo(
					{
						cE: _Utils_Tuple2(
							fn(control1.a),
							fn(control1.b)),
						cF: _Utils_Tuple2(
							fn(control2.a),
							fn(control2.b)),
						aB: _Utils_Tuple2(
							fn(point.a),
							fn(point.b))
					});
			case 10:
				var control = cmd.a.cD;
				var point = cmd.a.aB;
				return $author$project$Animation$Model$Quadratic(
					{
						cD: _Utils_Tuple2(
							fn(control.a),
							fn(control.b)),
						aB: _Utils_Tuple2(
							fn(point.a),
							fn(point.b))
					});
			case 11:
				var control = cmd.a.cD;
				var point = cmd.a.aB;
				return $author$project$Animation$Model$QuadraticTo(
					{
						cD: _Utils_Tuple2(
							fn(control.a),
							fn(control.b)),
						aB: _Utils_Tuple2(
							fn(point.a),
							fn(point.b))
					});
			case 12:
				var coords = cmd.a;
				return $author$project$Animation$Model$SmoothQuadratic(
					mapCoords(coords));
			case 13:
				var coords = cmd.a;
				return $author$project$Animation$Model$SmoothQuadraticTo(
					mapCoords(coords));
			case 14:
				var coords = cmd.a;
				return $author$project$Animation$Model$Smooth(
					mapCoords(coords));
			case 15:
				var coords = cmd.a;
				return $author$project$Animation$Model$SmoothTo(
					mapCoords(coords));
			case 16:
				var arc = cmd.a;
				return $author$project$Animation$Model$ClockwiseArc(
					function () {
						var y = arc.dd;
						var x = arc.dc;
						var startAngle = arc.c_;
						var radius = arc.cV;
						var endAngle = arc.cI;
						return _Utils_update(
							arc,
							{
								cI: fn(endAngle),
								cV: fn(radius),
								c_: fn(startAngle),
								dc: fn(x),
								dd: fn(y)
							});
					}());
			case 17:
				var arc = cmd.a;
				return $author$project$Animation$Model$AntiClockwiseArc(
					function () {
						var y = arc.dd;
						var x = arc.dc;
						var startAngle = arc.c_;
						var radius = arc.cV;
						var endAngle = arc.cI;
						return _Utils_update(
							arc,
							{
								cI: fn(endAngle),
								cV: fn(radius),
								c_: fn(startAngle),
								dc: fn(x),
								dd: fn(y)
							});
					}());
			default:
				return $author$project$Animation$Model$Close;
		}
	});
var $author$project$Animation$Model$mapToMotion = F2(
	function (fn, prop) {
		switch (prop.$) {
			case 0:
				var name = prop.a;
				var value = prop.b;
				return A2($author$project$Animation$Model$ExactProperty, name, value);
			case 1:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				var m3 = prop.d;
				var m4 = prop.e;
				return A5(
					$author$project$Animation$Model$ColorProperty,
					name,
					fn(m1),
					fn(m2),
					fn(m3),
					fn(m4));
			case 2:
				var name = prop.a;
				var inset = prop.b;
				var shadow = prop.c;
				var size = shadow.aD;
				var red = shadow.ae;
				var offsetY = shadow.ay;
				var offsetX = shadow.ax;
				var green = shadow.aa;
				var blur = shadow.as;
				var blue = shadow.Y;
				var alpha = shadow.X;
				return A3(
					$author$project$Animation$Model$ShadowProperty,
					name,
					inset,
					{
						X: fn(alpha),
						Y: fn(blue),
						as: fn(blur),
						aa: fn(green),
						ax: fn(offsetX),
						ay: fn(offsetY),
						ae: fn(red),
						aD: fn(size)
					});
			case 3:
				var name = prop.a;
				var m1 = prop.b;
				return A2(
					$author$project$Animation$Model$Property,
					name,
					fn(m1));
			case 4:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				return A3(
					$author$project$Animation$Model$Property2,
					name,
					fn(m1),
					fn(m2));
			case 5:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				var m3 = prop.d;
				return A4(
					$author$project$Animation$Model$Property3,
					name,
					fn(m1),
					fn(m2),
					fn(m3));
			case 6:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				var m3 = prop.d;
				var m4 = prop.e;
				return A5(
					$author$project$Animation$Model$Property4,
					name,
					fn(m1),
					fn(m2),
					fn(m3),
					fn(m4));
			case 7:
				var name = prop.a;
				var m1 = prop.b;
				return A2(
					$author$project$Animation$Model$AngleProperty,
					name,
					fn(m1));
			case 8:
				var ms = prop.a;
				return $author$project$Animation$Model$Points(
					A2(
						$elm$core$List$map,
						function (_v1) {
							var x = _v1.a;
							var y = _v1.b;
							return _Utils_Tuple2(
								fn(x),
								fn(y));
						},
						ms));
			default:
				var cmds = prop.a;
				return $author$project$Animation$Model$Path(
					A2(
						$elm$core$List$map,
						$author$project$Animation$Model$mapPathMotion(fn),
						cmds));
		}
	});
var $author$project$Animation$setDefaultInterpolation = function (prop) {
	var interp = $author$project$Animation$defaultInterpolationByProperty(prop);
	return A2(
		$author$project$Animation$Model$mapToMotion,
		function (m) {
			return _Utils_update(
				m,
				{b3: interp});
		},
		prop);
};
var $author$project$Animation$Render$dropWhile = F2(
	function (predicate, list) {
		dropWhile:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					var $temp$predicate = predicate,
						$temp$list = xs;
					predicate = $temp$predicate;
					list = $temp$list;
					continue dropWhile;
				} else {
					return list;
				}
			}
		}
	});
var $author$project$Animation$Render$takeWhile = function (predicate) {
	var takeWhileMemo = F2(
		function (memo, list) {
			takeWhileMemo:
			while (true) {
				if (!list.b) {
					return $elm$core$List$reverse(memo);
				} else {
					var x = list.a;
					var xs = list.b;
					if (predicate(x)) {
						var $temp$memo = A2($elm$core$List$cons, x, memo),
							$temp$list = xs;
						memo = $temp$memo;
						list = $temp$list;
						continue takeWhileMemo;
					} else {
						return $elm$core$List$reverse(memo);
					}
				}
			}
		});
	return takeWhileMemo(_List_Nil);
};
var $author$project$Animation$Render$span = F2(
	function (p, xs) {
		return _Utils_Tuple2(
			A2($author$project$Animation$Render$takeWhile, p, xs),
			A2($author$project$Animation$Render$dropWhile, p, xs));
	});
var $author$project$Animation$Render$groupWhile = F2(
	function (eq, xs_) {
		if (!xs_.b) {
			return _List_Nil;
		} else {
			var x = xs_.a;
			var xs = xs_.b;
			var _v1 = A2(
				$author$project$Animation$Render$span,
				eq(x),
				xs);
			var ys = _v1.a;
			var zs = _v1.b;
			return A2(
				$elm$core$List$cons,
				A2($elm$core$List$cons, x, ys),
				A2($author$project$Animation$Render$groupWhile, eq, zs));
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Animation$Model$propertyName = function (prop) {
	switch (prop.$) {
		case 0:
			var name = prop.a;
			return name;
		case 1:
			var name = prop.a;
			return name;
		case 2:
			var name = prop.a;
			return name;
		case 3:
			var name = prop.a;
			return name;
		case 4:
			var name = prop.a;
			return name;
		case 5:
			var name = prop.a;
			return name;
		case 6:
			var name = prop.a;
			return name;
		case 7:
			var name = prop.a;
			return name;
		case 8:
			return 'points';
		default:
			return 'path';
	}
};
var $author$project$Animation$Render$isTransformation = function (prop) {
	return A2(
		$elm$core$List$member,
		$author$project$Animation$Model$propertyName(prop),
		_List_fromArray(
			['rotate', 'rotateX', 'rotateY', 'rotateZ', 'rotate3d', 'translate', 'translate3d', 'scale', 'scale3d']));
};
var $elm$core$List$sortBy = _List_sortBy;
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $author$project$Animation$Render$warnForDoubleListedProperties = function (props) {
	var _v0 = A2(
		$elm$core$List$map,
		function (propGroup) {
			var _v1 = $elm$core$List$head(propGroup);
			if (_v1.$ === 1) {
				return '';
			} else {
				var name = _v1.a;
				return ($elm$core$List$length(propGroup) > 1) ? '' : '';
			}
		},
		A2(
			$author$project$Animation$Render$groupWhile,
			$elm$core$Basics$eq,
			$elm$core$List$sort(
				A2(
					$elm$core$List$map,
					$author$project$Animation$Model$propertyName,
					A2(
						$elm$core$List$filter,
						function (prop) {
							return !$author$project$Animation$Render$isTransformation(prop);
						},
						props)))));
	return props;
};
var $author$project$Animation$style = function (props) {
	return $author$project$Animation$initialState(
		A2(
			$elm$core$List$map,
			$author$project$Animation$setDefaultInterpolation,
			$author$project$Animation$Render$warnForDoubleListedProperties(props)));
};
var $author$project$Animation$Radians = $elm$core$Basics$identity;
var $author$project$Animation$deg = function (a) {
	return (a / 360) * (2 * $elm$core$Basics$pi);
};
var $author$project$Animation$rotate = function (_v0) {
	var valX = _v0;
	return A2(
		$author$project$Animation$Model$AngleProperty,
		'rotate',
		A2($author$project$Animation$initMotion, valX, 'rad'));
};
var $author$project$PAM$UI$Basic$toggleStates = {
	fI: _List_fromArray(
		[
			$author$project$Animation$rotate(
			$author$project$Animation$deg(180.0))
		]),
	mR: _List_fromArray(
		[
			$author$project$Animation$rotate(
			$author$project$Animation$deg(-180.0))
		]),
	ev: _List_fromArray(
		[
			$author$project$Animation$rotate(
			$author$project$Animation$deg(0.0))
		])
};
var $author$project$PAM$UI$Basic$greenSmoke = A3($mdgriffith$elm_ui$Element$rgb255, 154, 172, 109);
var $author$project$PAM$UI$Basic$orangeRoughy = A3($mdgriffith$elm_ui$Element$rgb255, 203, 77, 21);
var $author$project$PAM$UI$Basic$pineTree = A3($mdgriffith$elm_ui$Element$rgb255, 32, 61, 5);
var $author$project$PAM$UI$Basic$woodland = A3($mdgriffith$elm_ui$Element$rgb255, 78, 90, 48);
var $author$project$PAM$UI$Palette$utahAction = {
	mB: {G: $author$project$PAM$UI$Basic$orangeRoughy, eE: $author$project$PAM$UI$Basic$white},
	mF: {G: $author$project$PAM$UI$Basic$pineTree, eE: $author$project$PAM$UI$Basic$white},
	cd: {G: $author$project$PAM$UI$Basic$orangeRoughy, eE: $author$project$PAM$UI$Basic$white},
	fK: {G: $author$project$PAM$UI$Basic$greenSmoke, eE: $author$project$PAM$UI$Basic$white},
	iI: {G: $author$project$PAM$UI$Basic$woodland, eE: $author$project$PAM$UI$Basic$white},
	fW: {G: $author$project$PAM$UI$Basic$woodland, eE: $author$project$PAM$UI$Basic$greenSmoke}
};
var $author$project$PAM$UI$Basic$asparagus = A3($mdgriffith$elm_ui$Element$rgb255, 130, 151, 80);
var $author$project$PAM$UI$Palette$utahBackground = {
	mB: {G: $author$project$PAM$UI$Basic$pineTree, eE: $author$project$PAM$UI$Basic$white},
	mF: {G: $author$project$PAM$UI$Basic$codGray, eE: $author$project$PAM$UI$Basic$white},
	cd: {G: $author$project$PAM$UI$Basic$codGray, eE: $author$project$PAM$UI$Basic$white},
	fK: {G: $author$project$PAM$UI$Basic$asparagus, eE: $author$project$PAM$UI$Basic$pineTree},
	iI: {G: $author$project$PAM$UI$Basic$codGray, eE: $author$project$PAM$UI$Basic$white},
	fW: {G: $author$project$PAM$UI$Basic$black, eE: $author$project$PAM$UI$Basic$orangeRoughy}
};
var $author$project$PAM$UI$Palette$utahPalette = {S: $author$project$PAM$UI$Palette$utahAction, cx: $author$project$PAM$UI$Palette$utahBackground};
var $author$project$PAM$UI$Palette$utahActionDetails = {
	mB: {G: $author$project$PAM$UI$Basic$orangeRoughy, eE: $author$project$PAM$UI$Basic$white},
	mF: {G: $author$project$PAM$UI$Basic$white, eE: $author$project$PAM$UI$Basic$black},
	cd: {G: $author$project$PAM$UI$Basic$orangeRoughy, eE: $author$project$PAM$UI$Basic$white},
	fK: {G: $author$project$PAM$UI$Basic$asparagus, eE: $author$project$PAM$UI$Basic$white},
	iI: {G: $author$project$PAM$UI$Basic$white, eE: $author$project$PAM$UI$Basic$white},
	fW: {G: $author$project$PAM$UI$Basic$pineTree, eE: $author$project$PAM$UI$Basic$white}
};
var $author$project$PAM$UI$Palette$utahBackgroundDetails = {
	mB: {G: $author$project$PAM$UI$Basic$pineTree, eE: $author$project$PAM$UI$Basic$white},
	mF: {G: $author$project$PAM$UI$Basic$codGray, eE: $author$project$PAM$UI$Basic$white},
	cd: {G: $author$project$PAM$UI$Basic$codGray, eE: $author$project$PAM$UI$Basic$white},
	fK: {G: $author$project$PAM$UI$Basic$doveGray, eE: $author$project$PAM$UI$Basic$white},
	iI: {G: $author$project$PAM$UI$Basic$codGray, eE: $author$project$PAM$UI$Basic$white},
	fW: {G: $author$project$PAM$UI$Basic$black, eE: $author$project$PAM$UI$Basic$orangeRoughy}
};
var $author$project$PAM$UI$Palette$utahPaletteDetails = {S: $author$project$PAM$UI$Palette$utahActionDetails, cx: $author$project$PAM$UI$Palette$utahBackgroundDetails};
var $author$project$PAM$UI$Palette$utahActionGrid = {
	mB: {G: $author$project$PAM$UI$Basic$orangeRoughy, eE: $author$project$PAM$UI$Basic$white},
	mF: {G: $author$project$PAM$UI$Basic$pineTree, eE: $author$project$PAM$UI$Basic$white},
	cd: {G: $author$project$PAM$UI$Basic$orangeRoughy, eE: $author$project$PAM$UI$Basic$white},
	fK: {G: $author$project$PAM$UI$Basic$woodland, eE: $author$project$PAM$UI$Basic$white},
	iI: {G: $author$project$PAM$UI$Basic$orangeRoughy, eE: $author$project$PAM$UI$Basic$white},
	fW: {
		G: A2($author$project$PAM$UI$Basic$darkenColor, 0.25, $author$project$PAM$UI$Basic$doveGray),
		eE: $author$project$PAM$UI$Basic$white
	}
};
var $author$project$PAM$UI$Palette$utahBackgroundGrid = {
	mB: {G: $author$project$PAM$UI$Basic$doveGray, eE: $author$project$PAM$UI$Basic$white},
	mF: {G: $author$project$PAM$UI$Basic$satinLinen, eE: $author$project$PAM$UI$Basic$black},
	cd: {G: $author$project$PAM$UI$Basic$satinLinen, eE: $author$project$PAM$UI$Basic$black},
	fK: {G: $author$project$PAM$UI$Basic$pineTree, eE: $author$project$PAM$UI$Basic$white},
	iI: {G: $author$project$PAM$UI$Basic$satinLinen, eE: $author$project$PAM$UI$Basic$black},
	fW: {G: $author$project$PAM$UI$Basic$white, eE: $author$project$PAM$UI$Basic$black}
};
var $author$project$PAM$UI$Palette$utahPaletteGrid = {S: $author$project$PAM$UI$Palette$utahActionGrid, cx: $author$project$PAM$UI$Palette$utahBackgroundGrid};
var $author$project$PAM$UI$Palette$utPalette = {bl: $author$project$PAM$UI$Palette$utahPaletteGrid, fa: $author$project$PAM$UI$Palette$utahPalette, j: $author$project$PAM$UI$Palette$utahPalette, iO: $author$project$PAM$UI$Palette$utahPaletteDetails};
var $author$project$PAM$init = F3(
	function (flags, url, key) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PAM$flagsDecoder, flags);
		if (!_v0.$) {
			var flagData = _v0.a;
			var oAuth = A2($author$project$PAM$oAuthConfig, flagData.m4, url);
			var leftSidebarSearchBaseUrl = function () {
				var _v12 = flagData.m4.M.lF;
				if (!_v12.$) {
					var leftSidebarSearchUrl = _v12.a;
					return leftSidebarSearchUrl;
				} else {
					return '';
				}
			}();
			var lssm = {kN: '', lC: $elm$core$Maybe$Nothing, mG: $krisajenkins$remotedata$RemoteData$NotAsked, mH: leftSidebarSearchBaseUrl, ip: $elm$core$Maybe$Nothing};
			var device = $mdgriffith$elm_ui$Element$classifyDevice(flagData.aD);
			var panelDimensions = function () {
				var adjustedHeight = function () {
					var _v11 = flagData.m4.M.d$;
					if ((!_v11.$) && (_v11.a === 'noDrawer')) {
						return 1;
					} else {
						return 2;
					}
				}();
				return A3($author$project$PAM$UI$Basic$calculatePanelDimensions, flagData.aD, device, adjustedHeight);
			}();
			var defaultPalette = function () {
				var _v10 = flagData.m4.M.cj;
				if (!_v10.$) {
					var s = _v10.a;
					return $author$project$PAM$UI$Palette$palePalette;
				} else {
					return $author$project$PAM$UI$Palette$utPalette;
				}
			}();
			var configuredQueryParams = function () {
				var _v9 = flagData.m4.M.d$;
				_v9$2:
				while (true) {
					if (!_v9.$) {
						switch (_v9.a) {
							case 'hide':
								var routeDefaultQueryParams = $author$project$PAM$Route$defaultQueryParams;
								return _Utils_update(
									routeDefaultQueryParams,
									{eY: false});
							case 'noDrawer':
								var routeDefaultQueryParams = $author$project$PAM$Route$defaultQueryParams;
								return _Utils_update(
									routeDefaultQueryParams,
									{eY: false});
							default:
								break _v9$2;
						}
					} else {
						break _v9$2;
					}
				}
				return $author$project$PAM$Route$defaultQueryParams;
			}();
			var bookmarkedRoute = $author$project$PAM$Route$fromUrl(url);
			var route = A2(
				$elm$core$Maybe$withDefault,
				A2(
					$author$project$PAM$Route$initMap,
					$elm$core$Maybe$Just(configuredQueryParams),
					$elm$core$Maybe$Nothing),
				A2(
					$elm$core$Maybe$andThen,
					function (r) {
						if (!r.$) {
							var params = r.a;
							return $elm$core$Maybe$Nothing;
						} else {
							return $elm$core$Maybe$Just(r);
						}
					},
					bookmarkedRoute));
			var queryParams = $author$project$PAM$Route$getQueryParams(route);
			var _v1 = queryParams.iP ? _Utils_Tuple2(
				_Utils_Tuple3(
					$author$project$Animation$style(
						$author$project$PAM$UI$Basic$leftSidebarStates(panelDimensions).em),
					$author$project$Animation$style($author$project$PAM$UI$Basic$toggleStates.ev),
					$author$project$Animation$style(
						A2($author$project$PAM$UI$Basic$riskLegendStates, flagData.aD, panelDimensions).eA)),
				0) : _Utils_Tuple2(
				_Utils_Tuple3(
					$author$project$Animation$style(
						$author$project$PAM$UI$Basic$leftSidebarStates(panelDimensions).dA),
					$author$project$Animation$style($author$project$PAM$UI$Basic$toggleStates.fI),
					$author$project$Animation$style(
						A2($author$project$PAM$UI$Basic$riskLegendStates, flagData.aD, panelDimensions).dV)),
				1);
			var _v2 = _v1.a;
			var sidePanelFx = _v2.a;
			var sidePanelToggleFx = _v2.b;
			var riskLegendFx = _v2.c;
			var sidePanelOpenness = _v1.b;
			var _v3 = $author$project$InPageOAuth$regetAuthorization(
				A3($author$project$InPageOAuth$setParameters, $author$project$InPageOAuth$defaultModel, oAuth, flagData.m4));
			var newOAuthModel = _v3.a;
			var newOAuthCmds = _v3.b;
			var _v4 = $author$project$PAM$UI$DateRangeFilter$init;
			var newDateRangeFilterModel = _v4.a;
			var newDateRangeFilterCmd = _v4.b;
			var _v5 = queryParams.eY ? _Utils_Tuple2(
				_Utils_Tuple3(
					$author$project$Animation$style(
						$author$project$PAM$UI$Basic$bottomDrawerStates(panelDimensions).em),
					$author$project$Animation$style($author$project$PAM$UI$Basic$toggleStates.ev),
					$author$project$Animation$style(
						$author$project$PAM$UI$Basic$leftSidebarStates(panelDimensions).eA)),
				0) : _Utils_Tuple2(
				_Utils_Tuple3(
					$author$project$Animation$style(
						$author$project$PAM$UI$Basic$bottomDrawerStates(panelDimensions).dA),
					$author$project$Animation$style($author$project$PAM$UI$Basic$toggleStates.fI),
					$author$project$Animation$style(
						$author$project$PAM$UI$Basic$leftSidebarStates(panelDimensions).dV)),
				1);
			var _v6 = _v5.a;
			var bottomPanelFx = _v6.a;
			var bottomPanelToggleFx = _v6.b;
			var sidePanelContentFx = _v6.c;
			var bottomPanelOpenness = _v5.b;
			var mdl = {bR: flagData.m4, ar: newOAuthModel, du: bookmarkedRoute, aI: bottomPanelFx, j8: bottomPanelOpenness, aJ: bottomPanelToggleFx, J: flagData.J, dx: false, bo: newDateRangeFilterModel, e: _List_Nil, eV: device, dR: _List_Nil, hf: flags, aN: $author$project$PAM$initGridState, cN: $author$project$PAM$Header$defaultModel, lw: key, aP: lssm, d9: false, h: $author$project$PAM$Map$defaultModel, bA: $author$project$PlugMap$Plugins$Search$defaultModel, j: $author$project$PAM$Modal$NoModal, a: $author$project$PAM$Model$ModelInfo$new, hY: oAuth, b: $author$project$PAM$Object$Repo$new, bE: defaultPalette, fD: panelDimensions, ca: _List_Nil, aV: riskLegendFx, mS: $elm$core$Maybe$Nothing, iE: false, L: $author$project$PAM$defaultSearchModel, R: sidePanelContentFx, aX: sidePanelFx, m8: sidePanelOpenness, aY: sidePanelToggleFx, aq: $author$project$PAM$User$LoggedOut, c9: 1, da: flagData.da, f5: flagData.aD};
			var _v7 = _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
			var blah = _v7.a;
			var blah2 = _v7.b;
			return _Utils_Tuple2(
				$author$project$PAM$AppLoaded(blah),
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2($elm$core$Platform$Cmd$map, $author$project$PAM$authTranslator, newOAuthCmds),
							A2($elm$core$Task$perform, $author$project$PAM$Message$ReceiveDate, $justinmimbs$date$Date$today),
							A2($author$project$PAM$Route$pushUrl, key, route)
						])));
		} else {
			var err = _v0.a;
			var errMsg = $elm$json$Json$Decode$errorToString(err);
			return _Utils_Tuple2(
				$author$project$PAM$AppFailed(errMsg),
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(errMsg)));
		}
	});
var $author$project$PAM$Message$Animate = function (a) {
	return {$: 45, a: a};
};
var $author$project$PAM$Message$HandleAnimationTrigger = function (a) {
	return {$: 46, a: a};
};
var $author$project$PAM$Message$WindowResized = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$PAM$animations = function (model) {
	return _List_fromArray(
		[model.aI, model.aJ, model.aX, model.aY, model.aV]);
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$PAM$Message$DateRangeFilterMsg = function (a) {
	return {$: 73, a: a};
};
var $author$project$PAM$UI$DateRangeFilter$ApplyButtonClicked = {$: 2};
var $author$project$PAM$UI$DateRangeFilter$ClearButtonClicked = {$: 3};
var $author$project$PAM$UI$DateRangeFilter$apiTranslator = F2(
	function (td, msg) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				return td.el(m);
			case 1:
				return td.el($author$project$PAM$UI$DateRangeFilter$ApplyButtonClicked);
			default:
				return td.el($author$project$PAM$UI$DateRangeFilter$ClearButtonClicked);
		}
	});
var $author$project$PAM$dateRangeFilterTranslator = $author$project$PAM$UI$DateRangeFilter$apiTranslator(
	{el: $author$project$PAM$Message$DateRangeFilterMsg});
var $author$project$PAM$Message$ChainLoading = function (a) {
	return {$: 47, a: a};
};
var $author$project$PAM$Message$DecodeError = function (a) {
	return {$: 48, a: a};
};
var $author$project$PAM$Message$GotModelInfo = function (a) {
	return {$: 6, a: a};
};
var $elm_community$result_extra$Result$Extra$mapBoth = F3(
	function (errFunc, okFunc, result) {
		if (!result.$) {
			var ok = result.a;
			return $elm$core$Result$Ok(
				okFunc(ok));
		} else {
			var err = result.a;
			return $elm$core$Result$Err(
				errFunc(err));
		}
	});
var $elm_community$result_extra$Result$Extra$merge = function (r) {
	if (!r.$) {
		var rr = r.a;
		return rr;
	} else {
		var rr = r.a;
		return rr;
	}
};
var $author$project$PAM$Model$ModelInfo$ModelInfo = F5(
	function (objectModels, gridLayouts, detailLayouts, objectTypes, fieldChoices) {
		return {C: detailLayouts, y: fieldChoices, dW: gridLayouts, l: objectModels, c: objectTypes};
	});
var $author$project$PAM$Model$LayoutField$LayoutField = F6(
	function (id, modelId, displayName, displayOrder, display, mode) {
		return {eW: display, eX: displayName, kG: displayOrder, r: id, lP: mode, hU: modelId};
	});
var $author$project$PAM$Model$LayoutField$LayoutFieldMode_None = 0;
var $author$project$PAM$Model$LayoutField$LayoutFieldMode_ReadOnly = 1;
var $author$project$PAM$Model$LayoutField$LayoutFieldMode_Required = 2;
var $author$project$PAM$Model$LayoutField$layoutFieldModeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (i) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (i) {
					case 0:
						return 0;
					case 1:
						return 2;
					case 2:
						return 1;
					default:
						return 0;
				}
			}());
	},
	$elm$json$Json$Decode$int);
var $author$project$PAM$Model$LayoutField$decodeLayoutField = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'mode',
	$author$project$PAM$Model$LayoutField$layoutFieldModeDecoder,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'display',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool),
		$elm$core$Maybe$Just(false),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'displayOrder',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'displayName',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'modelId',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$PAM$Model$LayoutField$LayoutField)))))));
var $author$project$PAM$Model$DetailLayouts$decodeDetailView = $elm$json$Json$Decode$list(
	$elm$json$Json$Decode$list($author$project$PAM$Model$LayoutField$decodeLayoutField));
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples = F2(
	function (keyDecoder, tuples) {
		if (!tuples.b) {
			return $elm$json$Json$Decode$succeed($elm$core$Dict$empty);
		} else {
			var _v1 = tuples.a;
			var strKey = _v1.a;
			var value = _v1.b;
			var rest = tuples.b;
			var _v2 = A2($elm$json$Json$Decode$decodeString, keyDecoder, strKey);
			if (!_v2.$) {
				var key = _v2.a;
				return A2(
					$elm$json$Json$Decode$andThen,
					A2(
						$elm$core$Basics$composeR,
						A2($elm$core$Dict$insert, key, value),
						$elm$json$Json$Decode$succeed),
					A2($elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples, keyDecoder, rest));
			} else {
				var error = _v2.a;
				return $elm$json$Json$Decode$fail(
					$elm$json$Json$Decode$errorToString(error));
			}
		}
	});
var $elm_community$json_extra$Json$Decode$Extra$dict2 = F2(
	function (keyDecoder, valueDecoder) {
		return A2(
			$elm$json$Json$Decode$andThen,
			$elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples(keyDecoder),
			$elm$json$Json$Decode$keyValuePairs(valueDecoder));
	});
var $author$project$PAM$Model$DetailLayouts$detailLayoutsDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (dict) {
		return $elm$json$Json$Decode$succeed(dict);
	},
	A2($elm_community$json_extra$Json$Decode$Extra$dict2, $elm$json$Json$Decode$int, $author$project$PAM$Model$DetailLayouts$decodeDetailView));
var $author$project$PAM$Model$GridLayouts$decodeGridView = $elm$json$Json$Decode$list($author$project$PAM$Model$LayoutField$decodeLayoutField);
var $author$project$PAM$Model$GridLayouts$gridLayoutsDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (dict) {
		return $elm$json$Json$Decode$succeed(dict);
	},
	A2($elm_community$json_extra$Json$Decode$Extra$dict2, $elm$json$Json$Decode$int, $author$project$PAM$Model$GridLayouts$decodeGridView));
var $author$project$PAM$Model$FieldChoices$ChoiceValue = F4(
	function (id, name, tag, type_) {
		return {r: id, fu: name, no: tag, bi: type_};
	});
var $author$project$PAM$Model$FieldChoices$decodeChoiceValue = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'type',
	$elm$json$Json$Decode$int,
	-1,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'tag',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$PAM$Model$FieldChoices$ChoiceValue)))));
var $author$project$PAM$Model$FieldChoices$decodeChoiceValues = $elm$json$Json$Decode$list($author$project$PAM$Model$FieldChoices$decodeChoiceValue);
var $author$project$PAM$Model$FieldChoices$objectChoicesDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (dict) {
		return $elm$json$Json$Decode$succeed(dict);
	},
	A2(
		$elm_community$json_extra$Json$Decode$Extra$dict2,
		$elm$json$Json$Decode$int,
		A2(
			$elm$json$Json$Decode$andThen,
			function (list) {
				return $elm$json$Json$Decode$succeed(
					$elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (item) {
								return _Utils_Tuple2(item.r, item);
							},
							list)));
			},
			$author$project$PAM$Model$FieldChoices$decodeChoiceValues)));
var $author$project$PAM$Model$ObjectModels$ModelField = F5(
	function (id, name, fieldType, limit, reference) {
		return {hb: fieldType, r: id, hJ: limit, fu: name, is: reference};
	});
var $author$project$PAM$Model$ObjectModels$FieldType_Date = 6;
var $author$project$PAM$Model$ObjectModels$FieldType_DateRange = 7;
var $author$project$PAM$Model$ObjectModels$FieldType_File = 14;
var $author$project$PAM$Model$ObjectModels$FieldType_Flag = 8;
var $author$project$PAM$Model$ObjectModels$FieldType_FlagWithComment = 17;
var $author$project$PAM$Model$ObjectModels$FieldType_Float = 4;
var $author$project$PAM$Model$ObjectModels$FieldType_Int = 3;
var $author$project$PAM$Model$ObjectModels$FieldType_LongText = 2;
var $author$project$PAM$Model$ObjectModels$FieldType_Money = 5;
var $author$project$PAM$Model$ObjectModels$FieldType_MultipleChoice = 10;
var $author$project$PAM$Model$ObjectModels$FieldType_MultipleObject = 12;
var $author$project$PAM$Model$ObjectModels$FieldType_Natural = 16;
var $author$project$PAM$Model$ObjectModels$FieldType_None = 0;
var $author$project$PAM$Model$ObjectModels$FieldType_Scores = 19;
var $author$project$PAM$Model$ObjectModels$FieldType_SingleChoice = 9;
var $author$project$PAM$Model$ObjectModels$FieldType_SingleChoiceWithOther = 18;
var $author$project$PAM$Model$ObjectModels$FieldType_SingleObject = 11;
var $author$project$PAM$Model$ObjectModels$FieldType_Spatial = 13;
var $author$project$PAM$Model$ObjectModels$FieldType_Text = 1;
var $author$project$PAM$Model$ObjectModels$FieldType_Year = 15;
var $author$project$PAM$Model$ObjectModels$fieldTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (value) {
					case 0:
						return 1;
					case 1:
						return 2;
					case 2:
						return 3;
					case 3:
						return 4;
					case 4:
						return 5;
					case 5:
						return 6;
					case 6:
						return 8;
					case 7:
						return 9;
					case 8:
						return 10;
					case 9:
						return 11;
					case 10:
						return 12;
					case 11:
						return 13;
					case 12:
						return 14;
					case 13:
						return 15;
					case 14:
						return 7;
					case 15:
						return 16;
					case 16:
						return 17;
					case 17:
						return 18;
					case 18:
						return 19;
					case 19:
						return 13;
					default:
						return 0;
				}
			}());
	},
	$elm$json$Json$Decode$int);
var $author$project$PAM$Model$ObjectModels$modelFieldDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'reference',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'limit',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'type',
			$author$project$PAM$Model$ObjectModels$fieldTypeDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$PAM$Model$ObjectModels$ModelField))))));
var $author$project$PAM$Model$ObjectModels$objectModelsDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (dict) {
		return $elm$json$Json$Decode$succeed(dict);
	},
	A2(
		$elm_community$json_extra$Json$Decode$Extra$dict2,
		$elm$json$Json$Decode$int,
		A2(
			$elm$json$Json$Decode$andThen,
			function (fields) {
				return $elm$json$Json$Decode$succeed(
					A2(
						$elm$core$List$map,
						function (_v0) {
							var a = _v0.a;
							var b = _v0.b;
							return b;
						},
						$elm$core$Dict$toList(fields)));
			},
			$elm$json$Json$Decode$dict($author$project$PAM$Model$ObjectModels$modelFieldDecoder))));
var $author$project$PAM$Model$ObjectTypes$ObjectType = F6(
	function (id, key, singularName, pluralName, display, displayOrder) {
		return {eW: display, kG: displayOrder, r: id, lw: key, ie: pluralName, cZ: singularName};
	});
var $author$project$PAM$Model$ObjectTypes$typeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'display_order',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'display',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'plural_name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'singular_name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'key',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$PAM$Model$ObjectTypes$ObjectType)))))));
var $author$project$PAM$Model$ObjectTypes$objectTypesDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (dict) {
		return $elm$json$Json$Decode$succeed(dict);
	},
	A2($elm_community$json_extra$Json$Decode$Extra$dict2, $elm$json$Json$Decode$int, $author$project$PAM$Model$ObjectTypes$typeDecoder));
var $author$project$PAM$Model$ModelInfo$modelInfoDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'choices',
	$author$project$PAM$Model$FieldChoices$objectChoicesDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'types',
		$author$project$PAM$Model$ObjectTypes$objectTypesDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'detailViews',
			$author$project$PAM$Model$DetailLayouts$detailLayoutsDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'gridViews',
				$author$project$PAM$Model$GridLayouts$gridLayoutsDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'models',
					$author$project$PAM$Model$ObjectModels$objectModelsDecoder,
					$elm$json$Json$Decode$succeed($author$project$PAM$Model$ModelInfo$ModelInfo))))));
var $author$project$PAM$handleGotModelInfo = F2(
	function (model, allTheThings) {
		return $elm_community$result_extra$Result$Extra$merge(
			A3(
				$elm_community$result_extra$Result$Extra$mapBoth,
				function (a) {
					return $author$project$PAM$Message$DecodeError(
						$elm$json$Json$Decode$errorToString(a));
				},
				function (a) {
					return $author$project$PAM$Message$ChainLoading(
						$author$project$PAM$Message$GotModelInfo(a));
				},
				A2($elm$json$Json$Decode$decodeValue, $author$project$PAM$Model$ModelInfo$modelInfoDecoder, allTheThings)));
	});
var $author$project$PAM$Message$SettingsLoaded = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$PAM$handleSettingsLoaded = F2(
	function (model, settings) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$PAM$Message$NoOp,
			A2(
				$elm$core$Maybe$andThen,
				function (results) {
					return $elm$core$Maybe$Just(
						$author$project$PAM$Message$ChainLoading(
							$author$project$PAM$Message$SettingsLoaded(results)));
				},
				$elm$core$Result$toMaybe(
					A2($elm$json$Json$Decode$decodeValue, $author$project$PAM$Settings$appSettingsDecoder, settings))));
	});
var $author$project$PAM$Message$ClickViewObjectDetailsNew = F2(
	function (a, b) {
		return {$: 22, a: a, b: b};
	});
var $author$project$PAM$Message$LeftSidebarSearchMsg = function (a) {
	return {$: 72, a: a};
};
var $author$project$PAM$UI$LeftSidebarSearch$ClickGoButtonClicked = {$: 2};
var $author$project$PAM$UI$LeftSidebarSearch$GotlayerDefs = function (a) {
	return {$: 5, a: a};
};
var $author$project$PAM$UI$LeftSidebarSearch$ZoomToSelectedItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$PAM$UI$LeftSidebarSearch$apiTranslator = F2(
	function (td, msg) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				return td.el(m);
			case 1:
				return td.el($author$project$PAM$UI$LeftSidebarSearch$ClickGoButtonClicked);
			case 3:
				var si = msg.a;
				return td.el(
					$author$project$PAM$UI$LeftSidebarSearch$ZoomToSelectedItem(si));
			case 2:
				var id = msg.a;
				var typeKey = 'fire-incident';
				return A2(td.h2, id, typeKey);
			case 4:
				return td.el($author$project$PAM$UI$LeftSidebarSearch$ClickGoButtonClicked);
			default:
				var layerDefs = msg.a;
				return td.el(
					$author$project$PAM$UI$LeftSidebarSearch$GotlayerDefs(layerDefs));
		}
	});
var $author$project$PAM$leftSidebarSearchFilterTranslator = $author$project$PAM$UI$LeftSidebarSearch$apiTranslator(
	{
		h2: function (a) {
			return function (t) {
				return A2($author$project$PAM$Message$ClickViewObjectDetailsNew, a, t);
			};
		},
		el: $author$project$PAM$Message$LeftSidebarSearchMsg
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$PAM$Message$APIUnauthorizedHandler = function (a) {
	return {$: 61, a: a};
};
var $author$project$PAM$Message$API_GetMapServices = {$: 10};
var $author$project$PAM$Message$MapMsg = function (a) {
	return {$: 50, a: a};
};
var $author$project$PAM$Map$apiTranslator = F2(
	function (td, msg) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				return td.el(m);
			case 1:
				var id = msg.a;
				var typeKey = msg.b;
				return A2(td.l7, id, typeKey);
			default:
				return td.l_;
		}
	});
var $author$project$PAM$mapTranslater = $author$project$PAM$Map$apiTranslator(
	{
		l_: $author$project$PAM$Message$APIUnauthorizedHandler($author$project$PAM$Message$API_GetMapServices),
		l7: function (a) {
			return function (t) {
				return A2($author$project$PAM$Message$ClickViewObjectDetailsNew, a, t);
			};
		},
		el: $author$project$PAM$Message$MapMsg
	});
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {id: pids, iX: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {g6: event, lw: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.id,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.lw;
		var event = _v0.g6;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.iX);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Ports$returnModelInfo = _Platform_incomingPort('returnModelInfo', $elm$json$Json$Decode$value);
var $author$project$Ports$settingsLoaded = _Platform_incomingPort('settingsLoaded', $elm$json$Json$Decode$value);
var $author$project$PAM$Message$ChangeObjectDetails = function (a) {
	return {$: 7, a: a};
};
var $author$project$PAM$Message$SetLayer = function (a) {
	return {$: 8, a: a};
};
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Fields$Shared$Required = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Object$FieldValue$FieldValue_Text = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Object$FieldValue$get = F2(
	function (_v0, _v1) {
		var name = _v0.fu;
		var values = _v1;
		return A2($elm$core$Dict$get, name, values);
	});
var $author$project$PAM$EditableFields$stringToMaybeString = function (input) {
	return ($elm$core$String$length(input) > 0) ? $elm$core$Maybe$Just(input) : $elm$core$Maybe$Nothing;
};
var $author$project$PAM$EditableFields$validityValid = function (v) {
	if (v.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$PAM$EditableFields$getValidationForField = F2(
	function (object, field) {
		var value = (field.r >= 0) ? A2($author$project$PAM$Object$FieldValue$get, field, object.au) : $elm$core$Maybe$Just(
			$author$project$PAM$Object$FieldValue$FieldValue_Text(
				$author$project$PAM$EditableFields$stringToMaybeString(object.fu)));
		if (value.$ === 1) {
			return false;
		} else {
			var val = value.a;
			switch (val.$) {
				case 1:
					var text = val.a;
					return !_Utils_eq(text, $elm$core$Maybe$Nothing);
				case 10:
					var text = val.a;
					return !_Utils_eq(text, $elm$core$Maybe$Nothing);
				case 7:
					var text = val.a;
					if (text.$ === 1) {
						var start = text.a;
						return true;
					} else {
						return false;
					}
				case 8:
					var text = val.a;
					switch (text.$) {
						case 0:
							return false;
						case 2:
							return false;
						default:
							var range = text.a;
							var str = text.b;
							var _v4 = _Utils_Tuple2(range.iT, range.e0);
							_v4$2:
							while (true) {
								switch (_v4.a.$) {
									case 2:
										return false;
									case 0:
										switch (_v4.b.$) {
											case 2:
												break _v4$2;
											case 0:
												var _v7 = _v4.a;
												var _v8 = _v4.b;
												return false;
											default:
												var _v10 = _v4.b;
												var end = _v10.a;
												return true;
										}
									default:
										switch (_v4.b.$) {
											case 2:
												break _v4$2;
											case 1:
												var _v5 = _v4.a;
												var start = _v5.a;
												var _v6 = _v4.b;
												var end = _v6.a;
												return true;
											default:
												var _v9 = _v4.a;
												var start = _v9.a;
												return true;
										}
								}
							}
							return false;
					}
				case 3:
					var text = val.a;
					return $author$project$PAM$EditableFields$validityValid(text);
				case 4:
					var text = val.a;
					if (text.$ === 1) {
						var nat = text.a;
						return (nat >= 0) ? true : false;
					} else {
						return false;
					}
				case 5:
					var v = val.a;
					if (v.$ === 1) {
						return true;
					} else {
						return false;
					}
				case 9:
					var text = val.a;
					return !_Utils_eq(text, $elm$core$Maybe$Nothing);
				case 2:
					var text = val.a;
					return !_Utils_eq(text, $elm$core$Maybe$Nothing);
				case 6:
					var v = val.a;
					return $author$project$PAM$EditableFields$validityValid(v);
				case 11:
					var text = val.a;
					return !_Utils_eq(text, $elm$core$Maybe$Nothing);
				case 12:
					var choice = val.a;
					if ((!choice.$) && (choice.a.$ === 2)) {
						if (choice.a.a.$ === 1) {
							var cho = choice.a.a.a;
							return true;
						} else {
							var cho = choice.a.a.a;
							return !_Utils_eq(cho, $elm$core$Maybe$Nothing);
						}
					} else {
						return false;
					}
				case 13:
					var obj = val.a;
					return !_Utils_eq(obj, $elm$core$Maybe$Nothing);
				case 14:
					var objs = val.a;
					return !_Utils_eq(objs, $elm$core$Maybe$Nothing);
				case 15:
					var year = val.a;
					if (year.$ === 1) {
						var y = year.a;
						return (y >= 1970) && (y <= 2099);
					} else {
						return false;
					}
				case 16:
					var flag = val.a;
					if (!flag.$) {
						if (!flag.a.$) {
							var comment = flag.a.a;
							return !_Utils_eq(comment, $elm$core$Maybe$Nothing);
						} else {
							return true;
						}
					} else {
						return false;
					}
				case 18:
					var scores = val.a;
					return !_Utils_eq(scores, $elm$core$Maybe$Nothing);
				case 0:
					return false;
				case 19:
					return false;
				default:
					var v = val.a;
					if (v.$ === 1) {
						return true;
					} else {
						return false;
					}
			}
		}
	});
var $author$project$PAM$EditableFields$setGenericFieldByLayoutFieldMode = F2(
	function (mode, field) {
		switch (mode) {
			case 2:
				return _Utils_update(
					field,
					{
						eI: $author$project$Fields$Shared$Required(
							function (a) {
								return A2($author$project$PAM$EditableFields$getValidationForField, a, field.eC);
							})
					});
			case 1:
				var basicInfo = field.a$;
				var newBasicInfo = _Utils_update(
					basicInfo,
					{ir: true});
				return _Utils_update(
					field,
					{a$: newBasicInfo});
			default:
				return field;
		}
	});
var $author$project$PAM$EditableFields$setGenericFieldDisplayName = F2(
	function (maybeDisplayname, field) {
		var obi = field.a$;
		var nbi = _Utils_update(
			obi,
			{
				fu: A2($elm$core$Maybe$withDefault, obi.fu, maybeDisplayname)
			});
		return _Utils_update(
			field,
			{a$: nbi});
	});
var $author$project$PAM$EditableFields$detailLayoutRowToGridRow = F2(
	function (row, fields) {
		return A2(
			$elm$core$List$filterMap,
			function (layoutField) {
				return A2(
					$elm$core$Maybe$map,
					$author$project$PAM$EditableFields$setGenericFieldDisplayName(layoutField.eX),
					A2(
						$elm$core$Maybe$map,
						$author$project$PAM$EditableFields$setGenericFieldByLayoutFieldMode(layoutField.lP),
						A2(
							$elm_community$list_extra$List$Extra$find,
							function (genericField) {
								return _Utils_eq(
									$elm$core$Maybe$Just(genericField.eC.r),
									layoutField.hU);
							},
							fields)));
			},
			row);
	});
var $author$project$PAM$EditableFields$detailLayoutToGrid = F2(
	function (layout, fields) {
		return A2(
			$elm$core$List$map,
			function (row) {
				return A2($author$project$PAM$EditableFields$detailLayoutRowToGridRow, row, fields);
			},
			layout);
	});
var $author$project$Fields$Shared$NoValidation = {$: 0};
var $author$project$PAM$Model$ObjectTypes$get = F2(
	function (id, _v0) {
		var types = _v0;
		return A2($elm$core$Dict$get, id, types);
	});
var $author$project$Fields$GenericField$Custom = function (a) {
	return {$: 12, a: a};
};
var $author$project$Fields$GenericField$CustomColumnNoLabel = function (a) {
	return {$: 14, a: a};
};
var $author$project$Fields$GenericField$Date = function (a) {
	return {$: 4, a: a};
};
var $author$project$PAM$Object$FieldValue$FieldValue_None = {$: 0};
var $author$project$Fields$GenericField$FlagWithComment = function (a) {
	return {$: 8, a: a};
};
var $author$project$Fields$GenericField$Geometry = function (a) {
	return {$: 10, a: a};
};
var $author$project$Fields$GenericField$MaybeDateRange = function (a) {
	return {$: 5, a: a};
};
var $author$project$Fields$GenericField$MaybeFlag = function (a) {
	return {$: 7, a: a};
};
var $author$project$Fields$GenericField$MaybeFloat = function (a) {
	return {$: 3, a: a};
};
var $author$project$Fields$GenericField$MaybeInt = function (a) {
	return {$: 2, a: a};
};
var $author$project$Fields$GenericField$MaybeLongString = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$GenericField$MaybeString = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$Shared$NotEntered = {$: 0};
var $author$project$Fields$GenericField$SingleChoice = function (a) {
	return {$: 6, a: a};
};
var $author$project$Fields$GenericField$SingleChoiceWithOther = function (a) {
	return {$: 9, a: a};
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$PAM$Model$FieldChoices$allFor = function (choices) {
	return $elm$core$Dict$values(choices);
};
var $author$project$PAM$Object$FieldValue$FieldValue_Date = function (a) {
	return {$: 7, a: a};
};
var $author$project$PAM$Object$FieldValue$FieldValues = $elm$core$Basics$identity;
var $author$project$PAM$Object$FieldValue$insert = F3(
	function (_v0, value, _v1) {
		var name = _v0.fu;
		var values = _v1;
		return A3($elm$core$Dict$insert, name, value, values);
	});
var $author$project$PAM$EditableFields$updateGeneric = F4(
	function (field, value, object, updateTarget) {
		return updateTarget(
			_Utils_update(
				object,
				{
					au: A3($author$project$PAM$Object$FieldValue$insert, field, value, object.au)
				}));
	});
var $author$project$PAM$EditableFields$dateField = F3(
	function (field, value, updateTarget) {
		return {
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_Date(set_value),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$Misc$DateRange = F2(
	function (start, end) {
		return {e0: end, iT: start};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_DateRange = function (a) {
	return {$: 8, a: a};
};
var $author$project$Fields$Shared$Invalid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Fields$Shared$Valid = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {gU: d, ef: m, cs: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		fA: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		cs: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.cs, 0, date.fA);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.gU;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.ef;
	});
var $author$project$NormalDate$monthToInt = function (mnth) {
	switch (mnth) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$NormalDate$dateToNormalString = function (date) {
	return A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			$author$project$NormalDate$monthToInt(
				$justinmimbs$date$Date$month(date)))) + ('/' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			$justinmimbs$date$Date$day(date))) + ('/' + $elm$core$String$fromInt(
		$justinmimbs$date$Date$year(date)))));
};
var $author$project$PAM$Misc$dateRangeToString = function (range) {
	var _v0 = _Utils_Tuple2(range.iT, range.e0);
	if (_v0.a.$ === 1) {
		if (_v0.b.$ === 1) {
			var _v1 = _v0.a;
			var st = _v1.a;
			var _v2 = _v0.b;
			var ed = _v2.a;
			return $author$project$NormalDate$dateToNormalString(st) + (' - ' + $author$project$NormalDate$dateToNormalString(ed));
		} else {
			var _v3 = _v0.a;
			var st = _v3.a;
			return $author$project$NormalDate$dateToNormalString(st) + ' - ';
		}
	} else {
		if (_v0.b.$ === 1) {
			var _v4 = _v0.b;
			var st = _v4.a;
			return ' - ' + $author$project$NormalDate$dateToNormalString(st);
		} else {
			return '';
		}
	}
};
var $author$project$PAM$EditableFields$dateRangeField = F3(
	function (field, value, updateTarget) {
		return {
			m3: function (item) {
				return function (_v0) {
					var start = _v0.a;
					var end = _v0.b;
					var st = start;
					var ed = end;
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_DateRange(
							function () {
								var _v1 = _Utils_Tuple2(st, ed);
								_v1$2:
								while (true) {
									_v1$3:
									while (true) {
										switch (_v1.a.$) {
											case 0:
												switch (_v1.b.$) {
													case 0:
														var _v2 = _v1.a;
														var _v3 = _v1.b;
														return $author$project$Fields$Shared$NotEntered;
													case 2:
														break _v1$2;
													default:
														break _v1$3;
												}
											case 2:
												var v = _v1.a.a;
												return $author$project$Fields$Shared$Invalid('asd');
											default:
												if (_v1.b.$ === 2) {
													break _v1$2;
												} else {
													break _v1$3;
												}
										}
									}
									return A2(
										$author$project$Fields$Shared$Valid,
										A2($author$project$PAM$Misc$DateRange, st, ed),
										$author$project$PAM$Misc$dateRangeToString(
											A2($author$project$PAM$Misc$DateRange, st, ed)));
								}
								var v = _v1.b.a;
								return $author$project$Fields$Shared$Invalid('asdad');
							}()),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_Flag = function (a) {
	return {$: 9, a: a};
};
var $author$project$PAM$EditableFields$flagField = F5(
	function (field, value, yesValue, noValue, updateTarget) {
		return {
			hj: function (item) {
				return _Utils_Tuple2(value, 'Not Set');
			},
			hX: noValue,
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_Flag(set_value),
						item,
						updateTarget);
				};
			},
			jt: yesValue
		};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_FlagWithComment = function (a) {
	return {$: 16, a: a};
};
var $author$project$PAM$Object$FieldValue$No = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Object$FieldValue$Yes = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$EditableFields$flagWithCommentField = F6(
	function (field, value, yesValue, noValue, limit, updater) {
		return {
			hj: function (item) {
				return {
					gN: function () {
						if (!value.$) {
							if (!value.a.$) {
								var comment = value.a.a;
								return comment;
							} else {
								var comment = value.a.a;
								return comment;
							}
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}(),
					fu: field.fu,
					hX: noValue,
					aG: function () {
						if (!value.$) {
							if (!value.a.$) {
								return $elm$core$Maybe$Just(true);
							} else {
								return $elm$core$Maybe$Just(false);
							}
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}(),
					jt: yesValue
				};
			},
			hJ: limit,
			m3: function (item) {
				return function (checked) {
					return function (comment) {
						var set_value = function () {
							if (!checked.$) {
								if (checked.a) {
									return $elm$core$Maybe$Just(
										$author$project$PAM$Object$FieldValue$Yes(comment));
								} else {
									return $elm$core$Maybe$Just(
										$author$project$PAM$Object$FieldValue$No(comment));
								}
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}();
						return A4(
							$author$project$PAM$EditableFields$updateGeneric,
							field,
							$author$project$PAM$Object$FieldValue$FieldValue_FlagWithComment(set_value),
							item,
							updater);
					};
				};
			}
		};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_Float = function (a) {
	return {$: 5, a: a};
};
var $author$project$PAM$EditableFields$floatField = F4(
	function (field, limit, value, updateTarget) {
		return {
			hj: function (item) {
				return _Utils_Tuple2(value, 'Not Set');
			},
			hJ: limit,
			cc: $elm$core$Maybe$Nothing,
			mJ: $elm$core$Maybe$Nothing,
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_Float(set_value),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$Object$FieldValue$Exact = 1;
var $author$project$PAM$Object$FieldValue$FieldValue_Spatial = function (a) {
	return {$: 17, a: a};
};
var $author$project$PAM$EditableFields$geometrySetter = F4(
	function (field, set_value, updateMsg, data) {
		return A4(
			$author$project$PAM$EditableFields$updateGeneric,
			field,
			$author$project$PAM$Object$FieldValue$FieldValue_Spatial(
				function () {
					switch (set_value.$) {
						case 1:
							var geo = set_value.a;
							var text = set_value.b;
							return A2(
								$author$project$Fields$Shared$Valid,
								{
									U: $elm$core$Maybe$Just(geo.U),
									bi: 1,
									aj: geo.aj
								},
								text);
						case 2:
							var text = set_value.a;
							return $author$project$Fields$Shared$Invalid(text);
						default:
							return $author$project$Fields$Shared$NotEntered;
					}
				}()),
			data,
			updateMsg);
	});
var $author$project$PAM$EditableFields$geometryField = F4(
	function (field, value, updateTarget, setLayer) {
		return {
			hj: function (item) {
				return $author$project$Fields$Shared$NotEntered;
			},
			fO: setLayer,
			m3: function (item) {
				return function (set_value) {
					return A4($author$project$PAM$EditableFields$geometrySetter, field, set_value, updateTarget, item);
				};
			}
		};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_Int = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$EditableFields$intField = F4(
	function (field, limit, value, updateTarget) {
		return {
			hj: function (item) {
				return _Utils_Tuple2(value, 'Not Set');
			},
			hJ: limit,
			mJ: $elm$core$Maybe$Nothing,
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_Int(set_value),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$Message$LinkExistingObject = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$PAM$Message$UnlinkObject = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$PAM$Message$ZoomToObjectRef = function (a) {
	return {$: 31, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Left = 0;
var $mdgriffith$elm_ui$Element$alignLeft = $mdgriffith$elm_ui$Internal$Model$AlignX(0);
var $mdgriffith$elm_ui$Internal$Model$Right = 2;
var $mdgriffith$elm_ui$Element$alignRight = $mdgriffith$elm_ui$Internal$Model$AlignX(2);
var $author$project$PAM$UI$Basic$borders = {cz: 0, cR: 0, cX: 0, c3: 0};
var $mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Button = {$: 8};
var $mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsEl = 2;
var $mdgriffith$elm_ui$Internal$Model$asEl = 2;
var $mdgriffith$elm_ui$Internal$Style$classes = {jy: 'a', ct: 'atv', jC: 'ab', jD: 'cx', jE: 'cy', jF: 'acb', jG: 'accx', jH: 'accy', jI: 'acr', gf: 'al', gg: 'ar', jK: 'at', eK: 'ah', eL: 'av', jP: 's', jY: 'bh', jZ: 'b', j0: 'w7', j2: 'bd', j3: 'bdt', dv: 'bn', j5: 'bs', dz: 'cpe', kj: 'cp', kk: 'cpx', kl: 'cpy', a1: 'c', dD: 'ctr', dE: 'cb', dF: 'ccx', a2: 'ccy', cC: 'cl', dG: 'cr', ko: 'ct', ks: 'cptr', kt: 'ctxt', kW: 'fcs', kZ: 'fs', aM: 'g', e9: 'hbh', dZ: 'hc', fc: 'hf', hm: 'hfp', k6: 'hv', le: 'ic', lg: 'fr', ll: 'iml', ab: 'it', lu: 'i', b7: 'nb', hW: 'notxt', l8: 'ol', me: 'or', bC: 'oq', mr: 'oh', h8: 'pg', ia: 'p', mu: 'ppe', iA: 'ui', iC: 'r', mX: 'sb', mY: 'sbx', mZ: 'sby', m_: 'sbt', m9: 'e', na: 'cap', nb: 'sev', nj: 'sk', eE: 't', nq: 'tc', nr: 'w8', ns: 'w2', nt: 'w9', nu: 'tj', eF: 'tja', nv: 'tl', nw: 'w3', nx: 'w5', ny: 'w4', nz: 'tr', nA: 'w6', nB: 'w1', nC: 'tun', i7: 'ts', bO: 'clr', nN: 'u', f3: 'wc', jo: 'we', f4: 'wf', jp: 'wfp', f6: 'wrp'};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $mdgriffith$elm_ui$Internal$Model$Generic = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$div = $mdgriffith$elm_ui$Internal$Model$Generic;
var $mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$columnClass = $mdgriffith$elm_ui$Internal$Style$classes.jP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.a1);
var $mdgriffith$elm_ui$Internal$Model$gridClass = $mdgriffith$elm_ui$Internal$Style$classes.jP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.aM);
var $mdgriffith$elm_ui$Internal$Model$pageClass = $mdgriffith$elm_ui$Internal$Style$classes.jP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.h8);
var $mdgriffith$elm_ui$Internal$Model$paragraphClass = $mdgriffith$elm_ui$Internal$Style$classes.jP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ia);
var $mdgriffith$elm_ui$Internal$Model$rowClass = $mdgriffith$elm_ui$Internal$Style$classes.jP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.iC);
var $mdgriffith$elm_ui$Internal$Model$singleClass = $mdgriffith$elm_ui$Internal$Style$classes.jP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.m9);
var $mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Model$rowClass;
		case 1:
			return $mdgriffith$elm_ui$Internal$Model$columnClass;
		case 2:
			return $mdgriffith$elm_ui$Internal$Model$singleClass;
		case 3:
			return $mdgriffith$elm_ui$Internal$Model$gridClass;
		case 4:
			return $mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return $mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							$elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsParagraph = 4;
var $mdgriffith$elm_ui$Internal$Model$asParagraph = 4;
var $mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? $mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : $mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var $mdgriffith$elm_ui$Internal$Flag$alignBottom = $mdgriffith$elm_ui$Internal$Flag$flag(41);
var $mdgriffith$elm_ui$Internal$Flag$alignRight = $mdgriffith$elm_ui$Internal$Flag$flag(40);
var $mdgriffith$elm_ui$Internal$Flag$centerX = $mdgriffith$elm_ui$Internal$Flag$flag(42);
var $mdgriffith$elm_ui$Internal$Flag$centerY = $mdgriffith$elm_ui$Internal$Flag$flag(43);
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 0:
			var px = x.a;
			return $elm$core$String$fromInt(px) + 'px';
		case 1:
			return 'auto';
		case 2:
			var i = x.a;
			return $elm$core$String$fromInt(i) + 'fr';
		case 3:
			var min = x.a;
			var len = x.b;
			return 'min' + ($elm$core$String$fromInt(min) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + ($elm$core$String$fromInt(max) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var $mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(x * 255));
};
var $mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'mv-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			return $elm$core$Maybe$Just(
				'tfrm-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 13:
			var name = style.a;
			return name;
		case 12:
			var name = style.a;
			var o = style.b;
			return name;
		case 0:
			var _class = style.a;
			return _class;
		case 1:
			var name = style.a;
			return name;
		case 2:
			var i = style.a;
			return 'font-size-' + $elm$core$String$fromInt(i);
		case 3:
			var _class = style.a;
			return _class;
		case 4:
			var _class = style.a;
			return _class;
		case 5:
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 7:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 6:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 8:
			var template = style.a;
			return 'grid-rows-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.mT)) + ('-cols-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.kn)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.nc.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.nc.b)))))));
		case 9:
			var pos = style.a;
			return 'gp grid-pos-' + ($elm$core$String$fromInt(pos.iC) + ('-' + ($elm$core$String$fromInt(pos.gK) + ('-' + ($elm$core$String$fromInt(pos.eJ) + ('-' + $elm$core$String$fromInt(pos.fb)))))));
		case 11:
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector) {
					case 0:
						return 'fs';
					case 1:
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (sty) {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_v1 === '') {
							return '';
						} else {
							var styleName = _v1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = $mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2($elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2($elm$core$Set$insert, styleName, cache),
			A2($elm$core$List$cons, style, existing));
	});
var $mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $mdgriffith$elm_ui$Internal$Model$formatColor = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return 'rgba(' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(red * 255)) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(green * 255))) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(blue * 255))) + (',' + ($elm$core$String$fromFloat(alpha) + ')')))));
};
var $mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.hw ? $elm$core$Maybe$Just('inset') : $elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.lY.a) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.lY.b) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.as) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.aD) + 'px'),
					$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.G))
				])));
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$Style,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP) + (':focus .focusable, ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP) + '.focusable:focus')),
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$map,
					function (color) {
						return A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'border-color',
							$mdgriffith$elm_ui$Internal$Model$formatColor(color));
					},
					focus.j1),
					A2(
					$elm$core$Maybe$map,
					function (color) {
						return A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'background-color',
							$mdgriffith$elm_ui$Internal$Model$formatColor(color));
					},
					focus.jV),
					A2(
					$elm$core$Maybe$map,
					function (shadow) {
						return A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'box-shadow',
							$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
								{
									as: shadow.as,
									G: shadow.G,
									hw: false,
									lY: A2(
										$elm$core$Tuple$mapSecond,
										$elm$core$Basics$toFloat,
										A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.lY)),
									aD: shadow.aD
								}));
					},
					focus.m5),
					$elm$core$Maybe$Just(
					A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
				])));
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Left = 3;
var $mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Right = 2;
var $mdgriffith$elm_ui$Internal$Style$Self = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Content = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Bottom = 1;
var $mdgriffith$elm_ui$Internal$Style$CenterX = 4;
var $mdgriffith$elm_ui$Internal$Style$CenterY = 5;
var $mdgriffith$elm_ui$Internal$Style$Top = 0;
var $mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ko);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dE);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dG);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cC);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dF);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.a2);
	}
};
var $mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jK);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jC);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gg);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gf);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jD);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jE);
	}
};
var $mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _v0 = values(alignment);
		var content = _v0.a;
		var indiv = _v0.b;
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$contentName(alignment),
				content),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						indiv)
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e9),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.m_),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eE),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fc),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f4),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dZ),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fc),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f4),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f3),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		$mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 4:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var $mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						values(alignment))
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$Above = 0;
var $mdgriffith$elm_ui$Internal$Style$Behind = 5;
var $mdgriffith$elm_ui$Internal$Style$Below = 1;
var $mdgriffith$elm_ui$Internal$Style$OnLeft = 3;
var $mdgriffith$elm_ui$Internal$Style$OnRight = 2;
var $mdgriffith$elm_ui$Internal$Style$Within = 4;
var $mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = 0;
	var _v0 = function () {
		switch (loc) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 0;
			case 3:
				return 0;
			case 4:
				return 0;
			default:
				return 0;
		}
	}();
	return _List_fromArray(
		[0, 1, 2, 3, 4, 5]);
}();
var $mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
			_Utils_ap(
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.m9),
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.le))),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP) + ':focus',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iA),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fc)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fc),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lg),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b7),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b7),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.m9),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				$mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2($elm$core$List$map, fn, $mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc) {
							case 0:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jy),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fc),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f4),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 1:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jZ),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fc),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 2:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.me),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 3:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l8),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 4:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lg),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jY),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f6),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hW),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ks),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kt),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.mu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dz),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bO),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bC),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.k6, $mdgriffith$elm_ui$Internal$Style$classes.bO)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.k6, $mdgriffith$elm_ui$Internal$Style$classes.bC)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.kW, $mdgriffith$elm_ui$Internal$Style$classes.bO)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.kW, $mdgriffith$elm_ui$Internal$Style$classes.bC)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.ct, $mdgriffith$elm_ui$Internal$Style$classes.bO)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.ct, $mdgriffith$elm_ui$Internal$Style$classes.bC)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.i7),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							$elm$core$String$join,
							', ',
							A2(
								$elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.mX),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.mY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iC),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.mZ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.a1),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.m9),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kj),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kk),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kl),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j2),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j5),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eE),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ab),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.m9),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iC),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jo),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fc),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hm),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f4),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dD),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jI,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jG,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jD),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jG,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jD),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jG,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jG + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jI + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.jG)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.a1),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fc),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f4),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jp),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f3),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jF,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jH,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jH,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jH,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jH + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jF + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.jH)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dD),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aM),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 1:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 2:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 3:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 4:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP + ':first-child'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.jP + ($mdgriffith$elm_ui$Internal$Style$selfName(3) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.jP))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.jP + ($mdgriffith$elm_ui$Internal$Style$selfName(2) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.jP))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ll),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ia),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e9),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eE),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.m9),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lg),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jy),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jZ),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.me),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iC),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.a1),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aM),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nB),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ns),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nw),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ny),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nx),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nA),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j0),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nr),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nt),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nj),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nN),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nN),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nj)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nC),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eF),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nq),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nz),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var $mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var $mdgriffith$elm_ui$Internal$Style$commonValues = $elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + $elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							$elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 6)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 8, 32)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var $mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + ($mdgriffith$elm_ui$Internal$Style$classes.jP + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + ($mdgriffith$elm_ui$Internal$Style$classes.jP + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var $mdgriffith$elm_ui$Internal$Style$sliderOverrides = '\n\n/* General Input Reset */\ninput[type=range] {\n  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */\n  /* width: 100%;  Specific width is required for Firefox. */\n  background: transparent; /* Otherwise white in Chrome */\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n\n/* Hide all syling for track */\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n\n/* Thumbs */\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var $mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iC) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP) + (' { flex-basis: auto !important; } ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iC) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dD) + (' { flex-basis: auto !important; }}' + ($mdgriffith$elm_ui$Internal$Style$sliderOverrides + $mdgriffith$elm_ui$Internal$Style$explainer))))))))))));
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $mdgriffith$elm_ui$Internal$Style$Intermediate = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return {dB: closing, Q: _List_Nil, bc: _List_Nil, aW: selector};
	});
var $mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_v0, rulesToRender) {
		var parent = _v0;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 0:
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								bc: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.bc)
							});
					case 2:
						var _v2 = rule.a;
						var prop = _v2.a;
						var value = _v2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								Q: A2(
									$elm$core$List$cons,
									{dB: '\n}', Q: _List_Nil, bc: props, aW: '@supports (' + (prop + (':' + (value + (') {' + parent.aW))))},
									rendered.Q)
							});
					case 4:
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								Q: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aW + (' + ' + selector), ''),
										adjRules),
									rendered.Q)
							});
					case 1:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								Q: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aW + (' > ' + child), ''),
										childRules),
									rendered.Q)
							});
					case 3:
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								Q: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											$mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.aW, descriptor),
											''),
										descriptorRules),
									rendered.Q)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								Q: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aW, ''),
										batched),
									rendered.Q)
							});
				}
			});
		return A3($elm$core$List$foldr, generateIntermediates, parent, rulesToRender);
	});
var $mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _v2 = rule.bc;
		if (!_v2.b) {
			return '';
		} else {
			return rule.aW + ('{' + (renderValues(rule.bc) + (rule.dB + '}')));
		}
	};
	var renderIntermediate = function (_v0) {
		var rule = _v0;
		return _Utils_ap(
			renderClass(rule),
			$elm$core$String$concat(
				A2($elm$core$List$map, renderIntermediate, rule.Q)));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$map,
			renderIntermediate,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v1, existing) {
						var name = _v1.a;
						var styleRules = _v1.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Internal$Style$renderRules,
								A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var $mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	$mdgriffith$elm_ui$Internal$Style$overrides,
	$mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap($mdgriffith$elm_ui$Internal$Style$baseSheet, $mdgriffith$elm_ui$Internal$Style$commonValues)));
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $mdgriffith$elm_ui$Internal$Model$staticRoot = A3(
	$elm$virtual_dom$VirtualDom$node,
	'style',
	_List_Nil,
	_List_fromArray(
		[
			$elm$virtual_dom$VirtualDom$text($mdgriffith$elm_ui$Internal$Style$rules)
		]));
var $mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 0:
			return 'serif';
		case 1:
			return 'sans-serif';
		case 2:
			return 'monospace';
		case 3:
			var name = font.a;
			return '\"' + (name + '\"');
		case 4:
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.fu;
			return '\"' + (name + '\"');
	}
};
var $mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return name === 'smcp';
		case 1:
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var $mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.ji);
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _v0, existing) {
		var key = _v0.a;
		var val = _v0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var $mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_v0) {
			var name = _v0.a;
			var val = _v0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			$elm$core$String$join,
			'',
			A2($elm$core$List$map, renderPair, rules)) + '}'));
	});
var $mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _v0) {
		var parentAdj = _v0.a;
		var textAdjustment = _v0.b;
		return _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.eE + (', .' + (name + (' .' + (modifier + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.eE)))))))))), textAdjustment)
			]);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _v0, otherFontName) {
		var full = _v0.a;
		var capital = _v0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.na, capital),
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.kZ, full)));
	});
var $mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.na + (', ' + ('.' + (name + (' .' + $mdgriffith$elm_ui$Internal$Style$classes.na))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.na + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.eE + (', .' + (name + (' .' + ($mdgriffith$elm_ui$Internal$Style$classes.na + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.eE)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {fb: height / size, aD: size, jj: vertical};
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.kc, adjustment.jX, adjustment.kC, adjustment.lI]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.kC,
		$elm$core$List$minimum(lines));
	var newBaseline = A2(
		$elm$core$Maybe$withDefault,
		adjustment.jX,
		$elm$core$List$minimum(
			A2(
				$elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.kc,
		$elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		kc: A3($mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		dV: A3($mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var $mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				$elm$core$String$fromFloat(converted.fb)),
				_Utils_Tuple2(
				'vertical-align',
				$elm$core$String$fromFloat(converted.jj) + 'em'),
				_Utils_Tuple2(
				'font-size',
				$elm$core$String$fromFloat(converted.aD) + 'em')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 1) {
					if (face.$ === 5) {
						var _with = face.a;
						var _v2 = _with.jB;
						if (_v2.$ === 1) {
							return found;
						} else {
							var adjustment = _v2.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.dV;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.kc;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		$elm$core$Maybe$Nothing,
		typefaces);
};
var $mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 4) {
			var url = font.b;
			return $elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_v2) {
		var name = _v2.a;
		var typefaces = _v2.b;
		var imports = A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2($elm$core$List$map, $elm$core$Tuple$first, rules);
	var fontAdjustments = function (_v1) {
		var name = _v1.a;
		var typefaces = _v1.b;
		var _v0 = $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_v0.$ === 1) {
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					A2($mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontImports, rules)),
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontAdjustments, rules)));
};
var $mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return '\"' + (name + '\"');
		case 1:
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + $elm$core$String$fromInt(index)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$renderVariant, font.ji)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 1) {
		var name = rule.a;
		var typefaces = rule.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'translate3d(' + ($elm$core$String$fromFloat(x) + ('px, ' + ($elm$core$String$fromFloat(y) + ('px, ' + ($elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + ($elm$core$String$fromFloat(tx) + ('px, ' + ($elm$core$String$fromFloat(ty) + ('px, ' + ($elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + ($elm$core$String$fromFloat(sx) + (', ' + ($elm$core$String$fromFloat(sy) + (', ' + ($elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + ($elm$core$String$fromFloat(ox) + (', ' + ($elm$core$String$fromFloat(oy) + (', ' + ($elm$core$String$fromFloat(oz) + (', ' + ($elm$core$String$fromFloat(angle) + 'rad)')))))));
			return $elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var renderStyle = F3(
			function (maybePseudo, selector, props) {
				if (maybePseudo.$ === 1) {
					return selector + ('{' + (A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props) + '\n}'));
				} else {
					var pseudo = maybePseudo.a;
					switch (pseudo) {
						case 1:
							var _v17 = options.k6;
							switch (_v17) {
								case 0:
									return '';
								case 2:
									return selector + ('-hv {' + (A3(
										$elm$core$List$foldl,
										$mdgriffith$elm_ui$Internal$Model$renderProps(true),
										'',
										props) + '\n}'));
								default:
									return selector + ('-hv:hover {' + (A3(
										$elm$core$List$foldl,
										$mdgriffith$elm_ui$Internal$Model$renderProps(false),
										'',
										props) + '\n}'));
							}
						case 0:
							var renderedProps = A3(
								$elm$core$List$foldl,
								$mdgriffith$elm_ui$Internal$Model$renderProps(false),
								'',
								props);
							return A2(
								$elm$core$String$join,
								'\n',
								_List_fromArray(
									[selector + ('-fs:focus {' + (renderedProps + '\n}')), '.' + ($mdgriffith$elm_ui$Internal$Style$classes.jP + (':focus ~ ' + (selector + ('-fs:not(.focus)  {' + (renderedProps + '\n}'))))), '.' + ($mdgriffith$elm_ui$Internal$Style$classes.jP + (':focus ' + (selector + ('-fs  {' + (renderedProps + '\n}'))))), '.focusable-parent:focus ~ ' + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.jP + (' ' + (selector + ('-fs {' + (renderedProps + '\n}'))))))]));
						default:
							return selector + ('-act:active {' + (A3(
								$elm$core$List$foldl,
								$mdgriffith$elm_ui$Internal$Model$renderProps(false),
								'',
								props) + '\n}'));
					}
				}
			});
		var renderStyleRule = F2(
			function (rule, maybePseudo) {
				switch (rule.$) {
					case 0:
						var selector = rule.a;
						var props = rule.b;
						return A3(renderStyle, maybePseudo, selector, props);
					case 13:
						var name = rule.a;
						var prop = rule.b;
						return A3(
							renderStyle,
							maybePseudo,
							'.' + name,
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
								]));
					case 12:
						var name = rule.a;
						var transparency = rule.b;
						var opacity = A2(
							$elm$core$Basics$max,
							0,
							A2($elm$core$Basics$min, 1, 1 - transparency));
						return A3(
							renderStyle,
							maybePseudo,
							'.' + name,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'opacity',
									$elm$core$String$fromFloat(opacity))
								]));
					case 2:
						var i = rule.a;
						return A3(
							renderStyle,
							maybePseudo,
							'.font-size-' + $elm$core$String$fromInt(i),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'font-size',
									$elm$core$String$fromInt(i) + 'px')
								]));
					case 1:
						var name = rule.a;
						var typefaces = rule.b;
						var features = A2(
							$elm$core$String$join,
							', ',
							A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
						var families = _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'font-family',
								A2(
									$elm$core$String$join,
									', ',
									A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
								A2($mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
								A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'font-variant',
								A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
							]);
						return A2(
							$elm$core$String$join,
							' ',
							_List_fromArray(
								[
									A3(renderStyle, maybePseudo, '.' + name, families)
								]));
					case 3:
						var _class = rule.a;
						var prop = rule.b;
						var val = rule.c;
						return A3(
							renderStyle,
							maybePseudo,
							'.' + _class,
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, prop, val)
								]));
					case 4:
						var _class = rule.a;
						var prop = rule.b;
						var color = rule.c;
						return A3(
							renderStyle,
							maybePseudo,
							'.' + _class,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									prop,
									$mdgriffith$elm_ui$Internal$Model$formatColor(color))
								]));
					case 5:
						var cls = rule.a;
						var x = rule.b;
						var y = rule.c;
						var yPx = $elm$core$String$fromInt(y) + 'px';
						var xPx = $elm$core$String$fromInt(x) + 'px';
						var single = '.' + $mdgriffith$elm_ui$Internal$Style$classes.m9;
						var row = '.' + $mdgriffith$elm_ui$Internal$Style$classes.iC;
						var wrappedRow = '.' + ($mdgriffith$elm_ui$Internal$Style$classes.f6 + row);
						var right = '.' + $mdgriffith$elm_ui$Internal$Style$classes.gg;
						var paragraph = '.' + $mdgriffith$elm_ui$Internal$Style$classes.ia;
						var page = '.' + $mdgriffith$elm_ui$Internal$Style$classes.h8;
						var left = '.' + $mdgriffith$elm_ui$Internal$Style$classes.gf;
						var halfY = $elm$core$String$fromFloat(y / 2) + 'px';
						var halfX = $elm$core$String$fromFloat(x / 2) + 'px';
						var column = '.' + $mdgriffith$elm_ui$Internal$Style$classes.a1;
						var _class = '.' + cls;
						var any = '.' + $mdgriffith$elm_ui$Internal$Style$classes.jP;
						return $elm$core$String$concat(
							_List_fromArray(
								[
									A3(
									renderStyle,
									maybePseudo,
									_class + (row + (' > ' + (any + (' + ' + any)))),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (wrappedRow + (' > ' + any)),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (column + (' > ' + (any + (' + ' + any)))),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (page + (' > ' + (any + (' + ' + any)))),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (page + (' > ' + left)),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (page + (' > ' + right)),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_Utils_ap(_class, paragraph),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Internal$Model$Property,
											'line-height',
											'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
										])),
									A3(
									renderStyle,
									maybePseudo,
									'textarea' + _class,
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Internal$Model$Property,
											'line-height',
											'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (paragraph + (' > ' + left)),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (paragraph + (' > ' + right)),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (paragraph + '::after'),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
											A2(
											$mdgriffith$elm_ui$Internal$Model$Property,
											'margin-top',
											$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (paragraph + '::before'),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
											A2(
											$mdgriffith$elm_ui$Internal$Model$Property,
											'margin-bottom',
											$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
										]))
								]));
					case 7:
						var cls = rule.a;
						var top = rule.b;
						var right = rule.c;
						var bottom = rule.d;
						var left = rule.e;
						var _class = '.' + cls;
						return A3(
							renderStyle,
							maybePseudo,
							_class,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'padding',
									$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
								]));
					case 6:
						var cls = rule.a;
						var top = rule.b;
						var right = rule.c;
						var bottom = rule.d;
						var left = rule.e;
						var _class = '.' + cls;
						return A3(
							renderStyle,
							maybePseudo,
							_class,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'border-width',
									$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
								]));
					case 8:
						var template = rule.a;
						var toGridLengthHelper = F3(
							function (minimum, maximum, x) {
								toGridLengthHelper:
								while (true) {
									switch (x.$) {
										case 0:
											var px = x.a;
											return $elm$core$String$fromInt(px) + 'px';
										case 1:
											var _v2 = _Utils_Tuple2(minimum, maximum);
											if (_v2.a.$ === 1) {
												if (_v2.b.$ === 1) {
													var _v3 = _v2.a;
													var _v4 = _v2.b;
													return 'max-content';
												} else {
													var _v6 = _v2.a;
													var maxSize = _v2.b.a;
													return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
												}
											} else {
												if (_v2.b.$ === 1) {
													var minSize = _v2.a.a;
													var _v5 = _v2.b;
													return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
												} else {
													var minSize = _v2.a.a;
													var maxSize = _v2.b.a;
													return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
												}
											}
										case 2:
											var i = x.a;
											var _v7 = _Utils_Tuple2(minimum, maximum);
											if (_v7.a.$ === 1) {
												if (_v7.b.$ === 1) {
													var _v8 = _v7.a;
													var _v9 = _v7.b;
													return $elm$core$String$fromInt(i) + 'fr';
												} else {
													var _v11 = _v7.a;
													var maxSize = _v7.b.a;
													return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
												}
											} else {
												if (_v7.b.$ === 1) {
													var minSize = _v7.a.a;
													var _v10 = _v7.b;
													return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
												} else {
													var minSize = _v7.a.a;
													var maxSize = _v7.b.a;
													return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
												}
											}
										case 3:
											var m = x.a;
											var len = x.b;
											var $temp$minimum = $elm$core$Maybe$Just(m),
												$temp$maximum = maximum,
												$temp$x = len;
											minimum = $temp$minimum;
											maximum = $temp$maximum;
											x = $temp$x;
											continue toGridLengthHelper;
										default:
											var m = x.a;
											var len = x.b;
											var $temp$minimum = minimum,
												$temp$maximum = $elm$core$Maybe$Just(m),
												$temp$x = len;
											minimum = $temp$minimum;
											maximum = $temp$maximum;
											x = $temp$x;
											continue toGridLengthHelper;
									}
								}
							});
						var toGridLength = function (x) {
							return A3(toGridLengthHelper, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, x);
						};
						var xSpacing = toGridLength(template.nc.a);
						var ySpacing = toGridLength(template.nc.b);
						var rows = function (x) {
							return 'grid-template-rows: ' + (x + ';');
						}(
							A2(
								$elm$core$String$join,
								' ',
								A2($elm$core$List$map, toGridLength, template.mT)));
						var msRows = function (x) {
							return '-ms-grid-rows: ' + (x + ';');
						}(
							A2(
								$elm$core$String$join,
								ySpacing,
								A2($elm$core$List$map, toGridLength, template.kn)));
						var msColumns = function (x) {
							return '-ms-grid-columns: ' + (x + ';');
						}(
							A2(
								$elm$core$String$join,
								ySpacing,
								A2($elm$core$List$map, toGridLength, template.kn)));
						var gapY = 'grid-row-gap:' + (toGridLength(template.nc.b) + ';');
						var gapX = 'grid-column-gap:' + (toGridLength(template.nc.a) + ';');
						var columns = function (x) {
							return 'grid-template-columns: ' + (x + ';');
						}(
							A2(
								$elm$core$String$join,
								' ',
								A2($elm$core$List$map, toGridLength, template.kn)));
						var _class = '.grid-rows-' + (A2(
							$elm$core$String$join,
							'-',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.mT)) + ('-cols-' + (A2(
							$elm$core$String$join,
							'-',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.kn)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.nc.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.nc.b)))))));
						var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
						var supports = '@supports (display:grid) {' + (modernGrid + '}');
						var base = _class + ('{' + (msColumns + (msRows + '}')));
						return _Utils_ap(base, supports);
					case 9:
						var position = rule.a;
						var msPosition = A2(
							$elm$core$String$join,
							' ',
							_List_fromArray(
								[
									'-ms-grid-row: ' + ($elm$core$String$fromInt(position.iC) + ';'),
									'-ms-grid-row-span: ' + ($elm$core$String$fromInt(position.fb) + ';'),
									'-ms-grid-column: ' + ($elm$core$String$fromInt(position.gK) + ';'),
									'-ms-grid-column-span: ' + ($elm$core$String$fromInt(position.eJ) + ';')
								]));
						var modernPosition = A2(
							$elm$core$String$join,
							' ',
							_List_fromArray(
								[
									'grid-row: ' + ($elm$core$String$fromInt(position.iC) + (' / ' + ($elm$core$String$fromInt(position.iC + position.fb) + ';'))),
									'grid-column: ' + ($elm$core$String$fromInt(position.gK) + (' / ' + ($elm$core$String$fromInt(position.gK + position.eJ) + ';')))
								]));
						var _class = '.grid-pos-' + ($elm$core$String$fromInt(position.iC) + ('-' + ($elm$core$String$fromInt(position.gK) + ('-' + ($elm$core$String$fromInt(position.eJ) + ('-' + $elm$core$String$fromInt(position.fb)))))));
						var modernGrid = _class + ('{' + (modernPosition + '}'));
						var supports = '@supports (display:grid) {' + (modernGrid + '}');
						var base = _class + ('{' + (msPosition + '}'));
						return _Utils_ap(base, supports);
					case 11:
						var _class = rule.a;
						var styles = rule.b;
						var renderPseudoRule = function (style) {
							return A2(
								renderStyleRule,
								style,
								$elm$core$Maybe$Just(_class));
						};
						return A2(
							$elm$core$String$join,
							' ',
							A2($elm$core$List$map, renderPseudoRule, styles));
					default:
						var transform = rule.a;
						var val = $mdgriffith$elm_ui$Internal$Model$transformValue(transform);
						var _class = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
						var _v12 = _Utils_Tuple2(_class, val);
						if ((!_v12.a.$) && (!_v12.b.$)) {
							var cls = _v12.a.a;
							var v = _v12.b.a;
							return A3(
								renderStyle,
								maybePseudo,
								'.' + cls,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
									]));
						} else {
							return '';
						}
				}
			});
		var combine = F2(
			function (style, rendered) {
				return {
					ew: _Utils_ap(
						rendered.ew,
						A2(renderStyleRule, style, $elm$core$Maybe$Nothing)),
					c4: function () {
						var _v14 = $mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_v14.$ === 1) {
							return rendered.c4;
						} else {
							var topLevel = _v14.a;
							return A2($elm$core$List$cons, topLevel, rendered.c4);
						}
					}()
				};
			});
		var _v13 = A3(
			$elm$core$List$foldl,
			combine,
			{ew: '', c4: _List_Nil},
			stylesheet);
		var topLevel = _v13.c4;
		var rules = _v13.ew;
		return _Utils_ap(
			$mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			rules);
	});
var $mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			'style',
			_List_Nil,
			_List_fromArray(
				[
					$elm$virtual_dom$VirtualDom$text(
					A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		return _static ? A2(
			$elm$core$List$cons,
			_Utils_Tuple2('static-stylesheet', $mdgriffith$elm_ui$Internal$Model$staticRoot),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2(
					'dynamic-stylesheet',
					A2(
						$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
						opts,
						A3(
							$elm$core$List$foldl,
							$mdgriffith$elm_ui$Internal$Model$reduceStyles,
							_Utils_Tuple2(
								$elm$core$Set$empty,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.kW)
									])),
							styles).b)),
				children)) : A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'dynamic-stylesheet',
				A2(
					$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
					opts,
					A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$reduceStyles,
						_Utils_Tuple2(
							$elm$core$Set$empty,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.kW)
								])),
						styles).b)),
			children);
	});
var $mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		return _static ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Internal$Model$staticRoot,
			A2(
				$elm$core$List$cons,
				A2(
					$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
					opts,
					A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$reduceStyles,
						_Utils_Tuple2(
							$elm$core$Set$empty,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.kW)
								])),
						styles).b),
				children)) : A2(
			$elm$core$List$cons,
			A2(
				$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
				opts,
				A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_ui$Internal$Model$reduceStyles,
					_Utils_Tuple2(
						$elm$core$Set$empty,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.kW)
							])),
					styles).b),
			children);
	});
var $mdgriffith$elm_ui$Internal$Flag$heightBetween = $mdgriffith$elm_ui$Internal$Flag$flag(45);
var $mdgriffith$elm_ui$Internal$Flag$heightFill = $mdgriffith$elm_ui$Internal$Flag$flag(37);
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _v0) {
		var fieldOne = _v0.a;
		var fieldTwo = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $elm$html$Html$u = _VirtualDom_node('u');
var $mdgriffith$elm_ui$Internal$Flag$widthBetween = $mdgriffith$elm_ui$Internal$Flag$flag(44);
var $mdgriffith$elm_ui$Internal$Flag$widthFill = $mdgriffith$elm_ui$Internal$Flag$flag(39);
var $mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 1) {
					var keyed = children.a;
					return A3(
						$elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return keyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return $elm$html$Html$div;
								case 'p':
									return $elm$html$Html$p;
								default:
									return $elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return unkeyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 0:
					return A2(createNode, 'div', attributes);
				case 1:
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						$elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.jP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.m9))
									]))
							]));
			}
		}();
		switch (parentContext) {
			case 0:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.jP, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.dD, $mdgriffith$elm_ui$Internal$Style$classes.a2, $mdgriffith$elm_ui$Internal$Style$classes.jI])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.jP, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.dD, $mdgriffith$elm_ui$Internal$Style$classes.a2, $mdgriffith$elm_ui$Internal$Style$classes.jG])))
						]),
					_List_fromArray(
						[html])) : html));
			case 1:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.jP, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.dD, $mdgriffith$elm_ui$Internal$Style$classes.jH])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.jP, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.dD, $mdgriffith$elm_ui$Internal$Style$classes.jF])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[$mdgriffith$elm_ui$Internal$Style$classes.jP, $mdgriffith$elm_ui$Internal$Style$classes.eE, $mdgriffith$elm_ui$Internal$Style$classes.f3, $mdgriffith$elm_ui$Internal$Style$classes.dZ])))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A3(
		$elm$virtual_dom$VirtualDom$node,
		'div',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[$mdgriffith$elm_ui$Internal$Style$classes.jP, $mdgriffith$elm_ui$Internal$Style$classes.eE, $mdgriffith$elm_ui$Internal$Style$classes.f4, $mdgriffith$elm_ui$Internal$Style$classes.fc])))
			]),
		_List_fromArray(
			[
				$elm$virtual_dom$VirtualDom$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_v8, _v9) {
				var key = _v8.a;
				var child = _v8.b;
				var htmls = _v9.a;
				var existingStyles = _v9.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.k8, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.nk : _Utils_ap(styled.nk, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.k8, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.nk : _Utils_ap(styled.nk, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _v6) {
				var htmls = _v6.a;
				var existingStyles = _v6.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.k8, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.nk : _Utils_ap(styled.nk, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.k8, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.nk : _Utils_ap(styled.nk, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 1) {
			var keyedChildren = children.a;
			var _v1 = A3(
				$elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _v1.a;
			var styles = _v1.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.nk : _Utils_ap(rendered.nk, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.bw,
						rendered.bB,
						rendered.jS,
						$mdgriffith$elm_ui$Internal$Model$Keyed(
							A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.gG)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						k8: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.bw,
							rendered.bB,
							rendered.jS,
							$mdgriffith$elm_ui$Internal$Model$Keyed(
								A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.gG))),
						nk: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _v3 = A3(
				$elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _v3.a;
			var styles = _v3.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.nk : _Utils_ap(rendered.nk, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.bw,
						rendered.bB,
						rendered.jS,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.gG)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						k8: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.bw,
							rendered.bB,
							rendered.jS,
							$mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.gG))),
						nk: allStyles
					});
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 10, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					function () {
						switch (location) {
							case 0:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.b7, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.jy]));
							case 1:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.b7, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.jZ]));
							case 2:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.b7, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.me]));
							case 3:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.b7, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.l8]));
							case 4:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.b7, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.lg]));
							default:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.b7, $mdgriffith$elm_ui$Internal$Style$classes.m9, $mdgriffith$elm_ui$Internal$Style$classes.jY]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 3:
							return $elm$virtual_dom$VirtualDom$text('');
						case 2:
							var str = elem.a;
							return $mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 0:
							var html = elem.a;
							return html($mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.k8, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, $mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2($mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 0:
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 1:
				var existingBehind = existing.a;
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2($elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 2:
				var existingInFront = existing.a;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2($elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2($elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2($elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 1:
				var name = old.a;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.eK + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gf);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.eK + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gg);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.eK + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jD);
	}
};
var $mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.eL + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jK);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.eL + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jC);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.eL + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jE);
	}
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 0:
				switch (component.$) {
					case 0:
						var x = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 1:
						var y = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 2:
						var z = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 1:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 1:
						var newY = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 2:
						var newZ = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 1:
						var newY = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 2:
						var newZ = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 3:
						var newMove = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 4:
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$height = $mdgriffith$elm_ui$Internal$Flag$flag(7);
var $mdgriffith$elm_ui$Internal$Flag$heightContent = $mdgriffith$elm_ui$Internal$Flag$flag(36);
var $mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_v0, _v1) {
		var one = _v0.a;
		var two = _v0.b;
		var three = _v1.a;
		var four = _v1.b;
		return A2($mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var $mdgriffith$elm_ui$Internal$Flag$none = A2($mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 0:
			var px = h.a;
			var val = $elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				name,
				_List_fromArray(
					[
						A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.dZ,
				_List_Nil);
		case 2:
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.fc,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.hm + (' height-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.jP + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.iC + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$widthContent = $mdgriffith$elm_ui$Internal$Flag$flag(38);
var $mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 0:
			var px = w.a;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.jo + (' width-px-' + $elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + $elm$core$String$fromInt(px),
						'width',
						$elm$core$String$fromInt(px) + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.f3,
				_List_Nil);
		case 2:
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.f4,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.jp + (' width-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.jP + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.iC + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$borderWidth = $mdgriffith$elm_ui$Internal$Flag$flag(27);
var $mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, $mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 3) {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 2:
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 7:
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$width = $mdgriffith$elm_ui$Internal$Flag$flag(6);
var $mdgriffith$elm_ui$Internal$Flag$xAlign = $mdgriffith$elm_ui$Internal$Flag$flag(30);
var $mdgriffith$elm_ui$Internal$Flag$yAlign = $mdgriffith$elm_ui$Internal$Flag$flag(29);
var $mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _v1 = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_v1.$ === 1) {
					return {
						jS: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes),
							attrs),
						gG: children,
						bw: has,
						bB: node,
						nk: styles
					};
				} else {
					var _class = _v1.a;
					return {
						jS: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						gG: children,
						bw: has,
						bB: node,
						nk: A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 0:
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 3:
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 1:
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2($elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 4:
						var flag = attribute.a;
						var style = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2($mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2($elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 10:
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 7:
						var width = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 0:
									var px = width.a;
									var $temp$classes = ($mdgriffith$elm_ui$Internal$Style$classes.jo + (' width-px-' + $elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3(
											$mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + $elm$core$String$fromInt(px),
											'width',
											$elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f3),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f4),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.jp + (' width-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.jP + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.iC + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v4 = $mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _v4.a;
									var newClass = _v4.b;
									var newStyles = _v4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$merge, addToFlags, has),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 8:
						var height = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 0:
									var px = height.a;
									var val = $elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = name + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.dZ + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.fc + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.hm + (' height-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.jP + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.a1 + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v6 = $mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _v6.a;
									var newClass = _v6.b;
									var newStyles = _v6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$merge, addToFlags, has),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 2:
						var description = attribute.a;
						switch (description.$) {
							case 0:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 1:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 2:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 3:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 4:
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											$mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + $elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 9:
								var newNode = function () {
									switch (node.$) {
										case 0:
											return $mdgriffith$elm_ui$Internal$Model$NodeName('p');
										case 1:
											var name = node.a;
											return $mdgriffith$elm_ui$Internal$Model$NodeName(name);
										default:
											var x = node.a;
											var y = node.b;
											return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
									}
								}();
								var $temp$classes = classes,
									$temp$node = newNode,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 8:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 5:
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 6:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 9:
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 3:
									return styles;
								case 2:
									var str = elem.a;
									return styles;
								case 0:
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.nk);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3($mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 6:
						var x = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$untransformed = $mdgriffith$elm_ui$Internal$Model$Untransformed;
var $mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			$mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				$mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				$mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				$mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				$elm$core$List$reverse(attributes)));
	});
var $mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 0};
var $mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 4) && (attr.b.$ === 11)) && (!attr.b.a)) {
		var _v1 = attr.b;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		$elm$html$Html$Attributes$class(cls));
};
var $mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var $mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Element$height = $mdgriffith$elm_ui$Internal$Model$Height;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onClick = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onClick);
var $mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $mdgriffith$elm_ui$Element$Input$onKey = F2(
	function (desiredCode, msg) {
		var decode = function (code) {
			return _Utils_eq(code, desiredCode) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('Not the enter key');
		};
		var isKey = A2(
			$elm$json$Json$Decode$andThen,
			decode,
			A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
		return $mdgriffith$elm_ui$Internal$Model$Attr(
			A2(
				$elm$html$Html$Events$preventDefaultOn,
				'keyup',
				A2(
					$elm$json$Json$Decode$map,
					function (fired) {
						return _Utils_Tuple2(fired, true);
					},
					isKey)));
	});
var $mdgriffith$elm_ui$Element$Input$onEnter = function (msg) {
	return A2($mdgriffith$elm_ui$Element$Input$onKey, $mdgriffith$elm_ui$Element$Input$enter, msg);
};
var $mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$cursor = $mdgriffith$elm_ui$Internal$Flag$flag(21);
var $mdgriffith$elm_ui$Element$pointer = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.ks);
var $mdgriffith$elm_ui$Internal$Model$Content = {$: 1};
var $mdgriffith$elm_ui$Element$shrink = $mdgriffith$elm_ui$Internal$Model$Content;
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$width = $mdgriffith$elm_ui$Internal$Model$Width;
var $mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _v0) {
		var onPress = _v0.b8;
		var label = _v0.a8;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dF + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a2 + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.m_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hW)))))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$pointer,
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											$elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 1) {
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$Attr(
														$elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														$elm$core$List$cons,
														$mdgriffith$elm_ui$Element$Input$onEnter(msg),
														attrs));
											}
										}()))))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Internal$Model$CenterX = 1;
var $mdgriffith$elm_ui$Element$centerX = $mdgriffith$elm_ui$Internal$Model$AlignX(1);
var $mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$CenterY = 1;
var $mdgriffith$elm_ui$Element$centerY = $mdgriffith$elm_ui$Internal$Model$AlignY(1);
var $mdgriffith$elm_ui$Internal$Flag$overflow = $mdgriffith$elm_ui$Internal$Flag$flag(20);
var $mdgriffith$elm_ui$Element$clip = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.kj);
var $mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$bgColor = $mdgriffith$elm_ui$Internal$Flag$flag(8);
var $mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return $mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var $mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Flag$borderColor = $mdgriffith$elm_ui$Internal$Flag$flag(28);
var $mdgriffith$elm_ui$Element$Border$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'border-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Flag$fontColor = $mdgriffith$elm_ui$Internal$Flag$flag(14);
var $mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var $mdgriffith$elm_ui$Internal$Model$AsColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$asColumn = 1;
var $mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ko + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cC)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $author$project$PAM$UI$Basic$edges = {cz: 0, cR: 0, cX: 0, c3: 0};
var $mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var $author$project$PAM$UI$Basic$fadeColor = F2(
	function (alpha, color) {
		var c = $mdgriffith$elm_ui$Element$toRgb(color);
		return A4($mdgriffith$elm_ui$Element$rgba, c.ae, c.aa, c.Y, alpha);
	});
var $mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$fill = $mdgriffith$elm_ui$Internal$Model$Fill(1);
var $author$project$PAM$Route$initLinkNewObject = F5(
	function (parentObjTypeKey, parentObjId, childObjTypeKey, params, route) {
		return A4(
			$author$project$PAM$Route$LinkNewObject,
			parentObjTypeKey,
			parentObjId,
			childObjTypeKey,
			A2($author$project$PAM$Route$pickQueryParams, params, route));
	});
var $author$project$PAM$Route$initShowObjectDetails = F4(
	function (objTypeKey, objId, params, route) {
		return A3(
			$author$project$PAM$Route$ShowObjectDetails,
			objTypeKey,
			objId,
			A2($author$project$PAM$Route$pickQueryParams, params, route));
	});
var $author$project$PAM$Object$isNew = function (_v0) {
	var id = _v0.r;
	return _Utils_eq(id, -1);
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $mdgriffith$elm_ui$Element$link = F2(
	function (attrs, _v0) {
		var url = _v0.c8;
		var label = _v0.a8;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dF + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.a2)),
								attrs))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Internal$Model$Min = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$minimum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Min, i, l);
	});
var $mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$fontFamily = $mdgriffith$elm_ui$Internal$Flag$flag(5);
var $elm$core$String$toLower = _String_toLower;
var $elm$core$String$words = _String_words;
var $mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 0:
						return 'serif';
					case 1:
						return 'sans-serif';
					case 2:
						return 'monospace';
					case 3:
						var name = font.a;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					case 4:
						var name = font.a;
						var url = font.b;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					default:
						var name = font.a.fu;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
				}
			}());
	});
var $mdgriffith$elm_ui$Element$Font$family = function (families) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontFamily,
		A2(
			$mdgriffith$elm_ui$Internal$Model$FontFamily,
			A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'ff-', families),
			families));
};
var $mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 1};
var $mdgriffith$elm_ui$Element$Font$sansSerif = $mdgriffith$elm_ui$Internal$Model$SansSerif;
var $mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 3, a: a};
};
var $mdgriffith$elm_ui$Element$Font$typeface = $mdgriffith$elm_ui$Internal$Model$Typeface;
var $author$project$PAM$UI$Basic$montserrat = $mdgriffith$elm_ui$Element$Font$family(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$typeface('Montserrat'),
			$mdgriffith$elm_ui$Element$Font$sansSerif
		]));
var $mdgriffith$elm_ui$Internal$Model$Hover = 1;
var $mdgriffith$elm_ui$Internal$Model$PseudoSelector = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$hover = $mdgriffith$elm_ui$Internal$Flag$flag(33);
var $mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Empty = {$: 3};
var $mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $mdgriffith$elm_ui$Internal$Model$map = F2(
	function (fn, el) {
		switch (el.$) {
			case 1:
				var styled = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						k8: F2(
							function (add, context) {
								return A2(
									$elm$virtual_dom$VirtualDom$map,
									fn,
									A2(styled.k8, add, context));
							}),
						nk: styled.nk
					});
			case 0:
				var html = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A2(
						$elm$core$Basics$composeL,
						$elm$virtual_dom$VirtualDom$map(fn),
						html));
			case 2:
				var str = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Text(str);
			default:
				return $mdgriffith$elm_ui$Internal$Model$Empty;
		}
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$removeNever = function (style) {
	return A2($mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle, $elm$core$Basics$never, style);
};
var $mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper = F2(
	function (attr, _v0) {
		var styles = _v0.a;
		var trans = _v0.b;
		var _v1 = $mdgriffith$elm_ui$Internal$Model$removeNever(attr);
		switch (_v1.$) {
			case 4:
				var style = _v1.b;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, style, styles),
					trans);
			case 10:
				var flag = _v1.a;
				var component = _v1.b;
				return _Utils_Tuple2(
					styles,
					A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, trans, component));
			default:
				return _Utils_Tuple2(styles, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$unwrapDecorations = function (attrs) {
	var _v0 = A3(
		$elm$core$List$foldl,
		$mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper,
		_Utils_Tuple2(_List_Nil, $mdgriffith$elm_ui$Internal$Model$Untransformed),
		attrs);
	var styles = _v0.a;
	var transform = _v0.b;
	return A2(
		$elm$core$List$cons,
		$mdgriffith$elm_ui$Internal$Model$Transform(transform),
		styles);
};
var $mdgriffith$elm_ui$Element$mouseOver = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$hover,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			1,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $mdgriffith$elm_ui$Element$none = $mdgriffith$elm_ui$Internal$Model$Empty;
var $mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Flag$padding = $mdgriffith$elm_ui$Internal$Flag$flag(2);
var $mdgriffith$elm_ui$Element$padding = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(x),
			x,
			x,
			x,
			x));
};
var $mdgriffith$elm_ui$Internal$Model$paddingName = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left)))))));
	});
var $mdgriffith$elm_ui$Element$paddingEach = function (_v0) {
	var top = _v0.c3;
	var right = _v0.cX;
	var bottom = _v0.cz;
	var left = _v0.cR;
	return (_Utils_eq(top, right) && (_Utils_eq(top, bottom) && _Utils_eq(top, left))) ? A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(top),
			top,
			top,
			top,
			top)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			A4($mdgriffith$elm_ui$Internal$Model$paddingName, top, right, bottom, left),
			top,
			right,
			bottom,
			left));
};
var $mdgriffith$elm_ui$Element$paddingXY = F2(
	function (x, y) {
		return _Utils_eq(x, y) ? A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + $elm$core$String$fromInt(x),
				x,
				x,
				x,
				x)) : A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
				y,
				x,
				y,
				x));
	});
var $mdgriffith$elm_ui$Internal$Model$Paragraph = {$: 9};
var $mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Flag$spacing = $mdgriffith$elm_ui$Internal$Flag$flag(3);
var $mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y)));
	});
var $mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var $mdgriffith$elm_ui$Element$paragraph = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asParagraph,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Paragraph),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$spacing(5),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$px = $mdgriffith$elm_ui$Internal$Model$Px;
var $mdgriffith$elm_ui$Element$rgba255 = F4(
	function (red, green, blue, a) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, a);
	});
var $mdgriffith$elm_ui$Element$text = function (content) {
	return $mdgriffith$elm_ui$Internal$Model$Text(content);
};
var $author$project$Fields$Shared$requiredMarker = function (isRequired) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(10)),
				$mdgriffith$elm_ui$Element$Font$color(
				A4($mdgriffith$elm_ui$Element$rgba255, 255, 0, 0, 1)),
				$mdgriffith$elm_ui$Element$alignLeft
			]),
		isRequired ? $mdgriffith$elm_ui$Element$text('*') : $mdgriffith$elm_ui$Element$none);
};
var $mdgriffith$elm_ui$Internal$Flag$borderRound = $mdgriffith$elm_ui$Internal$Flag$flag(17);
var $mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + $elm$core$String$fromInt(radius),
			'border-radius',
			$elm$core$String$fromInt(radius) + 'px'));
};
var $mdgriffith$elm_ui$Internal$Model$AsRow = 0;
var $mdgriffith$elm_ui$Internal$Model$asRow = 0;
var $mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cC + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.a2)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontSize = $mdgriffith$elm_ui$Internal$Flag$flag(4);
var $mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontSize,
		$mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $mdgriffith$elm_ui$Element$htmlAttribute = $mdgriffith$elm_ui$Internal$Model$Attr;
var $author$project$PAM$UI$Basic$title = function (data) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2($elm$html$Html$Attributes$attribute, 'title', data));
};
var $author$project$PAM$Route$toString = function (route) {
	return $author$project$PAM$Route$unparser(route);
};
var $mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + $elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var $mdgriffith$elm_ui$Element$Border$widthXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$borderWidth,
			A5(
				$mdgriffith$elm_ui$Internal$Model$BorderWidth,
				'b-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
				y,
				x,
				y,
				x));
	});
var $mdgriffith$elm_ui$Element$Border$widthEach = function (_v0) {
	var bottom = _v0.cz;
	var top = _v0.c3;
	var left = _v0.cR;
	var right = _v0.cX;
	return (_Utils_eq(top, bottom) && _Utils_eq(left, right)) ? (_Utils_eq(top, right) ? $mdgriffith$elm_ui$Element$Border$width(top) : A2($mdgriffith$elm_ui$Element$Border$widthXY, left, top)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left))))))),
			top,
			right,
			bottom,
			left));
};
var $author$project$PAM$EditableFields$linkedObjectsField = F5(
	function (route, parentObject, field, objReferences, maybeLink) {
		var numLinkedObjects = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$List$length, objReferences));
		var noLinkedObjectsRow = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$Font$size(14)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					$mdgriffith$elm_ui$Element$text(''))
				]));
		var linkedObjectRow = F3(
			function (palette, readOnly, ref) {
				var zoomBtn = ref.ls ? A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('Zoom')
						]),
					{
						a8: $mdgriffith$elm_ui$Element$text('Zoom'),
						b8: $elm$core$Maybe$Just(
							$author$project$PAM$Message$ZoomToObjectRef(ref))
					}) : A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray),
							$author$project$PAM$UI$Basic$title('No geometry to zoom to')
						]),
					{
						a8: $mdgriffith$elm_ui$Element$text('Zoom'),
						b8: $elm$core$Maybe$Nothing
					});
				var linkBtns = readOnly ? _List_fromArray(
					[zoomBtn]) : _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$author$project$PAM$UI$Basic$title('Unlink')
							]),
						{
							a8: $mdgriffith$elm_ui$Element$text('Unlink'),
							b8: $elm$core$Maybe$Just(
								A2($author$project$PAM$Message$UnlinkObject, ref, parentObject))
						}),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_Nil,
						$mdgriffith$elm_ui$Element$text('|')),
						zoomBtn
					]);
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$padding(4),
							$mdgriffith$elm_ui$Element$Font$size(14)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$link,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignLeft,
									$mdgriffith$elm_ui$Element$Font$color(palette.S.fK.eE),
									$mdgriffith$elm_ui$Element$mouseOver(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color(
											A2($author$project$PAM$UI$Basic$darkenColor, 0.15, palette.S.fK.eE))
										])),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							{
								a8: A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(ref.lX)
										])),
								c8: $author$project$PAM$Route$toString(
									A4($author$project$PAM$Route$initShowObjectDetails, ref.ja, ref.h_, $elm$core$Maybe$Nothing, route))
							}),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$alignRight,
									$mdgriffith$elm_ui$Element$spacing(4)
								]),
							linkBtns)
						]));
			});
		return {
			hj: function (readOnly) {
				return function (data) {
					return function (palette) {
						if (maybeLink.$ === 1) {
							return $mdgriffith$elm_ui$Element$none;
						} else {
							var link = maybeLink.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$padding(10),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(12),
										$mdgriffith$elm_ui$Element$spacing(16),
										$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
										$mdgriffith$elm_ui$Element$paddingEach(
										_Utils_update(
											$author$project$PAM$UI$Basic$edges,
											{c3: 10})),
										$mdgriffith$elm_ui$Element$Border$widthEach(
										_Utils_update(
											$author$project$PAM$UI$Basic$borders,
											{c3: 1})),
										$mdgriffith$elm_ui$Element$Border$color(palette.S.fK.eE),
										$mdgriffith$elm_ui$Element$clip
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$size(20),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												$author$project$Fields$Shared$requiredMarker(false),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$color(palette.S.fK.eE),
														$mdgriffith$elm_ui$Element$alignLeft
													]),
												$mdgriffith$elm_ui$Element$text(link.ie)),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
														$mdgriffith$elm_ui$Element$alignRight,
														$mdgriffith$elm_ui$Element$Font$size(14),
														$mdgriffith$elm_ui$Element$paddingEach(
														_Utils_update(
															$author$project$PAM$UI$Basic$edges,
															{cX: 10}))
													]),
												$mdgriffith$elm_ui$Element$text(
													link.ie + (' Linked: ' + $elm$core$String$fromInt(numLinkedObjects))))
											])),
										(!numLinkedObjects) ? A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$size(12)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('No ' + (link.ie + ' have been linked for this item.'))
											])) : A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$padding(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$size(16),
													$mdgriffith$elm_ui$Element$Background$color(
													A2($author$project$PAM$UI$Basic$fadeColor, 0.32, palette.S.fK.G)),
													$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$padding(10),
													$mdgriffith$elm_ui$Element$Border$rounded(3)
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$row,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Border$widthEach(
															_Utils_update(
																$author$project$PAM$UI$Basic$borders,
																{cz: 1})),
															$mdgriffith$elm_ui$Element$padding(4),
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
															$mdgriffith$elm_ui$Element$Font$size(14)
														]),
													_List_fromArray(
														[
															A2(
															$mdgriffith$elm_ui$Element$el,
															_List_Nil,
															$mdgriffith$elm_ui$Element$text(link.cZ + ' Name'))
														])),
													A2(
													$mdgriffith$elm_ui$Element$column,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
															$mdgriffith$elm_ui$Element$spacing(2),
															A2($mdgriffith$elm_ui$Element$paddingXY, 0, 4)
														]),
													A2(
														$elm$core$Maybe$withDefault,
														_List_fromArray(
															[noLinkedObjectsRow]),
														A2(
															$elm$core$Maybe$map,
															$elm$core$List$map(
																A2(linkedObjectRow, palette, readOnly)),
															objReferences)))
												]))),
										function () {
										if (!readOnly) {
											var parentIsNew = $author$project$PAM$Object$isNew(parentObject);
											var btnStyles = _List_fromArray(
												[
													$author$project$PAM$UI$Basic$montserrat,
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$px(28)),
													$mdgriffith$elm_ui$Element$width(
													A2($mdgriffith$elm_ui$Element$minimum, 100, $mdgriffith$elm_ui$Element$shrink)),
													A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
													$mdgriffith$elm_ui$Element$Border$rounded(16),
													$mdgriffith$elm_ui$Element$centerX
												]);
											var linkBtn = parentIsNew ? A2(
												$mdgriffith$elm_ui$Element$el,
												_List_Nil,
												A2(
													$mdgriffith$elm_ui$Element$Input$button,
													_Utils_ap(
														btnStyles,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$Background$color(
																A2($author$project$PAM$UI$Basic$fadeColor, 0.5, palette.S.mB.G)),
																$mdgriffith$elm_ui$Element$Font$color(
																A2($author$project$PAM$UI$Basic$fadeColor, 0.5, palette.S.mB.eE))
															])),
													{
														a8: $mdgriffith$elm_ui$Element$text('New ' + link.cZ),
														b8: $elm$core$Maybe$Nothing
													})) : A2(
												$mdgriffith$elm_ui$Element$el,
												_List_Nil,
												A2(
													$mdgriffith$elm_ui$Element$link,
													_Utils_ap(
														btnStyles,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
																$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE)
															])),
													{
														a8: A2(
															$mdgriffith$elm_ui$Element$el,
															_List_fromArray(
																[$mdgriffith$elm_ui$Element$centerY]),
															$mdgriffith$elm_ui$Element$text('New ' + link.cZ)),
														c8: $author$project$PAM$Route$toString(
															A5($author$project$PAM$Route$initLinkNewObject, parentObject.bi.lw, parentObject.r, link.lw, $elm$core$Maybe$Nothing, route))
													}));
											return A2(
												$mdgriffith$elm_ui$Element$row,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
														$mdgriffith$elm_ui$Element$spacing(10),
														$mdgriffith$elm_ui$Element$paddingEach(
														_Utils_update(
															$author$project$PAM$UI$Basic$edges,
															{cz: 10, cR: 10, cX: 10}))
													]),
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$el,
														_List_Nil,
														A2(
															$mdgriffith$elm_ui$Element$Input$button,
															_Utils_ap(
																btnStyles,
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
																		$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE)
																	])),
															{
																a8: $mdgriffith$elm_ui$Element$text('Link ' + link.cZ),
																b8: $elm$core$Maybe$Just(
																	A2($author$project$PAM$Message$LinkExistingObject, parentObject, link))
															})),
														linkBtn
													]));
										} else {
											return $mdgriffith$elm_ui$Element$none;
										}
									}()
									]));
						}
					};
				};
			}
		};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_LongText = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$EditableFields$longStringField = F4(
	function (field, limit, value, updateTarget) {
		return {
			hj: function (item) {
				return _Utils_Tuple2(value, 'Not Set');
			},
			hJ: limit,
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_LongText(set_value),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_Money = function (a) {
	return {$: 6, a: a};
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$floor = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('0' === _v0.a.a) {
					var _v1 = _v0.a;
					var rest = _v1.b;
					return signed && A2(
						$elm$core$List$any,
						$elm$core$Basics$neq('0'),
						$elm$core$String$toList(rest));
				} else {
					return signed;
				}
			}
		}));
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$PAM$EditableFields$moneyField = F4(
	function (field, limit, value, updateTarget) {
		return {
			hj: function (item) {
				return _Utils_Tuple2(value, 'Not Set');
			},
			hJ: limit,
			cc: $elm$core$Maybe$Just('$'),
			mJ: $elm$core$Maybe$Just(
				{
					lK: $elm$core$Maybe$Nothing,
					lN: $elm$core$Maybe$Just(0)
				}),
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_Money(
							function () {
								if (set_value.$ === 1) {
									var v = set_value.a;
									var t = set_value.b;
									if ((v > 0) && (v < 10000000)) {
										var rounded = A2($myrho$elm_round$Round$floor, 2, v);
										return (_Utils_cmp(
											$elm$core$String$length(rounded),
											$elm$core$String$length(t)) < 0) ? A2(
											$author$project$Fields$Shared$Valid,
											A2(
												$elm$core$Maybe$withDefault,
												v,
												$elm$core$String$toFloat(rounded)),
											rounded) : A2($author$project$Fields$Shared$Valid, v, t);
									} else {
										if (!v) {
											return A2($author$project$Fields$Shared$Valid, v, t);
										} else {
											return $author$project$Fields$Shared$Invalid(t);
										}
									}
								} else {
									return set_value;
								}
							}()),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_Natural = function (a) {
	return {$: 4, a: a};
};
var $author$project$PAM$EditableFields$naturalField = F4(
	function (field, limit, value, updateTarget) {
		return {
			hj: function (item) {
				return _Utils_Tuple2(value, 'Not Set');
			},
			hJ: limit,
			mJ: $elm$core$Maybe$Just(
				{
					lK: $elm$core$Maybe$Nothing,
					lN: $elm$core$Maybe$Just(0)
				}),
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_Natural(set_value),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$EditableFields$notImplementedCustomField = F3(
	function (_v0, value, updateTarget) {
		var name = _v0.fu;
		return {
			hj: function (readOnly) {
				return function (item) {
					return function (palette) {
						return A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							$mdgriffith$elm_ui$Element$text('Type for field \'' + (name + '\' has not been implemented.')));
					};
				};
			}
		};
	});
var $cuducos$elm_format_number$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {gW: decimals, hy: integers, h6: original, fG: prefix, fU: suffix};
	});
var $cuducos$elm_format_number$Parser$Negative = 2;
var $cuducos$elm_format_number$Parser$Positive = 0;
var $cuducos$elm_format_number$Parser$Zero = 1;
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $cuducos$elm_format_number$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.hy,
				$elm$core$List$singleton(formatted.gW))));
	return onlyZeros ? 1 : ((formatted.h6 < 0) ? 2 : 0);
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$String$filter = _String_filter;
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $cuducos$elm_format_number$Parser$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$Parser$parse = F2(
	function (locale, original) {
		var parts = A2(
			$elm$core$String$split,
			'.',
			A2($myrho$elm_round$Round$round, locale.gW, original));
		var integers = $cuducos$elm_format_number$Parser$splitThousands(
			A2(
				$elm$core$String$filter,
				$elm$core$Char$isDigit,
				A2(
					$elm$core$Maybe$withDefault,
					'0',
					$elm$core$List$head(parts))));
		var decimals = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				A2($elm$core$List$drop, 1, parts)));
		var partial = A5($cuducos$elm_format_number$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{fG: locale.lU, fU: locale.lV});
			case 0:
				return _Utils_update(
					partial,
					{fG: locale.mz, fU: locale.mA});
			default:
				return partial;
		}
	});
var $cuducos$elm_format_number$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.kx, decimals);
	});
var $cuducos$elm_format_number$Stringfy$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$Stringfy$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$Stringfy$humanizeDecimals = F3(
	function (locale, strategy, decimals) {
		if ((decimals === '') || _Utils_eq(
			A2($elm$core$String$repeat, locale.gW, '0'),
			decimals)) {
			return '';
		} else {
			if (!strategy) {
				return _Utils_ap(locale.kx, decimals);
			} else {
				return A2(
					$cuducos$elm_format_number$Stringfy$formatDecimals,
					locale,
					$cuducos$elm_format_number$Stringfy$removeZeros(decimals));
			}
		}
	});
var $cuducos$elm_format_number$Stringfy$stringfy = F3(
	function (locale, strategy, formatted) {
		var stringfyDecimals = function () {
			if (!strategy.$) {
				var strategy_ = strategy.a;
				return A2($cuducos$elm_format_number$Stringfy$humanizeDecimals, locale, strategy_);
			} else {
				return $cuducos$elm_format_number$Stringfy$formatDecimals(locale);
			}
		}();
		var integers = A2($elm$core$String$join, locale.nD, formatted.hy);
		var decimals = stringfyDecimals(formatted.gW);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.fG, integers, decimals, formatted.fU]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A3(
			$cuducos$elm_format_number$Stringfy$stringfy,
			locale,
			$elm$core$Maybe$Nothing,
			A2($cuducos$elm_format_number$Parser$parse, locale, number_));
	});
var $author$project$PAM$Scores$listState = function (state) {
	switch (state) {
		case 0:
			return 'Not calculated';
		case 1:
			return 'Estimating the score';
		case 3:
			return 'Calculating the score';
		default:
			return '';
	}
};
var $author$project$PAM$Scores$tagState = F2(
	function (state, score) {
		switch (state) {
			case 2:
				return '~' + score;
			case 3:
				return '~' + score;
			case 5:
				return '~' + (score + '*');
			default:
				return score;
		}
	});
var $cuducos$elm_format_number$FormatNumber$Locales$Locale = F7(
	function (decimals, thousandSeparator, decimalSeparator, negativePrefix, negativeSuffix, positivePrefix, positiveSuffix) {
		return {kx: decimalSeparator, gW: decimals, lU: negativePrefix, lV: negativeSuffix, mz: positivePrefix, mA: positiveSuffix, nD: thousandSeparator};
	});
var $cuducos$elm_format_number$FormatNumber$Locales$usLocale = A7($cuducos$elm_format_number$FormatNumber$Locales$Locale, 2, ',', '.', '−', '', '', '');
var $author$project$PAM$Scores$printScore = function (_v0) {
	var score = _v0.iG;
	var state = _v0.cj;
	var scoreLocale = _Utils_update(
		$cuducos$elm_format_number$FormatNumber$Locales$usLocale,
		{gW: 4});
	switch (state) {
		case 0:
			return 'Not Started';
		case 1:
			return 'Calculating';
		default:
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$PAM$Scores$listState(state),
				A2(
					$elm$core$Maybe$map,
					$author$project$PAM$Scores$tagState(state),
					A2(
						$elm$core$Maybe$map,
						$cuducos$elm_format_number$FormatNumber$format(scoreLocale),
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.iG;
							},
							score))));
	}
};
var $author$project$PAM$EditableFields$renderScoreBox = function (score) {
	return A2(
		$elm$core$Maybe$withDefault,
		$mdgriffith$elm_ui$Element$none,
		A2(
			$elm$core$Maybe$map,
			function (bc) {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$width(1),
							$mdgriffith$elm_ui$Element$alignLeft,
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(15)),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(15)),
							$mdgriffith$elm_ui$Element$Background$color(
							A4($mdgriffith$elm_ui$Element$rgba, bc.mI / 255, bc.k_ / 255, bc.jU / 255, bc.jx))
						]),
					$mdgriffith$elm_ui$Element$none);
			},
			A2(
				$elm$core$Maybe$andThen,
				function ($) {
					return $.j9;
				},
				A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.lx;
					},
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.gm;
						},
						score.iG)))));
};
var $author$project$PAM$EditableFields$renderScoreLabel = function (score) {
	return A2(
		$elm$core$Maybe$withDefault,
		$mdgriffith$elm_ui$Element$none,
		A2(
			$elm$core$Maybe$map,
			$mdgriffith$elm_ui$Element$el(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignLeft])),
			A2(
				$elm$core$Maybe$map,
				$mdgriffith$elm_ui$Element$text,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.eE;
					},
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.lx;
						},
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.gm;
							},
							score.iG))))));
};
var $author$project$PAM$EditableFields$scoresField = function (scores) {
	return {
		hj: function (_v0) {
			return function (_v1) {
				return function (palette) {
					var targetLayer = 'Wildfire Risk';
					var targetBuffer = 0;
					var displayScore = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Maybe$Nothing,
						A2(
							$elm$core$Maybe$map,
							function (_v2) {
								var layer = _v2.hC;
								var bufferScores = _v2.ka;
								return $elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (b) {
											return _Utils_eq(b.cA, targetBuffer);
										},
										bufferScores));
							},
							A2(
								$elm$core$Maybe$andThen,
								$elm_community$list_extra$List$Extra$find(
									function (s) {
										return _Utils_eq(s.hC.fu, targetLayer);
									}),
								scores)));
					return A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(7),
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
							]),
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									A2(
									$elm$core$Maybe$map,
									$mdgriffith$elm_ui$Element$el(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$alignLeft,
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$px(75))
											])),
									A2(
										$elm$core$Maybe$map,
										$mdgriffith$elm_ui$Element$text,
										A2($elm$core$Maybe$map, $author$project$PAM$Scores$printScore, displayScore))),
									A2($elm$core$Maybe$map, $author$project$PAM$EditableFields$renderScoreBox, displayScore),
									A2($elm$core$Maybe$map, $author$project$PAM$EditableFields$renderScoreLabel, displayScore)
								])));
				};
			};
		}
	};
};
var $author$project$PAM$Object$FieldValue$FieldValue_SingleChoice = function (a) {
	return {$: 10, a: a};
};
var $author$project$PAM$Model$FieldChoices$Single = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$EditableFields$updateSingleChoice = F4(
	function (field, item, set_value, updateTarget) {
		var nv = function () {
			if (!set_value.$) {
				var _v1 = set_value.a;
				var value = _v1.a;
				var nam = _v1.b;
				return A2(
					$elm$core$Maybe$andThen,
					function (a) {
						return $elm$core$Maybe$Just(
							$author$project$PAM$Model$FieldChoices$Single(a));
					},
					$elm$core$String$toInt(value));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var newValues = A3(
			$author$project$PAM$Object$FieldValue$insert,
			field,
			$author$project$PAM$Object$FieldValue$FieldValue_SingleChoice(nv),
			item.au);
		var newItem = _Utils_update(
			item,
			{au: newValues});
		return updateTarget(newItem);
	});
var $author$project$PAM$EditableFields$singleChoiceField = F4(
	function (field, value, choices, updateTarget) {
		var options = A2(
			$elm$core$List$map,
			function (choice) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(choice.r),
					choice.fu);
			},
			A2(
				$elm$core$List$filter,
				function (choice) {
					return _Utils_eq(choice.bi, field.r);
				},
				choices));
		return {
			hj: function (a) {
				if (!value.$) {
					if (!value.a.$) {
						var item = value.a.a;
						return A2(
							$elm$core$Maybe$andThen,
							function (itm) {
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(
										$elm$core$String$fromInt(itm.r),
										itm.fu));
							},
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (choice) {
										return _Utils_eq(choice.r, item);
									},
									choices)));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			h4: options,
			m3: function (item) {
				return function (set_value) {
					return A4($author$project$PAM$EditableFields$updateSingleChoice, field, item, set_value, updateTarget);
				};
			}
		};
	});
var $author$project$Fields$Shared$Choice = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$Shared$Other = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Object$FieldValue$FieldValue_SingleChoiceWithOther = function (a) {
	return {$: 12, a: a};
};
var $toastal$either$Either$Left = function (a) {
	return {$: 0, a: a};
};
var $toastal$either$Either$Right = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Model$FieldChoices$SingleOrOther = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$EditableFields$updateSingleChoiceWithOther = F4(
	function (field, item, set_value, updateTarget) {
		var nv = function () {
			if (!set_value.$) {
				if (!set_value.a.$) {
					var _v1 = set_value.a.a;
					var value = _v1.a;
					var nam = _v1.b;
					return A2(
						$elm$core$Maybe$andThen,
						function (a) {
							return $elm$core$Maybe$Just(
								$author$project$PAM$Model$FieldChoices$SingleOrOther(
									$toastal$either$Either$Right(a)));
						},
						$elm$core$String$toInt(value));
				} else {
					var otherChoice = set_value.a.a;
					return $elm$core$Maybe$Just(
						$author$project$PAM$Model$FieldChoices$SingleOrOther(
							$toastal$either$Either$Left(otherChoice)));
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var newValues = A3(
			$author$project$PAM$Object$FieldValue$insert,
			field,
			$author$project$PAM$Object$FieldValue$FieldValue_SingleChoiceWithOther(nv),
			item.au);
		var newItem = _Utils_update(
			item,
			{au: newValues});
		return updateTarget(newItem);
	});
var $author$project$PAM$EditableFields$singleChoiceWithOtherField = F4(
	function (field, value, choices, updateTarget) {
		var options = A2(
			$elm$core$List$map,
			function (choice) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(choice.r),
					choice.fu);
			},
			A2(
				$elm$core$List$filter,
				function (choice) {
					return _Utils_eq(choice.bi, field.r);
				},
				choices));
		return {
			hj: function (a) {
				if ((!value.$) && (value.a.$ === 2)) {
					if (value.a.a.$ === 1) {
						var item = value.a.a.a;
						return A2(
							$elm$core$Maybe$andThen,
							function (itm) {
								return $elm$core$Maybe$Just(
									$author$project$Fields$Shared$Choice(
										_Utils_Tuple2(
											$elm$core$String$fromInt(itm.r),
											itm.fu)));
							},
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (choice) {
										return _Utils_eq(choice.r, item);
									},
									choices)));
					} else {
						var maybeChoice = value.a.a.a;
						return $elm$core$Maybe$Just(
							$author$project$Fields$Shared$Other(maybeChoice));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			hJ: $elm$core$Maybe$Just(50),
			h4: options,
			m3: function (item) {
				return function (set_value) {
					return A4($author$project$PAM$EditableFields$updateSingleChoiceWithOther, field, item, set_value, updateTarget);
				};
			}
		};
	});
var $author$project$PAM$EditableFields$stringField = F4(
	function (field, limit, value, updateTarget) {
		return {
			hj: function (item) {
				return _Utils_Tuple2(value, 'Not Set');
			},
			hJ: limit,
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_Text(set_value),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_Year = function (a) {
	return {$: 15, a: a};
};
var $author$project$PAM$EditableFields$yearField = F4(
	function (field, limit, value, updateTarget) {
		return {
			hj: function (item) {
				return _Utils_Tuple2(value, 'Not Set');
			},
			hJ: $elm$core$Maybe$Just(4),
			mJ: $elm$core$Maybe$Just(
				{
					lK: $elm$core$Maybe$Just(2099),
					lN: $elm$core$Maybe$Just(1970)
				}),
			m3: function (item) {
				return function (set_value) {
					return A4(
						$author$project$PAM$EditableFields$updateGeneric,
						field,
						$author$project$PAM$Object$FieldValue$FieldValue_Year(
							function () {
								if (set_value.$ === 1) {
									var val = set_value.a;
									var tex = set_value.b;
									return (val > 9999) ? value : A2($author$project$Fields$Shared$Valid, val, tex);
								} else {
									return set_value;
								}
							}()),
						item,
						updateTarget);
				};
			}
		};
	});
var $author$project$PAM$EditableFields$makeGenericFieldFromValue = F7(
	function (route, object, field, choices, updateTarget, maybeLink, setLayer) {
		var value = (field.fu !== 'Name') ? A2($author$project$PAM$Object$FieldValue$get, field, object.au) : $elm$core$Maybe$Just(
			$author$project$PAM$Object$FieldValue$FieldValue_Text(
				$elm$core$Maybe$Just(object.fu)));
		if (!value.$) {
			var v = value.a;
			var _v1 = _Utils_Tuple2(v, field.hb);
			_v1$16:
			while (true) {
				switch (_v1.a.$) {
					case 1:
						if (_v1.b === 1) {
							var text = _v1.a.a;
							var _v2 = _v1.b;
							return $author$project$Fields$GenericField$MaybeString(
								A4($author$project$PAM$EditableFields$stringField, field, field.hJ, text, updateTarget));
						} else {
							break _v1$16;
						}
					case 2:
						if (_v1.b === 2) {
							var text = _v1.a.a;
							var _v3 = _v1.b;
							return $author$project$Fields$GenericField$MaybeLongString(
								A4($author$project$PAM$EditableFields$longStringField, field, field.hJ, text, updateTarget));
						} else {
							break _v1$16;
						}
					case 5:
						if (_v1.b === 4) {
							var _float = _v1.a.a;
							var _v4 = _v1.b;
							return $author$project$Fields$GenericField$MaybeFloat(
								A4($author$project$PAM$EditableFields$floatField, field, field.hJ, _float, updateTarget));
						} else {
							break _v1$16;
						}
					case 3:
						if (_v1.b === 3) {
							var _int = _v1.a.a;
							var _v5 = _v1.b;
							return $author$project$Fields$GenericField$MaybeInt(
								A4($author$project$PAM$EditableFields$intField, field, field.hJ, _int, updateTarget));
						} else {
							break _v1$16;
						}
					case 4:
						if (_v1.b === 16) {
							var _int = _v1.a.a;
							var _v6 = _v1.b;
							return $author$project$Fields$GenericField$MaybeInt(
								A4($author$project$PAM$EditableFields$naturalField, field, field.hJ, _int, updateTarget));
						} else {
							break _v1$16;
						}
					case 15:
						if (_v1.b === 15) {
							var year = _v1.a.a;
							var _v7 = _v1.b;
							return $author$project$Fields$GenericField$MaybeInt(
								A4($author$project$PAM$EditableFields$yearField, field, field.hJ, year, updateTarget));
						} else {
							break _v1$16;
						}
					case 10:
						if (_v1.b === 9) {
							var choice = _v1.a.a;
							var _v8 = _v1.b;
							return $author$project$Fields$GenericField$SingleChoice(
								A4(
									$author$project$PAM$EditableFields$singleChoiceField,
									field,
									choice,
									$author$project$PAM$Model$FieldChoices$allFor(choices),
									updateTarget));
						} else {
							break _v1$16;
						}
					case 7:
						if (_v1.b === 6) {
							var date = _v1.a.a;
							var _v9 = _v1.b;
							return $author$project$Fields$GenericField$Date(
								A3($author$project$PAM$EditableFields$dateField, field, date, updateTarget));
						} else {
							break _v1$16;
						}
					case 8:
						if (_v1.b === 7) {
							var date = _v1.a.a;
							var _v10 = _v1.b;
							return $author$project$Fields$GenericField$MaybeDateRange(
								A3($author$project$PAM$EditableFields$dateRangeField, field, date, updateTarget));
						} else {
							break _v1$16;
						}
					case 6:
						if (_v1.b === 5) {
							var money = _v1.a.a;
							var _v11 = _v1.b;
							return $author$project$Fields$GenericField$MaybeFloat(
								A4($author$project$PAM$EditableFields$moneyField, field, field.hJ, money, updateTarget));
						} else {
							break _v1$16;
						}
					case 9:
						if (_v1.b === 8) {
							var flag = _v1.a.a;
							var _v12 = _v1.b;
							return $author$project$Fields$GenericField$MaybeFlag(
								A5($author$project$PAM$EditableFields$flagField, field, flag, 'Yes', 'No', updateTarget));
						} else {
							break _v1$16;
						}
					case 14:
						if (_v1.b === 12) {
							var val = _v1.a.a;
							var _v13 = _v1.b;
							return $author$project$Fields$GenericField$CustomColumnNoLabel(
								A5($author$project$PAM$EditableFields$linkedObjectsField, route, object, field, val, maybeLink));
						} else {
							break _v1$16;
						}
					case 16:
						if (_v1.b === 17) {
							var val = _v1.a.a;
							var _v14 = _v1.b;
							return $author$project$Fields$GenericField$FlagWithComment(
								A6($author$project$PAM$EditableFields$flagWithCommentField, field, val, 'Yes', 'No', field.hJ, updateTarget));
						} else {
							break _v1$16;
						}
					case 12:
						if (_v1.b === 18) {
							var val = _v1.a.a;
							var _v15 = _v1.b;
							return $author$project$Fields$GenericField$SingleChoiceWithOther(
								A4(
									$author$project$PAM$EditableFields$singleChoiceWithOtherField,
									field,
									val,
									$author$project$PAM$Model$FieldChoices$allFor(choices),
									updateTarget));
						} else {
							break _v1$16;
						}
					case 17:
						if (_v1.b === 13) {
							var val = _v1.a.a;
							var _v16 = _v1.b;
							return $author$project$Fields$GenericField$Geometry(
								A4($author$project$PAM$EditableFields$geometryField, field, val, updateTarget, setLayer));
						} else {
							break _v1$16;
						}
					case 18:
						if (_v1.b === 19) {
							var scores = _v1.a.a;
							var _v17 = _v1.b;
							return $author$project$Fields$GenericField$Custom(
								$author$project$PAM$EditableFields$scoresField(scores));
						} else {
							break _v1$16;
						}
					default:
						break _v1$16;
				}
			}
			return $author$project$Fields$GenericField$Custom(
				A3($author$project$PAM$EditableFields$notImplementedCustomField, field, v, updateTarget));
		} else {
			var _v18 = field.hb;
			switch (_v18) {
				case 1:
					return $author$project$Fields$GenericField$MaybeString(
						A4($author$project$PAM$EditableFields$stringField, field, field.hJ, $elm$core$Maybe$Nothing, updateTarget));
				case 2:
					return $author$project$Fields$GenericField$MaybeLongString(
						A4($author$project$PAM$EditableFields$longStringField, field, field.hJ, $elm$core$Maybe$Nothing, updateTarget));
				case 4:
					return $author$project$Fields$GenericField$MaybeFloat(
						A4($author$project$PAM$EditableFields$floatField, field, field.hJ, $author$project$Fields$Shared$NotEntered, updateTarget));
				case 3:
					return $author$project$Fields$GenericField$MaybeInt(
						A4($author$project$PAM$EditableFields$intField, field, field.hJ, $author$project$Fields$Shared$NotEntered, updateTarget));
				case 16:
					return $author$project$Fields$GenericField$MaybeInt(
						A4($author$project$PAM$EditableFields$naturalField, field, field.hJ, $author$project$Fields$Shared$NotEntered, updateTarget));
				case 15:
					return $author$project$Fields$GenericField$MaybeInt(
						A4($author$project$PAM$EditableFields$yearField, field, field.hJ, $author$project$Fields$Shared$NotEntered, updateTarget));
				case 9:
					return $author$project$Fields$GenericField$SingleChoice(
						A4(
							$author$project$PAM$EditableFields$singleChoiceField,
							field,
							$elm$core$Maybe$Nothing,
							$author$project$PAM$Model$FieldChoices$allFor(choices),
							updateTarget));
				case 6:
					return $author$project$Fields$GenericField$Date(
						A3($author$project$PAM$EditableFields$dateField, field, $author$project$Fields$Shared$NotEntered, updateTarget));
				case 7:
					return $author$project$Fields$GenericField$MaybeDateRange(
						A3($author$project$PAM$EditableFields$dateRangeField, field, $author$project$Fields$Shared$NotEntered, updateTarget));
				case 5:
					return $author$project$Fields$GenericField$MaybeFloat(
						A4($author$project$PAM$EditableFields$moneyField, field, field.hJ, $author$project$Fields$Shared$NotEntered, updateTarget));
				case 8:
					return $author$project$Fields$GenericField$MaybeFlag(
						A5($author$project$PAM$EditableFields$flagField, field, $elm$core$Maybe$Nothing, 'Yes', 'No', updateTarget));
				case 17:
					return $author$project$Fields$GenericField$FlagWithComment(
						A6($author$project$PAM$EditableFields$flagWithCommentField, field, $elm$core$Maybe$Nothing, 'Yes', 'No', field.hJ, updateTarget));
				case 12:
					return $author$project$Fields$GenericField$CustomColumnNoLabel(
						A5($author$project$PAM$EditableFields$linkedObjectsField, route, object, field, $elm$core$Maybe$Nothing, maybeLink));
				case 18:
					return $author$project$Fields$GenericField$SingleChoiceWithOther(
						A4(
							$author$project$PAM$EditableFields$singleChoiceWithOtherField,
							field,
							$elm$core$Maybe$Nothing,
							$author$project$PAM$Model$FieldChoices$allFor(choices),
							updateTarget));
				case 13:
					return $author$project$Fields$GenericField$Geometry(
						A4($author$project$PAM$EditableFields$geometryField, field, $author$project$Fields$Shared$NotEntered, updateTarget, setLayer));
				case 19:
					return $author$project$Fields$GenericField$Custom(
						$author$project$PAM$EditableFields$scoresField($elm$core$Maybe$Nothing));
				default:
					return $author$project$Fields$GenericField$Custom(
						A3($author$project$PAM$EditableFields$notImplementedCustomField, field, $author$project$PAM$Object$FieldValue$FieldValue_None, updateTarget));
			}
		}
	});
var $author$project$PAM$EditableFields$convertToEditField = F7(
	function (route, choices, emptyValue, updateTarget, setLayer, objectTypes, field) {
		var maybeLink = A2(
			$elm$core$Maybe$andThen,
			function (objId) {
				return A2($author$project$PAM$Model$ObjectTypes$get, objId, objectTypes);
			},
			field.is);
		return {
			a$: {fu: field.fu, ir: false},
			bq: function (object) {
				return A7($author$project$PAM$EditableFields$makeGenericFieldFromValue, route, object, field, choices, updateTarget, maybeLink, setLayer);
			},
			hT: $elm$core$Maybe$Nothing,
			eC: field,
			eI: $author$project$Fields$Shared$NoValidation
		};
	});
var $author$project$PAM$EditableFields$modelFieldsToEditFields = F6(
	function (route, choices, fields, updateTarget, setLayer, objectTypes) {
		return A2(
			$elm$core$List$map,
			A6($author$project$PAM$EditableFields$convertToEditField, route, choices, 'Empty', updateTarget, setLayer, objectTypes),
			fields);
	});
var $author$project$Fields$GenericField$BasicFieldInfo = F2(
	function (name, readOnly) {
		return {fu: name, ir: readOnly};
	});
var $author$project$PAM$EditableFields$maybeStringToString = function (input) {
	if (!input.$) {
		var value = input.a;
		return value;
	} else {
		return '';
	}
};
var $author$project$PAM$EditableFields$nameField = function (updateTarget) {
	return {
		hj: function (item) {
			return _Utils_Tuple2(
				$author$project$PAM$EditableFields$stringToMaybeString(item.fu),
				'');
		},
		hJ: $elm$core$Maybe$Just(50),
		m3: function (item) {
			return function (set_value) {
				var newItem = _Utils_update(
					item,
					{
						fu: $author$project$PAM$EditableFields$maybeStringToString(set_value)
					});
				return updateTarget(newItem);
			};
		}
	};
};
var $author$project$PAM$EditableFields$modelSystemNameField = function (updateTarget) {
	return {
		a$: A2($author$project$Fields$GenericField$BasicFieldInfo, 'Name', false),
		bq: function (a) {
			return $author$project$Fields$GenericField$MaybeString(
				$author$project$PAM$EditableFields$nameField(updateTarget));
		},
		hT: $elm$core$Maybe$Nothing,
		eC: A5($author$project$PAM$Model$ObjectModels$ModelField, -2, 'Name', 1, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
		eI: $author$project$Fields$Shared$Required(
			function (a) {
				return false && ($elm$core$String$length(a.fu) > 1);
			})
	};
};
var $author$project$PAM$EditableFields$modelSystemFields = function (updateTarget) {
	return _List_fromArray(
		[
			$author$project$PAM$EditableFields$modelSystemNameField(updateTarget)
		]);
};
var $author$project$PAM$buildDetailLayout = function (config) {
	var allFields = _Utils_ap(
		A6($author$project$PAM$EditableFields$modelFieldsToEditFields, config.mS, config.t, config.u, $author$project$PAM$Message$ChangeObjectDetails, $author$project$PAM$Message$SetLayer, config.c),
		$author$project$PAM$EditableFields$modelSystemFields($author$project$PAM$Message$ChangeObjectDetails));
	return A2($author$project$PAM$EditableFields$detailLayoutToGrid, config.v, allFields);
};
var $author$project$PAM$Message$DetailGridMsg = function (a) {
	return {$: 51, a: a};
};
var $author$project$PAM$deetsTranslator = function (msg) {
	switch (msg.$) {
		case 0:
			var m = msg.a;
			return $author$project$PAM$Message$DetailGridMsg(m);
		case 1:
			var m = msg.a;
			return m;
		default:
			return $author$project$PAM$Message$NoOp;
	}
};
var $author$project$PAM$Message$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$Message$ParentMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Message$UpdateRow = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Fields$GenericField$translator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			var sub = msg.a;
			return td.jc(sub);
		}
	});
var $author$project$PAM$EditableFields$genericFieldTranslator = F2(
	function (row, col) {
		return $author$project$Fields$GenericField$translator(
			{
				el: function (a) {
					return $author$project$PAM$Message$Internal(
						A3($author$project$PAM$Message$UpdateRow, row, col, a));
				},
				jc: function (a) {
					return $author$project$PAM$Message$ParentMsg(a);
				}
			});
	});
var $author$project$Fields$GenericField$DateMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$GenericField$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$GenericField$ParentMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$DateField$translator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			var val = msg.a;
			return td.jc(val);
		}
	});
var $author$project$Fields$GenericField$dateTranslator = F2(
	function (data, field) {
		return $author$project$Fields$DateField$translator(
			{
				el: function (a) {
					return $author$project$Fields$GenericField$Internal(
						$author$project$Fields$GenericField$DateMsg(a));
				},
				jc: function (a) {
					return $author$project$Fields$GenericField$ParentMsg(
						A2(field.m3, data, a));
				}
			});
	});
var $author$project$Fields$GenericField$GeometryMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Fields$GeometryField$translator = F2(
	function (td, msg) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				return td.el(m);
			case 1:
				var val = msg.a;
				return td.jc(val);
			default:
				var layer = msg.a;
				return td.fO(layer);
		}
	});
var $author$project$Fields$GenericField$geometryTranslator = F3(
	function (data, field, model) {
		return $author$project$Fields$GeometryField$translator(
			{
				el: function (a) {
					return $author$project$Fields$GenericField$Internal(
						$author$project$Fields$GenericField$GeometryMsg(a));
				},
				fO: function (a) {
					return $author$project$Fields$GenericField$ParentMsg(
						field.fO(a));
				},
				jc: function (a) {
					return $author$project$Fields$GenericField$ParentMsg(
						A2(field.m3, data, a));
				}
			});
	});
var $author$project$Fields$GenericField$SingleChoiceWithOtherMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Fields$SingleChoiceWithOtherField$translator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			var val = msg.a;
			return td.jc(val);
		}
	});
var $author$project$Fields$GenericField$singleChoiceWithOtherTranslator = F2(
	function (data, field) {
		return $author$project$Fields$SingleChoiceWithOtherField$translator(
			{
				el: function (a) {
					return $author$project$Fields$GenericField$Internal(
						$author$project$Fields$GenericField$SingleChoiceWithOtherMsg(a));
				},
				jc: function (a) {
					return $author$project$Fields$GenericField$ParentMsg(
						A2(field.m3, data, a));
				}
			});
	});
var $author$project$Fields$DateField$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Fields$GeometryField$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$GeometryField$MultiMessage = function (a) {
	return {$: 20, a: a};
};
var $author$project$Fields$GeometryField$DrawingMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$PlugMap$Plugins$Drawing$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Drawing$apiTranslator = F2(
	function (td, msg) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				return td.el(
					$author$project$PlugMap$Plugins$Drawing$Internal(m));
			case 1:
				return td.l1;
			case 2:
				var spatial = msg.a;
				return td.l0(spatial);
			case 3:
				var spatial = msg.a;
				return td.ma(spatial);
			case 4:
				var flat = msg.a;
				return td.mc(flat);
			default:
				var flat = msg.a;
				return td.md(flat);
		}
	});
var $author$project$Fields$GeometryField$AddShape = function (a) {
	return {$: 9, a: a};
};
var $author$project$Fields$GeometryField$drawEnd = function (shp) {
	return $author$project$Fields$GeometryField$Internal(
		$author$project$Fields$GeometryField$AddShape(
			{U: shp.U, r: shp.r, bi: shp.bi, aj: shp.aj}));
};
var $author$project$Fields$GeometryField$LockUpdating = {$: 30};
var $author$project$Fields$GeometryField$drawStart = $author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$LockUpdating);
var $author$project$Fields$GeometryField$ModifyEnd = function (a) {
	return {$: 12, a: a};
};
var $author$project$Fields$GeometryField$modifyEnd = function (spatial) {
	return $author$project$Fields$GeometryField$Internal(
		$author$project$Fields$GeometryField$ModifyEnd(spatial));
};
var $author$project$Fields$GeometryField$FlatRecalculated = function (a) {
	return {$: 13, a: a};
};
var $author$project$Fields$GeometryField$onRecalculatedFlat = function (flat) {
	return $author$project$Fields$GeometryField$Internal(
		$author$project$Fields$GeometryField$FlatRecalculated(flat));
};
var $author$project$Fields$GeometryField$FlatReeceived = function (a) {
	return {$: 10, a: a};
};
var $author$project$Fields$GeometryField$onReceivedFlat = function (flat) {
	return $author$project$Fields$GeometryField$Internal(
		$author$project$Fields$GeometryField$FlatReeceived(
			A2(
				$elm$core$Maybe$map,
				function (shp) {
					return {U: shp.U, r: shp.r, bi: shp.bi, aj: shp.aj};
				},
				flat)));
};
var $author$project$Fields$GeometryField$drawingTranslator = $author$project$PlugMap$Plugins$Drawing$apiTranslator(
	{
		l0: $author$project$Fields$GeometryField$drawEnd,
		l1: $author$project$Fields$GeometryField$drawStart,
		el: A2($elm$core$Basics$composeL, $author$project$Fields$GeometryField$Internal, $author$project$Fields$GeometryField$DrawingMsg),
		ma: $author$project$Fields$GeometryField$modifyEnd,
		mc: $author$project$Fields$GeometryField$onRecalculatedFlat,
		md: $author$project$Fields$GeometryField$onReceivedFlat
	});
var $author$project$Fields$GeometryField$FeatureLoading = {$: 29};
var $author$project$Fields$GeometryField$FeaturePickerMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$Fields$GeometryField$FeaturesLoadError = function (a) {
	return {$: 23, a: a};
};
var $author$project$Fields$GeometryField$FeaturesLoaded = function (a) {
	return {$: 22, a: a};
};
var $author$project$Fields$GeometryField$FeaturesSelected = function (a) {
	return {$: 24, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$apiTranslator = F2(
	function (td, msg) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				return td.el(m);
			case 1:
				var feats = msg.a;
				return td.l5(feats);
			case 2:
				var feats = msg.a;
				return td.l4(feats);
			case 3:
				var err = msg.a;
				return td.l3(err);
			default:
				return td.l2;
		}
	});
var $author$project$Fields$GeometryField$featurePickerTranslator = $author$project$PlugMap$Plugins$FeaturePicker$apiTranslator(
	{
		l2: $author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$FeatureLoading),
		l3: A2($elm$core$Basics$composeL, $author$project$Fields$GeometryField$Internal, $author$project$Fields$GeometryField$FeaturesLoadError),
		l4: A2($elm$core$Basics$composeL, $author$project$Fields$GeometryField$Internal, $author$project$Fields$GeometryField$FeaturesLoaded),
		l5: A2($elm$core$Basics$composeL, $author$project$Fields$GeometryField$Internal, $author$project$Fields$GeometryField$FeaturesSelected),
		el: A2($elm$core$Basics$composeL, $author$project$Fields$GeometryField$Internal, $author$project$Fields$GeometryField$FeaturePickerMsg)
	});
var $inkuzmin$elm_multiselect$Multiselect$Click = {$: 1};
var $inkuzmin$elm_multiselect$Multiselect$Opened = 2;
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, 0, 'click');
var $inkuzmin$elm_multiselect$Multiselect$subscriptions = function (_v0) {
	var model = _v0;
	return (model.aE === 2) ? $elm$browser$Browser$Events$onClick(
		$elm$json$Json$Decode$succeed($inkuzmin$elm_multiselect$Multiselect$Click)) : $elm$core$Platform$Sub$none;
};
var $author$project$PlugMap$Plugins$Drawing$DrawingStart = {$: 1};
var $author$project$PlugMap$Plugins$Drawing$NoOp = 0;
var $author$project$PlugMap$Plugins$Drawing$drawingEnd = _Platform_incomingPort(
	'drawingEnd',
	$elm$json$Json$Decode$null(0));
var $author$project$PlugMap$Plugins$Drawing$drawingEnded = _Platform_incomingPort('drawingEnded', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$Drawing$drawingStarted = _Platform_incomingPort(
	'drawingStarted',
	$elm$json$Json$Decode$null(0));
var $author$project$PlugMap$Plugins$Drawing$flattened = _Platform_incomingPort('flattened', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$Drawing$DrawingEnd = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$Drawing$ISpatial = F4(
	function (type_, wkt, acres, id) {
		return {U: acres, r: id, bi: type_, aj: wkt};
	});
var $author$project$PlugMap$Plugins$Drawing$spatialDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'id',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'acres',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'wkt',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'type_',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Drawing$ISpatial)))));
var $author$project$PlugMap$Plugins$Drawing$handleDrawingEnded = function (val) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$Drawing$spatialDecoder, val);
	if (!_v0.$) {
		var spat = _v0.a;
		return $author$project$PlugMap$Plugins$Drawing$DrawingEnd(spat);
	} else {
		var err = _v0.a;
		return $author$project$PlugMap$Plugins$Drawing$Internal(0);
	}
};
var $author$project$PlugMap$Plugins$Drawing$ReceivedFlat = function (a) {
	return {$: 5, a: a};
};
var $author$project$PlugMap$Plugins$Drawing$handleFlattened = function (val) {
	var _v0 = A2(
		$elm$json$Json$Decode$decodeValue,
		$elm$json$Json$Decode$maybe($author$project$PlugMap$Plugins$Drawing$spatialDecoder),
		val);
	if (!_v0.$) {
		var flat = _v0.a;
		return $author$project$PlugMap$Plugins$Drawing$ReceivedFlat(flat);
	} else {
		var err = _v0.a;
		return $author$project$PlugMap$Plugins$Drawing$Internal(0);
	}
};
var $author$project$PlugMap$Plugins$Drawing$ModifyEnd = function (a) {
	return {$: 3, a: a};
};
var $author$project$PlugMap$Plugins$Drawing$ModifiedSpatial = function (modifiedShapes) {
	return {lQ: modifiedShapes};
};
var $author$project$PlugMap$Plugins$Drawing$SimpleSpatial = F5(
	function (type_, wkt, acres, id, ol_uid) {
		return {U: acres, r: id, lZ: ol_uid, bi: type_, aj: wkt};
	});
var $author$project$PlugMap$Plugins$Drawing$simpleSpatialDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'ol_uid',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'acres',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'wkt',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'type_',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Drawing$SimpleSpatial))))));
var $author$project$PlugMap$Plugins$Drawing$modifiedSpatialDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'modifiedShapes',
	$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Drawing$simpleSpatialDecoder),
	$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Drawing$ModifiedSpatial));
var $author$project$PlugMap$Plugins$Drawing$handleModifyEnd = function (val) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$Drawing$modifiedSpatialDecoder, val);
	if (!_v0.$) {
		var spat = _v0.a;
		return $author$project$PlugMap$Plugins$Drawing$ModifyEnd(spat);
	} else {
		var err = _v0.a;
		return $author$project$PlugMap$Plugins$Drawing$Internal(0);
	}
};
var $author$project$PlugMap$Plugins$Drawing$RecalculatedFlat = function (a) {
	return {$: 4, a: a};
};
var $author$project$PlugMap$Plugins$Drawing$FlatRecalc = F2(
	function (flat, flatArea) {
		return {av: flat, kV: flatArea};
	});
var $author$project$PlugMap$Plugins$Drawing$flatRecalcDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'flatArea',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'flat',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Drawing$FlatRecalc)));
var $author$project$PlugMap$Plugins$Drawing$handleRecalcEnd = function (val) {
	var _v0 = A2(
		$elm$json$Json$Decode$decodeValue,
		$elm$json$Json$Decode$maybe($author$project$PlugMap$Plugins$Drawing$flatRecalcDecoder),
		val);
	if (!_v0.$) {
		var flat = _v0.a;
		return $author$project$PlugMap$Plugins$Drawing$RecalculatedFlat(flat);
	} else {
		var err = _v0.a;
		return $author$project$PlugMap$Plugins$Drawing$Internal(0);
	}
};
var $author$project$PlugMap$Plugins$Drawing$modifyEnd = _Platform_incomingPort('modifyEnd', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$Drawing$recalcEnded = _Platform_incomingPort('recalcEnded', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$Drawing$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$PlugMap$Plugins$Drawing$drawingStarted(
				$elm$core$Basics$always($author$project$PlugMap$Plugins$Drawing$DrawingStart)),
				$author$project$PlugMap$Plugins$Drawing$drawingEnded($author$project$PlugMap$Plugins$Drawing$handleDrawingEnded),
				$author$project$PlugMap$Plugins$Drawing$flattened($author$project$PlugMap$Plugins$Drawing$handleFlattened),
				$author$project$PlugMap$Plugins$Drawing$drawingEnd(
				$elm$core$Basics$always(
					$author$project$PlugMap$Plugins$Drawing$Internal(0))),
				$author$project$PlugMap$Plugins$Drawing$modifyEnd($author$project$PlugMap$Plugins$Drawing$handleModifyEnd),
				$author$project$PlugMap$Plugins$Drawing$recalcEnded($author$project$PlugMap$Plugins$Drawing$handleRecalcEnd)
			]));
};
var $author$project$PlugMap$Plugins$FeaturePicker$featureSelected = _Platform_incomingPort('featureSelected', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$FeaturePicker$FeatureClicked = function (a) {
	return {$: 6, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$NoOp = {$: 0};
var $author$project$PlugMap$Plugins$FeaturePicker$featureSelectedProcess = F2(
	function (model, val) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$string, val);
		if (!_v0.$) {
			var id = _v0.a;
			var _int = A2(
				$elm$core$Maybe$withDefault,
				-1,
				$elm$core$String$toInt(id));
			return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
				$author$project$PlugMap$Plugins$FeaturePicker$FeatureClicked(_int));
		} else {
			var err = _v0.a;
			return $author$project$PlugMap$Plugins$FeaturePicker$Internal($author$project$PlugMap$Plugins$FeaturePicker$NoOp);
		}
	});
var $author$project$PlugMap$Plugins$FeaturePicker$EsriRespondedConvert = function (a) {
	return {$: 4, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$EsriRespondedConvertError = function (a) {
	return {$: 5, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$NormalizeEsriRequest = F4(
	function (from, esri, value, showGeometry) {
		return {e2: esri, dU: from, fQ: showGeometry, aG: value};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$Select = 0;
var $author$project$PlugMap$Plugins$FeaturePicker$SetLayer = 1;
var $author$project$PlugMap$Plugins$FeaturePicker$decodeResponseFrom = A2(
	$elm$json$Json$Decode$andThen,
	function (val) {
		return $elm$json$Json$Decode$succeed(
			(val === 'Select') ? 0 : ((val === 'SetLayer') ? 1 : 1));
	},
	$elm$json$Json$Decode$string);
var $author$project$PlugMap$Plugins$FeaturePicker$EsriResponse = function (features) {
	return {cJ: features};
};
var $author$project$PlugMap$Plugins$FeaturePicker$EsriFeature = F2(
	function (properties, geometry) {
		return {k$: geometry, bG: properties};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$Esri_Float = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$Esri_Int = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$Esri_Nil = {$: 3};
var $author$project$PlugMap$Plugins$FeaturePicker$Esri_String = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$decodeEsriProperty = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (v) {
				return $elm$json$Json$Decode$succeed(
					$author$project$PlugMap$Plugins$FeaturePicker$Esri_String(v));
			},
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$andThen,
			function (v) {
				return $elm$json$Json$Decode$succeed(
					$author$project$PlugMap$Plugins$FeaturePicker$Esri_Int(v));
			},
			$elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$andThen,
			A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $author$project$PlugMap$Plugins$FeaturePicker$Esri_Float),
			$elm$json$Json$Decode$float),
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$Esri_Nil)
		]));
var $author$project$PlugMap$Plugins$FeaturePicker$decodeEsriFeature = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'geometry',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'properties',
		$elm$json$Json$Decode$dict($author$project$PlugMap$Plugins$FeaturePicker$decodeEsriProperty),
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$EsriFeature)));
var $author$project$PlugMap$Plugins$FeaturePicker$decodeEsriFeatures = $elm$json$Json$Decode$list($author$project$PlugMap$Plugins$FeaturePicker$decodeEsriFeature);
var $author$project$PlugMap$Plugins$FeaturePicker$esriResponseDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'features',
	$author$project$PlugMap$Plugins$FeaturePicker$decodeEsriFeatures,
	$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$EsriResponse));
var $author$project$PlugMap$Plugins$FeaturePicker$decodeNormalizeEsri = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'showGeometry',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'value',
		$elm$json$Json$Decode$maybe($author$project$PlugMap$Plugins$FeaturePicker$esriResponseDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'esri',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'from',
				$author$project$PlugMap$Plugins$FeaturePicker$decodeResponseFrom,
				$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$NormalizeEsriRequest)))));
var $author$project$PlugMap$Plugins$FeaturePicker$normalizeEsriProcess = function (value) {
	var ret = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$FeaturePicker$decodeNormalizeEsri, value);
	if (!ret.$) {
		var resp = ret.a;
		return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
			$author$project$PlugMap$Plugins$FeaturePicker$EsriRespondedConvert(resp));
	} else {
		var err = ret.a;
		return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
			$author$project$PlugMap$Plugins$FeaturePicker$EsriRespondedConvertError('normalize'));
	}
};
var $author$project$PlugMap$Plugins$FeaturePicker$normalizeEsriResult = _Platform_incomingPort('normalizeEsriResult', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$FeaturePicker$FeaturesLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$WfsResult = F2(
	function (attributes, wfsMembers) {
		return {jS: attributes, jn: wfsMembers};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$WfsMember = F2(
	function (typeName, properties) {
		return {bG: properties, nL: typeName};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$Nil = {$: 0};
var $author$project$PlugMap$Plugins$FeaturePicker$Text = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$decodeWFSProperty = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_text',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$Text)),
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$Nil)
		]));
var $author$project$PlugMap$Plugins$FeaturePicker$decodeWFSMembers = A2(
	$elm$json$Json$Decode$andThen,
	function (itms) {
		return $elm$json$Json$Decode$succeed(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (collection) {
						return A2(
							$elm$core$Maybe$map,
							function (_v0) {
								var typeName = _v0.a;
								var props = _v0.b;
								return A2($author$project$PlugMap$Plugins$FeaturePicker$WfsMember, typeName, props);
							},
							$elm$core$List$head(
								$elm$core$Dict$toList(collection)));
					},
					itms)));
	},
	$elm$json$Json$Decode$list(
		$elm$json$Json$Decode$dict(
			$elm$json$Json$Decode$dict($author$project$PlugMap$Plugins$FeaturePicker$decodeWFSProperty))));
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt = F3(
	function (path, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$at, path, valDecoder),
			decoder);
	});
var $author$project$PlugMap$Plugins$FeaturePicker$WfsAttributes = {};
var $author$project$PlugMap$Plugins$FeaturePicker$wfsAttributesDecoder = $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$WfsAttributes);
var $author$project$PlugMap$Plugins$FeaturePicker$wfsResultDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
	_List_fromArray(
		['wfs:FeatureCollection', 'wfs:member']),
	$author$project$PlugMap$Plugins$FeaturePicker$decodeWFSMembers,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
		_List_fromArray(
			['wfs:FeatureCollection', '_attributes']),
		$author$project$PlugMap$Plugins$FeaturePicker$wfsAttributesDecoder,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$WfsResult)));
var $author$project$PlugMap$Plugins$FeaturePicker$wfsMemberToFeature = function (member) {
	return {
		U: $elm$core$Maybe$Nothing,
		k$: $elm$core$Maybe$Nothing,
		r: A2(
			$elm$core$Maybe$withDefault,
			-1,
			A2(
				$elm$core$Maybe$map,
				function (val) {
					if (val.$ === 1) {
						var i = val.a;
						return A2(
							$elm$core$Maybe$withDefault,
							-1,
							$elm$core$String$toInt(i));
					} else {
						return -1;
					}
				},
				A2($elm$core$Dict$get, 'utwrap_PAM:id', member.bG))),
		fu: A2(
			$elm$core$Maybe$withDefault,
			'Unknown',
			A2(
				$elm$core$Maybe$map,
				function (val) {
					if (val.$ === 1) {
						var i = val.a;
						return i;
					} else {
						return 'Unknown';
					}
				},
				A2($elm$core$Dict$get, 'utwrap_PAM:name', member.bG))),
		fy: -1
	};
};
var $author$project$PlugMap$Plugins$FeaturePicker$wfsResultToFeatures = function (result) {
	return A2($elm$core$List$map, $author$project$PlugMap$Plugins$FeaturePicker$wfsMemberToFeature, result.jn);
};
var $author$project$PlugMap$Plugins$FeaturePicker$ToJsResult = F3(
	function (typ, xml, result) {
		return {fH: result, eH: typ, f7: xml};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$X2J_WFS = 0;
var $author$project$PlugMap$Plugins$FeaturePicker$decodeXmlToJsType = A2(
	$elm$json$Json$Decode$andThen,
	function (val) {
		return (val === 'WFS') ? $elm$json$Json$Decode$succeed(0) : $elm$json$Json$Decode$fail('Unknown XmlToJSType ' + val);
	},
	$elm$json$Json$Decode$string);
var $author$project$PlugMap$Plugins$FeaturePicker$xmlToJsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'result',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$value),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'xml',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'type',
			$author$project$PlugMap$Plugins$FeaturePicker$decodeXmlToJsType,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$ToJsResult))));
var $author$project$PlugMap$Plugins$FeaturePicker$xmlToJsProcess = function (result) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$FeaturePicker$xmlToJsDecoder, result);
	if (!_v0.$) {
		var val = _v0.a;
		var _v1 = _Utils_Tuple2(val.eH, val.fH);
		if (!_v1.b.$) {
			var _v2 = _v1.a;
			var json = _v1.b.a;
			var res = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$FeaturePicker$wfsResultDecoder, json);
			if (!res.$) {
				var wfsResult = res.a;
				return $author$project$PlugMap$Plugins$FeaturePicker$FeaturesLoaded(
					$author$project$PlugMap$Plugins$FeaturePicker$wfsResultToFeatures(wfsResult));
			} else {
				var err = res.a;
				return $author$project$PlugMap$Plugins$FeaturePicker$Internal($author$project$PlugMap$Plugins$FeaturePicker$NoOp);
			}
		} else {
			return $author$project$PlugMap$Plugins$FeaturePicker$Internal($author$project$PlugMap$Plugins$FeaturePicker$NoOp);
		}
	} else {
		var err = _v0.a;
		return $author$project$PlugMap$Plugins$FeaturePicker$Internal($author$project$PlugMap$Plugins$FeaturePicker$NoOp);
	}
};
var $author$project$PlugMap$Plugins$FeaturePicker$xmlToJsResult = _Platform_incomingPort('xmlToJsResult', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$FeaturePicker$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$PlugMap$Plugins$FeaturePicker$xmlToJsResult($author$project$PlugMap$Plugins$FeaturePicker$xmlToJsProcess),
				$author$project$PlugMap$Plugins$FeaturePicker$normalizeEsriResult($author$project$PlugMap$Plugins$FeaturePicker$normalizeEsriProcess),
				$author$project$PlugMap$Plugins$FeaturePicker$featureSelected(
				$author$project$PlugMap$Plugins$FeaturePicker$featureSelectedProcess(model))
			]));
};
var $author$project$PlugMap$Plugins$Upload$fileUploaded = _Platform_incomingPort('fileUploaded', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$Upload$FeaturesUploaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Upload$fileUploadedProcess = function (val) {
	return $author$project$PlugMap$Plugins$Upload$FeaturesUploaded(_List_Nil);
};
var $author$project$PlugMap$Plugins$Upload$Error = function (a) {
	return {$: 9, a: a};
};
var $author$project$PlugMap$Plugins$Upload$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Upload$UpdateStatus = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Upload$StatusUpdate = F2(
	function (status, log) {
		return {hM: log, aE: status};
	});
var $author$project$PlugMap$Plugins$Upload$Calculating = {$: 7};
var $author$project$PlugMap$Plugins$Upload$CollectingFeatures = {$: 4};
var $author$project$PlugMap$Plugins$Upload$Converting = {$: 5};
var $author$project$PlugMap$Plugins$Upload$Done = {$: 8};
var $author$project$PlugMap$Plugins$Upload$Opening = {$: 2};
var $author$project$PlugMap$Plugins$Upload$Processing = {$: 3};
var $author$project$PlugMap$Plugins$Upload$SelectingFile = {$: 1};
var $author$project$PlugMap$Plugins$Upload$Unkinking = {$: 6};
var $author$project$PlugMap$Plugins$Upload$statusDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (stringValue) {
				switch (stringValue) {
					case 'SelectingFile':
						return $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$SelectingFile);
					case 'Opening':
						return $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$Opening);
					case 'Processing':
						return $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$Processing);
					case 'CollectingFeatures':
						return $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$CollectingFeatures);
					case 'Unkinking':
						return $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$Unkinking);
					case 'Converting':
						return $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$Converting);
					case 'Calculating':
						return $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$Calculating);
					case 'Done':
						return $elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$Done);
					default:
						return $elm$json$Json$Decode$fail('Unable to parse status');
				}
			},
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$andThen,
			function (stringValue) {
				return $elm$json$Json$Decode$succeed(
					$author$project$PlugMap$Plugins$Upload$Error(stringValue));
			},
			A2(
				$elm$json$Json$Decode$field,
				'error',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string))),
			$elm$json$Json$Decode$succeed(
			$author$project$PlugMap$Plugins$Upload$Error(
				_List_fromArray(
					['Could not parse status'])))
		]));
var $author$project$PlugMap$Plugins$Upload$statusUpdateDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'log',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$author$project$PlugMap$Plugins$Upload$statusDecoder,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Upload$StatusUpdate)));
var $author$project$PlugMap$Plugins$Upload$processStatusUpdate = function (value) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$Upload$statusUpdateDecoder, value);
	if (!_v0.$) {
		var status = _v0.a;
		return $author$project$PlugMap$Plugins$Upload$Internal(
			$author$project$PlugMap$Plugins$Upload$UpdateStatus(status));
	} else {
		var error = _v0.a;
		return $author$project$PlugMap$Plugins$Upload$Internal(
			$author$project$PlugMap$Plugins$Upload$UpdateStatus(
				{
					hM: _List_Nil,
					aE: $author$project$PlugMap$Plugins$Upload$Error(
						_List_fromArray(
							['Cannot parse status update']))
				}));
	}
};
var $author$project$PlugMap$Plugins$Upload$statusUpdate = _Platform_incomingPort('statusUpdate', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$Upload$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$PlugMap$Plugins$Upload$fileUploaded($author$project$PlugMap$Plugins$Upload$fileUploadedProcess),
				$author$project$PlugMap$Plugins$Upload$statusUpdate($author$project$PlugMap$Plugins$Upload$processStatusUpdate)
			]));
};
var $author$project$Fields$GeometryField$FeatureUploaded = function (a) {
	return {$: 28, a: a};
};
var $author$project$Fields$GeometryField$UploadMsg = function (a) {
	return {$: 27, a: a};
};
var $author$project$PlugMap$Plugins$Upload$apiTranslator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			var feats = msg.a;
			return td.l6(feats);
		}
	});
var $author$project$Fields$GeometryField$uploadTranslator = $author$project$PlugMap$Plugins$Upload$apiTranslator(
	{
		l6: A2($elm$core$Basics$composeL, $author$project$Fields$GeometryField$Internal, $author$project$Fields$GeometryField$FeatureUploaded),
		el: A2($elm$core$Basics$composeL, $author$project$Fields$GeometryField$Internal, $author$project$Fields$GeometryField$UploadMsg)
	});
var $author$project$Fields$GeometryField$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Fields$GeometryField$drawingTranslator,
				$author$project$PlugMap$Plugins$Drawing$subscriptions(model.m)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Fields$GeometryField$featurePickerTranslator,
				$author$project$PlugMap$Plugins$FeaturePicker$subscriptions(model.d.D)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Fields$GeometryField$uploadTranslator,
				$author$project$PlugMap$Plugins$Upload$subscriptions(model.aF)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Fields$GeometryField$Internal,
				A2(
					$elm$core$Platform$Sub$map,
					$author$project$Fields$GeometryField$MultiMessage,
					$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.d.ac)))
			]));
};
var $author$project$Fields$SingleChoiceWithOtherField$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Fields$GenericField$subscriptions = F3(
	function (data, field, model) {
		var _v0 = _Utils_Tuple2(field, model);
		_v0$3:
		while (true) {
			switch (_v0.a.$) {
				case 10:
					if (_v0.b.$ === 3) {
						var ff = _v0.a.a;
						var mod = _v0.b.a;
						return A2(
							$elm$core$Platform$Sub$map,
							A3($author$project$Fields$GenericField$geometryTranslator, data, ff, mod),
							$author$project$Fields$GeometryField$subscriptions(mod));
					} else {
						break _v0$3;
					}
				case 4:
					if (_v0.b.$ === 1) {
						var ff = _v0.a.a;
						var mod = _v0.b.a;
						return A2(
							$elm$core$Platform$Sub$map,
							A2($author$project$Fields$GenericField$dateTranslator, data, ff),
							$author$project$Fields$DateField$subscriptions(mod));
					} else {
						break _v0$3;
					}
				case 9:
					if (_v0.b.$ === 2) {
						var ff = _v0.a.a;
						var mod = _v0.b.a;
						return A2(
							$elm$core$Platform$Sub$map,
							A2($author$project$Fields$GenericField$singleChoiceWithOtherTranslator, data, ff),
							$author$project$Fields$SingleChoiceWithOtherField$subscriptions(mod));
					} else {
						break _v0$3;
					}
				default:
					break _v0$3;
			}
		}
		return $elm$core$Platform$Sub$none;
	});
var $author$project$PAM$EditableFields$subscriptionSingle = F5(
	function (row, col, field, data, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Sub$none,
			A2(
				$elm$core$Maybe$andThen,
				function (a) {
					return $elm$core$Maybe$Just(
						A2(
							$elm$core$Platform$Sub$map,
							A2($author$project$PAM$EditableFields$genericFieldTranslator, row, col),
							A3(
								$author$project$Fields$GenericField$subscriptions,
								data,
								field.bq(data),
								a)));
				},
				model));
	});
var $author$project$PAM$EditableFields$subRow = F4(
	function (rowNum, fieldRow, data, models) {
		return $elm$core$Platform$Sub$batch(
			A2(
				$elm$core$List$indexedMap,
				function (index) {
					return function (field) {
						return A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Sub$none,
							A2(
								$elm$core$Maybe$andThen,
								function (a) {
									return $elm$core$Maybe$Just(
										A5($author$project$PAM$EditableFields$subscriptionSingle, rowNum, index, field, data, a));
								},
								$elm$core$List$head(
									A2($elm$core$List$drop, index, models))));
					};
				},
				fieldRow));
	});
var $author$project$PAM$EditableFields$subscriptions = F3(
	function (fields, models, data) {
		return $elm$core$Platform$Sub$batch(
			A2(
				$elm$core$List$indexedMap,
				function (index) {
					return function (rowFields) {
						return A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Sub$none,
							A2(
								$elm$core$Maybe$andThen,
								function (a) {
									return $elm$core$Maybe$Just(
										A4($author$project$PAM$EditableFields$subRow, index, rowFields, data, a));
								},
								$elm$core$List$head(
									A2($elm$core$List$drop, index, models))));
					};
				},
				fields));
	});
var $author$project$PAM$detailSubscriptions = function (config) {
	var grid = $author$project$PAM$buildDetailLayout(config);
	var subs = A3($author$project$PAM$EditableFields$subscriptions, grid, config.e, config.n);
	return A2($elm$core$Platform$Sub$map, $author$project$PAM$deetsTranslator, subs);
};
var $elm_community$dict_extra$Dict$Extra$find = F2(
	function (predicate, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, acc) {
					if (!acc.$) {
						return acc;
					} else {
						return A2(predicate, k, v) ? $elm$core$Maybe$Just(
							_Utils_Tuple2(k, v)) : $elm$core$Maybe$Nothing;
					}
				}),
			$elm$core$Maybe$Nothing,
			dict);
	});
var $author$project$PAM$Model$ObjectTypes$findByKey = F2(
	function (key, _v0) {
		var types = _v0;
		return A2(
			$elm_community$dict_extra$Dict$Extra$find,
			F2(
				function (_v1, val) {
					return _Utils_eq(val.lw, key);
				}),
			types);
	});
var $author$project$PAM$Model$DetailLayouts$get = F2(
	function (id, _v0) {
		var layouts = _v0;
		return A2($elm$core$Dict$get, id, layouts);
	});
var $author$project$PAM$Model$FieldChoices$get = F2(
	function (id, _v0) {
		var choices = _v0;
		return A2($elm$core$Dict$get, id, choices);
	});
var $author$project$PAM$Model$ObjectModels$get = F2(
	function (id, _v0) {
		var fields = _v0;
		return A2($elm$core$Dict$get, id, fields);
	});
var $author$project$PAM$Object$Repo$getNewObject = function (_v0) {
	var repo = _v0.a;
	return A2($elm$core$Dict$get, -1, repo);
};
var $author$project$PAM$Object$Repo$getObject = F2(
	function (id, _v0) {
		var repo = _v0.a;
		var object = A2($elm$core$Dict$get, id, repo);
		if (!object.$) {
			var obj = object.a;
			return _Utils_eq(obj.r, id) ? $elm$core$Maybe$Just(obj) : $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Maybe$map4 = F5(
	function (func, ma, mb, mc, md) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						return $elm$core$Maybe$Just(
							A4(func, a, b, c, d));
					}
				}
			}
		}
	});
var $author$project$PAM$subDetails = function (model) {
	var _v0 = model.mS;
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 0:
				var params = _v0.a.a;
				return $elm$core$Maybe$Nothing;
			case 1:
				var params = _v0.a.a;
				return $elm$core$Maybe$Nothing;
			case 2:
				var _v1 = _v0.a;
				var objTypeKey = _v1.a;
				var params = _v1.b;
				var _v2 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
				if (!_v2.$) {
					var _v3 = _v2.a;
					var objTypeId = _v3.a;
					var objType = _v3.b;
					return A5(
						$elm$core$Maybe$map4,
						F4(
							function (newObj, choices, layout, fields) {
								return $author$project$PAM$detailSubscriptions(
									{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS});
							}),
						$author$project$PAM$Object$Repo$getNewObject(model.b),
						A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
						A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
						A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			case 3:
				var _v4 = _v0.a;
				var parentObjTypeKey = _v4.a;
				var parentObjId = _v4.b;
				var childObjTypeKey = _v4.c;
				var params = _v4.d;
				var _v5 = A2($author$project$PAM$Model$ObjectTypes$findByKey, childObjTypeKey, model.a.c);
				if (!_v5.$) {
					var _v6 = _v5.a;
					var objTypeId = _v6.a;
					return A5(
						$elm$core$Maybe$map4,
						F4(
							function (newObj, choices, layout, fields) {
								return $author$project$PAM$detailSubscriptions(
									{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS});
							}),
						$author$project$PAM$Object$Repo$getNewObject(model.b),
						A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
						A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
						A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			case 4:
				var _v7 = _v0.a;
				var objTypeKey = _v7.a;
				var objId = _v7.b;
				var params = _v7.c;
				var _v8 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
				if (!_v8.$) {
					var _v9 = _v8.a;
					var objTypeId = _v9.a;
					return A5(
						$elm$core$Maybe$map4,
						F4(
							function (newObj, choices, layout, fields) {
								return $author$project$PAM$detailSubscriptions(
									{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS});
							}),
						A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
						A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
						A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
						A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			default:
				var _v10 = _v0.a;
				var objTypeKey = _v10.a;
				var objId = _v10.b;
				var params = _v10.c;
				var _v11 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
				if (!_v11.$) {
					var _v12 = _v11.a;
					var objTypeId = _v12.a;
					return A5(
						$elm$core$Maybe$map4,
						F4(
							function (newObj, choices, layout, fields) {
								return $author$project$PAM$detailSubscriptions(
									{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS});
							}),
						A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
						A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
						A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
						A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
				} else {
					return $elm$core$Maybe$Nothing;
				}
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Animation$Model$Tick = $elm$core$Basics$identity;
var $author$project$Animation$isRunning = function (_v0) {
	var model = _v0;
	return model.ex;
};
var $elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {fx: oldTime, iv: request, iX: subs};
	});
var $elm$browser$Browser$AnimationManager$init = $elm$core$Task$succeed(
	A3($elm$browser$Browser$AnimationManager$State, _List_Nil, $elm$core$Maybe$Nothing, 0));
var $elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var $elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _v0) {
		var request = _v0.iv;
		var oldTime = _v0.fx;
		var _v1 = _Utils_Tuple2(request, subs);
		if (_v1.a.$ === 1) {
			if (!_v1.b.b) {
				var _v2 = _v1.a;
				return $elm$browser$Browser$AnimationManager$init;
			} else {
				var _v4 = _v1.a;
				return A2(
					$elm$core$Task$andThen,
					function (pid) {
						return A2(
							$elm$core$Task$andThen,
							function (time) {
								return $elm$core$Task$succeed(
									A3(
										$elm$browser$Browser$AnimationManager$State,
										subs,
										$elm$core$Maybe$Just(pid),
										time));
							},
							$elm$browser$Browser$AnimationManager$now);
					},
					$elm$core$Process$spawn(
						A2(
							$elm$core$Task$andThen,
							$elm$core$Platform$sendToSelf(router),
							$elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_v1.b.b) {
				var pid = _v1.a.a;
				return A2(
					$elm$core$Task$andThen,
					function (_v3) {
						return $elm$browser$Browser$AnimationManager$init;
					},
					$elm$core$Process$kill(pid));
			} else {
				return $elm$core$Task$succeed(
					A3($elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var $elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _v0) {
		var subs = _v0.iX;
		var oldTime = _v0.fx;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(
						$elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			$elm$core$Task$andThen,
			function (pid) {
				return A2(
					$elm$core$Task$andThen,
					function (_v1) {
						return $elm$core$Task$succeed(
							A3(
								$elm$browser$Browser$AnimationManager$State,
								subs,
								$elm$core$Maybe$Just(pid),
								newTime));
					},
					$elm$core$Task$sequence(
						A2($elm$core$List$map, send, subs)));
			},
			$elm$core$Process$spawn(
				A2(
					$elm$core$Task$andThen,
					$elm$core$Platform$sendToSelf(router),
					$elm$browser$Browser$AnimationManager$rAF)));
	});
var $elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Time(
				A2($elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Delta(
				A2($elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager($elm$browser$Browser$AnimationManager$init, $elm$browser$Browser$AnimationManager$onEffects, $elm$browser$Browser$AnimationManager$onSelfMsg, 0, $elm$browser$Browser$AnimationManager$subMap);
var $elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var $elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return $elm$browser$Browser$AnimationManager$subscription(
		$elm$browser$Browser$AnimationManager$Time(tagger));
};
var $elm$browser$Browser$Events$onAnimationFrame = $elm$browser$Browser$AnimationManager$onAnimationFrame;
var $author$project$Animation$subscription = F2(
	function (msg, states) {
		return A2($elm$core$List$any, $author$project$Animation$isRunning, states) ? A2(
			$elm$core$Platform$Sub$map,
			msg,
			$elm$browser$Browser$Events$onAnimationFrame($elm$core$Basics$identity)) : $elm$core$Platform$Sub$none;
	});
var $author$project$InPageOAuth$CodeReceived = function (a) {
	return {$: 2, a: a};
};
var $author$project$InPageOAuth$oAuthCodeReceived = _Platform_incomingPort('oAuthCodeReceived', $elm$json$Json$Decode$string);
var $author$project$InPageOAuth$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$InPageOAuth$oAuthCodeReceived(
				function (code) {
					return $author$project$InPageOAuth$Internal(
						$author$project$InPageOAuth$CodeReceived(code));
				})
			]));
};
var $author$project$PAM$Map$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$Map$MapMessage = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$Map$Msg_Drawing = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Map$PluginMessage = function (a) {
	return {$: 5, a: a};
};
var $author$project$PAM$Map$ColorUnderPointerMessage = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$Map$UpdateColor = function (a) {
	return {$: 12, a: a};
};
var $author$project$PlugMap$Plugins$ColorUnderPointer$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$ColorUnderPointer$apiTranslator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(
				$author$project$PlugMap$Plugins$ColorUnderPointer$Internal(m));
		} else {
			var color = msg.a;
			return td.mb(color);
		}
	});
var $author$project$PAM$Map$colorUnderPointerTranslator = $author$project$PlugMap$Plugins$ColorUnderPointer$apiTranslator(
	{
		el: function (msg) {
			return $author$project$PAM$Map$Internal(
				$author$project$PAM$Map$ColorUnderPointerMessage(msg));
		},
		mb: function (a) {
			return $author$project$PAM$Map$Internal(
				$author$project$PAM$Map$UpdateColor(
					$elm$core$Maybe$Just(a)));
		}
	});
var $author$project$PAM$Map$GetDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$PAM$Map$IdentifyMessage = function (a) {
	return {$: 4, a: a};
};
var $author$project$PlugMap$Plugins$Identify$apiTranslator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			var id = msg.a;
			var typeKey = msg.b;
			return A2(td.l7, id, typeKey);
		}
	});
var $author$project$PAM$Map$identifyTranslator = $author$project$PlugMap$Plugins$Identify$apiTranslator(
	{
		l7: $author$project$PAM$Map$GetDetails,
		el: function (id) {
			return $author$project$PAM$Map$Internal(
				$author$project$PAM$Map$IdentifyMessage(id));
		}
	});
var $author$project$PlugMap$Core$Ports$extentUpdated = _Platform_incomingPort('extentUpdated', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Core$ExtentUpdated = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Core$Noop = {$: 0};
var $author$project$PlugMap$Core$Known = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Core$MapExtent = F5(
	function (xmin, ymin, xmax, ymax, center) {
		return {gB: center, jr: xmax, js: xmin, ju: ymax, jv: ymin};
	});
var $author$project$PlugMap$Core$Location = F2(
	function (x, y) {
		return {dc: x, dd: y};
	});
var $author$project$PlugMap$Core$locationDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'y',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'x',
		$elm$json$Json$Decode$float,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Core$Location)));
var $author$project$PlugMap$Core$mapExtentDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'center',
	$author$project$PlugMap$Core$locationDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'ymax',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'xmax',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'ymin',
				$elm$json$Json$Decode$float,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'xmin',
					$elm$json$Json$Decode$float,
					$elm$json$Json$Decode$succeed($author$project$PlugMap$Core$MapExtent))))));
var $author$project$PlugMap$Core$extentDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$PlugMap$Core$Known, $author$project$PlugMap$Core$mapExtentDecoder),
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Core$Unknown)
		]));
var $author$project$PlugMap$Core$handleExtentUpdated = F2(
	function (model, value) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Core$extentDecoder, value);
		if (!_v0.$) {
			var extent = _v0.a;
			return $author$project$PlugMap$Core$ExtentUpdated(extent);
		} else {
			var err = _v0.a;
			return $author$project$PlugMap$Core$Noop;
		}
	});
var $author$project$PlugMap$Core$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$PlugMap$Core$Ports$extentUpdated(
				$author$project$PlugMap$Core$handleExtentUpdated(model))
			]));
};
var $author$project$PlugMap$Plugins$ColorUnderPointer$colorChanged = _Platform_incomingPort('colorChanged', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$ColorUnderPointer$I_NoOp = {$: 0};
var $author$project$PlugMap$Plugins$ColorUnderPointer$NewColor = function (a) {
	return {$: 1, a: a};
};
var $avh4$elm_color$Color$scaleFrom255 = function (c) {
	return c / 255;
};
var $avh4$elm_color$Color$rgb255 = F3(
	function (r, g, b) {
		return A4(
			$avh4$elm_color$Color$RgbaSpace,
			$avh4$elm_color$Color$scaleFrom255(r),
			$avh4$elm_color$Color$scaleFrom255(g),
			$avh4$elm_color$Color$scaleFrom255(b),
			1.0);
	});
var $author$project$PlugMap$Plugins$ColorUnderPointer$colorDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'b',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'g',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'r',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($avh4$elm_color$Color$rgb255))));
var $author$project$PlugMap$Plugins$ColorUnderPointer$handleColorChange = function (value) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$ColorUnderPointer$colorDecoder, value);
	if (!_v0.$) {
		var color = _v0.a;
		return $author$project$PlugMap$Plugins$ColorUnderPointer$NewColor(color);
	} else {
		return $author$project$PlugMap$Plugins$ColorUnderPointer$Internal($author$project$PlugMap$Plugins$ColorUnderPointer$I_NoOp);
	}
};
var $author$project$PlugMap$Plugins$ColorUnderPointer$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$PlugMap$Plugins$ColorUnderPointer$colorChanged($author$project$PlugMap$Plugins$ColorUnderPointer$handleColorChange)
			]));
};
var $author$project$PlugMap$Plugins$Identify$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Identify$NoOp = {$: 1};
var $author$project$PlugMap$Plugins$Identify$GetDetails = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$PlugMap$Plugins$Identify$getDetails = F2(
	function (model, req) {
		return A2($author$project$PlugMap$Plugins$Identify$GetDetails, req.r, req.ja);
	});
var $author$project$PlugMap$Plugins$Identify$GetDetailsRequest = F2(
	function (id, typeKey) {
		return {r: id, ja: typeKey};
	});
var $author$project$PlugMap$Plugins$Identify$getDetailsRequestDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'typeKey',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Identify$GetDetailsRequest)));
var $author$project$PlugMap$Plugins$Identify$handleGetDetails = F2(
	function (model, val) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$Identify$getDetailsRequestDecoder, val);
		if (!_v0.$) {
			var req = _v0.a;
			return A2($author$project$PlugMap$Plugins$Identify$getDetails, model, req);
		} else {
			return $author$project$PlugMap$Plugins$Identify$Internal($author$project$PlugMap$Plugins$Identify$NoOp);
		}
	});
var $author$project$PlugMap$Plugins$Identify$IdentifyResultsReceived = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Identify$IdentifyResults = F3(
	function (clickX, clickY, results) {
		return {kg: clickX, kh: clickY, iy: results};
	});
var $author$project$PlugMap$Plugins$Identify$Results = F2(
	function (layerId, fields) {
		return {u: fields, lA: layerId};
	});
var $author$project$PlugMap$Plugins$Identify$resultsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'fields',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'layerID',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Identify$Results)));
var $author$project$PlugMap$Plugins$Identify$identifyResultsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'results',
	$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Identify$resultsDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'clickY',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'clickX',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Identify$IdentifyResults))));
var $author$project$PlugMap$Plugins$Identify$handleIdentifyResults = function (value) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$PlugMap$Plugins$Identify$identifyResultsDecoder, value);
	if (!_v0.$) {
		var results = _v0.a;
		return $author$project$PlugMap$Plugins$Identify$Internal(
			$author$project$PlugMap$Plugins$Identify$IdentifyResultsReceived(results));
	} else {
		var err = _v0.a;
		return $author$project$PlugMap$Plugins$Identify$Internal($author$project$PlugMap$Plugins$Identify$NoOp);
	}
};
var $author$project$PlugMap$Plugins$Identify$identifyGetDetails = _Platform_incomingPort('identifyGetDetails', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$Identify$identifyResults = _Platform_incomingPort('identifyResults', $elm$json$Json$Decode$value);
var $author$project$PlugMap$Plugins$Identify$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$PlugMap$Plugins$Identify$identifyResults($author$project$PlugMap$Plugins$Identify$handleIdentifyResults),
				$author$project$PlugMap$Plugins$Identify$identifyGetDetails(
				$author$project$PlugMap$Plugins$Identify$handleGetDetails(model))
			]));
};
var $author$project$PAM$Map$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$PAM$Map$colorUnderPointerTranslator,
				$author$project$PlugMap$Plugins$ColorUnderPointer$subscriptions(model.cB)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$PAM$Map$Internal,
				A2(
					$elm$core$Platform$Sub$map,
					$author$project$PAM$Map$PluginMessage,
					A2(
						$elm$core$Platform$Sub$map,
						$author$project$PAM$Map$Msg_Drawing,
						$author$project$PlugMap$Plugins$Drawing$subscriptions(model.at)))),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$PAM$Map$identifyTranslator,
				$author$project$PlugMap$Plugins$Identify$subscriptions(model.aO)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$PAM$Map$Internal,
				A2(
					$elm$core$Platform$Sub$map,
					$author$project$PAM$Map$MapMessage,
					$author$project$PlugMap$Core$subscriptions(model.h)))
			]));
};
var $author$project$PAM$UI$DateRangeFilter$ClickClearButton = {$: 2};
var $author$project$PAM$UI$DateRangeFilter$clearFilter = _Platform_incomingPort(
	'clearFilter',
	$elm$json$Json$Decode$null(0));
var $author$project$PAM$UI$DateRangeFilter$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$PAM$UI$DateRangeFilter$clearFilter(
				$elm$core$Basics$always($author$project$PAM$UI$DateRangeFilter$ClickClearButton))
			]));
};
var $author$project$PAM$UI$LeftSidebarSearch$SetFilter = function (a) {
	return {$: 5, a: a};
};
var $author$project$PAM$UI$LeftSidebarSearch$setFilter = function (layerDefs) {
	return $author$project$PAM$UI$LeftSidebarSearch$SetFilter(layerDefs);
};
var $author$project$PAM$UI$LeftSidebarSearch$setRangeFilters = _Platform_incomingPort('setRangeFilters', $elm$json$Json$Decode$string);
var $author$project$PAM$UI$LeftSidebarSearch$subscriptions = function (_v0) {
	return $author$project$PAM$UI$LeftSidebarSearch$setRangeFilters($author$project$PAM$UI$LeftSidebarSearch$setFilter);
};
var $author$project$Ports$triggerAnimationSub = _Platform_incomingPort('triggerAnimationSub', $elm$json$Json$Decode$value);
var $author$project$PAM$subscriptions = function (app) {
	switch (app.$) {
		case 0:
			var model = app.a;
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						$elm$browser$Browser$Events$onResize($author$project$PAM$Message$WindowResized),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$PAM$mapTranslater,
						$author$project$PAM$Map$subscriptions(model.h)),
						A2(
						$author$project$Animation$subscription,
						$author$project$PAM$Message$Animate,
						$author$project$PAM$animations(model)),
						$author$project$Ports$settingsLoaded(
						$author$project$PAM$handleSettingsLoaded(model)),
						$author$project$Ports$returnModelInfo(
						$author$project$PAM$handleGotModelInfo(model)),
						$author$project$Ports$triggerAnimationSub($author$project$PAM$Message$HandleAnimationTrigger),
						A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Sub$none,
						$author$project$PAM$subDetails(model)),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$PAM$authTranslator,
						$author$project$InPageOAuth$subscriptions(model.ar)),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$PAM$leftSidebarSearchFilterTranslator,
						$author$project$PAM$UI$LeftSidebarSearch$subscriptions(model.aP)),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$PAM$dateRangeFilterTranslator,
						$author$project$PAM$UI$DateRangeFilter$subscriptions(model.bo))
					]));
		case 1:
			var err = app.a;
			return $elm$core$Platform$Sub$none;
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$PAM$UserUnauthorized = {$: 2};
var $author$project$PlugMap$Plugins$Themes$CategoryKey = $elm$core$Basics$identity;
var $author$project$PAM$Modal$ConfirmModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Message$GridMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$PlugMap$Plugins$Themes$LayerKey = $elm$core$Basics$identity;
var $author$project$PAM$Message$MapConfigured = {$: 63};
var $author$project$PAM$UI$Palette$AppPalette = F4(
	function (header, sidePanel, bottomGrid, modal) {
		return {bl: bottomGrid, fa: header, j: modal, iO: sidePanel};
	});
var $author$project$PAM$UI$Palette$PaletteSet = F2(
	function (background, action) {
		return {S: action, cx: background};
	});
var $author$project$PAM$UI$Palette$Palette = F6(
	function (primary, secondary, tertiary, quaternary, quinary, senary) {
		return {mB: primary, mF: quaternary, cd: quinary, fK: secondary, iI: senary, fW: tertiary};
	});
var $author$project$PAM$UI$Palette$ColorSet = F2(
	function (color, text) {
		return {G: color, eE: text};
	});
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$PAM$UI$Basic$rgb255ColorDecoder = A4(
	$elm$json$Json$Decode$map3,
	$mdgriffith$elm_ui$Element$rgb255,
	A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$index, 2, $elm$json$Json$Decode$int));
var $author$project$PAM$UI$Palette$colorSetWithDefaultDecoder = function (_default) {
	return A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'text',
		$author$project$PAM$UI$Basic$rgb255ColorDecoder,
		_default.eE,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'color',
			$author$project$PAM$UI$Basic$rgb255ColorDecoder,
			_default.G,
			$elm$json$Json$Decode$succeed($author$project$PAM$UI$Palette$ColorSet)));
};
var $author$project$PAM$UI$Palette$paletteWithDefaultDecoder = function (_default) {
	return A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'senary',
		$author$project$PAM$UI$Palette$colorSetWithDefaultDecoder(_default.iI),
		_default.iI,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'quinary',
			$author$project$PAM$UI$Palette$colorSetWithDefaultDecoder(_default.cd),
			_default.cd,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'quaternary',
				$author$project$PAM$UI$Palette$colorSetWithDefaultDecoder(_default.mF),
				_default.mF,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'tertiary',
					$author$project$PAM$UI$Palette$colorSetWithDefaultDecoder(_default.fW),
					_default.fW,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'secondary',
						$author$project$PAM$UI$Palette$colorSetWithDefaultDecoder(_default.fK),
						_default.fK,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'primary',
							$author$project$PAM$UI$Palette$colorSetWithDefaultDecoder(_default.mB),
							_default.mB,
							$elm$json$Json$Decode$succeed($author$project$PAM$UI$Palette$Palette)))))));
};
var $author$project$PAM$UI$Palette$paletteSetWithDefaultDecoder = function (_default) {
	return A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'action',
		$author$project$PAM$UI$Palette$paletteWithDefaultDecoder(_default.S),
		_default.S,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'background',
			$author$project$PAM$UI$Palette$paletteWithDefaultDecoder(_default.cx),
			_default.cx,
			$elm$json$Json$Decode$succeed($author$project$PAM$UI$Palette$PaletteSet)));
};
var $author$project$PAM$UI$Palette$appPaletteWithDefaultDecoder = function (_default) {
	return A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'modal',
		$author$project$PAM$UI$Palette$paletteSetWithDefaultDecoder(_default.j),
		_default.j,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'bottomGrid',
			$author$project$PAM$UI$Palette$paletteSetWithDefaultDecoder(_default.bl),
			_default.bl,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'sidePanel',
				$author$project$PAM$UI$Palette$paletteSetWithDefaultDecoder(_default.iO),
				_default.iO,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'header',
					$author$project$PAM$UI$Palette$paletteSetWithDefaultDecoder(_default.fa),
					_default.fa,
					$elm$json$Json$Decode$succeed($author$project$PAM$UI$Palette$AppPalette)))));
};
var $author$project$PAM$Message$CancelClicked = {$: 1};
var $author$project$PAM$Message$ConfirmCancelObjectEdit = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$Message$ConfirmMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$PAM$Modal$OkCancelConfig = $elm$core$Basics$identity;
var $author$project$PAM$confirmation_CancelEdit = function (okCmd) {
	return {
		bn: {
			dy: 'No',
			bU: $author$project$PAM$Message$ConfirmMsg($author$project$PAM$Message$CancelClicked),
			ej: 'Yes',
			ek: $author$project$PAM$Message$ConfirmMsg(
				$author$project$PAM$Message$ConfirmCancelObjectEdit(okCmd))
		},
		ee: 'Your changes have not been saved. If you leave, your changes will be lost. Are you sure you want to leave?',
		F: 'Confirm Leave'
	};
};
var $author$project$PAM$cancelEditDetails = F2(
	function (_v0, model) {
		var key = _v0.a;
		var id = _v0.b;
		var okCmd = A2(
			$author$project$PAM$Route$pushUrl,
			model.lw,
			A4($author$project$PAM$Route$initShowObjectDetails, key, id, $elm$core$Maybe$Nothing, model.mS));
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$PAM$Modal$ConfirmModal(
						$author$project$PAM$confirmation_CancelEdit(okCmd))
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$PAM$Message$ConfirmCancelObjectLink = function (a) {
	return {$: 4, a: a};
};
var $author$project$PAM$confirmation_CancelNewLink = function (okCmd) {
	return {
		bn: {
			dy: 'No',
			bU: $author$project$PAM$Message$ConfirmMsg($author$project$PAM$Message$CancelClicked),
			ej: 'Yes',
			ek: $author$project$PAM$Message$ConfirmMsg(
				$author$project$PAM$Message$ConfirmCancelObjectLink(okCmd))
		},
		ee: 'Your changes have not been saved. If you leave, your changes will be lost. Are you sure you want to leave?',
		F: 'Confirm Leave'
	};
};
var $author$project$PAM$Route$initEditObjectDetails = F4(
	function (objTypeKey, objId, params, route) {
		return A3(
			$author$project$PAM$Route$EditObjectDetails,
			objTypeKey,
			objId,
			A2($author$project$PAM$Route$pickQueryParams, params, route));
	});
var $author$project$PAM$cancelLinkDetails = F2(
	function (_v0, model) {
		var parentKey = _v0.a;
		var parentId = _v0.b;
		var okCmd = A2(
			$author$project$PAM$Route$pushUrl,
			model.lw,
			A4($author$project$PAM$Route$initEditObjectDetails, parentKey, parentId, $elm$core$Maybe$Nothing, model.mS));
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j: $author$project$PAM$Modal$ConfirmModal(
						$author$project$PAM$confirmation_CancelNewLink(okCmd))
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$PAM$Message$ConfirmCancelNewObject = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$confirmation_CancelNew = function (okCmd) {
	return {
		bn: {
			dy: 'No',
			bU: $author$project$PAM$Message$ConfirmMsg($author$project$PAM$Message$CancelClicked),
			ej: 'Yes',
			ek: $author$project$PAM$Message$ConfirmMsg(
				$author$project$PAM$Message$ConfirmCancelNewObject(okCmd))
		},
		ee: 'Your changes have not been saved. If you leave, your changes will be lost. Are you sure you want to leave?',
		F: 'Confirm Leave'
	};
};
var $author$project$PAM$cancelNewObject = function (model) {
	var okCmd = A2(
		$author$project$PAM$Route$pushUrl,
		model.lw,
		A2($author$project$PAM$Route$initMap, $elm$core$Maybe$Nothing, model.mS));
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{
				j: $author$project$PAM$Modal$ConfirmModal(
					$author$project$PAM$confirmation_CancelNew(okCmd))
			}),
		$elm$core$Platform$Cmd$none);
};
var $author$project$PAM$Object$Repo$changeObject = F2(
	function (obj, _v0) {
		var repo = _v0.a;
		var backup = _v0.b;
		return A2(
			$author$project$PAM$Object$Repo$Repo,
			A3($elm$core$Dict$insert, obj.r, obj, repo),
			backup);
	});
var $author$project$PlugMap$Plugins$Search$clearSearchCmd = _Platform_outgoingPort(
	'clearSearchCmd',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Search$clear = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{iy: $krisajenkins$remotedata$RemoteData$NotAsked}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$PlugMap$Plugins$Search$clearSearchCmd(0)
				])));
};
var $author$project$Animation$extractInitialWait = function (steps) {
	var _v0 = $elm$core$List$head(steps);
	if (_v0.$ === 1) {
		return _Utils_Tuple2(
			$elm$time$Time$millisToPosix(0),
			_List_Nil);
	} else {
		var step = _v0.a;
		if (step.$ === 4) {
			var till = step.a;
			var _v2 = $author$project$Animation$extractInitialWait(
				A2($elm$core$List$drop, 1, steps));
			var additionalTime = _v2.a;
			var remainingSteps = _v2.b;
			return _Utils_Tuple2(
				$elm$time$Time$millisToPosix(
					$elm$time$Time$posixToMillis(till) + $elm$time$Time$posixToMillis(additionalTime)),
				remainingSteps);
		} else {
			return _Utils_Tuple2(
				$elm$time$Time$millisToPosix(0),
				steps);
		}
	}
};
var $author$project$Animation$interrupt = F2(
	function (steps, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				fh: A2(
					$elm$core$List$cons,
					$author$project$Animation$extractInitialWait(steps),
					model.fh),
				ex: true
			});
	});
var $author$project$Animation$Model$To = function (a) {
	return {$: 1, a: a};
};
var $author$project$Animation$to = function (props) {
	return $author$project$Animation$Model$To(props);
};
var $author$project$Animation$Model$ToWith = function (a) {
	return {$: 2, a: a};
};
var $author$project$Animation$toWith = F2(
	function (interp, props) {
		return $author$project$Animation$Model$ToWith(
			A2(
				$elm$core$List$map,
				$author$project$Animation$Model$mapToMotion(
					function (m) {
						return _Utils_update(
							m,
							{b3: interp});
					}),
				props));
	});
var $author$project$PAM$collapseBottomDrawer = function (model) {
	return _Utils_update(
		model,
		{
			aI: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						$author$project$Animation$to(
						$author$project$PAM$UI$Basic$bottomDrawerStates(model.fD).dA)
					]),
				model.aI),
			j8: 1,
			aJ: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						A2(
						$author$project$Animation$toWith,
						$author$project$Animation$speed(
							{ic: 10.0}),
						$author$project$PAM$UI$Basic$toggleStates.fI)
					]),
				model.aJ),
			R: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						$author$project$Animation$to(
						$author$project$PAM$UI$Basic$leftSidebarStates(model.fD).dV)
					]),
				model.R)
		});
};
var $author$project$PAM$collapseLeftSidebar = function (model) {
	var sidebarFx = $author$project$PAM$UI$Basic$leftSidebarStates(model.fD).dA;
	var legendFx = A2($author$project$PAM$UI$Basic$riskLegendStates, model.f5, model.fD).dV;
	return _Utils_update(
		model,
		{
			aV: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						A2(
						$author$project$Animation$toWith,
						$author$project$Animation$speed(
							{ic: 1800.0}),
						legendFx)
					]),
				model.aV),
			aX: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						A2(
						$author$project$Animation$toWith,
						$author$project$Animation$speed(
							{ic: 1800.0}),
						sidebarFx)
					]),
				model.aX),
			m8: 1,
			aY: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						A2(
						$author$project$Animation$toWith,
						$author$project$Animation$speed(
							{ic: 10.0}),
						$author$project$PAM$UI$Basic$toggleStates.ev)
					]),
				model.aY)
		});
};
var $author$project$PlugMap$Plugins$Themes$encodeCategoryKey = function (_v0) {
	var key = _v0;
	return $elm$json$Json$Encode$string(key);
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$PlugMap$Plugins$Themes$encodeCategoryTransparency = function (category) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'category_key',
				$author$project$PlugMap$Plugins$Themes$encodeCategoryKey(category.lw)),
				_Utils_Tuple2(
				'transparency',
				$elm$json$Json$Encode$float(category.i8))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeLayerKey = function (_v0) {
	var key = _v0;
	return $elm$json$Json$Encode$string(key);
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm_community$json_extra$Json$Encode$Extra$maybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($elm$json$Json$Encode$null));
};
var $author$project$PlugMap$Plugins$Themes$encodeSelection = function (selection) {
	switch (selection.$) {
		case 0:
			var maybeKey = selection.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'selection_type',
						$elm$json$Json$Encode$string('monoselection')),
						_Utils_Tuple2(
						'selection_key',
						A2($elm_community$json_extra$Json$Encode$Extra$maybe, $author$project$PlugMap$Plugins$Themes$encodeLayerKey, maybeKey))
					]));
		case 1:
			var keys = selection.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'selection_type',
						$elm$json$Json$Encode$string('polyselection')),
						_Utils_Tuple2(
						'selection_keys',
						A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Themes$encodeLayerKey, keys))
					]));
		case 2:
			var key = selection.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'selection_type',
						$elm$json$Json$Encode$string('monoselection')),
						_Utils_Tuple2(
						'selection_key',
						$author$project$PlugMap$Plugins$Themes$encodeLayerKey(key))
					]));
		default:
			var headKey = selection.a;
			var tailKeys = selection.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'selection_type',
						$elm$json$Json$Encode$string('polyselection')),
						_Utils_Tuple2(
						'selection_keys',
						A2(
							$elm$json$Json$Encode$list,
							$author$project$PlugMap$Plugins$Themes$encodeLayerKey,
							A2($elm$core$List$cons, headKey, tailKeys)))
					]));
	}
};
var $author$project$PlugMap$Plugins$Themes$encodeCategorySelection = function (category) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'category_key',
				$author$project$PlugMap$Plugins$Themes$encodeCategoryKey(category.lw)),
				_Utils_Tuple2(
				'selection',
				$author$project$PlugMap$Plugins$Themes$encodeSelection(category.m0))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeSelectedThemes = function (model) {
	return A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Themes$encodeCategorySelection, model.ly);
};
var $author$project$PAM$expandBottomDrawer = function (model) {
	return _Utils_update(
		model,
		{
			aI: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						$author$project$Animation$to(
						$author$project$PAM$UI$Basic$bottomDrawerStates(model.fD).em)
					]),
				model.aI),
			j8: 0,
			aJ: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						A2(
						$author$project$Animation$toWith,
						$author$project$Animation$speed(
							{ic: 10.0}),
						$author$project$PAM$UI$Basic$toggleStates.ev)
					]),
				model.aJ),
			R: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						$author$project$Animation$to(
						$author$project$PAM$UI$Basic$leftSidebarStates(model.fD).eA)
					]),
				model.R)
		});
};
var $author$project$PAM$expandLeftSidebar = function (model) {
	var sidebarFx = $author$project$PAM$UI$Basic$leftSidebarStates(model.fD).em;
	var legendFx = A2($author$project$PAM$UI$Basic$riskLegendStates, model.f5, model.fD).eA;
	return _Utils_update(
		model,
		{
			aV: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						A2(
						$author$project$Animation$toWith,
						$author$project$Animation$speed(
							{ic: 1200.0}),
						legendFx)
					]),
				model.aV),
			aX: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						A2(
						$author$project$Animation$toWith,
						$author$project$Animation$speed(
							{ic: 1200.0}),
						sidebarFx)
					]),
				model.aX),
			m8: 0,
			aY: A2(
				$author$project$Animation$interrupt,
				_List_fromArray(
					[
						A2(
						$author$project$Animation$toWith,
						$author$project$Animation$speed(
							{ic: 10.0}),
						$author$project$PAM$UI$Basic$toggleStates.mR)
					]),
				model.aY)
		});
};
var $author$project$PlugMap$Plugins$Search$ForSuggestion = 0;
var $author$project$PlugMap$Plugins$Search$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Search$Response = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.ng));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {iu: reqs, iX: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.nK;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.iu));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.iX)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					jL: r.jL,
					j$: r.j$,
					kP: A2(_Http_mapExpect, func, r.kP),
					hk: r.hk,
					lM: r.lM,
					nF: r.nF,
					nK: r.nK,
					c8: r.c8
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{jL: false, j$: r.j$, kP: r.kP, hk: r.hk, lM: r.lM, nF: r.nF, nK: r.nK, c8: r.c8}));
};
var $author$project$PlugMap$Plugins$Search$CandidateData = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Search$SuggestionData = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Search$Candidate = F5(
	function (address, longLabel, location, score, extent) {
		return {gd: address, e3: extent, d8: location, hN: longLabel, iG: score};
	});
var $author$project$PlugMap$Plugins$Search$Location = F2(
	function (x, y) {
		return {dc: x, dd: y};
	});
var $author$project$PlugMap$Plugins$Search$decodeLatitude = $elm$json$Json$Decode$float;
var $author$project$PlugMap$Plugins$Search$decodeLongitude = $elm$json$Json$Decode$float;
var $author$project$PlugMap$Plugins$Search$decodeLocation = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'y',
	$author$project$PlugMap$Plugins$Search$decodeLatitude,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'x',
		$author$project$PlugMap$Plugins$Search$decodeLongitude,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Search$Location)));
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt = F4(
	function (path, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$PlugMap$Plugins$Search$searchExtentDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'spatialReference',
	A2(
		$elm$json$Json$Decode$field,
		'wkid',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'ymax',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'xmax',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'ymin',
				$elm$json$Json$Decode$float,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'xmin',
					$elm$json$Json$Decode$float,
					$elm$json$Json$Decode$succeed($author$project$PAM$Settings$SearchExtent))))));
var $author$project$PlugMap$Plugins$Search$candidateDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'extent',
	$author$project$PlugMap$Plugins$Search$searchExtentDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'score',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'location',
			$author$project$PlugMap$Plugins$Search$decodeLocation,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
				_List_fromArray(
					['attributes', 'LongLabel']),
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'address',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Search$Candidate))))));
var $author$project$PlugMap$Plugins$Search$Suggestion = F3(
	function (text, magicKey, isCollection) {
		return {hz: isCollection, fo: magicKey, eE: text};
	});
var $author$project$PlugMap$Plugins$Search$suggestionDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'isCollection',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'magicKey',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'text',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Search$Suggestion))));
var $author$project$PlugMap$Plugins$Search$suggestionsOrCandidatesDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'suggestions',
			$elm$json$Json$Decode$list(
				A2($elm$json$Json$Decode$map, $author$project$PlugMap$Plugins$Search$SuggestionData, $author$project$PlugMap$Plugins$Search$suggestionDecoder))),
			A2(
			$elm$json$Json$Decode$field,
			'candidates',
			$elm$json$Json$Decode$list(
				A2($elm$json$Json$Decode$map, $author$project$PlugMap$Plugins$Search$CandidateData, $author$project$PlugMap$Plugins$Search$candidateDecoder)))
		]));
var $author$project$PlugMap$Plugins$Search$getCandidatesFor = F2(
	function (candidateForResponseType, url) {
		var get = F2(
			function (theUrl, body) {
				return $elm$http$Http$request(
					{
						j$: body,
						kP: A2(
							$elm$http$Http$expectJson,
							A2(
								$elm$core$Basics$composeR,
								$krisajenkins$remotedata$RemoteData$fromResult,
								A2($elm$core$Basics$composeR, $author$project$PlugMap$Plugins$Search$Response, $author$project$PlugMap$Plugins$Search$Internal)),
							$author$project$PlugMap$Plugins$Search$suggestionsOrCandidatesDecoder),
						hk: _List_fromArray(
							[
								A2($elm$http$Http$header, 'Accept', 'application/json, text/javascript, */*; q=0.01')
							]),
						lM: 'GET',
						nF: $elm$core$Maybe$Nothing,
						nK: $elm$core$Maybe$Nothing,
						c8: theUrl
					});
			});
		return A2(get, url, $elm$http$Http$emptyBody);
	});
var $sporto$qs$QS$One = function (a) {
	return {$: 0, a: a};
};
var $sporto$qs$QS$Str = function (a) {
	return {$: 2, a: a};
};
var $sporto$qs$QS$set = F3(
	function (key, value, query) {
		return A3($elm$core$Dict$insert, key, value, query);
	});
var $author$project$PlugMap$Plugins$Search$addPlainTextQueryParams = F2(
	function (searchTerm, qs) {
		return A3(
			$sporto$qs$QS$set,
			'singleLine',
			$sporto$qs$QS$One(
				$sporto$qs$QS$Str(searchTerm)),
			qs);
	});
var $author$project$PlugMap$Plugins$Search$addSuggestionQueryParams = F2(
	function (suggestion, qs) {
		return A3(
			$sporto$qs$QS$set,
			'magicKey',
			$sporto$qs$QS$One(
				$sporto$qs$QS$Str(suggestion.fo)),
			A2($author$project$PlugMap$Plugins$Search$addPlainTextQueryParams, suggestion.eE, qs));
	});
var $sporto$qs$QS$empty = $elm$core$Dict$empty;
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$PlugMap$Plugins$Search$encodeWkid = function (wkid) {
	return $elm$json$Json$Encode$int(wkid);
};
var $author$project$PlugMap$Plugins$Search$encodeSpatialReference = function (ref) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'wkid',
				$author$project$PlugMap$Plugins$Search$encodeWkid(ref))
			]));
};
var $author$project$PlugMap$Plugins$Search$encodeSearchExtent = function (extent) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'xmin',
				$elm$json$Json$Encode$float(extent.js)),
				_Utils_Tuple2(
				'ymin',
				$elm$json$Json$Encode$float(extent.jv)),
				_Utils_Tuple2(
				'xmax',
				$elm$json$Json$Encode$float(extent.jr)),
				_Utils_Tuple2(
				'ymax',
				$elm$json$Json$Encode$float(extent.ju)),
				_Utils_Tuple2(
				'spatialReference',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $author$project$PlugMap$Plugins$Search$encodeSpatialReference, extent.iS))
			]));
};
var $author$project$PlugMap$Plugins$Search$buildCommonQueryString = F2(
	function (location, config) {
		var searchExtent = A2(
			$elm$json$Json$Encode$encode,
			0,
			$author$project$PlugMap$Plugins$Search$encodeSearchExtent(config.e3));
		return A3(
			$sporto$qs$QS$set,
			'outFields',
			$sporto$qs$QS$One(
				$sporto$qs$QS$Str('LongLabel')),
			A3(
				$sporto$qs$QS$set,
				'outSR',
				$sporto$qs$QS$One(
					$sporto$qs$QS$Str('3857')),
				A3(
					$sporto$qs$QS$set,
					'sourceCountry',
					$sporto$qs$QS$One(
						$sporto$qs$QS$Str('USA')),
					A3(
						$sporto$qs$QS$set,
						'countryCode',
						$sporto$qs$QS$One(
							$sporto$qs$QS$Str('USA')),
						A3(
							$sporto$qs$QS$set,
							'location',
							$sporto$qs$QS$One(
								$sporto$qs$QS$Str(
									$elm$core$String$fromFloat(location.dc) + (',' + $elm$core$String$fromFloat(location.dd)))),
							A3(
								$sporto$qs$QS$set,
								'maxLocations',
								$sporto$qs$QS$One(
									$sporto$qs$QS$Str(
										$elm$core$String$fromInt(config.fp))),
								A3(
									$sporto$qs$QS$set,
									'searchExtent',
									$sporto$qs$QS$One(
										$sporto$qs$QS$Str(searchExtent)),
									A3(
										$sporto$qs$QS$set,
										'f',
										$sporto$qs$QS$One(
											$sporto$qs$QS$Str('json')),
										$sporto$qs$QS$empty))))))));
	});
var $sporto$qs$QS$Config = $elm$core$Basics$identity;
var $sporto$qs$QS$config = {cH: true, ep: true, eq: true};
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {lh: index, lJ: match, lW: number, nm: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{kd: false, lS: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $sporto$qs$QS$decodeSymbol = function (symbol) {
	var regex = A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString(
			$elm$url$Url$percentEncode(symbol)));
	return A2(
		$elm$regex$Regex$replace,
		regex,
		function (_v0) {
			return symbol;
		});
};
var $sporto$qs$QS$percentageEncode = function (shouldEncodeBrackets) {
	var maybeDecodeBrackets = shouldEncodeBrackets ? $elm$core$Basics$identity : A2(
		$elm$core$Basics$composeR,
		$sporto$qs$QS$decodeSymbol('['),
		$sporto$qs$QS$decodeSymbol(']'));
	return A2($elm$core$Basics$composeR, $elm$url$Url$percentEncode, maybeDecodeBrackets);
};
var $sporto$qs$QS$boolToString = A2(
	$elm$core$Basics$composeR,
	$elm$json$Json$Encode$bool,
	$elm$json$Json$Encode$encode(0));
var $sporto$qs$QS$primitiveToString = function (value) {
	switch (value.$) {
		case 0:
			var bool = value.a;
			return $sporto$qs$QS$boolToString(bool);
		case 1:
			var num = value.a;
			return $elm$core$String$fromFloat(num);
		default:
			var str = value.a;
			return str;
	}
};
var $sporto$qs$QS$serialize = F2(
	function (_v0, query) {
		var cfg = _v0;
		if ($elm$core$Dict$isEmpty(query)) {
			return '';
		} else {
			var addUniqueKey = F3(
				function (acc, key, value) {
					var encodedVal = A2($sporto$qs$QS$percentageEncode, true, value);
					var encodedKey = A2($sporto$qs$QS$percentageEncode, cfg.cH, key);
					return _Utils_ap(
						acc,
						_List_fromArray(
							[encodedKey + ('=' + encodedVal)]));
				});
			var addListKey = F3(
				function (acc, key, values) {
					var encodedKey = A2($sporto$qs$QS$percentageEncode, cfg.cH, key + '[]');
					var encodeVal = function (v) {
						return A2($sporto$qs$QS$percentageEncode, true, v);
					};
					return A2(
						$elm$core$List$append,
						acc,
						A2(
							$elm$core$List$map,
							function (val) {
								return encodedKey + ('=' + encodeVal(val));
							},
							values));
				});
			var addKey = F2(
				function (_v2, acc) {
					var key = _v2.a;
					var queryVal = _v2.b;
					if (!queryVal.$) {
						var val = queryVal.a;
						return A3(
							addUniqueKey,
							acc,
							key,
							$sporto$qs$QS$primitiveToString(val));
					} else {
						var list = queryVal.a;
						return A3(
							addListKey,
							acc,
							key,
							A2($elm$core$List$map, $sporto$qs$QS$primitiveToString, list));
					}
				});
			var processedValues = A2(
				$elm$core$String$join,
				'&',
				A3(
					$elm$core$List$foldl,
					addKey,
					_List_Nil,
					$elm$core$Dict$toList(query)));
			return '?' + processedValues;
		}
	});
var $author$project$PlugMap$Plugins$Search$getUrlForSuggestion = F3(
	function (location, suggestion, config) {
		return _Utils_ap(
			config.fJ,
			A2(
				$sporto$qs$QS$serialize,
				$sporto$qs$QS$config,
				A2(
					$author$project$PlugMap$Plugins$Search$addSuggestionQueryParams,
					suggestion,
					A2($author$project$PlugMap$Plugins$Search$buildCommonQueryString, location, config))));
	});
var $author$project$PlugMap$Plugins$Search$fetchCandidatesForSuggestions = F3(
	function (config, location, data) {
		return A2(
			$author$project$PlugMap$Plugins$Search$getCandidatesFor,
			0,
			A3($author$project$PlugMap$Plugins$Search$getUrlForSuggestion, location, data, config));
	});
var $author$project$PlugMap$Plugins$Search$encodeLatitude = function (lat) {
	return $elm$json$Json$Encode$float(lat);
};
var $author$project$PlugMap$Plugins$Search$encodeLongitude = function (lon) {
	return $elm$json$Json$Encode$float(lon);
};
var $author$project$PlugMap$Plugins$Search$encodeLocation = function (loc) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'x',
				$author$project$PlugMap$Plugins$Search$encodeLongitude(loc.dc)),
				_Utils_Tuple2(
				'y',
				$author$project$PlugMap$Plugins$Search$encodeLatitude(loc.dd))
			]));
};
var $author$project$PlugMap$Plugins$Search$encodeMapExtent = function (extent) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'xmin',
				$elm$json$Json$Encode$float(extent.js)),
				_Utils_Tuple2(
				'ymin',
				$elm$json$Json$Encode$float(extent.jv)),
				_Utils_Tuple2(
				'xmax',
				$elm$json$Json$Encode$float(extent.jr)),
				_Utils_Tuple2(
				'ymax',
				$elm$json$Json$Encode$float(extent.ju))
			]));
};
var $author$project$PlugMap$Plugins$Search$encodeCandidate = function (c) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'address',
				$elm$json$Json$Encode$string(c.gd)),
				_Utils_Tuple2(
				'location',
				$author$project$PlugMap$Plugins$Search$encodeLocation(c.d8)),
				_Utils_Tuple2(
				'score',
				$elm$json$Json$Encode$float(c.iG)),
				_Utils_Tuple2(
				'extent',
				$author$project$PlugMap$Plugins$Search$encodeMapExtent(c.e3))
			]));
};
var $author$project$PlugMap$Plugins$Search$encodeCandidates = F3(
	function (candidates, paddingLeft, paddingBottom) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'candidates',
					A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Search$encodeCandidate, candidates)),
					_Utils_Tuple2(
					'paddingLeft',
					$elm$json$Json$Encode$int(paddingLeft)),
					_Utils_Tuple2(
					'paddingBottom',
					$elm$json$Json$Encode$int(paddingBottom))
				]));
	});
var $author$project$PlugMap$Plugins$Search$gotoSearchCandidates = _Platform_outgoingPort('gotoSearchCandidates', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$Search$selectSearchCandidate = F3(
	function (candidate, paddingLeft, paddingBottom) {
		return $author$project$PlugMap$Plugins$Search$gotoSearchCandidates(
			A3(
				$author$project$PlugMap$Plugins$Search$encodeCandidates,
				_List_fromArray(
					[candidate]),
				paddingLeft,
				paddingBottom));
	});
var $author$project$PlugMap$Plugins$Search$fetchFor = F4(
	function (env, config, location, result) {
		if (!result.$) {
			var data = result.a;
			return A3($author$project$PlugMap$Plugins$Search$fetchCandidatesForSuggestions, config, location, data);
		} else {
			var data = result.a;
			return A3(
				$author$project$PlugMap$Plugins$Search$selectSearchCandidate,
				data,
				(!env.m8) ? env.fD.lG : 0,
				(!env.j8) ? (env.fD.j6 + env.fD.j7) : 0);
		}
	});
var $author$project$PlugMap$Plugins$Search$getSuggestions = function (url) {
	var get = F2(
		function (theUrl, body) {
			return $elm$http$Http$request(
				{
					j$: body,
					kP: A2(
						$elm$http$Http$expectJson,
						A2(
							$elm$core$Basics$composeR,
							$krisajenkins$remotedata$RemoteData$fromResult,
							A2($elm$core$Basics$composeR, $author$project$PlugMap$Plugins$Search$Response, $author$project$PlugMap$Plugins$Search$Internal)),
						$author$project$PlugMap$Plugins$Search$suggestionsOrCandidatesDecoder),
					hk: _List_fromArray(
						[
							A2($elm$http$Http$header, 'Accept', 'application/json, text/javascript, */*; q=0.01')
						]),
					lM: 'GET',
					nF: $elm$core$Maybe$Nothing,
					nK: $elm$core$Maybe$Nothing,
					c8: theUrl
				});
		});
	return A2(get, url, $elm$http$Http$emptyBody);
};
var $author$project$PlugMap$Plugins$Search$getSuggestionsUrl = F3(
	function (location, search, config) {
		var searchExtent = A2(
			$elm$json$Json$Encode$encode,
			0,
			$author$project$PlugMap$Plugins$Search$encodeSearchExtent(config.e3));
		var query = A3(
			$sporto$qs$QS$set,
			'countryCode',
			$sporto$qs$QS$One(
				$sporto$qs$QS$Str('USA')),
			A3(
				$sporto$qs$QS$set,
				'location',
				$sporto$qs$QS$One(
					$sporto$qs$QS$Str(
						$elm$core$String$fromFloat(location.dc) + (',' + $elm$core$String$fromFloat(location.dd)))),
				A3(
					$sporto$qs$QS$set,
					'maxSuggestions',
					$sporto$qs$QS$One(
						$sporto$qs$QS$Str(
							$elm$core$String$fromInt(config.fp))),
					A3(
						$sporto$qs$QS$set,
						'searchExtent',
						$sporto$qs$QS$One(
							$sporto$qs$QS$Str(searchExtent)),
						A3(
							$sporto$qs$QS$set,
							'f',
							$sporto$qs$QS$One(
								$sporto$qs$QS$Str('json')),
							A3(
								$sporto$qs$QS$set,
								'text',
								$sporto$qs$QS$One(
									$sporto$qs$QS$Str(search)),
								$sporto$qs$QS$empty))))));
		var qs = A2($sporto$qs$QS$serialize, $sporto$qs$QS$config, query);
		return _Utils_ap(config.fV, qs);
	});
var $author$project$PlugMap$Plugins$Search$fetchSuggestions = F4(
	function (model, config, location, search) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{iy: $krisajenkins$remotedata$RemoteData$NotAsked}),
			$author$project$PlugMap$Plugins$Search$getSuggestions(
				A3($author$project$PlugMap$Plugins$Search$getSuggestionsUrl, location, search, config)));
	});
var $author$project$PlugMap$Plugins$Search$ForCandidate = 1;
var $author$project$PlugMap$Plugins$Search$getUrlForPlainText = F3(
	function (location, searchTerm, config) {
		return _Utils_ap(
			config.fJ,
			A2(
				$sporto$qs$QS$serialize,
				$sporto$qs$QS$config,
				A2(
					$author$project$PlugMap$Plugins$Search$addPlainTextQueryParams,
					searchTerm,
					A2($author$project$PlugMap$Plugins$Search$buildCommonQueryString, location, config))));
	});
var $author$project$PlugMap$Plugins$Search$fetchcandidates = F3(
	function (config, location, search) {
		return A2(
			$author$project$PlugMap$Plugins$Search$getCandidatesFor,
			1,
			A3($author$project$PlugMap$Plugins$Search$getUrlForPlainText, location, search, config));
	});
var $author$project$PAM$UI$Basic$flipOpenness = function (openness) {
	if (!openness) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$PAM$UI$Palette$fromSet = function (set) {
	return {bl: set, fa: set, j: set, iO: set};
};
var $author$project$PAM$Model$GridLayouts$get = F2(
	function (id, _v0) {
		var layouts = _v0;
		return A2($elm$core$Dict$get, id, layouts);
	});
var $author$project$PlugMap$Plugins$Themes$getCategoryByKey = F2(
	function (model, key) {
		return A2(
			$elm$core$List$filter,
			function (a) {
				return _Utils_eq(a.lw, key);
			},
			model.ly);
	});
var $author$project$PlugMap$Plugins$Themes$getGroupByKey = F2(
	function (repo, _v0) {
		var key = _v0;
		return A2($elm$core$Dict$get, key, repo);
	});
var $author$project$PlugMap$Plugins$Themes$groupHasLayer = F2(
	function (key, group) {
		return A2($elm$core$List$member, key, group.hE);
	});
var $author$project$PlugMap$Plugins$Themes$categoryHasLayer = F3(
	function (key, category, model) {
		return A2(
			$elm$core$List$any,
			$author$project$PlugMap$Plugins$Themes$groupHasLayer(key),
			A2(
				$elm$core$List$filterMap,
				$author$project$PlugMap$Plugins$Themes$getGroupByKey(model.lz),
				category.d5));
	});
var $author$project$PlugMap$Plugins$Themes$getCategoryFromLayerKey = F2(
	function (model, key) {
		return A2(
			$elm$core$List$filter,
			function (c) {
				return A3($author$project$PlugMap$Plugins$Themes$categoryHasLayer, key, c, model);
			},
			model.ly);
	});
var $author$project$PlugMap$Core$getLocation = function (model) {
	var _v0 = model.dK;
	if (!_v0.$) {
		return A2($author$project$PlugMap$Core$Location, 0, 0);
	} else {
		var ext = _v0.a;
		return ext.gB;
	}
};
var $author$project$PAM$Map$getLocation = function (model) {
	return $author$project$PlugMap$Core$getLocation(model.h);
};
var $author$project$PAM$Message$API_RequestLazyLongObject = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$PAM$Message$LazyObjectLoaded = function (a) {
	return {$: 55, a: a};
};
var $author$project$PAM$Object$LazyObject = F9(
	function (id, name, type_, fieldValues, createdBy, createdOn, lastModifiedBy, lastModifiedOn, isDirty) {
		return {gP: createdBy, dI: createdOn, au: fieldValues, r: id, lo: isDirty, d3: lastModifiedBy, d4: lastModifiedOn, fu: name, bi: type_};
	});
var $author$project$PAM$Model$ObjectTypes$unknownObjectType = {eW: false, kG: 999999, r: -1, lw: 'unknown', ie: 'unknown', cZ: 'unknown'};
var $author$project$PAM$User$UserModel = F3(
	function (id, fullName, email) {
		return {kI: email, hi: fullName, r: id};
	});
var $author$project$PAM$User$userModelDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'email',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'name',
				$elm$json$Json$Decode$string,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$PAM$User$UserModel)))),
			A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			'unknown',
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				'unknown',
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
					'0',
					$elm$json$Json$Decode$succeed($author$project$PAM$User$UserModel))))
		]));
var $author$project$PAM$Object$lazyObjectDecoder = function (defaultType) {
	return A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		false,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'modified',
			A2(
				$elm$json$Json$Decode$andThen,
				function (i) {
					return $elm$json$Json$Decode$succeed(
						$elm$time$Time$millisToPosix(i));
				},
				$elm$json$Json$Decode$int),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'lastModifiedBy',
				$author$project$PAM$User$userModelDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'created',
					A2(
						$elm$json$Json$Decode$andThen,
						function (i) {
							return $elm$json$Json$Decode$succeed(
								$elm$time$Time$millisToPosix(i));
						},
						$elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'createdBy',
						$author$project$PAM$User$userModelDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'fields',
							$elm$json$Json$Decode$value,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'type',
								$elm$json$Json$Decode$oneOf(
									_List_fromArray(
										[
											$author$project$PAM$Model$ObjectTypes$typeDecoder,
											$elm$json$Json$Decode$succeed(
											A2($elm$core$Maybe$withDefault, $author$project$PAM$Model$ObjectTypes$unknownObjectType, defaultType))
										])),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'name',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$int,
										$elm$json$Json$Decode$succeed($author$project$PAM$Object$LazyObject))))))))));
};
var $author$project$PAM$API$APIBase$bump = F3(
	function (sucessReturn, unauthorizedReturn, result) {
		if (!result.$) {
			return sucessReturn(result);
		} else {
			var err = result.a;
			if (err.$ === 3) {
				var status = err.a;
				return (status === 401) ? unauthorizedReturn(result) : sucessReturn(result);
			} else {
				return sucessReturn(result);
			}
		}
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{jL: true, j$: r.j$, kP: r.kP, hk: r.hk, lM: r.lM, nF: r.nF, nK: r.nK, c8: r.c8}));
};
var $truqu$elm_oauth2$OAuth$tokenToString = function (_v0) {
	var t = _v0;
	return 'Bearer ' + t;
};
var $truqu$elm_oauth2$OAuth$useToken = function (token) {
	return $elm$core$List$cons(
		A2(
			$elm$http$Http$header,
			'Authorization',
			$truqu$elm_oauth2$OAuth$tokenToString(token)));
};
var $author$project$PAM$API$APIBase$postRequest = F8(
	function (settings, controller, action, body_, sucessReturn, unauthorizedReturn, user, decoder) {
		var version = A2(
			$elm$core$Maybe$withDefault,
			settings.je ? $elm$core$Maybe$Just(settings.da) : $elm$core$Maybe$Nothing,
			A2(
				$elm$core$Maybe$andThen,
				function (x) {
					var _v4 = x.je;
					if ((!_v4.$) && _v4.a) {
						var _v5 = x.da;
						if (!_v5.$) {
							var ver = _v5.a;
							return $elm$core$Maybe$Just(
								$elm$core$Maybe$Just(ver));
						} else {
							return $elm$core$Maybe$Just(
								$elm$core$Maybe$Just(settings.da));
						}
					} else {
						return $elm$core$Maybe$Nothing;
					}
				},
				A2($elm$core$Dict$get, controller, settings.kK)));
		var trok = function () {
			switch (user.$) {
				case 3:
					var token = user.a.nI;
					return token;
				case 2:
					var token = user.a;
					return token;
				default:
					return $elm$core$Maybe$Nothing;
			}
		}();
		return $elm$http$Http$riskyRequest(
			{
				j$: function () {
					if (!body_.$) {
						var body = body_.a;
						return $elm$http$Http$jsonBody(body);
					} else {
						return $elm$http$Http$emptyBody;
					}
				}(),
				kP: A2(
					$elm$http$Http$expectJson,
					A2($author$project$PAM$API$APIBase$bump, sucessReturn, unauthorizedReturn),
					decoder),
				hk: A2(
					$elm$core$List$append,
					function () {
						if (!trok.$) {
							var tok = trok.a;
							return A2($truqu$elm_oauth2$OAuth$useToken, tok, _List_Nil);
						} else {
							return _List_fromArray(
								[
									A2($elm$http$Http$header, 'Anon', 'True')
								]);
						}
					}(),
					_List_fromArray(
						[
							A2($elm$http$Http$header, 'Accept', 'application/json, text/javascript, */*; q=0.01'),
							A2($elm$http$Http$header, 'Content-Type', 'application/json')
						])),
				lM: 'POST',
				nF: $elm$core$Maybe$Nothing,
				nK: $elm$core$Maybe$Nothing,
				c8: settings.jW + (function () {
					if (!version.$) {
						var ver = version.a;
						return ver + '/';
					} else {
						return '';
					}
				}() + (controller + ('/' + action)))
			});
	});
var $author$project$PAM$API$APIBase$object_LazyLongRead = F6(
	function (settings, user, id, type_, _return, onError) {
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'object',
			'LongRead',
			$elm$core$Maybe$Just(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$int(id)),
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$int(type_.r))
						]))),
			_return,
			onError,
			user,
			$author$project$PAM$Object$lazyObjectDecoder(
				$elm$core$Maybe$Just(type_)));
	});
var $author$project$PAM$getObjectDetailsNew = F3(
	function (model, id, type_) {
		var obj_ = A2($author$project$PAM$Object$Repo$getObject, id, model.b);
		return _Utils_Tuple2(
			model,
			A6(
				$author$project$PAM$API$APIBase$object_LazyLongRead,
				model.bR.jQ,
				model.aq,
				id,
				type_,
				$author$project$PAM$Message$LazyObjectLoaded,
				function (res) {
					return $author$project$PAM$Message$APIUnauthorizedHandler(
						A2($author$project$PAM$Message$API_RequestLazyLongObject, id, type_.lw));
				}));
	});
var $author$project$PAM$Message$API_RequestObjectForZoom = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$PAM$Message$LazyObjectLoadedForZoom = function (a) {
	return {$: 56, a: a};
};
var $author$project$PAM$getObjectForZoom = F3(
	function (model, id, type_) {
		var obj_ = A2($author$project$PAM$Object$Repo$getObject, id, model.b);
		return _Utils_Tuple2(
			model,
			A6(
				$author$project$PAM$API$APIBase$object_LazyLongRead,
				model.bR.jQ,
				model.aq,
				id,
				type_,
				$author$project$PAM$Message$LazyObjectLoadedForZoom,
				function (res) {
					return $author$project$PAM$Message$APIUnauthorizedHandler(
						A2($author$project$PAM$Message$API_RequestObjectForZoom, id, type_.lw));
				}));
	});
var $author$project$PAM$UI$Search$getSearch = function ($) {
	return $.bd;
};
var $author$project$PAM$Object$FieldValue$getByKey = F2(
	function (name, _v0) {
		var values = _v0;
		return A2($elm$core$Dict$get, name, values);
	});
var $author$project$PAM$Object$getWkt = function (obj) {
	var _v0 = A2($author$project$PAM$Object$FieldValue$getByKey, 'Geometry', obj.au);
	if (((!_v0.$) && (_v0.a.$ === 17)) && (_v0.a.a.$ === 1)) {
		var _v1 = _v0.a.a;
		var spatial = _v1.a;
		return $elm$core$Maybe$Just(spatial.aj);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$PAM$handleAPIUnauthorized = F2(
	function (model, whereAt) {
		var _v0 = $author$project$InPageOAuth$regetAuthorization(model.ar);
		var newAuth = _v0.a;
		var authCmds = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					ar: newAuth,
					ca: _Utils_ap(
						model.ca,
						_List_fromArray(
							[whereAt]))
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$PAM$authTranslator, authCmds));
	});
var $author$project$PAM$Message$API_GetAppConfig = {$: 12};
var $author$project$PAM$Message$API_GetModelInfo = {$: 11};
var $author$project$PAM$Message$AppConfigResponse = function (a) {
	return {$: 54, a: a};
};
var $author$project$PAM$Message$ModelInfoResponse = function (a) {
	return {$: 53, a: a};
};
var $author$project$PAM$API$APIBase$app_GetConfig = F4(
	function (settings, user, _return, onError) {
		return A8($author$project$PAM$API$APIBase$postRequest, settings, 'app', 'GetConfig', $elm$core$Maybe$Nothing, _return, onError, user, $elm$json$Json$Decode$value);
	});
var $author$project$PAM$Message$API_Delete = function (a) {
	return {$: 4, a: a};
};
var $author$project$PAM$Message$ObjectDeleted = F2(
	function (a, b) {
		return {$: 29, a: a, b: b};
	});
var $author$project$PAM$API$APIBase$object_Delete = F5(
	function (settings, user, id, _return, onError) {
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'object',
			'Delete',
			$elm$core$Maybe$Just(
				$elm$json$Json$Encode$int(id)),
			_return,
			onError,
			user,
			$elm$json$Json$Decode$bool);
	});
var $author$project$PAM$deleteObjectCmd = F2(
	function (objId, model) {
		return A5(
			$author$project$PAM$API$APIBase$object_Delete,
			model.bR.jQ,
			model.aq,
			objId,
			$author$project$PAM$Message$ObjectDeleted(objId),
			function (res) {
				return $author$project$PAM$Message$APIUnauthorizedHandler(
					$author$project$PAM$Message$API_Delete(objId));
			});
	});
var $author$project$PAM$deleteObject = F2(
	function (objId, model) {
		return _Utils_Tuple2(
			model,
			A2($author$project$PAM$deleteObjectCmd, objId, model));
	});
var $author$project$PAM$getAppConfig = function (model) {
	return _Utils_Tuple2(
		model,
		A4(
			$author$project$PAM$API$APIBase$app_GetConfig,
			model.bR.jQ,
			model.aq,
			$author$project$PAM$Message$AppConfigResponse,
			function (res) {
				return $author$project$PAM$Message$APIUnauthorizedHandler($author$project$PAM$Message$API_GetAppConfig);
			}));
};
var $author$project$InPageOAuth$getUser = function (model) {
	return model.aq;
};
var $author$project$PAM$API$APIBase$model_GetInfo = F4(
	function (settings, user, _return, onError) {
		return A8($author$project$PAM$API$APIBase$postRequest, settings, 'model', 'GetInfo', $elm$core$Maybe$Nothing, _return, onError, user, $author$project$PAM$Model$ModelInfo$modelInfoDecoder);
	});
var $author$project$PAM$Message$API_LinkExisting = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$PAM$Message$CancelLinkExistingClicked = {$: 0};
var $author$project$PAM$Message$HandleGetObjectsForLinking = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$PAM$Message$LinkExistingObjectMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$PAM$Modal$LinkModal = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$PAM$Object$Object = F9(
	function (id, name, type_, fieldValues, createdBy, createdOn, lastModifiedBy, lastModifiedOn, isDirty) {
		return {gP: createdBy, dI: createdOn, au: fieldValues, r: id, lo: isDirty, d3: lastModifiedBy, d4: lastModifiedOn, fu: name, bi: type_};
	});
var $author$project$PAM$Object$FieldValue$FieldValue_NotSupported = {$: 19};
var $author$project$NormalDate$dateToIsoString = function (date) {
	return $elm$core$String$fromInt(
		$justinmimbs$date$Date$year(date)) + ('-' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			$author$project$NormalDate$monthToInt(
				$justinmimbs$date$Date$month(date)))) + ('-' + A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			$justinmimbs$date$Date$day(date))))));
};
var $author$project$NormalDate$toMonth = function (mnth) {
	switch (mnth) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		case 12:
			return 11;
		default:
			return 0;
	}
};
var $author$project$NormalDate$isoStringToDate = F2(
	function (input, delim) {
		var split = A2(
			$elm$core$List$filterMap,
			function (a) {
				return a;
			},
			A2(
				$elm$core$List$map,
				$elm$core$String$toInt,
				A2(
					$elm$core$String$split,
					delim,
					A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, 'T', input))))));
		if ((split.b && split.b.b) && split.b.b.b) {
			var y = split.a;
			var _v1 = split.b;
			var m = _v1.a;
			var _v2 = _v1.b;
			var d = _v2.a;
			return ((y >= 1970) && (y <= 2099)) ? $elm$core$Maybe$Just(
				A3(
					$justinmimbs$date$Date$fromCalendarDate,
					y,
					$author$project$NormalDate$toMonth(m),
					d)) : $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$NormalDate$fromIsoDate = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Err('Could not parse a date from ' + input),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Result$Ok,
			A2($author$project$NormalDate$isoStringToDate, input, '-')));
};
var $author$project$PAM$Object$FieldValue$decodeDynamicDate = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Date($author$project$Fields$Shared$NotEntered),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (date) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Date(
										A2(
											$author$project$Fields$Shared$Valid,
											date,
											$author$project$NormalDate$dateToIsoString(date))));
							},
							A2(
								$elm$json$Json$Decode$andThen,
								function (s) {
									var _v0 = $author$project$NormalDate$fromIsoDate(s);
									if (!_v0.$) {
										var d = _v0.a;
										return $elm$json$Json$Decode$succeed(d);
									} else {
										return $elm$json$Json$Decode$fail('Unable to decode date');
									}
								},
								$elm$json$Json$Decode$string)),
						vals));
			},
			value));
};
var $author$project$PAM$Misc$dateRangeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (res) {
		var split = A2($elm$core$String$split, '|', res);
		var parts = A2($elm$core$List$map, $author$project$NormalDate$fromIsoDate, split);
		var parsedStart = $elm$core$List$head(parts);
		var parsedEnd = $elm$core$List$head(
			$elm$core$List$reverse(parts));
		var result = function () {
			var _v0 = _Utils_Tuple2(parsedStart, parsedEnd);
			if ((!_v0.a.$) && (!_v0.b.$)) {
				var start = _v0.a.a;
				var end = _v0.b.a;
				return $elm$json$Json$Decode$succeed(
					A2(
						$author$project$PAM$Misc$DateRange,
						function () {
							if (!start.$) {
								var st = start.a;
								return A2(
									$author$project$Fields$Shared$Valid,
									st,
									$author$project$NormalDate$dateToIsoString(st));
							} else {
								return $author$project$Fields$Shared$NotEntered;
							}
						}(),
						function () {
							if (!end.$) {
								var st = end.a;
								return A2(
									$author$project$Fields$Shared$Valid,
									st,
									$author$project$NormalDate$dateToIsoString(st));
							} else {
								return $author$project$Fields$Shared$NotEntered;
							}
						}()));
			} else {
				return $elm$json$Json$Decode$fail('Not a valid date. Must be yyyy-mm-dd');
			}
		}();
		return result;
	},
	$elm$json$Json$Decode$string);
var $author$project$PAM$Object$FieldValue$decodeDynamicDateRange = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_DateRange($author$project$Fields$Shared$NotEntered),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (dateRange) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_DateRange(
										A2(
											$author$project$Fields$Shared$Valid,
											dateRange,
											$author$project$PAM$Misc$dateRangeToString(dateRange))));
							},
							$author$project$PAM$Misc$dateRangeDecoder),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$decodeDynamicFlag = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Flag($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Flag(
										$elm$core$Maybe$Just(val)));
							},
							$elm$json$Json$Decode$bool),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$decodeFlagWithComment = function () {
	var mcv = $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$field,
				'comment',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
	var bv = A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$bool);
	return A3(
		$elm$json$Json$Decode$map2,
		function (a) {
			return function (b) {
				return a ? $author$project$PAM$Object$FieldValue$Yes(b) : $author$project$PAM$Object$FieldValue$No(b);
			};
		},
		bv,
		mcv);
}();
var $author$project$PAM$Object$FieldValue$decodeDynamicFlagWithComment = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_FlagWithComment($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_FlagWithComment(
										$elm$core$Maybe$Just(val)));
							},
							$author$project$PAM$Object$FieldValue$decodeFlagWithComment),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$decodeDynamicFloat = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Float($author$project$Fields$Shared$NotEntered),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Float(
										A2(
											$author$project$Fields$Shared$Valid,
											val,
											$elm$core$String$fromFloat(val))));
							},
							$elm$json$Json$Decode$float),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$decodeDynamicInt = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Int($author$project$Fields$Shared$NotEntered),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Int(
										A2(
											$author$project$Fields$Shared$Valid,
											val,
											$elm$core$String$fromInt(val))));
							},
							$elm$json$Json$Decode$int),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$decodeDynamicLongText = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_LongText($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (s) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_LongText(
										$elm$core$Maybe$Just(s)));
							},
							$elm$json$Json$Decode$string),
						vals));
			},
			value));
};
var $elm$regex$Regex$contains = _Regex_contains;
var $author$project$PAM$Misc$moneyDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		if (A2(
			$elm$regex$Regex$contains,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString('\\$\\d+($|\\.\\d{2})')),
			string)) {
			var _v0 = $elm$core$String$toFloat(
				A3(
					$elm$regex$Regex$replace,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('\\$')),
					function (_v1) {
						return '';
					},
					string));
			if (!_v0.$) {
				var money = _v0.a;
				return $elm$json$Json$Decode$succeed(money);
			} else {
				return $elm$json$Json$Decode$fail('Not money yo');
			}
		} else {
			return $elm$json$Json$Decode$fail('Not Money');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$PAM$Object$FieldValue$decodeDynamicMoney = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Money($author$project$Fields$Shared$NotEntered),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Money(
										A2(
											$author$project$Fields$Shared$Valid,
											val,
											$elm$core$String$fromFloat(val))));
							},
							$author$project$PAM$Misc$moneyDecoder),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$FieldValue_MultipleChoice = function (a) {
	return {$: 11, a: a};
};
var $author$project$PAM$Model$FieldChoices$Multiple = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Model$FieldChoices$multipleChoiceDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'selected',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
	$elm$json$Json$Decode$succeed($author$project$PAM$Model$FieldChoices$Multiple));
var $author$project$PAM$Object$FieldValue$decodeDynamicMultipleChoice = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_MultipleChoice($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_MultipleChoice(
										$elm$core$Maybe$Just(val)));
							},
							$author$project$PAM$Model$FieldChoices$multipleChoiceDecoder),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$FieldValue_MultipleObject = function (a) {
	return {$: 14, a: a};
};
var $author$project$PAM$Object$FieldValue$ObjectReference = F4(
	function (objectId, typeKey, objectName, isSpatial) {
		return {ls: isSpatial, h_: objectId, lX: objectName, ja: typeKey};
	});
var $author$project$PAM$Object$FieldValue$objectReferenceDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'isSpatial',
	$elm$json$Json$Decode$bool,
	false,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'objectName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'typeKey',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'objectId',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$PAM$Object$FieldValue$ObjectReference)))));
var $author$project$PAM$Object$FieldValue$objectReferencesDecoder = $elm$json$Json$Decode$list($author$project$PAM$Object$FieldValue$objectReferenceDecoder);
var $author$project$PAM$Object$FieldValue$decodeDynamicMultipleObject = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_MultipleObject($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_MultipleObject(
										$elm$core$Maybe$Just(val)));
							},
							$author$project$PAM$Object$FieldValue$objectReferencesDecoder),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$decodeDynamicNatural = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Natural($author$project$Fields$Shared$NotEntered),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return (val >= 0) ? $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Natural(
										A2(
											$author$project$Fields$Shared$Valid,
											val,
											$elm$core$String$fromInt(val)))) : $elm$json$Json$Decode$fail('must be a natural number');
							},
							$elm$json$Json$Decode$int),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$FieldValue_Scores = function (a) {
	return {$: 18, a: a};
};
var $author$project$PAM$Scores$LayerScores = F2(
	function (layer, bufferScores) {
		return {ka: bufferScores, hC: layer};
	});
var $author$project$PAM$Scores$BufferScoreJson = F3(
	function (buffer, state, score) {
		return {cA: buffer, iG: score, cj: state};
	});
var $author$project$PAM$Scores$Score = F5(
	function (min, max, average, score, band) {
		return {eP: average, gm: band, lK: max, lN: min, iG: score};
	});
var $author$project$PAM$Scores$Band = F2(
	function (id, labelConfig) {
		return {r: id, lx: labelConfig};
	});
var $author$project$PAM$Scores$LabelConfig = F2(
	function (text, boxColor) {
		return {j9: boxColor, eE: text};
	});
var $author$project$PAM$Scores$AlphaColor = F4(
	function (r, g, b, a) {
		return {jx: a, jU: b, k_: g, mI: r};
	});
var $author$project$PAM$Scores$alphaColorDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'a',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'b',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'g',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'r',
				$elm$json$Json$Decode$float,
				$elm$json$Json$Decode$succeed($author$project$PAM$Scores$AlphaColor)))));
var $author$project$PAM$Scores$labelConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'boxColor',
	$elm$json$Json$Decode$maybe($author$project$PAM$Scores$alphaColorDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'text',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PAM$Scores$LabelConfig)));
var $author$project$PAM$Scores$bandDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'labelConfig',
	$elm$json$Json$Decode$maybe($author$project$PAM$Scores$labelConfigDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$PAM$Scores$Band)));
var $author$project$PAM$Scores$scoreDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'band',
	$author$project$PAM$Scores$bandDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'score',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'average',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'max',
				$elm$json$Json$Decode$float,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'min',
					$elm$json$Json$Decode$float,
					$elm$json$Json$Decode$succeed($author$project$PAM$Scores$Score))))));
var $author$project$PAM$Scores$Score_Calculated = 4;
var $author$project$PAM$Scores$Score_Calculating = 3;
var $author$project$PAM$Scores$Score_Error = 5;
var $author$project$PAM$Scores$Score_Estimated = 2;
var $author$project$PAM$Scores$Score_Estimating = 1;
var $author$project$PAM$Scores$Score_NotStarted = 0;
var $author$project$PAM$Scores$scoreStateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (val) {
		switch (val) {
			case 0:
				return $elm$json$Json$Decode$succeed(0);
			case 1:
				return $elm$json$Json$Decode$succeed(1);
			case 2:
				return $elm$json$Json$Decode$succeed(2);
			case 3:
				return $elm$json$Json$Decode$succeed(3);
			case 4:
				return $elm$json$Json$Decode$succeed(4);
			case 5:
				return $elm$json$Json$Decode$succeed(5);
			default:
				return $elm$json$Json$Decode$fail(
					'Unable to map ' + ($elm$core$String$fromInt(val) + ' to a score state.'));
		}
	},
	$elm$json$Json$Decode$int);
var $author$project$PAM$Scores$bufferScoresJsonDecoder = $elm$json$Json$Decode$list(
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'score',
		$elm$json$Json$Decode$maybe($author$project$PAM$Scores$scoreDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'state',
			$author$project$PAM$Scores$scoreStateDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'buffer',
				$elm$json$Json$Decode$float,
				$elm$json$Json$Decode$succeed($author$project$PAM$Scores$BufferScoreJson)))));
var $author$project$PAM$Scores$Layer = F2(
	function (name, id) {
		return {r: id, fu: name};
	});
var $author$project$PAM$Scores$layerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'id',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PAM$Scores$Layer)));
var $author$project$PAM$Scores$layerScoresDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$PAM$Scores$LayerScores,
	A2($elm$json$Json$Decode$field, 'layer', $author$project$PAM$Scores$layerDecoder),
	A2($elm$json$Json$Decode$field, 'buffers', $author$project$PAM$Scores$bufferScoresJsonDecoder));
var $author$project$PAM$Object$FieldValue$decodeDynamicScores = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Scores($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Scores(
										$elm$core$Maybe$Just(val)));
							},
							$elm$json$Json$Decode$list($author$project$PAM$Scores$layerScoresDecoder)),
						vals));
			},
			value));
};
var $author$project$PAM$Model$FieldChoices$singleChoiceDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'selected',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$PAM$Model$FieldChoices$Single));
var $author$project$PAM$Object$FieldValue$decodeDynamicSingleChoice = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_SingleChoice($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_SingleChoice(
										$elm$core$Maybe$Just(val)));
							},
							$author$project$PAM$Model$FieldChoices$singleChoiceDecoder),
						vals));
			},
			value));
};
var $toastal$either$Either$Decode$either = F2(
	function (a, b) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2($elm$json$Json$Decode$map, $toastal$either$Either$Right, b),
					A2($elm$json$Json$Decode$map, $toastal$either$Either$Left, a)
				]));
	});
var $author$project$PAM$Model$FieldChoices$singleChoiceOrOtherDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'selected',
	A2(
		$toastal$either$Either$Decode$either,
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$json$Json$Decode$int),
	$elm$json$Json$Decode$succeed($author$project$PAM$Model$FieldChoices$SingleOrOther));
var $author$project$PAM$Object$FieldValue$decodeDynamicSingleChoiceWithOther = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_SingleChoiceWithOther($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_SingleChoiceWithOther(
										$elm$core$Maybe$Just(val)));
							},
							$author$project$PAM$Model$FieldChoices$singleChoiceOrOtherDecoder),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$Spatial = F3(
	function (wkt, acres, type_) {
		return {U: acres, bi: type_, aj: wkt};
	});
var $author$project$PAM$Object$FieldValue$BBox = 0;
var $author$project$PAM$Object$FieldValue$spatialTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (val) {
		return (val === 'bbox') ? $elm$json$Json$Decode$succeed(0) : ((val === 'exact') ? $elm$json$Json$Decode$succeed(1) : $elm$json$Json$Decode$fail('must be wither \'bbox\' or \'exact\'. Instead we got \'' + (val + '\'.')));
	},
	$elm$json$Json$Decode$string);
var $author$project$PAM$Object$FieldValue$spatialDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'type',
	$author$project$PAM$Object$FieldValue$spatialTypeDecoder,
	1,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'acres',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'wkt',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PAM$Object$FieldValue$Spatial))));
var $author$project$PAM$Object$FieldValue$decodeDynamicSpatial = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Spatial($author$project$Fields$Shared$NotEntered),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Spatial(
										A2($author$project$Fields$Shared$Valid, val, '')));
							},
							$author$project$PAM$Object$FieldValue$spatialDecoder),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$decodeDynamicText = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Text($elm$core$Maybe$Nothing),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (s) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Text(
										$elm$core$Maybe$Just(s)));
							},
							$elm$json$Json$Decode$string),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$decodeDynamicYear = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$PAM$Object$FieldValue$FieldValue_Year($author$project$Fields$Shared$NotEntered),
		A2(
			$elm$core$Maybe$andThen,
			function (vals) {
				return $elm$core$Result$toMaybe(
					A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$andThen,
							function (val) {
								return $elm$json$Json$Decode$succeed(
									$author$project$PAM$Object$FieldValue$FieldValue_Year(
										A2(
											$author$project$Fields$Shared$Valid,
											val,
											$elm$core$String$fromInt(val))));
							},
							$elm$json$Json$Decode$int),
						vals));
			},
			value));
};
var $author$project$PAM$Object$FieldValue$fieldValueDecoder = F2(
	function (fieldType, value) {
		switch (fieldType) {
			case 1:
				return $author$project$PAM$Object$FieldValue$decodeDynamicText(value);
			case 2:
				return $author$project$PAM$Object$FieldValue$decodeDynamicLongText(value);
			case 6:
				return $author$project$PAM$Object$FieldValue$decodeDynamicDate(value);
			case 7:
				return $author$project$PAM$Object$FieldValue$decodeDynamicDateRange(value);
			case 3:
				return $author$project$PAM$Object$FieldValue$decodeDynamicInt(value);
			case 16:
				return $author$project$PAM$Object$FieldValue$decodeDynamicNatural(value);
			case 15:
				return $author$project$PAM$Object$FieldValue$decodeDynamicYear(value);
			case 4:
				return $author$project$PAM$Object$FieldValue$decodeDynamicFloat(value);
			case 9:
				return $author$project$PAM$Object$FieldValue$decodeDynamicSingleChoice(value);
			case 10:
				return $author$project$PAM$Object$FieldValue$decodeDynamicMultipleChoice(value);
			case 12:
				return $author$project$PAM$Object$FieldValue$decodeDynamicMultipleObject(value);
			case 5:
				return $author$project$PAM$Object$FieldValue$decodeDynamicMoney(value);
			case 8:
				return $author$project$PAM$Object$FieldValue$decodeDynamicFlag(value);
			case 17:
				return $author$project$PAM$Object$FieldValue$decodeDynamicFlagWithComment(value);
			case 18:
				return $author$project$PAM$Object$FieldValue$decodeDynamicSingleChoiceWithOther(value);
			case 13:
				return $author$project$PAM$Object$FieldValue$decodeDynamicSpatial(value);
			case 19:
				return $author$project$PAM$Object$FieldValue$decodeDynamicScores(value);
			default:
				return $author$project$PAM$Object$FieldValue$FieldValue_NotSupported;
		}
	});
var $author$project$PAM$Object$FieldValue$fieldValuesDecoder = F2(
	function (fields, jsonValues) {
		return $elm$json$Json$Decode$succeed(
			$elm$core$Dict$fromList(
				A2(
					$elm$core$List$map,
					function (field) {
						return _Utils_Tuple2(
							field.fu,
							A2(
								$author$project$PAM$Object$FieldValue$fieldValueDecoder,
								field.hb,
								A2($elm$core$Dict$get, field.fu, jsonValues)));
					},
					fields)));
	});
var $author$project$PAM$Object$objectDecoder = F2(
	function (fields, defaultType) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			false,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'modified',
				A2(
					$elm$json$Json$Decode$andThen,
					function (i) {
						return $elm$json$Json$Decode$succeed(
							$elm$time$Time$millisToPosix(i));
					},
					$elm$json$Json$Decode$int),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'lastModifiedBy',
					$author$project$PAM$User$userModelDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'created',
						A2(
							$elm$json$Json$Decode$andThen,
							function (i) {
								return $elm$json$Json$Decode$succeed(
									$elm$time$Time$millisToPosix(i));
							},
							$elm$json$Json$Decode$int),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'createdBy',
							$author$project$PAM$User$userModelDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'fields',
								A2(
									$elm$json$Json$Decode$andThen,
									$author$project$PAM$Object$FieldValue$fieldValuesDecoder(fields),
									$elm$json$Json$Decode$dict($elm$json$Json$Decode$value)),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'type',
									$elm$json$Json$Decode$oneOf(
										_List_fromArray(
											[
												$author$project$PAM$Model$ObjectTypes$typeDecoder,
												$elm$json$Json$Decode$succeed(
												A2($elm$core$Maybe$withDefault, $author$project$PAM$Model$ObjectTypes$unknownObjectType, defaultType))
											])),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'name',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$elm$json$Json$Decode$int,
											$elm$json$Json$Decode$succeed($author$project$PAM$Object$Object))))))))));
	});
var $author$project$PAM$Object$objectsDecoder = F2(
	function (fields, defaultType) {
		return $elm$json$Json$Decode$list(
			A2($author$project$PAM$Object$objectDecoder, fields, defaultType));
	});
var $author$project$PAM$API$APIBase$object_ReadAllOfType = F6(
	function (settings, user, model, type_, _return, onError) {
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'object',
			'ReadAllOfType',
			$elm$core$Maybe$Just(
				$elm$json$Json$Encode$int(type_.r)),
			_return,
			onError,
			user,
			A2(
				$author$project$PAM$Object$objectsDecoder,
				model,
				$elm$core$Maybe$Just(type_)));
	});
var $author$project$PAM$requestObjectsForTypeCmd = F4(
	function (msg, authErrorMsg, typeId, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (type_, model_) {
						return A6($author$project$PAM$API$APIBase$object_ReadAllOfType, model.bR.jQ, model.aq, model_, type_, msg, authErrorMsg);
					}),
				A2($author$project$PAM$Model$ObjectTypes$get, typeId, model.a.c),
				A2($author$project$PAM$Model$ObjectModels$get, typeId, model.a.l)));
	});
var $author$project$PAM$openLinkExistingObjectModal = F3(
	function (parentObj, childObjType, model) {
		var linkExistingModal = $author$project$PAM$Modal$LinkModal(
			{
				bU: $elm$core$Maybe$Just(
					$author$project$PAM$Message$LinkExistingObjectMsg($author$project$PAM$Message$CancelLinkExistingClicked)),
				gA: 'Cancel',
				gF: childObjType,
				gG: $elm$core$Result$Ok(_List_Nil),
				bm: 1,
				fm: $elm$core$Maybe$Nothing,
				hL: 'Link',
				fE: parentObj,
				fM: $elm$core$Maybe$Nothing
			});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{j: linkExistingModal}),
			A4(
				$author$project$PAM$requestObjectsForTypeCmd,
				A2(
					$elm$core$Basics$composeL,
					$author$project$PAM$Message$LinkExistingObjectMsg,
					A2($author$project$PAM$Message$HandleGetObjectsForLinking, parentObj, childObjType)),
				function (a) {
					return $author$project$PAM$Message$APIUnauthorizedHandler(
						A2($author$project$PAM$Message$API_LinkExisting, parentObj, childObjType));
				},
				childObjType.r,
				model));
	});
var $author$project$PAM$Message$API_RequestLongObject = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$PAM$Message$ObjectLoaded = function (a) {
	return {$: 52, a: a};
};
var $author$project$PAM$API$APIBase$object_LongRead = F7(
	function (settings, user, model, id, type_, _return, onError) {
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'object',
			'LongRead',
			$elm$core$Maybe$Just(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$int(id)),
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$int(type_.r))
						]))),
			_return,
			onError,
			user,
			A2(
				$author$project$PAM$Object$objectDecoder,
				model,
				$elm$core$Maybe$Just(type_)));
	});
var $author$project$PAM$requestLongObject = F3(
	function (model, type_, objId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				function (objModel) {
					return A7(
						$author$project$PAM$API$APIBase$object_LongRead,
						model.bR.jQ,
						model.aq,
						objModel,
						objId,
						type_,
						$author$project$PAM$Message$ObjectLoaded,
						function (res) {
							return $author$project$PAM$Message$APIUnauthorizedHandler(
								A2($author$project$PAM$Message$API_RequestLongObject, objId, type_.r));
						});
				},
				A2($author$project$PAM$Model$ObjectModels$get, type_.r, model.a.l)));
	});
var $author$project$PAM$Message$API_RequestObject = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$PAM$Message$UpdateLoadedObject = function (a) {
	return {$: 11, a: a};
};
var $author$project$PAM$API$APIBase$object_Read = F6(
	function (settings, user, model, id, _return, onError) {
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'object',
			'Read',
			$elm$core$Maybe$Just(
				$elm$json$Json$Encode$int(id)),
			_return,
			onError,
			user,
			A2($author$project$PAM$Object$objectDecoder, model, $elm$core$Maybe$Nothing));
	});
var $author$project$PAM$requestObject = F3(
	function (model, typeKey, objId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				function (objModel) {
					return A6(
						$author$project$PAM$API$APIBase$object_Read,
						model.bR.jQ,
						model.aq,
						objModel,
						objId,
						$author$project$PAM$Message$UpdateLoadedObject,
						function (res) {
							return $author$project$PAM$Message$APIUnauthorizedHandler(
								A2($author$project$PAM$Message$API_RequestObject, typeKey, objId));
						});
				},
				A2($author$project$PAM$Model$ObjectModels$get, typeKey, model.a.l)));
	});
var $author$project$PAM$Message$API_RequestObjects = function (a) {
	return {$: 9, a: a};
};
var $author$project$PAM$Message$UpdateLoadedObjects = function (a) {
	return {$: 12, a: a};
};
var $elm$core$Maybe$map3 = F4(
	function (func, ma, mb, mc) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					return $elm$core$Maybe$Just(
						A3(func, a, b, c));
				}
			}
		}
	});
var $author$project$PAM$newGridState = F3(
	function (objectType, fields, layout) {
		return {
			aM: $author$project$Grid$Grid$defaultGridModel,
			N: $elm$core$Maybe$Just(objectType)
		};
	});
var $author$project$PAM$setNewGridState = F2(
	function (model, typeId) {
		var modelInfo = model.a;
		return A2(
			$elm$core$Maybe$withDefault,
			model,
			A4(
				$elm$core$Maybe$map3,
				F3(
					function (objType, fields, layout) {
						return _Utils_update(
							model,
							{
								aN: A3($author$project$PAM$newGridState, objType, fields, layout)
							});
					}),
				A2($author$project$PAM$Model$ObjectTypes$get, typeId, modelInfo.c),
				A2($author$project$PAM$Model$ObjectModels$get, typeId, modelInfo.l),
				A2($author$project$PAM$Model$GridLayouts$get, typeId, modelInfo.dW)));
	});
var $author$project$PAM$Route$setQueryParams = F2(
	function (params, route) {
		switch (route.$) {
			case 0:
				return $author$project$PAM$Route$Blank(params);
			case 1:
				return $author$project$PAM$Route$Map(params);
			case 2:
				var objTypeKey = route.a;
				return A2($author$project$PAM$Route$NewObject, objTypeKey, params);
			case 3:
				var parentObjTypeKey = route.a;
				var parentObjId = route.b;
				var childObjTypeKey = route.c;
				return A4($author$project$PAM$Route$LinkNewObject, parentObjTypeKey, parentObjId, childObjTypeKey, params);
			case 4:
				var objTypeKey = route.a;
				var objId = route.b;
				return A3($author$project$PAM$Route$ShowObjectDetails, objTypeKey, objId, params);
			default:
				var objTypeKey = route.a;
				var objId = route.b;
				return A3($author$project$PAM$Route$EditObjectDetails, objTypeKey, objId, params);
		}
	});
var $author$project$PAM$Route$setGridParam = F2(
	function (objTypeId, route) {
		var newParams = function (params) {
			return _Utils_update(
				params,
				{aM: objTypeId});
		}(
			$author$project$PAM$Route$getQueryParams(route));
		return A2($author$project$PAM$Route$setQueryParams, newParams, route);
	});
var $author$project$PAM$Route$trySetGridParam = F2(
	function (objTypeId, route) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$PAM$Route$setGridParam(objTypeId),
			route);
	});
var $author$project$PAM$requestObjectsFor = F2(
	function (typeId, model) {
		return _Utils_Tuple2(
			A2($author$project$PAM$setNewGridState, model, typeId),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$PAM$requestObjectsForTypeCmd,
						$author$project$PAM$Message$UpdateLoadedObjects,
						function (a) {
							return $author$project$PAM$Message$APIUnauthorizedHandler(
								$author$project$PAM$Message$API_RequestObjects(typeId));
						},
						typeId,
						model),
						A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							$author$project$PAM$Route$pushUrl(model.lw),
							A2($author$project$PAM$Route$trySetGridParam, typeId, model.mS)))
					])));
	});
var $author$project$PAM$Message$API_SaveAndLink = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$PAM$Message$NewLinkedObjectSaved = F2(
	function (a, b) {
		return {$: 27, a: a, b: b};
	});
var $author$project$PAM$Misc$dateRangeToYMDString = function (range) {
	return function () {
		var _v0 = range.iT;
		if (_v0.$ === 1) {
			var st = _v0.a;
			return $author$project$NormalDate$dateToIsoString(st);
		} else {
			return '';
		}
	}() + ('|' + function () {
		var _v1 = range.e0;
		if (_v1.$ === 1) {
			var ed = _v1.a;
			return $author$project$NormalDate$dateToIsoString(ed);
		} else {
			return '';
		}
	}());
};
var $author$project$PAM$Model$FieldChoices$encodeChoice = function (choice) {
	switch (choice.$) {
		case 0:
			var value = choice.a;
			return $elm$json$Json$Encode$int(value);
		case 1:
			var values = choice.a;
			return A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, values);
		default:
			if (choice.a.$ === 1) {
				var value = choice.a.a;
				return $elm$json$Json$Encode$int(value);
			} else {
				if (!choice.a.a.$) {
					var value = choice.a.a.a;
					return $elm$json$Json$Encode$string(value);
				} else {
					var _v1 = choice.a.a;
					return $elm$json$Json$Encode$null;
				}
			}
	}
};
var $author$project$PAM$Object$FieldValue$encodeFieldChoice = F2(
	function (field, maybeChoice) {
		var encodedChoice = A2(
			$elm$core$Maybe$withDefault,
			$elm$json$Json$Encode$null,
			A2($elm$core$Maybe$map, $author$project$PAM$Model$FieldChoices$encodeChoice, maybeChoice));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$int(field.r)),
					_Utils_Tuple2('selected', encodedChoice)
				]));
	});
var $author$project$PAM$Scores$layerScoresEncoder = function (scores) {
	return $elm$json$Json$Encode$null;
};
var $author$project$PAM$Misc$moneyToString = function (money) {
	return '$' + A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, money);
};
var $author$project$PAM$Object$FieldValue$objectReferenceEncoder = function (ref) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'objectId',
				$elm$json$Json$Encode$int(ref.h_)),
				_Utils_Tuple2(
				'typeKey',
				$elm$json$Json$Encode$string(ref.ja)),
				_Utils_Tuple2(
				'objectName',
				$elm$json$Json$Encode$string(ref.lX)),
				_Utils_Tuple2(
				'isSpatial',
				$elm$json$Json$Encode$bool(ref.ls))
			]));
};
var $author$project$Fields$Shared$validityToMaybe = function (v) {
	if (v.$ === 1) {
		var a = v.a;
		return $elm$core$Maybe$Just(a);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$PAM$Object$FieldValue$fieldValueEncoder = F2(
	function (field, value) {
		switch (value.$) {
			case 1:
				var val = value.a;
				return $elm$json$Json$Encode$string(
					A2($elm$core$Maybe$withDefault, '', val));
			case 2:
				var val = value.a;
				return $elm$json$Json$Encode$string(
					A2($elm$core$Maybe$withDefault, '', val));
			case 7:
				var val = value.a;
				return $elm$json$Json$Encode$string(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							$author$project$NormalDate$dateToIsoString,
							$author$project$Fields$Shared$validityToMaybe(val))));
			case 8:
				var val = value.a;
				return $elm$json$Json$Encode$string(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							$author$project$PAM$Misc$dateRangeToYMDString,
							$author$project$Fields$Shared$validityToMaybe(val))));
			case 9:
				var val = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, val));
			case 5:
				var val = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						$elm$json$Json$Encode$float,
						$author$project$Fields$Shared$validityToMaybe(val)));
			case 3:
				var val = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						$elm$json$Json$Encode$int,
						$author$project$Fields$Shared$validityToMaybe(val)));
			case 4:
				var val = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						$elm$json$Json$Encode$int,
						$author$project$Fields$Shared$validityToMaybe(val)));
			case 15:
				var val = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						$elm$json$Json$Encode$int,
						$author$project$Fields$Shared$validityToMaybe(val)));
			case 6:
				var val = value.a;
				return $elm$json$Json$Encode$string(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							$author$project$PAM$Misc$moneyToString,
							$author$project$Fields$Shared$validityToMaybe(val))));
			case 10:
				var maybeChoice = value.a;
				return A2($author$project$PAM$Object$FieldValue$encodeFieldChoice, field, maybeChoice);
			case 11:
				var maybeChoice = value.a;
				return A2($author$project$PAM$Object$FieldValue$encodeFieldChoice, field, maybeChoice);
			case 12:
				var maybeChoice = value.a;
				return A2($author$project$PAM$Object$FieldValue$encodeFieldChoice, field, maybeChoice);
			case 13:
				var val = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$PAM$Object$FieldValue$objectReferenceEncoder, val));
			case 14:
				var values = value.a;
				if (!values.$) {
					var vals = values.a;
					return A2($elm$json$Json$Encode$list, $author$project$PAM$Object$FieldValue$objectReferenceEncoder, vals);
				} else {
					return A2($elm$json$Json$Encode$list, $author$project$PAM$Object$FieldValue$objectReferenceEncoder, _List_Nil);
				}
			case 16:
				if (!value.a.$) {
					var flag = value.a.a;
					return $elm$json$Json$Encode$object(
						function () {
							if (!flag.$) {
								if (!flag.a.$) {
									var comment = flag.a.a;
									return _List_fromArray(
										[
											_Utils_Tuple2(
											'value',
											$elm$json$Json$Encode$bool(true)),
											_Utils_Tuple2(
											'comment',
											$elm$json$Json$Encode$string(comment))
										]);
								} else {
									var _v3 = flag.a;
									return _List_fromArray(
										[
											_Utils_Tuple2(
											'value',
											$elm$json$Json$Encode$bool(true))
										]);
								}
							} else {
								if (!flag.a.$) {
									var comment = flag.a.a;
									return _List_fromArray(
										[
											_Utils_Tuple2(
											'value',
											$elm$json$Json$Encode$bool(false)),
											_Utils_Tuple2(
											'comment',
											$elm$json$Json$Encode$string(comment))
										]);
								} else {
									var _v4 = flag.a;
									return _List_fromArray(
										[
											_Utils_Tuple2(
											'value',
											$elm$json$Json$Encode$bool(false))
										]);
								}
							}
						}());
				} else {
					var _v5 = value.a;
					return $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2('value', $elm$json$Json$Encode$null)
							]));
				}
			case 18:
				if (!value.a.$) {
					var vals = value.a.a;
					return A2($elm$json$Json$Encode$list, $author$project$PAM$Scores$layerScoresEncoder, vals);
				} else {
					var _v6 = value.a;
					return $elm$json$Json$Encode$null;
				}
			case 0:
				return $elm$json$Json$Encode$null;
			case 19:
				return $elm$json$Json$Encode$null;
			default:
				var spatial = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						function (val) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'wkt',
										$elm$json$Json$Encode$string(val.aj)),
										_Utils_Tuple2(
										'acres',
										A2(
											$elm$core$Maybe$withDefault,
											$elm$json$Json$Encode$null,
											A2(
												$elm$core$Maybe$map,
												function (x) {
													return $elm$json$Json$Encode$float(x);
												},
												val.U))),
										_Utils_Tuple2(
										'type',
										$elm$json$Json$Encode$string(
											function () {
												var _v7 = val.bi;
												if (!_v7) {
													return 'bbox';
												} else {
													return 'exact';
												}
											}()))
									]));
						},
						$author$project$Fields$Shared$validityToMaybe(spatial)));
		}
	});
var $author$project$PAM$Object$FieldValue$maybeFieldValueEncoder = F2(
	function (values, field) {
		return _Utils_Tuple2(
			field.fu,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2(
					$elm$core$Maybe$map,
					$author$project$PAM$Object$FieldValue$fieldValueEncoder(field),
					A2($author$project$PAM$Object$FieldValue$get, field, values))));
	});
var $author$project$PAM$Object$FieldValue$fieldValuesEncoder = F2(
	function (modelFields, values) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				$author$project$PAM$Object$FieldValue$maybeFieldValueEncoder(values),
				modelFields));
	});
var $author$project$PAM$Model$ObjectTypes$objectTypeEncoder = function (type_) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(type_.r))
			]));
};
var $author$project$PAM$Object$objectEncoder = F2(
	function (model, object) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(object.r)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(object.fu)),
					_Utils_Tuple2(
					'type',
					$author$project$PAM$Model$ObjectTypes$objectTypeEncoder(object.bi)),
					_Utils_Tuple2(
					'fields',
					A2($author$project$PAM$Object$FieldValue$fieldValuesEncoder, model, object.au))
				]));
	});
var $author$project$PAM$API$APIBase$object_Create = F5(
	function (settings, user, _v0, _return, onError) {
		var model = _v0.a;
		var object = _v0.b;
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'object',
			'Create',
			$elm$core$Maybe$Just(
				A2($author$project$PAM$Object$objectEncoder, model, object)),
			_return,
			onError,
			user,
			A2($author$project$PAM$Object$objectDecoder, model, $elm$core$Maybe$Nothing));
	});
var $author$project$PAM$saveAndLinkDetails = F3(
	function (parentRef, childObj, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
			A2(
				$elm$core$Maybe$map,
				function (objModel) {
					return _Utils_eq(childObj.r, -1) ? _Utils_Tuple2(
						model,
						A5(
							$author$project$PAM$API$APIBase$object_Create,
							model.bR.jQ,
							model.aq,
							_Utils_Tuple2(objModel, childObj),
							$author$project$PAM$Message$NewLinkedObjectSaved(parentRef),
							function (res) {
								return $author$project$PAM$Message$APIUnauthorizedHandler(
									A2($author$project$PAM$Message$API_SaveAndLink, parentRef, childObj));
							})) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				},
				A2($author$project$PAM$Model$ObjectModels$get, childObj.bi.r, model.a.l)));
	});
var $author$project$PAM$Message$API_Create = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$Message$API_Update = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$Message$NewObjectSaved = function (a) {
	return {$: 26, a: a};
};
var $author$project$PAM$Message$ObjectUpdatesSaved = function (a) {
	return {$: 28, a: a};
};
var $author$project$PAM$Object$Repo$finishEditObject = F2(
	function (obj, _v0) {
		var repo = _v0.a;
		var backup = _v0.b;
		return A2(
			$elm$core$Maybe$withDefault,
			A2($author$project$PAM$Object$Repo$Repo, repo, $elm$core$Dict$empty),
			A2(
				$elm$core$Maybe$map,
				function (o) {
					return A2(
						$author$project$PAM$Object$Repo$Repo,
						A3(
							$elm$core$Dict$insert,
							o.r,
							_Utils_update(
								o,
								{lo: false}),
							repo),
						$elm$core$Dict$empty);
				},
				A2(
					$author$project$PAM$Object$Repo$getObject,
					obj.r,
					A2($author$project$PAM$Object$Repo$Repo, repo, backup))));
	});
var $author$project$PAM$API$APIBase$object_Update = F5(
	function (settings, user, _v0, _return, onError) {
		var model = _v0.a;
		var object = _v0.b;
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'object',
			'Update',
			$elm$core$Maybe$Just(
				A2($author$project$PAM$Object$objectEncoder, model, object)),
			_return,
			onError,
			user,
			A2($author$project$PAM$Object$objectDecoder, model, $elm$core$Maybe$Nothing));
	});
var $author$project$PAM$saveDetails = F2(
	function (obj, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
			A2(
				$elm$core$Maybe$map,
				function (objModel) {
					if (_Utils_eq(obj.r, -1)) {
						return _Utils_Tuple2(
							model,
							A5(
								$author$project$PAM$API$APIBase$object_Create,
								model.bR.jQ,
								model.aq,
								_Utils_Tuple2(objModel, obj),
								$author$project$PAM$Message$NewObjectSaved,
								function (res) {
									return $author$project$PAM$Message$APIUnauthorizedHandler(
										$author$project$PAM$Message$API_Create(obj));
								}));
					} else {
						var newRepo = A2($author$project$PAM$Object$Repo$finishEditObject, obj, model.b);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{b: newRepo}),
							A5(
								$author$project$PAM$API$APIBase$object_Update,
								model.bR.jQ,
								model.aq,
								_Utils_Tuple2(objModel, obj),
								$author$project$PAM$Message$ObjectUpdatesSaved,
								function (res) {
									return $author$project$PAM$Message$APIUnauthorizedHandler(
										$author$project$PAM$Message$API_Update(obj));
								}));
					}
				},
				A2($author$project$PAM$Model$ObjectModels$get, obj.bi.r, model.a.l)));
	});
var $author$project$PAM$handleAuthorized = function (model) {
	return $author$project$PAM$processPendingAPIRecursive(
		function (_v6) {
			var mo = _v6.a;
			var c = _v6.b;
			return _Utils_Tuple2(
				_Utils_update(
					mo,
					{
						aq: $author$project$InPageOAuth$getUser(model.ar)
					}),
				c);
		}(
			function () {
				if (!model.d9) {
					var loginState = $author$project$InPageOAuth$getUser(model.ar);
					if (loginState.$ === 3) {
						var user = loginState.a;
						return _Utils_Tuple2(
							model,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A4(
										$author$project$PAM$API$APIBase$model_GetInfo,
										model.bR.jQ,
										loginState,
										$author$project$PAM$Message$ModelInfoResponse,
										function (res) {
											return $author$project$PAM$Message$APIUnauthorizedHandler($author$project$PAM$Message$API_GetModelInfo);
										}),
										A4(
										$author$project$PAM$API$APIBase$app_GetConfig,
										model.bR.jQ,
										loginState,
										$author$project$PAM$Message$AppConfigResponse,
										function (res) {
											return $author$project$PAM$Message$APIUnauthorizedHandler($author$project$PAM$Message$API_GetAppConfig);
										})
									])));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aq: loginState}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			}()));
};
var $author$project$PAM$processPendingAPI = F3(
	function (omodel, request, pending) {
		var model = _Utils_update(
			omodel,
			{ca: pending});
		switch (request.$) {
			case 0:
				var typeKey = request.a;
				var oid = request.b;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (type_) {
							return _Utils_Tuple2(
								model,
								A3($author$project$PAM$requestLongObject, model, type_, oid));
						},
						A2($author$project$PAM$Model$ObjectTypes$get, typeKey, model.a.c)));
			case 1:
				var typeKey = request.a;
				var oid = request.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$PAM$requestObject, model, typeKey, oid));
			case 2:
				var obj = request.a;
				return A2($author$project$PAM$saveDetails, obj, model);
			case 3:
				var obj = request.a;
				return A2($author$project$PAM$saveDetails, obj, model);
			case 4:
				var objId = request.a;
				return A2($author$project$PAM$deleteObject, objId, model);
			case 5:
				var parentRed = request.a;
				var obj = request.b;
				return A3($author$project$PAM$saveAndLinkDetails, parentRed, obj, model);
			case 6:
				var oid = request.a;
				var typeid = request.b;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (_v3) {
							var id = _v3.a;
							var type_ = _v3.b;
							return A3($author$project$PAM$getObjectDetailsNew, model, oid, type_);
						},
						A2($author$project$PAM$Model$ObjectTypes$findByKey, typeid, model.a.c)));
			case 7:
				var oid = request.a;
				var typeid = request.b;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (_v4) {
							var id = _v4.a;
							var type_ = _v4.b;
							return A3($author$project$PAM$getObjectForZoom, model, oid, type_);
						},
						A2($author$project$PAM$Model$ObjectTypes$findByKey, typeid, model.a.c)));
			case 8:
				var o = request.a;
				var t = request.b;
				return A3($author$project$PAM$openLinkExistingObjectModal, o, t, model);
			case 9:
				var t = request.a;
				return A2($author$project$PAM$requestObjectsFor, t, model);
			case 10:
				return $author$project$PAM$handleAuthorized(model);
			case 11:
				return $author$project$PAM$handleAuthorized(model);
			default:
				return $author$project$PAM$getAppConfig(model);
		}
	});
var $author$project$PAM$processPendingAPIRecursive = function (_v0) {
	processPendingAPIRecursive:
	while (true) {
		var model = _v0.a;
		var cmds = _v0.b;
		var _v1 = model.ca;
		if (!_v1.b) {
			return _Utils_Tuple2(model, cmds);
		} else {
			if (!_v1.b.b) {
				var item = _v1.a;
				return A3($author$project$PAM$processPendingAPI, model, item, _List_Nil);
			} else {
				var item = _v1.a;
				var more = _v1.b;
				var $temp$_v0 = A3($author$project$PAM$processPendingAPI, model, item, more);
				_v0 = $temp$_v0;
				continue processPendingAPIRecursive;
			}
		}
	}
};
var $author$project$PAM$Message$ConfirmDeleteObject = function (a) {
	return {$: 5, a: a};
};
var $author$project$PAM$confirmation_DeleteObject = F3(
	function (_v0, maybeChildType, okCmd) {
		var singularName = _v0.cZ;
		return {
			bn: {
				dy: 'No',
				bU: $author$project$PAM$Message$ConfirmMsg($author$project$PAM$Message$CancelClicked),
				ej: 'Yes',
				ek: $author$project$PAM$Message$ConfirmMsg(
					$author$project$PAM$Message$ConfirmDeleteObject(okCmd))
			},
			ee: function () {
				if (!maybeChildType.$) {
					var pluralName = maybeChildType.a.ie;
					return 'Are you sure you want to delete the ' + (singularName + (' and unlink all associated ' + (pluralName + ('? No linked ' + (pluralName + ' will be deleted in the process.')))));
				} else {
					return 'Are you sure you want to delete the ' + (singularName + '?');
				}
			}(),
			F: 'Confirm Delete'
		};
	});
var $author$project$PAM$Model$ObjectModels$findField = F3(
	function (predicate, id, objectModels) {
		return A2(
			$elm$core$Maybe$andThen,
			$elm_community$list_extra$List$Extra$find(predicate),
			A2($author$project$PAM$Model$ObjectModels$get, id, objectModels));
	});
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$PAM$Model$ModelInfo$getLinkableObjectType = F2(
	function (parentTypeId, info) {
		return A2(
			$elm$core$Maybe$andThen,
			function (id) {
				return A2($author$project$PAM$Model$ObjectTypes$get, id, info.c);
			},
			A2(
				$elm$core$Maybe$andThen,
				function ($) {
					return $.is;
				},
				A3(
					$author$project$PAM$Model$ObjectModels$findField,
					function (field) {
						return $elm_community$maybe_extra$Maybe$Extra$isJust(field.is);
					},
					parentTypeId,
					info.l)));
	});
var $author$project$PAM$handleClickDeleteObject = F3(
	function (objTypeKey, objId, model) {
		var maybeObjType = A2(
			$elm$core$Maybe$map,
			$elm$core$Tuple$second,
			A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c));
		var maybeChildObjType = A2(
			$elm$core$Maybe$andThen,
			function (id) {
				return A2($author$project$PAM$Model$ModelInfo$getLinkableObjectType, id, model.a);
			},
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.r;
				},
				maybeObjType));
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						'PAM.handleClickDeleteObject :: Could not find object type. [ObjectTypeKey = ' + (objTypeKey + ('] [ObjectId = ' + ($elm$core$String$fromInt(objId) + ']')))))),
			A2(
				$elm$core$Maybe$map,
				function (parentType) {
					var cmd = A2($author$project$PAM$deleteObjectCmd, objId, model);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								j: $author$project$PAM$Modal$ConfirmModal(
									A3(
										$author$project$PAM$confirmation_DeleteObject,
										parentType,
										maybeChildObjType,
										A2($author$project$PAM$deleteObjectCmd, objId, model)))
							}),
						$elm$core$Platform$Cmd$none);
				},
				maybeObjType));
	});
var $author$project$PAM$Object$Repo$clearNewObject = function (_v0) {
	var repo = _v0.a;
	var backup = _v0.b;
	return A2(
		$author$project$PAM$Object$Repo$Repo,
		A2($elm$core$Dict$remove, -1, repo),
		backup);
};
var $author$project$PAM$Object$Repo$loadBackup = function (_v0) {
	var repo = _v0.a;
	var backup = _v0.b;
	return (!$elm$core$Dict$isEmpty(backup)) ? A2($author$project$PAM$Object$Repo$Repo, backup, $elm$core$Dict$empty) : A2($author$project$PAM$Object$Repo$Repo, repo, backup);
};
var $author$project$PAM$noop = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$PAM$handleConfirm = F2(
	function (model, message) {
		switch (message.$) {
			case 0:
				return $author$project$PAM$noop(
					_Utils_update(
						model,
						{j: $author$project$PAM$Modal$NoModal}));
			case 1:
				return $author$project$PAM$noop(
					_Utils_update(
						model,
						{j: $author$project$PAM$Modal$NoModal}));
			case 2:
				var cmd = message.a;
				var newRepo = $author$project$PAM$Object$Repo$loadBackup(model.b);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: $author$project$PAM$Modal$NoModal, b: newRepo}),
					cmd);
			case 3:
				var cmd = message.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							j: $author$project$PAM$Modal$NoModal,
							b: $author$project$PAM$Object$Repo$clearNewObject(model.b)
						}),
					cmd);
			case 4:
				var cmd = message.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: $author$project$PAM$Modal$NoModal}),
					cmd);
			default:
				var cmd = message.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: $author$project$PAM$Modal$NoModal}),
					cmd);
		}
	});
var $author$project$PAM$handleError = F2(
	function (model, err) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$PAM$Helpers$httpErrorMsg = F2(
	function (info, error) {
		switch (error.$) {
			case 0:
				var err = error.a;
				return info + (' :: ' + ('Bad url: ' + err));
			case 1:
				return info + ' :: Http request timed out';
			case 2:
				return info + ' :: Network error';
			case 3:
				var status = error.a;
				return info + (' :: Bad status: ' + $elm$core$String$fromInt(status));
			default:
				var err = error.a;
				return info + (' :: Bad payload: ' + err);
		}
	});
var $author$project$PAM$Object$Repo$insertObject = F2(
	function (obj, _v0) {
		var repo = _v0.a;
		var backup = _v0.b;
		var newRepo = A3($elm$core$Dict$insert, obj.r, obj, repo);
		var newBackup = A3($elm$core$Dict$insert, obj.r, obj, backup);
		var maybeOldObj = A2(
			$author$project$PAM$Object$Repo$getObject,
			obj.r,
			A2($author$project$PAM$Object$Repo$Repo, repo, backup));
		if (!maybeOldObj.$) {
			var oldObj = maybeOldObj.a;
			return oldObj.lo ? A2($author$project$PAM$Object$Repo$Repo, repo, backup) : ($elm$core$Dict$isEmpty(backup) ? A2($author$project$PAM$Object$Repo$Repo, newRepo, backup) : A2($author$project$PAM$Object$Repo$Repo, newRepo, newBackup));
		} else {
			return $elm$core$Dict$isEmpty(backup) ? A2($author$project$PAM$Object$Repo$Repo, newRepo, backup) : A2($author$project$PAM$Object$Repo$Repo, newRepo, newBackup);
		}
	});
var $author$project$PAM$Object$lazyToFull = F2(
	function (lazyObject, model) {
		var decoded = function () {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2(
					$elm$json$Json$Decode$andThen,
					$author$project$PAM$Object$FieldValue$fieldValuesDecoder(model),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value)),
				lazyObject.au);
			if (!_v0.$) {
				var vals = _v0.a;
				return vals;
			} else {
				return $elm$core$Dict$empty;
			}
		}();
		return A9($author$project$PAM$Object$Object, lazyObject.r, lazyObject.fu, lazyObject.bi, decoded, lazyObject.gP, lazyObject.dI, lazyObject.d3, lazyObject.d4, lazyObject.lo);
	});
var $author$project$PAM$Route$setSidebarParam = F2(
	function (isOpen, route) {
		var newParams = function (params) {
			return _Utils_update(
				params,
				{iP: isOpen});
		}(
			$author$project$PAM$Route$getQueryParams(route));
		return A2($author$project$PAM$Route$setQueryParams, newParams, route);
	});
var $author$project$PAM$Route$setThemesParam = F2(
	function (isSelected, route) {
		var newParams = function (params) {
			return _Utils_update(
				params,
				{s: isSelected});
		}(
			$author$project$PAM$Route$getQueryParams(route));
		return A2($author$project$PAM$Route$setQueryParams, newParams, route);
	});
var $author$project$PAM$handleLazyObjectLoaded = F2(
	function (model, res) {
		if (!res.$) {
			var lazyObj = res.a;
			var nnn = A2(
				$elm$core$Maybe$map,
				$author$project$PAM$Object$lazyToFull(lazyObj),
				A2($author$project$PAM$Model$ObjectModels$get, lazyObj.bi.r, model.a.l));
			if (!nnn.$) {
				var o = nnn.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A2($author$project$PAM$Object$Repo$insertObject, o, model.b)
						}),
					A2(
						$author$project$PAM$Route$pushUrl,
						model.lw,
						A2(
							$author$project$PAM$Route$setSidebarParam,
							true,
							A2(
								$author$project$PAM$Route$setThemesParam,
								false,
								A4($author$project$PAM$Route$initShowObjectDetails, o.bi.lw, o.r, $elm$core$Maybe$Nothing, model.mS)))));
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = res.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleLazyObjectLoaded', error))));
		}
	});
var $author$project$PlugMap$Core$Ports$fitToWktCmd = _Platform_outgoingPort('fitToWktCmd', $elm$core$Basics$identity);
var $author$project$PAM$Map$zoomToWkt = F4(
	function (wkt, model, leftPadding, bottomPadding) {
		return _Utils_Tuple2(
			model,
			$author$project$PlugMap$Core$Ports$fitToWktCmd(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'wkt',
							$elm$json$Json$Encode$string(wkt)),
							_Utils_Tuple2(
							'left',
							$elm$json$Json$Encode$int(leftPadding)),
							_Utils_Tuple2(
							'bottom',
							$elm$json$Json$Encode$int(bottomPadding))
						]))));
	});
var $author$project$PAM$zoomToWkt = F2(
	function (model, maybeWkt) {
		if (!maybeWkt.$) {
			var wkt = maybeWkt.a;
			var _v1 = A4(
				$author$project$PAM$Map$zoomToWkt,
				wkt,
				model.h,
				(!model.m8) ? model.fD.lG : 0,
				(!model.j8) ? (model.fD.j6 + model.fD.j7) : 0);
			var newMap = _v1.a;
			var newCmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{h: newMap}),
				A2($elm$core$Platform$Cmd$map, $author$project$PAM$mapTranslater, newCmd));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$handleLazyObjectLoadedZoom = F2(
	function (model, res) {
		if (!res.$) {
			var lazyObj = res.a;
			var nnn = A2(
				$elm$core$Maybe$map,
				$author$project$PAM$Object$lazyToFull(lazyObj),
				A2($author$project$PAM$Model$ObjectModels$get, lazyObj.bi.r, model.a.l));
			if (!nnn.$) {
				var newObj = nnn.a;
				var newModel = _Utils_update(
					model,
					{
						b: A2($author$project$PAM$Object$Repo$insertObject, newObj, model.b)
					});
				return A2(
					$author$project$PAM$zoomToWkt,
					newModel,
					$author$project$PAM$Object$getWkt(newObj));
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = res.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleLazyObjectLoadedZoom', error))));
		}
	});
var $author$project$PAM$Message$ConfirmLinkExistingClicked = function (a) {
	return {$: 5, a: a};
};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$blur = _Browser_call('blur');
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$PAM$handleGetObjectsForLinking = F4(
	function (parentObj, childObjType, response, model) {
		var linkExistingModal = $author$project$PAM$Modal$LinkModal(
			{
				bU: $elm$core$Maybe$Just(
					$author$project$PAM$Message$LinkExistingObjectMsg($author$project$PAM$Message$CancelLinkExistingClicked)),
				gA: 'Cancel',
				gF: childObjType,
				gG: response,
				bm: 1,
				fm: $elm$core$Maybe$Nothing,
				hL: 'Link',
				fE: parentObj,
				fM: $elm$core$Maybe$Nothing
			});
		if (!response.$) {
			var objects = response.a;
			return function (newRepo) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: linkExistingModal, b: newRepo}),
					$elm$core$Platform$Cmd$none);
			}(
				A3(
					$elm$core$List$foldl,
					F2(
						function (obj, repo) {
							return A2($author$project$PAM$Object$Repo$insertObject, obj, repo);
						}),
					model.b,
					objects));
		} else {
			var error = response.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{j: linkExistingModal}),
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleGetObjectsForLinking', error))));
		}
	});
var $author$project$PAM$Object$hasGeometry = function (obj) {
	var _v0 = A2($author$project$PAM$Object$FieldValue$getByKey, 'Geometry', obj.au);
	if (((!_v0.$) && (_v0.a.$ === 17)) && (_v0.a.a.$ === 1)) {
		var _v1 = _v0.a.a;
		var spatial = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$PAM$Object$FieldValue$tryGet = F2(
	function (maybeModelField, fieldValues) {
		if (!maybeModelField.$) {
			var modelField = maybeModelField.a;
			return A2($author$project$PAM$Object$FieldValue$get, modelField, fieldValues);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$PAM$Object$FieldValue$tryInsert = F3(
	function (maybeModelField, value, fieldValues) {
		if (!maybeModelField.$) {
			var modelField = maybeModelField.a;
			return A3($author$project$PAM$Object$FieldValue$insert, modelField, value, fieldValues);
		} else {
			return fieldValues;
		}
	});
var $author$project$PAM$Object$linkObjects = F3(
	function (child, parent, objectModels) {
		var predicate = function (_v4) {
			var reference = _v4.is;
			return _Utils_eq(
				reference,
				$elm$core$Maybe$Just(child.bi.r));
		};
		var targetField = A3($author$project$PAM$Model$ObjectModels$findField, predicate, parent.bi.r, objectModels);
		var childReference = A4(
			$author$project$PAM$Object$FieldValue$ObjectReference,
			child.r,
			child.bi.lw,
			child.fu,
			$author$project$PAM$Object$hasGeometry(child));
		var _v0 = A2($author$project$PAM$Object$FieldValue$tryGet, targetField, parent.au);
		if (!_v0.$) {
			var fieldValue = _v0.a;
			if (fieldValue.$ === 14) {
				if (!fieldValue.a.$) {
					var references = fieldValue.a.a;
					var _v2 = A2(
						$elm_community$list_extra$List$Extra$find,
						function (ref) {
							return _Utils_eq(childReference.h_, ref.h_);
						},
						references);
					if (!_v2.$) {
						return $elm$core$Result$Ok(parent);
					} else {
						var newValue = $author$project$PAM$Object$FieldValue$FieldValue_MultipleObject(
							$elm$core$Maybe$Just(
								A2($elm$core$List$cons, childReference, references)));
						var newValues = A3($author$project$PAM$Object$FieldValue$tryInsert, targetField, newValue, parent.au);
						return $elm$core$Result$Ok(
							_Utils_update(
								parent,
								{au: newValues}));
					}
				} else {
					var _v3 = fieldValue.a;
					var newValue = $author$project$PAM$Object$FieldValue$FieldValue_MultipleObject(
						$elm$core$Maybe$Just(
							_List_fromArray(
								[childReference])));
					var newValues = A3($author$project$PAM$Object$FieldValue$tryInsert, targetField, newValue, parent.au);
					return $elm$core$Result$Ok(
						_Utils_update(
							parent,
							{au: newValues}));
				}
			} else {
				return $elm$core$Result$Err('Tried to link object to invalid field type');
			}
		} else {
			var newValue = $author$project$PAM$Object$FieldValue$FieldValue_MultipleObject(
				$elm$core$Maybe$Just(
					_List_fromArray(
						[childReference])));
			var newValues = A3($author$project$PAM$Object$FieldValue$tryInsert, targetField, newValue, parent.au);
			return $elm$core$Result$Ok(
				_Utils_update(
					parent,
					{au: newValues}));
		}
	});
var $author$project$PAM$linkObject = F3(
	function (childObj, parentObj, model) {
		var _v0 = A3($author$project$PAM$Object$linkObjects, childObj, parentObj, model.a.l);
		if (!_v0.$) {
			var updatedParent = _v0.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						b: A2($author$project$PAM$Object$Repo$changeObject, updatedParent, model.b)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			var error = _v0.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string('PAM.linkObject :: ' + error)));
		}
	});
var $author$project$PAM$handleLinkExistingMsg = F2(
	function (message, model) {
		switch (message.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: $author$project$PAM$Modal$NoModal}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var parentObj = message.a;
				var childObjType = message.b;
				var response = message.c;
				return A4($author$project$PAM$handleGetObjectsForLinking, parentObj, childObjType, response, model);
			case 2:
				var _v1 = model.j;
				if (_v1.$ === 2) {
					var modal = _v1.a;
					var linkExistingModal = $author$project$PAM$Modal$LinkModal(
						_Utils_update(
							modal,
							{bm: 1}));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j: linkExistingModal}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var _v2 = model.j;
				if (_v2.$ === 2) {
					var modal = _v2.a;
					var linkExistingModal = $author$project$PAM$Modal$LinkModal(
						_Utils_update(
							modal,
							{
								bm: $author$project$PAM$UI$Basic$flipOpenness(modal.bm)
							}));
					var focusLinkOptions = function () {
						var _v3 = modal.bm;
						if (!_v3) {
							return $elm$core$Platform$Cmd$none;
						} else {
							return A2(
								$elm$core$Task$attempt,
								function (_v4) {
									return $author$project$PAM$Message$NoOp;
								},
								$elm$browser$Browser$Dom$focus('link_existing_options'));
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j: linkExistingModal}),
						focusLinkOptions);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var selected = message.a;
				var _v5 = model.j;
				if (_v5.$ === 2) {
					var modal = _v5.a;
					var linkExistingModal = $author$project$PAM$Modal$LinkModal(
						_Utils_update(
							modal,
							{
								fm: $elm$core$Maybe$Just(
									$author$project$PAM$Message$LinkExistingObjectMsg(
										$author$project$PAM$Message$ConfirmLinkExistingClicked(selected))),
								fM: $elm$core$Maybe$Just(selected)
							}));
					var blurLinkOptions = function () {
						var _v6 = modal.bm;
						if (!_v6) {
							return A2(
								$elm$core$Task$attempt,
								function (_v7) {
									return $author$project$PAM$Message$NoOp;
								},
								$elm$browser$Browser$Dom$blur('link_existing_options'));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j: linkExistingModal}),
						blurLinkOptions);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var selectedChild = message.a;
				var _v8 = model.j;
				if (_v8.$ === 2) {
					var modal = _v8.a;
					return A3(
						$author$project$PAM$linkObject,
						selectedChild,
						modal.fE,
						_Utils_update(
							model,
							{j: $author$project$PAM$Modal$NoModal}));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$PAM$Map$ClearColor = {$: 13};
var $author$project$PAM$Map$Msg_Themes = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$Map$DrawEdit = 1;
var $author$project$PlugMap$Plugins$Drawing$disableDrawingCmd = _Platform_outgoingPort(
	'disableDrawingCmd',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Drawing$disableTool = function (model) {
	return model.kJ ? _Utils_Tuple2(
		_Utils_update(
			model,
			{kJ: false}),
		$author$project$PlugMap$Plugins$Drawing$disableDrawingCmd(0)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$PlugMap$Plugins$Drawing$enableDrawingCmd = _Platform_outgoingPort(
	'enableDrawingCmd',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Drawing$enableTool = function (model) {
	return (!model.kJ) ? _Utils_Tuple2(
		_Utils_update(
			model,
			{kJ: true}),
		$author$project$PlugMap$Plugins$Drawing$enableDrawingCmd(0)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$PAM$Map$handleDrawingToggle = function (model) {
	var _v0 = model.V;
	if (_v0 === 1) {
		var _v1 = $author$project$PlugMap$Plugins$Drawing$disableTool(model.at);
		var nd = _v1.a;
		var nc = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{V: 0, at: nd}),
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$PAM$Map$Internal,
				A2(
					$elm$core$Platform$Cmd$map,
					$author$project$PAM$Map$PluginMessage,
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$Msg_Drawing, nc))));
	} else {
		var _v2 = $author$project$PlugMap$Plugins$Drawing$enableTool(model.at);
		var nd = _v2.a;
		var nc = _v2.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{V: 1, at: nd}),
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$PAM$Map$Internal,
				A2(
					$elm$core$Platform$Cmd$map,
					$author$project$PAM$Map$PluginMessage,
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$Msg_Drawing, nc))));
	}
};
var $author$project$PAM$Map$FeaturePicker = 3;
var $author$project$PAM$Map$handleFeaturePickerToggle = function (model) {
	var _v0 = model.V;
	if (_v0 === 3) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{V: 0}),
			$elm$core$Platform$Cmd$none);
	} else {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{V: 3}),
			$elm$core$Platform$Cmd$none);
	}
};
var $author$project$PAM$Map$Upload = 2;
var $author$project$PAM$Map$handleUploadToggle = function (model) {
	var _v0 = model.V;
	if (_v0 === 2) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{V: 0}),
			$elm$core$Platform$Cmd$none);
	} else {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{V: 2}),
			$elm$core$Platform$Cmd$none);
	}
};
var $author$project$PlugMap$Plugins$Themes$addThemesCmd = _Platform_outgoingPort('addThemesCmd', $elm$core$Basics$identity);
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $author$project$PlugMap$Plugins$Themes$encodeMap = function (param) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'query',
				$elm$json$Json$Encode$string(param.$9)),
				_Utils_Tuple2(
				'values',
				A2($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$string)(param.jh))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeIdentifyField = function (idf) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(idf.fu)),
				function () {
				var _v0 = idf.hb;
				switch (_v0.$) {
					case 0:
						var param = _v0.a;
						return _Utils_Tuple2(
							'query',
							$elm$json$Json$Encode$string(param));
					case 1:
						var param = _v0.a;
						return _Utils_Tuple2(
							'static',
							$elm$json$Json$Encode$string(param));
					default:
						var param = _v0.a;
						return _Utils_Tuple2(
							'map',
							$author$project$PlugMap$Plugins$Themes$encodeMap(param));
				}
			}()
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeIdentifyDataMapping = function (idm) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'fields',
				A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Themes$encodeIdentifyField, idm.u))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeIdentify = function (id) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'enabled',
				$elm$json$Json$Encode$bool(id.kJ)),
				_Utils_Tuple2(
				'dataMapping',
				$author$project$PlugMap$Plugins$Themes$encodeIdentifyDataMapping(id.gT)),
				_Utils_Tuple2(
				'canViewDetails',
				$elm$json$Json$Encode$bool(id.gz)),
				_Utils_Tuple2(
				'idTransform',
				$elm$json$Json$Encode$int(id.ho)),
				_Utils_Tuple2(
				'typeKey',
				$elm$json$Json$Encode$string(id.ja)),
				_Utils_Tuple2(
				'queryLayerIds',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						$elm$json$Json$Encode$list($elm$json$Json$Encode$int),
						id.io))),
				_Utils_Tuple2(
				'tolerance',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$int, id.i1))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeEndpoint = function (endpoint) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(endpoint.c8)),
				_Utils_Tuple2(
				'zIndex',
				$elm$json$Json$Encode$int(endpoint.df)),
				_Utils_Tuple2(
				'tokenKey',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, endpoint.bM)),
				_Utils_Tuple2(
				'layersToShow',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, endpoint.hF)),
				_Utils_Tuple2(
				'layerDefs',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, endpoint.hD)),
				_Utils_Tuple2(
				'bbox',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, endpoint.go))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeEndpoints = function (urlTemplates) {
	if (!urlTemplates.$) {
		var url = urlTemplates.a;
		return A2(
			$elm$json$Json$Encode$list,
			$author$project$PlugMap$Plugins$Themes$encodeEndpoint,
			_List_fromArray(
				[url]));
	} else {
		var urls = urlTemplates.a;
		return A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Themes$encodeEndpoint, urls);
	}
};
var $author$project$PlugMap$Plugins$Themes$encodeExtent = function (extent) {
	return A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$float, extent);
};
var $author$project$PlugMap$Plugins$Themes$encodeEsriExportLayer = function (config) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'endpoints',
				$author$project$PlugMap$Plugins$Themes$encodeEndpoints(config.kK)),
				_Utils_Tuple2(
				'extent',
				$author$project$PlugMap$Plugins$Themes$encodeExtent(config.e3))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeWMSEndpoint = function (endpoint) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(endpoint.c8)),
				_Utils_Tuple2(
				'tokenKey',
				$elm$json$Json$Encode$string(endpoint.bM)),
				_Utils_Tuple2(
				'layers',
				$elm$json$Json$Encode$list($elm$json$Json$Encode$string)(endpoint.hE)),
				_Utils_Tuple2(
				'propertyNames',
				$elm$json$Json$Encode$list($elm$json$Json$Encode$string)(endpoint.ad)),
				_Utils_Tuple2(
				'nameField',
				$elm$json$Json$Encode$string(endpoint.fv)),
				_Utils_Tuple2(
				'idField',
				$elm$json$Json$Encode$string(endpoint.d_))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeWMSEndpoints = function (endpoints) {
	return A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Themes$encodeWMSEndpoint, endpoints);
};
var $author$project$PlugMap$Plugins$Themes$encodeWMSLayer = function (config) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'endpoints',
				$author$project$PlugMap$Plugins$Themes$encodeWMSEndpoints(config.kK)),
				_Utils_Tuple2(
				'extent',
				$author$project$PlugMap$Plugins$Themes$encodeExtent(config.e3))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeWMTSLayer = function (config) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'endpoints',
				$author$project$PlugMap$Plugins$Themes$encodeEndpoints(config.kK)),
				_Utils_Tuple2(
				'extent',
				$author$project$PlugMap$Plugins$Themes$encodeExtent(config.e3))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeXYZLayer = function (config) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'endpoints',
				$author$project$PlugMap$Plugins$Themes$encodeEndpoints(config.kK)),
				_Utils_Tuple2(
				'maxZoom',
				$elm$json$Json$Encode$int(config.hQ)),
				_Utils_Tuple2(
				'minZoom',
				$elm$json$Json$Encode$int(config.hS))
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeLayerConfig = function (config_) {
	var _v0 = function () {
		switch (config_.$) {
			case 0:
				var config = config_.a;
				return _Utils_Tuple2(
					'xyz',
					$author$project$PlugMap$Plugins$Themes$encodeXYZLayer(config));
			case 1:
				var config = config_.a;
				return _Utils_Tuple2(
					'wmts',
					$author$project$PlugMap$Plugins$Themes$encodeWMTSLayer(config));
			case 2:
				var config = config_.a;
				return _Utils_Tuple2(
					'wms',
					$author$project$PlugMap$Plugins$Themes$encodeWMSLayer(config));
			default:
				var config = config_.a;
				return _Utils_Tuple2(
					'esriExport',
					$author$project$PlugMap$Plugins$Themes$encodeEsriExportLayer(config));
		}
	}();
	var type_ = _v0.a;
	var encoder = _v0.b;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string(type_)),
				_Utils_Tuple2('value', encoder)
			]));
};
var $author$project$PlugMap$Plugins$Themes$encodeLayer = function (layer) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'key',
				$author$project$PlugMap$Plugins$Themes$encodeLayerKey(layer.lw)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(layer.fu)),
				_Utils_Tuple2(
				'opacity',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$float, layer.h3)),
				_Utils_Tuple2(
				'zIndex',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$float, layer.df)),
				_Utils_Tuple2(
				'config',
				$author$project$PlugMap$Plugins$Themes$encodeLayerConfig(layer.bn)),
				_Utils_Tuple2(
				'identify',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$PlugMap$Plugins$Themes$encodeIdentify, layer.lc)))
			]));
};
var $author$project$PlugMap$Plugins$Themes$getLayerByKey = F2(
	function (repo, _v0) {
		var key = _v0;
		return A2($elm$core$Dict$get, key, repo);
	});
var $author$project$PlugMap$Plugins$Themes$encodeCategory = F2(
	function (model, category) {
		var layers = A2(
			$elm$core$List$filterMap,
			$author$project$PlugMap$Plugins$Themes$getLayerByKey(model.lB),
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.hE;
				},
				A2(
					$elm$core$List$filterMap,
					$author$project$PlugMap$Plugins$Themes$getGroupByKey(model.lz),
					category.d5)));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'category_key',
					$author$project$PlugMap$Plugins$Themes$encodeCategoryKey(category.lw)),
					_Utils_Tuple2(
					'opacity',
					$elm$json$Json$Encode$float(category.i8)),
					_Utils_Tuple2(
					'layers',
					A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Themes$encodeLayer, layers)),
					_Utils_Tuple2(
					'selection',
					$author$project$PlugMap$Plugins$Themes$encodeSelection(category.m0))
				]));
	});
var $author$project$PlugMap$Plugins$Themes$encodeThemes = function (model) {
	return A2(
		$elm$json$Json$Encode$list,
		$author$project$PlugMap$Plugins$Themes$encodeCategory(model),
		model.ly);
};
var $author$project$PlugMap$Plugins$Themes$Model = F3(
	function (layerRepo, layerGroupRepo, layerCategories) {
		return {ly: layerCategories, lz: layerGroupRepo, lB: layerRepo};
	});
var $author$project$PlugMap$Plugins$Themes$LayerCategory = function (key) {
	return function (name) {
		return function (selection) {
			return function (multiphasic) {
				return function (transparency) {
					return function (openness) {
						return function (usesRasterLegend) {
							return function (activeIcon) {
								return function (infoIcon) {
									return function (layerGroups) {
										return {jA: activeIcon, li: infoIcon, lw: key, d5: layerGroups, lT: multiphasic, fu: name, bD: openness, m0: selection, i8: transparency, jg: usesRasterLegend};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$PlugMap$Plugins$Themes$categoryKeyDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $elm$core$Basics$identity),
	$elm$json$Json$Decode$string);
var $author$project$PlugMap$Plugins$Themes$GroupKey = $elm$core$Basics$identity;
var $author$project$PlugMap$Plugins$Themes$groupKeyDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $elm$core$Basics$identity),
	$elm$json$Json$Decode$string);
var $author$project$PlugMap$Plugins$Themes$Icon = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$Themes$Img = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Themes$NoImage = {$: 0};
var $author$project$PlugMap$Plugins$Themes$linkImageDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'iconImg',
			A2($elm$json$Json$Decode$map, $author$project$PlugMap$Plugins$Themes$Img, $elm$json$Json$Decode$string)),
			A2(
			$elm$json$Json$Decode$field,
			'iconClass',
			A2($elm$json$Json$Decode$map, $author$project$PlugMap$Plugins$Themes$Icon, $elm$json$Json$Decode$string)),
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$NoImage)
		]));
var $author$project$PlugMap$Plugins$Themes$Closed = 1;
var $author$project$PlugMap$Plugins$Themes$Open = 0;
var $author$project$PlugMap$Plugins$Themes$opennessDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (openness) {
		switch (openness) {
			case 'open':
				return $elm$json$Json$Decode$succeed(0);
			case 'closed':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('PlugMap.Plugins.Themes.opennessDecoder :: invalid configuration value. Must be either \'open\' or \'closed\'.');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$PlugMap$Plugins$Themes$EnforcedMonoselection = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$Themes$EnforcedPolyselection = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$PlugMap$Plugins$Themes$Monoselection = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Themes$Polyselection = function (a) {
	return {$: 1, a: a};
};
var $elm_community$json_extra$Json$Decode$Extra$fromResult = function (result) {
	if (!result.$) {
		var successValue = result.a;
		return $elm$json$Json$Decode$succeed(successValue);
	} else {
		var errorMessage = result.a;
		return $elm$json$Json$Decode$fail(errorMessage);
	}
};
var $author$project$PlugMap$Plugins$Themes$layerKeyDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $elm$core$Basics$identity),
	$elm$json$Json$Decode$string);
var $author$project$PlugMap$Plugins$Themes$Monoselective = 0;
var $author$project$PlugMap$Plugins$Themes$Polyselective = 1;
var $author$project$PlugMap$Plugins$Themes$selectivenessDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (selectiveness) {
		switch (selectiveness) {
			case 'monoselective':
				return $elm$json$Json$Decode$succeed(0);
			case 'polyselective':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('PlugMap.Plugins.Themes.selectivenessDecoder :: invalid configuration value. Must be either \'monoselective\' or \'polyselective\'.');
		}
	},
	$elm$json$Json$Decode$string);
var $elm_community$json_extra$Json$Decode$Extra$withDefault = F2(
	function (fallback, decoder) {
		return A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(fallback),
			$elm$json$Json$Decode$maybe(decoder));
	});
var $author$project$PlugMap$Plugins$Themes$selectionDecoder = function () {
	var toSelection = F3(
		function (selectiveness, mustHave, defaults) {
			var errMsg = 'PlugMap.Plugins.Themes.selectionDecoder :: invalid configuration value. If a Layer Category has \'mustHaveSelection=true\' then it must also have a valid \'defaultSelection\' set.';
			var _v0 = _Utils_Tuple2(selectiveness, mustHave);
			if (!_v0.a) {
				if (!_v0.b) {
					var _v1 = _v0.a;
					if (!defaults.b) {
						return $elm$core$Result$Ok(
							$author$project$PlugMap$Plugins$Themes$Monoselection($elm$core$Maybe$Nothing));
					} else {
						var head = defaults.a;
						return $elm$core$Result$Ok(
							$author$project$PlugMap$Plugins$Themes$Monoselection(
								$elm$core$Maybe$Just(head)));
					}
				} else {
					var _v4 = _v0.a;
					if (!defaults.b) {
						return $elm$core$Result$Err(errMsg);
					} else {
						var head = defaults.a;
						return $elm$core$Result$Ok(
							$author$project$PlugMap$Plugins$Themes$EnforcedMonoselection(head));
					}
				}
			} else {
				if (!_v0.b) {
					var _v3 = _v0.a;
					return $elm$core$Result$Ok(
						$author$project$PlugMap$Plugins$Themes$Polyselection(defaults));
				} else {
					var _v6 = _v0.a;
					if (!defaults.b) {
						return $elm$core$Result$Err(errMsg);
					} else {
						var head = defaults.a;
						var tail = defaults.b;
						return $elm$core$Result$Ok(
							A2($author$project$PlugMap$Plugins$Themes$EnforcedPolyselection, head, tail));
					}
				}
			}
		});
	return A2(
		$elm$json$Json$Decode$andThen,
		$elm_community$json_extra$Json$Decode$Extra$fromResult,
		A4(
			$elm$json$Json$Decode$map3,
			toSelection,
			A2($elm$json$Json$Decode$field, 'selectiveness', $author$project$PlugMap$Plugins$Themes$selectivenessDecoder),
			A2($elm$json$Json$Decode$field, 'mustHaveSelection', $elm$json$Json$Decode$bool),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$withDefault,
				_List_Nil,
				A2(
					$elm$json$Json$Decode$field,
					'defaultSelection',
					$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$layerKeyDecoder)))));
}();
var $author$project$PlugMap$Plugins$Themes$transparencyDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2(
		$elm$core$Basics$composeL,
		$elm$json$Json$Decode$succeed,
		A2($elm$core$Basics$clamp, 0.0, 1.0)),
	$elm$json$Json$Decode$float);
var $author$project$PlugMap$Plugins$Themes$layerCategoryDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'layerGroups',
	$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$groupKeyDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'infoIcon',
		$author$project$PlugMap$Plugins$Themes$linkImageDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'activeIcon',
			$author$project$PlugMap$Plugins$Themes$linkImageDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'usesRasterLegend',
				$elm$json$Json$Decode$bool,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'openness',
					$author$project$PlugMap$Plugins$Themes$opennessDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'transparency',
						$author$project$PlugMap$Plugins$Themes$transparencyDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'multiphasic',
							$elm$json$Json$Decode$bool,
							A2(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
								$author$project$PlugMap$Plugins$Themes$selectionDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'name',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'key',
										$author$project$PlugMap$Plugins$Themes$categoryKeyDecoder,
										$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$LayerCategory)))))))))));
var $author$project$PlugMap$Plugins$Themes$layerCategoriesDecoder = $elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$layerCategoryDecoder);
var $author$project$PlugMap$Plugins$Themes$LayerGroup = F4(
	function (key, name, openness, layers) {
		return {lw: key, hE: layers, fu: name, bD: openness};
	});
var $author$project$PlugMap$Plugins$Themes$groupKeyToString = function (_v0) {
	var key = _v0;
	return key;
};
var $author$project$PlugMap$Plugins$Themes$layerGroupDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (lg) {
		return $elm$json$Json$Decode$succeed(
			_Utils_Tuple2(
				$author$project$PlugMap$Plugins$Themes$groupKeyToString(lg.lw),
				lg));
	},
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'layers',
		$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$layerKeyDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'openness',
			$author$project$PlugMap$Plugins$Themes$opennessDecoder,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'name',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'key',
					$author$project$PlugMap$Plugins$Themes$groupKeyDecoder,
					$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$LayerGroup))))));
var $author$project$PlugMap$Plugins$Themes$layerGroupRepoDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $elm$core$Dict$fromList),
	$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$layerGroupDecoder));
var $author$project$PlugMap$Plugins$Themes$Layer = F7(
	function (key, name, opacity, zIndex, config, legend, identify) {
		return {bn: config, lc: identify, lw: key, d6: legend, fu: name, h3: opacity, df: zIndex};
	});
var $author$project$PlugMap$Plugins$Themes$EsriExportLayer = function (a) {
	return {$: 3, a: a};
};
var $author$project$PlugMap$Plugins$Themes$EsriExportConfig = F2(
	function (endpoints, extent) {
		return {kK: endpoints, e3: extent};
	});
var $author$project$PlugMap$Plugins$Themes$Multiple = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Themes$Endpoint = F6(
	function (url, zIndex, tokenKey, layersToShow, layerDefs, bbox) {
		return {go: bbox, hD: layerDefs, hF: layersToShow, bM: tokenKey, c8: url, df: zIndex};
	});
var $author$project$PlugMap$Plugins$Themes$endpointDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'bbox',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'layerDefs',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'layersToShow',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'tokenKey',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'z-index',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'url',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$Endpoint)))))));
var $author$project$PlugMap$Plugins$Themes$endpointsDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$endpointDecoder),
	$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$Multiple));
var $author$project$PlugMap$Plugins$Themes$extentDecoder = $elm$json$Json$Decode$list($elm$json$Json$Decode$float);
var $author$project$PlugMap$Plugins$Themes$esriExportConfigDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'extent',
	$author$project$PlugMap$Plugins$Themes$extentDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'endpoints',
		$author$project$PlugMap$Plugins$Themes$endpointsDecoder,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$EsriExportConfig)));
var $author$project$PlugMap$Plugins$Themes$esriExportLayerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'esriExport',
	$author$project$PlugMap$Plugins$Themes$esriExportConfigDecoder,
	$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$EsriExportLayer));
var $author$project$PlugMap$Plugins$Themes$WMSLayer = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$Themes$WMSConfig = F2(
	function (endpoints, extent) {
		return {kK: endpoints, e3: extent};
	});
var $author$project$PlugMap$Plugins$Themes$WMSEndpoint = F7(
	function (url, zIndex, tokenKey, layers, propertyNames, nameField, idField) {
		return {d_: idField, hE: layers, fv: nameField, ad: propertyNames, bM: tokenKey, c8: url, df: zIndex};
	});
var $author$project$PlugMap$Plugins$Themes$wmsEndpointDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'idField',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'nameField',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'propertyNames',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'layers',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'tokenKey',
					$elm$json$Json$Decode$string,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'z-index',
						$elm$json$Json$Decode$int,
						0,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'url',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$WMSEndpoint))))))));
var $author$project$PlugMap$Plugins$Themes$wmsEndpointsDecoder = $elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$wmsEndpointDecoder);
var $author$project$PlugMap$Plugins$Themes$wmsConfigDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'extent',
	$author$project$PlugMap$Plugins$Themes$extentDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'endpoints',
		$author$project$PlugMap$Plugins$Themes$wmsEndpointsDecoder,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$WMSConfig)));
var $author$project$PlugMap$Plugins$Themes$wmsLayerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'wms',
	$author$project$PlugMap$Plugins$Themes$wmsConfigDecoder,
	$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$WMSLayer));
var $author$project$PlugMap$Plugins$Themes$WMTSLayer = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Themes$WMTSConfig = F2(
	function (endpoints, extent) {
		return {kK: endpoints, e3: extent};
	});
var $author$project$PlugMap$Plugins$Themes$wmtsConfigDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'extent',
	$author$project$PlugMap$Plugins$Themes$extentDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'endpoints',
		$author$project$PlugMap$Plugins$Themes$endpointsDecoder,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$WMTSConfig)));
var $author$project$PlugMap$Plugins$Themes$wmtsLayerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'wmts',
	$author$project$PlugMap$Plugins$Themes$wmtsConfigDecoder,
	$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$WMTSLayer));
var $author$project$PlugMap$Plugins$Themes$XYZLayer = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Themes$XYZConfig = F3(
	function (endpoints, maxZoom, minZoom) {
		return {kK: endpoints, hQ: maxZoom, hS: minZoom};
	});
var $author$project$PlugMap$Plugins$Themes$xyzConfigDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'minZoom',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'maxZoom',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'endpoints',
			$author$project$PlugMap$Plugins$Themes$endpointsDecoder,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$XYZConfig))));
var $author$project$PlugMap$Plugins$Themes$xyzLayerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'xyz',
	$author$project$PlugMap$Plugins$Themes$xyzConfigDecoder,
	$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$XYZLayer));
var $author$project$PlugMap$Plugins$Themes$configDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$PlugMap$Plugins$Themes$xyzLayerDecoder, $author$project$PlugMap$Plugins$Themes$wmtsLayerDecoder, $author$project$PlugMap$Plugins$Themes$wmsLayerDecoder, $author$project$PlugMap$Plugins$Themes$esriExportLayerDecoder]));
var $author$project$PlugMap$Plugins$Themes$Identify = F7(
	function (enabled, dataMapping, canViewDetails, idTransform, typeKey, queryLayerIds, tolerance) {
		return {gz: canViewDetails, gT: dataMapping, kJ: enabled, ho: idTransform, io: queryLayerIds, i1: tolerance, ja: typeKey};
	});
var $author$project$PlugMap$Plugins$Themes$IdentifyDataMapping = F2(
	function (title, fields) {
		return {u: fields, F: title};
	});
var $author$project$PlugMap$Plugins$Themes$IdentifyField = F2(
	function (name, fieldType) {
		return {hb: fieldType, fu: name};
	});
var $author$project$PlugMap$Plugins$Themes$Map = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$Themes$Query = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$Themes$Static = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Themes$ValueMapperParam = F2(
	function (query, values) {
		return {$9: query, jh: values};
	});
var $author$project$PlugMap$Plugins$Themes$mapDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'values',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'query',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$ValueMapperParam)));
var $author$project$PlugMap$Plugins$Themes$identifyFieldDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'query',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$Query)),
				A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'static',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$Static)),
				A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'map',
				$author$project$PlugMap$Plugins$Themes$mapDecoder,
				$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$Map))
			])),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$IdentifyField)));
var $author$project$PlugMap$Plugins$Themes$identifyDataMappingDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'fields',
	$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$identifyFieldDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'title',
		$author$project$PlugMap$Plugins$Themes$identifyFieldDecoder,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$IdentifyDataMapping)));
var $author$project$PlugMap$Plugins$Themes$identifyDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tolerance',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'queryLayerIds',
		$elm$json$Json$Decode$maybe(
			$elm$json$Json$Decode$list($elm$json$Json$Decode$int)),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'typeKey',
			$elm$json$Json$Decode$string,
			'Unknown',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'idTransform',
				$elm$json$Json$Decode$int,
				0,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'canViewDetails',
					$elm$json$Json$Decode$bool,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'dataMapping',
						$author$project$PlugMap$Plugins$Themes$identifyDataMappingDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'enabled',
							$elm$json$Json$Decode$bool,
							$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$Identify))))))));
var $author$project$PlugMap$Plugins$Themes$layerKeyToString = function (_v0) {
	var key = _v0;
	return key;
};
var $author$project$PlugMap$Plugins$Themes$LegendEntry = F3(
	function (name, color, fontColor) {
		return {G: color, kY: fontColor, fu: name};
	});
var $author$project$PlugMap$Plugins$Themes$colorDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'b',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'g',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'r',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($avh4$elm_color$Color$rgb255))));
var $author$project$PlugMap$Plugins$Themes$darkFont = A3($avh4$elm_color$Color$rgb255, 36, 36, 36);
var $avh4$elm_color$Color$white = A4($avh4$elm_color$Color$RgbaSpace, 255 / 255, 255 / 255, 255 / 255, 1.0);
var $author$project$PlugMap$Plugins$Themes$lightFont = $avh4$elm_color$Color$white;
var $elm$core$Basics$pow = _Basics_pow;
var $noahzgordon$elm_color_extra$Color$Accessibility$luminance = function (cl) {
	var f = function (intensity) {
		return (intensity <= 0.03928) ? (intensity / 12.92) : A2($elm$core$Basics$pow, (intensity + 0.055) / 1.055, 2.4);
	};
	var _v0 = function (a) {
		return _Utils_Tuple3(
			f(a.ae),
			f(a.aa),
			f(a.Y));
	}(
		$avh4$elm_color$Color$toRgba(cl));
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	return ((0.2126 * r) + (0.7152 * g)) + (0.0722 * b);
};
var $noahzgordon$elm_color_extra$Color$Accessibility$contrastRatio = F2(
	function (c1, c2) {
		var b = $noahzgordon$elm_color_extra$Color$Accessibility$luminance(c2) + 0.05;
		var a = $noahzgordon$elm_color_extra$Color$Accessibility$luminance(c1) + 0.05;
		return (_Utils_cmp(a, b) > 0) ? (a / b) : (b / a);
	});
var $elm$core$List$sortWith = _List_sortWith;
var $noahzgordon$elm_color_extra$Color$Accessibility$maximumContrast = F2(
	function (base, options) {
		var compareContrast = F2(
			function (c1, c2) {
				return A2(
					$elm$core$Basics$compare,
					A2($noahzgordon$elm_color_extra$Color$Accessibility$contrastRatio, base, c2),
					A2($noahzgordon$elm_color_extra$Color$Accessibility$contrastRatio, base, c1));
			});
		return $elm$core$List$head(
			A2($elm$core$List$sortWith, compareContrast, options));
	});
var $author$project$PlugMap$Plugins$Themes$fontColorDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (c) {
		return $elm$json$Json$Decode$succeed(
			A2(
				$elm$core$Maybe$withDefault,
				$author$project$PlugMap$Plugins$Themes$darkFont,
				A2(
					$noahzgordon$elm_color_extra$Color$Accessibility$maximumContrast,
					c,
					_List_fromArray(
						[$author$project$PlugMap$Plugins$Themes$lightFont, $author$project$PlugMap$Plugins$Themes$darkFont]))));
	},
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['color']),
		$author$project$PlugMap$Plugins$Themes$colorDecoder));
var $author$project$PlugMap$Plugins$Themes$legendEntryDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$PlugMap$Plugins$Themes$fontColorDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'color',
		$author$project$PlugMap$Plugins$Themes$colorDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'label',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$LegendEntry))));
var $author$project$PlugMap$Plugins$Themes$legendDecoder = $elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$legendEntryDecoder);
var $author$project$PlugMap$Plugins$Themes$layerDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (ly) {
		return $elm$json$Json$Decode$succeed(
			_Utils_Tuple2(
				$author$project$PlugMap$Plugins$Themes$layerKeyToString(ly.lw),
				ly));
	},
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'identify',
		$elm$json$Json$Decode$maybe($author$project$PlugMap$Plugins$Themes$identifyDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'legend',
			$elm$json$Json$Decode$maybe($author$project$PlugMap$Plugins$Themes$legendDecoder),
			$elm$core$Maybe$Nothing,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
				$author$project$PlugMap$Plugins$Themes$configDecoder,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'zIndex',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'opacity',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
						$elm$core$Maybe$Nothing,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'name',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'key',
								$author$project$PlugMap$Plugins$Themes$layerKeyDecoder,
								$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$Layer)))))))));
var $author$project$PlugMap$Plugins$Themes$layerRepoDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $elm$core$Dict$fromList),
	$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$Themes$layerDecoder));
var $author$project$PlugMap$Plugins$Themes$themesDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'layerCategories',
	$author$project$PlugMap$Plugins$Themes$layerCategoriesDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'layerGroups',
		$author$project$PlugMap$Plugins$Themes$layerGroupRepoDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'layers',
			$author$project$PlugMap$Plugins$Themes$layerRepoDecoder,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$Themes$Model))));
var $author$project$PlugMap$Plugins$Themes$init = function (flags) {
	var _v0 = A2(
		$elm$json$Json$Decode$decodeValue,
		A2($elm$json$Json$Decode$field, 'mapConfig', $author$project$PlugMap$Plugins$Themes$themesDecoder),
		flags);
	if (!_v0.$) {
		var model = _v0.a;
		return _Utils_Tuple2(
			$elm$core$Result$Ok(model),
			$author$project$PlugMap$Plugins$Themes$addThemesCmd(
				$author$project$PlugMap$Plugins$Themes$encodeThemes(model)));
	} else {
		var err = _v0.a;
		return _Utils_Tuple2(
			$elm$core$Result$Err(
				'PlugMap.Plugins.Themes.init :: ' + $elm$json$Json$Decode$errorToString(err)),
			$elm$core$Platform$Cmd$none);
	}
};
var $author$project$PAM$Map$noop = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$PAM$Map$addDrawingPlugin = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$PAM$Map$initLayerDefinitionCmd = _Platform_outgoingPort('initLayerDefinitionCmd', $elm$json$Json$Encode$string);
var $author$project$PAM$MapServices$encodeBaseConfig = function (base) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'baseUrl',
			$elm$json$Json$Encode$string(base.gn)),
			_Utils_Tuple2(
			'key',
			$elm$json$Json$Encode$string(base.lw))
		]);
};
var $author$project$PAM$MapServices$encodeInsecured = function (base) {
	return $elm$json$Json$Encode$object(
		$author$project$PAM$MapServices$encodeBaseConfig(base));
};
var $author$project$PAM$MapServices$encodeToken = function (token) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'token',
			$elm$json$Json$Encode$string(token.nI)),
			_Utils_Tuple2(
			'expires',
			$elm$json$Json$Encode$string(token.g8))
		]);
};
var $author$project$PAM$MapServices$encodeSecured = F2(
	function (base, token) {
		return $elm$json$Json$Encode$object(
			_Utils_ap(
				$author$project$PAM$MapServices$encodeBaseConfig(base),
				$author$project$PAM$MapServices$encodeToken(token)));
	});
var $author$project$PAM$MapServices$encodeMapService = function (svc) {
	if (!svc.$) {
		var base = svc.a;
		return $author$project$PAM$MapServices$encodeInsecured(base);
	} else {
		var base = svc.a;
		var tok = svc.b;
		return A2($author$project$PAM$MapServices$encodeSecured, base, tok);
	}
};
var $author$project$PlugMap$Core$Ports$setServicesCmd = _Platform_outgoingPort('setServicesCmd', $elm$core$Basics$identity);
var $author$project$PlugMap$Core$setServices = F2(
	function (model, services) {
		var encodedServices = A2($elm$json$Json$Encode$list, $author$project$PAM$MapServices$encodeMapService, services);
		return _Utils_Tuple2(
			model,
			$author$project$PlugMap$Core$Ports$setServicesCmd(encodedServices));
	});
var $author$project$PAM$Map$setServices = F2(
	function (model, services) {
		var mappedServices = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (service) {
					if (!service.$) {
						var base = service.a;
						return _Utils_Tuple2(base.lw, service);
					} else {
						var base = service.a;
						return _Utils_Tuple2(base.lw, service);
					}
				},
				services));
		var _v0 = A2($author$project$PlugMap$Core$setServices, model.h, services);
		var newMap = _v0.a;
		var newMapCmd = _v0.b;
		var newModel = _Utils_update(
			model,
			{h: newMap, ez: mappedServices});
		var _v1 = $author$project$PAM$Map$addDrawingPlugin(newModel);
		var newNewNewModel = _v1.a;
		var addlalldCmds = _v1.b;
		return _Utils_Tuple2(
			newNewNewModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						addlalldCmds,
						A2(
						$elm$core$Platform$Cmd$map,
						$author$project$PAM$Map$Internal,
						A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$MapMessage, newMapCmd)),
						$author$project$PAM$Map$initLayerDefinitionCmd('init')
					])));
	});
var $author$project$PlugMap$Core$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var newExtent = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dK: newExtent}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PlugMap$Plugins$Identify$update = F2(
	function (model, msg) {
		if (!msg.$) {
			var results = msg.a;
			return ($elm$core$List$length(results.iy) > 0) ? _Utils_Tuple2(
				_Utils_update(
					model,
					{
						eT: $elm$core$Maybe$Just(results),
						fN: $elm$core$Maybe$Just(0),
						fR: true
					}),
				$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PlugMap$Plugins$Drawing$update = F2(
	function (model, msg) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
	});
var $author$project$PAM$Map$updateDrawNoOut = F2(
	function (model, _v0) {
		var nd = _v0.a;
		var nc = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{at: nd}),
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$PAM$Map$Internal,
				A2(
					$elm$core$Platform$Cmd$map,
					$author$project$PAM$Map$PluginMessage,
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$Msg_Drawing, nc))));
	});
var $author$project$PAM$Map$updateDraw = F2(
	function (model, _v0) {
		var nd = _v0.a;
		var nc = _v0.b;
		var omnom = _v0.c;
		return A2(
			$author$project$PAM$Map$updateDrawNoOut,
			model,
			_Utils_Tuple2(nd, nc));
	});
var $author$project$PAM$Map$updatePlugin = F2(
	function (model, sub) {
		if (sub.$ === 1) {
			var msg = sub.a;
			return A2(
				$author$project$PAM$Map$updateDraw,
				model,
				A2($author$project$PlugMap$Plugins$Drawing$update, model.at, msg));
		} else {
			return $author$project$PAM$Map$noop(model);
		}
	});
var $author$project$PAM$Map$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 6:
				var services = msg.a;
				return A2($author$project$PAM$Map$setServices, model, services);
			case 5:
				var sub = msg.a;
				return A2($author$project$PAM$Map$updatePlugin, model, sub);
			case 7:
				return $author$project$PAM$Map$handleDrawingToggle(model);
			case 8:
				return $author$project$PAM$Map$handleUploadToggle(model);
			case 9:
				return $author$project$PAM$Map$handleFeaturePickerToggle(model);
			case 1:
				var flags = msg.a;
				var _v1 = $author$project$PlugMap$Plugins$Themes$init(flags);
				var themesModel = _v1.a;
				var themesCmd = _v1.b;
				if (!themesModel.$) {
					var themes = themesModel.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{s: themes}),
						A2(
							$elm$core$Platform$Cmd$map,
							function (a) {
								return $author$project$PAM$Map$Internal(
									$author$project$PAM$Map$PluginMessage(
										$author$project$PAM$Map$Msg_Themes(a)));
							},
							themesCmd));
				} else {
					return $author$project$PAM$Map$noop(model);
				}
			case 10:
				var item = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cv: item}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var item = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cv: ''}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var color = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cu: color}),
					$elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cu: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var wkt = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$PlugMap$Core$Ports$fitToWktCmd(
						$elm$json$Json$Encode$string(wkt)));
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c$: $author$project$PAM$UI$Basic$flipOpenness(model.c$)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var m = msg.a;
				var _v3 = A2($author$project$PlugMap$Plugins$Identify$update, model.aO, m);
				var im = _v3.a;
				var ic = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aO: im}),
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$identifyTranslator, ic));
			case 2:
				if (msg.a.$ === 1) {
					var _v4 = msg.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$core$Task$perform,
							$elm$core$Basics$always(
								$author$project$PAM$Map$Internal($author$project$PAM$Map$ClearColor)),
							$elm$core$Process$sleep(200)));
				} else {
					var sub = msg.a;
					var _v5 = A2($author$project$PlugMap$Core$update, sub, model.h);
					var newMap = _v5.a;
					var newMapCmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{h: newMap}),
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$PAM$Map$Internal,
							A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$MapMessage, newMapCmd)));
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$handleMapMsg = F2(
	function (model, subMsg) {
		var _v0 = A2($author$project$PAM$Map$update, model.h, subMsg);
		var newMap = _v0.a;
		var newCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{h: newMap}),
			A2($elm$core$Platform$Cmd$map, $author$project$PAM$mapTranslater, newCmd));
	});
var $author$project$PAM$Message$RefreshMap = {$: 6};
var $author$project$PAM$refreshMap = function (_v0) {
	var model = _v0.a;
	var cmds = _v0.b;
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					cmds,
					A2(
					$elm$core$Task$perform,
					$elm$core$Basics$always($author$project$PAM$Message$RefreshMap),
					$elm$core$Process$sleep(100))
				])));
};
var $author$project$PAM$handleNewLinkedObjectSavedResponse = F3(
	function (_v0, response, model) {
		var parentObjTypeKey = _v0.a;
		var parentObjId = _v0.b;
		var parentObj = A2($author$project$PAM$Object$Repo$getObject, parentObjId, model.b);
		var objModels = model.a.l;
		var _v1 = _Utils_Tuple2(response, parentObj);
		if (!_v1.a.$) {
			if (!_v1.b.$) {
				var savedChild = _v1.a.a;
				var parent = _v1.b.a;
				var _v2 = A3($author$project$PAM$Object$linkObjects, savedChild, parent, objModels);
				if (!_v2.$) {
					var updatedParent = _v2.a;
					var newRepo = A2(
						$author$project$PAM$Object$Repo$changeObject,
						updatedParent,
						$author$project$PAM$Object$Repo$clearNewObject(
							A2($author$project$PAM$Object$Repo$insertObject, savedChild, model.b)));
					return $author$project$PAM$refreshMap(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{b: newRepo}),
							A2(
								$author$project$PAM$Route$pushUrl,
								model.lw,
								A4($author$project$PAM$Route$initEditObjectDetails, parentObjTypeKey, parentObjId, $elm$core$Maybe$Nothing, model.mS))));
				} else {
					var error = _v2.a;
					var newRepo = $author$project$PAM$Object$Repo$clearNewObject(
						A2($author$project$PAM$Object$Repo$insertObject, savedChild, model.b));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: newRepo}),
						$author$project$Ports$logErrorCmd(
							$elm$json$Json$Encode$string('PAM.handleNewLinkedObjectSavedResponse :: ' + error)));
				}
			} else {
				var _v3 = _v1.b;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$logErrorCmd(
						$elm$json$Json$Encode$string('PAM.handleNewLinkedObjectSavedResponse :: ' + 'Could not find parent object in repo')));
			}
		} else {
			var error = _v1.a.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleNewLinkedObjectSavedResponse', error))));
		}
	});
var $author$project$PAM$handleNewObjectSavedResponse = F2(
	function (response, model) {
		if (!response.$) {
			var savedObject = response.a;
			var newRepo = $author$project$PAM$Object$Repo$clearNewObject(
				A2($author$project$PAM$Object$Repo$insertObject, savedObject, model.b));
			return $author$project$PAM$refreshMap(
				_Utils_Tuple2(
					_Utils_update(
						model,
						{b: newRepo}),
					A2(
						$author$project$PAM$Route$pushUrl,
						model.lw,
						A4($author$project$PAM$Route$initShowObjectDetails, savedObject.bi.lw, savedObject.r, $elm$core$Maybe$Nothing, model.mS))));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$Object$Repo$deleteObject = F2(
	function (id, _v0) {
		var repo = _v0.a;
		var backup = _v0.b;
		return $elm$core$Dict$isEmpty(backup) ? A2(
			$author$project$PAM$Object$Repo$Repo,
			A2($elm$core$Dict$remove, id, repo),
			backup) : A2($author$project$PAM$Object$Repo$Repo, repo, backup);
	});
var $author$project$PAM$handleObjectDeleted = F3(
	function (id, response, model) {
		if (!response.$) {
			if (response.a) {
				return $author$project$PAM$refreshMap(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{
								b: A2($author$project$PAM$Object$Repo$deleteObject, id, model.b)
							}),
						function () {
							var _v1 = model.mS;
							if ((!_v1.$) && (_v1.a.$ === 4)) {
								var _v2 = _v1.a;
								var oId = _v2.b;
								var params = _v2.c;
								return _Utils_eq(oId, id) ? A2(
									$author$project$PAM$Route$pushUrl,
									model.lw,
									$author$project$PAM$Route$Map(params)) : $elm$core$Platform$Cmd$none;
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}()));
			} else {
				return _Utils_Tuple2(
					model,
					$author$project$Ports$logErrorCmd(
						$elm$json$Json$Encode$string('PAM.handleObjectDeleted :: Could not delete object')));
			}
		} else {
			var error = response.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleObjectDeleted', error))));
		}
	});
var $author$project$PAM$Message$StartEditing = {$: 57};
var $author$project$PAM$Message$StopEditing = function (a) {
	return {$: 58, a: a};
};
var $author$project$PAM$Object$Repo$contains = F2(
	function (id, _v0) {
		var repo = _v0.a;
		var object = A2($elm$core$Dict$get, id, repo);
		if (!object.$) {
			var obj = object.a;
			return _Utils_eq(obj.r, id) ? true : false;
		} else {
			return false;
		}
	});
var $author$project$Fields$Shared$Getter_Date = function (a) {
	return {$: 2, a: a};
};
var $author$project$Fields$Shared$Getter_DateRange = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$Shared$Getter_Geometry = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$Shared$Getter_SingleChoiceWithOther = function (a) {
	return {$: 3, a: a};
};
var $author$project$Fields$Shared$NoneGetter = {$: 5};
var $author$project$Fields$GenericField$Sans_Date = {$: 2};
var $author$project$Fields$GenericField$Sans_DateRange = {$: 1};
var $author$project$Fields$GenericField$Sans_Geometry = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$GenericField$Sans_SingleChoiceWithOther = {$: 3};
var $author$project$Fields$GenericField$Sans_Unknown = {$: 4};
var $author$project$Fields$GeometryField$newFieldConfig = F3(
	function (services, featurePickerLayers, decimalPlaces) {
		return {cG: decimalPlaces, cW: featurePickerLayers, ez: services};
	});
var $author$project$PAM$EditableFields$fieldTypeToGenericFieldType = F4(
	function (type_, services, featurePickerLayers, decimalPlaces) {
		switch (type_) {
			case 13:
				return $author$project$Fields$GenericField$Sans_Geometry(
					A3($author$project$Fields$GeometryField$newFieldConfig, services, featurePickerLayers, decimalPlaces));
			case 7:
				return $author$project$Fields$GenericField$Sans_DateRange;
			case 6:
				return $author$project$Fields$GenericField$Sans_Date;
			case 18:
				return $author$project$Fields$GenericField$Sans_SingleChoiceWithOther;
			default:
				return $author$project$Fields$GenericField$Sans_Unknown;
		}
	});
var $author$project$Fields$GenericField$DateModel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$GenericField$DateRangeModel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$GenericField$GeometryModel = function (a) {
	return {$: 3, a: a};
};
var $author$project$Fields$GenericField$SingleChoiceWithOtherModel = function (a) {
	return {$: 2, a: a};
};
var $author$project$Fields$GenericField$Generic_NoOp = {$: 4};
var $author$project$Fields$DateField$I_NoOp = {$: 0};
var $author$project$Fields$DateField$initTranslator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			return td.el($author$project$Fields$DateField$I_NoOp);
		}
	});
var $author$project$Fields$GenericField$dateInitTranslator = $author$project$Fields$DateField$initTranslator(
	{
		el: function (a) {
			return $author$project$Fields$GenericField$Internal(
				$author$project$Fields$GenericField$DateMsg(a));
		},
		jc: function (a) {
			return $author$project$Fields$GenericField$Internal($author$project$Fields$GenericField$Generic_NoOp);
		}
	});
var $author$project$Fields$GenericField$DateRangeMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$DateRangeField$I_NoOp = {$: 0};
var $author$project$Fields$DateRangeField$initTranslator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			var val = msg.a;
			return td.el($author$project$Fields$DateRangeField$I_NoOp);
		}
	});
var $author$project$Fields$GenericField$dateRangeInitTranslator = $author$project$Fields$DateRangeField$initTranslator(
	{
		el: function (a) {
			return $author$project$Fields$GenericField$Internal(
				$author$project$Fields$GenericField$DateRangeMsg(a));
		},
		jc: function (a) {
			return $author$project$Fields$GenericField$Internal($author$project$Fields$GenericField$Generic_NoOp);
		}
	});
var $author$project$Fields$GeometryField$NoOp = {$: 0};
var $author$project$Fields$GeometryField$initTranslator = F2(
	function (td, msg) {
		switch (msg.$) {
			case 0:
				var m = msg.a;
				return td.el(m);
			case 1:
				var val = msg.a;
				return td.el($author$project$Fields$GeometryField$NoOp);
			default:
				var layer = msg.a;
				return td.el($author$project$Fields$GeometryField$NoOp);
		}
	});
var $author$project$Fields$GenericField$geometryInitTranslator = $author$project$Fields$GeometryField$initTranslator(
	{
		el: function (a) {
			return $author$project$Fields$GenericField$Internal(
				$author$project$Fields$GenericField$GeometryMsg(a));
		},
		fO: function (a) {
			return $author$project$Fields$GenericField$Internal($author$project$Fields$GenericField$Generic_NoOp);
		},
		jc: function (a) {
			return $author$project$Fields$GenericField$Internal($author$project$Fields$GenericField$Generic_NoOp);
		}
	});
var $author$project$Fields$DateField$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$DateField$PickerMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$DatePicker$Single = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$DatePicker$SingleOnly = 0;
var $elm$time$Time$Sun = 6;
var $author$project$PAM$UI$DatePicker$Date$formatDay = function (day) {
	switch (day) {
		case 0:
			return 'Mo';
		case 1:
			return 'Tu';
		case 2:
			return 'We';
		case 3:
			return 'Th';
		case 4:
			return 'Fr';
		case 5:
			return 'Sa';
		default:
			return 'Su';
	}
};
var $author$project$PAM$UI$DatePicker$Date$formatMonth = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {gK: col, kp: contextStack, ii: problem, iC: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.iC, s.gK, x, s.k));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.lY, s.ne);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{gK: 1, k: s.k, o: s.o, lY: s.lY + 1, iC: s.iC + 1, ne: s.ne}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{gK: s.gK + 1, k: s.k, o: s.o, lY: newOffset, iC: s.iC, ne: s.ne}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.ii;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.ne),
			s.lY) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.lY, s1.lY, s0.ne),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.lY, s.iC, s.gK, s.ne);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{gK: newCol, k: s.k, o: s.o, lY: newOffset, iC: newRow, ne: s.ne});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {gK: col, ii: problem, iC: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.iC, p.gK, p.ii);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{gK: 1, k: _List_Nil, o: 1, lY: 0, iC: 1, ne: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($justinmimbs$date$Date$deadEndToString),
			$elm$core$String$join('; '))));
var $author$project$PAM$UI$DatePicker$Date$Off = {$: 0};
var $author$project$PAM$UI$DatePicker$off = $author$project$PAM$UI$DatePicker$Date$Off;
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.fA;
	});
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		jl: 1 + (((rd - week1Day1) / 7) | 0),
		jm: wy,
		nU: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.jl;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.jm;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.eg(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.ft(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.eg(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.eU(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.bQ(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.bQ(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.bQ(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.f2(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.bQ(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.bQ(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.ne);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.lY, offset) < 0,
					0,
					{gK: col, k: s0.k, o: s0.o, lY: offset, iC: row, ne: s0.ne});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.lY, s.iC, s.gK, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.lY, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	eU: $justinmimbs$date$Date$withOrdinalSuffix,
	ft: $justinmimbs$date$Date$monthToName,
	eg: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	f2: $justinmimbs$date$Date$weekdayToName,
	bQ: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $author$project$PAM$UI$DatePicker$sampleFormatter = function (day) {
	return A2($justinmimbs$date$Date$format, 'yyyy-MM-dd', day);
};
var $author$project$PAM$UI$DatePicker$defaultSettings = {
	gC: $author$project$PAM$UI$DatePicker$off,
	gI: 'elm-datepicker--',
	gO: _List_Nil,
	kv: $author$project$PAM$UI$DatePicker$sampleFormatter,
	gV: $author$project$PAM$UI$DatePicker$Date$formatDay,
	dO: true,
	cL: 6,
	lk: _List_fromArray(
		[
			$elm$html$Html$Attributes$required(false)
		]),
	ht: _List_Nil,
	hu: 'Picker',
	hv: $elm$core$Maybe$Nothing,
	lp: false,
	fs: $author$project$PAM$UI$DatePicker$Date$formatMonth,
	mt: $justinmimbs$date$Date$fromIsoString,
	mv: 'Please pick a date...',
	m1: 0,
	f8: $elm$core$String$fromInt
};
var $author$project$NormalDate$stringToDate = F2(
	function (input, delim) {
		var split = A2(
			$elm$core$List$filterMap,
			function (a) {
				return a;
			},
			A2(
				$elm$core$List$map,
				$elm$core$String$toInt,
				A2(
					$elm$core$String$split,
					delim,
					A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, 'T', input))))));
		if ((split.b && split.b.b) && split.b.b.b) {
			var m = split.a;
			var _v1 = split.b;
			var d = _v1.a;
			var _v2 = _v1.b;
			var y = _v2.a;
			return ((y >= 1970) && (y <= 2099)) ? $elm$core$Maybe$Just(
				A3(
					$justinmimbs$date$Date$fromCalendarDate,
					y,
					$author$project$NormalDate$toMonth(m),
					d)) : $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$NormalDate$fromNormalDate = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Err('Could not parse a date from ' + input),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Result$Ok,
			A2($author$project$NormalDate$stringToDate, input, '/')));
};
var $author$project$Fields$DateField$pickerSettings = function (readOnly) {
	return _Utils_update(
		$author$project$PAM$UI$DatePicker$defaultSettings,
		{
			kv: $justinmimbs$date$Date$format('MM/dd/yyyy'),
			lk: _List_Nil,
			lp: readOnly,
			mt: $author$project$NormalDate$fromNormalDate,
			mv: (!readOnly) ? 'Select' : 'Not Set'
		});
};
var $author$project$PAM$UI$DatePicker$InputValid = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$UI$DatePicker$setTextInputs = F2(
	function (settings, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				ab: function () {
					var _v1 = model.m0;
					_v1$3:
					while (true) {
						switch (_v1.$) {
							case 1:
								var d = _v1.a;
								return $author$project$PAM$UI$DatePicker$InputValid(
									settings.kv(d));
							case 2:
								switch (_v1.a.$) {
									case 1:
										var d = _v1.a.a;
										return $author$project$PAM$UI$DatePicker$InputValid(
											settings.kv(d));
									case 0:
										var _v2 = _v1.a;
										var d = _v2.a;
										return $author$project$PAM$UI$DatePicker$InputValid(
											settings.kv(d));
									default:
										break _v1$3;
								}
							default:
								break _v1$3;
						}
					}
					return $author$project$PAM$UI$DatePicker$NotEntered;
				}(),
				af: function () {
					var _v3 = model.m0;
					_v3$2:
					while (true) {
						if (_v3.$ === 2) {
							switch (_v3.a.$) {
								case 2:
									var d = _v3.a.a;
									return $author$project$PAM$UI$DatePicker$InputValid(
										settings.kv(d));
								case 0:
									var _v4 = _v3.a;
									var d = _v4.b;
									return $author$project$PAM$UI$DatePicker$InputValid(
										settings.kv(d));
								default:
									break _v3$2;
							}
						} else {
							break _v3$2;
						}
					}
					return $author$project$PAM$UI$DatePicker$NotEntered;
				}()
			});
	});
var $author$project$PAM$UI$DatePicker$setPickedDate = F3(
	function (settings, pickedDate, _v0) {
		var model = _v0;
		return A2(
			$author$project$PAM$UI$DatePicker$setTextInputs,
			settings,
			_Utils_update(
				model,
				{m0: pickedDate}));
	});
var $author$project$Fields$DateField$init = F2(
	function (data, getter) {
		var date = getter(data);
		var _v0 = function (_v1) {
			var a = _v1.a;
			var b = _v1.b;
			return _Utils_Tuple2(
				a,
				$elm$core$Maybe$Just(b));
		}($author$project$PAM$UI$DatePicker$init);
		var picker_ = _v0.a;
		var dpc = _v0.b;
		var picker = function () {
			if (date.$ === 1) {
				var dt = date.a;
				var ds = date.b;
				return A3(
					$author$project$PAM$UI$DatePicker$setPickedDate,
					$author$project$Fields$DateField$pickerSettings(false),
					$author$project$PAM$UI$DatePicker$Single(dt),
					picker_);
			} else {
				return picker_;
			}
		}();
		return _Utils_Tuple2(
			{dM: date, ao: picker},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Platform$Cmd$map(
								A2($elm$core$Basics$composeR, $author$project$Fields$DateField$PickerMsg, $author$project$Fields$DateField$Internal)),
							dpc))
					])));
	});
var $author$project$PAM$UI$DatePicker$End = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$UI$DatePicker$FullRange = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Fields$DateRangeField$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$DateRangeField$PickerMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$DatePicker$Range = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$UI$DatePicker$Start = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$DatePicker$DateRange = 1;
var $author$project$Fields$DateRangeField$pickerSettings = function (readOnly) {
	return _Utils_update(
		$author$project$PAM$UI$DatePicker$defaultSettings,
		{
			kv: $justinmimbs$date$Date$format('MM/dd/yyyy'),
			lk: _List_Nil,
			lp: readOnly,
			mt: $author$project$NormalDate$fromNormalDate,
			mv: 'Select',
			m1: 1
		});
};
var $author$project$Fields$DateRangeField$init = F2(
	function (data, getter) {
		var date = getter(data);
		var _v0 = function (_v1) {
			var a = _v1.a;
			var b = _v1.b;
			return _Utils_Tuple2(
				a,
				$elm$core$Maybe$Just(b));
		}($author$project$PAM$UI$DatePicker$init);
		var picker_ = _v0.a;
		var dpc = _v0.b;
		var picker = function () {
			if (date.a.$ === 1) {
				if (date.b.$ === 1) {
					var _v3 = date.a;
					var dt = _v3.a;
					var ds = _v3.b;
					var _v4 = date.b;
					var dte = _v4.a;
					var dse = _v4.b;
					return A3(
						$author$project$PAM$UI$DatePicker$setPickedDate,
						$author$project$Fields$DateRangeField$pickerSettings(false),
						$author$project$PAM$UI$DatePicker$Range(
							A2($author$project$PAM$UI$DatePicker$FullRange, dt, dte)),
						picker_);
				} else {
					var _v5 = date.a;
					var dt = _v5.a;
					var ds = _v5.b;
					return A3(
						$author$project$PAM$UI$DatePicker$setPickedDate,
						$author$project$Fields$DateRangeField$pickerSettings(false),
						$author$project$PAM$UI$DatePicker$Range(
							$author$project$PAM$UI$DatePicker$Start(dt)),
						picker_);
				}
			} else {
				if (date.b.$ === 1) {
					var _v6 = date.b;
					var dte = _v6.a;
					var dse = _v6.b;
					return A3(
						$author$project$PAM$UI$DatePicker$setPickedDate,
						$author$project$Fields$DateRangeField$pickerSettings(false),
						$author$project$PAM$UI$DatePicker$Range(
							$author$project$PAM$UI$DatePicker$End(dte)),
						picker_);
				} else {
					return picker_;
				}
			}
		}();
		return _Utils_Tuple2(
			{dM: date, ao: picker},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Platform$Cmd$map(
								A2($elm$core$Basics$composeR, $author$project$Fields$DateRangeField$PickerMsg, $author$project$Fields$DateRangeField$Internal)),
							dpc))
					])));
	});
var $author$project$Fields$GeometryField$InitSpat = {$: 1};
var $author$project$Fields$GeometryField$None = {$: 0};
var $author$project$Fields$GeometryField$defaultConfig = {cG: 2, cW: _List_Nil, ez: $elm$core$Dict$empty};
var $author$project$PlugMap$Plugins$Upload$Idle = {$: 0};
var $author$project$PlugMap$Plugins$Upload$defaultModel = {aE: $author$project$PlugMap$Plugins$Upload$Idle};
var $author$project$Fields$GeometryField$NotStarted = 0;
var $author$project$PlugMap$Plugins$FeaturePicker$Model = F5(
	function (services, layer, trackingRequests, trackingFeatures, selectedFeatures) {
		return {hC: layer, ag: selectedFeatures, ez: services, bh: trackingFeatures, bN: trackingRequests};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$NoFeatures = {$: 0};
var $author$project$PlugMap$Plugins$FeaturePicker$defaultModel = A5($author$project$PlugMap$Plugins$FeaturePicker$Model, $elm$core$Dict$empty, $elm$core$Maybe$Nothing, 0, $author$project$PlugMap$Plugins$FeaturePicker$NoFeatures, _List_Nil);
var $inkuzmin$elm_multiselect$Multiselect$Closed = 0;
var $inkuzmin$elm_multiselect$Multiselect$Model = $elm$core$Basics$identity;
var $inkuzmin$elm_multiselect$Multiselect$initModel = F2(
	function (values, tag1) {
		return {
			kL: $elm$core$Maybe$Nothing,
			w: values,
			x: $elm$core$List$head(values),
			z: '',
			fg: 23.0,
			aT: false,
			I: _List_Nil,
			aE: 0,
			no: tag1,
			jh: values
		};
	});
var $author$project$Fields$GeometryField$defaultSelectModel = {
	dg: _List_Nil,
	D: $author$project$PlugMap$Plugins$FeaturePicker$defaultModel,
	bz: 0,
	ac: A2($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, ''),
	bD: 1,
	h4: _List_Nil,
	aA: 0,
	I: $elm$core$Maybe$Nothing,
	ag: _List_Nil
};
var $author$project$Fields$GeometryField$defaultModel = {cA: 50, bn: $author$project$Fields$GeometryField$defaultConfig, m: $author$project$PlugMap$Plugins$Drawing$defaultModel, dP: false, av: $elm$core$Maybe$Nothing, d: $author$project$Fields$GeometryField$defaultSelectModel, I: $elm$core$Maybe$Nothing, B: $author$project$Fields$GeometryField$None, O: _List_Nil, cp: false, aF: $author$project$PlugMap$Plugins$Upload$defaultModel};
var $author$project$PlugMap$Plugins$Drawing$encodeOptions = function (options) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'enableMultishape',
				$elm$json$Json$Encode$bool(options.e$))
			]));
};
var $author$project$PlugMap$Plugins$Drawing$initDrawingCmd = _Platform_outgoingPort('initDrawingCmd', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$Drawing$wrapId = F2(
	function (id, val) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(id)),
					_Utils_Tuple2('data', val)
				]));
	});
var $author$project$PlugMap$Plugins$Drawing$init = _Utils_Tuple2(
	$author$project$PlugMap$Plugins$Drawing$defaultModel,
	$elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				$author$project$PlugMap$Plugins$Drawing$initDrawingCmd(
				A2(
					$author$project$PlugMap$Plugins$Drawing$wrapId,
					'moop',
					$author$project$PlugMap$Plugins$Drawing$encodeOptions($author$project$PlugMap$Plugins$Drawing$defaultOptions)))
			])));
var $author$project$PlugMap$Plugins$FeaturePicker$init = function (services) {
	return _Utils_Tuple2(
		_Utils_update(
			$author$project$PlugMap$Plugins$FeaturePicker$defaultModel,
			{ez: services}),
		$elm$core$Platform$Cmd$none);
};
var $author$project$Fields$GeometryField$init = F3(
	function (data, getter, config) {
		var spatial = getter(data);
		var ss = function () {
			if (spatial.$ === 1) {
				var spa = spatial.a;
				var text = spatial.b;
				return _List_fromArray(
					[
						_Utils_update(
						spa,
						{
							r: $elm$core$Maybe$Just(1)
						})
					]);
			} else {
				return _List_Nil;
			}
		}();
		var cfg = config;
		var dm = _Utils_update(
			$author$project$Fields$GeometryField$defaultModel,
			{bn: cfg});
		var osm = dm.d;
		var _v0 = $author$project$PlugMap$Plugins$FeaturePicker$init(config.ez);
		var fpm = _v0.a;
		var fpc = _v0.b;
		var ssm = _Utils_update(
			osm,
			{D: fpm});
		var _v1 = $author$project$PlugMap$Plugins$Drawing$init;
		var dmo = _v1.a;
		var dmc = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				dm,
				{
					m: dmo,
					av: $elm$core$List$head(ss),
					d: ssm,
					O: ss
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, dmc),
						A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always(
							$author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$InitSpat)),
						$elm$core$Process$sleep(100))
					])));
	});
var $author$project$Fields$SingleChoiceWithOtherField$init = F2(
	function (data, getter) {
		var t = 1;
		return _Utils_Tuple2(
			{be: 1},
			$elm$core$Platform$Cmd$batch(_List_Nil));
	});
var $author$project$Fields$SingleChoiceWithOtherField$NoOp = {$: 0};
var $author$project$Fields$SingleChoiceWithOtherField$initTranslator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			return td.el($author$project$Fields$SingleChoiceWithOtherField$NoOp);
		}
	});
var $author$project$Fields$GenericField$singleChoiceWithOtherInitTranslator = $author$project$Fields$SingleChoiceWithOtherField$initTranslator(
	{
		el: function (a) {
			return $author$project$Fields$GenericField$Internal(
				$author$project$Fields$GenericField$SingleChoiceWithOtherMsg(a));
		},
		jc: function (a) {
			return $author$project$Fields$GenericField$Internal($author$project$Fields$GenericField$Generic_NoOp);
		}
	});
var $author$project$Fields$GenericField$init = F3(
	function (fieldType, dat, getter) {
		var _v0 = _Utils_Tuple2(fieldType, getter);
		_v0$4:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var config = _v0.a.a;
						var get = _v0.b.a;
						return function (_v1) {
							var mdl = _v1.a;
							var cmd = _v1.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(
									$author$project$Fields$GenericField$GeometryModel(mdl)),
								A2($elm$core$Platform$Cmd$map, $author$project$Fields$GenericField$geometryInitTranslator, cmd));
						}(
							A3($author$project$Fields$GeometryField$init, dat, get, config));
					} else {
						break _v0$4;
					}
				case 1:
					if (_v0.b.$ === 1) {
						var _v2 = _v0.a;
						var get = _v0.b.a;
						return function (_v3) {
							var mdl = _v3.a;
							var cmd = _v3.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(
									$author$project$Fields$GenericField$DateRangeModel(mdl)),
								A2($elm$core$Platform$Cmd$map, $author$project$Fields$GenericField$dateRangeInitTranslator, cmd));
						}(
							A2($author$project$Fields$DateRangeField$init, dat, get));
					} else {
						break _v0$4;
					}
				case 2:
					if (_v0.b.$ === 2) {
						var _v4 = _v0.a;
						var get = _v0.b.a;
						return function (_v5) {
							var mdl = _v5.a;
							var cmd = _v5.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(
									$author$project$Fields$GenericField$DateModel(mdl)),
								A2($elm$core$Platform$Cmd$map, $author$project$Fields$GenericField$dateInitTranslator, cmd));
						}(
							A2($author$project$Fields$DateField$init, dat, get));
					} else {
						break _v0$4;
					}
				case 3:
					if (_v0.b.$ === 3) {
						var _v6 = _v0.a;
						var get = _v0.b.a;
						return function (_v7) {
							var mdl = _v7.a;
							var cmd = _v7.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(
									$author$project$Fields$GenericField$SingleChoiceWithOtherModel(mdl)),
								A2($elm$core$Platform$Cmd$map, $author$project$Fields$GenericField$singleChoiceWithOtherInitTranslator, cmd));
						}(
							A2($author$project$Fields$SingleChoiceWithOtherField$init, dat, get));
					} else {
						break _v0$4;
					}
				default:
					break _v0$4;
			}
		}
		return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
	});
var $author$project$PAM$EditableFields$initField = function (data) {
	return function (updateFn) {
		return function (layout) {
			return function (field) {
				return function (services) {
					return function (featurePickerLayers) {
						return function (decimalPlaces) {
							return function (choices) {
								return function (row) {
									return function (col) {
										var val = A2($author$project$PAM$Object$FieldValue$get, field, data.au);
										var nt = A4($author$project$PAM$EditableFields$fieldTypeToGenericFieldType, field.hb, services, featurePickerLayers, decimalPlaces);
										var getter = function () {
											var _v1 = _Utils_Tuple2(nt, val);
											switch (_v1.a.$) {
												case 0:
													if ((!_v1.b.$) && (_v1.b.a.$ === 17)) {
														var cfg = _v1.a.a;
														var spat = _v1.b.a.a;
														return $author$project$Fields$Shared$Getter_Geometry(
															function (a) {
																switch (spat.$) {
																	case 1:
																		var s = spat.a;
																		var t = spat.b;
																		return A2(
																			$author$project$Fields$Shared$Valid,
																			{
																				U: A2($elm$core$Maybe$withDefault, 0, s.U),
																				r: $elm$core$Maybe$Nothing,
																				bi: function () {
																					var _v3 = s.bi;
																					if (!_v3) {
																						return 'BoundingBox';
																					} else {
																						return 'Polygon';
																					}
																				}(),
																				aj: s.aj
																			},
																			t);
																	case 2:
																		var t = spat.a;
																		return $author$project$Fields$Shared$Invalid(t);
																	default:
																		return $author$project$Fields$Shared$NotEntered;
																}
															});
													} else {
														var cfg = _v1.a.a;
														return $author$project$Fields$Shared$Getter_Geometry(
															function (a) {
																return $author$project$Fields$Shared$NotEntered;
															});
													}
												case 1:
													if ((!_v1.b.$) && (_v1.b.a.$ === 8)) {
														var _v4 = _v1.a;
														var dat = _v1.b.a.a;
														return $author$project$Fields$Shared$Getter_DateRange(
															function (a) {
																switch (dat.$) {
																	case 1:
																		var range = dat.a;
																		var str = dat.b;
																		return _Utils_Tuple2(range.iT, range.e0);
																	case 2:
																		var str = dat.a;
																		return _Utils_Tuple2(
																			$author$project$Fields$Shared$Invalid(str),
																			$author$project$Fields$Shared$Invalid(str));
																	default:
																		return _Utils_Tuple2($author$project$Fields$Shared$NotEntered, $author$project$Fields$Shared$NotEntered);
																}
															});
													} else {
														var _v6 = _v1.a;
														return $author$project$Fields$Shared$Getter_DateRange(
															function (a) {
																return _Utils_Tuple2($author$project$Fields$Shared$NotEntered, $author$project$Fields$Shared$NotEntered);
															});
													}
												case 2:
													if ((!_v1.b.$) && (_v1.b.a.$ === 7)) {
														var _v7 = _v1.a;
														var dat = _v1.b.a.a;
														return $author$project$Fields$Shared$Getter_Date(
															function (a) {
																return dat;
															});
													} else {
														var _v8 = _v1.a;
														return $author$project$Fields$Shared$Getter_Date(
															function (a) {
																return $author$project$Fields$Shared$NotEntered;
															});
													}
												case 3:
													if ((!_v1.b.$) && (_v1.b.a.$ === 12)) {
														var _v9 = _v1.a;
														var value = _v1.b.a.a;
														return $author$project$Fields$Shared$Getter_SingleChoiceWithOther(
															function (a) {
																if ((!value.$) && (value.a.$ === 2)) {
																	if (value.a.a.$ === 1) {
																		var item = value.a.a.a;
																		return A2(
																			$elm$core$Maybe$andThen,
																			function (itm) {
																				return $elm$core$Maybe$Just(
																					$author$project$Fields$Shared$Choice(
																						_Utils_Tuple2(
																							$elm$core$String$fromInt(itm.r),
																							itm.fu)));
																			},
																			$elm$core$List$head(
																				A2(
																					$elm$core$List$filter,
																					function (choice) {
																						return _Utils_eq(choice.r, item);
																					},
																					choices)));
																	} else {
																		var maybeChoice = value.a.a.a;
																		return $elm$core$Maybe$Just(
																			$author$project$Fields$Shared$Other(maybeChoice));
																	}
																} else {
																	return $elm$core$Maybe$Nothing;
																}
															});
													} else {
														var _v11 = _v1.a;
														return $author$project$Fields$Shared$Getter_SingleChoiceWithOther(
															function (a) {
																return $elm$core$Maybe$Nothing;
															});
													}
												default:
													return $author$project$Fields$Shared$NoneGetter;
											}
										}();
										var _v0 = A3($author$project$Fields$GenericField$init, nt, data, getter);
										var gm = _v0.a;
										var gc = _v0.b;
										return _Utils_Tuple2(
											gm,
											A2(
												$elm$core$Platform$Cmd$map,
												A2($author$project$PAM$EditableFields$genericFieldTranslator, row, col),
												gc));
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$core$List$unzip = function (pairs) {
	var step = F2(
		function (_v0, _v1) {
			var x = _v0.a;
			var y = _v0.b;
			var xs = _v1.a;
			var ys = _v1.b;
			return _Utils_Tuple2(
				A2($elm$core$List$cons, x, xs),
				A2($elm$core$List$cons, y, ys));
		});
	return A3(
		$elm$core$List$foldr,
		step,
		_Utils_Tuple2(_List_Nil, _List_Nil),
		pairs);
};
var $author$project$PAM$EditableFields$initRow = F9(
	function (data, updateFn, row, fields, services, featurePickerLayers, decimalPlaces, choices, rowNum) {
		return function (_v0) {
			var models = _v0.a;
			var cmds = _v0.b;
			return _Utils_Tuple2(
				models,
				$elm$core$Platform$Cmd$batch(cmds));
		}(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index) {
						return function (field) {
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									function (mf) {
										return $author$project$PAM$EditableFields$initField(data)(updateFn)(field)(mf)(services)(featurePickerLayers)(decimalPlaces)(choices)(rowNum)(index);
									},
									A2(
										$elm_community$list_extra$List$Extra$find,
										function (genericField) {
											return _Utils_eq(
												$elm$core$Maybe$Just(genericField.r),
												field.hU);
										},
										fields)));
						};
					},
					row)));
	});
var $author$project$PAM$EditableFields$init = F8(
	function (services, featurePickerLayers, decimalPlaces, choices, data, updateFn, fields, layout) {
		return function (_v0) {
			var models = _v0.a;
			var cmds = _v0.b;
			return _Utils_Tuple2(
				models,
				$elm$core$Platform$Cmd$batch(cmds));
		}(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index) {
						return function (row) {
							return A9($author$project$PAM$EditableFields$initRow, data, updateFn, row, fields, services, featurePickerLayers, decimalPlaces, choices, index);
						};
					},
					layout)));
	});
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $author$project$PAM$scrollLeftPanelToTop = A2(
	$elm$core$Task$attempt,
	$elm$core$Basics$always($author$project$PAM$Message$NoOp),
	A2(
		$elm$core$Task$onError,
		$elm$core$Basics$always(
			$elm$core$Task$succeed(0)),
		A3($elm$browser$Browser$Dom$setViewportOf, 'object-details', 0, 0)));
var $author$project$PAM$getDetailModelsFor = F7(
	function (key, _v0, object, editing, services, featurePickerLayers, decimalPlaces) {
		var objectTypes = _v0.c;
		var objectModels = _v0.l;
		var detailLayouts = _v0.C;
		var fieldChoices = _v0.y;
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(_List_Nil, $elm$core$Platform$Cmd$none),
			A2(
				$elm$core$Maybe$map,
				function (_v2) {
					var models = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						models,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$PAM$deetsTranslator, cmd),
									$author$project$PAM$scrollLeftPanelToTop
								])));
				},
				A2(
					$elm$core$Maybe$andThen,
					function (_v1) {
						var objTypeId = _v1.a;
						return function (fc) {
							return A3(
								$elm$core$Maybe$map2,
								A6($author$project$PAM$EditableFields$init, services, featurePickerLayers, decimalPlaces, fc, object, $author$project$PAM$Message$ChangeObjectDetails),
								A2($author$project$PAM$Model$ObjectModels$get, objTypeId, objectModels),
								A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, detailLayouts));
						}(
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2(
									$elm$core$Maybe$map,
									$author$project$PAM$Model$FieldChoices$allFor,
									A2($author$project$PAM$Model$FieldChoices$get, objTypeId, fieldChoices))));
					},
					A2($author$project$PAM$Model$ObjectTypes$findByKey, key, objectTypes))));
	});
var $author$project$PAM$User$defaultUserModel = {kI: 'test@co.com', hi: 'Testing', r: '-1'};
var $author$project$PAM$Object$newObjectFromObjectType = function (type_) {
	return {
		gP: $author$project$PAM$User$defaultUserModel,
		dI: $elm$time$Time$millisToPosix(0),
		au: $elm$core$Dict$empty,
		r: -1,
		lo: true,
		d3: $author$project$PAM$User$defaultUserModel,
		d4: $elm$time$Time$millisToPosix(0),
		fu: '',
		bi: type_
	};
};
var $author$project$PAM$initFields = F3(
	function (route, newModel, isNew) {
		var editing = function () {
			_v13$4:
			while (true) {
				if (!route.$) {
					switch (route.a.$) {
						case 2:
							var _v14 = route.a;
							var objTypeKey = _v14.a;
							var params = _v14.b;
							return true;
						case 3:
							var _v15 = route.a;
							var parentObjTypeKey = _v15.a;
							var parentObjId = _v15.b;
							var childObjTypeKey = _v15.c;
							var params = _v15.d;
							return true;
						case 4:
							var _v16 = route.a;
							var objTypeKey = _v16.a;
							var objId = _v16.b;
							var params = _v16.c;
							return false;
						case 5:
							var _v17 = route.a;
							var objTypeKey = _v17.a;
							var objId = _v17.b;
							var params = _v17.c;
							return true;
						default:
							break _v13$4;
					}
				} else {
					break _v13$4;
				}
			}
			return false;
		}();
		var _v0 = function () {
			_v1$4:
			while (true) {
				if (!route.$) {
					switch (route.a.$) {
						case 2:
							var _v2 = route.a;
							var objTypeKey = _v2.a;
							var params = _v2.b;
							var _v3 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, newModel.a.c);
							if (!_v3.$) {
								var _v4 = _v3.a;
								var objTypeId = _v4.a;
								var newType = _v4.b;
								return _Utils_Tuple3(
									$elm$core$Maybe$Just(objTypeKey),
									$elm$core$Maybe$Just(newModel.a),
									$elm$core$Maybe$Just(
										$author$project$PAM$Object$newObjectFromObjectType(newType)));
							} else {
								return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
							}
						case 3:
							var _v5 = route.a;
							var parentObjTypeKey = _v5.a;
							var parentObjId = _v5.b;
							var childObjTypeKey = _v5.c;
							var params = _v5.d;
							var _v6 = A2($author$project$PAM$Model$ObjectTypes$findByKey, childObjTypeKey, newModel.a.c);
							if (!_v6.$) {
								var _v7 = _v6.a;
								var objTypeId = _v7.a;
								var childType = _v7.b;
								return _Utils_Tuple3(
									$elm$core$Maybe$Just(childObjTypeKey),
									$elm$core$Maybe$Just(newModel.a),
									$elm$core$Maybe$Just(
										$author$project$PAM$Object$newObjectFromObjectType(childType)));
							} else {
								return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
							}
						case 4:
							var _v8 = route.a;
							var objTypeKey = _v8.a;
							var objId = _v8.b;
							var params = _v8.c;
							if (A2($author$project$PAM$Object$Repo$contains, objId, newModel.b)) {
								var _v9 = A2($author$project$PAM$Object$Repo$getObject, objId, newModel.b);
								if (!_v9.$) {
									var obj = _v9.a;
									return _Utils_Tuple3(
										$elm$core$Maybe$Just(objTypeKey),
										$elm$core$Maybe$Just(newModel.a),
										$elm$core$Maybe$Just(obj));
								} else {
									return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
								}
							} else {
								return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
							}
						case 5:
							var _v10 = route.a;
							var objTypeKey = _v10.a;
							var objId = _v10.b;
							var params = _v10.c;
							if (A2($author$project$PAM$Object$Repo$contains, objId, newModel.b)) {
								var _v11 = A2($author$project$PAM$Object$Repo$getObject, objId, newModel.b);
								if (!_v11.$) {
									var obj = _v11.a;
									return _Utils_Tuple3(
										$elm$core$Maybe$Just(objTypeKey),
										$elm$core$Maybe$Just(newModel.a),
										$elm$core$Maybe$Just(obj));
								} else {
									return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
								}
							} else {
								return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
							}
						default:
							break _v1$4;
					}
				} else {
					break _v1$4;
				}
			}
			return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
		}();
		var objType = _v0.a;
		var modelInfo = _v0.b;
		var newObj = _v0.c;
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none),
			A4(
				$elm$core$Maybe$map3,
				function (key) {
					return function (info) {
						return function (obj) {
							var _v12 = A7($author$project$PAM$getDetailModelsFor, key, info, obj, editing, newModel.h.ez, newModel.dR, newModel.bR.M.k$.eW.cG);
							var newDetailModels = _v12.a;
							var newDetailCmds = _v12.b;
							return _Utils_Tuple2(
								_Utils_update(
									newModel,
									{e: newDetailModels}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											newDetailCmds,
											A2(
											$elm$core$Task$perform,
											$elm$core$Basics$always(
												editing ? $author$project$PAM$Message$StartEditing : $author$project$PAM$Message$StopEditing(newModel.e)),
											$elm$core$Task$succeed('Hello'))
										])));
						};
					};
				},
				objType,
				modelInfo,
				newObj));
	});
var $author$project$PAM$handleObjectLoaded = F2(
	function (model, result) {
		if (!result.$) {
			var object = result.a;
			return function (newRepo) {
				return A3(
					$author$project$PAM$initFields,
					model.mS,
					_Utils_update(
						model,
						{b: newRepo}),
					false);
			}(
				A2($author$project$PAM$Object$Repo$insertObject, object, model.b));
		} else {
			var error = result.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleUpdateLoadedObject', error))));
		}
	});
var $author$project$PAM$handleObjectUpdatesSavedResponse = F2(
	function (response, model) {
		if (!response.$) {
			var savedObject = response.a;
			return $author$project$PAM$refreshMap(
				_Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A2($author$project$PAM$Object$Repo$insertObject, savedObject, model.b)
						}),
					A2(
						$author$project$PAM$Route$pushUrl,
						model.lw,
						A4($author$project$PAM$Route$initShowObjectDetails, savedObject.bi.lw, savedObject.r, $elm$core$Maybe$Nothing, model.mS))));
		} else {
			var error = response.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleObjectUpdatesSavedResponse', error))));
		}
	});
var $author$project$PAM$handleUpdateLoadedObject = F2(
	function (model, result) {
		if (!result.$) {
			var object = result.a;
			return function (newRepo) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b: newRepo}),
					$elm$core$Platform$Cmd$none);
			}(
				A2($author$project$PAM$Object$Repo$insertObject, object, model.b));
		} else {
			var error = result.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleUpdateLoadedObject', error))));
		}
	});
var $author$project$PAM$handleUpdateLoadedObjects = F2(
	function (model, result) {
		if (!result.$) {
			var objects = result.a;
			return function (newRepo) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b: newRepo}),
					$elm$core$Platform$Cmd$none);
			}(
				A3(
					$elm$core$List$foldl,
					F2(
						function (obj, repo) {
							return A2($author$project$PAM$Object$Repo$insertObject, obj, repo);
						}),
					model.b,
					objects));
		} else {
			var error = result.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string(
						A2($author$project$PAM$Helpers$httpErrorMsg, 'PAM.handleUpdateLoadedObjects', error))));
		}
	});
var $author$project$PAM$Message$HeaderMsg = function (a) {
	return {$: 71, a: a};
};
var $author$project$PAM$Header$translator = F2(
	function (td, msg) {
		var m = msg;
		return td.el(m);
	});
var $author$project$PAM$headerTranslator = $author$project$PAM$Header$translator(
	{el: $author$project$PAM$Message$HeaderMsg});
var $author$project$PAM$UI$Search$hideSuggestionView = function (model) {
	return _Utils_update(
		model,
		{k5: true, bg: $author$project$PAM$UI$Search$None});
};
var $author$project$PAM$Map$InitThemes = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$Map$Ready = 1;
var $author$project$PAM$Map$ErrorLoadingServices = {$: 2};
var $author$project$PAM$Map$handleErrorLoadMapServices = function (res) {
	return $author$project$PAM$Map$ErrorLoadingServices;
};
var $author$project$PAM$Map$NoOp = {$: 0};
var $author$project$PAM$Map$UpdateServices = function (a) {
	return {$: 6, a: a};
};
var $author$project$PAM$Map$handleLoadMapServices = function (res) {
	if (!res.$) {
		var services = res.a;
		return $author$project$PAM$Map$Internal(
			$author$project$PAM$Map$UpdateServices(services));
	} else {
		return $author$project$PAM$Map$Internal($author$project$PAM$Map$NoOp);
	}
};
var $author$project$PlugMap$Plugins$ColorUnderPointer$ColorUnderPointerOptions = function (layers) {
	return {hE: layers};
};
var $author$project$PlugMap$Plugins$ColorUnderPointer$enableColorUnderPointerCmd = _Platform_outgoingPort('enableColorUnderPointerCmd', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$ColorUnderPointer$encodeColorUnderPointerOptions = function (options) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'layers',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, options.hE))
			]));
};
var $author$project$PlugMap$Plugins$ColorUnderPointer$init = function (layersToWatch) {
	return _Utils_Tuple2(
		$author$project$PlugMap$Plugins$ColorUnderPointer$Model,
		$author$project$PlugMap$Plugins$ColorUnderPointer$enableColorUnderPointerCmd(
			$author$project$PlugMap$Plugins$ColorUnderPointer$encodeColorUnderPointerOptions(
				$author$project$PlugMap$Plugins$ColorUnderPointer$ColorUnderPointerOptions(layersToWatch))));
};
var $author$project$PlugMap$Plugins$Identify$enableIdentify = _Platform_outgoingPort('enableIdentify', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$Identify$init = function (layers) {
	var filteredLayers = A2(
		$elm$core$List$filter,
		function (layer) {
			return !_Utils_eq(layer.lc, $elm$core$Maybe$Nothing);
		},
		layers);
	return _Utils_Tuple2(
		_Utils_update(
			$author$project$PlugMap$Plugins$Identify$defaultModel,
			{hE: filteredLayers}),
		$author$project$PlugMap$Plugins$Identify$enableIdentify(
			A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Themes$encodeLayer, filteredLayers)));
};
var $author$project$PlugMap$Core$Options$Options = F6(
	function (target, center, zoom, areaProj, maxZoom, minZoom) {
		return {gj: areaProj, gB: center, hQ: maxZoom, hS: minZoom, np: target, jw: zoom};
	});
var $author$project$PlugMap$Core$Options$defaultOptions = A6(
	$author$project$PlugMap$Core$Options$Options,
	'ol-map',
	_Utils_Tuple2(-111.6, 39.4),
	7,
	$elm$core$Maybe$Nothing,
	$elm$core$Maybe$Just(22),
	$elm$core$Maybe$Just(3));
var $author$project$PlugMap$Core$Options$encodeCenter = function (_v0) {
	var lat = _v0.a;
	var lon = _v0.b;
	return A2(
		$elm$json$Json$Encode$list,
		$elm$json$Json$Encode$float,
		_List_fromArray(
			[lat, lon]));
};
var $author$project$PlugMap$Core$Options$encodeOptions = function (options) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'target',
				$elm$json$Json$Encode$string(options.np)),
				_Utils_Tuple2(
				'center',
				$author$project$PlugMap$Core$Options$encodeCenter(options.gB)),
				_Utils_Tuple2(
				'zoom',
				$elm$json$Json$Encode$int(options.jw)),
				_Utils_Tuple2(
				'areaProj',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, options.gj)),
				_Utils_Tuple2(
				'maxZoom',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$int, options.hQ)),
				_Utils_Tuple2(
				'minZoom',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$int, options.hS))
			]));
};
var $author$project$PlugMap$Core$Ports$renderMapCmd = _Platform_outgoingPort('renderMapCmd', $elm$core$Basics$identity);
var $author$project$PlugMap$Core$initWithOptions = function (options) {
	var _v0 = _Utils_Tuple2(
		$elm$core$Maybe$Just(options),
		$author$project$PlugMap$Core$Options$encodeOptions(options));
	var mapOptions = _v0.a;
	var encodedOptions = _v0.b;
	var initialModel = _Utils_update(
		$author$project$PlugMap$Core$defaultModel,
		{ea: mapOptions});
	return _Utils_Tuple2(
		initialModel,
		$author$project$PlugMap$Core$Ports$renderMapCmd(encodedOptions));
};
var $author$project$PlugMap$Core$initWithDefaults = $author$project$PlugMap$Core$initWithOptions($author$project$PlugMap$Core$Options$defaultOptions);
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$PlugMap$Plugins$Themes$layersWithIdentify = function (repo) {
	return $elm$core$Dict$values(
		A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, lyr) {
					return $elm_community$maybe_extra$Maybe$Extra$isJust(lyr.lc);
				}),
			repo));
};
var $author$project$PlugMap$Plugins$Themes$layersWithLegend = function (repo) {
	return $elm$core$Dict$values(
		A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, lyr) {
					return $elm_community$maybe_extra$Maybe$Extra$isJust(lyr.d6);
				}),
			repo));
};
var $author$project$PAM$MapServices$Insecured = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$MapServices$BaseMapServiceConfig = F2(
	function (baseUrl, key) {
		return {gn: baseUrl, lw: key};
	});
var $author$project$PAM$MapServices$baseMapServiceConfigDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'key',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'baseUrl',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PAM$MapServices$BaseMapServiceConfig)));
var $author$project$PAM$MapServices$insecuredMapServiceDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$PAM$MapServices$baseMapServiceConfigDecoder,
	$elm$json$Json$Decode$succeed($author$project$PAM$MapServices$Insecured));
var $author$project$PAM$MapServices$Secured = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$PAM$MapServices$ServiceToken = F2(
	function (token, expires) {
		return {g8: expires, nI: token};
	});
var $author$project$PAM$MapServices$serviceTokenDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'expires',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'token',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PAM$MapServices$ServiceToken)));
var $author$project$PAM$MapServices$securedMapServiceDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$PAM$MapServices$serviceTokenDecoder,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$PAM$MapServices$baseMapServiceConfigDecoder,
		$elm$json$Json$Decode$succeed($author$project$PAM$MapServices$Secured)));
var $author$project$PAM$MapServices$mapServiceDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$PAM$MapServices$securedMapServiceDecoder, $author$project$PAM$MapServices$insecuredMapServiceDecoder]));
var $author$project$PAM$API$APIBase$map_GetAllMapServices = F4(
	function (settings, user, _return, onError) {
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'map',
			'GetAllTokens',
			$elm$core$Maybe$Nothing,
			_return,
			onError,
			user,
			$elm$json$Json$Decode$list($author$project$PAM$MapServices$mapServiceDecoder));
	});
var $author$project$PlugMap$Core$Options$centerDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (lat) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (lon) {
				return $elm$json$Json$Decode$succeed(
					_Utils_Tuple2(lat, lon));
			},
			A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$float));
	},
	A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$float));
var $author$project$PlugMap$Core$Options$optionsFromConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'minZoom',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	$elm$core$Maybe$Just(3),
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'maxZoom',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
		$elm$core$Maybe$Just(22),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'areaProj',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'zoom',
				$elm$json$Json$Decode$int,
				7,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'center',
					$author$project$PlugMap$Core$Options$centerDecoder,
					_Utils_Tuple2(-111.6, 39.4),
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
						'ol-map',
						$elm$json$Json$Decode$succeed($author$project$PlugMap$Core$Options$Options)))))));
var $author$project$PAM$Map$updateZoomButtonBgCmd = _Platform_outgoingPort('updateZoomButtonBgCmd', $elm$json$Json$Encode$string);
var $author$project$PAM$Map$init = F4(
	function (appSettings, flags, apiset, user) {
		var zoomBtnClass = function () {
			var _v6 = appSettings.M.nW;
			if (!_v6.$) {
				var cls = _v6.a;
				return cls;
			} else {
				return '';
			}
		}();
		var options = A2(
			$elm$json$Json$Decode$decodeValue,
			A2($elm$json$Json$Decode$field, 'options', $author$project$PlugMap$Core$Options$optionsFromConfigDecoder),
			flags);
		var extent = A2(
			$elm$json$Json$Decode$decodeValue,
			A2(
				$elm$json$Json$Decode$field,
				'extent',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$float)),
			flags);
		var _v0 = $author$project$PlugMap$Plugins$Themes$init(flags);
		var themesModel = _v0.a;
		var themesCmd = _v0.b;
		var _v1 = function () {
			if (!options.$) {
				var o = options.a;
				return $author$project$PlugMap$Core$initWithOptions(o);
			} else {
				return $author$project$PlugMap$Core$initWithDefaults;
			}
		}();
		var mapModel = _v1.a;
		var mapCmd = _v1.b;
		if (!themesModel.$) {
			var themes = themesModel.a;
			var _v4 = $author$project$PlugMap$Plugins$Identify$init(
				$author$project$PlugMap$Plugins$Themes$layersWithIdentify(themes.lB));
			var identifyModel = _v4.a;
			var idCmd = _v4.b;
			var _v5 = $author$project$PlugMap$Plugins$ColorUnderPointer$init(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.lw;
						},
						$author$project$PlugMap$Plugins$Themes$layerKeyToString),
					$author$project$PlugMap$Plugins$Themes$layersWithLegend(themes.lB)));
			var cupModel = _v5.a;
			var cupCmd = _v5.b;
			return _Utils_Tuple2(
				$elm$core$Result$Ok(
					_Utils_update(
						$author$project$PAM$Map$defaultModel,
						{cB: cupModel, aO: identifyModel, h: mapModel, cj: 1, s: themes})),
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2(
							$elm$core$Platform$Cmd$map,
							$author$project$PAM$Map$Internal,
							A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$MapMessage, mapCmd)),
							A4($author$project$PAM$API$APIBase$map_GetAllMapServices, apiset, user, $author$project$PAM$Map$handleLoadMapServices, $author$project$PAM$Map$handleErrorLoadMapServices),
							A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$identifyTranslator, idCmd),
							A2(
							$elm$core$Task$perform,
							$elm$core$Basics$always(
								$author$project$PAM$Map$Internal(
									$author$project$PAM$Map$InitThemes(flags))),
							$elm$core$Process$sleep(200)),
							A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$colorUnderPointerTranslator, cupCmd),
							$author$project$PAM$Map$updateZoomButtonBgCmd(zoomBtnClass)
						])));
		} else {
			var err = themesModel.a;
			return _Utils_Tuple2(
				$elm$core$Result$Err(err),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$UI$DatePicker$initFromDate = function (date) {
	return {
		K: $elm$core$Maybe$Just(date),
		bs: false,
		ab: $author$project$PAM$UI$DatePicker$NotEntered,
		em: false,
		af: $author$project$PAM$UI$DatePicker$NotEntered,
		m0: $author$project$PAM$UI$DatePicker$NoneSelected,
		ah: date
	};
};
var $author$project$PAM$Route$initNewObject = F3(
	function (objTypeKey, params, route) {
		return A2(
			$author$project$PAM$Route$NewObject,
			objTypeKey,
			A2($author$project$PAM$Route$pickQueryParams, params, route));
	});
var $author$project$PAM$Model$GridLayouts$insert = F3(
	function (id, layout, _v0) {
		var layouts = _v0;
		return A3($elm$core$Dict$insert, id, layout, layouts);
	});
var $author$project$PAM$isEditingRoute = function (route) {
	_v0$3:
	while (true) {
		if (!route.$) {
			switch (route.a.$) {
				case 5:
					var _v1 = route.a;
					return true;
				case 3:
					var _v2 = route.a;
					return true;
				case 2:
					var _v3 = route.a;
					return true;
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return false;
};
var $author$project$PAM$leftSidebarSearchTranslator = $author$project$PAM$UI$LeftSidebarSearch$apiTranslator(
	{
		h2: function (a) {
			return function (t) {
				return A2($author$project$PAM$Message$ClickViewObjectDetailsNew, a, t);
			};
		},
		el: $author$project$PAM$Message$LeftSidebarSearchMsg
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm_community$maybe_extra$Maybe$Extra$orElse = F2(
	function (ma, mb) {
		if (mb.$ === 1) {
			return ma;
		} else {
			return mb;
		}
	});
var $author$project$PAM$setModelInfo = F2(
	function (modelInfo, model) {
		return _Utils_update(
			model,
			{a: modelInfo});
	});
var $author$project$PAM$loadChainUpdate = F2(
	function (model, chain) {
		if (chain.$ === 6) {
			var modelInfo = chain.a;
			var gridObjTypeId = $author$project$PAM$Route$getQueryParamsOrDefault(model.mS).aM;
			var defaultObjectRequest = function (m) {
				return function (momo) {
					return _Utils_eq(
						momo.bR.M.d$,
						$elm$core$Maybe$Just('noDrawer')) ? _Utils_Tuple2(momo, $elm$core$Platform$Cmd$none) : A2($author$project$PAM$requestObjectsFor, gridObjTypeId, momo);
				}(
					A2($author$project$PAM$setModelInfo, modelInfo, m));
			};
			var objectRequestForType = F2(
				function (key, m) {
					var _v6 = A2($author$project$PAM$Model$ObjectTypes$findByKey, key, modelInfo.c);
					if (!_v6.$) {
						var _v7 = _v6.a;
						var objTypeId = _v7.a;
						var _v8 = A2(
							$author$project$PAM$requestObjectsFor,
							objTypeId,
							A2($author$project$PAM$setModelInfo, modelInfo, m));
						var newModel = _v8.a;
						var newCmds = _v8.b;
						var newRoute = A2(
							$elm$core$Maybe$withDefault,
							A2($author$project$PAM$Route$initMap, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
							A2(
								$elm_community$maybe_extra$Maybe$Extra$orElse,
								newModel.mS,
								A2(
									$elm$core$Maybe$andThen,
									function (r) {
										if (!r.$) {
											var params = r.a;
											return $elm$core$Maybe$Nothing;
										} else {
											return $elm$core$Maybe$Just(r);
										}
									},
									newModel.du)));
						return _Utils_Tuple2(
							newModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										newCmds,
										A2($author$project$PAM$Route$pushUrl, m.lw, newRoute)
									])));
					} else {
						return defaultObjectRequest(m);
					}
				});
			var _v1 = model.du;
			if (!_v1.$) {
				switch (_v1.a.$) {
					case 0:
						var params = _v1.a.a;
						return defaultObjectRequest(model);
					case 1:
						var params = _v1.a.a;
						return defaultObjectRequest(model);
					case 2:
						var _v2 = _v1.a;
						var objTypeKey = _v2.a;
						var params = _v2.b;
						return A2(objectRequestForType, objTypeKey, model);
					case 3:
						var _v3 = _v1.a;
						var parentObjTypeKey = _v3.a;
						var parentObjId = _v3.b;
						var childObjTypeKey = _v3.c;
						var params = _v3.d;
						return A2(objectRequestForType, parentObjTypeKey, model);
					case 4:
						var _v4 = _v1.a;
						var objTypeKey = _v4.a;
						var objId = _v4.b;
						var params = _v4.c;
						return A2(objectRequestForType, objTypeKey, model);
					default:
						var _v5 = _v1.a;
						var objTypeKey = _v5.a;
						var objId = _v5.b;
						var params = _v5.c;
						return A2(objectRequestForType, objTypeKey, model);
				}
			} else {
				return defaultObjectRequest(model);
			}
		} else {
			return $author$project$PAM$noop(model);
		}
	});
var $author$project$PAM$Message$MapSearchHasSuggestions = function (a) {
	return {$: 68, a: a};
};
var $author$project$PAM$Message$MapSearchPlugin = function (a) {
	return {$: 67, a: a};
};
var $author$project$PlugMap$Plugins$Search$apiTranslator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			var m = msg.a;
			return td.mh(m);
		}
	});
var $author$project$PAM$mapSearchTranslator = $author$project$PlugMap$Plugins$Search$apiTranslator(
	{el: $author$project$PAM$Message$MapSearchPlugin, mh: $author$project$PAM$Message$MapSearchHasSuggestions});
var $author$project$PAM$UI$DatePicker$Pick = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$UI$DatePicker$pick = $author$project$PAM$UI$DatePicker$Pick;
var $author$project$PAM$UI$DateRangeFilter$pickerSettings = function (readOnly) {
	return _Utils_update(
		$author$project$PAM$UI$DatePicker$defaultSettings,
		{
			kv: $justinmimbs$date$Date$format('MM/dd/yyyy'),
			dO: true,
			lk: _List_Nil,
			hu: 'fromRangeFilter',
			lp: false,
			mt: $author$project$NormalDate$fromNormalDate,
			mv: 'Select'
		});
};
var $author$project$PlugMap$Plugins$FeaturePicker$ReferenceLayer = F3(
	function (name, key, config) {
		return {bn: config, lw: key, fu: name};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$Esri = function (a) {
	return {$: 0, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$Esri_Config = F6(
	function (url, tokenKey, layers, propertyNames, idField, nameField) {
		return {d_: idField, hE: layers, fv: nameField, ad: propertyNames, bM: tokenKey, c8: url};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$esriConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'nameField',
	$elm$json$Json$Decode$string,
	'name',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'idField',
		$elm$json$Json$Decode$string,
		'id',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'propertyNames',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			_List_Nil,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'layers',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				_List_Nil,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'tokenKey',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'url',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$Esri_Config)))))));
var $author$project$PlugMap$Plugins$FeaturePicker$esriDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'esri',
	$author$project$PlugMap$Plugins$FeaturePicker$esriConfigDecoder,
	$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$Esri));
var $author$project$PlugMap$Plugins$FeaturePicker$WMS_WFS = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$WFS_Config = F6(
	function (url, tokenKey, typeNames, propertyNames, filter, responseMode) {
		return {e5: filter, ad: propertyNames, mN: responseMode, bM: tokenKey, cn: typeNames, c8: url};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$XML = 0;
var $author$project$PlugMap$Plugins$FeaturePicker$EqualTo = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$equalToDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'equals',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'field',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$EqualTo)));
var $author$project$PlugMap$Plugins$FeaturePicker$filterDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$PlugMap$Plugins$FeaturePicker$equalToDecoder]));
var $author$project$PlugMap$Plugins$FeaturePicker$JSON = 1;
var $author$project$PlugMap$Plugins$FeaturePicker$wfsResponseModeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		return (a === 'JSON') ? $elm$json$Json$Decode$succeed(1) : $elm$json$Json$Decode$succeed(0);
	},
	$elm$json$Json$Decode$string);
var $author$project$PlugMap$Plugins$FeaturePicker$wfsConfigDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'mode',
	$author$project$PlugMap$Plugins$FeaturePicker$wfsResponseModeDecoder,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'filter',
		$elm$json$Json$Decode$maybe($author$project$PlugMap$Plugins$FeaturePicker$filterDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'propertyNames',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			_List_Nil,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'typeNames',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'tokenKey',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'url',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$WFS_Config)))))));
var $author$project$PlugMap$Plugins$FeaturePicker$WMS_Config = F3(
	function (url, tokenKey, layers) {
		return {hE: layers, bM: tokenKey, c8: url};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$wmsConfigDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'layers',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'tokenKey',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'url',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$WMS_Config))));
var $author$project$PlugMap$Plugins$FeaturePicker$wmsWfsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'wfs',
	$author$project$PlugMap$Plugins$FeaturePicker$wfsConfigDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'wms',
		$author$project$PlugMap$Plugins$FeaturePicker$wmsConfigDecoder,
		$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$WMS_WFS)));
var $author$project$PlugMap$Plugins$FeaturePicker$referenceLayerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'config',
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[$author$project$PlugMap$Plugins$FeaturePicker$esriDecoder, $author$project$PlugMap$Plugins$FeaturePicker$wmsWfsDecoder])),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'key',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$PlugMap$Plugins$FeaturePicker$ReferenceLayer))));
var $author$project$PlugMap$Core$Ports$refreshMap = _Platform_outgoingPort(
	'refreshMap',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Core$refresh = function (model) {
	return $author$project$PlugMap$Core$Ports$refreshMap(0);
};
var $author$project$PAM$Map$refresh = function (model) {
	return A2(
		$elm$core$Platform$Cmd$map,
		$author$project$PAM$Map$Internal,
		A2(
			$elm$core$Platform$Cmd$map,
			$author$project$PAM$Map$MapMessage,
			$author$project$PlugMap$Core$refresh(model.h)));
};
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$PAM$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$PAM$Route$unparser(route));
	});
var $author$project$PlugMap$Plugins$Themes$deselectAll = function (cats) {
	return A2(
		$elm$core$List$map,
		function (cat) {
			var ns = function () {
				var _v0 = cat.m0;
				switch (_v0.$) {
					case 0:
						return $author$project$PlugMap$Plugins$Themes$Monoselection($elm$core$Maybe$Nothing);
					case 1:
						var keys = _v0.a;
						return $author$project$PlugMap$Plugins$Themes$Polyselection(_List_Nil);
					case 2:
						var key = _v0.a;
						return $author$project$PlugMap$Plugins$Themes$EnforcedMonoselection(key);
					default:
						if (!_v0.b.b) {
							var key = _v0.a;
							return A2($author$project$PlugMap$Plugins$Themes$EnforcedPolyselection, key, _List_Nil);
						} else {
							var key = _v0.a;
							var _v1 = _v0.b;
							var head = _v1.a;
							var tail = _v1.b;
							return A2($author$project$PlugMap$Plugins$Themes$EnforcedPolyselection, key, _List_Nil);
						}
				}
			}();
			return _Utils_update(
				cat,
				{m0: ns});
		},
		cats);
};
var $author$project$PlugMap$Plugins$Themes$replaceCategory = F2(
	function (category, categories) {
		return A2(
			$elm$core$List$map,
			function (cat) {
				return _Utils_eq(cat.lw, category.lw) ? category : cat;
			},
			categories);
	});
var $author$project$PlugMap$Plugins$Themes$resetCategory = F2(
	function (key, model) {
		var meow = A2($author$project$PlugMap$Plugins$Themes$getCategoryByKey, model, key);
		var newSelection = $author$project$PlugMap$Plugins$Themes$deselectAll(meow);
		var newCategories = A2(
			$elm$core$Maybe$withDefault,
			model.ly,
			A2(
				$elm$core$Maybe$map,
				function (cat) {
					return A2($author$project$PlugMap$Plugins$Themes$replaceCategory, cat, model.ly);
				},
				$elm$core$List$head(newSelection)));
		return _Utils_update(
			model,
			{ly: newCategories});
	});
var $author$project$PAM$Message$MapSearch = {$: 70};
var $author$project$PAM$Message$MapSearchMsg = function (a) {
	return {$: 65, a: a};
};
var $author$project$PAM$Message$MapSearchSuggestionSelected = function (a) {
	return {$: 69, a: a};
};
var $author$project$PAM$Message$MapSearchTextChanged = function (a) {
	return {$: 66, a: a};
};
var $author$project$PAM$UI$Search$SearchChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$Search$apiTranslator = F2(
	function (td, msg) {
		switch (msg.$) {
			case 1:
				var m = msg.a;
				return td.el(m);
			case 0:
				return td.mf;
			case 2:
				var txt = msg.a;
				return td.mg(txt);
			case 3:
				var itm = msg.a;
				return td.mi(itm);
			case 4:
				return td.el(
					$author$project$PAM$UI$Search$SearchChanged(''));
			default:
				return td.mf;
		}
	});
var $author$project$PAM$searchNewTranslator = $author$project$PAM$UI$Search$apiTranslator(
	{el: $author$project$PAM$Message$MapSearchMsg, mf: $author$project$PAM$Message$MapSearch, mg: $author$project$PAM$Message$MapSearchTextChanged, mi: $author$project$PAM$Message$MapSearchSuggestionSelected});
var $author$project$PlugMap$Plugins$Themes$setCategoryTransparency = F3(
	function (t, category, model) {
		var newCategory = _Utils_update(
			category,
			{
				i8: A3($elm$core$Basics$clamp, 0.0, 1.0, t)
			});
		var newCategories = A2($author$project$PlugMap$Plugins$Themes$replaceCategory, newCategory, model.ly);
		return _Utils_Tuple2(
			newCategory,
			_Utils_update(
				model,
				{ly: newCategories}));
	});
var $author$project$Ports$setCategoryTransparencyCmd = _Platform_outgoingPort('setCategoryTransparencyCmd', $elm$core$Basics$identity);
var $author$project$PAM$UI$Search$setDirectionVisibility = function (model) {
	var visibility = function () {
		var _v0 = model.kF;
		if (!_v0) {
			return true;
		} else {
			return false;
		}
	}();
	return _Utils_update(
		model,
		{kF: visibility});
};
var $author$project$PAM$Model$ModelInfo$setGridLayouts = F2(
	function (layouts, info) {
		return _Utils_update(
			info,
			{dW: layouts});
	});
var $author$project$PAM$UI$Search$setSearch = F2(
	function (model, search) {
		return _Utils_update(
			model,
			{bd: search});
	});
var $author$project$PAM$UI$Search$Suggestions = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$Search$setSuggestionResults = F2(
	function (model, suggestions) {
		return _Utils_update(
			model,
			{
				k5: false,
				bg: $author$project$PAM$UI$Search$Suggestions(suggestions)
			});
	});
var $author$project$PAM$EditableFields$rebatch = function (_v0) {
	var models = _v0.a;
	var cmds = _v0.b;
	return _Utils_Tuple2(
		models,
		$elm$core$Platform$Cmd$batch(cmds));
};
var $author$project$Fields$GenericField$geometryToGeneric = F3(
	function (data, field, _v0) {
		var mod = _v0.a;
		var msg = _v0.b;
		return _Utils_Tuple2(
			$author$project$Fields$GenericField$GeometryModel(mod),
			A2(
				$elm$core$Platform$Cmd$map,
				A3($author$project$Fields$GenericField$geometryTranslator, data, field, mod),
				msg));
	});
var $author$project$PlugMap$Plugins$Drawing$enableModify = _Platform_outgoingPort(
	'enableModify',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Drawing$enableModifyDrawn = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$PlugMap$Plugins$Drawing$enableModify(0));
};
var $author$project$Fields$GeometryField$startEditing = function (model) {
	var _v0 = $author$project$PlugMap$Plugins$Drawing$enableModifyDrawn(model.m);
	var nd = _v0.a;
	var nc = _v0.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{m: nd, dP: true}),
		A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, nc));
};
var $author$project$Fields$GenericField$startEditing = F3(
	function (field, model, data) {
		var _v0 = _Utils_Tuple2(field, model);
		if ((_v0.a.$ === 10) && (_v0.b.$ === 3)) {
			var ff = _v0.a.a;
			var mod = _v0.b.a;
			return A3(
				$author$project$Fields$GenericField$geometryToGeneric,
				data,
				ff,
				$author$project$Fields$GeometryField$startEditing(mod));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$EditableFields$startEditingItem = F5(
	function (maybeModel, row, col, data, maybeField) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none),
			A3(
				$elm$core$Maybe$map2,
				function (mdl) {
					return function (field) {
						return function (_v0) {
							var a = _v0.a;
							var b = _v0.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(a),
								A2(
									$elm$core$Platform$Cmd$map,
									A2($author$project$PAM$EditableFields$genericFieldTranslator, row, col),
									b));
						}(
							A3(
								$author$project$Fields$GenericField$startEditing,
								field.bq(data),
								mdl,
								data));
					};
				},
				maybeModel,
				maybeField));
	});
var $author$project$PAM$EditableFields$startEditingRow = F4(
	function (rowFields, rowModels, row, data) {
		return $author$project$PAM$EditableFields$rebatch(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index2) {
						return function (item) {
							return A5(
								$author$project$PAM$EditableFields$startEditingItem,
								item,
								row,
								index2,
								data,
								$elm$core$List$head(
									A2($elm$core$List$drop, index2, rowFields)));
						};
					},
					rowModels)));
	});
var $author$project$PAM$EditableFields$startEditing = F3(
	function (fields, models, data) {
		return $author$project$PAM$EditableFields$rebatch(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index) {
						return function (rowItems) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$PAM$EditableFields$rebatch(
									$elm$core$List$unzip(
										A2(
											$elm$core$List$map,
											function (a) {
												return _Utils_Tuple2(a, $elm$core$Platform$Cmd$none);
											},
											rowItems))),
								A2(
									$elm$core$Maybe$andThen,
									function (flds) {
										return $elm$core$Maybe$Just(
											A4($author$project$PAM$EditableFields$startEditingRow, flds, rowItems, index, data));
									},
									$elm$core$List$head(
										A2($elm$core$List$drop, index, fields))));
						};
					},
					models)));
	});
var $author$project$PAM$startEditingDeets = F2(
	function (config, model) {
		var grid = $author$project$PAM$buildDetailLayout(config);
		var _v0 = A3($author$project$PAM$EditableFields$startEditing, grid, model.e, config.n);
		var newDeetModels = _v0.a;
		var newDeetCmds = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{e: newDeetModels}),
			A2($elm$core$Platform$Cmd$map, $author$project$PAM$deetsTranslator, newDeetCmds));
	});
var $author$project$PAM$startEditingDeetsBuild = function (model) {
	var _v0 = model.mS;
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 0:
				var params = _v0.a.a;
				return $elm$core$Maybe$Nothing;
			case 1:
				var params = _v0.a.a;
				return $elm$core$Maybe$Nothing;
			case 2:
				var _v1 = _v0.a;
				var objTypeKey = _v1.a;
				var params = _v1.b;
				var _v2 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
				if (!_v2.$) {
					var _v3 = _v2.a;
					var objTypeId = _v3.a;
					var objType = _v3.b;
					return A5(
						$elm$core$Maybe$map4,
						F4(
							function (newObj, choices, layout, fields) {
								return A2(
									$author$project$PAM$startEditingDeets,
									{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
									model);
							}),
						$author$project$PAM$Object$Repo$getNewObject(model.b),
						A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
						A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
						A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			case 3:
				var _v4 = _v0.a;
				var parentObjTypeKey = _v4.a;
				var parentObjId = _v4.b;
				var childObjTypeKey = _v4.c;
				var params = _v4.d;
				var _v5 = A2($author$project$PAM$Model$ObjectTypes$findByKey, childObjTypeKey, model.a.c);
				if (!_v5.$) {
					var _v6 = _v5.a;
					var objTypeId = _v6.a;
					return A5(
						$elm$core$Maybe$map4,
						F4(
							function (newObj, choices, layout, fields) {
								return A2(
									$author$project$PAM$startEditingDeets,
									{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
									model);
							}),
						$author$project$PAM$Object$Repo$getNewObject(model.b),
						A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
						A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
						A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			case 4:
				var _v7 = _v0.a;
				var objTypeKey = _v7.a;
				var objId = _v7.b;
				var params = _v7.c;
				var _v8 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
				if (!_v8.$) {
					var _v9 = _v8.a;
					var objTypeId = _v9.a;
					return A5(
						$elm$core$Maybe$map4,
						F4(
							function (newObj, choices, layout, fields) {
								return A2(
									$author$project$PAM$startEditingDeets,
									{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
									model);
							}),
						A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
						A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
						A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
						A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			default:
				var _v10 = _v0.a;
				var objTypeKey = _v10.a;
				var objId = _v10.b;
				var params = _v10.c;
				var _v11 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
				if (!_v11.$) {
					var _v12 = _v11.a;
					var objTypeId = _v12.a;
					return A5(
						$elm$core$Maybe$map4,
						F4(
							function (newObj, choices, layout, fields) {
								return A2(
									$author$project$PAM$startEditingDeets,
									{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
									model);
							}),
						A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
						A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
						A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
						A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
				} else {
					return $elm$core$Maybe$Nothing;
				}
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$PlugMap$Plugins$Identify$enable = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$PlugMap$Plugins$Identify$enableIdentify(
			A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Themes$encodeLayer, model.hE)));
};
var $author$project$PAM$Map$enableIdentify = function (model) {
	var _v0 = $author$project$PlugMap$Plugins$Identify$enable(model.aO);
	var ni = _v0.a;
	var nic = _v0.b;
	return _Utils_Tuple2(
		model,
		A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$identifyTranslator, nic));
};
var $author$project$PAM$startIdentifying = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var _v1 = $author$project$PAM$Map$enableIdentify(model.h);
	var nm = _v1.a;
	var nmc = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{h: nm}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					cmd,
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$mapTranslater, nmc)
				])));
};
var $author$project$Fields$GeometryField$Reference = {$: 3};
var $author$project$Fields$GeometryField$SetLayer = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$hideReferenceLayerDisplay = _Platform_outgoingPort(
	'hideReferenceLayerDisplay',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$FeaturePicker$destruct = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$PlugMap$Plugins$FeaturePicker$hideReferenceLayerDisplay(0));
};
var $author$project$PlugMap$Plugins$Drawing$disableDraw = _Platform_outgoingPort(
	'disableDraw',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Drawing$disableDrawDrawn = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$PlugMap$Plugins$Drawing$disableDraw(0));
};
var $author$project$PlugMap$Plugins$Drawing$disableModify = _Platform_outgoingPort(
	'disableModify',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Drawing$disableModifyDrawn = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$PlugMap$Plugins$Drawing$disableModify(0));
};
var $author$project$Fields$GeometryField$stopEditing = function (model) {
	var ex = _Utils_eq(model.B, $author$project$Fields$GeometryField$Reference) ? A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(
			$author$project$Fields$GeometryField$SetLayer($elm$core$Maybe$Nothing)),
		$elm$core$Task$succeed('Hi')) : $elm$core$Platform$Cmd$none;
	var _v0 = $author$project$PlugMap$Plugins$Drawing$disableModifyDrawn(model.m);
	var nnd = _v0.a;
	var nnc = _v0.b;
	var _v1 = $author$project$PlugMap$Plugins$Drawing$disableDrawDrawn(nnd);
	var nd = _v1.a;
	var nc = _v1.b;
	var _v2 = $author$project$PlugMap$Plugins$FeaturePicker$destruct(model.d.D);
	var fpModel = _v2.a;
	var fpCmd = _v2.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{m: nd, dP: false}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, nc),
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, nnc),
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, fpCmd),
					ex
				])));
};
var $author$project$Fields$GenericField$stopEditing = F3(
	function (field, model, data) {
		var _v0 = _Utils_Tuple2(field, model);
		if ((_v0.a.$ === 10) && (_v0.b.$ === 3)) {
			var ff = _v0.a.a;
			var mod = _v0.b.a;
			return A3(
				$author$project$Fields$GenericField$geometryToGeneric,
				data,
				ff,
				$author$project$Fields$GeometryField$stopEditing(mod));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$EditableFields$stopEditingItem = F5(
	function (maybeModel, row, col, data, maybeField) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none),
			A3(
				$elm$core$Maybe$map2,
				function (mdl) {
					return function (field) {
						return function (_v0) {
							var a = _v0.a;
							var b = _v0.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(a),
								A2(
									$elm$core$Platform$Cmd$map,
									A2($author$project$PAM$EditableFields$genericFieldTranslator, row, col),
									b));
						}(
							A3(
								$author$project$Fields$GenericField$stopEditing,
								field.bq(data),
								mdl,
								data));
					};
				},
				maybeModel,
				maybeField));
	});
var $author$project$PAM$EditableFields$stopEditingRow = F4(
	function (rowFields, rowModels, row, data) {
		return $author$project$PAM$EditableFields$rebatch(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index2) {
						return function (item) {
							return A5(
								$author$project$PAM$EditableFields$stopEditingItem,
								item,
								row,
								index2,
								data,
								$elm$core$List$head(
									A2($elm$core$List$drop, index2, rowFields)));
						};
					},
					rowModels)));
	});
var $author$project$PAM$EditableFields$stopEditing = F3(
	function (fields, models, data) {
		return $author$project$PAM$EditableFields$rebatch(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index) {
						return function (rowItems) {
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$PAM$EditableFields$rebatch(
									$elm$core$List$unzip(
										A2(
											$elm$core$List$map,
											function (a) {
												return _Utils_Tuple2(a, $elm$core$Platform$Cmd$none);
											},
											rowItems))),
								A2(
									$elm$core$Maybe$andThen,
									function (flds) {
										return $elm$core$Maybe$Just(
											A4($author$project$PAM$EditableFields$stopEditingRow, flds, rowItems, index, data));
									},
									$elm$core$List$head(
										A2($elm$core$List$drop, index, fields))));
						};
					},
					models)));
	});
var $author$project$PAM$stopEditingDeets = F2(
	function (config, model) {
		var grid = $author$project$PAM$buildDetailLayout(config);
		var _v0 = A3($author$project$PAM$EditableFields$stopEditing, grid, config.e, config.n);
		var newDeetModels = _v0.a;
		var newDeetCmds = _v0.b;
		return _Utils_Tuple2(
			model,
			A2($elm$core$Platform$Cmd$map, $author$project$PAM$deetsTranslator, newDeetCmds));
	});
var $author$project$PAM$stopEditingDeetsBuild = F2(
	function (model, models) {
		var _v0 = model.mS;
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 0:
					return $elm$core$Maybe$Nothing;
				case 1:
					return $elm$core$Maybe$Nothing;
				case 2:
					var _v1 = _v0.a;
					var objTypeKey = _v1.a;
					var params = _v1.b;
					var _v2 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
					if (!_v2.$) {
						var _v3 = _v2.a;
						var objTypeId = _v3.a;
						var objType = _v3.b;
						return A5(
							$elm$core$Maybe$map4,
							F4(
								function (newObj, choices, layout, fields) {
									return A2(
										$author$project$PAM$stopEditingDeets,
										{t: choices, e: models, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
										model);
								}),
							$author$project$PAM$Object$Repo$getNewObject(model.b),
							A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
							A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
							A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				case 3:
					var _v4 = _v0.a;
					var parentObjTypeKey = _v4.a;
					var parentObjId = _v4.b;
					var childObjTypeKey = _v4.c;
					var params = _v4.d;
					var _v5 = A2($author$project$PAM$Model$ObjectTypes$findByKey, childObjTypeKey, model.a.c);
					if (!_v5.$) {
						var _v6 = _v5.a;
						var objTypeId = _v6.a;
						return A5(
							$elm$core$Maybe$map4,
							F4(
								function (newObj, choices, layout, fields) {
									return A2(
										$author$project$PAM$stopEditingDeets,
										{t: choices, e: models, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
										model);
								}),
							$author$project$PAM$Object$Repo$getNewObject(model.b),
							A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
							A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
							A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				case 4:
					var _v7 = _v0.a;
					var objTypeKey = _v7.a;
					var objId = _v7.b;
					var params = _v7.c;
					var _v8 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
					if (!_v8.$) {
						var _v9 = _v8.a;
						var objTypeId = _v9.a;
						return A5(
							$elm$core$Maybe$map4,
							F4(
								function (newObj, choices, layout, fields) {
									return A2(
										$author$project$PAM$stopEditingDeets,
										{t: choices, e: models, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
										model);
								}),
							A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
							A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
							A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
							A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				default:
					var _v10 = _v0.a;
					var objTypeKey = _v10.a;
					var objId = _v10.b;
					var params = _v10.c;
					var _v11 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
					if (!_v11.$) {
						var _v12 = _v11.a;
						var objTypeId = _v12.a;
						return A5(
							$elm$core$Maybe$map4,
							F4(
								function (newObj, choices, layout, fields) {
									return A2(
										$author$project$PAM$stopEditingDeets,
										{t: choices, e: models, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
										model);
								}),
							A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
							A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
							A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
							A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
					} else {
						return $elm$core$Maybe$Nothing;
					}
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$PlugMap$Plugins$Identify$disableIdentify = _Platform_outgoingPort(
	'disableIdentify',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Identify$disable = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$PlugMap$Plugins$Identify$disableIdentify(0));
};
var $author$project$PAM$Map$disableIdentify = function (model) {
	var _v0 = $author$project$PlugMap$Plugins$Identify$disable(model.aO);
	var ni = _v0.a;
	var nic = _v0.b;
	return _Utils_Tuple2(
		model,
		A2($elm$core$Platform$Cmd$map, $author$project$PAM$Map$identifyTranslator, nic));
};
var $author$project$PAM$stopIdentifying = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var _v1 = $author$project$PAM$Map$disableIdentify(model.h);
	var nm = _v1.a;
	var nmc = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{h: nm}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					cmd,
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$mapTranslater, nmc)
				])));
};
var $author$project$PAM$UI$DateRangeFilter$toPickerSettings = function (readOnly) {
	return _Utils_update(
		$author$project$PAM$UI$DatePicker$defaultSettings,
		{
			kv: $justinmimbs$date$Date$format('MM/dd/yyyy'),
			dO: true,
			lk: _List_Nil,
			hu: 'toRangeFilter',
			lp: false,
			mt: $author$project$NormalDate$fromNormalDate,
			mv: 'Select'
		});
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.mE;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.e8,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.$9,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.mx,
					_Utils_ap(http, url.cO)),
				url.cU)));
};
var $author$project$PlugMap$Plugins$Themes$toggleCategoryOpenness = F2(
	function (category, model) {
		var newCategory = function () {
			var _v0 = category.bD;
			if (!_v0) {
				return _Utils_update(
					category,
					{bD: 1});
			} else {
				return _Utils_update(
					category,
					{bD: 0});
			}
		}();
		var newCategories = A2($author$project$PlugMap$Plugins$Themes$replaceCategory, newCategory, model.ly);
		return _Utils_update(
			model,
			{ly: newCategories});
	});
var $author$project$PlugMap$Plugins$Themes$toggleCategoryTransparency = F2(
	function (category, model) {
		var newCategory = (category.i8 === 0.0) ? _Utils_update(
			category,
			{i8: 1.0}) : _Utils_update(
			category,
			{i8: 0.0});
		var newCategories = A2($author$project$PlugMap$Plugins$Themes$replaceCategory, newCategory, model.ly);
		return _Utils_Tuple2(
			newCategory,
			_Utils_update(
				model,
				{ly: newCategories}));
	});
var $author$project$PAM$Model$LayoutField$toggleDisplay = function (field) {
	var _v0 = field.eW;
	if (!_v0.$) {
		if (_v0.a) {
			return _Utils_update(
				field,
				{
					eW: $elm$core$Maybe$Just(false)
				});
		} else {
			return _Utils_update(
				field,
				{
					eW: $elm$core$Maybe$Just(true)
				});
		}
	} else {
		return field;
	}
};
var $author$project$PAM$Model$GridLayouts$toggleFieldDisplay = F2(
	function (id, layout) {
		return A2(
			$elm$core$List$map,
			function (field) {
				return _Utils_eq(
					field.hU,
					$elm$core$Maybe$Just(id)) ? $author$project$PAM$Model$LayoutField$toggleDisplay(field) : field;
			},
			layout);
	});
var $author$project$PlugMap$Plugins$Themes$toggleGroupOpenness = F2(
	function (group, model) {
		var newGroup = function () {
			var _v0 = group.bD;
			if (!_v0) {
				return _Utils_update(
					group,
					{bD: 1});
			} else {
				return _Utils_update(
					group,
					{bD: 0});
			}
		}();
		var newGroups = A3(
			$elm$core$Dict$update,
			$author$project$PlugMap$Plugins$Themes$groupKeyToString(group.lw),
			$elm$core$Basics$always(
				$elm$core$Maybe$Just(newGroup)),
			model.lz);
		return _Utils_update(
			model,
			{lz: newGroups});
	});
var $author$project$PlugMap$Plugins$Themes$isLayerSelected = F2(
	function (layerKey, selection) {
		_v0$4:
		while (true) {
			switch (selection.$) {
				case 0:
					if (!selection.a.$) {
						var key = selection.a.a;
						return _Utils_eq(key, layerKey);
					} else {
						break _v0$4;
					}
				case 1:
					if (selection.a.b) {
						var _v1 = selection.a;
						var head = _v1.a;
						var tail = _v1.b;
						return A2(
							$elm$core$List$any,
							function (k) {
								return _Utils_eq(k, layerKey);
							},
							A2($elm$core$List$cons, head, tail));
					} else {
						break _v0$4;
					}
				case 2:
					var key = selection.a;
					return _Utils_eq(key, layerKey);
				default:
					if (selection.b.b) {
						var key = selection.a;
						var _v2 = selection.b;
						var head = _v2.a;
						var tail = _v2.b;
						return A2(
							$elm$core$List$any,
							function (k) {
								return _Utils_eq(k, layerKey);
							},
							A2(
								$elm$core$List$cons,
								key,
								A2($elm$core$List$cons, head, tail)));
					} else {
						break _v0$4;
					}
			}
		}
		return false;
	});
var $author$project$PlugMap$Plugins$Themes$updateSelection = F2(
	function (layerKey, selection) {
		if (A2($author$project$PlugMap$Plugins$Themes$isLayerSelected, layerKey, selection)) {
			switch (selection.$) {
				case 0:
					return $author$project$PlugMap$Plugins$Themes$Monoselection($elm$core$Maybe$Nothing);
				case 1:
					var keys = selection.a;
					return $author$project$PlugMap$Plugins$Themes$Polyselection(
						A2(
							$elm$core$List$filter,
							function (key) {
								return !_Utils_eq(key, layerKey);
							},
							keys));
				case 2:
					var key = selection.a;
					return $author$project$PlugMap$Plugins$Themes$EnforcedMonoselection(key);
				default:
					if (!selection.b.b) {
						var key = selection.a;
						return A2($author$project$PlugMap$Plugins$Themes$EnforcedPolyselection, key, _List_Nil);
					} else {
						var key = selection.a;
						var _v1 = selection.b;
						var head = _v1.a;
						var tail = _v1.b;
						return _Utils_eq(key, layerKey) ? A2($author$project$PlugMap$Plugins$Themes$EnforcedPolyselection, head, tail) : A2(
							$author$project$PlugMap$Plugins$Themes$EnforcedPolyselection,
							key,
							A2(
								$elm$core$List$filter,
								function (k) {
									return !_Utils_eq(k, layerKey);
								},
								A2($elm$core$List$cons, head, tail)));
					}
			}
		} else {
			switch (selection.$) {
				case 0:
					return $author$project$PlugMap$Plugins$Themes$Monoselection(
						$elm$core$Maybe$Just(layerKey));
				case 1:
					var keys = selection.a;
					return $author$project$PlugMap$Plugins$Themes$Polyselection(
						A2($elm$core$List$cons, layerKey, keys));
				case 2:
					return $author$project$PlugMap$Plugins$Themes$EnforcedMonoselection(layerKey);
				default:
					var key = selection.a;
					var keys = selection.b;
					return A2(
						$author$project$PlugMap$Plugins$Themes$EnforcedPolyselection,
						layerKey,
						A2($elm$core$List$cons, key, keys));
			}
		}
	});
var $author$project$PlugMap$Plugins$Themes$toggleLayerSelection = F3(
	function (key, category, model) {
		if (A3($author$project$PlugMap$Plugins$Themes$categoryHasLayer, key, category, model)) {
			var newSelection = A2($author$project$PlugMap$Plugins$Themes$updateSelection, key, category.m0);
			var newCategory = _Utils_update(
				category,
				{m0: newSelection});
			var newCategories = A2($author$project$PlugMap$Plugins$Themes$replaceCategory, newCategory, model.ly);
			return _Utils_update(
				model,
				{ly: newCategories});
		} else {
			return model;
		}
	});
var $author$project$Ports$toggleSelectedThemesCmd = _Platform_outgoingPort('toggleSelectedThemesCmd', $elm$core$Basics$identity);
var $author$project$PAM$Route$setDrawerParam = F2(
	function (isOpen, route) {
		var newParams = function (params) {
			return _Utils_update(
				params,
				{eY: isOpen});
		}(
			$author$project$PAM$Route$getQueryParams(route));
		return A2($author$project$PAM$Route$setQueryParams, newParams, route);
	});
var $author$project$PAM$Route$trySetDrawerParam = F2(
	function (isOpen, route) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$PAM$Route$setDrawerParam(isOpen),
			route);
	});
var $author$project$PAM$Route$trySetSidebarParam = F2(
	function (isOpen, route) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$PAM$Route$setSidebarParam(isOpen),
			route);
	});
var $author$project$PAM$Route$trySetThemesParam = F2(
	function (isSelected, route) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$PAM$Route$setThemesParam(isSelected),
			route);
	});
var $author$project$PAM$Object$unlinkObjects = F3(
	function (_v0, parent, objectModels) {
		var childId = _v0.a;
		var childTypeId = _v0.b;
		var predicate = function (_v5) {
			var reference = _v5.is;
			return _Utils_eq(
				reference,
				$elm$core$Maybe$Just(childTypeId));
		};
		var targetField = A3($author$project$PAM$Model$ObjectModels$findField, predicate, parent.bi.r, objectModels);
		var _v1 = A2($author$project$PAM$Object$FieldValue$tryGet, targetField, parent.au);
		if (!_v1.$) {
			var fieldValue = _v1.a;
			if (fieldValue.$ === 14) {
				if (!fieldValue.a.$) {
					var references = fieldValue.a.a;
					var newValue = $author$project$PAM$Object$FieldValue$FieldValue_MultipleObject(
						$elm$core$Maybe$Just(
							A2(
								$elm$core$List$filter,
								function (_v3) {
									var objectId = _v3.h_;
									return !_Utils_eq(objectId, childId);
								},
								references)));
					var newValues = A3($author$project$PAM$Object$FieldValue$tryInsert, targetField, newValue, parent.au);
					return $elm$core$Result$Ok(
						_Utils_update(
							parent,
							{au: newValues}));
				} else {
					var _v4 = fieldValue.a;
					return $elm$core$Result$Err('Object not linked to parent');
				}
			} else {
				return $elm$core$Result$Err('Tried to unlink object from invalid field type');
			}
		} else {
			return $elm$core$Result$Err('Tried to unlink object from invalid parent object');
		}
	});
var $author$project$PAM$unlinkObject = F3(
	function (childRef, parentObj, model) {
		var objModels = model.a.l;
		var maybeChildObjType = A2($author$project$PAM$Model$ObjectTypes$findByKey, childRef.ja, model.a.c);
		if (!maybeChildObjType.$) {
			var _v1 = maybeChildObjType.a;
			var childType = _v1.b;
			var _v2 = A3(
				$author$project$PAM$Object$unlinkObjects,
				_Utils_Tuple2(childRef.h_, childType.r),
				parentObj,
				objModels);
			if (!_v2.$) {
				var updatedParent = _v2.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A2($author$project$PAM$Object$Repo$changeObject, updatedParent, model.b)
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				var error = _v2.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$logErrorCmd(
						$elm$json$Json$Encode$string('PAM.unlinkObject :: ' + error)));
			}
		} else {
			return _Utils_Tuple2(
				model,
				$author$project$Ports$logErrorCmd(
					$elm$json$Json$Encode$string('PAM.unlinkObject :: ' + 'Could not find child object type')));
		}
	});
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $author$project$Animation$Model$refreshTiming = F2(
	function (now, timing) {
		var dt = $elm$time$Time$posixToMillis(now) - $elm$time$Time$posixToMillis(timing.gQ);
		return {
			gQ: now,
			kH: ((dt > 34) || (!$elm$time$Time$posixToMillis(timing.gQ))) ? $elm$time$Time$millisToPosix(
				$elm$core$Basics$round(16.666)) : $elm$time$Time$millisToPosix(dt)
		};
	});
var $author$project$Animation$Model$Loop = function (a) {
	return {$: 7, a: a};
};
var $author$project$Animation$Model$Repeat = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Animation$Model$Step = {$: 0};
var $author$project$Animation$Model$Wait = function (a) {
	return {$: 4, a: a};
};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Animation$Model$isCmdDone = function (cmd) {
	var motionDone = function (motion) {
		return (!motion.nT) && _Utils_eq(motion.my, motion.np);
	};
	switch (cmd.$) {
		case 0:
			var m1 = cmd.a;
			var m2 = cmd.b;
			return motionDone(m1) && motionDone(m2);
		case 1:
			var m1 = cmd.a;
			var m2 = cmd.b;
			return motionDone(m1) && motionDone(m2);
		case 2:
			var m1 = cmd.a;
			var m2 = cmd.b;
			return motionDone(m1) && motionDone(m2);
		case 3:
			var m1 = cmd.a;
			var m2 = cmd.b;
			return motionDone(m1) && motionDone(m2);
		case 4:
			var motion = cmd.a;
			return motionDone(motion);
		case 5:
			var motion = cmd.a;
			return motionDone(motion);
		case 6:
			var motion = cmd.a;
			return motionDone(motion);
		case 7:
			var motion = cmd.a;
			return motionDone(motion);
		case 8:
			var control1 = cmd.a.cE;
			var control2 = cmd.a.cF;
			var point = cmd.a.aB;
			return motionDone(control1.a) && (motionDone(control1.b) && (motionDone(control2.a) && (motionDone(control2.b) && (motionDone(point.a) && motionDone(point.b)))));
		case 9:
			var control1 = cmd.a.cE;
			var control2 = cmd.a.cF;
			var point = cmd.a.aB;
			return motionDone(control1.a) && (motionDone(control1.b) && (motionDone(control2.a) && (motionDone(control2.b) && (motionDone(point.a) && motionDone(point.b)))));
		case 10:
			var control = cmd.a.cD;
			var point = cmd.a.aB;
			return motionDone(control.a) && (motionDone(control.b) && (motionDone(point.a) && motionDone(point.b)));
		case 11:
			var control = cmd.a.cD;
			var point = cmd.a.aB;
			return motionDone(control.a) && (motionDone(control.b) && (motionDone(point.a) && motionDone(point.b)));
		case 12:
			var coords = cmd.a;
			return A2(
				$elm$core$List$all,
				function (_v1) {
					var x = _v1.a;
					var y = _v1.b;
					return motionDone(x) && motionDone(y);
				},
				coords);
		case 13:
			var coords = cmd.a;
			return A2(
				$elm$core$List$all,
				function (_v2) {
					var x = _v2.a;
					var y = _v2.b;
					return motionDone(x) && motionDone(y);
				},
				coords);
		case 14:
			var coords = cmd.a;
			return A2(
				$elm$core$List$all,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return motionDone(x) && motionDone(y);
				},
				coords);
		case 15:
			var coords = cmd.a;
			return A2(
				$elm$core$List$all,
				function (_v4) {
					var x = _v4.a;
					var y = _v4.b;
					return motionDone(x) && motionDone(y);
				},
				coords);
		case 16:
			var arc = cmd.a;
			return motionDone(arc.dc) && (motionDone(arc.dd) && (motionDone(arc.cV) && (motionDone(arc.c_) && motionDone(arc.cI))));
		case 17:
			var arc = cmd.a;
			return motionDone(arc.dc) && (motionDone(arc.dd) && (motionDone(arc.cV) && (motionDone(arc.c_) && motionDone(arc.cI))));
		default:
			return true;
	}
};
var $author$project$Animation$Model$isDone = function (property) {
	var motionDone = function (motion) {
		var runningInterpolation = A2($elm$core$Maybe$withDefault, motion.b3, motion.lm);
		switch (runningInterpolation.$) {
			case 0:
				return (!motion.nT) && _Utils_eq(motion.my, motion.np);
			case 1:
				var eased = runningInterpolation.a;
				return (eased.im === 1) || ((!eased.im) && _Utils_eq(motion.my, motion.np));
			default:
				var speed = runningInterpolation.a;
				return _Utils_eq(motion.my, motion.np);
		}
	};
	switch (property.$) {
		case 0:
			return true;
		case 1:
			var m1 = property.b;
			var m2 = property.c;
			var m3 = property.d;
			var m4 = property.e;
			return A2(
				$elm$core$List$all,
				motionDone,
				_List_fromArray(
					[m1, m2, m3, m4]));
		case 2:
			var shadow = property.c;
			return A2(
				$elm$core$List$all,
				motionDone,
				_List_fromArray(
					[shadow.ax, shadow.ay, shadow.aD, shadow.as, shadow.ae, shadow.aa, shadow.Y, shadow.X]));
		case 3:
			var m1 = property.b;
			return motionDone(m1);
		case 4:
			var m1 = property.b;
			var m2 = property.c;
			return motionDone(m1) && motionDone(m2);
		case 5:
			var m1 = property.b;
			var m2 = property.c;
			var m3 = property.d;
			return A2(
				$elm$core$List$all,
				motionDone,
				_List_fromArray(
					[m1, m2, m3]));
		case 6:
			var m1 = property.b;
			var m2 = property.c;
			var m3 = property.d;
			var m4 = property.e;
			return A2(
				$elm$core$List$all,
				motionDone,
				_List_fromArray(
					[m1, m2, m3, m4]));
		case 7:
			var m1 = property.b;
			return motionDone(m1);
		case 8:
			var ms = property.a;
			return A2(
				$elm$core$List$all,
				function (_v1) {
					var x = _v1.a;
					var y = _v1.b;
					return motionDone(x) && motionDone(y);
				},
				ms);
		default:
			var cmds = property.a;
			return A2($elm$core$List$all, $author$project$Animation$Model$isCmdDone, cmds);
	}
};
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Animation$Model$matchPoints = F2(
	function (points1, points2) {
		var diff = $elm$core$List$length(points1) - $elm$core$List$length(points2);
		if (diff > 0) {
			var _v0 = $elm$core$List$head(
				$elm$core$List$reverse(points2));
			if (_v0.$ === 1) {
				return _Utils_Tuple2(points1, points2);
			} else {
				var last2 = _v0.a;
				return _Utils_Tuple2(
					points1,
					_Utils_ap(
						points2,
						A2(
							$elm$core$List$repeat,
							$elm$core$Basics$abs(diff),
							last2)));
			}
		} else {
			if (diff < 0) {
				var _v1 = $elm$core$List$head(
					$elm$core$List$reverse(points1));
				if (_v1.$ === 1) {
					return _Utils_Tuple2(points1, points2);
				} else {
					var last1 = _v1.a;
					return _Utils_Tuple2(
						_Utils_ap(
							points1,
							A2(
								$elm$core$List$repeat,
								$elm$core$Basics$abs(diff),
								last1)),
						points2);
				}
			} else {
				return _Utils_Tuple2(points1, points2);
			}
		}
	});
var $author$project$Animation$Model$setPathTarget = F2(
	function (cmd, targetCmd) {
		var setMotionTarget = F2(
			function (motion, targetMotion) {
				var _v27 = motion.b3;
				if (_v27.$ === 1) {
					var ease = _v27.a;
					return _Utils_update(
						motion,
						{
							b3: $author$project$Animation$Model$Easing(
								_Utils_update(
									ease,
									{iT: motion.my})),
							np: targetMotion.my
						});
				} else {
					return _Utils_update(
						motion,
						{np: targetMotion.my});
				}
			});
		switch (cmd.$) {
			case 0:
				var m1 = cmd.a;
				var m2 = cmd.b;
				if (!targetCmd.$) {
					var t1 = targetCmd.a;
					var t2 = targetCmd.b;
					return A2(
						$author$project$Animation$Model$Move,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return cmd;
				}
			case 1:
				var m1 = cmd.a;
				var m2 = cmd.b;
				if (targetCmd.$ === 1) {
					var t1 = targetCmd.a;
					var t2 = targetCmd.b;
					return A2(
						$author$project$Animation$Model$MoveTo,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return cmd;
				}
			case 2:
				var m1 = cmd.a;
				var m2 = cmd.b;
				if (targetCmd.$ === 2) {
					var t1 = targetCmd.a;
					var t2 = targetCmd.b;
					return A2(
						$author$project$Animation$Model$Line,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return cmd;
				}
			case 3:
				var m1 = cmd.a;
				var m2 = cmd.b;
				if (targetCmd.$ === 3) {
					var t1 = targetCmd.a;
					var t2 = targetCmd.b;
					return A2(
						$author$project$Animation$Model$LineTo,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return cmd;
				}
			case 4:
				var m1 = cmd.a;
				if (targetCmd.$ === 4) {
					var t1 = targetCmd.a;
					return $author$project$Animation$Model$Horizontal(
						A2(setMotionTarget, m1, t1));
				} else {
					return cmd;
				}
			case 5:
				var m1 = cmd.a;
				if (targetCmd.$ === 5) {
					var t1 = targetCmd.a;
					return $author$project$Animation$Model$HorizontalTo(
						A2(setMotionTarget, m1, t1));
				} else {
					return cmd;
				}
			case 6:
				var m1 = cmd.a;
				if (targetCmd.$ === 6) {
					var t1 = targetCmd.a;
					return $author$project$Animation$Model$Vertical(
						A2(setMotionTarget, m1, t1));
				} else {
					return cmd;
				}
			case 7:
				var m1 = cmd.a;
				if (targetCmd.$ === 7) {
					var t1 = targetCmd.a;
					return $author$project$Animation$Model$VerticalTo(
						A2(setMotionTarget, m1, t1));
				} else {
					return cmd;
				}
			case 8:
				var points = cmd.a;
				if (targetCmd.$ === 8) {
					var targets = targetCmd.a;
					return $author$project$Animation$Model$Curve(
						{
							cE: _Utils_Tuple2(
								A2(setMotionTarget, points.cE.a, targets.cE.a),
								A2(setMotionTarget, points.cE.b, targets.cE.b)),
							cF: _Utils_Tuple2(
								A2(setMotionTarget, points.cF.a, targets.cF.a),
								A2(setMotionTarget, points.cF.b, targets.cF.b)),
							aB: _Utils_Tuple2(
								A2(setMotionTarget, points.aB.a, targets.aB.a),
								A2(setMotionTarget, points.aB.b, targets.aB.b))
						});
				} else {
					return cmd;
				}
			case 9:
				var points = cmd.a;
				if (targetCmd.$ === 9) {
					var targets = targetCmd.a;
					return $author$project$Animation$Model$CurveTo(
						{
							cE: _Utils_Tuple2(
								A2(setMotionTarget, points.cE.a, targets.cE.a),
								A2(setMotionTarget, points.cE.b, targets.cE.b)),
							cF: _Utils_Tuple2(
								A2(setMotionTarget, points.cF.a, targets.cF.a),
								A2(setMotionTarget, points.cF.b, targets.cF.b)),
							aB: _Utils_Tuple2(
								A2(setMotionTarget, points.aB.a, targets.aB.a),
								A2(setMotionTarget, points.aB.b, targets.aB.b))
						});
				} else {
					return cmd;
				}
			case 10:
				var points = cmd.a;
				if (targetCmd.$ === 10) {
					var targets = targetCmd.a;
					return $author$project$Animation$Model$Quadratic(
						{
							cD: _Utils_Tuple2(
								A2(setMotionTarget, points.cD.a, targets.cD.a),
								A2(setMotionTarget, points.cD.b, targets.cD.b)),
							aB: _Utils_Tuple2(
								A2(setMotionTarget, points.aB.a, targets.aB.a),
								A2(setMotionTarget, points.aB.b, targets.aB.b))
						});
				} else {
					return cmd;
				}
			case 11:
				var points = cmd.a;
				if (targetCmd.$ === 11) {
					var targets = targetCmd.a;
					return $author$project$Animation$Model$QuadraticTo(
						{
							cD: _Utils_Tuple2(
								A2(setMotionTarget, points.cD.a, targets.cD.a),
								A2(setMotionTarget, points.cD.b, targets.cD.b)),
							aB: _Utils_Tuple2(
								A2(setMotionTarget, points.aB.a, targets.aB.a),
								A2(setMotionTarget, points.aB.b, targets.aB.b))
						});
				} else {
					return cmd;
				}
			case 12:
				var coords = cmd.a;
				if (targetCmd.$ === 12) {
					var targetCoords = targetCmd.a;
					return $author$project$Animation$Model$SmoothQuadratic(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v14, _v15) {
									var x1 = _v14.a;
									var y1 = _v14.b;
									var x2 = _v15.a;
									var y2 = _v15.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							coords,
							targetCoords));
				} else {
					return cmd;
				}
			case 13:
				var coords = cmd.a;
				if (targetCmd.$ === 13) {
					var targetCoords = targetCmd.a;
					return $author$project$Animation$Model$SmoothQuadraticTo(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v17, _v18) {
									var x1 = _v17.a;
									var y1 = _v17.b;
									var x2 = _v18.a;
									var y2 = _v18.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							coords,
							targetCoords));
				} else {
					return cmd;
				}
			case 14:
				var coords = cmd.a;
				if (targetCmd.$ === 14) {
					var targetCoords = targetCmd.a;
					return $author$project$Animation$Model$Smooth(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v20, _v21) {
									var x1 = _v20.a;
									var y1 = _v20.b;
									var x2 = _v21.a;
									var y2 = _v21.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							coords,
							targetCoords));
				} else {
					return cmd;
				}
			case 15:
				var coords = cmd.a;
				if (targetCmd.$ === 15) {
					var targetCoords = targetCmd.a;
					return $author$project$Animation$Model$SmoothTo(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v23, _v24) {
									var x1 = _v23.a;
									var y1 = _v23.b;
									var x2 = _v24.a;
									var y2 = _v24.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							coords,
							targetCoords));
				} else {
					return cmd;
				}
			case 16:
				var arc = cmd.a;
				if (targetCmd.$ === 16) {
					var target = targetCmd.a;
					return $author$project$Animation$Model$ClockwiseArc(
						function () {
							var y = arc.dd;
							var x = arc.dc;
							var startAngle = arc.c_;
							var radius = arc.cV;
							var endAngle = arc.cI;
							return _Utils_update(
								arc,
								{
									cI: A2(setMotionTarget, endAngle, target.cI),
									cV: A2(setMotionTarget, radius, target.cV),
									c_: A2(setMotionTarget, startAngle, target.c_),
									dc: A2(setMotionTarget, x, target.dc),
									dd: A2(setMotionTarget, y, target.dd)
								});
						}());
				} else {
					return cmd;
				}
			case 17:
				var arc = cmd.a;
				if (targetCmd.$ === 17) {
					var target = targetCmd.a;
					return $author$project$Animation$Model$AntiClockwiseArc(
						function () {
							var y = arc.dd;
							var x = arc.dc;
							var startAngle = arc.c_;
							var radius = arc.cV;
							var endAngle = arc.cI;
							return _Utils_update(
								arc,
								{
									cI: A2(setMotionTarget, endAngle, target.cI),
									cV: A2(setMotionTarget, radius, target.cV),
									c_: A2(setMotionTarget, startAngle, target.c_),
									dc: A2(setMotionTarget, x, target.dc),
									dd: A2(setMotionTarget, y, target.dd)
								});
						}());
				} else {
					return cmd;
				}
			default:
				return $author$project$Animation$Model$Close;
		}
	});
var $author$project$Animation$Model$setTarget = F3(
	function (overrideInterpolation, current, newTarget) {
		var setMotionTarget = F2(
			function (motion, targetMotion) {
				var newMotion = overrideInterpolation ? _Utils_update(
					motion,
					{
						lm: $elm$core$Maybe$Just(targetMotion.b3)
					}) : motion;
				var _v13 = newMotion.lm;
				if (_v13.$ === 1) {
					var _v14 = newMotion.b3;
					if (_v14.$ === 1) {
						var ease = _v14.a;
						return _Utils_update(
							newMotion,
							{
								b3: $author$project$Animation$Model$Easing(
									_Utils_update(
										ease,
										{im: 0, iT: motion.my})),
								np: targetMotion.my
							});
					} else {
						return _Utils_update(
							newMotion,
							{np: targetMotion.my});
					}
				} else {
					var override = _v13.a;
					if (override.$ === 1) {
						var ease = override.a;
						return _Utils_update(
							newMotion,
							{
								lm: $elm$core$Maybe$Just(
									$author$project$Animation$Model$Easing(
										_Utils_update(
											ease,
											{im: 0, iT: motion.my}))),
								np: targetMotion.my
							});
					} else {
						return _Utils_update(
							newMotion,
							{np: targetMotion.my});
					}
				}
			});
		switch (current.$) {
			case 0:
				var name = current.a;
				var value = current.b;
				return A2($author$project$Animation$Model$ExactProperty, name, value);
			case 1:
				var name = current.a;
				var m1 = current.b;
				var m2 = current.c;
				var m3 = current.d;
				var m4 = current.e;
				if (newTarget.$ === 1) {
					var t1 = newTarget.b;
					var t2 = newTarget.c;
					var t3 = newTarget.d;
					var t4 = newTarget.e;
					return A5(
						$author$project$Animation$Model$ColorProperty,
						name,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2),
						A2(setMotionTarget, m3, t3),
						A2(setMotionTarget, m4, t4));
				} else {
					return current;
				}
			case 2:
				var name = current.a;
				var inset = current.b;
				var shadow = current.c;
				if (newTarget.$ === 2) {
					var targetShadow = newTarget.c;
					return A3(
						$author$project$Animation$Model$ShadowProperty,
						name,
						inset,
						{
							X: A2(setMotionTarget, shadow.X, targetShadow.X),
							Y: A2(setMotionTarget, shadow.Y, targetShadow.Y),
							as: A2(setMotionTarget, shadow.as, targetShadow.as),
							aa: A2(setMotionTarget, shadow.aa, targetShadow.aa),
							ax: A2(setMotionTarget, shadow.ax, targetShadow.ax),
							ay: A2(setMotionTarget, shadow.ay, targetShadow.ay),
							ae: A2(setMotionTarget, shadow.ae, targetShadow.ae),
							aD: A2(setMotionTarget, shadow.aD, targetShadow.aD)
						});
				} else {
					return current;
				}
			case 3:
				var name = current.a;
				var m1 = current.b;
				if (newTarget.$ === 3) {
					var t1 = newTarget.b;
					return A2(
						$author$project$Animation$Model$Property,
						name,
						A2(setMotionTarget, m1, t1));
				} else {
					return current;
				}
			case 4:
				var name = current.a;
				var m1 = current.b;
				var m2 = current.c;
				if (newTarget.$ === 4) {
					var t1 = newTarget.b;
					var t2 = newTarget.c;
					return A3(
						$author$project$Animation$Model$Property2,
						name,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return current;
				}
			case 5:
				var name = current.a;
				var m1 = current.b;
				var m2 = current.c;
				var m3 = current.d;
				if (newTarget.$ === 5) {
					var t1 = newTarget.b;
					var t2 = newTarget.c;
					var t3 = newTarget.d;
					return A4(
						$author$project$Animation$Model$Property3,
						name,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2),
						A2(setMotionTarget, m3, t3));
				} else {
					return current;
				}
			case 6:
				var name = current.a;
				var m1 = current.b;
				var m2 = current.c;
				var m3 = current.d;
				var m4 = current.e;
				if (newTarget.$ === 6) {
					var t1 = newTarget.b;
					var t2 = newTarget.c;
					var t3 = newTarget.d;
					var t4 = newTarget.e;
					return A5(
						$author$project$Animation$Model$Property4,
						name,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2),
						A2(setMotionTarget, m3, t3),
						A2(setMotionTarget, m4, t4));
				} else {
					return current;
				}
			case 7:
				var name = current.a;
				var m1 = current.b;
				if (newTarget.$ === 7) {
					var t1 = newTarget.b;
					return A2(
						$author$project$Animation$Model$AngleProperty,
						name,
						A2(setMotionTarget, m1, t1));
				} else {
					return current;
				}
			case 8:
				var currentPts = current.a;
				if (newTarget.$ === 8) {
					var targetPts = newTarget.a;
					var _v9 = A2($author$project$Animation$Model$matchPoints, currentPts, targetPts);
					var m1s = _v9.a;
					var m2s = _v9.b;
					return $author$project$Animation$Model$Points(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v10, _v11) {
									var x1 = _v10.a;
									var y1 = _v10.b;
									var x2 = _v11.a;
									var y2 = _v11.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							m1s,
							m2s));
				} else {
					return current;
				}
			default:
				var cmds = current.a;
				if (newTarget.$ === 9) {
					var targets = newTarget.a;
					return $author$project$Animation$Model$Path(
						A3($elm$core$List$map2, $author$project$Animation$Model$setPathTarget, cmds, targets));
				} else {
					return current;
				}
		}
	});
var $author$project$Animation$Model$zipPropertiesGreedy = F2(
	function (initialProps, newTargetProps) {
		var propertyMatch = F2(
			function (prop1, prop2) {
				return _Utils_eq(
					$author$project$Animation$Model$propertyName(prop1),
					$author$project$Animation$Model$propertyName(prop2));
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, _v2) {
					var stackA = _v2.a;
					var stackB = _v2.b;
					var result = _v2.c;
					var _v3 = $elm$core$List$head(stackA);
					if (_v3.$ === 1) {
						return _Utils_Tuple3(stackA, stackB, result);
					} else {
						var a = _v3.a;
						var _v4 = A2(
							$elm$core$List$partition,
							propertyMatch(a),
							stackB);
						var matchingBs = _v4.a;
						var nonMatchingBs = _v4.b;
						return _Utils_Tuple3(
							A2($elm$core$List$drop, 1, stackA),
							function () {
								if (!matchingBs.b) {
									return nonMatchingBs;
								} else {
									var b = matchingBs.a;
									var remainingBs = matchingBs.b;
									return _Utils_ap(remainingBs, nonMatchingBs);
								}
							}(),
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									a,
									$elm$core$List$head(matchingBs)),
								result));
					}
				}),
			_Utils_Tuple3(initialProps, newTargetProps, _List_Nil),
			A2(
				$elm$core$List$repeat,
				$elm$core$List$length(initialProps),
				0));
		var warnings = _v0.b;
		var props = _v0.c;
		var _v6 = warnings;
		return $elm$core$List$reverse(props);
	});
var $author$project$Animation$Model$startTowards = F3(
	function (overrideInterpolation, current, target) {
		return A2(
			$elm$core$List$filterMap,
			function (propPair) {
				if (!propPair.b.$) {
					var cur = propPair.a;
					var to = propPair.b.a;
					return $elm$core$Maybe$Just(
						A3($author$project$Animation$Model$setTarget, overrideInterpolation, cur, to));
				} else {
					var prop = propPair.a;
					var _v1 = propPair.b;
					return $elm$core$Maybe$Just(prop);
				}
			},
			A2($author$project$Animation$Model$zipPropertiesGreedy, current, target));
	});
var $author$project$Animation$Model$tolerance = 0.01;
var $elm$core$Basics$truncate = _Basics_truncate;
var $author$project$Animation$Model$vTolerance = 0.1;
var $author$project$Animation$Model$stepInterpolation = F2(
	function (posix, motion) {
		var interpolationToUse = A2($elm$core$Maybe$withDefault, motion.b3, motion.lm);
		var dtms = $elm$time$Time$posixToMillis(posix);
		switch (interpolationToUse.$) {
			case 2:
				var perSecond = interpolationToUse.a.ic;
				var _v1 = function () {
					if (_Utils_cmp(motion.my, motion.np) < 0) {
						var _new = motion.my + (perSecond * (dtms / 1000));
						return _Utils_Tuple2(
							_new,
							_Utils_cmp(_new, motion.np) > -1);
					} else {
						var _new = motion.my - (perSecond * (dtms / 1000));
						return _Utils_Tuple2(
							_new,
							_Utils_cmp(_new, motion.np) < 1);
					}
				}();
				var newPos = _v1.a;
				var finished = _v1.b;
				return finished ? _Utils_update(
					motion,
					{my: motion.np, nT: 0.0}) : _Utils_update(
					motion,
					{my: newPos, nT: perSecond * 1000});
			case 0:
				var stiffness = interpolationToUse.a.iU;
				var damping = interpolationToUse.a.gR;
				var fspring = stiffness * (motion.np - motion.my);
				var fdamper = ((-1) * damping) * motion.nT;
				var dt = dtms / 1000;
				var a = fspring + fdamper;
				var newVelocity = motion.nT + (a * dt);
				var newPos = motion.my + (newVelocity * dt);
				var dx = $elm$core$Basics$abs(motion.np - newPos);
				return ((_Utils_cmp(dx, $author$project$Animation$Model$tolerance) < 0) && (_Utils_cmp(
					$elm$core$Basics$abs(newVelocity),
					$author$project$Animation$Model$vTolerance) < 0)) ? _Utils_update(
					motion,
					{my: motion.np, nT: 0.0}) : _Utils_update(
					motion,
					{my: newPos, nT: newVelocity});
			default:
				var progress = interpolationToUse.a.im;
				var duration = interpolationToUse.a.eZ;
				var ease = interpolationToUse.a.e_;
				var start = interpolationToUse.a.iT;
				var durationMs = $elm$time$Time$posixToMillis(duration);
				var newProgress = (((dtms / durationMs) + progress) < 1) ? ((dtms / durationMs) + progress) : 1;
				var eased = ease(newProgress);
				var distance = motion.np - start;
				var newPos = ((((eased * distance) + start) * 10000) | 0) / 10000;
				var newVelocity = (newProgress === 1) ? 0 : ((newPos - motion.my) / dtms);
				var _v2 = motion.lm;
				if (_v2.$ === 1) {
					return _Utils_update(
						motion,
						{
							b3: $author$project$Animation$Model$Easing(
								{eZ: duration, e_: ease, im: newProgress, iT: start}),
							my: newPos,
							nT: newVelocity
						});
				} else {
					var override = _v2.a;
					return _Utils_update(
						motion,
						{
							lm: $elm$core$Maybe$Just(
								$author$project$Animation$Model$Easing(
									{eZ: duration, e_: ease, im: newProgress, iT: start})),
							my: newPos,
							nT: newVelocity
						});
				}
		}
	});
var $author$project$Animation$Model$stepPath = F2(
	function (dt, cmd) {
		var stepCoords = function (coords) {
			return A2(
				$elm$core$List$map,
				function (_v1) {
					var x = _v1.a;
					var y = _v1.b;
					return _Utils_Tuple2(
						A2($author$project$Animation$Model$stepInterpolation, dt, x),
						A2($author$project$Animation$Model$stepInterpolation, dt, y));
				},
				coords);
		};
		switch (cmd.$) {
			case 0:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$author$project$Animation$Model$Move,
					A2($author$project$Animation$Model$stepInterpolation, dt, m1),
					A2($author$project$Animation$Model$stepInterpolation, dt, m2));
			case 1:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$author$project$Animation$Model$MoveTo,
					A2($author$project$Animation$Model$stepInterpolation, dt, m1),
					A2($author$project$Animation$Model$stepInterpolation, dt, m2));
			case 2:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$author$project$Animation$Model$Line,
					A2($author$project$Animation$Model$stepInterpolation, dt, m1),
					A2($author$project$Animation$Model$stepInterpolation, dt, m2));
			case 3:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$author$project$Animation$Model$LineTo,
					A2($author$project$Animation$Model$stepInterpolation, dt, m1),
					A2($author$project$Animation$Model$stepInterpolation, dt, m2));
			case 4:
				var motion = cmd.a;
				return $author$project$Animation$Model$Horizontal(
					A2($author$project$Animation$Model$stepInterpolation, dt, motion));
			case 5:
				var motion = cmd.a;
				return $author$project$Animation$Model$HorizontalTo(
					A2($author$project$Animation$Model$stepInterpolation, dt, motion));
			case 6:
				var motion = cmd.a;
				return $author$project$Animation$Model$Vertical(
					A2($author$project$Animation$Model$stepInterpolation, dt, motion));
			case 7:
				var motion = cmd.a;
				return $author$project$Animation$Model$VerticalTo(
					A2($author$project$Animation$Model$stepInterpolation, dt, motion));
			case 8:
				var control1 = cmd.a.cE;
				var control2 = cmd.a.cF;
				var point = cmd.a.aB;
				return $author$project$Animation$Model$Curve(
					{
						cE: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, control1.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, control1.b)),
						cF: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, control2.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, control2.b)),
						aB: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, point.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, point.b))
					});
			case 9:
				var control1 = cmd.a.cE;
				var control2 = cmd.a.cF;
				var point = cmd.a.aB;
				return $author$project$Animation$Model$CurveTo(
					{
						cE: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, control1.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, control1.b)),
						cF: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, control2.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, control2.b)),
						aB: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, point.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, point.b))
					});
			case 10:
				var control = cmd.a.cD;
				var point = cmd.a.aB;
				return $author$project$Animation$Model$Quadratic(
					{
						cD: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, control.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, control.b)),
						aB: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, point.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, point.b))
					});
			case 11:
				var control = cmd.a.cD;
				var point = cmd.a.aB;
				return $author$project$Animation$Model$QuadraticTo(
					{
						cD: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, control.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, control.b)),
						aB: _Utils_Tuple2(
							A2($author$project$Animation$Model$stepInterpolation, dt, point.a),
							A2($author$project$Animation$Model$stepInterpolation, dt, point.b))
					});
			case 12:
				var coords = cmd.a;
				return $author$project$Animation$Model$SmoothQuadratic(
					stepCoords(coords));
			case 13:
				var coords = cmd.a;
				return $author$project$Animation$Model$SmoothQuadraticTo(
					stepCoords(coords));
			case 14:
				var coords = cmd.a;
				return $author$project$Animation$Model$Smooth(
					stepCoords(coords));
			case 15:
				var coords = cmd.a;
				return $author$project$Animation$Model$SmoothTo(
					stepCoords(coords));
			case 16:
				var arc = cmd.a;
				return $author$project$Animation$Model$ClockwiseArc(
					_Utils_update(
						arc,
						{
							cI: A2($author$project$Animation$Model$stepInterpolation, dt, arc.cI),
							cV: A2($author$project$Animation$Model$stepInterpolation, dt, arc.cV),
							c_: A2($author$project$Animation$Model$stepInterpolation, dt, arc.c_),
							dc: A2($author$project$Animation$Model$stepInterpolation, dt, arc.dc),
							dd: A2($author$project$Animation$Model$stepInterpolation, dt, arc.dd)
						}));
			case 17:
				var arc = cmd.a;
				return $author$project$Animation$Model$AntiClockwiseArc(
					_Utils_update(
						arc,
						{
							cI: A2($author$project$Animation$Model$stepInterpolation, dt, arc.cI),
							cV: A2($author$project$Animation$Model$stepInterpolation, dt, arc.cV),
							c_: A2($author$project$Animation$Model$stepInterpolation, dt, arc.c_),
							dc: A2($author$project$Animation$Model$stepInterpolation, dt, arc.dc),
							dd: A2($author$project$Animation$Model$stepInterpolation, dt, arc.dd)
						}));
			default:
				return $author$project$Animation$Model$Close;
		}
	});
var $author$project$Animation$Model$step = F2(
	function (dt, props) {
		var stepProp = function (property) {
			switch (property.$) {
				case 0:
					var name = property.a;
					var value = property.b;
					return A2($author$project$Animation$Model$ExactProperty, name, value);
				case 3:
					var name = property.a;
					var motion = property.b;
					return A2(
						$author$project$Animation$Model$Property,
						name,
						A2($author$project$Animation$Model$stepInterpolation, dt, motion));
				case 4:
					var name = property.a;
					var motion1 = property.b;
					var motion2 = property.c;
					return A3(
						$author$project$Animation$Model$Property2,
						name,
						A2($author$project$Animation$Model$stepInterpolation, dt, motion1),
						A2($author$project$Animation$Model$stepInterpolation, dt, motion2));
				case 5:
					var name = property.a;
					var motion1 = property.b;
					var motion2 = property.c;
					var motion3 = property.d;
					return A4(
						$author$project$Animation$Model$Property3,
						name,
						A2($author$project$Animation$Model$stepInterpolation, dt, motion1),
						A2($author$project$Animation$Model$stepInterpolation, dt, motion2),
						A2($author$project$Animation$Model$stepInterpolation, dt, motion3));
				case 6:
					var name = property.a;
					var motion1 = property.b;
					var motion2 = property.c;
					var motion3 = property.d;
					var motion4 = property.e;
					return A5(
						$author$project$Animation$Model$Property4,
						name,
						A2($author$project$Animation$Model$stepInterpolation, dt, motion1),
						A2($author$project$Animation$Model$stepInterpolation, dt, motion2),
						A2($author$project$Animation$Model$stepInterpolation, dt, motion3),
						A2($author$project$Animation$Model$stepInterpolation, dt, motion4));
				case 7:
					var name = property.a;
					var motion = property.b;
					return A2(
						$author$project$Animation$Model$AngleProperty,
						name,
						A2($author$project$Animation$Model$stepInterpolation, dt, motion));
				case 1:
					var name = property.a;
					var red = property.b;
					var green = property.c;
					var blue = property.d;
					var alpha = property.e;
					return A5(
						$author$project$Animation$Model$ColorProperty,
						name,
						A2($author$project$Animation$Model$stepInterpolation, dt, red),
						A2($author$project$Animation$Model$stepInterpolation, dt, green),
						A2($author$project$Animation$Model$stepInterpolation, dt, blue),
						A2($author$project$Animation$Model$stepInterpolation, dt, alpha));
				case 2:
					var name = property.a;
					var inset = property.b;
					var shadow = property.c;
					return A3(
						$author$project$Animation$Model$ShadowProperty,
						name,
						inset,
						{
							X: A2($author$project$Animation$Model$stepInterpolation, dt, shadow.X),
							Y: A2($author$project$Animation$Model$stepInterpolation, dt, shadow.Y),
							as: A2($author$project$Animation$Model$stepInterpolation, dt, shadow.as),
							aa: A2($author$project$Animation$Model$stepInterpolation, dt, shadow.aa),
							ax: A2($author$project$Animation$Model$stepInterpolation, dt, shadow.ax),
							ay: A2($author$project$Animation$Model$stepInterpolation, dt, shadow.ay),
							ae: A2($author$project$Animation$Model$stepInterpolation, dt, shadow.ae),
							aD: A2($author$project$Animation$Model$stepInterpolation, dt, shadow.aD)
						});
				case 8:
					var points = property.a;
					return $author$project$Animation$Model$Points(
						A2(
							$elm$core$List$map,
							function (_v1) {
								var x = _v1.a;
								var y = _v1.b;
								return _Utils_Tuple2(
									A2($author$project$Animation$Model$stepInterpolation, dt, x),
									A2($author$project$Animation$Model$stepInterpolation, dt, y));
							},
							points));
				default:
					var cmds = property.a;
					return $author$project$Animation$Model$Path(
						A2(
							$elm$core$List$map,
							$author$project$Animation$Model$stepPath(dt),
							cmds));
			}
		};
		return A2($elm$core$List$map, stepProp, props);
	});
var $author$project$Animation$Model$alreadyThere = F2(
	function (current, target) {
		return A2(
			$elm$core$List$all,
			$author$project$Animation$Model$isDone,
			A2(
				$author$project$Animation$Model$step,
				$elm$time$Time$millisToPosix(0),
				A3($author$project$Animation$Model$startTowards, false, current, target)));
	});
var $author$project$Animation$Model$replaceProps = F2(
	function (props, replacements) {
		var replacementNames = A2($elm$core$List$map, $author$project$Animation$Model$propertyName, replacements);
		var removed = A2(
			$elm$core$List$filter,
			function (prop) {
				return !A2(
					$elm$core$List$member,
					$author$project$Animation$Model$propertyName(prop),
					replacementNames);
			},
			props);
		return _Utils_ap(removed, replacements);
	});
var $author$project$Animation$Model$resolveSteps = F3(
	function (currentStyle, steps, dt) {
		resolveSteps:
		while (true) {
			var _v0 = $elm$core$List$head(steps);
			if (_v0.$ === 1) {
				return _Utils_Tuple3(currentStyle, _List_Nil, _List_Nil);
			} else {
				var currentStep = _v0.a;
				switch (currentStep.$) {
					case 4:
						var n = currentStep.a;
						if ($elm$time$Time$posixToMillis(n) <= 0) {
							var $temp$currentStyle = currentStyle,
								$temp$steps = A2($elm$core$List$drop, 1, steps),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						} else {
							return _Utils_Tuple3(
								currentStyle,
								_List_Nil,
								A2(
									$elm$core$List$cons,
									$author$project$Animation$Model$Wait(
										$elm$time$Time$millisToPosix(
											$elm$time$Time$posixToMillis(n) - $elm$time$Time$posixToMillis(dt))),
									A2($elm$core$List$drop, 1, steps)));
						}
					case 5:
						var msg = currentStep.a;
						var _v2 = A3(
							$author$project$Animation$Model$resolveSteps,
							currentStyle,
							A2($elm$core$List$drop, 1, steps),
							dt);
						var newStyle = _v2.a;
						var msgs = _v2.b;
						var remainingSteps = _v2.c;
						return _Utils_Tuple3(
							newStyle,
							A2($elm$core$List$cons, msg, msgs),
							remainingSteps);
					case 1:
						var target = currentStep.a;
						if (A2($author$project$Animation$Model$alreadyThere, currentStyle, target)) {
							return _Utils_Tuple3(
								currentStyle,
								_List_Nil,
								A2($elm$core$List$drop, 1, steps));
						} else {
							var $temp$currentStyle = A3($author$project$Animation$Model$startTowards, false, currentStyle, target),
								$temp$steps = A2(
								$elm$core$List$cons,
								$author$project$Animation$Model$Step,
								A2($elm$core$List$drop, 1, steps)),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						}
					case 2:
						var target = currentStep.a;
						if (A2($author$project$Animation$Model$alreadyThere, currentStyle, target)) {
							return _Utils_Tuple3(
								currentStyle,
								_List_Nil,
								A2($elm$core$List$drop, 1, steps));
						} else {
							var $temp$currentStyle = A3($author$project$Animation$Model$startTowards, true, currentStyle, target),
								$temp$steps = A2(
								$elm$core$List$cons,
								$author$project$Animation$Model$Step,
								A2($elm$core$List$drop, 1, steps)),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						}
					case 3:
						var props = currentStep.a;
						var $temp$currentStyle = A2($author$project$Animation$Model$replaceProps, currentStyle, props),
							$temp$steps = A2($elm$core$List$drop, 1, steps),
							$temp$dt = dt;
						currentStyle = $temp$currentStyle;
						steps = $temp$steps;
						dt = $temp$dt;
						continue resolveSteps;
					case 0:
						var stepped = A2($author$project$Animation$Model$step, dt, currentStyle);
						return A2($elm$core$List$all, $author$project$Animation$Model$isDone, stepped) ? _Utils_Tuple3(
							A2(
								$elm$core$List$map,
								$author$project$Animation$Model$mapToMotion(
									function (m) {
										return _Utils_update(
											m,
											{lm: $elm$core$Maybe$Nothing});
									}),
								stepped),
							_List_Nil,
							A2($elm$core$List$drop, 1, steps)) : _Utils_Tuple3(stepped, _List_Nil, steps);
					case 7:
						var substeps = currentStep.a;
						var $temp$currentStyle = currentStyle,
							$temp$steps = _Utils_ap(
							substeps,
							_List_fromArray(
								[
									$author$project$Animation$Model$Loop(substeps)
								])),
							$temp$dt = dt;
						currentStyle = $temp$currentStyle;
						steps = $temp$steps;
						dt = $temp$dt;
						continue resolveSteps;
					default:
						var n = currentStep.a;
						var substeps = currentStep.b;
						if (n <= 0) {
							var $temp$currentStyle = currentStyle,
								$temp$steps = A2($elm$core$List$drop, 1, steps),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						} else {
							var $temp$currentStyle = currentStyle,
								$temp$steps = _Utils_ap(
								substeps,
								_Utils_ap(
									_List_fromArray(
										[
											A2($author$project$Animation$Model$Repeat, n - 1, substeps)
										]),
									A2($elm$core$List$drop, 1, steps))),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						}
				}
			}
		}
	});
var $author$project$Animation$Model$updateAnimation = F2(
	function (_v0, _v1) {
		var now = _v0;
		var model = _v1;
		var timing = A2($author$project$Animation$Model$refreshTiming, now, model.i$);
		var _v2 = A2(
			$elm$core$List$partition,
			function (_v4) {
				var wait = _v4.a;
				var mySteps = _v4.b;
				return $elm$time$Time$posixToMillis(wait) <= 0;
			},
			A2(
				$elm$core$List$map,
				function (_v3) {
					var wait = _v3.a;
					var mySteps = _v3.b;
					return _Utils_Tuple2(
						$elm$time$Time$millisToPosix(
							$elm$time$Time$posixToMillis(wait) - $elm$time$Time$posixToMillis(timing.kH)),
						mySteps);
				},
				model.fh));
		var readyInterruption = _v2.a;
		var queuedInterruptions = _v2.b;
		var _v5 = function () {
			var _v6 = $elm$core$List$head(readyInterruption);
			if (!_v6.$) {
				var _v7 = _v6.a;
				var wait = _v7.a;
				var interrupt = _v7.b;
				return _Utils_Tuple2(
					interrupt,
					A2(
						$elm$core$List$map,
						$author$project$Animation$Model$mapToMotion(
							function (m) {
								return _Utils_update(
									m,
									{lm: $elm$core$Maybe$Nothing});
							}),
						model.iW));
			} else {
				return _Utils_Tuple2(model.fS, model.iW);
			}
		}();
		var steps = _v5.a;
		var style = _v5.b;
		var _v8 = A3($author$project$Animation$Model$resolveSteps, style, steps, timing.kH);
		var revisedStyle = _v8.a;
		var sentMessages = _v8.b;
		var revisedSteps = _v8.c;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					fh: queuedInterruptions,
					ex: (!(!$elm$core$List$length(revisedSteps))) || (!(!$elm$core$List$length(queuedInterruptions))),
					fS: revisedSteps,
					iW: revisedStyle,
					i$: timing
				}),
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					function (m) {
						return A2(
							$elm$core$Task$perform,
							$elm$core$Basics$identity,
							$elm$core$Task$succeed(m));
					},
					sentMessages)));
	});
var $author$project$Animation$update = F2(
	function (tick, animation) {
		return A2($author$project$Animation$Model$updateAnimation, tick, animation).a;
	});
var $author$project$Grid$Grid$ClosePageSizeDropdown = {$: 6};
var $author$project$Grid$Grid$filterTextChange = F2(
	function (model, text) {
		var newSearch = ($elm$core$String$length(text) > 0) ? $elm$core$Maybe$Just(text) : $elm$core$Maybe$Nothing;
		var newModel = _Utils_update(
			model,
			{al: 0, cY: newSearch});
		var newCmd = $elm$core$Platform$Cmd$none;
		return _Utils_Tuple2(newModel, newCmd);
	});
var $author$project$Grid$Grid$NoOp = {$: 0};
var $author$project$Grid$Grid$inputIds = {hc: 'filter_button', dS: 'filter_options', hd: 'filter_search', h9: 'page_size_button', eo: 'page_size_options', iL: 'show_hide_button', m7: 'show_hide_options'};
var $author$project$Grid$Grid$blurFilterOptions = function (model) {
	var _v0 = model.aL;
	if (!_v0) {
		return A2(
			$elm$core$Task$attempt,
			function (_v1) {
				return $author$project$Grid$Grid$NoOp;
			},
			$elm$browser$Browser$Dom$blur($author$project$Grid$Grid$inputIds.dS));
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Grid$Grid$filterTypeChange = F2(
	function (model, column) {
		var newColumn = (column.F === 'All') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(column);
		var newModel = _Utils_update(
			model,
			{cK: newColumn, aL: 1});
		var newCmd = $author$project$Grid$Grid$blurFilterOptions(model);
		return _Utils_Tuple2(newModel, newCmd);
	});
var $author$project$Grid$Grid$focusFilterOptions = function (model) {
	var _v0 = model.aL;
	if (!_v0) {
		return $elm$core$Platform$Cmd$none;
	} else {
		return A2(
			$elm$core$Task$attempt,
			function (_v1) {
				return $author$project$Grid$Grid$NoOp;
			},
			$elm$browser$Browser$Dom$focus($author$project$Grid$Grid$inputIds.dS));
	}
};
var $author$project$Grid$Grid$focusPageSizeOptions = function (model) {
	var _v0 = model.a7;
	if (!_v0) {
		return $elm$core$Platform$Cmd$none;
	} else {
		return A2(
			$elm$core$Task$attempt,
			function (_v1) {
				return $author$project$Grid$Grid$NoOp;
			},
			$elm$browser$Browser$Dom$focus($author$project$Grid$Grid$inputIds.eo));
	}
};
var $author$project$Grid$Grid$gotoPage = F2(
	function (model, page) {
		var newModel = _Utils_update(
			model,
			{al: page});
		var newCmds = $elm$core$Platform$Cmd$none;
		return _Utils_Tuple2(newModel, newCmds);
	});
var $author$project$Grid$Grid$blurPageSizeOptions = function (model) {
	var _v0 = model.a7;
	if (!_v0) {
		return A2(
			$elm$core$Task$attempt,
			function (_v1) {
				return $author$project$Grid$Grid$NoOp;
			},
			$elm$browser$Browser$Dom$blur($author$project$Grid$Grid$inputIds.eo));
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Grid$Grid$handlePageSizeChanged = F2(
	function (model, newSize) {
		var newModel = _Utils_update(
			model,
			{am: newSize});
		var newCmds = $author$project$Grid$Grid$blurPageSizeOptions(model);
		return _Utils_Tuple2(newModel, newCmds);
	});
var $author$project$Grid$Grid$incrementPage = F2(
	function (model, incr) {
		var oldPage = model.al;
		var upPage = oldPage + incr;
		var newPage = upPage;
		var newModel = _Utils_update(
			model,
			{al: newPage});
		var newCmds = $elm$core$Platform$Cmd$none;
		return _Utils_Tuple2(newModel, newCmds);
	});
var $author$project$Grid$Grid$noOut = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple3(model, cmd, $elm$core$Maybe$Nothing);
};
var $author$project$Grid$Grid$Sort = function (a) {
	return {$: 1, a: a};
};
var $author$project$Grid$Grid$Sort_Reverse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Grid$Grid$updateSort = F2(
	function (model, sort) {
		var newSort = function () {
			var _v0 = model.bf;
			switch (_v0.$) {
				case 0:
					return $author$project$Grid$Grid$Sort(sort);
				case 1:
					var sortedBy = _v0.a;
					return _Utils_eq(sort, sortedBy) ? $author$project$Grid$Grid$Sort_Reverse(sort) : $author$project$Grid$Grid$Sort(sort);
				default:
					var sortedBy = _v0.a;
					return _Utils_eq(sort, sortedBy) ? $author$project$Grid$Grid$Sort_None : $author$project$Grid$Grid$Sort(sort);
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{bf: newSort}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Grid$Grid$update = F2(
	function (model, subMsg) {
		switch (subMsg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			case 4:
				var val = subMsg.a;
				return $author$project$Grid$Grid$noOut(
					A2($author$project$Grid$Grid$handlePageSizeChanged, model, val));
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							a7: $author$project$PAM$UI$Basic$flipOpenness(model.a7)
						}),
					$author$project$Grid$Grid$focusPageSizeOptions(model),
					$elm$core$Maybe$Nothing);
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{a7: 1}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 7:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Grid$Grid$ClosePageSizeDropdown),
						$elm$core$Process$sleep(100)),
					$elm$core$Maybe$Nothing);
			case 1:
				var sort = subMsg.a;
				return $author$project$Grid$Grid$noOut(
					A2($author$project$Grid$Grid$updateSort, model, sort));
			case 2:
				var incr = subMsg.a;
				return $author$project$Grid$Grid$noOut(
					A2($author$project$Grid$Grid$incrementPage, model, incr));
			case 3:
				var upPage = subMsg.a;
				return $author$project$Grid$Grid$noOut(
					A2($author$project$Grid$Grid$gotoPage, model, upPage));
			case 8:
				var text = subMsg.a;
				return $author$project$Grid$Grid$noOut(
					A2($author$project$Grid$Grid$filterTextChange, model, text));
			case 9:
				var column = subMsg.a;
				return $author$project$Grid$Grid$noOut(
					A2($author$project$Grid$Grid$filterTypeChange, model, column));
			case 10:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aL: $author$project$PAM$UI$Basic$flipOpenness(model.aL)
						}),
					$author$project$Grid$Grid$focusFilterOptions(model),
					$elm$core$Maybe$Nothing);
			case 11:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aL: 1}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 12:
				var msg = subMsg.a;
				var checked = subMsg.b;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(msg));
			case 13:
				return $author$project$Grid$Grid$noOut(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{
								bX: $author$project$PAM$UI$Basic$flipOpenness(model.bX)
							}),
						$elm$core$Platform$Cmd$none));
			case 14:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bX: 1}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			default:
				var msg = subMsg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(msg));
		}
	});
var $author$project$PAM$Header$update = F2(
	function (model, msg) {
		switch (msg) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c9: function () {
								var _v1 = model.c9;
								if (!_v1) {
									return 1;
								} else {
									return 0;
								}
							}()
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c9: 0}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c9: 1}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$UI$DatePicker$ControlInteraction = {$: 4};
var $author$project$PAM$UI$DatePicker$InputInvalid = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$DatePicker$None = {$: 0};
var $author$project$PAM$UI$DatePicker$Picked = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$UI$DatePicker$PickerOpened = {$: 3};
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $author$project$PAM$UI$DatePicker$normalizeRange = F2(
	function (start, date) {
		var _v0 = A2($justinmimbs$date$Date$compare, date, start);
		switch (_v0) {
			case 2:
				return $author$project$PAM$UI$DatePicker$Range(
					A2($author$project$PAM$UI$DatePicker$FullRange, start, date));
			case 0:
				return $author$project$PAM$UI$DatePicker$Range(
					A2($author$project$PAM$UI$DatePicker$FullRange, date, start));
			default:
				return $author$project$PAM$UI$DatePicker$Range(
					A2($author$project$PAM$UI$DatePicker$FullRange, start, date));
		}
	});
var $elm$regex$Regex$split = _Regex_splitAtMost(_Regex_infinity);
var $author$project$PAM$UI$DatePicker$FailedInput = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$DatePicker$Invalid = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$UI$DatePicker$normalizeRangeMaybe = F2(
	function (start, date) {
		var _v0 = A2($justinmimbs$date$Date$compare, date, start);
		switch (_v0) {
			case 2:
				return $elm$core$Maybe$Just(
					$author$project$PAM$UI$DatePicker$Range(
						A2($author$project$PAM$UI$DatePicker$FullRange, start, date)));
			case 0:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just(
					$author$project$PAM$UI$DatePicker$Range(
						A2($author$project$PAM$UI$DatePicker$FullRange, start, date)));
		}
	});
var $author$project$PAM$UI$DatePicker$updateText = F2(
	function (settings, _v0) {
		var model = _v0;
		var focused = model.K;
		var startParse = function () {
			var _v12 = model.ab;
			if (_v12.$ === 2) {
				var text = _v12.a;
				return $elm$core$Maybe$Just(
					settings.mt(text));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var endParse = function () {
			var _v11 = model.af;
			if (_v11.$ === 2) {
				var text = _v11.a;
				return $elm$core$Maybe$Just(
					settings.mt(text));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var foc2 = function () {
			var _v4 = settings.m1;
			if (!_v4) {
				return A2(
					$elm$core$Maybe$map,
					function (date) {
						if (!date.$) {
							var d = date.a;
							return $author$project$PAM$UI$DatePicker$Single(d);
						} else {
							return $author$project$PAM$UI$DatePicker$NoneSelected;
						}
					},
					startParse);
			} else {
				var _v6 = _Utils_Tuple2(startParse, endParse);
				_v6$5:
				while (true) {
					if (_v6.a.$ === 1) {
						if (_v6.b.$ === 1) {
							var _v9 = _v6.a;
							var _v10 = _v6.b;
							return $elm$core$Maybe$Just($author$project$PAM$UI$DatePicker$NoneSelected);
						} else {
							if (!_v6.b.a.$) {
								var _v8 = _v6.a;
								var newEnd = _v6.b.a.a;
								return $elm$core$Maybe$Just(
									$author$project$PAM$UI$DatePicker$Range(
										$author$project$PAM$UI$DatePicker$End(newEnd)));
							} else {
								break _v6$5;
							}
						}
					} else {
						if (!_v6.a.a.$) {
							if (_v6.b.$ === 1) {
								var newStart = _v6.a.a.a;
								var _v7 = _v6.b;
								return $elm$core$Maybe$Just(
									$author$project$PAM$UI$DatePicker$Range(
										$author$project$PAM$UI$DatePicker$Start(newStart)));
							} else {
								if (!_v6.b.a.$) {
									var newStart = _v6.a.a.a;
									var newEnd = _v6.b.a.a;
									return A2($author$project$PAM$UI$DatePicker$normalizeRangeMaybe, newStart, newEnd);
								} else {
									break _v6$5;
								}
							}
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}
				}
				return $elm$core$Maybe$Nothing;
			}
		}();
		var inputValid = function () {
			var _v1 = _Utils_Tuple3(settings.m1, model.ab, model.af);
			_v1$2:
			while (true) {
				if (!_v1.a) {
					switch (_v1.b.$) {
						case 1:
							var _v2 = _v1.a;
							var val = _v1.b.a;
							return $author$project$PAM$UI$DatePicker$FailedInput(
								$author$project$PAM$UI$DatePicker$Invalid(val + ' is not a valid date in the mm/dd/yyyy format'));
						case 2:
							var _v3 = _v1.a;
							var val = _v1.b.a;
							return $author$project$PAM$UI$DatePicker$Picked(foc2);
						default:
							break _v1$2;
					}
				} else {
					break _v1$2;
				}
			}
			return $author$project$PAM$UI$DatePicker$None;
		}();
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					m0: A2($elm$core$Maybe$withDefault, model.m0, foc2)
				}),
			inputValid);
	});
var $author$project$PAM$UI$DatePicker$update = F3(
	function (settings, msg, _v0) {
		var model = _v0;
		var forceOpen = model.bs;
		var focused = model.K;
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{K: $elm$core$Maybe$Nothing, ah: date}),
					$author$project$PAM$UI$DatePicker$None);
			case 1:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							K: $elm$core$Maybe$Just(date)
						}),
					$author$project$PAM$UI$DatePicker$ControlInteraction);
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							K: $elm$core$Maybe$Just(model.ah)
						}),
					$author$project$PAM$UI$DatePicker$ControlInteraction);
			case 2:
				var date = msg.a;
				var _v2 = settings.m1;
				if (!_v2) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ab: $author$project$PAM$UI$DatePicker$NotEntered,
								af: $author$project$PAM$UI$DatePicker$NotEntered,
								m0: $author$project$PAM$UI$DatePicker$Single(date)
							}),
						$author$project$PAM$UI$DatePicker$Picked(
							$elm$core$Maybe$Just(
								$author$project$PAM$UI$DatePicker$Single(date))));
				} else {
					var newDate = function () {
						var _v3 = model.m0;
						_v3$2:
						while (true) {
							if (_v3.$ === 2) {
								switch (_v3.a.$) {
									case 1:
										var start = _v3.a.a;
										return A2($author$project$PAM$UI$DatePicker$normalizeRange, date, start);
									case 2:
										var end = _v3.a.a;
										return A2($author$project$PAM$UI$DatePicker$normalizeRange, end, date);
									default:
										break _v3$2;
								}
							} else {
								break _v3$2;
							}
						}
						return $author$project$PAM$UI$DatePicker$Range(
							$author$project$PAM$UI$DatePicker$Start(date));
					}();
					return _Utils_Tuple2(
						A2(
							$author$project$PAM$UI$DatePicker$setTextInputs,
							settings,
							_Utils_update(
								model,
								{m0: newDate})),
						$author$project$PAM$UI$DatePicker$Picked(
							$elm$core$Maybe$Just(newDate)));
				}
			case 3:
				var text = msg.a;
				if (text !== '') {
					var yearRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('^[1-2][0-9]{3}$'));
					var splitRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('/'));
					var parts = A2($elm$regex$Regex$split, splitRegex, text);
					var yearMatches = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$regex$Regex$contains(yearRegex),
							$elm$core$List$head(
								A2($elm$core$List$drop, 2, parts))));
					var monthRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('^[1-9]$|^0[1-9]$|^1[1-2]$'));
					var monthMatches = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$regex$Regex$contains(monthRegex),
							$elm$core$List$head(parts)));
					var dayRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('^[1-9]$|^0[1-9]$|^[1-2][0-9]$|^3[0-1]$'));
					var dayMatches = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$regex$Regex$contains(dayRegex),
							$elm$core$List$head(
								A2($elm$core$List$drop, 1, parts))));
					var validText = monthMatches && (dayMatches && yearMatches);
					var inputText = validText ? $author$project$PAM$UI$DatePicker$InputValid(text) : $author$project$PAM$UI$DatePicker$InputInvalid(text);
					var dateFormatRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('^\\d{1,2}/\\d{1,2}/\\d{4}$'));
					return A2(
						$author$project$PAM$UI$DatePicker$updateText,
						settings,
						_Utils_update(
							model,
							{ab: inputText}));
				} else {
					return A2(
						$author$project$PAM$UI$DatePicker$updateText,
						settings,
						_Utils_update(
							model,
							{ab: $author$project$PAM$UI$DatePicker$NotEntered}));
				}
			case 4:
				var text = msg.a;
				if (text !== '') {
					var yearRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('^[1-2][0-9]{3}$'));
					var splitRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('/'));
					var parts = A2($elm$regex$Regex$split, splitRegex, text);
					var yearMatches = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$regex$Regex$contains(yearRegex),
							$elm$core$List$head(
								A2($elm$core$List$drop, 2, parts))));
					var monthRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('^[1-9]$|^0[1-9]$|^1[1-2]$'));
					var monthMatches = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$regex$Regex$contains(monthRegex),
							$elm$core$List$head(parts)));
					var dayRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('^[1-9]$|^0[1-9]$|^[1-2][0-9]$|^3[0-1]$'));
					var dayMatches = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$regex$Regex$contains(dayRegex),
							$elm$core$List$head(
								A2($elm$core$List$drop, 1, parts))));
					var validText = monthMatches && (dayMatches && yearMatches);
					var inputText = validText ? $author$project$PAM$UI$DatePicker$InputValid(text) : $author$project$PAM$UI$DatePicker$InputInvalid(text);
					var dateFormatRegex = A2(
						$elm$core$Maybe$withDefault,
						$elm$regex$Regex$never,
						$elm$regex$Regex$fromString('^\\d{1,2}/\\d{1,2}/\\d{4}$'));
					return A2(
						$author$project$PAM$UI$DatePicker$updateText,
						settings,
						_Utils_update(
							model,
							{af: inputText}));
				} else {
					return A2(
						$author$project$PAM$UI$DatePicker$updateText,
						settings,
						_Utils_update(
							model,
							{af: $author$project$PAM$UI$DatePicker$NotEntered}));
				}
			case 5:
				return _Utils_Tuple2(model, $author$project$PAM$UI$DatePicker$None);
			case 6:
				return _Utils_Tuple2(model, $author$project$PAM$UI$DatePicker$None);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{em: false}),
					$author$project$PAM$UI$DatePicker$None);
			case 8:
				return _Utils_Tuple2(
					A2(
						$author$project$PAM$UI$DatePicker$setTextInputs,
						settings,
						_Utils_update(
							model,
							{K: $elm$core$Maybe$Nothing, em: true})),
					$author$project$PAM$UI$DatePicker$PickerOpened);
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{em: false}),
					$author$project$PAM$UI$DatePicker$None);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: true}),
					$author$project$PAM$UI$DatePicker$None);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: false}),
					$author$project$PAM$UI$DatePicker$None);
			default:
				return _Utils_Tuple2(model, $author$project$PAM$UI$DatePicker$None);
		}
	});
var $author$project$PAM$UI$DatePicker$Blur = {$: 7};
var $author$project$PAM$UI$DatePicker$close = $author$project$PAM$UI$DatePicker$Blur;
var $author$project$PAM$UI$DatePicker$compareDates = F2(
	function (fromDate, toDate) {
		var _v0 = A2($justinmimbs$date$Date$compare, fromDate, toDate);
		switch (_v0) {
			case 2:
				return false;
			case 0:
				return true;
			default:
				return true;
		}
	});
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.cs - 1)) + ($justinmimbs$date$Date$monthToNumber(date.ef) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.gU,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $author$project$PAM$UI$DatePicker$getSelectedDate = F2(
	function (identity, _v0) {
		var model = _v0;
		var focused = model.K;
		var _v1 = model.m0;
		if (_v1.$ === 1) {
			var d = _v1.a;
			return d;
		} else {
			return (identity === 'from') ? $author$project$PAM$UI$DatePicker$Date$initDate : A3($justinmimbs$date$Date$add, 1, 12, model.ah);
		}
	});
var $author$project$PAM$UI$DatePicker$isOpen = function (_v0) {
	var model = _v0;
	return model.em;
};
var $author$project$PAM$UI$DateRangeFilter$updateLayerDefinitionCmd = _Platform_outgoingPort('updateLayerDefinitionCmd', $elm$json$Json$Encode$string);
var $author$project$PAM$UI$DateRangeFilter$update = F2(
	function (msg, model) {
		var date = model.dM;
		var datePicker = model.kw;
		var toDatePicker = model.nH;
		switch (msg.$) {
			case 0:
				var sub = msg.a;
				var toDate = A2($author$project$PAM$UI$DatePicker$getSelectedDate, 'to', model.nH);
				var isToCalendarOpen = $author$project$PAM$UI$DatePicker$isOpen(model.nH);
				var _v1 = A3(
					$author$project$PAM$UI$DatePicker$update,
					$author$project$PAM$UI$DateRangeFilter$toPickerSettings(false),
					$author$project$PAM$UI$DatePicker$close,
					model.nH);
				var newToPicker = _v1.a;
				var evt1 = _v1.b;
				var _v2 = A3(
					$author$project$PAM$UI$DatePicker$update,
					$author$project$PAM$UI$DateRangeFilter$pickerSettings(false),
					sub,
					model.kw);
				var newPicker = _v2.a;
				var evt = _v2.b;
				var fromDate = A2($author$project$PAM$UI$DatePicker$getSelectedDate, 'from', newPicker);
				var isValid = A2($author$project$PAM$UI$DatePicker$compareDates, fromDate, toDate);
				var newNewPicker = isValid ? newPicker : model.kw;
				var isFromCalendarOpen = $author$project$PAM$UI$DatePicker$isOpen(newNewPicker);
				var newNewToPicker = isFromCalendarOpen ? newToPicker : model.nH;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kw: newNewPicker, nH: newNewToPicker}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var sub = msg.a;
				var isFromCalendarOpen = $author$project$PAM$UI$DatePicker$isOpen(model.kw);
				var fromDate = A2($author$project$PAM$UI$DatePicker$getSelectedDate, 'from', model.kw);
				var _v3 = A3(
					$author$project$PAM$UI$DatePicker$update,
					$author$project$PAM$UI$DateRangeFilter$pickerSettings(false),
					$author$project$PAM$UI$DatePicker$close,
					model.kw);
				var newPicker = _v3.a;
				var evt1 = _v3.b;
				var _v4 = A3(
					$author$project$PAM$UI$DatePicker$update,
					$author$project$PAM$UI$DateRangeFilter$toPickerSettings(false),
					sub,
					model.nH);
				var newToDatePicker = _v4.a;
				var evt = _v4.b;
				var toDate = A2($author$project$PAM$UI$DatePicker$getSelectedDate, 'to', newToDatePicker);
				var isValid = A2($author$project$PAM$UI$DatePicker$compareDates, fromDate, toDate);
				var newNewToDatePicker = isValid ? newToDatePicker : model.nH;
				var isToCalendarOpen = $author$project$PAM$UI$DatePicker$isOpen(newNewToDatePicker);
				var newNewPicker = isToCalendarOpen ? newPicker : model.kw;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kw: newNewPicker, nH: newNewToDatePicker}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var _v5 = A3(
					$author$project$PAM$UI$DatePicker$update,
					$author$project$PAM$UI$DateRangeFilter$toPickerSettings(false),
					$author$project$PAM$UI$DatePicker$close,
					model.nH);
				var newToPicker = _v5.a;
				var evt1 = _v5.b;
				var _v6 = A3(
					$author$project$PAM$UI$DatePicker$update,
					$author$project$PAM$UI$DateRangeFilter$pickerSettings(false),
					$author$project$PAM$UI$DatePicker$close,
					model.kw);
				var newPicker = _v6.a;
				var evt = _v6.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kw: newPicker, nH: newToPicker}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$PAM$UI$DateRangeFilter$updateLayerDefinitionCmd('apply')
							])));
			default:
				var ddd = function () {
					var _v7 = model.dM;
					if (!_v7.$) {
						var d = _v7.a;
						return d;
					} else {
						return $author$project$PAM$UI$DatePicker$Date$initDate;
					}
				}();
				var newDatePicker = $author$project$PAM$UI$DatePicker$initFromDate(ddd);
				var newToDatePicker = $author$project$PAM$UI$DatePicker$initFromDate(ddd);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dM: $elm$core$Maybe$Just(ddd),
							kw: newDatePicker,
							nH: newToDatePicker
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$PAM$UI$DateRangeFilter$updateLayerDefinitionCmd('clear')
							])));
		}
	});
var $author$project$PlugMap$Plugins$Search$defaultSearchLocation = {dc: -111.654, dd: 39.444};
var $author$project$PAM$UI$LeftSidebarSearch$defaultQueryedFeature = {r: -1, fi: '', d8: $author$project$PlugMap$Plugins$Search$defaultSearchLocation, fu: '', f_: ''};
var $author$project$PAM$UI$LeftSidebarSearch$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$UI$LeftSidebarSearch$QueryResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$UI$LeftSidebarSearch$QueryedFeature = F5(
	function (id, name, irwinid, uniquefireidentifier, location) {
		return {r: id, fi: irwinid, d8: location, fu: name, f_: uniquefireidentifier};
	});
var $author$project$PAM$UI$LeftSidebarSearch$decodeid = $elm$json$Json$Decode$int;
var $author$project$PAM$UI$LeftSidebarSearch$decodestring = $elm$json$Json$Decode$string;
var $author$project$PAM$UI$LeftSidebarSearch$queryedFeatureDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'geometry',
	$author$project$PlugMap$Plugins$Search$decodeLocation,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
		_List_fromArray(
			['attributes', 'uniquefireidentifier']),
		$author$project$PAM$UI$LeftSidebarSearch$decodestring,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
			_List_fromArray(
				['attributes', 'irwinid']),
			$author$project$PAM$UI$LeftSidebarSearch$decodestring,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
				_List_fromArray(
					['attributes', 'name']),
				$author$project$PAM$UI$LeftSidebarSearch$decodestring,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['attributes', 'id']),
					$author$project$PAM$UI$LeftSidebarSearch$decodeid,
					$elm$json$Json$Decode$succeed($author$project$PAM$UI$LeftSidebarSearch$QueryedFeature))))));
var $author$project$PAM$UI$LeftSidebarSearch$queryedFeaturesDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$withDefault,
	_List_Nil,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['features']),
		$elm$json$Json$Decode$list($author$project$PAM$UI$LeftSidebarSearch$queryedFeatureDecoder)));
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$core$String$trim = _String_trim;
var $author$project$PAM$UI$LeftSidebarSearch$getQueryResult = F2(
	function (layerDefs, model) {
		var queryTxt = function () {
			var _v1 = model.lC;
			if (!_v1.$) {
				var txt = _v1.a;
				return $elm$core$String$trim(
					$elm$core$String$toLower(txt));
			} else {
				return '';
			}
		}();
		var queryToken = function () {
			var _v0 = model.ip;
			if (!_v0.$) {
				var token = _v0.a;
				return token;
			} else {
				return '';
			}
		}();
		var layerDefsStr = A3($elm$core$String$replace, 'NotSet', '', layerDefs);
		var baseUrl = model.mH;
		var queryString = baseUrl + ('?where=(Lower(uniquefireidentifier) LIKE \'%25' + (queryTxt + ('%25\'+OR+Lower(name) LIKE \'%25' + (queryTxt + ('%25\'+OR+Lower(irwinid) LIKE \'%25' + (queryTxt + ('%25\')' + (layerDefsStr + ('&outFields=id,name,irwinid,uniquefireidentifier&orderByFields=startdatetime DESC&resultRecordCount=10&token=' + (queryToken + '&f=json'))))))))));
		var get = function (body) {
			return $elm$http$Http$request(
				{
					j$: body,
					kP: A2(
						$elm$http$Http$expectJson,
						A2(
							$elm$core$Basics$composeR,
							$krisajenkins$remotedata$RemoteData$fromResult,
							A2($elm$core$Basics$composeR, $author$project$PAM$UI$LeftSidebarSearch$QueryResponse, $author$project$PAM$UI$LeftSidebarSearch$Internal)),
						$author$project$PAM$UI$LeftSidebarSearch$queryedFeaturesDecoder),
					hk: _List_fromArray(
						[
							A2($elm$http$Http$header, 'Accept', 'application/json, text/javascript, */*; q=0.01')
						]),
					lM: 'GET',
					nF: $elm$core$Maybe$Nothing,
					nK: $elm$core$Maybe$Nothing,
					c8: queryString
				});
		};
		return get($elm$http$Http$emptyBody);
	});
var $author$project$PAM$UI$LeftSidebarSearch$getRangeFilter = _Platform_outgoingPort(
	'getRangeFilter',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$PAM$UI$LeftSidebarSearch$moveMapCenter = _Platform_outgoingPort(
	'moveMapCenter',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int($.r)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string($.fu)),
					_Utils_Tuple2(
					'x',
					$elm$json$Json$Encode$float($.dc)),
					_Utils_Tuple2(
					'y',
					$elm$json$Json$Encode$float($.dd))
				]));
	});
var $author$project$PAM$UI$LeftSidebarSearch$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 1:
				var txt = msg.a;
				return ($elm$core$String$length(txt) > 0) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{
							lC: $elm$core$Maybe$Just(txt)
						}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							lC: $elm$core$Maybe$Just(txt)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var searchTxt = function () {
					var _v1 = model.lC;
					if (!_v1.$) {
						var txt = _v1.a;
						return txt;
					} else {
						return '';
					}
				}();
				return ($elm$core$String$length(
					$elm$core$String$trim(searchTxt)) > 0) ? _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$PAM$UI$LeftSidebarSearch$getRangeFilter(0)
							]))) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 4:
				var si = msg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$PAM$UI$LeftSidebarSearch$moveMapCenter(
								{r: si.r, fu: si.fu, dc: si.d8.dc, dd: si.d8.dd})
							])));
			case 5:
				var layerDefs = msg.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$PAM$UI$LeftSidebarSearch$getQueryResult, layerDefs, model)
							])));
			case 3:
				var response = msg.a;
				var responseList = function () {
					if (response.$ === 3) {
						var featureList = response.a;
						return featureList;
					} else {
						return _List_Nil;
					}
				}();
				var selectedCount = $elm$core$List$length(responseList);
				var selectedFeature = $elm_community$list_extra$List$Extra$last(responseList);
				var sf = function () {
					if (!selectedFeature.$) {
						var feature = selectedFeature.a;
						return feature;
					} else {
						return $author$project$PAM$UI$LeftSidebarSearch$defaultQueryedFeature;
					}
				}();
				var sortedResponseList = A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.fu;
					},
					responseList);
				var sortedResponse = $krisajenkins$remotedata$RemoteData$Success(sortedResponseList);
				var unSortedResponse = $krisajenkins$remotedata$RemoteData$Success(responseList);
				return (selectedCount > 0) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{kN: '', mG: unSortedResponse}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							kN: 'No results found.',
							mG: $krisajenkins$remotedata$RemoteData$Success(_List_Nil)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$UI$Search$SearchTextChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$PAM$UI$Search$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 1:
				var txt = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kF: false, k5: true, bd: txt, bg: $author$project$PAM$UI$Search$None}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always(
							$author$project$PAM$UI$Search$SearchTextChanged(txt)),
						$elm$core$Task$succeed('')));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{k5: true}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PlugMap$Plugins$Search$Suggest = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Search$getBottomPadding = function (env) {
	return (!env.j8) ? (env.fD.j6 + env.fD.j7) : 0;
};
var $author$project$PlugMap$Plugins$Search$getLeftPadding = function (env) {
	return (!env.m8) ? env.fD.lG : 0;
};
var $author$project$PlugMap$Plugins$Search$selectSearchCandidates = F3(
	function (candidates, paddingLeft, paddingBottom) {
		return $author$project$PlugMap$Plugins$Search$gotoSearchCandidates(
			A3($author$project$PlugMap$Plugins$Search$encodeCandidates, candidates, paddingLeft, paddingBottom));
	});
var $author$project$PlugMap$Plugins$Search$showSuggestions = F2(
	function (env, suggestions) {
		var tf = A2(
			$elm$core$List$filterMap,
			function (a) {
				if (a.$ === 1) {
					var data = a.a;
					return $elm$core$Maybe$Just(data);
				} else {
					var data = a.a;
					return $elm$core$Maybe$Nothing;
				}
			},
			suggestions);
		return A3(
			$author$project$PlugMap$Plugins$Search$selectSearchCandidates,
			tf,
			$author$project$PlugMap$Plugins$Search$getLeftPadding(env),
			$author$project$PlugMap$Plugins$Search$getBottomPadding(env));
	});
var $author$project$PlugMap$Plugins$Search$update = F3(
	function (env, model, msg) {
		if (msg.$ === 1) {
			var response = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{iy: response}),
				function () {
					if (response.$ === 3) {
						var suggestions = response.a;
						if (suggestions.b && (!suggestions.b.b)) {
							var justOne = suggestions.a;
							if (justOne.$ === 1) {
								var candidate = justOne.a;
								return A3(
									$author$project$PlugMap$Plugins$Search$selectSearchCandidate,
									candidate,
									$author$project$PlugMap$Plugins$Search$getLeftPadding(env),
									$author$project$PlugMap$Plugins$Search$getBottomPadding(env));
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						} else {
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Task$perform,
										$elm$core$Basics$always(
											$author$project$PlugMap$Plugins$Search$Suggest(suggestions)),
										$elm$core$Task$succeed('')),
										A2($author$project$PlugMap$Plugins$Search$showSuggestions, env, suggestions)
									]));
						}
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}());
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Fields$DateRangeField$translator = F2(
	function (td, msg) {
		if (!msg.$) {
			var m = msg.a;
			return td.el(m);
		} else {
			var val = msg.a;
			return td.jc(val);
		}
	});
var $author$project$Fields$GenericField$dateRangeTranslator = F2(
	function (data, field) {
		return $author$project$Fields$DateRangeField$translator(
			{
				el: function (a) {
					return $author$project$Fields$GenericField$Internal(
						$author$project$Fields$GenericField$DateRangeMsg(a));
				},
				jc: function (a) {
					return $author$project$Fields$GenericField$ParentMsg(
						A2(field.m3, data, a));
				}
			});
	});
var $author$project$Fields$GenericField$dateRangeToGeneric = F3(
	function (data, field, _v0) {
		var mod = _v0.a;
		var msg = _v0.b;
		return _Utils_Tuple2(
			$author$project$Fields$GenericField$DateRangeModel(mod),
			A2(
				$elm$core$Platform$Cmd$map,
				A2($author$project$Fields$GenericField$dateRangeTranslator, data, field),
				msg));
	});
var $author$project$Fields$GenericField$dateToGeneric = F3(
	function (data, field, _v0) {
		var mod = _v0.a;
		var msg = _v0.b;
		return _Utils_Tuple2(
			$author$project$Fields$GenericField$DateModel(mod),
			A2(
				$elm$core$Platform$Cmd$map,
				A2($author$project$Fields$GenericField$dateTranslator, data, field),
				msg));
	});
var $author$project$Fields$GenericField$singleChoiceWithOtherToGeneric = F3(
	function (data, field, _v0) {
		var mod = _v0.a;
		var msg = _v0.b;
		return _Utils_Tuple2(
			$author$project$Fields$GenericField$SingleChoiceWithOtherModel(mod),
			A2(
				$elm$core$Platform$Cmd$map,
				A2($author$project$Fields$GenericField$singleChoiceWithOtherTranslator, data, field),
				msg));
	});
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $author$project$Fields$DateField$scrollTask = function (id) {
	return A2(
		$elm$core$Task$andThen,
		function (outcome) {
			if ((((outcome.b && outcome.b.b) && outcome.b.b.b) && outcome.b.b.b.b) && (!outcome.b.b.b.b.b)) {
				var _v1 = outcome.a;
				var elY = _v1.a;
				var elHeight = _v1.b;
				var _v2 = outcome.b;
				var _v3 = _v2.a;
				var pickerY = _v3.a;
				var pickerHeight = _v3.b;
				var _v4 = _v2.b;
				var _v5 = _v4.a;
				var viewportY = _v5.a;
				var viewportHeight = _v5.b;
				var _v6 = _v4.b;
				var _v7 = _v6.a;
				var viewportScrollTop = _v7.a;
				var elementTop = elY - viewportY;
				var elementBottom = (pickerY - viewportY) + pickerHeight;
				return (elementTop < 0) ? ((_Utils_cmp(elementBottom + 5, viewportHeight) < 0) ? A2(
					$elm$core$Task$onError,
					function (_v8) {
						return $elm$core$Task$succeed(0);
					},
					A3($elm$browser$Browser$Dom$setViewportOf, 'object-details', 0, viewportScrollTop + elementTop)) : $elm$core$Task$succeed(0)) : ((_Utils_cmp(elementBottom, viewportHeight) > 0) ? ((elementTop > 0) ? A2(
					$elm$core$Task$onError,
					function (_v9) {
						return $elm$core$Task$succeed(0);
					},
					A3($elm$browser$Browser$Dom$setViewportOf, 'object-details', 0, (viewportScrollTop + (elementBottom - viewportHeight)) + 5)) : $elm$core$Task$succeed(0)) : $elm$core$Task$succeed(0));
			} else {
				return $elm$core$Task$succeed(0);
			}
		},
		$elm$core$Task$sequence(
			_List_fromArray(
				[
					A2(
					$elm$core$Task$map,
					function (x) {
						return _Utils_Tuple2(x.bY.dd, x.bY.fb);
					},
					$elm$browser$Browser$Dom$getElement(
						$author$project$Fields$DateField$pickerSettings(true).hu)),
					A2(
					$elm$core$Task$map,
					function (x) {
						return _Utils_Tuple2(x.bY.dd, x.bY.fb);
					},
					$elm$browser$Browser$Dom$getElement(
						$author$project$Fields$DateField$pickerSettings(true).hu + '-picker')),
					A2(
					$elm$core$Task$map,
					function (x) {
						return _Utils_Tuple2(x.bY.dd, x.bY.fb);
					},
					$elm$browser$Browser$Dom$getElement('object-details')),
					A2(
					$elm$core$Task$map,
					function (x) {
						return _Utils_Tuple2(x.jk.dd, x.jk.dd);
					},
					$elm$browser$Browser$Dom$getViewportOf('object-details'))
				])));
};
var $author$project$Fields$DateField$ensureFieldInView = A2(
	$elm$core$Task$attempt,
	$elm$core$Basics$always(
		$author$project$Fields$DateField$Internal($author$project$Fields$DateField$I_NoOp)),
	$author$project$Fields$DateField$scrollTask('date-field-todo'));
var $author$project$Fields$DateField$UpdateValue = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$DateField$updateParentHack = F2(
	function (val, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dM: val}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always(
							$author$project$Fields$DateField$UpdateValue(val)),
						$elm$core$Task$succeed('Hello'))
					])));
	});
var $author$project$Fields$DateField$update = F2(
	function (model, msg) {
		if (msg.$ === 1) {
			var sub = msg.a;
			var _v1 = A3(
				$author$project$PAM$UI$DatePicker$update,
				$author$project$Fields$DateField$pickerSettings(false),
				sub,
				model.ao);
			var newPicker = _v1.a;
			var evt = _v1.b;
			_v2$4:
			while (true) {
				switch (evt.$) {
					case 2:
						if (!evt.a.$) {
							var newDate = evt.a.a;
							if (newDate.$ === 1) {
								var date = newDate.a;
								return A2(
									$author$project$Fields$DateField$updateParentHack,
									A2(
										$author$project$Fields$Shared$Valid,
										date,
										$author$project$NormalDate$dateToIsoString(date)),
									_Utils_Tuple2(
										_Utils_update(
											model,
											{ao: newPicker}),
										$author$project$Fields$DateField$ensureFieldInView));
							} else {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{ao: newPicker}),
									$author$project$Fields$DateField$ensureFieldInView);
							}
						} else {
							break _v2$4;
						}
					case 1:
						var x = evt.a;
						return A2(
							$author$project$Fields$DateField$updateParentHack,
							$author$project$Fields$Shared$Invalid(''),
							_Utils_Tuple2(
								_Utils_update(
									model,
									{ao: newPicker}),
								$author$project$Fields$DateField$ensureFieldInView));
					case 3:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ao: newPicker}),
							$author$project$Fields$DateField$ensureFieldInView);
					case 4:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ao: newPicker}),
							$author$project$Fields$DateField$ensureFieldInView);
					default:
						break _v2$4;
				}
			}
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{ao: newPicker}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Fields$DateRangeField$scrollTask = function (id) {
	return A2(
		$elm$core$Task$andThen,
		function (outcome) {
			if ((((outcome.b && outcome.b.b) && outcome.b.b.b) && outcome.b.b.b.b) && (!outcome.b.b.b.b.b)) {
				var _v1 = outcome.a;
				var elY = _v1.a;
				var elHeight = _v1.b;
				var _v2 = outcome.b;
				var _v3 = _v2.a;
				var pickerY = _v3.a;
				var pickerHeight = _v3.b;
				var _v4 = _v2.b;
				var _v5 = _v4.a;
				var viewportY = _v5.a;
				var viewportHeight = _v5.b;
				var _v6 = _v4.b;
				var _v7 = _v6.a;
				var viewportScrollTop = _v7.a;
				var elementTop = elY - viewportY;
				var elementBottom = (pickerY - viewportY) + pickerHeight;
				return (elementTop < 0) ? ((_Utils_cmp(elementBottom + 5, viewportHeight) < 0) ? A2(
					$elm$core$Task$onError,
					function (_v8) {
						return $elm$core$Task$succeed(0);
					},
					A3($elm$browser$Browser$Dom$setViewportOf, 'object-details', 0, viewportScrollTop + elementTop)) : $elm$core$Task$succeed(0)) : ((_Utils_cmp(elementBottom, viewportHeight) > 0) ? ((elementTop > 0) ? A2(
					$elm$core$Task$onError,
					function (_v9) {
						return $elm$core$Task$succeed(0);
					},
					A3($elm$browser$Browser$Dom$setViewportOf, 'object-details', 0, (viewportScrollTop + (elementBottom - viewportHeight)) + 5)) : $elm$core$Task$succeed(0)) : $elm$core$Task$succeed(0));
			} else {
				return $elm$core$Task$succeed(0);
			}
		},
		$elm$core$Task$sequence(
			_List_fromArray(
				[
					A2(
					$elm$core$Task$map,
					function (x) {
						return _Utils_Tuple2(x.bY.dd, x.bY.fb);
					},
					$elm$browser$Browser$Dom$getElement(
						$author$project$Fields$DateRangeField$pickerSettings(true).hu)),
					A2(
					$elm$core$Task$map,
					function (x) {
						return _Utils_Tuple2(x.bY.dd, x.bY.fb);
					},
					$elm$browser$Browser$Dom$getElement(
						$author$project$Fields$DateRangeField$pickerSettings(true).hu + '-picker')),
					A2(
					$elm$core$Task$map,
					function (x) {
						return _Utils_Tuple2(x.bY.dd, x.bY.fb);
					},
					$elm$browser$Browser$Dom$getElement('object-details')),
					A2(
					$elm$core$Task$map,
					function (x) {
						return _Utils_Tuple2(x.jk.dd, x.jk.dd);
					},
					$elm$browser$Browser$Dom$getViewportOf('object-details'))
				])));
};
var $author$project$Fields$DateRangeField$ensureFieldInView = A2(
	$elm$core$Task$attempt,
	$elm$core$Basics$always(
		$author$project$Fields$DateRangeField$Internal($author$project$Fields$DateRangeField$I_NoOp)),
	$author$project$Fields$DateRangeField$scrollTask('date-field-todo'));
var $author$project$Fields$DateRangeField$UpdateValue = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$DateRangeField$updateParentHack = F2(
	function (dat, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dM: dat}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2(
						$elm$core$Task$perform,
						function (dontCareAboutThisValue) {
							return $author$project$Fields$DateRangeField$UpdateValue(dat);
						},
						$elm$core$Task$succeed('Hello'))
					])));
	});
var $author$project$Fields$DateRangeField$update = F2(
	function (model, msg) {
		if (msg.$ === 1) {
			var sub = msg.a;
			var _v1 = A3(
				$author$project$PAM$UI$DatePicker$update,
				$author$project$Fields$DateRangeField$pickerSettings(false),
				sub,
				model.ao);
			var newPicker = _v1.a;
			var evt = _v1.b;
			_v2$4:
			while (true) {
				switch (evt.$) {
					case 2:
						if (!evt.a.$) {
							var newDate = evt.a.a;
							if (newDate.$ === 2) {
								var range = newDate.a;
								return A2(
									$author$project$Fields$DateRangeField$updateParentHack,
									function () {
										switch (range.$) {
											case 1:
												var start = range.a;
												return _Utils_Tuple2(
													A2(
														$author$project$Fields$Shared$Valid,
														start,
														$author$project$NormalDate$dateToIsoString(start)),
													$author$project$Fields$Shared$NotEntered);
											case 2:
												var end = range.a;
												return _Utils_Tuple2(
													$author$project$Fields$Shared$NotEntered,
													A2(
														$author$project$Fields$Shared$Valid,
														end,
														$author$project$NormalDate$dateToIsoString(end)));
											default:
												var start = range.a;
												var end = range.b;
												return _Utils_Tuple2(
													A2(
														$author$project$Fields$Shared$Valid,
														start,
														$author$project$NormalDate$dateToIsoString(start)),
													A2(
														$author$project$Fields$Shared$Valid,
														end,
														$author$project$NormalDate$dateToIsoString(end)));
										}
									}(),
									_Utils_Tuple2(
										_Utils_update(
											model,
											{ao: newPicker}),
										$author$project$Fields$DateRangeField$ensureFieldInView));
							} else {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{ao: newPicker}),
									$author$project$Fields$DateRangeField$ensureFieldInView);
							}
						} else {
							break _v2$4;
						}
					case 1:
						var x = evt.a;
						return A2(
							$author$project$Fields$DateRangeField$updateParentHack,
							_Utils_Tuple2(
								$author$project$Fields$Shared$Invalid(''),
								$author$project$Fields$Shared$Invalid('')),
							_Utils_Tuple2(
								_Utils_update(
									model,
									{ao: newPicker}),
								$author$project$Fields$DateRangeField$ensureFieldInView));
					case 3:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ao: newPicker}),
							$author$project$Fields$DateRangeField$ensureFieldInView);
					case 4:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ao: newPicker}),
							$author$project$Fields$DateRangeField$ensureFieldInView);
					default:
						break _v2$4;
				}
			}
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{ao: newPicker}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Fields$GeometryField$Error = 3;
var $author$project$Fields$GeometryField$Loaded = 2;
var $author$project$Fields$GeometryField$Requested = 1;
var $author$project$PlugMap$Plugins$Drawing$addReferenceItems = _Platform_outgoingPort(
	'addReferenceItems',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Drawing$addSelectedReferenceItems = F2(
	function (model, wkts) {
		return _Utils_Tuple2(
			model,
			$author$project$PlugMap$Plugins$Drawing$addReferenceItems(0));
	});
var $author$project$Fields$GeometryField$NotDrawing = 0;
var $author$project$Fields$GeometryField$newSpatial = F2(
	function (model, spatial) {
		return _Utils_update(
			spatial,
			{bi: 'Polygon'});
	});
var $author$project$Fields$GeometryField$Draw = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$Drawing$Line = 2;
var $author$project$PlugMap$Plugins$Drawing$modeToText = function (mode) {
	switch (mode) {
		case 0:
			return 'Polygon';
		case 1:
			return 'Point';
		case 2:
			return 'Line';
		case 3:
			return 'Circle';
		default:
			return 'Modify';
	}
};
var $author$project$PlugMap$Plugins$Drawing$encodeMode = function (mode) {
	return $elm$json$Json$Encode$string(
		$author$project$PlugMap$Plugins$Drawing$modeToText(mode));
};
var $author$project$PlugMap$Plugins$Drawing$encodeStartDrawingWithModeOptions = F2(
	function (mode, id) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'mode',
					$author$project$PlugMap$Plugins$Drawing$encodeMode(mode)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(id))
				]));
	});
var $author$project$PlugMap$Plugins$Drawing$startDrawingWithMode = _Platform_outgoingPort('startDrawingWithMode', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$Drawing$drawLine = F2(
	function (model, id) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					lP: 2,
					eh: $elm$core$Maybe$Just(id)
				}),
			$author$project$PlugMap$Plugins$Drawing$startDrawingWithMode(
				A2($author$project$PlugMap$Plugins$Drawing$encodeStartDrawingWithModeOptions, 2, id)));
	});
var $author$project$PlugMap$Plugins$Drawing$Point = 1;
var $author$project$PlugMap$Plugins$Drawing$drawPoint = F2(
	function (model, id) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					lP: 1,
					eh: $elm$core$Maybe$Just(id)
				}),
			$author$project$PlugMap$Plugins$Drawing$startDrawingWithMode(
				A2($author$project$PlugMap$Plugins$Drawing$encodeStartDrawingWithModeOptions, 1, id)));
	});
var $author$project$PlugMap$Plugins$Drawing$drawPoly = F2(
	function (model, id) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					lP: 0,
					eh: $elm$core$Maybe$Just(id)
				}),
			$author$project$PlugMap$Plugins$Drawing$startDrawingWithMode(
				A2($author$project$PlugMap$Plugins$Drawing$encodeStartDrawingWithModeOptions, 0, id)));
	});
var $author$project$PlugMap$Plugins$Drawing$stopAndUnload = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{kJ: false}),
		$author$project$PlugMap$Plugins$Drawing$disableDrawingCmd(0));
};
var $author$project$Fields$GeometryField$setupDrawingTool = F2(
	function (model, tool) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(model.B, tool);
			if (_v1.a.$ === 1) {
				switch (_v1.b) {
					case 3:
						var _v2 = _v1.b;
						return A2($author$project$PlugMap$Plugins$Drawing$drawPoly, model.m, 0);
					case 1:
						var _v3 = _v1.b;
						return A2($author$project$PlugMap$Plugins$Drawing$drawPoint, model.m, 0);
					case 2:
						var _v4 = _v1.b;
						return A2($author$project$PlugMap$Plugins$Drawing$drawLine, model.m, 0);
					default:
						var _v5 = _v1.b;
						return $author$project$PlugMap$Plugins$Drawing$stopAndUnload(model.m);
				}
			} else {
				return $author$project$PlugMap$Plugins$Drawing$stopAndUnload(model.m);
			}
		}();
		var newDrawModel = _v0.a;
		var newDrawCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					m: newDrawModel,
					B: $author$project$Fields$GeometryField$Draw(tool)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, newDrawCmd));
	});
var $author$project$Fields$GeometryField$UpdateValue = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$GeometryField$updateParentHack = F2(
	function (data, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2(
						$elm$core$Task$perform,
						function (dontCareAboutThisValue) {
							return $author$project$Fields$GeometryField$UpdateValue(
								function () {
									var _v1 = model.av;
									if (!_v1.$) {
										var v = _v1.a;
										return A2($author$project$Fields$Shared$Valid, v, v.aj);
									} else {
										return $author$project$Fields$Shared$NotEntered;
									}
								}());
						},
						$elm$core$Task$succeed('Hello'))
					])));
	});
var $author$project$Fields$GeometryField$addShape = F3(
	function (model, spatial, data) {
		return A2(
			$author$project$Fields$GeometryField$updateParentHack,
			data,
			function () {
				var _v0 = model.B;
				if (_v0.$ === 1) {
					return A2(
						$author$project$Fields$GeometryField$setupDrawingTool,
						_Utils_update(
							model,
							{
								O: A2(
									$elm$core$List$cons,
									A2($author$project$Fields$GeometryField$newSpatial, model, spatial),
									model.O)
							}),
						0);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								O: A2(
									$elm$core$List$cons,
									A2($author$project$Fields$GeometryField$newSpatial, model, spatial),
									model.O)
							}),
						$elm$core$Platform$Cmd$none);
				}
			}());
	});
var $author$project$PlugMap$Plugins$Upload$openChooseFile = _Platform_outgoingPort(
	'openChooseFile',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Upload$chooseFile = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{aE: $author$project$PlugMap$Plugins$Upload$SelectingFile}),
		$author$project$PlugMap$Plugins$Upload$openChooseFile(0));
};
var $author$project$PlugMap$Plugins$FeaturePicker$clearReferenceFeatures = _Platform_outgoingPort(
	'clearReferenceFeatures',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$FeaturePicker$clear = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$PlugMap$Plugins$FeaturePicker$clearReferenceFeatures(0));
};
var $author$project$PlugMap$Plugins$Drawing$deleteFeatureById = _Platform_outgoingPort('deleteFeatureById', $elm$json$Json$Encode$int);
var $author$project$PlugMap$Plugins$Drawing$deleteById = F2(
	function (model, id) {
		return _Utils_Tuple2(
			model,
			$author$project$PlugMap$Plugins$Drawing$deleteFeatureById(id));
	});
var $author$project$Fields$GeometryField$deleteFeature = F2(
	function (model, id) {
		var spa = A2(
			$elm$core$List$filterMap,
			function (itm) {
				return _Utils_eq(
					A2($elm$core$Maybe$withDefault, 1, itm.r),
					id) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(itm);
			},
			model.O);
		var _v0 = A2($author$project$PlugMap$Plugins$Drawing$deleteById, model.m, id);
		var dmo = _v0.a;
		var dme = _v0.b;
		var msg = A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, dme);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					I: _Utils_eq(
						model.I,
						$elm$core$Maybe$Just(id)) ? $elm$core$Maybe$Nothing : model.I,
					O: spa
				}),
			msg);
	});
var $author$project$PlugMap$Plugins$FeaturePicker$deselectFeature = _Platform_outgoingPort('deselectFeature', $elm$json$Json$Encode$int);
var $author$project$PlugMap$Plugins$FeaturePicker$deselect = F2(
	function (id, model) {
		return _Utils_Tuple2(
			model,
			$author$project$PlugMap$Plugins$FeaturePicker$deselectFeature(id));
	});
var $inkuzmin$elm_multiselect$Multiselect$getSelectedValues = function (_v0) {
	var model = _v0;
	return model.I;
};
var $author$project$PlugMap$Plugins$FeaturePicker$hideReferenceLayer = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$PlugMap$Plugins$FeaturePicker$hideReferenceLayerDisplay(0));
};
var $author$project$Fields$GeometryField$switchTo = F2(
	function (_v0, tool) {
		var model = _v0.a;
		var cmds = _v0.b;
		if (!_Utils_eq(model.B, tool)) {
			var _v1 = model.B;
			switch (_v1.$) {
				case 3:
					var osm = model.d;
					var nsm = _Utils_update(
						osm,
						{I: $elm$core$Maybe$Nothing});
					var _v2 = $author$project$PlugMap$Plugins$FeaturePicker$hideReferenceLayer(model.d.D);
					var rm = _v2.a;
					var rc = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d: nsm, B: tool}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmds,
									A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, rc),
									A2(
									$elm$core$Task$perform,
									$elm$core$Basics$always(
										$author$project$Fields$GeometryField$SetLayer($elm$core$Maybe$Nothing)),
									$elm$core$Task$succeed('Hi'))
								])));
				case 2:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{B: tool}),
						cmds);
				default:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{B: tool}),
						cmds);
			}
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{B: tool}),
				cmds);
		}
	});
var $author$project$Fields$GeometryField$handleDrawClicked = function (model) {
	return A2(
		$author$project$Fields$GeometryField$switchTo,
		_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
		$author$project$Fields$GeometryField$Draw(0));
};
var $author$project$Fields$GeometryField$handleDrawingMsg = F2(
	function (model, sub) {
		var _v0 = A2($author$project$PlugMap$Plugins$Drawing$update, model.m, sub);
		var nd = _v0.a;
		var ndc = _v0.b;
		var om = _v0.c;
		var nm = _Utils_update(
			model,
			{m: nd});
		var nc = A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, ndc);
		return _Utils_Tuple2(nm, nc);
	});
var $author$project$Fields$GeometryField$handleFlatRecalculated = F3(
	function (model, maybeFlat, data) {
		return A2(
			$author$project$Fields$GeometryField$updateParentHack,
			data,
			function (nm) {
				return _Utils_Tuple2(
					_Utils_update(
						nm,
						{cp: false}),
					$elm$core$Platform$Cmd$none);
			}(
				A2(
					$elm$core$Maybe$withDefault,
					_Utils_update(
						model,
						{av: $elm$core$Maybe$Nothing}),
					A2(
						$elm$core$Maybe$map,
						function (flat) {
							return _Utils_update(
								model,
								{
									av: $elm$core$Maybe$Just(
										{U: flat.kV, r: $elm$core$Maybe$Nothing, bi: 'Polygon', aj: flat.av})
								});
						},
						maybeFlat))));
	});
var $author$project$Fields$GeometryField$handleFlatReceived = F3(
	function (model, flat, data) {
		return A2(
			$author$project$Fields$GeometryField$updateParentHack,
			data,
			_Utils_Tuple2(
				_Utils_update(
					model,
					{av: flat, cp: false}),
				$elm$core$Platform$Cmd$none));
	});
var $author$project$Fields$GeometryField$replaceIfMatch = F4(
	function (spatials, length, index, item) {
		return A2(
			$elm$core$Maybe$withDefault,
			item,
			A2(
				$elm$core$Maybe$map,
				function (shp) {
					return {U: shp.U, r: shp.r, bi: shp.bi, aj: shp.aj};
				},
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (itm) {
							return _Utils_eq(itm.r, item.r);
						},
						spatials))));
	});
var $author$project$Fields$GeometryField$handleModifyEnd = F3(
	function (model, modifiedItems, data) {
		var updootedItems = $elm$core$List$reverse(
			A2(
				$elm$core$List$indexedMap,
				A2(
					$author$project$Fields$GeometryField$replaceIfMatch,
					modifiedItems.lQ,
					$elm$core$List$length(model.O)),
				$elm$core$List$reverse(model.O)));
		return A2(
			$author$project$Fields$GeometryField$updateParentHack,
			data,
			_Utils_Tuple2(
				_Utils_update(
					model,
					{O: updootedItems}),
				$elm$core$Platform$Cmd$none));
	});
var $author$project$Fields$GeometryField$handleReferenceClicked = function (model) {
	return A2(
		$author$project$Fields$GeometryField$switchTo,
		_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
		$author$project$Fields$GeometryField$Reference);
};
var $author$project$Fields$GeometryField$Upload = {$: 2};
var $author$project$Fields$GeometryField$handleUploadClicked = function (model) {
	return A2(
		$author$project$Fields$GeometryField$switchTo,
		_Utils_Tuple2(
			_Utils_update(
				model,
				{aF: $author$project$PlugMap$Plugins$Upload$defaultModel}),
			$elm$core$Platform$Cmd$none),
		$author$project$Fields$GeometryField$Upload);
};
var $author$project$Fields$GeometryField$lockUpdating = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{cp: true}),
		A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(
				$author$project$Fields$GeometryField$UpdateValue(
					$author$project$Fields$Shared$Invalid('User is Drawing'))),
			$elm$core$Task$succeed('')));
};
var $inkuzmin$elm_multiselect$Multiselect$filter = F2(
	function (selected, values) {
		return A2(
			$elm$core$List$filter,
			function (value) {
				return !A2($elm$core$List$member, value, selected);
			},
			values);
	});
var $inkuzmin$elm_multiselect$Multiselect$populateValues = F3(
	function (_v0, values, selected) {
		var model = _v0;
		var filtered = $elm$core$List$isEmpty(selected) ? values : A2($inkuzmin$elm_multiselect$Multiselect$filter, selected, values);
		return _Utils_update(
			model,
			{w: filtered, I: selected, jh: values});
	});
var $author$project$PlugMap$Plugins$FeaturePicker$FeatureLoading = {$: 4};
var $author$project$PlugMap$Plugins$FeaturePicker$dictGet = F2(
	function (dict, key) {
		return A2($elm$core$Dict$get, key, dict);
	});
var $author$project$PlugMap$Plugins$FeaturePicker$buildUrl = F3(
	function (layer, ags, params) {
		var tokenService = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Maybe$Nothing,
			A2(
				$elm$core$Maybe$map,
				$author$project$PlugMap$Plugins$FeaturePicker$dictGet(ags),
				layer.bM));
		var pstr = '?' + A2(
			$elm$core$String$join,
			'&',
			A2(
				$elm$core$List$map,
				function (_v2) {
					var key = _v2.a;
					var value = _v2.b;
					return key + ('=' + value);
				},
				_Utils_ap(
					params,
					function () {
						if ((!tokenService.$) && (tokenService.a.$ === 1)) {
							var _v4 = tokenService.a;
							var service = _v4.b;
							return _List_fromArray(
								[
									_Utils_Tuple2('token', service.nI)
								]);
						} else {
							return _List_Nil;
						}
					}())));
		var baseUrl = function () {
			if (!tokenService.$) {
				if (!tokenService.a.$) {
					var service = tokenService.a.a;
					return service.gn;
				} else {
					var _v1 = tokenService.a;
					var service = _v1.a;
					return service.gn;
				}
			} else {
				return '';
			}
		}();
		return _Utils_ap(
			baseUrl,
			_Utils_ap(layer.c8, pstr));
	});
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $author$project$PlugMap$Plugins$FeaturePicker$EsriResponded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$EsriRespondedError = function (a) {
	return {$: 3, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$handleEsriRequestByIdResponse = function (result) {
	if (!result.$) {
		var esriResponse = result.a;
		return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
			A2($author$project$PlugMap$Plugins$FeaturePicker$EsriResponded, 0, esriResponse));
	} else {
		var err = result.a;
		return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
			$author$project$PlugMap$Plugins$FeaturePicker$EsriRespondedError('Select'));
	}
};
var $author$project$PlugMap$Plugins$FeaturePicker$esriRequestById = F3(
	function (services, id, config) {
		var params = _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('outSR', '3857'),
					_Utils_Tuple2(
					'where',
					config.d_ + ('=' + $elm$core$String$fromInt(id))),
					_Utils_Tuple2('returnGeometry', 'true'),
					_Utils_Tuple2('f', 'geojson')
				]),
			($elm$core$List$length(config.ad) > 0) ? _List_fromArray(
				[
					_Utils_Tuple2(
					'outFields',
					A2($elm$core$String$join, ',', config.ad))
				]) : _List_Nil);
		return $elm$core$Platform$Cmd$batch(
			A2(
				$elm$core$List$map,
				function (lyr) {
					return $elm$http$Http$riskyRequest(
						{
							j$: $elm$http$Http$emptyBody,
							kP: $elm$http$Http$expectString($author$project$PlugMap$Plugins$FeaturePicker$handleEsriRequestByIdResponse),
							hk: _List_Nil,
							lM: 'GET',
							nF: $elm$core$Maybe$Nothing,
							nK: $elm$core$Maybe$Nothing,
							c8: A3(
								$author$project$PlugMap$Plugins$FeaturePicker$buildUrl,
								_Utils_update(
									config,
									{c8: config.c8 + (lyr + '/query')}),
								services,
								params)
						});
				},
				config.hE));
	});
var $author$project$PlugMap$Plugins$FeaturePicker$And = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$buildFilterXml = function (mFilter) {
	return _List_fromArray(
		[
			_Utils_Tuple2('filter', '<Filter></Filter>')
		]);
};
var $author$project$PlugMap$Plugins$FeaturePicker$WFSXmlResult = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$handleWfsRequestByIdResponse = function (result) {
	return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
		A2($author$project$PlugMap$Plugins$FeaturePicker$WFSXmlResult, 0, result));
};
var $author$project$PlugMap$Plugins$FeaturePicker$wfsRequestById = F3(
	function (services, id, config) {
		var params = _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('service', 'WFS'),
					_Utils_Tuple2('version', '2.0.0'),
					_Utils_Tuple2('request', 'GetFeature')
				]),
			($elm$core$List$length(config.cn) > 0) ? _List_fromArray(
				[
					_Utils_Tuple2(
					'typeNames',
					A2($elm$core$String$join, ',', config.cn))
				]) : _Utils_ap(
				_List_Nil,
				($elm$core$List$length(config.ad) > 0) ? _List_fromArray(
					[
						_Utils_Tuple2(
						'propertyName',
						A2($elm$core$String$join, ',', config.ad))
					]) : _Utils_ap(
					_List_Nil,
					$author$project$PlugMap$Plugins$FeaturePicker$buildFilterXml(
						function () {
							var _v0 = config.e5;
							if (!_v0.$) {
								var filter = _v0.a;
								return $elm$core$Maybe$Just(
									A2(
										$author$project$PlugMap$Plugins$FeaturePicker$And,
										filter,
										A2(
											$author$project$PlugMap$Plugins$FeaturePicker$EqualTo,
											'id',
											$elm$core$String$fromInt(id))));
							} else {
								return $elm$core$Maybe$Just(
									A2(
										$author$project$PlugMap$Plugins$FeaturePicker$EqualTo,
										'id',
										$elm$core$String$fromInt(id)));
							}
						}()))));
		return $elm$http$Http$riskyRequest(
			{
				j$: $elm$http$Http$emptyBody,
				kP: $elm$http$Http$expectString($author$project$PlugMap$Plugins$FeaturePicker$handleWfsRequestByIdResponse),
				hk: _List_Nil,
				lM: 'GET',
				nF: $elm$core$Maybe$Nothing,
				nK: $elm$core$Maybe$Nothing,
				c8: A3($author$project$PlugMap$Plugins$FeaturePicker$buildUrl, config, services, params)
			});
	});
var $author$project$PlugMap$Plugins$FeaturePicker$getSingleFeature = F4(
	function (services, refLayer, id, _v0) {
		var model = _v0.a;
		var cmds = _v0.b;
		if (!refLayer.$) {
			var layer = refLayer.a;
			var _v2 = layer.bn;
			if (_v2.$ === 1) {
				var wms = _v2.a;
				var wfs = _v2.b;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A3($author$project$PlugMap$Plugins$FeaturePicker$wfsRequestById, services, id, wfs),
								cmds,
								A2(
								$elm$core$Task$perform,
								function (dontCareAboutThisValue) {
									return $author$project$PlugMap$Plugins$FeaturePicker$FeatureLoading;
								},
								$elm$core$Task$succeed('Hello'))
							])));
			} else {
				var conf = _v2.a;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A3($author$project$PlugMap$Plugins$FeaturePicker$esriRequestById, services, id, conf),
								cmds,
								A2(
								$elm$core$Task$perform,
								function (dontCareAboutThisValue) {
									return $author$project$PlugMap$Plugins$FeaturePicker$FeatureLoading;
								},
								$elm$core$Task$succeed('Hello'))
							])));
			}
		} else {
			return _Utils_Tuple2(model, cmds);
		}
	});
var $author$project$PlugMap$Plugins$FeaturePicker$selectFeature = _Platform_outgoingPort('selectFeature', $elm$json$Json$Encode$int);
var $author$project$PlugMap$Plugins$FeaturePicker$select = F2(
	function (id, model) {
		return A4(
			$author$project$PlugMap$Plugins$FeaturePicker$getSingleFeature,
			model.ez,
			model.hC,
			id,
			function () {
				var _v0 = model.bh;
				if (_v0.$ === 2) {
					var feats = _v0.a;
					var newFeat = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (a) {
								return _Utils_eq(a.r, id);
							},
							feats));
					if (!newFeat.$) {
						var f = newFeat.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ag: _Utils_ap(
										model.ag,
										_List_fromArray(
											[f]))
								}),
							$author$project$PlugMap$Plugins$FeaturePicker$selectFeature(id));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			}());
	});
var $author$project$PlugMap$Plugins$Drawing$encodeBuffer = function (value) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'buffer',
				$elm$json$Json$Encode$float(value))
			]));
};
var $author$project$PlugMap$Plugins$Drawing$setBuffer = _Platform_outgoingPort('setBuffer', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$Drawing$handleBufferChanged = F2(
	function (model, newValue) {
		var setValue = (newValue < 5) ? 5.0 : newValue;
		var changeCmd = (!_Utils_eq(setValue, model.cA)) ? $author$project$PlugMap$Plugins$Drawing$setBuffer(
			$author$project$PlugMap$Plugins$Drawing$encodeBuffer(setValue)) : $elm$core$Platform$Cmd$none;
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{cA: setValue}),
			changeCmd,
			$elm$core$Maybe$Nothing);
	});
var $author$project$Fields$GeometryField$setAndUpdateBuffer = F2(
	function (model, buf) {
		var _v0 = A2($author$project$PlugMap$Plugins$Drawing$handleBufferChanged, model.m, buf);
		var nd = _v0.a;
		var ndc = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{cA: buf, m: nd}),
			A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, ndc));
	});
var $author$project$PlugMap$Plugins$Drawing$selectById = _Platform_outgoingPort('selectById', $elm$json$Json$Encode$int);
var $author$project$PlugMap$Plugins$Drawing$lockModifyById = F2(
	function (model, id) {
		return _Utils_Tuple2(
			model,
			$author$project$PlugMap$Plugins$Drawing$selectById(id));
	});
var $author$project$Fields$GeometryField$setFeatureToEdit = F2(
	function (model, id) {
		var _v0 = A2($author$project$PlugMap$Plugins$Drawing$lockModifyById, model.m, id);
		var dmo = _v0.a;
		var dme = _v0.b;
		var msg = A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, dme);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					I: _Utils_eq(
						model.I,
						$elm$core$Maybe$Just(id)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(id)
				}),
			msg);
	});
var $author$project$PlugMap$Plugins$FeaturePicker$encodeEsriConfig = function (conf) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(conf.c8)),
				_Utils_Tuple2(
				'tokenKey',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, conf.bM))),
				_Utils_Tuple2(
				'layers',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, conf.hE)),
				_Utils_Tuple2(
				'propertyNames',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, conf.ad)),
				_Utils_Tuple2(
				'idField',
				$elm$json$Json$Encode$string(conf.d_)),
				_Utils_Tuple2(
				'nameField',
				$elm$json$Json$Encode$string(conf.fv))
			]));
};
var $author$project$PlugMap$Plugins$FeaturePicker$encodeWFSConfig = function (wfs) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(wfs.c8)),
				_Utils_Tuple2(
				'tokenKey',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, wfs.bM)),
				_Utils_Tuple2(
				'typeNames',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, wfs.cn)),
				_Utils_Tuple2(
				'propertyNames',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, wfs.ad))
			]));
};
var $author$project$PlugMap$Plugins$FeaturePicker$encodeWMSConfig = function (wms) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(wms.c8)),
				_Utils_Tuple2(
				'tokenKey',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, wms.bM)),
				_Utils_Tuple2(
				'layers',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, wms.hE))
			]));
};
var $author$project$PlugMap$Plugins$FeaturePicker$encodeReferenceLayerConfig = function (layer) {
	return $elm$json$Json$Encode$object(
		function () {
			if (!layer.$) {
				var conf = layer.a;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'esri',
						$author$project$PlugMap$Plugins$FeaturePicker$encodeEsriConfig(conf))
					]);
			} else {
				var wms = layer.a;
				var wfs = layer.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'wfs',
						$author$project$PlugMap$Plugins$FeaturePicker$encodeWFSConfig(wfs)),
						_Utils_Tuple2(
						'wms',
						$author$project$PlugMap$Plugins$FeaturePicker$encodeWMSConfig(wms))
					]);
			}
		}());
};
var $author$project$PlugMap$Plugins$FeaturePicker$encodeReferenceLayer = function (layer) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(layer.fu)),
				_Utils_Tuple2(
				'key',
				$elm$json$Json$Encode$string(layer.lw)),
				_Utils_Tuple2(
				'config',
				$author$project$PlugMap$Plugins$FeaturePicker$encodeReferenceLayerConfig(layer.bn))
			]));
};
var $author$project$PlugMap$Plugins$FeaturePicker$featurePickerDisable = _Platform_outgoingPort(
	'featurePickerDisable',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$FeaturePicker$featurePickerSetLayer = _Platform_outgoingPort('featurePickerSetLayer', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$FeaturePicker$handleEsriRequestResponse = function (result) {
	if (!result.$) {
		var esriResponse = result.a;
		return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
			A2($author$project$PlugMap$Plugins$FeaturePicker$EsriResponded, 1, esriResponse));
	} else {
		var err = result.a;
		return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
			$author$project$PlugMap$Plugins$FeaturePicker$EsriRespondedError('SetLayer'));
	}
};
var $author$project$PlugMap$Plugins$FeaturePicker$esriRequest = F2(
	function (services, config) {
		var params = _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('outSR', '3857'),
					_Utils_Tuple2('where', '1=1'),
					_Utils_Tuple2('f', 'pjson'),
					_Utils_Tuple2('returnGeometry', 'false')
				]),
			($elm$core$List$length(config.ad) > 0) ? _List_fromArray(
				[
					_Utils_Tuple2(
					'outFields',
					A2($elm$core$String$join, ',', config.ad))
				]) : _List_Nil);
		return $elm$core$Platform$Cmd$batch(
			A2(
				$elm$core$List$map,
				function (lyr) {
					return $elm$http$Http$riskyRequest(
						{
							j$: $elm$http$Http$emptyBody,
							kP: $elm$http$Http$expectString($author$project$PlugMap$Plugins$FeaturePicker$handleEsriRequestResponse),
							hk: _List_Nil,
							lM: 'GET',
							nF: $elm$core$Maybe$Nothing,
							nK: $elm$core$Maybe$Nothing,
							c8: A3(
								$author$project$PlugMap$Plugins$FeaturePicker$buildUrl,
								_Utils_update(
									config,
									{c8: config.c8 + (lyr + '/query')}),
								services,
								params)
						});
				},
				config.hE));
	});
var $author$project$PlugMap$Plugins$FeaturePicker$handleWfsRequestResponse = function (result) {
	return $author$project$PlugMap$Plugins$FeaturePicker$Internal(
		A2($author$project$PlugMap$Plugins$FeaturePicker$WFSXmlResult, 1, result));
};
var $author$project$PlugMap$Plugins$FeaturePicker$wfsRequest = F2(
	function (services, config) {
		var params = _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('service', 'WFS'),
					_Utils_Tuple2('version', '2.0.0'),
					_Utils_Tuple2('request', 'GetFeature')
				]),
			($elm$core$List$length(config.cn) > 0) ? _List_fromArray(
				[
					_Utils_Tuple2(
					'typeNames',
					A2($elm$core$String$join, ',', config.cn))
				]) : _Utils_ap(
				_List_Nil,
				($elm$core$List$length(config.ad) > 0) ? _List_fromArray(
					[
						_Utils_Tuple2(
						'propertyName',
						A2($elm$core$String$join, ',', config.ad))
					]) : _Utils_ap(
					_List_Nil,
					$author$project$PlugMap$Plugins$FeaturePicker$buildFilterXml(config.e5))));
		return $elm$http$Http$riskyRequest(
			{
				j$: $elm$http$Http$emptyBody,
				kP: $elm$http$Http$expectString($author$project$PlugMap$Plugins$FeaturePicker$handleWfsRequestResponse),
				hk: _List_Nil,
				lM: 'GET',
				nF: $elm$core$Maybe$Nothing,
				nK: $elm$core$Maybe$Nothing,
				c8: A3($author$project$PlugMap$Plugins$FeaturePicker$buildUrl, config, services, params)
			});
	});
var $author$project$PlugMap$Plugins$FeaturePicker$getFeatures = F3(
	function (services, refLayer, _v0) {
		var model = _v0.a;
		var cmds = _v0.b;
		if (!refLayer.$) {
			var layer = refLayer.a;
			var _v2 = layer.bn;
			if (_v2.$ === 1) {
				var wms = _v2.a;
				var wfs = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ag: _List_Nil, bh: $author$project$PlugMap$Plugins$FeaturePicker$NoFeatures, bN: 1}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$PlugMap$Plugins$FeaturePicker$wfsRequest, services, wfs),
								cmds
							])));
			} else {
				var conf = _v2.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ag: _List_Nil,
							bh: $author$project$PlugMap$Plugins$FeaturePicker$NoFeatures,
							bN: $elm$core$List$length(conf.hE)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$PlugMap$Plugins$FeaturePicker$esriRequest, services, conf),
								cmds
							])));
			}
		} else {
			return _Utils_Tuple2(model, cmds);
		}
	});
var $author$project$PlugMap$Plugins$FeaturePicker$setReferenceLayer = F2(
	function (model, refLayer) {
		return function (_v1) {
			var a = _v1.a;
			var b = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					a,
					{hC: refLayer}),
				b);
		}(
			A3(
				$author$project$PlugMap$Plugins$FeaturePicker$getFeatures,
				model.ez,
				refLayer,
				function () {
					if (!refLayer.$) {
						var layer = refLayer.a;
						return _Utils_Tuple2(
							model,
							$author$project$PlugMap$Plugins$FeaturePicker$featurePickerSetLayer(
								$author$project$PlugMap$Plugins$FeaturePicker$encodeReferenceLayer(layer)));
					} else {
						return _Utils_Tuple2(
							model,
							$author$project$PlugMap$Plugins$FeaturePicker$featurePickerDisable(0));
					}
				}()));
	});
var $author$project$PlugMap$Plugins$Drawing$encodeWKTSrc = function (src) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(src.r)),
				_Utils_Tuple2(
				'wkt',
				$elm$json$Json$Encode$string(src.aj))
			]));
};
var $author$project$PlugMap$Plugins$Drawing$setWKTs = _Platform_outgoingPort('setWKTs', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$Drawing$setWKTsAsSource = F3(
	function (model, wkts, clr) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					b1: false,
					cr: A2(
						$elm$core$List$map,
						function ($) {
							return $.aj;
						},
						wkts)
				}),
			$author$project$PlugMap$Plugins$Drawing$setWKTs(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'clear',
							$elm$json$Json$Encode$bool(clr)),
							_Utils_Tuple2(
							'wkts',
							A2($elm$json$Json$Encode$list, $author$project$PlugMap$Plugins$Drawing$encodeWKTSrc, wkts))
						]))));
	});
var $inkuzmin$elm_multiselect$Multiselect$Cleared = {$: 2};
var $inkuzmin$elm_multiselect$Multiselect$DisableProtection = {$: 3};
var $inkuzmin$elm_multiselect$Multiselect$FocusResult = function (a) {
	return {$: 8, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$NotFound = function (a) {
	return {$: 3, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$ScrollResult = function (a) {
	return {$: 9, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$ScrollY = function (a) {
	return {$: 14, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Selected = function (a) {
	return {$: 0, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Unselected = function (a) {
	return {$: 1, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$backspace = 8;
var $inkuzmin$elm_multiselect$Multiselect$delayInMs = F2(
	function (ms, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(ms));
	});
var $inkuzmin$elm_multiselect$Multiselect$domScrollToY = F2(
	function (id, y) {
		return A2(
			$elm$core$Task$andThen,
			function (vp) {
				return A3($elm$browser$Browser$Dom$setViewportOf, id, vp.jk.dc, y);
			},
			$elm$browser$Browser$Dom$getViewportOf(id));
	});
var $inkuzmin$elm_multiselect$Multiselect$domScrollY = function (id) {
	return A2(
		$elm$core$Task$map,
		function (vp) {
			return vp.jk.dd;
		},
		$elm$browser$Browser$Dom$getViewportOf(id));
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow = 40;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$end = 35;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$escape = 27;
var $inkuzmin$elm_multiselect$Multiselect$fitViewPort = F2(
	function (_v0, _v1) {
		var top = _v0.a;
		var bottom = _v0.b;
		var vpTop = _v1.a;
		var vpBottom = _v1.b;
		return (_Utils_cmp(top, vpTop) < 0) ? top : ((_Utils_cmp(bottom, vpBottom) > 0) ? (vpTop + (bottom - vpBottom)) : vpTop);
	});
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight = 32;
var $inkuzmin$elm_multiselect$Multiselect$getBoundaries = function (i) {
	return _Utils_Tuple2(i * $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight, (i * $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight) + $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight = 200;
var $inkuzmin$elm_multiselect$Multiselect$getViewPortBoundaries = function (i) {
	return _Utils_Tuple2(i, i + $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight);
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$home = 36;
var $inkuzmin$elm_multiselect$Multiselect$indexOf = F2(
	function (el, list) {
		var helper = F2(
			function (l, index) {
				helper:
				while (true) {
					if (!l.b) {
						return $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var xs = l.b;
						if (_Utils_eq(x, el)) {
							return $elm$core$Maybe$Just(index);
						} else {
							var $temp$l = xs,
								$temp$index = index + 1;
							l = $temp$l;
							index = $temp$index;
							continue helper;
						}
					}
				}
			});
		return A2(helper, list, 0);
	});
var $inkuzmin$elm_multiselect$Multiselect$Utils$invisibleCharacter = '\u200C\u200C';
var $inkuzmin$elm_multiselect$Multiselect$lastElem = A2(
	$elm$core$List$foldl,
	A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, $elm$core$Basics$always),
	$elm$core$Maybe$Nothing);
var $inkuzmin$elm_multiselect$Multiselect$nextItem = F2(
	function (list, item) {
		var findNextInList = function (l) {
			findNextInList:
			while (true) {
				if (!l.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!l.b.b) {
						var x = l.a;
						return _Utils_eq(x, item) ? $elm$core$List$head(list) : $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var _v1 = l.b;
						var y = _v1.a;
						var rest = _v1.b;
						if (_Utils_eq(x, item)) {
							return $elm$core$Maybe$Just(y);
						} else {
							var $temp$l = A2($elm$core$List$cons, y, rest);
							l = $temp$l;
							continue findNextInList;
						}
					}
				}
			}
		};
		return findNextInList(list);
	});
var $inkuzmin$elm_multiselect$Multiselect$nextSelectedItem = F2(
	function (list, item) {
		var takeLast = function (l) {
			if (!l.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				if (!l.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var _v3 = l.b;
					var y = _v3.a;
					return $elm$core$Maybe$Just(y);
				}
			}
		};
		var findNextInList = function (l) {
			findNextInList:
			while (true) {
				if (!l.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!l.b.b) {
						var x = l.a;
						return _Utils_eq(x, item) ? takeLast(
							$elm$core$List$reverse(list)) : $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var _v1 = l.b;
						var y = _v1.a;
						var rest = _v1.b;
						if (_Utils_eq(x, item)) {
							return $elm$core$Maybe$Just(y);
						} else {
							var $temp$l = A2($elm$core$List$cons, y, rest);
							l = $temp$l;
							continue findNextInList;
						}
					}
				}
			}
		};
		return findNextInList(list);
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageDown = 34;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageUp = 33;
var $inkuzmin$elm_multiselect$Multiselect$prevItem = F2(
	function (list, item) {
		return A2(
			$inkuzmin$elm_multiselect$Multiselect$nextItem,
			$elm$core$List$reverse(list),
			item);
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$return = 13;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$tab = 9;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow = 38;
var $inkuzmin$elm_multiselect$Multiselect$update = F2(
	function (msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			case 4:
				return (model.aE === 2) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{aE: 0}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.no))
							])),
					$elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{aE: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.no))
							])),
					$elm$core$Maybe$Nothing);
			case 1:
				return model.aT ? _Utils_Tuple3(
					_Utils_update(
						model,
						{aT: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{aE: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aT: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				return model.aT ? _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{aT: true, aE: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.no)),
								A2($inkuzmin$elm_multiselect$Multiselect$delayInMs, 100, $inkuzmin$elm_multiselect$Multiselect$DisableProtection)
							])),
					$elm$core$Maybe$Nothing);
			case 9:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								kL: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_eq(model.z, $inkuzmin$elm_multiselect$Multiselect$Utils$invisibleCharacter) ? _Utils_Tuple3(
						_Utils_update(
							model,
							{z: ''}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing) : _Utils_Tuple3(
						_Utils_update(
							model,
							{kL: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			case 8:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								kL: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_eq(model.z, $inkuzmin$elm_multiselect$Multiselect$Utils$invisibleCharacter) ? _Utils_Tuple3(
						_Utils_update(
							model,
							{z: ''}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing) : _Utils_Tuple3(
						_Utils_update(
							model,
							{kL: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			case 11:
				var value = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{fg: value}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				var value = msg.a;
				var filtered = A2(
					$inkuzmin$elm_multiselect$Multiselect$filter,
					model.I,
					A2(
						$elm$core$List$filter,
						function (_v5) {
							var val = _v5.b;
							return A2(
								$elm$core$String$contains,
								$elm$core$String$toLower(value),
								$elm$core$String$toLower(val));
						},
						model.jh));
				if (model.aT) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aT: false}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var _v4 = model.x;
					if (_v4.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									w: filtered,
									x: $elm$core$List$head(filtered),
									z: value,
									aE: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var item = _v4.a;
						return (!$elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (i) {
									return _Utils_eq(i, item);
								},
								filtered))) ? _Utils_Tuple3(
							_Utils_update(
								model,
								{
									w: filtered,
									x: $elm$core$List$head(filtered),
									z: value,
									aE: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing) : _Utils_Tuple3(
							_Utils_update(
								model,
								{
									w: filtered,
									z: value,
									aE: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 5:
				var item = msg.a;
				var selected = _Utils_ap(
					model.I,
					_List_fromArray(
						[item]));
				var filtered = A2($inkuzmin$elm_multiselect$Multiselect$filter, selected, model.jh);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: filtered,
							x: A2($inkuzmin$elm_multiselect$Multiselect$nextSelectedItem, model.w, item),
							z: $inkuzmin$elm_multiselect$Multiselect$Utils$invisibleCharacter,
							I: selected,
							aE: $elm$core$List$isEmpty(filtered) ? 0 : 2
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.no))
							])),
					$elm$core$Maybe$Just(
						$inkuzmin$elm_multiselect$Multiselect$Selected(item)));
			case 6:
				var item = msg.a;
				var selected = A2(
					$elm$core$List$filter,
					function (value) {
						return !_Utils_eq(value, item);
					},
					model.I);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: A2($inkuzmin$elm_multiselect$Multiselect$filter, selected, model.jh),
							x: $elm$core$Maybe$Just(item),
							I: selected
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$ScrollY,
								$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.no))
							])),
					$elm$core$Maybe$Just(
						$inkuzmin$elm_multiselect$Multiselect$Unselected(item)));
			case 7:
				var selected = _List_Nil;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							w: A2($inkuzmin$elm_multiselect$Multiselect$filter, selected, model.jh),
							z: $inkuzmin$elm_multiselect$Multiselect$Utils$invisibleCharacter,
							I: selected,
							aE: 0
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.no))
							])),
					$elm$core$Maybe$Just($inkuzmin$elm_multiselect$Multiselect$Cleared));
			case 12:
				var item = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							x: $elm$core$Maybe$Just(item)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 14:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								kL: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var y = result.a;
					var _v7 = model.x;
					if (_v7.$ === 1) {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
					} else {
						var item = _v7.a;
						var _v8 = A2($inkuzmin$elm_multiselect$Multiselect$indexOf, item, model.w);
						if (_v8.$ === 1) {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						} else {
							var idx = _v8.a;
							var vpBoundaries = $inkuzmin$elm_multiselect$Multiselect$getViewPortBoundaries(y);
							var boundaries = $inkuzmin$elm_multiselect$Multiselect$getBoundaries(idx);
							var scroll = A2($inkuzmin$elm_multiselect$Multiselect$fitViewPort, boundaries, vpBoundaries);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{kL: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollResult,
											A2($inkuzmin$elm_multiselect$Multiselect$domScrollToY, 'multiselectMenu' + model.no, scroll))
										])),
								$elm$core$Maybe$Nothing);
						}
					}
				}
			default:
				var key = msg.a;
				if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow)) {
					var _v9 = model.x;
					if (_v9.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									x: $elm$core$List$head(model.w)
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var item = _v9.a;
						var prev = A2($inkuzmin$elm_multiselect$Multiselect$prevItem, model.w, item);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{x: prev}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Task$attempt,
										$inkuzmin$elm_multiselect$Multiselect$ScrollY,
										$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.no))
									])),
							$elm$core$Maybe$Nothing);
					}
				} else {
					if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow)) {
						var _v10 = model.x;
						if (_v10.$ === 1) {
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										x: $elm$core$List$head(model.w)
									}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							var item = _v10.a;
							var next = A2($inkuzmin$elm_multiselect$Multiselect$nextItem, model.w, item);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{x: next}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollY,
											$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.no))
										])),
								$elm$core$Maybe$Nothing);
						}
					} else {
						if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageUp) || _Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$home)) {
							var first = $elm$core$List$head(model.w);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{x: first}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollY,
											$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.no))
										])),
								$elm$core$Maybe$Nothing);
						} else {
							if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageDown) || _Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$end)) {
								var last = $inkuzmin$elm_multiselect$Multiselect$lastElem(model.w);
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{x: last}),
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												A2(
												$elm$core$Task$attempt,
												$inkuzmin$elm_multiselect$Multiselect$ScrollY,
												$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.no))
											])),
									$elm$core$Maybe$Nothing);
							} else {
								if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$return)) {
									var _v11 = model.x;
									if (_v11.$ === 1) {
										var isInvisible = _Utils_eq(model.z, $inkuzmin$elm_multiselect$Multiselect$Utils$invisibleCharacter);
										var isEmpty = $elm$core$String$isEmpty(model.z);
										return (isInvisible || isEmpty) ? _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing) : _Utils_Tuple3(
											model,
											$elm$core$Platform$Cmd$none,
											$elm$core$Maybe$Just(
												$inkuzmin$elm_multiselect$Multiselect$NotFound(model.z)));
									} else {
										var item = _v11.a;
										var selected = _Utils_ap(
											model.I,
											_List_fromArray(
												[item]));
										var filtered = A2($inkuzmin$elm_multiselect$Multiselect$filter, selected, model.jh);
										return _Utils_Tuple3(
											_Utils_update(
												model,
												{
													w: filtered,
													x: A2($inkuzmin$elm_multiselect$Multiselect$nextSelectedItem, model.w, item),
													z: $inkuzmin$elm_multiselect$Multiselect$Utils$invisibleCharacter,
													I: selected,
													aE: $elm$core$List$isEmpty(filtered) ? 0 : 2
												}),
											$elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														A2(
														$elm$core$Task$attempt,
														$inkuzmin$elm_multiselect$Multiselect$FocusResult,
														$elm$browser$Browser$Dom$focus('multiselectInput' + model.no))
													])),
											$elm$core$Maybe$Just(
												$inkuzmin$elm_multiselect$Multiselect$Selected(item)));
									}
								} else {
									if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$escape)) {
										return _Utils_Tuple3(
											_Utils_update(
												model,
												{aT: true, aE: 0}),
											$elm$core$Platform$Cmd$none,
											$elm$core$Maybe$Nothing);
									} else {
										if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$tab)) {
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{aE: 0}),
												$elm$core$Platform$Cmd$none,
												$elm$core$Maybe$Nothing);
										} else {
											if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$backspace)) {
												if (model.z === '') {
													var _v12 = $inkuzmin$elm_multiselect$Multiselect$lastElem(model.I);
													if (_v12.$ === 1) {
														return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
													} else {
														var item = _v12.a;
														var selected = A2(
															$elm$core$List$filter,
															function (value) {
																return !_Utils_eq(value, item);
															},
															model.I);
														return _Utils_Tuple3(
															_Utils_update(
																model,
																{
																	w: A2($inkuzmin$elm_multiselect$Multiselect$filter, selected, model.jh),
																	x: $elm$core$Maybe$Just(item),
																	I: selected
																}),
															$elm$core$Platform$Cmd$batch(
																_List_fromArray(
																	[
																		A2(
																		$elm$core$Task$attempt,
																		$inkuzmin$elm_multiselect$Multiselect$ScrollY,
																		$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.no))
																	])),
															$elm$core$Maybe$Just(
																$inkuzmin$elm_multiselect$Multiselect$Unselected(item)));
													}
												} else {
													return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
												}
											} else {
												return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
											}
										}
									}
								}
							}
						}
					}
				}
		}
	});
var $author$project$PlugMap$Plugins$FeaturePicker$FeaturesLoadError = function (a) {
	return {$: 3, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$FeaturesSelected = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$Loading = function (a) {
	return {$: 1, a: a};
};
var $author$project$PlugMap$Plugins$FeaturePicker$responseFromToString = function (from) {
	if (!from) {
		return 'Select';
	} else {
		return 'SetLayer';
	}
};
var $author$project$PlugMap$Plugins$FeaturePicker$encodeNormalizeEsri = function (request) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'from',
				$elm$json$Json$Encode$string(
					$author$project$PlugMap$Plugins$FeaturePicker$responseFromToString(request.dU))),
				_Utils_Tuple2(
				'esri',
				$elm$json$Json$Encode$string(request.e2)),
				_Utils_Tuple2(
				'showGeometry',
				$elm$json$Json$Encode$bool(request.fQ))
			]));
};
var $author$project$PlugMap$Plugins$FeaturePicker$xmlToJsTypeToString = function (typ) {
	return 'WFS';
};
var $author$project$PlugMap$Plugins$FeaturePicker$encodeXMLToJsRequest = function (result) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string(
					$author$project$PlugMap$Plugins$FeaturePicker$xmlToJsTypeToString(result.eH))),
				_Utils_Tuple2(
				'xml',
				$elm$json$Json$Encode$string(result.f7))
			]));
};
var $author$project$PlugMap$Plugins$FeaturePicker$esriFeatureToFeature = F2(
	function (lyr, feat) {
		var _v0 = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('id', 'name'),
			A2(
				$elm$core$Maybe$map,
				function (l) {
					var _v1 = l.bn;
					if (!_v1.$) {
						var config = _v1.a;
						return _Utils_Tuple2(config.d_, config.fv);
					} else {
						return _Utils_Tuple2('id', 'name');
					}
				},
				lyr));
		var idField = _v0.a;
		var nameField = _v0.b;
		return {
			U: A2(
				$elm$core$Maybe$map,
				function (itm) {
					switch (itm.$) {
						case 0:
							var str = itm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								-1,
								$elm$core$String$toFloat(str));
						case 1:
							var _float = itm.a;
							return _float;
						case 2:
							var _int = itm.a;
							return _int;
						default:
							return -1;
					}
				},
				A2($elm$core$Dict$get, 'acres', feat.bG)),
			k$: feat.k$,
			r: A2(
				$elm$core$Maybe$withDefault,
				-1,
				A2(
					$elm$core$Maybe$map,
					function (itm) {
						switch (itm.$) {
							case 0:
								var str = itm.a;
								return A2(
									$elm$core$Maybe$withDefault,
									-1,
									$elm$core$String$toInt(str));
							case 2:
								var _int = itm.a;
								return _int;
							case 1:
								var _float = itm.a;
								return -1;
							default:
								return -1;
						}
					},
					A2($elm$core$Dict$get, idField, feat.bG))),
			fu: A2(
				$elm$core$Maybe$withDefault,
				'Unknown',
				A2(
					$elm$core$Maybe$map,
					function (itm) {
						switch (itm.$) {
							case 0:
								var str = itm.a;
								return str;
							case 2:
								var _int = itm.a;
								return $elm$core$String$fromInt(_int);
							case 1:
								var _float = itm.a;
								return $elm$core$String$fromFloat(_float);
							default:
								return 'Unknown';
						}
					},
					A2($elm$core$Dict$get, nameField, feat.bG))),
			fy: -1
		};
	});
var $author$project$PlugMap$Plugins$FeaturePicker$noop = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$PlugMap$Plugins$FeaturePicker$normalizeEsri = _Platform_outgoingPort('normalizeEsri', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$FeaturePicker$xmlToJs = _Platform_outgoingPort('xmlToJs', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$FeaturePicker$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var from = msg.a;
				var result = msg.b;
				var _v1 = _Utils_Tuple2(from, result);
				if (!_v1.b.$) {
					if (_v1.a === 1) {
						var _v2 = _v1.a;
						var res = _v1.b.a;
						return _Utils_Tuple2(
							model,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$PlugMap$Plugins$FeaturePicker$xmlToJs(
										$author$project$PlugMap$Plugins$FeaturePicker$encodeXMLToJsRequest(
											{fH: $elm$core$Maybe$Nothing, eH: 0, f7: res}))
									])));
					} else {
						return $author$project$PlugMap$Plugins$FeaturePicker$noop(model);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var from = msg.a;
				var feats = msg.b;
				return _Utils_Tuple2(
					model,
					$author$project$PlugMap$Plugins$FeaturePicker$normalizeEsri(
						$author$project$PlugMap$Plugins$FeaturePicker$encodeNormalizeEsri(
							{e2: feats, dU: from, fQ: true, aG: $elm$core$Maybe$Nothing})));
			case 3:
				var error = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						function (dontCareAboutThisValue) {
							return $author$project$PlugMap$Plugins$FeaturePicker$FeaturesLoadError(error);
						},
						$elm$core$Task$succeed('Hello')));
			case 5:
				var error = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						function (dontCareAboutThisValue) {
							return $author$project$PlugMap$Plugins$FeaturePicker$FeaturesLoadError(error);
						},
						$elm$core$Task$succeed('Hello')));
			case 4:
				var res = msg.a;
				var _v3 = res.dU;
				if (_v3 === 1) {
					if ((model.bN - 1) > 0) {
						var oldFeats = function () {
							var _v4 = model.bh;
							if (_v4.$ === 1) {
								var featz = _v4.a;
								return featz;
							} else {
								return _List_Nil;
							}
						}();
						var allFeats = _Utils_ap(
							oldFeats,
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2(
									$elm$core$Maybe$map,
									$elm$core$List$map(
										$author$project$PlugMap$Plugins$FeaturePicker$esriFeatureToFeature(model.hC)),
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.cJ;
										},
										res.aG))));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									bh: $author$project$PlugMap$Plugins$FeaturePicker$Loading(allFeats),
									bN: model.bN - 1
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var oldFeats = function () {
							var _v5 = model.bh;
							if (_v5.$ === 1) {
								var featz = _v5.a;
								return featz;
							} else {
								return _List_Nil;
							}
						}();
						var allFeats = _Utils_ap(
							oldFeats,
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2(
									$elm$core$Maybe$map,
									$elm$core$List$map(
										$author$project$PlugMap$Plugins$FeaturePicker$esriFeatureToFeature(model.hC)),
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.cJ;
										},
										res.aG))));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									bh: $author$project$PlugMap$Plugins$FeaturePicker$Loaded(allFeats),
									bN: 0
								}),
							A2(
								$elm$core$Task$perform,
								function (dontCareAboutThisValue) {
									return $author$project$PlugMap$Plugins$FeaturePicker$FeaturesLoaded(allFeats);
								},
								$elm$core$Task$succeed('Hello')));
					}
				} else {
					if ($elm$core$List$length(
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.cJ;
								},
								res.aG))) > 0) {
						var allFeats = A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								$elm$core$List$map(
									$author$project$PlugMap$Plugins$FeaturePicker$esriFeatureToFeature(model.hC)),
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.cJ;
									},
									res.aG)));
						return _Utils_Tuple2(
							model,
							A2(
								$elm$core$Task$perform,
								function (dontCareAboutThisValue) {
									return $author$project$PlugMap$Plugins$FeaturePicker$FeaturesSelected(allFeats);
								},
								$elm$core$Task$succeed('Hello')));
					} else {
						return $author$project$PlugMap$Plugins$FeaturePicker$noop(model);
					}
				}
			case 6:
				var id = msg.a;
				return A4(
					$author$project$PlugMap$Plugins$FeaturePicker$getSingleFeature,
					model.ez,
					model.hC,
					id,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
			default:
				return $author$project$PlugMap$Plugins$FeaturePicker$noop(model);
		}
	});
var $author$project$PlugMap$Plugins$Upload$noop = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$PlugMap$Plugins$Upload$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				var feats = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aE: $author$project$PlugMap$Plugins$Upload$Done}),
					A2(
						$elm$core$Task$perform,
						function (dontCareAboutThisValue) {
							return $author$project$PlugMap$Plugins$Upload$FeaturesUploaded(feats);
						},
						$elm$core$Task$succeed('Hello')));
			case 1:
				var status = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aE: status.aE}),
					$elm$core$Platform$Cmd$none);
			default:
				return $author$project$PlugMap$Plugins$Upload$noop(model);
		}
	});
var $author$project$PlugMap$Plugins$Drawing$setExtentByFeatueId = _Platform_outgoingPort('setExtentByFeatueId', $elm$core$Basics$identity);
var $author$project$PlugMap$Plugins$Drawing$zoomToFeatureById = F4(
	function (model, id, leftPadding, bottomPadding) {
		return _Utils_Tuple2(
			model,
			$author$project$PlugMap$Plugins$Drawing$setExtentByFeatueId(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$int(id)),
							_Utils_Tuple2(
							'left',
							$elm$json$Json$Encode$int(leftPadding)),
							_Utils_Tuple2(
							'bottom',
							$elm$json$Json$Encode$int(bottomPadding))
						]))));
	});
var $author$project$Fields$GeometryField$zoomToFeatureById = F4(
	function (model, id, leftPadding, bottomPadding) {
		var _v0 = A4($author$project$PlugMap$Plugins$Drawing$zoomToFeatureById, model.m, id, leftPadding, bottomPadding);
		var dmo = _v0.a;
		var dme = _v0.b;
		var msg = A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, dme);
		return _Utils_Tuple2(model, msg);
	});
var $author$project$Fields$GeometryField$update = F3(
	function (model, msg, data) {
		switch (msg.$) {
			case 1:
				var _v1 = A3(
					$author$project$PlugMap$Plugins$Drawing$setWKTsAsSource,
					model.m,
					A2(
						$elm$core$List$map,
						function (a) {
							return {
								r: A2($elm$core$Maybe$withDefault, 1, a.r),
								aj: a.aj
							};
						},
						model.O),
					true);
				var dmo = _v1.a;
				var dmoc2 = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m: dmo}),
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, dmoc2));
			case 2:
				return $author$project$Fields$GeometryField$handleDrawClicked(model);
			case 3:
				return $author$project$Fields$GeometryField$handleUploadClicked(model);
			case 4:
				return $author$project$Fields$GeometryField$handleReferenceClicked(model);
			case 7:
				var sub = msg.a;
				return A2($author$project$Fields$GeometryField$handleDrawingMsg, model, sub);
			case 8:
				var tool = msg.a;
				return A2($author$project$Fields$GeometryField$setupDrawingTool, model, tool);
			case 9:
				var spatial = msg.a;
				return A3($author$project$Fields$GeometryField$addShape, model, spatial, data);
			case 12:
				var spatial = msg.a;
				return A3($author$project$Fields$GeometryField$handleModifyEnd, model, spatial, data);
			case 11:
				var buffer = msg.a;
				return A2($author$project$Fields$GeometryField$setAndUpdateBuffer, model, buffer);
			case 15:
				var id = msg.a;
				return A2($author$project$Fields$GeometryField$setFeatureToEdit, model, id);
			case 16:
				var id = msg.a;
				return A2($author$project$Fields$GeometryField$deleteFeature, model, id);
			case 13:
				var flat = msg.a;
				return A3($author$project$Fields$GeometryField$handleFlatRecalculated, model, flat, data);
			case 14:
				var id = msg.a;
				var leftPadding = msg.b;
				var bottomPadding = msg.c;
				return A4($author$project$Fields$GeometryField$zoomToFeatureById, model, id, leftPadding, bottomPadding);
			case 18:
				var layer = msg.a;
				var selectModel = model.d;
				var _v2 = A2($author$project$PlugMap$Plugins$FeaturePicker$setReferenceLayer, model.d.D, layer);
				var fpm = _v2.a;
				var fpc = _v2.b;
				var sm = _Utils_update(
					selectModel,
					{
						D: fpm,
						bz: 1,
						ac: A2($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, ''),
						bD: 1,
						I: layer,
						ag: _List_Nil
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d: sm}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, fpc),
								A2(
								$elm$core$Task$perform,
								$elm$core$Basics$always(
									$author$project$Fields$GeometryField$SetLayer(
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.lw;
											},
											layer))),
								$elm$core$Task$succeed('Hi'))
							])));
			case 19:
				var selectModel = model.d;
				var sm = _Utils_update(
					selectModel,
					{
						bD: function () {
							var _v3 = selectModel.bD;
							if (!_v3) {
								return 1;
							} else {
								return 0;
							}
						}()
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d: sm}),
					$elm$core$Platform$Cmd$none);
			case 20:
				var sub = msg.a;
				var selectModel = model.d;
				var _v4 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.d.ac);
				var a = _v4.a;
				var b = _v4.b;
				var c = _v4.c;
				var _v5 = function () {
					_v6$3:
					while (true) {
						if (!c.$) {
							switch (c.a.$) {
								case 0:
									var _v7 = c.a.a;
									var x = _v7.a;
									var y = _v7.b;
									var featId = A2(
										$elm$core$Maybe$withDefault,
										-1,
										$elm$core$String$toInt(x));
									var _v8 = A2($author$project$PlugMap$Plugins$FeaturePicker$select, featId, model.d.D);
									var f = _v8.a;
									var co = _v8.b;
									return _Utils_Tuple2(
										f,
										$elm$core$Maybe$Just(
											A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, co)));
								case 1:
									var _v9 = c.a.a;
									var x = _v9.a;
									var y = _v9.b;
									var featId = A2(
										$elm$core$Maybe$withDefault,
										-1,
										$elm$core$String$toInt(x));
									var _v10 = A2($author$project$PlugMap$Plugins$FeaturePicker$deselect, featId, model.d.D);
									var f = _v10.a;
									var co = _v10.b;
									return _Utils_Tuple2(
										f,
										$elm$core$Maybe$Just(
											A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, co)));
								case 2:
									var _v11 = c.a;
									var _v12 = $author$project$PlugMap$Plugins$FeaturePicker$clear(model.d.D);
									var f = _v12.a;
									var co = _v12.b;
									return _Utils_Tuple2(
										f,
										$elm$core$Maybe$Just(
											A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, co)));
								default:
									break _v6$3;
							}
						} else {
							break _v6$3;
						}
					}
					return _Utils_Tuple2(model.d.D, $elm$core$Maybe$Nothing);
				}();
				var nfp = _v5.a;
				var nfpc = _v5.b;
				var ns = _Utils_update(
					selectModel,
					{
						D: nfp,
						ac: a,
						ag: function () {
							_v13$2:
							while (true) {
								if (!c.$) {
									switch (c.a.$) {
										case 2:
											var _v14 = c.a;
											return _List_Nil;
										case 1:
											var _v15 = c.a.a;
											var x = _v15.a;
											var y = _v15.b;
											var featId = A2(
												$elm$core$Maybe$withDefault,
												-1,
												$elm$core$String$toInt(x));
											return A2(
												$elm$core$List$filter,
												function (itm) {
													return !_Utils_eq(itm.r, featId);
												},
												model.d.ag);
										default:
											break _v13$2;
									}
								} else {
									break _v13$2;
								}
							}
							return model.d.ag;
						}()
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d: ns}),
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$elm$core$Maybe$Just(
									A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Fields$GeometryField$Internal,
										A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$MultiMessage, b))),
									nfpc
								]))));
			case 21:
				var sub = msg.a;
				var os = model.d;
				var _v16 = A2($author$project$PlugMap$Plugins$FeaturePicker$update, sub, model.d.D);
				var nfp = _v16.a;
				var nc = _v16.b;
				var ns = _Utils_update(
					os,
					{D: nfp});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d: ns}),
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, nc));
			case 22:
				var feats = msg.a;
				var osm = model.d;
				var msm = A2(
					$inkuzmin$elm_multiselect$Multiselect$initModel,
					A2(
						$elm$core$List$map,
						function (feature) {
							return _Utils_Tuple2(
								$elm$core$String$fromInt(feature.r),
								feature.fu);
						},
						A2(
							$elm$core$List$sortBy,
							function (a) {
								return A3(
									$elm$core$String$replace,
									'\"',
									'',
									$elm$core$String$toLower(a.fu));
							},
							feats)),
					'');
				var sm = _Utils_update(
					osm,
					{dg: feats, bz: 2, ac: msm});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d: sm}),
					$elm$core$Platform$Cmd$none);
			case 24:
				var feats = msg.a;
				var osm = model.d;
				var oldSelectedItems = $elm$core$Dict$fromList(
					$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(osm.ac));
				var newSelectedItems = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (a) {
							return _Utils_Tuple2(
								$elm$core$String$fromInt(a.r),
								a.fu);
						},
						feats));
				var iAsDict = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (itm) {
							return _Utils_Tuple2(itm.r, itm);
						},
						osm.ag));
				var fAsDict = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (a) {
							return _Utils_Tuple2(a.r, a);
						},
						feats));
				var uDict = A2($elm$core$Dict$union, iAsDict, fAsDict);
				var ff = $elm$core$Dict$values(uDict);
				var allSelectedItems = A2($elm$core$Dict$union, oldSelectedItems, newSelectedItems);
				var ms = A3(
					$inkuzmin$elm_multiselect$Multiselect$populateValues,
					model.d.ac,
					A2(
						$elm$core$List$map,
						function (a) {
							return _Utils_Tuple2(
								$elm$core$String$fromInt(a.r),
								a.fu);
						},
						osm.dg),
					$elm$core$Dict$toList(allSelectedItems));
				var sm = _Utils_update(
					osm,
					{ac: ms, aA: osm.aA - 1, ag: ff});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d: sm}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var selectModel = model.d;
				var _v17 = $author$project$PlugMap$Plugins$FeaturePicker$hideReferenceLayer(model.d.D);
				var fpm = _v17.a;
				var fpc = _v17.b;
				var sm = _Utils_update(
					selectModel,
					{
						D: fpm,
						bz: 0,
						ac: A2($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, ''),
						bD: 1,
						I: $elm$core$Maybe$Nothing,
						ag: _List_Nil
					});
				var _v18 = A2(
					$author$project$PlugMap$Plugins$Drawing$addSelectedReferenceItems,
					model.m,
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						A2(
							$elm$core$List$map,
							function (a) {
								var _v19 = _Utils_Tuple2(a.k$, a.U);
								if (!_v19.a.$) {
									if (!_v19.b.$) {
										var geo = _v19.a.a;
										var acres = _v19.b.a;
										return $elm$core$Maybe$Just(
											{U: acres, aj: geo});
									} else {
										var geo = _v19.a.a;
										return $elm$core$Maybe$Just(
											{U: -1, aj: geo});
									}
								} else {
									return $elm$core$Maybe$Nothing;
								}
							},
							model.d.ag)));
				var dre = _v18.a;
				var drc = _v18.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m: dre, d: sm, B: $author$project$Fields$GeometryField$None}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, fpc),
								A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, drc),
								A2(
								$elm$core$Task$perform,
								$elm$core$Basics$always(
									$author$project$Fields$GeometryField$SetLayer($elm$core$Maybe$Nothing)),
								$elm$core$Task$succeed('Hi'))
							])));
			case 26:
				var _v20 = $author$project$PlugMap$Plugins$Upload$chooseFile(model.aF);
				var um = _v20.a;
				var uc = _v20.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aF: um}),
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$uploadTranslator, uc));
			case 27:
				var sub = msg.a;
				var _v21 = A2($author$project$PlugMap$Plugins$Upload$update, sub, model.aF);
				var um = _v21.a;
				var uc = _v21.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aF: um}),
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$uploadTranslator, uc));
			case 28:
				var feats = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{B: $author$project$Fields$GeometryField$None}),
					$elm$core$Platform$Cmd$none);
			case 23:
				var err = msg.a;
				var osm = model.d;
				var nsm = _Utils_update(
					osm,
					{bz: 3});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d: nsm}),
					$elm$core$Platform$Cmd$none);
			case 29:
				var osm = model.d;
				var nsm = _Utils_update(
					osm,
					{aA: osm.aA + 1});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d: nsm}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var flat = msg.a;
				return A3($author$project$Fields$GeometryField$handleFlatReceived, model, flat, data);
			case 30:
				return $author$project$Fields$GeometryField$lockUpdating(model);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Fields$SingleChoiceWithOtherField$UpdateValue = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$SingleChoiceWithOtherField$updateParentHack = F2(
	function (val, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always(
							$author$project$Fields$SingleChoiceWithOtherField$UpdateValue(val)),
						$elm$core$Task$succeed('Hello'))
					])));
	});
var $author$project$Fields$SingleChoiceWithOtherField$update = F3(
	function (msg, model, data) {
		switch (msg.$) {
			case 1:
				var selected = msg.a;
				return A2(
					$author$project$Fields$SingleChoiceWithOtherField$updateParentHack,
					$elm$core$Maybe$Just(selected),
					_Utils_Tuple2(
						_Utils_update(
							model,
							{be: 1}),
						$elm$core$Platform$Cmd$none));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{be: 1}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							be: function () {
								var _v1 = model.be;
								if (!_v1) {
									return 1;
								} else {
									return 0;
								}
							}()
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Fields$GenericField$update = F4(
	function (field, sub, model, data) {
		var _v0 = _Utils_Tuple3(field, sub, model);
		_v0$4:
		while (true) {
			switch (_v0.a.$) {
				case 5:
					if ((!_v0.b.$) && (!_v0.c.$)) {
						var ff = _v0.a.a;
						var msg = _v0.b.a;
						var mod = _v0.c.a;
						return A3(
							$author$project$Fields$GenericField$dateRangeToGeneric,
							data,
							ff,
							A2($author$project$Fields$DateRangeField$update, mod, msg));
					} else {
						break _v0$4;
					}
				case 4:
					if ((_v0.b.$ === 1) && (_v0.c.$ === 1)) {
						var ff = _v0.a.a;
						var msg = _v0.b.a;
						var mod = _v0.c.a;
						return A3(
							$author$project$Fields$GenericField$dateToGeneric,
							data,
							ff,
							A2($author$project$Fields$DateField$update, mod, msg));
					} else {
						break _v0$4;
					}
				case 10:
					if ((_v0.b.$ === 3) && (_v0.c.$ === 3)) {
						var ff = _v0.a.a;
						var msg = _v0.b.a;
						var mod = _v0.c.a;
						return A3(
							$author$project$Fields$GenericField$geometryToGeneric,
							data,
							ff,
							A3($author$project$Fields$GeometryField$update, mod, msg, data));
					} else {
						break _v0$4;
					}
				case 9:
					if ((_v0.b.$ === 2) && (_v0.c.$ === 2)) {
						var ff = _v0.a.a;
						var msg = _v0.b.a;
						var mod = _v0.c.a;
						return A3(
							$author$project$Fields$GenericField$singleChoiceWithOtherToGeneric,
							data,
							ff,
							A3($author$project$Fields$SingleChoiceWithOtherField$update, msg, mod, data));
					} else {
						break _v0$4;
					}
				default:
					break _v0$4;
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$PAM$EditableFields$updateItem = F6(
	function (maybeModel, sub, row, col, data, maybeField) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none),
			A3(
				$elm$core$Maybe$map2,
				function (mdl) {
					return function (field) {
						return function (_v0) {
							var a = _v0.a;
							var b = _v0.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(a),
								A2(
									$elm$core$Platform$Cmd$map,
									A2($author$project$PAM$EditableFields$genericFieldTranslator, row, col),
									b));
						}(
							A4(
								$author$project$Fields$GenericField$update,
								field.bq(data),
								sub,
								mdl,
								data));
					};
				},
				maybeModel,
				maybeField));
	});
var $author$project$PAM$EditableFields$updateRow = F6(
	function (rowFields, rowModels, row, col, sub, data) {
		return $author$project$PAM$EditableFields$rebatch(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index2) {
						return function (item) {
							return _Utils_eq(index2, col) ? A6(
								$author$project$PAM$EditableFields$updateItem,
								item,
								sub,
								row,
								col,
								data,
								$elm$core$List$head(
									A2($elm$core$List$drop, index2, rowFields))) : _Utils_Tuple2(item, $elm$core$Platform$Cmd$none);
						};
					},
					rowModels)));
	});
var $author$project$PAM$EditableFields$updateRowCol = F6(
	function (fields, models, row, col, sub, data) {
		return $author$project$PAM$EditableFields$rebatch(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index) {
						return function (rowItems) {
							return _Utils_eq(index, row) ? A2(
								$elm$core$Maybe$withDefault,
								$author$project$PAM$EditableFields$rebatch(
									$elm$core$List$unzip(
										A2(
											$elm$core$List$map,
											function (a) {
												return _Utils_Tuple2(a, $elm$core$Platform$Cmd$none);
											},
											rowItems))),
								A2(
									$elm$core$Maybe$andThen,
									function (flds) {
										return $elm$core$Maybe$Just(
											A6($author$project$PAM$EditableFields$updateRow, flds, rowItems, index, col, sub, data));
									},
									$elm$core$List$head(
										A2($elm$core$List$drop, index, fields)))) : $author$project$PAM$EditableFields$rebatch(
								$elm$core$List$unzip(
									A2(
										$elm$core$List$map,
										function (a) {
											return _Utils_Tuple2(a, $elm$core$Platform$Cmd$none);
										},
										rowItems)));
						};
					},
					models)));
	});
var $author$project$PAM$EditableFields$update = F4(
	function (fields, models, msg, data) {
		var row = msg.a;
		var col = msg.b;
		var sub = msg.c;
		return A6($author$project$PAM$EditableFields$updateRowCol, fields, models, row, col, sub, data);
	});
var $author$project$PAM$upsDeets = F3(
	function (config, sub, model) {
		var grid = $author$project$PAM$buildDetailLayout(config);
		var _v0 = A4($author$project$PAM$EditableFields$update, grid, config.e, sub, config.n);
		var nm = _v0.a;
		var nc = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{e: nm}),
			A2($elm$core$Platform$Cmd$map, $author$project$PAM$deetsTranslator, nc));
	});
var $author$project$PAM$updateDetails = F2(
	function (model, sub) {
		var _v0 = model.mS;
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 0:
					var params = _v0.a.a;
					return $elm$core$Maybe$Nothing;
				case 1:
					var params = _v0.a.a;
					return $elm$core$Maybe$Nothing;
				case 2:
					var _v1 = _v0.a;
					var objTypeKey = _v1.a;
					var params = _v1.b;
					var _v2 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
					if (!_v2.$) {
						var _v3 = _v2.a;
						var objTypeId = _v3.a;
						var objType = _v3.b;
						return A5(
							$elm$core$Maybe$map4,
							F4(
								function (newObj, choices, layout, fields) {
									return A3(
										$author$project$PAM$upsDeets,
										{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
										sub,
										model);
								}),
							$author$project$PAM$Object$Repo$getNewObject(model.b),
							A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
							A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
							A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				case 3:
					var _v4 = _v0.a;
					var parentObjTypeKey = _v4.a;
					var parentObjId = _v4.b;
					var childObjTypeKey = _v4.c;
					var params = _v4.d;
					var _v5 = A2($author$project$PAM$Model$ObjectTypes$findByKey, childObjTypeKey, model.a.c);
					if (!_v5.$) {
						var _v6 = _v5.a;
						var objTypeId = _v6.a;
						return A5(
							$elm$core$Maybe$map4,
							F4(
								function (newObj, choices, layout, fields) {
									return A3(
										$author$project$PAM$upsDeets,
										{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
										sub,
										model);
								}),
							$author$project$PAM$Object$Repo$getNewObject(model.b),
							A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
							A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
							A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				case 4:
					var _v7 = _v0.a;
					var objTypeKey = _v7.a;
					var objId = _v7.b;
					var params = _v7.c;
					var _v8 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
					if (!_v8.$) {
						var _v9 = _v8.a;
						var objTypeId = _v9.a;
						return A5(
							$elm$core$Maybe$map4,
							F4(
								function (newObj, choices, layout, fields) {
									return A3(
										$author$project$PAM$upsDeets,
										{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
										sub,
										model);
								}),
							A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
							A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
							A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
							A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				default:
					var _v10 = _v0.a;
					var objTypeKey = _v10.a;
					var objId = _v10.b;
					var params = _v10.c;
					var _v11 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, model.a.c);
					if (!_v11.$) {
						var _v12 = _v11.a;
						var objTypeId = _v12.a;
						return A5(
							$elm$core$Maybe$map4,
							F4(
								function (newObj, choices, layout, fields) {
									return A3(
										$author$project$PAM$upsDeets,
										{t: choices, e: model.e, u: fields, v: layout, n: newObj, c: model.a.c, mS: model.mS},
										sub,
										model);
								}),
							A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
							A2($author$project$PAM$Model$FieldChoices$get, objTypeId, model.a.y),
							A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, model.a.C),
							A2($author$project$PAM$Model$ObjectModels$get, objTypeId, model.a.l));
					} else {
						return $elm$core$Maybe$Nothing;
					}
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$InPageOAuth$AuthError = function (a) {
	return {$: 7, a: a};
};
var $author$project$InPageOAuth$Authorized = {$: 3};
var $author$project$InPageOAuth$Pending = {$: 1};
var $author$project$PAM$User$TokenReceived = function (a) {
	return {$: 2, a: a};
};
var $author$project$InPageOAuth$Unauthorized = {$: 2};
var $author$project$InPageOAuth$UserAuthorized = {$: 1};
var $author$project$InPageOAuth$GettingACLs = {$: 6};
var $author$project$InPageOAuth$Error = function (a) {
	return {$: 6, a: a};
};
var $author$project$InPageOAuth$handleAuthStateError = function (err) {
	return $author$project$InPageOAuth$Internal(
		$author$project$InPageOAuth$Error('Unable to retrieve your permissions. If this problem continues contact your system administrator.'));
};
var $author$project$InPageOAuth$ACLsLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$InPageOAuth$handleLoadACL = function (res) {
	if (!res.$) {
		var acls = res.a;
		return $author$project$InPageOAuth$Internal(
			$author$project$InPageOAuth$ACLsLoaded(acls));
	} else {
		return $author$project$InPageOAuth$Internal(
			$author$project$InPageOAuth$Error('Unable to get permissions. Try reloading.'));
	}
};
var $author$project$PAM$User$ACL = F4(
	function (id, section, value, access) {
		return {gb: access, r: id, fL: section, aG: value};
	});
var $author$project$PAM$User$None = 0;
var $author$project$PAM$User$Create = 2;
var $author$project$PAM$User$Delete = 6;
var $author$project$PAM$User$DeleteIfOwn = 4;
var $author$project$PAM$User$Modify = 5;
var $author$project$PAM$User$ModifyIfOwn = 3;
var $author$project$PAM$User$Read = 1;
var $author$project$PAM$User$accessDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (value) {
					case 0:
						return 0;
					case 1:
						return 1;
					case 2:
						return 2;
					case 3:
						return 3;
					case 4:
						return 4;
					case 5:
						return 5;
					case 6:
						return 6;
					default:
						return 0;
				}
			}());
	},
	$elm$json$Json$Decode$int);
var $author$project$PAM$User$aclDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'access',
	$author$project$PAM$User$accessDecoder,
	0,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'value',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'section',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$PAM$User$ACL)))));
var $author$project$PAM$API$APIBase$user_GetACLs = F4(
	function (settings, user, _return, onError) {
		return A8(
			$author$project$PAM$API$APIBase$postRequest,
			settings,
			'user',
			'GetACLs',
			$elm$core$Maybe$Nothing,
			_return,
			onError,
			user,
			$elm$json$Json$Decode$list($author$project$PAM$User$aclDecoder));
	});
var $author$project$InPageOAuth$getACLs = function (model) {
	var ncp = $elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					function (settings) {
						return A4($author$project$PAM$API$APIBase$user_GetACLs, settings.jQ, model.aq, $author$project$InPageOAuth$handleLoadACL, $author$project$InPageOAuth$handleAuthStateError);
					},
					model.bR))
			]));
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{cj: $author$project$InPageOAuth$GettingACLs}),
		ncp);
};
var $author$project$InPageOAuth$GettingToken = {$: 4};
var $author$project$InPageOAuth$GotAccessToken = function (a) {
	return {$: 3, a: a};
};
var $truqu$elm_base64$Base64$Encode$intToBase64 = function (i) {
	switch (i) {
		case 0:
			return 'A';
		case 1:
			return 'B';
		case 2:
			return 'C';
		case 3:
			return 'D';
		case 4:
			return 'E';
		case 5:
			return 'F';
		case 6:
			return 'G';
		case 7:
			return 'H';
		case 8:
			return 'I';
		case 9:
			return 'J';
		case 10:
			return 'K';
		case 11:
			return 'L';
		case 12:
			return 'M';
		case 13:
			return 'N';
		case 14:
			return 'O';
		case 15:
			return 'P';
		case 16:
			return 'Q';
		case 17:
			return 'R';
		case 18:
			return 'S';
		case 19:
			return 'T';
		case 20:
			return 'U';
		case 21:
			return 'V';
		case 22:
			return 'W';
		case 23:
			return 'X';
		case 24:
			return 'Y';
		case 25:
			return 'Z';
		case 26:
			return 'a';
		case 27:
			return 'b';
		case 28:
			return 'c';
		case 29:
			return 'd';
		case 30:
			return 'e';
		case 31:
			return 'f';
		case 32:
			return 'g';
		case 33:
			return 'h';
		case 34:
			return 'i';
		case 35:
			return 'j';
		case 36:
			return 'k';
		case 37:
			return 'l';
		case 38:
			return 'm';
		case 39:
			return 'n';
		case 40:
			return 'o';
		case 41:
			return 'p';
		case 42:
			return 'q';
		case 43:
			return 'r';
		case 44:
			return 's';
		case 45:
			return 't';
		case 46:
			return 'u';
		case 47:
			return 'v';
		case 48:
			return 'w';
		case 49:
			return 'x';
		case 50:
			return 'y';
		case 51:
			return 'z';
		case 52:
			return '0';
		case 53:
			return '1';
		case 54:
			return '2';
		case 55:
			return '3';
		case 56:
			return '4';
		case 57:
			return '5';
		case 58:
			return '6';
		case 59:
			return '7';
		case 60:
			return '8';
		case 61:
			return '9';
		case 62:
			return '+';
		default:
			return '/';
	}
};
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $truqu$elm_base64$Base64$Encode$toBase64 = function (_int) {
	return _Utils_ap(
		$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 18)),
		_Utils_ap(
			$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 12)),
			_Utils_ap(
				$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 6)),
				$truqu$elm_base64$Base64$Encode$intToBase64(63 & (_int >>> 0)))));
};
var $truqu$elm_base64$Base64$Encode$add = F2(
	function (_char, _v0) {
		var res = _v0.a;
		var count = _v0.b;
		var acc = _v0.c;
		var current = (acc << 8) | _char;
		if (count === 2) {
			return _Utils_Tuple3(
				_Utils_ap(
					res,
					$truqu$elm_base64$Base64$Encode$toBase64(current)),
				0,
				0);
		} else {
			return _Utils_Tuple3(res, count + 1, current);
		}
	});
var $truqu$elm_base64$Base64$Encode$chomp = F2(
	function (char_, acc) {
		var _char = $elm$core$Char$toCode(char_);
		return (_char < 128) ? A2($truqu$elm_base64$Base64$Encode$add, _char, acc) : ((_char < 2048) ? A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2($truqu$elm_base64$Base64$Encode$add, 192 | (_char >>> 6), acc)) : (((_char < 55296) || ((_char >= 57344) && (_char <= 65535))) ? A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2(
				$truqu$elm_base64$Base64$Encode$add,
				128 | (63 & (_char >>> 6)),
				A2($truqu$elm_base64$Base64$Encode$add, 224 | (_char >>> 12), acc))) : A2(
			$truqu$elm_base64$Base64$Encode$add,
			128 | (63 & _char),
			A2(
				$truqu$elm_base64$Base64$Encode$add,
				128 | (63 & (_char >>> 6)),
				A2(
					$truqu$elm_base64$Base64$Encode$add,
					128 | (63 & (_char >>> 12)),
					A2($truqu$elm_base64$Base64$Encode$add, 240 | (_char >>> 18), acc))))));
	});
var $elm$core$String$foldl = _String_foldl;
var $truqu$elm_base64$Base64$Encode$initial = _Utils_Tuple3('', 0, 0);
var $truqu$elm_base64$Base64$Encode$wrapUp = function (_v0) {
	var res = _v0.a;
	var cnt = _v0.b;
	var acc = _v0.c;
	switch (cnt) {
		case 1:
			return res + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 2)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc << 4)) + '=='));
		case 2:
			return res + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 10)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc >>> 4)) + ($truqu$elm_base64$Base64$Encode$intToBase64(63 & (acc << 2)) + '=')));
		default:
			return res;
	}
};
var $truqu$elm_base64$Base64$Encode$encode = function (input) {
	return $truqu$elm_base64$Base64$Encode$wrapUp(
		A3($elm$core$String$foldl, $truqu$elm_base64$Base64$Encode$chomp, $truqu$elm_base64$Base64$Encode$initial, input));
};
var $truqu$elm_base64$Base64$encode = $truqu$elm_base64$Base64$Encode$encode;
var $truqu$elm_oauth2$Internal$makeHeaders = function (credentials) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (s) {
				return _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Basic ' + s)
					]);
			},
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var clientId = _v0.a0;
					var secret = _v0.iH;
					return $truqu$elm_base64$Base64$encode(clientId + (':' + secret));
				},
				credentials)));
};
var $truqu$elm_oauth2$Internal$protocolToString = function (protocol) {
	if (!protocol) {
		return 'http';
	} else {
		return 'https';
	}
};
var $truqu$elm_oauth2$Internal$makeRedirectUri = function (url) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				$truqu$elm_oauth2$Internal$protocolToString(url.mE),
				'://',
				url.cO,
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (i) {
						return ':' + $elm$core$String$fromInt(i);
					},
					url.mx)),
				url.cU,
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (q) {
						return '?' + q;
					},
					url.$9))
			]));
};
var $truqu$elm_oauth2$Internal$AuthenticationSuccess = F4(
	function (token, refreshToken, expiresIn, scope) {
		return {kQ: expiresIn, mM: refreshToken, cg: scope, nI: token};
	});
var $truqu$elm_oauth2$Internal$expiresInDecoder = $elm$json$Json$Decode$maybe(
	A2($elm$json$Json$Decode$field, 'expires_in', $elm$json$Json$Decode$int));
var $truqu$elm_oauth2$Internal$decoderFromJust = function (msg) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map($elm$json$Json$Decode$succeed),
		$elm$core$Maybe$withDefault(
			$elm$json$Json$Decode$fail(msg)));
};
var $truqu$elm_oauth2$OAuth$maybeAndThen2 = F3(
	function (fn, ma, mb) {
		return A2(
			$elm$core$Maybe$andThen,
			$elm$core$Basics$identity,
			A3($elm$core$Maybe$map2, fn, ma, mb));
	});
var $truqu$elm_oauth2$OAuth$Bearer = $elm$core$Basics$identity;
var $truqu$elm_oauth2$OAuth$tryMakeToken = F2(
	function (tokenType, token) {
		var _v0 = $elm$core$String$toLower(tokenType);
		if (_v0 === 'bearer') {
			return $elm$core$Maybe$Just(token);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $truqu$elm_oauth2$OAuth$makeRefreshToken = F2(
	function (tokenType, mToken) {
		var _v0 = _Utils_Tuple2(
			mToken,
			A3(
				$truqu$elm_oauth2$OAuth$maybeAndThen2,
				$truqu$elm_oauth2$OAuth$tryMakeToken,
				$elm$core$Maybe$Just(tokenType),
				mToken));
		if (_v0.a.$ === 1) {
			var _v1 = _v0.a;
			return $elm$core$Maybe$Just($elm$core$Maybe$Nothing);
		} else {
			if (!_v0.b.$) {
				var token = _v0.b.a;
				return $elm$core$Maybe$Just(
					$elm$core$Maybe$Just(token));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}
	});
var $truqu$elm_oauth2$Internal$refreshTokenDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$truqu$elm_oauth2$Internal$decoderFromJust('missing or invalid \'refresh_token\' / \'token_type\''),
	A3(
		$elm$json$Json$Decode$map2,
		$truqu$elm_oauth2$OAuth$makeRefreshToken,
		A2($elm$json$Json$Decode$field, 'token_type', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'refresh_token', $elm$json$Json$Decode$string))));
var $truqu$elm_oauth2$Internal$scopeDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Maybe$withDefault(_List_Nil),
	$elm$json$Json$Decode$maybe(
		A2(
			$elm$json$Json$Decode$field,
			'scope',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string))));
var $truqu$elm_oauth2$OAuth$makeToken = $truqu$elm_oauth2$OAuth$maybeAndThen2($truqu$elm_oauth2$OAuth$tryMakeToken);
var $truqu$elm_oauth2$Internal$tokenDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$truqu$elm_oauth2$Internal$decoderFromJust('missing or invalid \'access_token\' / \'token_type\''),
	A3(
		$elm$json$Json$Decode$map2,
		$truqu$elm_oauth2$OAuth$makeToken,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$Just,
			A2($elm$json$Json$Decode$field, 'token_type', $elm$json$Json$Decode$string)),
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$Just,
			A2($elm$json$Json$Decode$field, 'access_token', $elm$json$Json$Decode$string))));
var $truqu$elm_oauth2$Internal$authenticationSuccessDecoder = A5($elm$json$Json$Decode$map4, $truqu$elm_oauth2$Internal$AuthenticationSuccess, $truqu$elm_oauth2$Internal$tokenDecoder, $truqu$elm_oauth2$Internal$refreshTokenDecoder, $truqu$elm_oauth2$Internal$expiresInDecoder, $truqu$elm_oauth2$Internal$scopeDecoder);
var $elm$http$Http$stringBody = _Http_pair;
var $truqu$elm_oauth2$Internal$makeRequest = F4(
	function (toMsg, url, headers, body) {
		return {
			j$: A2($elm$http$Http$stringBody, 'application/x-www-form-urlencoded', body),
			kP: A2($elm$http$Http$expectJson, toMsg, $truqu$elm_oauth2$Internal$authenticationSuccessDecoder),
			hk: headers,
			lM: 'POST',
			nF: $elm$core$Maybe$Nothing,
			nK: $elm$core$Maybe$Nothing,
			c8: $elm$url$Url$toString(url)
		};
	});
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $truqu$elm_oauth2$OAuth$AuthorizationCode$makeTokenRequest = F2(
	function (toMsg, _v0) {
		var credentials = _v0.kq;
		var code = _v0.dC;
		var url = _v0.c8;
		var redirectUri = _v0.aU;
		var headers = $truqu$elm_oauth2$Internal$makeHeaders(
			function () {
				var _v1 = credentials.iH;
				if (_v1.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var secret = _v1.a;
					return $elm$core$Maybe$Just(
						{a0: credentials.a0, iH: secret});
				}
			}());
		var body = A2(
			$elm$core$String$dropLeft,
			1,
			$elm$url$Url$Builder$toQuery(
				_List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'grant_type', 'authorization_code'),
						A2($elm$url$Url$Builder$string, 'client_id', credentials.a0),
						A2(
						$elm$url$Url$Builder$string,
						'redirect_uri',
						$truqu$elm_oauth2$Internal$makeRedirectUri(redirectUri)),
						A2($elm$url$Url$Builder$string, 'code', code)
					])));
		return A4($truqu$elm_oauth2$Internal$makeRequest, toMsg, url, headers, body);
	});
var $author$project$InPageOAuth$getAccessToken = F2(
	function (code, config) {
		var clientId = config.a0;
		var secret = config.iH;
		var tokenEndpoint = config.nJ;
		var redirectUri = config.aU;
		var req = A2(
			$truqu$elm_oauth2$OAuth$AuthorizationCode$makeTokenRequest,
			function (r) {
				return $author$project$InPageOAuth$Internal(
					$author$project$InPageOAuth$GotAccessToken(r));
			},
			{
				dC: code,
				kq: {
					a0: clientId,
					iH: $elm$core$Maybe$Just(secret)
				},
				aU: config.aU,
				c8: tokenEndpoint
			});
		var tok = A2(
			$elm$core$List$map,
			function (a) {
				return a;
			},
			req.hk);
		return $elm$http$Http$request(req);
	});
var $author$project$InPageOAuth$handleGotCode = F2(
	function (model, code) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dC: $elm$core$Maybe$Just(code),
					cj: $author$project$InPageOAuth$GettingToken
				}),
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					$author$project$InPageOAuth$getAccessToken(code),
					model.bn)));
	});
var $author$project$InPageOAuth$GettingUserInfo = {$: 5};
var $truqu$elm_oauth2$Internal$AuthenticationError = F3(
	function (error, errorDescription, errorUri) {
		return {kL: error, kM: errorDescription, g5: errorUri};
	});
var $truqu$elm_oauth2$Internal$errorDescriptionDecoder = $elm$json$Json$Decode$maybe(
	A2($elm$json$Json$Decode$field, 'error_description', $elm$json$Json$Decode$string));
var $truqu$elm_oauth2$Internal$errorUriDecoder = $elm$json$Json$Decode$maybe(
	A2($elm$json$Json$Decode$field, 'error_uri', $elm$json$Json$Decode$string));
var $truqu$elm_oauth2$Internal$authenticationErrorDecoder = function (errorCodeDecoder) {
	return A4($elm$json$Json$Decode$map3, $truqu$elm_oauth2$Internal$AuthenticationError, errorCodeDecoder, $truqu$elm_oauth2$Internal$errorDescriptionDecoder, $truqu$elm_oauth2$Internal$errorUriDecoder);
};
var $truqu$elm_oauth2$OAuth$AccessDenied = {$: 2};
var $truqu$elm_oauth2$OAuth$Custom = function (a) {
	return {$: 7, a: a};
};
var $truqu$elm_oauth2$OAuth$InvalidRequest = {$: 0};
var $truqu$elm_oauth2$OAuth$InvalidScope = {$: 4};
var $truqu$elm_oauth2$OAuth$ServerError = {$: 5};
var $truqu$elm_oauth2$OAuth$TemporarilyUnavailable = {$: 6};
var $truqu$elm_oauth2$OAuth$UnauthorizedClient = {$: 1};
var $truqu$elm_oauth2$OAuth$UnsupportedResponseType = {$: 3};
var $truqu$elm_oauth2$OAuth$errorCodeFromString = function (str) {
	switch (str) {
		case 'invalid_request':
			return $truqu$elm_oauth2$OAuth$InvalidRequest;
		case 'unauthorized_client':
			return $truqu$elm_oauth2$OAuth$UnauthorizedClient;
		case 'access_denied':
			return $truqu$elm_oauth2$OAuth$AccessDenied;
		case 'unsupported_response_type':
			return $truqu$elm_oauth2$OAuth$UnsupportedResponseType;
		case 'invalid_scope':
			return $truqu$elm_oauth2$OAuth$InvalidScope;
		case 'server_error':
			return $truqu$elm_oauth2$OAuth$ServerError;
		case 'temporarily_unavailable':
			return $truqu$elm_oauth2$OAuth$TemporarilyUnavailable;
		default:
			return $truqu$elm_oauth2$OAuth$Custom(str);
	}
};
var $truqu$elm_oauth2$Internal$errorDecoder = function (errorCodeFromString) {
	return A2(
		$elm$json$Json$Decode$map,
		errorCodeFromString,
		A2($elm$json$Json$Decode$field, 'error', $elm$json$Json$Decode$string));
};
var $truqu$elm_oauth2$OAuth$AuthorizationCode$defaultErrorDecoder = $truqu$elm_oauth2$Internal$errorDecoder($truqu$elm_oauth2$OAuth$errorCodeFromString);
var $truqu$elm_oauth2$OAuth$AuthorizationCode$defaultAuthenticationErrorDecoder = $truqu$elm_oauth2$Internal$authenticationErrorDecoder($truqu$elm_oauth2$OAuth$AuthorizationCode$defaultErrorDecoder);
var $author$project$InPageOAuth$NoOp = {$: 0};
var $author$project$InPageOAuth$UserInfoLoaded = function (a) {
	return {$: 4, a: a};
};
var $author$project$InPageOAuth$gotUserInfo = function (res) {
	if (!res.$) {
		var prof = res.a;
		return $author$project$InPageOAuth$Internal(
			$author$project$InPageOAuth$UserInfoLoaded(prof));
	} else {
		return $author$project$InPageOAuth$Internal($author$project$InPageOAuth$NoOp);
	}
};
var $author$project$InPageOAuth$getUserInfo = F2(
	function (_v0, token) {
		var profileEndpoint = _v0.mD;
		var decoder = _v0.kA;
		return $elm$http$Http$request(
			{
				j$: $elm$http$Http$emptyBody,
				kP: A2($elm$http$Http$expectJson, $author$project$InPageOAuth$gotUserInfo, decoder),
				hk: A2(
					$truqu$elm_oauth2$OAuth$useToken,
					token,
					_List_fromArray(
						[
							A2(
							$elm$http$Http$header,
							'X-Hack-Authorization',
							$truqu$elm_oauth2$OAuth$tokenToString(token))
						])),
				lM: 'GET',
				nF: $elm$core$Maybe$Nothing,
				nK: $elm$core$Maybe$Nothing,
				c8: $elm$url$Url$toString(profileEndpoint)
			});
	});
var $author$project$InPageOAuth$handleGotToken = F3(
	function (model, config, res) {
		if (res.$ === 1) {
			if (res.a.$ === 4) {
				var body = res.a.a;
				var _v1 = A2($elm$json$Json$Decode$decodeString, $truqu$elm_oauth2$OAuth$AuthorizationCode$defaultAuthenticationErrorDecoder, body);
				if (!_v1.$) {
					var error = _v1.a.kL;
					var errorDescription = _v1.a.kM;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			} else {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cj: $author$project$InPageOAuth$AuthError('Unable to get a valid token.')
						}),
					$elm$core$Platform$Cmd$none);
			}
		} else {
			var token = res.a.nI;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cj: $author$project$InPageOAuth$GettingUserInfo,
						aq: $author$project$PAM$User$TokenReceived(
							$elm$core$Maybe$Just(token))
					}),
				A2($author$project$InPageOAuth$getUserInfo, config, token));
		}
	});
var $truqu$elm_oauth2$Internal$Code = 0;
var $truqu$elm_oauth2$Internal$responseTypeToString = function (r) {
	if (!r) {
		return 'code';
	} else {
		return 'token';
	}
};
var $truqu$elm_oauth2$Internal$urlAddList = F3(
	function (param, xs, qs) {
		return _Utils_ap(
			qs,
			function () {
				if (!xs.b) {
					return _List_Nil;
				} else {
					return _List_fromArray(
						[
							A2(
							$elm$url$Url$Builder$string,
							param,
							A2($elm$core$String$join, ' ', xs))
						]);
				}
			}());
	});
var $truqu$elm_oauth2$Internal$urlAddMaybe = F3(
	function (param, ms, qs) {
		return _Utils_ap(
			qs,
			function () {
				if (ms.$ === 1) {
					return _List_Nil;
				} else {
					var s = ms.a;
					return _List_fromArray(
						[
							A2($elm$url$Url$Builder$string, param, s)
						]);
				}
			}());
	});
var $truqu$elm_oauth2$Internal$makeAuthUrl = F2(
	function (responseType, _v0) {
		var clientId = _v0.a0;
		var url = _v0.c8;
		var redirectUri = _v0.aU;
		var scope = _v0.cg;
		var state = _v0.cj;
		var query = A2(
			$elm$core$String$dropLeft,
			1,
			$elm$url$Url$Builder$toQuery(
				A3(
					$truqu$elm_oauth2$Internal$urlAddMaybe,
					'state',
					state,
					A3(
						$truqu$elm_oauth2$Internal$urlAddList,
						'scope',
						scope,
						_List_fromArray(
							[
								A2($elm$url$Url$Builder$string, 'client_id', clientId),
								A2(
								$elm$url$Url$Builder$string,
								'redirect_uri',
								$truqu$elm_oauth2$Internal$makeRedirectUri(redirectUri)),
								A2(
								$elm$url$Url$Builder$string,
								'response_type',
								$truqu$elm_oauth2$Internal$responseTypeToString(responseType))
							])))));
		var _v1 = url.$9;
		if (_v1.$ === 1) {
			return _Utils_update(
				url,
				{
					$9: $elm$core$Maybe$Just(query)
				});
		} else {
			var baseQuery = _v1.a;
			return _Utils_update(
				url,
				{
					$9: $elm$core$Maybe$Just(baseQuery + ('&' + query))
				});
		}
	});
var $truqu$elm_oauth2$OAuth$AuthorizationCode$makeAuthUrl = $truqu$elm_oauth2$Internal$makeAuthUrl(0);
var $author$project$InPageOAuth$noop = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$InPageOAuth$openAuth = _Platform_outgoingPort('openAuth', $elm$json$Json$Encode$string);
var $author$project$PAM$User$LoggedIn = function (a) {
	return {$: 3, a: a};
};
var $author$project$InPageOAuth$setACLs = F2(
	function (model, acls) {
		var _v0 = model.aq;
		if (_v0.$ === 3) {
			var um = _v0.a;
			return _Utils_update(
				model,
				{
					aq: $author$project$PAM$User$LoggedIn(
						_Utils_update(
							um,
							{jz: acls}))
				});
		} else {
			return model;
		}
	});
var $author$project$PAM$User$User = F3(
	function (profile, acls, token) {
		return {jz: acls, mC: profile, nI: token};
	});
var $author$project$InPageOAuth$defaultUser = F2(
	function (profile, token) {
		return A3($author$project$PAM$User$User, profile, _List_Nil, token);
	});
var $author$project$InPageOAuth$setUser = F2(
	function (model, profile) {
		var _v0 = model.aq;
		switch (_v0.$) {
			case 3:
				var um = _v0.a;
				return _Utils_update(
					model,
					{
						aq: $author$project$PAM$User$LoggedIn(
							_Utils_update(
								um,
								{mC: profile}))
					});
			case 2:
				var token = _v0.a;
				return _Utils_update(
					model,
					{
						aq: $author$project$PAM$User$LoggedIn(
							A2($author$project$InPageOAuth$defaultUser, profile, token))
					});
			default:
				return model;
		}
	});
var $author$project$InPageOAuth$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 2:
				var code = msg.a;
				return A2($author$project$InPageOAuth$handleGotCode, model, code);
			case 3:
				var res = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$InPageOAuth$noop(model),
					A2(
						$elm$core$Maybe$map,
						function (config) {
							return A3($author$project$InPageOAuth$handleGotToken, model, config, res);
						},
						model.bn));
			case 4:
				var profile = msg.a;
				return $author$project$InPageOAuth$getACLs(
					A2($author$project$InPageOAuth$setUser, model, profile));
			case 5:
				var acls = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$InPageOAuth$setACLs,
						_Utils_update(
							model,
							{cj: $author$project$InPageOAuth$Authorized}),
						acls),
					A2(
						$elm$core$Task$perform,
						function (dontCareAboutThisValue) {
							return $author$project$InPageOAuth$UserAuthorized;
						},
						$elm$core$Task$succeed('Hello')));
			case 1:
				var _v1 = model.cj;
				switch (_v1.$) {
					case 0:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{cj: $author$project$InPageOAuth$Pending}),
							A2(
								$elm$core$Task$perform,
								$elm$core$Basics$always(
									$author$project$InPageOAuth$Internal($author$project$InPageOAuth$Timeout)),
								$elm$core$Process$sleep(1000)));
					case 1:
						var _v2 = _Utils_Tuple2(model.bn, model.bR);
						if ((!_v2.a.$) && (!_v2.b.$)) {
							var config = _v2.a.a;
							var settings = _v2.b.a;
							if (!settings.jT.lP) {
								var auth = {
									a0: config.a0,
									aU: config.aU,
									cg: config.cg,
									cj: $elm$core$Maybe$Just('PAM.init'),
									c8: config.eM
								};
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{cj: $author$project$InPageOAuth$Unauthorized}),
									$author$project$InPageOAuth$openAuth(
										$elm$url$Url$toString(
											$truqu$elm_oauth2$OAuth$AuthorizationCode$makeAuthUrl(auth))));
							} else {
								return $author$project$InPageOAuth$getACLs(
									A2(
										$author$project$InPageOAuth$setUser,
										_Utils_update(
											model,
											{
												cj: $author$project$InPageOAuth$Authorized,
												aq: $author$project$PAM$User$TokenReceived($elm$core$Maybe$Nothing)
											}),
										{
											kI: 'DEMO',
											r: '00000000-0000-0000-0000-000000000000',
											fu: 'DEMO',
											iz: _List_fromArray(
												['DEMO'])
										}));
							}
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{cj: $author$project$InPageOAuth$Unauthorized}),
								$elm$core$Platform$Cmd$none);
						}
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var _v3 = model.bn;
				if (!_v3.$) {
					var config = _v3.a;
					var auth = {
						a0: config.a0,
						aU: config.aU,
						cg: config.cg,
						cj: $elm$core$Maybe$Just('PAM.init'),
						c8: config.eM
					};
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cj: $author$project$InPageOAuth$Unauthorized}),
						$author$project$InPageOAuth$openAuth(
							$elm$url$Url$toString(
								$truqu$elm_oauth2$OAuth$AuthorizationCode$makeAuthUrl(auth))));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var error = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cj: $author$project$InPageOAuth$AuthError(error)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return $author$project$InPageOAuth$noop(model);
		}
	});
var $author$project$PAM$updateOAuth = F2(
	function (model, msg) {
		var _v0 = A2($author$project$InPageOAuth$update, model.ar, msg);
		var noa = _v0.a;
		var noacmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{ar: noa}),
			A2($elm$core$Platform$Cmd$map, $author$project$PAM$authTranslator, noacmd));
	});
var $author$project$PlugMap$Plugins$Drawing$hideDrawingCmd = _Platform_outgoingPort(
	'hideDrawingCmd',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$PlugMap$Plugins$Drawing$hide = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{kJ: false}),
		$author$project$PlugMap$Plugins$Drawing$hideDrawingCmd(0));
};
var $author$project$Fields$GeometryField$destruct = function (model) {
	var dm = model;
	var _v0 = $author$project$PlugMap$Plugins$Drawing$hide(model.m);
	var drawMod = _v0.a;
	var sdc = _v0.b;
	var _v1 = $author$project$PlugMap$Plugins$FeaturePicker$destruct(model.d.D);
	var fpModel = _v1.a;
	var fpCmd = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			dm,
			{m: drawMod}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$drawingTranslator, sdc),
					A2($elm$core$Platform$Cmd$map, $author$project$Fields$GeometryField$featurePickerTranslator, fpCmd)
				])));
};
var $author$project$Fields$GenericField$destruct = function (fieldModel) {
	if ((!fieldModel.$) && (fieldModel.a.$ === 3)) {
		var modl = fieldModel.a.a;
		return function (_v1) {
			var mdl = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple2(
				$elm$core$Maybe$Just(
					$author$project$Fields$GenericField$GeometryModel(mdl)),
				A2($elm$core$Platform$Cmd$map, $author$project$Fields$GenericField$geometryInitTranslator, cmd));
		}(
			$author$project$Fields$GeometryField$destruct(modl));
	} else {
		return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$PAM$EditableFields$destructField = F3(
	function (model, row, col) {
		var _v0 = $author$project$Fields$GenericField$destruct(model);
		var gm = _v0.a;
		var gc = _v0.b;
		return _Utils_Tuple2(
			gm,
			A2(
				$elm$core$Platform$Cmd$map,
				A2($author$project$PAM$EditableFields$genericFieldTranslator, row, col),
				gc));
	});
var $author$project$PAM$EditableFields$destructRow = F2(
	function (rowModels, rowNum) {
		return function (_v0) {
			var models = _v0.a;
			var cmds = _v0.b;
			return _Utils_Tuple2(
				models,
				$elm$core$Platform$Cmd$batch(cmds));
		}(
			$elm$core$List$unzip(
				A2(
					$elm$core$List$indexedMap,
					function (index) {
						return function (model) {
							return A3($author$project$PAM$EditableFields$destructField, model, rowNum, index);
						};
					},
					rowModels)));
	});
var $author$project$PAM$EditableFields$destruct = function (models) {
	return function (_v0) {
		var nmodels = _v0.a;
		var cmds = _v0.b;
		return _Utils_Tuple2(
			nmodels,
			$elm$core$Platform$Cmd$batch(cmds));
	}(
		$elm$core$List$unzip(
			A2(
				$elm$core$List$indexedMap,
				function (index) {
					return function (rowModels) {
						return A2($author$project$PAM$EditableFields$destructRow, rowModels, index);
					};
				},
				models)));
};
var $author$project$PAM$destructDetailFor = function (models) {
	return function (_v0) {
		var m = _v0.a;
		var c = _v0.b;
		return _Utils_Tuple2(
			m,
			A2($elm$core$Platform$Cmd$map, $author$project$PAM$deetsTranslator, c));
	}(
		$author$project$PAM$EditableFields$destruct(models));
};
var $author$project$PAM$Object$Repo$saveBackup = function (_v0) {
	var repo = _v0.a;
	var backup = _v0.b;
	return (!$elm$core$Dict$isEmpty(backup)) ? A2($author$project$PAM$Object$Repo$Repo, repo, backup) : A2($author$project$PAM$Object$Repo$Repo, repo, repo);
};
var $author$project$PAM$Object$Repo$editObject = F2(
	function (obj, repo) {
		var dirtyObj = _Utils_update(
			obj,
			{lo: true});
		return A2(
			$author$project$PAM$Object$Repo$changeObject,
			dirtyObj,
			$author$project$PAM$Object$Repo$saveBackup(repo));
	});
var $author$project$PAM$Object$Repo$naiveInsert = F2(
	function (obj, _v0) {
		var repo = _v0.a;
		var backup = _v0.b;
		return A2(
			$author$project$PAM$Object$Repo$Repo,
			A3($elm$core$Dict$insert, obj.r, obj, repo),
			backup);
	});
var $author$project$PAM$Object$Repo$newObject = F2(
	function (objType, repo) {
		var newObj = $author$project$PAM$Object$newObjectFromObjectType(objType);
		return A2($author$project$PAM$Object$Repo$naiveInsert, newObj, repo);
	});
var $author$project$Ports$preventLeave = _Platform_outgoingPort(
	'preventLeave',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$triggerAnimationCmd = _Platform_outgoingPort('triggerAnimationCmd', $elm$core$Basics$identity);
var $author$project$PAM$Route$getObjectId = function (route) {
	switch (route.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Just(-1);
		case 3:
			return $elm$core$Maybe$Just(-1);
		case 4:
			var objId = route.b;
			return $elm$core$Maybe$Just(objId);
		default:
			var objId = route.b;
			return $elm$core$Maybe$Just(objId);
	}
};
var $author$project$PAM$Route$tryGetObjectId = function (route) {
	return A2($elm$core$Maybe$andThen, $author$project$PAM$Route$getObjectId, route);
};
var $author$project$PAM$Route$tryGetQueryParams = function (maybeRoute) {
	return A2($elm$core$Maybe$map, $author$project$PAM$Route$getQueryParams, maybeRoute);
};
var $author$project$PAM$updateRoute = F2(
	function (maybeRoute, model) {
		var targetRoute = function () {
			if ((!maybeRoute.$) && (maybeRoute.a.$ === 2)) {
				var _v34 = maybeRoute.a;
				return 'newObject';
			} else {
				return '';
			}
		}();
		var switchToNotEditing = (!$author$project$PAM$isEditingRoute(maybeRoute)) && $author$project$PAM$isEditingRoute(model.mS);
		var switchToEditing = $author$project$PAM$isEditingRoute(maybeRoute) && (!$author$project$PAM$isEditingRoute(model.mS));
		var sidebarCmd = A2(
			$elm$core$Maybe$map,
			function (_v31) {
				var sidebar = _v31.iP;
				if (sidebar) {
					return 'expandLeftSidebar';
				} else {
					return 'collapseLeftSidebar';
				}
			},
			$author$project$PAM$Route$tryGetQueryParams(maybeRoute));
		var routeOIDChanged = !_Utils_eq(
			$author$project$PAM$Route$tryGetObjectId(model.mS),
			$author$project$PAM$Route$tryGetObjectId(maybeRoute));
		var modelRoute = function () {
			var _v29 = model.mS;
			if ((!_v29.$) && (_v29.a.$ === 3)) {
				var _v30 = _v29.a;
				return 'linkNewObject';
			} else {
				return '';
			}
		}();
		var shouldDestructDetails = function () {
			if (!routeOIDChanged) {
				if (targetRoute === 'newObject') {
					if (modelRoute === 'linkNewObject') {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			} else {
				return true;
			}
		}();
		var newModel = _Utils_update(
			model,
			{
				b: shouldDestructDetails ? $author$project$PAM$Object$Repo$clearNewObject(model.b) : model.b,
				mS: maybeRoute
			});
		var _v0 = function () {
			if (!maybeRoute.$) {
				switch (maybeRoute.a.$) {
					case 0:
						var params = maybeRoute.a.a;
						return _Utils_Tuple2(
							newModel,
							$author$project$Ports$triggerAnimationCmd(
								$elm$json$Json$Encode$string(
									A2($elm$core$Maybe$withDefault, 'collapseLeftSidebar', sidebarCmd))));
					case 1:
						var params = maybeRoute.a.a;
						return _Utils_Tuple2(
							newModel,
							$author$project$Ports$triggerAnimationCmd(
								$elm$json$Json$Encode$string(
									A2($elm$core$Maybe$withDefault, 'collapseLeftSidebar', sidebarCmd))));
					case 2:
						var _v2 = maybeRoute.a;
						var objTypeKey = _v2.a;
						var params = _v2.b;
						var _v3 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, newModel.a.c);
						if (!_v3.$) {
							var _v4 = _v3.a;
							var objTypeId = _v4.a;
							var newType = _v4.b;
							var newRepo = function () {
								var _v6 = $author$project$PAM$Object$Repo$getNewObject(newModel.b);
								if (_v6.$ === 1) {
									return A2($author$project$PAM$Object$Repo$newObject, newType, newModel.b);
								} else {
									return newModel.b;
								}
							}();
							var _v5 = routeOIDChanged ? A3(
								$author$project$PAM$initFields,
								maybeRoute,
								_Utils_update(
									newModel,
									{b: newRepo}),
								true) : _Utils_Tuple2(
								_Utils_update(
									newModel,
									{b: newRepo}),
								$elm$core$Platform$Cmd$none);
							var updatedModel = _v5.a;
							var newFieldCmds = _v5.b;
							return _Utils_Tuple2(
								updatedModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											$author$project$Ports$triggerAnimationCmd(
											$elm$json$Json$Encode$string(
												A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd))),
											newFieldCmds
										])));
						} else {
							return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
						}
					case 3:
						var _v7 = maybeRoute.a;
						var parentObjTypeKey = _v7.a;
						var parentObjId = _v7.b;
						var childObjTypeKey = _v7.c;
						var params = _v7.d;
						var _v8 = A2($author$project$PAM$Model$ObjectTypes$findByKey, childObjTypeKey, newModel.a.c);
						if (!_v8.$) {
							var _v9 = _v8.a;
							var objTypeId = _v9.a;
							var childType = _v9.b;
							var newRepo = function () {
								var _v11 = $author$project$PAM$Object$Repo$getNewObject(newModel.b);
								if (_v11.$ === 1) {
									return A2($author$project$PAM$Object$Repo$newObject, childType, newModel.b);
								} else {
									return newModel.b;
								}
							}();
							var _v10 = routeOIDChanged ? A3(
								$author$project$PAM$initFields,
								maybeRoute,
								_Utils_update(
									newModel,
									{b: newRepo}),
								true) : _Utils_Tuple2(
								_Utils_update(
									newModel,
									{b: newRepo}),
								$elm$core$Platform$Cmd$none);
							var updatedModel = _v10.a;
							var newFieldCmds = _v10.b;
							return _Utils_Tuple2(
								$author$project$PAM$expandLeftSidebar(updatedModel),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											$author$project$Ports$triggerAnimationCmd(
											$elm$json$Json$Encode$string(
												A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd))),
											newFieldCmds
										])));
						} else {
							return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
						}
					case 4:
						var _v12 = maybeRoute.a;
						var objTypeKey = _v12.a;
						var objId = _v12.b;
						var params = _v12.c;
						if (A2($author$project$PAM$Object$Repo$contains, objId, newModel.b)) {
							var _v13 = _Utils_Tuple2(
								A2($author$project$PAM$Object$Repo$getObject, objId, model.b),
								A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, newModel.a.c));
							_v13$2:
							while (true) {
								if (!_v13.a.$) {
									if (!_v13.b.$) {
										var obj = _v13.a.a;
										var _v14 = _v13.b.a;
										var objTypeId = _v14.a;
										var type_ = _v14.b;
										if (routeOIDChanged || switchToNotEditing) {
											var _v15 = A3($author$project$PAM$initFields, maybeRoute, newModel, !switchToNotEditing);
											var o = _v15.a;
											var a = _v15.b;
											return _Utils_Tuple2(
												o,
												$elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															$author$project$Ports$triggerAnimationCmd(
															$elm$json$Json$Encode$string(
																A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd))),
															A3($author$project$PAM$requestLongObject, model, type_, objId)
														])));
										} else {
											return _Utils_Tuple2(
												newModel,
												$elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															$author$project$Ports$triggerAnimationCmd(
															$elm$json$Json$Encode$string(
																A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd)))
														])));
										}
									} else {
										break _v13$2;
									}
								} else {
									if (!_v13.b.$) {
										var _v16 = _v13.a;
										var _v17 = _v13.b.a;
										var objTypeId = _v17.a;
										var type_ = _v17.b;
										return _Utils_Tuple2(
											newModel,
											$elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														$author$project$Ports$triggerAnimationCmd(
														$elm$json$Json$Encode$string(
															A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd))),
														A3($author$project$PAM$requestLongObject, model, type_, objId)
													])));
									} else {
										break _v13$2;
									}
								}
							}
							return _Utils_Tuple2(
								newModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											$author$project$Ports$triggerAnimationCmd(
											$elm$json$Json$Encode$string(
												A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd)))
										])));
						} else {
							var _v18 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, newModel.a.c);
							if (!_v18.$) {
								var _v19 = _v18.a;
								var objTypeId = _v19.a;
								var type_ = _v19.b;
								return _Utils_Tuple2(
									newModel,
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												$author$project$Ports$triggerAnimationCmd(
												$elm$json$Json$Encode$string(
													A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd))),
												A3($author$project$PAM$requestLongObject, model, type_, objId)
											])));
							} else {
								return _Utils_Tuple2(
									newModel,
									$author$project$Ports$triggerAnimationCmd(
										$elm$json$Json$Encode$string(
											A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd))));
							}
						}
					default:
						var _v20 = maybeRoute.a;
						var objTypeKey = _v20.a;
						var objId = _v20.b;
						var params = _v20.c;
						if (A2($author$project$PAM$Object$Repo$contains, objId, newModel.b)) {
							var _v21 = A2($author$project$PAM$Object$Repo$getObject, objId, model.b);
							if (!_v21.$) {
								var obj = _v21.a;
								var newRepo = A2($author$project$PAM$Object$Repo$editObject, obj, newModel.b);
								var _v22 = (routeOIDChanged || switchToEditing) ? A3(
									$author$project$PAM$initFields,
									maybeRoute,
									_Utils_update(
										newModel,
										{b: newRepo}),
									false) : _Utils_Tuple2(
									_Utils_update(
										newModel,
										{b: newRepo}),
									$elm$core$Platform$Cmd$none);
								var newFields = _v22.a;
								var newFieldsCmd = _v22.b;
								return _Utils_Tuple2(
									newFields,
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												$author$project$Ports$preventLeave(0),
												newFieldsCmd,
												$author$project$Ports$triggerAnimationCmd(
												$elm$json$Json$Encode$string(
													A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd)))
											])));
							} else {
								return _Utils_Tuple2(
									newModel,
									$author$project$Ports$triggerAnimationCmd(
										$elm$json$Json$Encode$string(
											A2($elm$core$Maybe$withDefault, 'collapseLeftSidebar', sidebarCmd))));
							}
						} else {
							var _v23 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, newModel.a.c);
							if (!_v23.$) {
								var _v24 = _v23.a;
								var objTypeId = _v24.a;
								var type_ = _v24.b;
								return _Utils_Tuple2(
									newModel,
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												$author$project$Ports$triggerAnimationCmd(
												$elm$json$Json$Encode$string(
													A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd))),
												A3($author$project$PAM$requestLongObject, model, type_, objId)
											])));
							} else {
								return _Utils_Tuple2(
									newModel,
									$author$project$Ports$triggerAnimationCmd(
										$elm$json$Json$Encode$string(
											A2($elm$core$Maybe$withDefault, 'expandLeftSidebar', sidebarCmd))));
							}
						}
				}
			} else {
				return _Utils_Tuple2(
					newModel,
					$author$project$Ports$triggerAnimationCmd(
						$elm$json$Json$Encode$string(
							A2($elm$core$Maybe$withDefault, 'collapseLeftSidebar', sidebarCmd))));
			}
		}();
		var updatedRouteModel = _v0.a;
		var updatedCmds = _v0.b;
		var _v25 = ($elm$core$List$length(model.e) > 0) ? $author$project$PAM$destructDetailFor(model.e) : _Utils_Tuple2(_List_Nil, $elm$core$Platform$Cmd$none);
		var oldDetailModels = _v25.a;
		var oldDetailCmds = _v25.b;
		return _Utils_Tuple2(
			updatedRouteModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						shouldDestructDetails ? oldDetailCmds : $elm$core$Platform$Cmd$none,
						updatedCmds,
						A2(
						$elm$core$Task$perform,
						function (dontCareAboutThisValue) {
							return switchToEditing ? $author$project$PAM$Message$StartEditing : (switchToNotEditing ? $author$project$PAM$Message$StopEditing(model.e) : $author$project$PAM$Message$NoOp);
						},
						$elm$core$Task$succeed('Hello'))
					])));
	});
var $author$project$PAM$gridUpdate = F2(
	function (sub, model) {
		var gridState = model.aN;
		var _v46 = A2($author$project$Grid$Grid$update, gridState.aM, sub);
		var newGridModel = _v46.a;
		var newGridMsg = _v46.b;
		var outMsg = _v46.c;
		var updatedGridState = _Utils_update(
			gridState,
			{aM: newGridModel});
		var newModel = _Utils_update(
			model,
			{aN: updatedGridState});
		var newCmds = A2($elm$core$Platform$Cmd$map, $author$project$PAM$Message$GridMsg, newGridMsg);
		if (!outMsg.$) {
			var msg = outMsg.a;
			return A2($author$project$PAM$updateModel, msg, newModel);
		} else {
			return _Utils_Tuple2(newModel, newCmds);
		}
	});
var $author$project$PAM$updateModel = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return $author$project$PAM$noop(model);
			case 74:
				var ddd = msg.a;
				var pickYear = $justinmimbs$date$Date$year(ddd);
				var newToDatePicker = $author$project$PAM$UI$DatePicker$initFromDate(ddd);
				var newDatePicker = $author$project$PAM$UI$DatePicker$initFromDate(ddd);
				var _v1 = A3(
					$author$project$PAM$UI$DatePicker$update,
					$author$project$PAM$UI$DateRangeFilter$toPickerSettings(false),
					$author$project$PAM$UI$DatePicker$pick(ddd),
					newToDatePicker);
				var newPicker1 = _v1.a;
				var evt1 = _v1.b;
				var _v2 = A3(
					$author$project$PAM$UI$DatePicker$update,
					$author$project$PAM$UI$DateRangeFilter$pickerSettings(false),
					$author$project$PAM$UI$DatePicker$pick(
						A3($justinmimbs$date$Date$fromCalendarDate, pickYear, 0, 1)),
					newDatePicker);
				var newPicker = _v2.a;
				var evt = _v2.b;
				var newDateRangeFilterModel = function () {
					var picker = newPicker1;
					return {
						dM: $elm$core$Maybe$Just(ddd),
						kw: newPicker,
						nH: newPicker1
					};
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bo: newDateRangeFilterModel}),
					$elm$core$Platform$Cmd$none);
			case 71:
				var sub = msg.a;
				var _v3 = A2($author$project$PAM$Header$update, model.cN, sub);
				var newHeader = _v3.a;
				var newHeaderCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cN: newHeader}),
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$headerTranslator, newHeaderCmd));
			case 64:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dx: true}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					var _v5 = url.e8;
					if (_v5.$ === 1) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.lw,
								$elm$url$Url$toString(url)));
					}
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 2:
				var url = msg.a;
				return A2(
					$author$project$PAM$updateRoute,
					$author$project$PAM$Route$fromUrl(url),
					model);
			case 3:
				var route = msg.a;
				return A2($author$project$PAM$updateRoute, route, model);
			case 4:
				var x = msg.a;
				var y = msg.b;
				var newSize = A2($author$project$PAM$UI$Basic$WindowSize, x, y);
				var newDevice = $mdgriffith$elm_ui$Element$classifyDevice(newSize);
				var newPanelDimensions = function () {
					var adjustedHeight = function () {
						var _v7 = model.bR.M.d$;
						if ((!_v7.$) && (_v7.a === 'noDrawer')) {
							return 1;
						} else {
							return 2;
						}
					}();
					return A3($author$project$PAM$UI$Basic$calculatePanelDimensions, newSize, newDevice, adjustedHeight);
				}();
				var newModel = _Utils_update(
					model,
					{eV: newDevice, fD: newPanelDimensions, f5: newSize});
				var _v6 = model.m8;
				if (!_v6) {
					return _Utils_Tuple2(
						$author$project$PAM$expandLeftSidebar(newModel),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						$author$project$PAM$collapseLeftSidebar(newModel),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var sub = msg.a;
				return A2($author$project$PAM$gridUpdate, sub, model);
			case 6:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$PAM$mapTranslater,
						$author$project$PAM$Map$refresh(model.h)));
			case 7:
				var object = msg.a;
				var newRepo = A2($author$project$PAM$Object$Repo$changeObject, object, model.b);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b: newRepo}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var id = msg.a.r;
				return A2($author$project$PAM$requestObjectsFor, id, model);
			case 10:
				var objTypeId = msg.a;
				var modelFieldId = msg.b;
				var newModelInfo = A2(
					$elm$core$Maybe$map,
					function (gridLayouts) {
						return A2($author$project$PAM$Model$ModelInfo$setGridLayouts, gridLayouts, model.a);
					},
					A2(
						$elm$core$Maybe$map,
						function (gridLayout) {
							return A3($author$project$PAM$Model$GridLayouts$insert, objTypeId, gridLayout, model.a.dW);
						},
						A2(
							$elm$core$Maybe$map,
							$author$project$PAM$Model$GridLayouts$toggleFieldDisplay(modelFieldId),
							A2($author$project$PAM$Model$GridLayouts$get, objTypeId, model.a.dW))));
				if (!newModelInfo.$) {
					var info = newModelInfo.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a: info}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 11:
				var result = msg.a;
				return A2($author$project$PAM$handleUpdateLoadedObject, model, result);
			case 12:
				var result = msg.a;
				return A2($author$project$PAM$handleUpdateLoadedObjects, model, result);
			case 13:
				var subMsg = msg.a;
				return A2($author$project$PAM$handleConfirm, model, subMsg);
			case 14:
				var obj = msg.a;
				return A2($author$project$PAM$saveDetails, obj, model);
			case 15:
				var parentRef = msg.a;
				var childObj = msg.b;
				return A3($author$project$PAM$saveAndLinkDetails, parentRef, childObj, model);
			case 16:
				var childRef = msg.a;
				var parentObj = msg.b;
				return A3($author$project$PAM$unlinkObject, childRef, parentObj, model);
			case 18:
				var parentObj = msg.a;
				var childObjType = msg.b;
				return A3($author$project$PAM$openLinkExistingObjectModal, parentObj, childObjType, model);
			case 17:
				var subMsg = msg.a;
				return A2($author$project$PAM$handleLinkExistingMsg, subMsg, model);
			case 19:
				var obj = msg.a;
				return $author$project$PAM$cancelNewObject(model);
			case 25:
				var objTypeKey = msg.a;
				var cmd = A2(
					$author$project$PAM$Route$pushUrl,
					model.lw,
					A2(
						$author$project$PAM$Route$setSidebarParam,
						true,
						A2(
							$author$project$PAM$Route$setThemesParam,
							false,
							A3($author$project$PAM$Route$initNewObject, objTypeKey, $elm$core$Maybe$Nothing, model.mS))));
				var _v9 = model.mS;
				_v9$3:
				while (true) {
					if (!_v9.$) {
						switch (_v9.a.$) {
							case 2:
								var _v10 = _v9.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											j: $author$project$PAM$Modal$ConfirmModal(
												$author$project$PAM$confirmation_CancelNew(cmd))
										}),
									$elm$core$Platform$Cmd$none);
							case 3:
								var _v11 = _v9.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											j: $author$project$PAM$Modal$ConfirmModal(
												$author$project$PAM$confirmation_CancelNewLink(cmd))
										}),
									$elm$core$Platform$Cmd$none);
							case 5:
								var _v12 = _v9.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											j: $author$project$PAM$Modal$ConfirmModal(
												$author$project$PAM$confirmation_CancelEdit(cmd))
										}),
									$elm$core$Platform$Cmd$none);
							default:
								break _v9$3;
						}
					} else {
						break _v9$3;
					}
				}
				return _Utils_Tuple2(model, cmd);
			case 20:
				var objTypeKey = msg.a;
				var objId = msg.b;
				return A3($author$project$PAM$handleClickDeleteObject, objTypeKey, objId, model);
			case 29:
				var objId = msg.a;
				var response = msg.b;
				return A3($author$project$PAM$handleObjectDeleted, objId, response, model);
			case 21:
				var objTypeKey = msg.a;
				var objId = msg.b;
				var cmd = A2(
					$author$project$PAM$Route$pushUrl,
					model.lw,
					A2(
						$author$project$PAM$Route$setSidebarParam,
						true,
						A2(
							$author$project$PAM$Route$setThemesParam,
							false,
							A4($author$project$PAM$Route$initShowObjectDetails, objTypeKey, objId, $elm$core$Maybe$Nothing, model.mS))));
				var _v13 = model.mS;
				_v13$3:
				while (true) {
					if (!_v13.$) {
						switch (_v13.a.$) {
							case 2:
								var _v14 = _v13.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											j: $author$project$PAM$Modal$ConfirmModal(
												$author$project$PAM$confirmation_CancelNew(cmd))
										}),
									$elm$core$Platform$Cmd$none);
							case 3:
								var _v15 = _v13.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											j: $author$project$PAM$Modal$ConfirmModal(
												$author$project$PAM$confirmation_CancelNewLink(cmd))
										}),
									$elm$core$Platform$Cmd$none);
							case 5:
								var _v16 = _v13.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											j: $author$project$PAM$Modal$ConfirmModal(
												$author$project$PAM$confirmation_CancelEdit(cmd))
										}),
									$elm$core$Platform$Cmd$none);
							default:
								break _v13$3;
						}
					} else {
						break _v13$3;
					}
				}
				return _Utils_Tuple2(model, cmd);
			case 23:
				var returnInfo = msg.a;
				return A2($author$project$PAM$cancelEditDetails, returnInfo, model);
			case 24:
				var returnInfo = msg.a;
				return A2($author$project$PAM$cancelLinkDetails, returnInfo, model);
			case 26:
				var response = msg.a;
				return A2($author$project$PAM$handleNewObjectSavedResponse, response, model);
			case 27:
				var parentRef = msg.a;
				var response = msg.b;
				return A3($author$project$PAM$handleNewLinkedObjectSavedResponse, parentRef, response, model);
			case 28:
				var response = msg.a;
				return A2($author$project$PAM$handleObjectUpdatesSavedResponse, response, model);
			case 30:
				var obj = msg.a;
				return A2(
					$author$project$PAM$zoomToWkt,
					model,
					$author$project$PAM$Object$getWkt(obj));
			case 31:
				var objRef = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$PAM$noop(model),
					A2(
						$elm$core$Maybe$map,
						function (_v17) {
							var id = _v17.a;
							var type_ = _v17.b;
							return A3($author$project$PAM$getObjectForZoom, model, objRef.h_, type_);
						},
						A2($author$project$PAM$Model$ObjectTypes$findByKey, objRef.ja, model.a.c)));
			case 34:
				var queryParams = $author$project$PAM$Route$getQueryParamsOrDefault(model.mS);
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							$author$project$PAM$Route$replaceUrl(model.lw),
							A2($author$project$PAM$Route$trySetThemesParam, !queryParams.s, model.mS))));
			case 32:
				var queryParams = $author$project$PAM$Route$getQueryParamsOrDefault(model.mS);
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							$author$project$PAM$Route$replaceUrl(model.lw),
							A2($author$project$PAM$Route$trySetThemesParam, true, model.mS))));
			case 33:
				var queryParams = $author$project$PAM$Route$getQueryParamsOrDefault(model.mS);
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							$author$project$PAM$Route$replaceUrl(model.lw),
							A2($author$project$PAM$Route$trySetThemesParam, false, model.mS))));
			case 35:
				var category = msg.a;
				var mapModel = model.h;
				var newThemes = A2($author$project$PlugMap$Plugins$Themes$toggleCategoryOpenness, category, mapModel.s);
				var newMapModel = _Utils_update(
					mapModel,
					{s: newThemes});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newMapModel}),
					$elm$core$Platform$Cmd$none);
			case 36:
				var group = msg.a;
				var mapModel = model.h;
				var newThemes = A2($author$project$PlugMap$Plugins$Themes$toggleGroupOpenness, group, mapModel.s);
				var newMapModel = _Utils_update(
					mapModel,
					{s: newThemes});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newMapModel}),
					$elm$core$Platform$Cmd$none);
			case 37:
				var category = msg.a;
				var layerKey = msg.b;
				var mapModel = model.h;
				var newThemes = A3($author$project$PlugMap$Plugins$Themes$toggleLayerSelection, layerKey, category, mapModel.s);
				var newMapModel = _Utils_update(
					mapModel,
					{s: newThemes});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newMapModel}),
					$author$project$Ports$toggleSelectedThemesCmd(
						$author$project$PlugMap$Plugins$Themes$encodeSelectedThemes(newThemes)));
			case 8:
				var mlayer = msg.a;
				if (!mlayer.$) {
					var layer = mlayer.a;
					var mapModel = model.h;
					var cats = A2($author$project$PlugMap$Plugins$Themes$getCategoryFromLayerKey, mapModel.s, layer);
					var nt = A2(
						$elm$core$Maybe$withDefault,
						mapModel.s,
						A2(
							$elm$core$Maybe$map,
							function (c) {
								return A3($author$project$PlugMap$Plugins$Themes$toggleLayerSelection, layer, c, mapModel.s);
							},
							$elm$core$List$head(cats)));
					var newMapModel = _Utils_update(
						mapModel,
						{s: nt});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{h: newMapModel}),
						$author$project$Ports$toggleSelectedThemesCmd(
							$author$project$PlugMap$Plugins$Themes$encodeSelectedThemes(nt)));
				} else {
					var mapModel = model.h;
					var nt = A2($author$project$PlugMap$Plugins$Themes$resetCategory, 'cat_reference', mapModel.s);
					var nm = _Utils_update(
						mapModel,
						{s: nt});
					var refCat = A2($author$project$PlugMap$Plugins$Themes$getCategoryByKey, mapModel.s, 'ref');
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{h: nm}),
						$author$project$Ports$toggleSelectedThemesCmd(
							$author$project$PlugMap$Plugins$Themes$encodeSelectedThemes(nt)));
				}
			case 38:
				var category = msg.a;
				var mapModel = model.h;
				var _v19 = A2($author$project$PlugMap$Plugins$Themes$toggleCategoryTransparency, category, mapModel.s);
				var newCategory = _v19.a;
				var newThemes = _v19.b;
				var newMapModel = _Utils_update(
					mapModel,
					{s: newThemes});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newMapModel}),
					$author$project$Ports$setCategoryTransparencyCmd(
						$author$project$PlugMap$Plugins$Themes$encodeCategoryTransparency(newCategory)));
			case 39:
				var category = msg.a;
				var transparency = msg.b;
				var mapModel = model.h;
				var _v20 = A3($author$project$PlugMap$Plugins$Themes$setCategoryTransparency, transparency, category, mapModel.s);
				var newCategory = _v20.a;
				var newThemes = _v20.b;
				var newMapModel = _Utils_update(
					mapModel,
					{s: newThemes});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newMapModel}),
					$author$project$Ports$setCategoryTransparencyCmd(
						$author$project$PlugMap$Plugins$Themes$encodeCategoryTransparency(newCategory)));
			case 40:
				var _v21 = model.m8;
				if (!_v21) {
					return _Utils_Tuple2(
						$author$project$PAM$collapseLeftSidebar(model),
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								$author$project$PAM$Route$replaceUrl(model.lw),
								A2($author$project$PAM$Route$trySetSidebarParam, false, model.mS))));
				} else {
					return _Utils_Tuple2(
						$author$project$PAM$expandLeftSidebar(model),
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								$author$project$PAM$Route$replaceUrl(model.lw),
								A2($author$project$PAM$Route$trySetSidebarParam, true, model.mS))));
				}
			case 41:
				var _v22 = model.j8;
				if (!_v22) {
					return _Utils_Tuple2(
						$author$project$PAM$collapseBottomDrawer(model),
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								$author$project$PAM$Route$replaceUrl(model.lw),
								A2($author$project$PAM$Route$trySetDrawerParam, false, model.mS))));
				} else {
					return _Utils_Tuple2(
						$author$project$PAM$expandBottomDrawer(model),
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								$author$project$PAM$Route$replaceUrl(model.lw),
								A2($author$project$PAM$Route$trySetDrawerParam, true, model.mS))));
				}
			case 42:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c9: $author$project$PAM$UI$Basic$flipOpenness(model.c9)
						}),
					$elm$core$Platform$Cmd$none);
			case 43:
				var _v23 = model.c9;
				if (!_v23) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c9: 0}),
						$elm$core$Platform$Cmd$none);
				}
			case 44:
				var _v24 = model.c9;
				if (!_v24) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c9: 1}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 45:
				var animMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aI: A2($author$project$Animation$update, animMsg, model.aI),
							aJ: A2($author$project$Animation$update, animMsg, model.aJ),
							aV: A2($author$project$Animation$update, animMsg, model.aV),
							R: A2($author$project$Animation$update, animMsg, model.R),
							aX: A2($author$project$Animation$update, animMsg, model.aX),
							aY: A2($author$project$Animation$update, animMsg, model.aY)
						}),
					$elm$core$Platform$Cmd$none);
			case 46:
				var value = msg.a;
				var _v25 = A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$string, value);
				_v25$2:
				while (true) {
					if (!_v25.$) {
						switch (_v25.a) {
							case 'collapseLeftSidebar':
								return _Utils_Tuple2(
									$author$project$PAM$collapseLeftSidebar(model),
									$elm$core$Platform$Cmd$none);
							case 'expandLeftSidebar':
								return _Utils_Tuple2(
									$author$project$PAM$expandLeftSidebar(model),
									$elm$core$Platform$Cmd$none);
							default:
								break _v25$2;
						}
					} else {
						break _v25$2;
					}
				}
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 47:
				var subMsg = msg.a;
				return A2($author$project$PAM$loadChainUpdate, model, subMsg);
			case 48:
				var str = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$logErrorCmd(
						$elm$json$Json$Encode$string(str)));
			case 49:
				var err = msg.a;
				return A2($author$project$PAM$handleError, model, err);
			case 50:
				var subMsg = msg.a;
				return A2($author$project$PAM$handleMapMsg, model, subMsg);
			case 51:
				var subMsg = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2($author$project$PAM$updateDetails, model, subMsg));
			case 52:
				var res = msg.a;
				return A2($author$project$PAM$handleObjectLoaded, model, res);
			case 57:
				return $author$project$PAM$stopIdentifying(
					A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						$author$project$PAM$startEditingDeetsBuild(model)));
			case 58:
				var models = msg.a;
				return $author$project$PAM$startIdentifying(
					A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2($author$project$PAM$stopEditingDeetsBuild, model, models)));
			case 59:
				var sub = msg.a;
				return A2($author$project$PAM$updateOAuth, model, sub);
			case 60:
				return $author$project$PAM$handleAuthorized(model);
			case 61:
				var whereAt = msg.a;
				return A2($author$project$PAM$handleAPIUnauthorized, model, whereAt);
			case 22:
				var id = msg.a;
				var typeKey = msg.b;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$PAM$noop(model),
					A2(
						$elm$core$Maybe$map,
						function (_v26) {
							var id_ = _v26.a;
							var type_ = _v26.b;
							return A3($author$project$PAM$getObjectDetailsNew, model, id, type_);
						},
						A2($author$project$PAM$Model$ObjectTypes$findByKey, typeKey, model.a.c)));
			case 55:
				var obj = msg.a;
				return A2($author$project$PAM$handleLazyObjectLoaded, model, obj);
			case 56:
				var obj = msg.a;
				return A2($author$project$PAM$handleLazyObjectLoadedZoom, model, obj);
			case 62:
				return $author$project$PAM$noop(model);
			case 53:
				var resp = msg.a;
				return _Utils_Tuple2(
					model,
					A3(
						$elm$core$Basics$apR,
						A3(
							$elm_community$result_extra$Result$Extra$mapBoth,
							function (a) {
								return $elm$core$Task$perform(
									$elm$core$Basics$always(
										$author$project$PAM$Message$DecodeError('Error decoding')));
							},
							function (a) {
								return $elm$core$Task$perform(
									$elm$core$Basics$always(
										$author$project$PAM$Message$ChainLoading(
											$author$project$PAM$Message$GotModelInfo(a))));
							},
							resp),
						$elm_community$result_extra$Result$Extra$merge,
						$elm$core$Task$succeed('Hello')));
			case 54:
				var resp = msg.a;
				if (!resp.$) {
					var floogs = resp.a;
					var refs = function () {
						var _v35 = A2(
							$elm$json$Json$Decode$decodeValue,
							A2(
								$elm$json$Json$Decode$field,
								'referenceLayers',
								$elm$json$Json$Decode$list($author$project$PlugMap$Plugins$FeaturePicker$referenceLayerDecoder)),
							floogs);
						if (!_v35.$) {
							var reefs = _v35.a;
							return reefs;
						} else {
							var err = _v35.a;
							return _List_Nil;
						}
					}();
					var os = model.bR;
					var nt = A2(
						$elm$json$Json$Decode$decodeValue,
						A2($elm$json$Json$Decode$field, 'LeftSidebarSearchTxt', $author$project$PAM$Settings$leftSearchTxtDecoder),
						floogs);
					var nh = A2(
						$elm$json$Json$Decode$decodeValue,
						A2($elm$json$Json$Decode$field, 'header', $author$project$PAM$Settings$headerSettingsDecoder),
						floogs);
					var ns1 = function () {
						if (!nh.$) {
							var h = nh.a;
							return _Utils_update(
								os,
								{fa: h});
						} else {
							return os;
						}
					}();
					var nc = A2(
						$elm$json$Json$Decode$decodeValue,
						A2($elm$json$Json$Decode$field, 'appConfig', $author$project$PAM$Settings$appTypeConfigDecoder),
						floogs);
					var ns2 = function () {
						if (!nc.$) {
							var c = nc.a;
							return _Utils_update(
								ns1,
								{M: c});
						} else {
							return ns1;
						}
					}();
					var ns3 = function () {
						if (!nt.$) {
							var t = nt.a;
							return _Utils_update(
								ns2,
								{lD: t});
						} else {
							return ns2;
						}
					}();
					var defaultPalette = function () {
						var _v31 = A2(
							$elm$json$Json$Decode$decodeValue,
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['palette', 'default']),
								$author$project$PAM$UI$Palette$paletteSetWithDefaultDecoder($author$project$PAM$UI$Palette$utahPalette)),
							floogs);
						if (!_v31.$) {
							var set = _v31.a;
							return $author$project$PAM$UI$Palette$fromSet(set);
						} else {
							return $author$project$PAM$UI$Palette$utPalette;
						}
					}();
					var pellet = A2(
						$elm$json$Json$Decode$decodeValue,
						A2(
							$elm$json$Json$Decode$field,
							'palette',
							$author$project$PAM$UI$Palette$appPaletteWithDefaultDecoder(defaultPalette)),
						floogs);
					var np = function () {
						if (!pellet.$) {
							var pal = pellet.a;
							return pal;
						} else {
							return model.bE;
						}
					}();
					var _v28 = A4($author$project$PAM$Map$init, model.bR, floogs, model.bR.jQ, model.aq);
					var mapModel = _v28.a;
					var mapCmd = _v28.b;
					if (!mapModel.$) {
						var map = mapModel.a;
						return $author$project$PAM$startIdentifying(
							_Utils_Tuple2(
								_Utils_update(
									model,
									{bR: ns3, dR: refs, d9: true, h: map, bE: np}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2($elm$core$Platform$Cmd$map, $author$project$PAM$mapTranslater, mapCmd),
											A2(
											$elm$core$Task$perform,
											$elm$core$Basics$always($author$project$PAM$Message$MapConfigured),
											$elm$core$Process$sleep(100))
										]))));
					} else {
						var err = mapModel.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bR: ns2, bE: np}),
							$author$project$Ports$logErrorCmd(
								$elm$json$Json$Encode$string(err)));
					}
				} else {
					var err = resp.a;
					return $author$project$PAM$noop(model);
				}
			case 63:
				return (!$author$project$PAM$isEditingRoute(model.mS)) ? $author$project$PAM$startIdentifying(
					$author$project$PAM$noop(model)) : $author$project$PAM$stopIdentifying(
					$author$project$PAM$noop(model));
			case 65:
				var sub = msg.a;
				var _v36 = A2($author$project$PAM$UI$Search$update, model.L, sub);
				var newSearch = _v36.a;
				var newSearchCmds = _v36.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newSearch}),
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$searchNewTranslator, newSearchCmds));
			case 72:
				var sub = msg.a;
				var fireMapService = A2($elm$core$Dict$get, 'Timmons Group FireMap AGS REF', model.h.ez);
				var fireMapServiceToken = function () {
					if ((!fireMapService.$) && (fireMapService.a.$ === 1)) {
						var _v39 = fireMapService.a;
						var service = _v39.b;
						return service.nI;
					} else {
						return '';
					}
				}();
				var _v37 = A2($author$project$PAM$UI$LeftSidebarSearch$update, model.aP, sub);
				var newLeftSidebarSearch = _v37.a;
				var newLeftSidebarSearchCmds = _v37.b;
				var newLeftSidebarSearch1 = _Utils_update(
					newLeftSidebarSearch,
					{
						ip: $elm$core$Maybe$Just(fireMapServiceToken)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: newLeftSidebarSearch1}),
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$leftSidebarSearchTranslator, newLeftSidebarSearchCmds));
			case 73:
				var sub = msg.a;
				var _v40 = A2($author$project$PAM$UI$DateRangeFilter$update, sub, model.bo);
				var newdateRangeFilter = _v40.a;
				var newdateRangeFilterCmds = _v40.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bo: newdateRangeFilter}),
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$dateRangeFilterTranslator, newdateRangeFilterCmds));
			case 66:
				var txt = msg.a;
				var ns = (txt === '') ? $author$project$PAM$UI$Search$hideSuggestionView(model.L) : model.L;
				var _v41 = ($elm$core$String$length(txt) > 0) ? A4(
					$author$project$PlugMap$Plugins$Search$fetchSuggestions,
					model.bA,
					model.bR.M.cY,
					$author$project$PAM$Map$getLocation(model.h),
					txt) : $author$project$PlugMap$Plugins$Search$clear(model.bA);
				var newSearch = _v41.a;
				var newCmds = _v41.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bA: newSearch, L: ns}),
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$mapSearchTranslator, newCmds));
			case 67:
				var sub = msg.a;
				var _v42 = A3($author$project$PlugMap$Plugins$Search$update, model, model.bA, sub);
				var nsm = _v42.a;
				var nsc = _v42.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bA: nsm}),
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$mapSearchTranslator, nsc));
			case 68:
				var suggs = msg.a;
				var newSugs = A2(
					$elm$core$List$map,
					function (a) {
						if (!a.$) {
							var data = a.a;
							return {ku: a, eX: data.eE};
						} else {
							var data = a.a;
							return {
								ku: a,
								eX: A2($elm$core$Maybe$withDefault, data.gd, data.hN)
							};
						}
					},
					suggs);
				var newS = A2($author$project$PAM$UI$Search$setSuggestionResults, model.L, newSugs);
				var newSShow = _Utils_update(
					newS,
					{k5: false});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newSShow}),
					$elm$core$Platform$Cmd$none);
			case 69:
				var sug = msg.a;
				var ns = A2(
					$author$project$PAM$UI$Search$setSearch,
					model.L,
					function () {
						if (sug.$ === 1) {
							var data = sug.a;
							return A2($elm$core$Maybe$withDefault, data.gd, data.hN);
						} else {
							var data = sug.a;
							return data.eE;
						}
					}());
				var newSearch = function () {
					if (sug.$ === 1) {
						var data = sug.a;
						return $author$project$PAM$UI$Search$hideSuggestionView(ns);
					} else {
						var data = sug.a;
						return data.hz ? A2($author$project$PAM$UI$Search$setSuggestionResults, ns, _List_Nil) : $author$project$PAM$UI$Search$hideSuggestionView(ns);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newSearch}),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$PAM$mapSearchTranslator,
						A4(
							$author$project$PlugMap$Plugins$Search$fetchFor,
							model,
							model.bR.M.cY,
							$author$project$PAM$Map$getLocation(model.h),
							sug)));
			case 70:
				var newCmd = A3(
					$author$project$PlugMap$Plugins$Search$fetchcandidates,
					model.bR.M.cY,
					$author$project$PAM$Map$getLocation(model.h),
					$author$project$PAM$UI$Search$getSearch(model.L));
				return _Utils_Tuple2(
					model,
					A2($elm$core$Platform$Cmd$map, $author$project$PAM$mapSearchTranslator, newCmd));
			case 75:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var newSearch = $author$project$PAM$UI$Search$setDirectionVisibility(model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newSearch}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$PAM$updateUnauthorized = function (app) {
	return _Utils_Tuple2(
		$author$project$PAM$UserUnauthorized,
		function () {
			if (!app.$) {
				var model = app.a;
				var _v1 = model.bR.jT.nM;
				if (!_v1.$) {
					var redirect = _v1.a;
					return $elm$browser$Browser$Navigation$load(redirect);
				} else {
					return $elm$core$Platform$Cmd$none;
				}
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}());
};
var $author$project$PAM$update = F2(
	function (msg, app) {
		if (msg.$ === 62) {
			return $author$project$PAM$updateUnauthorized(app);
		} else {
			switch (app.$) {
				case 0:
					var model = app.a;
					var _v2 = A2($author$project$PAM$updateModel, msg, model);
					var newModel = _v2.a;
					var newCmd = _v2.b;
					return _Utils_Tuple2(
						$author$project$PAM$AppLoaded(newModel),
						newCmd);
				case 1:
					var err = app.a;
					return _Utils_Tuple2(
						$author$project$PAM$AppFailed(err),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2($author$project$PAM$UserUnauthorized, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$PAM$Message$ToggleBottomDrawer = {$: 41};
var $mdgriffith$elm_ui$Internal$Model$Behind = 5;
var $mdgriffith$elm_ui$Element$behindContent = function (element) {
	return A2($mdgriffith$elm_ui$Internal$Model$Nearby, 5, element);
};
var $mdgriffith$elm_ui$Internal$Model$Below = 1;
var $mdgriffith$elm_ui$Element$below = function (element) {
	return A2($mdgriffith$elm_ui$Internal$Model$Nearby, 1, element);
};
var $author$project$PAM$blankFilterToolView = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_Nil,
	$mdgriffith$elm_ui$Element$text(''));
var $author$project$PAM$Message$ToggleColumn = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (d, f, m) {
		if (m.$ === 1) {
			return d;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$PAM$GridColumns$columnToggle = F4(
	function (objTypeId, shouldShow, displayName, field) {
		var _v0 = field.hb;
		return $elm$core$Maybe$Just(
			{
				hU: field.r,
				eB: A3($elm_community$maybe_extra$Maybe$Extra$unwrap, false, $elm$core$Basics$identity, shouldShow),
				F: A2($elm$core$Maybe$withDefault, field.fu, displayName),
				i0: A2($author$project$PAM$Message$ToggleColumn, objTypeId, field.r),
				i9: objTypeId
			});
	});
var $author$project$Grid$Grid$AllowHideShow = 0;
var $author$project$Grid$Grid$IntGetter = function (a) {
	return {$: 1, a: a};
};
var $author$project$Grid$Grid$StringGetter = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$Model$FieldChoices$choiceValueToString = F2(
	function (id, choices) {
		return A2(
			$elm$core$Maybe$withDefault,
			'Not Found',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.fu;
				},
				A2($elm$core$Dict$get, id, choices)));
	});
var $author$project$PAM$Model$FieldChoices$choiceToString = F2(
	function (choices, choice) {
		switch (choice.$) {
			case 0:
				var value = choice.a;
				return A2($author$project$PAM$Model$FieldChoices$choiceValueToString, value, choices);
			case 1:
				var values = choice.a;
				return A2(
					$elm$core$String$join,
					', ',
					A2(
						$elm$core$List$map,
						function (v) {
							return A2($author$project$PAM$Model$FieldChoices$choiceValueToString, v, choices);
						},
						values));
			default:
				if (choice.a.$ === 1) {
					var value = choice.a.a;
					return A2($author$project$PAM$Model$FieldChoices$choiceValueToString, value, choices);
				} else {
					var maybeOther = choice.a.a;
					return 'Other: ' + A2($elm$core$Maybe$withDefault, '', maybeOther);
				}
		}
	});
var $author$project$PAM$Misc$dateToString = function (date) {
	return $author$project$NormalDate$dateToNormalString(date);
};
var $author$project$PAM$Object$FieldValue$flagToString = F3(
	function (bool, yes, no) {
		return bool ? yes : no;
	});
var $author$project$PAM$Scores$printScoreWithLabel = function (_v0) {
	var score = _v0.iG;
	var state = _v0.cj;
	var scoreLocale = _Utils_update(
		$cuducos$elm_format_number$FormatNumber$Locales$usLocale,
		{gW: 4});
	switch (state) {
		case 0:
			return 'Not Started';
		case 1:
			return 'Calculating';
		default:
			return _Utils_ap(
				A2(
					$elm$core$Maybe$withDefault,
					$author$project$PAM$Scores$listState(state),
					A2(
						$elm$core$Maybe$map,
						$author$project$PAM$Scores$tagState(state),
						A2(
							$elm$core$Maybe$map,
							$cuducos$elm_format_number$FormatNumber$format(scoreLocale),
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.iG;
								},
								score)))),
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						function (a) {
							return ' - ' + a.eE;
						},
						A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.lx;
							},
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.gm;
								},
								score)))));
	}
};
var $author$project$PAM$Object$FieldValue$fieldValueToString = F4(
	function (choices, value, noneValue, geometryDecimalPlaces) {
		switch (value.$) {
			case 19:
				return 'Field value parse not supported';
			case 0:
				return noneValue;
			case 7:
				var date = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$author$project$PAM$Misc$dateToString,
						$author$project$Fields$Shared$validityToMaybe(date)));
			case 8:
				var date = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$author$project$PAM$Misc$dateRangeToString,
						$author$project$Fields$Shared$validityToMaybe(date)));
			case 10:
				var choice = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$author$project$PAM$Model$FieldChoices$choiceToString(choices),
						choice));
			case 11:
				var choice = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$author$project$PAM$Model$FieldChoices$choiceToString(choices),
						choice));
			case 12:
				var choice = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$author$project$PAM$Model$FieldChoices$choiceToString(choices),
						choice));
			case 1:
				var string = value.a;
				return A2($elm$core$Maybe$withDefault, noneValue, string);
			case 2:
				var string = value.a;
				return A2($elm$core$Maybe$withDefault, noneValue, string);
			case 3:
				var _int = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromInt,
						$author$project$Fields$Shared$validityToMaybe(_int)));
			case 5:
				var _float = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromFloat,
						$author$project$Fields$Shared$validityToMaybe(_float)));
			case 6:
				var money = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$author$project$PAM$Misc$moneyToString,
						$author$project$Fields$Shared$validityToMaybe(money)));
			case 9:
				var bool = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						function (a) {
							return A3($author$project$PAM$Object$FieldValue$flagToString, a, 'Yes', 'No');
						},
						bool));
			case 15:
				var year = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromInt,
						$author$project$Fields$Shared$validityToMaybe(year)));
			case 16:
				var flag = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						function (flg) {
							if (!flg.$) {
								var val = flg.a;
								return 'Yes' + A2(
									$elm$core$Maybe$withDefault,
									'',
									A2(
										$elm$core$Maybe$map,
										function (a) {
											return ': ' + a;
										},
										val));
							} else {
								var val = flg.a;
								return 'No' + A2(
									$elm$core$Maybe$withDefault,
									'',
									A2(
										$elm$core$Maybe$map,
										function (a) {
											return ': ' + a;
										},
										val));
							}
						},
						flag));
			case 4:
				var _int = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromInt,
						$author$project$Fields$Shared$validityToMaybe(_int)));
			case 18:
				var scores = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						function (_v2) {
							var layer = _v2.hC;
							var bufferScores = _v2.ka;
							var _v3 = $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (b) {
										return !b.cA;
									},
									bufferScores));
							if (!_v3.$) {
								var score = _v3.a;
								return $author$project$PAM$Scores$printScoreWithLabel(score);
							} else {
								return '';
							}
						},
						A2(
							$elm$core$Maybe$andThen,
							$elm_community$list_extra$List$Extra$find(
								function (s) {
									return s.hC.fu === 'Wildfire Risk';
								}),
							scores)));
			case 14:
				var values = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					noneValue,
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromInt,
						A2($elm$core$Maybe$map, $elm$core$List$length, values)));
			case 17:
				var spatial = value.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						function (s) {
							return A2(
								$elm$core$Maybe$withDefault,
								'-',
								A2(
									$elm$core$Maybe$map,
									$cuducos$elm_format_number$FormatNumber$format(
										_Utils_update(
											$cuducos$elm_format_number$FormatNumber$Locales$usLocale,
											{gW: geometryDecimalPlaces})),
									s.U));
						},
						$author$project$Fields$Shared$validityToMaybe(spatial)));
			default:
				return 'Field value to string not implemented';
		}
	});
var $author$project$PAM$GridColumns$getValue = F2(
	function (field, values) {
		return A2($author$project$PAM$Object$FieldValue$get, field, values);
	});
var $author$project$PAM$GridColumns$choiceSorter = F2(
	function (choices, field) {
		return $elm$core$List$sortBy(
			function (x) {
				var _v0 = A2($author$project$PAM$GridColumns$getValue, field, x.au);
				_v0$5:
				while (true) {
					if (!_v0.$) {
						switch (_v0.a.$) {
							case 9:
								if (!_v0.a.a.$) {
									var v = _v0.a.a.a;
									return v ? 'a' : 'b';
								} else {
									break _v0$5;
								}
							case 16:
								if (!_v0.a.a.$) {
									var v = _v0.a.a.a;
									if (!v.$) {
										if (!v.a.$) {
											var t = v.a.a;
											return 'a_' + t;
										} else {
											var _v2 = v.a;
											return 'a_';
										}
									} else {
										if (!v.a.$) {
											var t = v.a.a;
											return 'b_' + t;
										} else {
											var _v3 = v.a;
											return 'b_';
										}
									}
								} else {
									break _v0$5;
								}
							case 10:
								if (!_v0.a.a.$) {
									var v = _v0.a.a.a;
									return A4(
										$author$project$PAM$Object$FieldValue$fieldValueToString,
										choices,
										$author$project$PAM$Object$FieldValue$FieldValue_SingleChoice(
											$elm$core$Maybe$Just(v)),
										'',
										0);
								} else {
									break _v0$5;
								}
							case 12:
								if (!_v0.a.a.$) {
									var v = _v0.a.a.a;
									return A4(
										$author$project$PAM$Object$FieldValue$fieldValueToString,
										choices,
										$author$project$PAM$Object$FieldValue$FieldValue_SingleChoiceWithOther(
											$elm$core$Maybe$Just(v)),
										'',
										0);
								} else {
									break _v0$5;
								}
							case 11:
								if (!_v0.a.a.$) {
									var v = _v0.a.a.a;
									return A4(
										$author$project$PAM$Object$FieldValue$fieldValueToString,
										choices,
										$author$project$PAM$Object$FieldValue$FieldValue_SingleChoice(
											$elm$core$Maybe$Just(v)),
										'',
										0);
								} else {
									break _v0$5;
								}
							default:
								break _v0$5;
						}
					} else {
						break _v0$5;
					}
				}
				return '';
			});
	});
var $author$project$PAM$GridColumns$dateSorter = function (field) {
	return $elm$core$List$sortWith(
		function (a) {
			return function (b) {
				var _v0 = _Utils_Tuple2(
					A2($author$project$PAM$GridColumns$getValue, field, a.au),
					A2($author$project$PAM$GridColumns$getValue, field, b.au));
				_v0$1:
				while (true) {
					_v0$2:
					while (true) {
						_v0$4:
						while (true) {
							if (!_v0.a.$) {
								if (!_v0.b.$) {
									switch (_v0.a.a.$) {
										case 7:
											if (_v0.a.a.a.$ === 1) {
												if ((_v0.b.a.$ === 7) && (_v0.b.a.a.$ === 1)) {
													var _v1 = _v0.a.a.a;
													var start = _v1.a;
													var _v2 = _v0.b.a.a;
													var end = _v2.a;
													return A2($justinmimbs$date$Date$compare, start, end);
												} else {
													break _v0$1;
												}
											} else {
												if ((_v0.b.a.$ === 7) && (_v0.b.a.a.$ === 1)) {
													break _v0$2;
												} else {
													break _v0$4;
												}
											}
										case 8:
											switch (_v0.b.a.$) {
												case 7:
													if (_v0.b.a.a.$ === 1) {
														break _v0$2;
													} else {
														break _v0$4;
													}
												case 8:
													var s = _v0.a.a.a;
													var e = _v0.b.a.a;
													var _v5 = _Utils_Tuple2(s, e);
													if (_v5.a.$ === 1) {
														if (_v5.b.$ === 1) {
															var _v6 = _v5.a;
															var start = _v6.a;
															var _v7 = _v5.b;
															var end = _v7.a;
															var _v8 = _Utils_Tuple2(
																$author$project$Fields$Shared$validityToMaybe(start.iT),
																$author$project$Fields$Shared$validityToMaybe(end.iT));
															if (!_v8.a.$) {
																if (!_v8.b.$) {
																	var st = _v8.a.a;
																	var ed = _v8.b.a;
																	var _v9 = A2($justinmimbs$date$Date$compare, st, ed);
																	switch (_v9) {
																		case 2:
																			return 2;
																		case 0:
																			return 0;
																		default:
																			var _v10 = _Utils_Tuple2(
																				$author$project$Fields$Shared$validityToMaybe(start.e0),
																				$author$project$Fields$Shared$validityToMaybe(end.e0));
																			if (!_v10.a.$) {
																				if (!_v10.b.$) {
																					var sst = _v10.a.a;
																					var eed = _v10.b.a;
																					return A2($justinmimbs$date$Date$compare, sst, eed);
																				} else {
																					var sst = _v10.a.a;
																					var _v12 = _v10.b;
																					return 2;
																				}
																			} else {
																				if (!_v10.b.$) {
																					var _v11 = _v10.a;
																					var eed = _v10.b.a;
																					return 0;
																				} else {
																					var _v13 = _v10.a;
																					var _v14 = _v10.b;
																					return 1;
																				}
																			}
																	}
																} else {
																	var st = _v8.a.a;
																	var _v16 = _v8.b;
																	return 2;
																}
															} else {
																if (!_v8.b.$) {
																	var _v15 = _v8.a;
																	var ed = _v8.b.a;
																	return 0;
																} else {
																	var _v17 = _v8.a;
																	var _v18 = _v8.b;
																	return 1;
																}
															}
														} else {
															var _v19 = _v5.a;
															var start = _v19.a;
															return 2;
														}
													} else {
														if (_v5.b.$ === 1) {
															var _v20 = _v5.b;
															var end = _v20.a;
															return 0;
														} else {
															return 1;
														}
													}
												default:
													break _v0$4;
											}
										default:
											if ((_v0.b.a.$ === 7) && (_v0.b.a.a.$ === 1)) {
												break _v0$2;
											} else {
												break _v0$4;
											}
									}
								} else {
									if ((_v0.a.a.$ === 7) && (_v0.a.a.a.$ === 1)) {
										break _v0$1;
									} else {
										break _v0$4;
									}
								}
							} else {
								if (((!_v0.b.$) && (_v0.b.a.$ === 7)) && (_v0.b.a.a.$ === 1)) {
									break _v0$2;
								} else {
									break _v0$4;
								}
							}
						}
						return 1;
					}
					var _v4 = _v0.b.a.a;
					var start = _v4.a;
					return 0;
				}
				var _v3 = _v0.a.a.a;
				var start = _v3.a;
				return 2;
			};
		});
};
var $author$project$PAM$GridColumns$nullSorter = F2(
	function (field, values) {
		return values;
	});
var $author$project$PAM$GridColumns$numberSorter = function (field) {
	return $elm$core$List$sortBy(
		function (x) {
			var _v0 = A2($author$project$PAM$GridColumns$getValue, field, x.au);
			_v0$8:
			while (true) {
				if (!_v0.$) {
					switch (_v0.a.$) {
						case 3:
							if (_v0.a.a.$ === 1) {
								var _v1 = _v0.a.a;
								var v = _v1.a;
								var t = _v1.b;
								return v;
							} else {
								break _v0$8;
							}
						case 15:
							if (_v0.a.a.$ === 1) {
								var _v2 = _v0.a.a;
								var v = _v2.a;
								var t = _v2.b;
								return v;
							} else {
								break _v0$8;
							}
						case 5:
							if (_v0.a.a.$ === 1) {
								var _v3 = _v0.a.a;
								var v = _v3.a;
								var t = _v3.b;
								return v;
							} else {
								break _v0$8;
							}
						case 6:
							if (_v0.a.a.$ === 1) {
								var _v4 = _v0.a.a;
								var v = _v4.a;
								var t = _v4.b;
								return v;
							} else {
								break _v0$8;
							}
						case 4:
							if (_v0.a.a.$ === 1) {
								var _v5 = _v0.a.a;
								var v = _v5.a;
								var t = _v5.b;
								return v;
							} else {
								break _v0$8;
							}
						case 17:
							if (_v0.a.a.$ === 1) {
								var _v6 = _v0.a.a;
								var s = _v6.a;
								var t = _v6.b;
								return A2($elm$core$Maybe$withDefault, 0, s.U);
							} else {
								break _v0$8;
							}
						case 14:
							var values = _v0.a.a;
							return A2(
								$elm$core$Maybe$withDefault,
								-1,
								A2(
									$elm$core$Maybe$map,
									$elm$core$Basics$toFloat,
									A2($elm$core$Maybe$map, $elm$core$List$length, values)));
						case 18:
							var scores = _v0.a.a;
							return A2(
								$elm$core$Maybe$withDefault,
								-0.00003,
								A2(
									$elm$core$Maybe$map,
									function (_v7) {
										var layer = _v7.hC;
										var bufferScores = _v7.ka;
										var _v8 = $elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (b) {
													return !b.cA;
												},
												bufferScores));
										if (!_v8.$) {
											var score = _v8.a;
											return A2(
												$elm$core$Maybe$withDefault,
												-0.00001,
												A2(
													$elm$core$Maybe$map,
													function ($) {
														return $.iG;
													},
													score.iG));
										} else {
											return -0.00002;
										}
									},
									A2(
										$elm$core$Maybe$andThen,
										$elm_community$list_extra$List$Extra$find(
											function (s) {
												return s.hC.fu === 'Wildfire Risk';
											}),
										scores)));
						default:
							break _v0$8;
					}
				} else {
					break _v0$8;
				}
			}
			return 0;
		});
};
var $author$project$PAM$GridColumns$textSorter = function (fieldModel) {
	var textValue = function (obj) {
		var _v0 = A2($author$project$PAM$GridColumns$getValue, fieldModel, obj.au);
		_v0$2:
		while (true) {
			if (!_v0.$) {
				switch (_v0.a.$) {
					case 1:
						if (!_v0.a.a.$) {
							var v = _v0.a.a.a;
							return v;
						} else {
							break _v0$2;
						}
					case 2:
						if (!_v0.a.a.$) {
							var v = _v0.a.a.a;
							return v;
						} else {
							break _v0$2;
						}
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return '';
	};
	return $elm$core$List$sortBy(
		A2($elm$core$Basics$composeL, $elm$core$String$toLower, textValue));
};
var $author$project$PAM$GridColumns$getSorter = F2(
	function (choices, field) {
		var _v0 = -field.r;
		switch (_v0) {
			case 1:
				return {
					fz: $elm$core$List$sortBy(
						function ($) {
							return $.r;
						})
				};
			case 2:
				return {
					fz: $elm$core$List$sortBy(
						A2(
							$elm$core$Basics$composeL,
							$elm$core$String$toLower,
							function ($) {
								return $.fu;
							}))
				};
			default:
				return {
					fz: function () {
						var _v1 = field.hb;
						switch (_v1) {
							case 1:
								return $author$project$PAM$GridColumns$textSorter;
							case 2:
								return $author$project$PAM$GridColumns$textSorter;
							case 3:
								return $author$project$PAM$GridColumns$numberSorter;
							case 16:
								return $author$project$PAM$GridColumns$numberSorter;
							case 4:
								return $author$project$PAM$GridColumns$numberSorter;
							case 5:
								return $author$project$PAM$GridColumns$numberSorter;
							case 15:
								return $author$project$PAM$GridColumns$numberSorter;
							case 13:
								return $author$project$PAM$GridColumns$numberSorter;
							case 6:
								return $author$project$PAM$GridColumns$dateSorter;
							case 7:
								return $author$project$PAM$GridColumns$dateSorter;
							case 9:
								return $author$project$PAM$GridColumns$choiceSorter(choices);
							case 10:
								return $author$project$PAM$GridColumns$choiceSorter(choices);
							case 8:
								return $author$project$PAM$GridColumns$choiceSorter(choices);
							case 17:
								return $author$project$PAM$GridColumns$choiceSorter(choices);
							case 18:
								return $author$project$PAM$GridColumns$choiceSorter(choices);
							case 12:
								return $author$project$PAM$GridColumns$numberSorter;
							case 19:
								return $author$project$PAM$GridColumns$numberSorter;
							default:
								return $author$project$PAM$GridColumns$nullSorter;
						}
					}()(field)
				};
		}
	});
var $author$project$PAM$GridColumns$gridColumn = F7(
	function (objTypeId, show, choices, emptyValue, geometryDecimalPlaces, displayName, field) {
		var _v0 = field.hb;
		if (_v0 === 11) {
			return $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Just(
				{
					hj: _Utils_eq(field.r, -1) ? $author$project$Grid$Grid$IntGetter(
						function ($) {
							return $.r;
						}) : (_Utils_eq(field.r, -2) ? $author$project$Grid$Grid$StringGetter(
						function ($) {
							return $.fu;
						}) : $author$project$Grid$Grid$StringGetter(
						function (a) {
							var _v1 = A2($author$project$PAM$Object$FieldValue$get, field, a.au);
							if (!_v1.$) {
								var v = _v1.a;
								return A4($author$project$PAM$Object$FieldValue$fieldValueToString, choices, v, '', geometryDecimalPlaces);
							} else {
								return '';
							}
						})),
					hU: $elm$core$Maybe$Just(field.r),
					m4: 0,
					eB: show,
					bf: A2($author$project$PAM$GridColumns$getSorter, choices, field),
					F: A2($elm$core$Maybe$withDefault, field.fu, displayName),
					i9: objTypeId
				});
		}
	});
var $author$project$PAM$Model$LayoutField$comparison = F2(
	function (field1, field2) {
		var _v0 = _Utils_Tuple2(field1.kG, field2.kG);
		if (!_v0.a.$) {
			if (!_v0.b.$) {
				var o1 = _v0.a.a;
				var o2 = _v0.b.a;
				return A2($elm$core$Basics$compare, o1, o2);
			} else {
				var _v1 = _v0.b;
				return 2;
			}
		} else {
			if (!_v0.b.$) {
				var _v2 = _v0.a;
				return 0;
			} else {
				var _v3 = _v0.a;
				var _v4 = _v0.b;
				var _v5 = _Utils_Tuple2(field1.eX, field2.eX);
				if (!_v5.a.$) {
					if (!_v5.b.$) {
						var n1 = _v5.a.a;
						var n2 = _v5.b.a;
						return A2($elm$core$Basics$compare, n1, n2);
					} else {
						var _v6 = _v5.b;
						return 2;
					}
				} else {
					if (!_v5.b.$) {
						var _v7 = _v5.a;
						return 0;
					} else {
						var _v8 = _v5.a;
						var _v9 = _v5.b;
						return A2($elm$core$Basics$compare, field1.r, field2.r);
					}
				}
			}
		}
	});
var $author$project$PAM$Model$GridLayouts$sort = function (layout) {
	return A2($elm$core$List$sortWith, $author$project$PAM$Model$LayoutField$comparison, layout);
};
var $author$project$PAM$GridColumns$configuredColumns = F6(
	function (objTypeId, gridLayout, choices, fields, emptyValue, geometryDecimalPlaces) {
		var systemFields = _List_fromArray(
			[
				{
				hb: 3,
				r: -1,
				hJ: $elm$core$Maybe$Just(50),
				fu: 'ID',
				is: $elm$core$Maybe$Nothing
			},
				{hb: 1, r: -2, hJ: $elm$core$Maybe$Nothing, fu: 'Name', is: $elm$core$Maybe$Nothing}
			]);
		var sorted = $author$project$PAM$Model$GridLayouts$sort(gridLayout);
		var allFields = _Utils_ap(systemFields, fields);
		var columnDefsToShow = A2(
			$elm$core$List$filterMap,
			function (layoutField) {
				var _v1 = layoutField.eW;
				if (!_v1.$) {
					var show = _v1.a;
					return A2(
						$elm_community$list_extra$List$Extra$find,
						function (_v2) {
							var modelId = _v2.hU;
							return _Utils_eq(layoutField.hU, modelId);
						},
						A2(
							$elm$core$List$filterMap,
							A6($author$project$PAM$GridColumns$gridColumn, objTypeId, show, choices, emptyValue, geometryDecimalPlaces, layoutField.eX),
							allFields));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			sorted);
		var columnToggles = A2(
			$elm$core$List$filterMap,
			function (layoutField) {
				return A2(
					$elm_community$list_extra$List$Extra$find,
					function (_v0) {
						var modelId = _v0.hU;
						return _Utils_eq(
							layoutField.hU,
							$elm$core$Maybe$Just(modelId));
					},
					A2(
						$elm$core$List$filterMap,
						A3($author$project$PAM$GridColumns$columnToggle, objTypeId, layoutField.eW, layoutField.eX),
						allFields));
			},
			sorted);
		return _Utils_Tuple2(columnDefsToShow, columnToggles);
	});
var $author$project$PAM$Message$ClickDeleteObject = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$PAM$Message$ClickViewObjectDetails = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$Grid$Grid$DisableHideShow = 1;
var $author$project$Grid$Grid$DynamicGetter = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$Message$ZoomToObject = function (a) {
	return {$: 30, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$unstyled = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Unstyled, $elm$core$Basics$always);
var $mdgriffith$elm_ui$Element$html = $mdgriffith$elm_ui$Internal$Model$unstyled;
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$PAM$UI$Basic$icon = function (className) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$centerY
			]),
		$mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(className)
					]),
				_List_Nil)));
};
var $author$project$PAM$UI$Basic$centeredIcon = function (className) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
			]),
		$author$project$PAM$UI$Basic$icon(className));
};
var $author$project$PAM$User$getACLOrDefault = F3(
	function (section, value, acls) {
		return A2(
			$elm$core$Maybe$withDefault,
			{gb: 0, r: 'NO ACL', fL: section, aG: value},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (x) {
						return _Utils_eq(
							$elm$core$String$toLower(x.fL),
							$elm$core$String$toLower(section)) && _Utils_eq(
							$elm$core$String$toLower(x.aG),
							$elm$core$String$toLower(value));
					},
					acls)));
	});
var $mdgriffith$elm_ui$Element$spacingXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$spacing,
			A3(
				$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
				A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, y),
				x,
				y));
	});
var $author$project$PAM$GridColumns$editColumn = F2(
	function (user, objTypeId) {
		return {
			hj: $author$project$Grid$Grid$DynamicGetter(
				function (obj) {
					if (obj.r > 0) {
						var zoomBtn = $author$project$PAM$Object$hasGeometry(obj) ? A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_Nil,
							{
								a8: A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$spacingXY, 3, 0),
											$author$project$PAM$UI$Basic$title('Zoom to geometry')
										]),
									_List_fromArray(
										[
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-map'),
											$mdgriffith$elm_ui$Element$text('Zoom')
										])),
								b8: $elm$core$Maybe$Just(
									$author$project$PAM$Message$ZoomToObject(obj))
							}) : A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_Nil,
							{
								a8: A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$spacingXY, 3, 0),
											$author$project$PAM$UI$Basic$title('No geometry'),
											$mdgriffith$elm_ui$Element$Font$color(
											A2($author$project$PAM$UI$Basic$fadeColor, 0.5, $author$project$PAM$UI$Basic$doveGray))
										]),
									_List_fromArray(
										[
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-map'),
											$mdgriffith$elm_ui$Element$text('Zoom')
										])),
								b8: $elm$core$Maybe$Nothing
							});
						var objectSpecificACL = A3(
							$author$project$PAM$User$getACLOrDefault,
							'Object',
							$elm$core$String$fromInt(objTypeId),
							user.jz);
						var userCanDelete = function (object) {
							var _v0 = objectSpecificACL.gb;
							switch (_v0) {
								case 0:
									return false;
								case 1:
									return false;
								case 2:
									return false;
								case 3:
									return false;
								case 4:
									return _Utils_eq(obj.gP.r, user.mC.r);
								case 5:
									return _Utils_eq(obj.gP.r, user.mC.r);
								default:
									return true;
							}
						};
						var detailsBtn = A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_Nil,
							{
								a8: A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$spacingXY, 3, 0),
											$author$project$PAM$UI$Basic$title('View details')
										]),
									_List_fromArray(
										[
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-reorder'),
											$mdgriffith$elm_ui$Element$text('View Details')
										])),
								b8: $elm$core$Maybe$Just(
									A2($author$project$PAM$Message$ClickViewObjectDetails, obj.bi.lw, obj.r))
							});
						var deleteBtn = (obj.lo || (!userCanDelete(obj))) ? A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_Nil,
							{
								a8: A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$spacingXY, 3, 0),
											$mdgriffith$elm_ui$Element$Font$color(
											A2($author$project$PAM$UI$Basic$fadeColor, 0.5, $author$project$PAM$UI$Basic$doveGray)),
											$author$project$PAM$UI$Basic$title('Save or cancel changes to delete')
										]),
									_List_fromArray(
										[
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-trash'),
											$mdgriffith$elm_ui$Element$text('Delete')
										])),
								b8: $elm$core$Maybe$Nothing
							}) : A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_Nil,
							{
								a8: A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$spacingXY, 3, 0),
											$author$project$PAM$UI$Basic$title('Delete')
										]),
									_List_fromArray(
										[
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-trash'),
											$mdgriffith$elm_ui$Element$text('Delete')
										])),
								b8: $elm$core$Maybe$Just(
									A2($author$project$PAM$Message$ClickDeleteObject, obj.bi.lw, obj.r))
							});
						return _Utils_Tuple2(
							A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$centerY,
										A2($mdgriffith$elm_ui$Element$spacingXY, 5, 0)
									]),
								_List_fromArray(
									[
										deleteBtn,
										$mdgriffith$elm_ui$Element$text('|'),
										detailsBtn,
										$mdgriffith$elm_ui$Element$text('|'),
										zoomBtn
									])),
							'Edit ' + obj.fu);
					} else {
						return _Utils_Tuple2(
							A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$centerY,
										A2($mdgriffith$elm_ui$Element$spacingXY, 5, 0)
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text('---')
									])),
							'New Object');
					}
				}),
			hU: $elm$core$Maybe$Nothing,
			m4: 1,
			eB: true,
			bf: {
				fz: function (a) {
					return a;
				}
			},
			F: 'Actions',
			i9: objTypeId
		};
	});
var $author$project$PAM$GridColumns$buildColumns = F6(
	function (user, geometryDecimalPlaces, objTypeId, gridLayout, choices, objectModel) {
		var _v0 = A6($author$project$PAM$GridColumns$configuredColumns, objTypeId, gridLayout, choices, objectModel, '', geometryDecimalPlaces);
		var shownColumns = _v0.a;
		var columnToggles = _v0.b;
		return _Utils_Tuple2(
			_Utils_ap(
				shownColumns,
				_List_fromArray(
					[
						A2($author$project$PAM$GridColumns$editColumn, user, objTypeId)
					])),
			columnToggles);
	});
var $author$project$PAM$Message$ToggleDirections = {$: 76};
var $mdgriffith$elm_ui$Internal$Model$Max = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$maximum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Max, i, l);
	});
var $mdgriffith$elm_ui$Element$scrollbarY = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.mZ);
var $mdgriffith$elm_ui$Internal$Model$boxShadowName = function (shadow) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				shadow.hw ? 'box-inset' : 'box-',
				$elm$core$String$fromFloat(shadow.lY.a) + 'px',
				$elm$core$String$fromFloat(shadow.lY.b) + 'px',
				$elm$core$String$fromFloat(shadow.as) + 'px',
				$elm$core$String$fromFloat(shadow.aD) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColorClass(shadow.G)
			]));
};
var $mdgriffith$elm_ui$Internal$Flag$shadows = $mdgriffith$elm_ui$Internal$Flag$flag(19);
var $mdgriffith$elm_ui$Element$Border$shadow = function (almostShade) {
	var shade = {as: almostShade.as, G: almostShade.G, hw: false, lY: almostShade.lY, aD: almostShade.aD};
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$shadows,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			$mdgriffith$elm_ui$Internal$Model$boxShadowName(shade),
			'box-shadow',
			$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(shade)));
};
var $mdgriffith$elm_ui$Internal$Model$AsTextColumn = 5;
var $mdgriffith$elm_ui$Internal$Model$asTextColumn = 5;
var $mdgriffith$elm_ui$Element$textColumn = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asTextColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width(
					A2(
						$mdgriffith$elm_ui$Element$maximum,
						750,
						A2($mdgriffith$elm_ui$Element$minimum, 500, $mdgriffith$elm_ui$Element$fill))),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $author$project$PAM$directionDetailView = function (model) {
	if (model.L.kF) {
		var maxWidth = function (size) {
			return (size > 950) ? 950 : size;
		}(
			((model.f5.eJ - ((!model.m8) ? model.fD.lG : 0)) - 30) - (20 * 2));
		var maxHeight = (((model.f5.fb - (model.fD.j7 * ((!model.j8) ? 1 : 0))) - model.fD.j6) - 75) - (20 * 2);
		var helpText = model.bR.M.cY.k2;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$alignRight,
					$mdgriffith$elm_ui$Element$padding(20)
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						A2($mdgriffith$elm_ui$Element$maximum, maxHeight, $mdgriffith$elm_ui$Element$shrink)),
						$mdgriffith$elm_ui$Element$width(
						A2(
							$mdgriffith$elm_ui$Element$maximum,
							maxWidth,
							A2($mdgriffith$elm_ui$Element$minimum, 200, $mdgriffith$elm_ui$Element$shrink))),
						$mdgriffith$elm_ui$Element$Background$color(
						A2($author$project$PAM$UI$Basic$fadeColor, 0.5, $author$project$PAM$UI$Basic$black)),
						$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
						$mdgriffith$elm_ui$Element$Border$shadow(
						{
							as: 10,
							G: $author$project$PAM$UI$Basic$black,
							lY: _Utils_Tuple2(5, 5),
							aD: 2
						})
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$black),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$paddingEach(
								{cz: 4, cR: 5, cX: 5, c3: 4})
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Search Directions'),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignRight]),
									{
										a8: A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
												]),
											_List_fromArray(
												[
													$author$project$PAM$UI$Basic$centeredIcon('fa fa-times')
												])),
										b8: $elm$core$Maybe$Just($author$project$PAM$Message$ToggleDirections)
									}))
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$scrollbarY,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$textColumn,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$paddingEach(
									{cz: 2, cR: 5, cX: 5, c3: 4})
								]),
							A2(
								$elm$core$List$map,
								function (txt) {
									return A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										A2(
											$elm$core$List$map,
											function (a) {
												return A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
															$mdgriffith$elm_ui$Element$paddingEach(
															_Utils_update(
																$author$project$PAM$UI$Basic$edges,
																{cX: 15}))
														]),
													$mdgriffith$elm_ui$Element$text(a));
											},
											txt));
								},
								helpText)))
					])));
	} else {
		return $mdgriffith$elm_ui$Element$none;
	}
};
var $mdgriffith$elm_ui$Element$modular = F3(
	function (normal, ratio, rescale) {
		return (!rescale) ? normal : ((rescale < 0) ? (normal * A2($elm$core$Basics$pow, ratio, rescale)) : (normal * A2($elm$core$Basics$pow, ratio, rescale - 1)));
	});
var $author$project$PAM$UI$Basic$responsiveFont = F3(
	function (a, _v0, _v1) {
		var aMin = _v0.a;
		var aMax = _v0.b;
		var bMin = _v1.a;
		var bMax = _v1.b;
		if (_Utils_cmp(a, aMin) < 1) {
			return bMin;
		} else {
			if (_Utils_cmp(a, aMax) > -1) {
				return bMax;
			} else {
				var deltaA = (a - aMin) / (aMax - aMin);
				return (deltaA * (bMax - bMin)) + bMin;
			}
		}
	});
var $author$project$PAM$UI$Basic$scaleFont = F2(
	function (size, factor) {
		var baseFont = A3(
			$author$project$PAM$UI$Basic$responsiveFont,
			size.eJ,
			_Utils_Tuple2(1200, 1800),
			_Utils_Tuple2(13, 16));
		return $elm$core$Basics$round(
			A3($mdgriffith$elm_ui$Element$modular, baseFont, 1.15, factor));
	});
var $author$project$PAM$directionView = F2(
	function (palette, model) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$paddingEach(
					_Utils_update(
						$author$project$PAM$UI$Basic$edges,
						{cX: 15}))
				]),
			A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						$mdgriffith$elm_ui$Element$Border$rounded(15),
						$mdgriffith$elm_ui$Element$padding(0)
					]),
				{
					a8: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$clip,
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$centerY,
								$mdgriffith$elm_ui$Element$padding(0),
								$mdgriffith$elm_ui$Element$Font$size(
								A2($author$project$PAM$UI$Basic$scaleFont, model.f5, 5)),
								$mdgriffith$elm_ui$Element$Font$color(palette.S.iI.G)
							]),
						$author$project$PAM$UI$Basic$centeredIcon('fa fa-info-circle fa-6')),
					b8: $elm$core$Maybe$Just($author$project$PAM$Message$ToggleDirections)
				}));
	});
var $mdgriffith$elm_ui$Internal$Model$InFront = 4;
var $mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2($mdgriffith$elm_ui$Internal$Model$Nearby, 4, element);
};
var $author$project$PAM$supportedBrowserType = _List_fromArray(
	[0, 1]);
var $author$project$PAM$isSupportedBrowserType = function (type_) {
	return A2($elm$core$List$member, type_, $author$project$PAM$supportedBrowserType);
};
var $mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$AllowHover = 1;
var $mdgriffith$elm_ui$Internal$Model$Layout = 1;
var $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	jV: $elm$core$Maybe$Nothing,
	j1: $elm$core$Maybe$Nothing,
	m5: $elm$core$Maybe$Just(
		{
			as: 3,
			G: A4($mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			lY: _Utils_Tuple2(0, 0),
			aD: 3
		})
};
var $mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 0:
					var hoverable = opt.a;
					var _v4 = record.k6;
					if (_v4.$ === 1) {
						return _Utils_update(
							record,
							{
								k6: $elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 1:
					var focusStyle = opt.a;
					var _v5 = record.kW;
					if (_v5.$ === 1) {
						return _Utils_update(
							record,
							{
								kW: $elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _v6 = record.lP;
					if (_v6.$ === 1) {
						return _Utils_update(
							record,
							{
								lP: $elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			kW: function () {
				var _v0 = record.kW;
				if (_v0.$ === 1) {
					return $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _v0.a;
					return focusable;
				}
			}(),
			k6: function () {
				var _v1 = record.k6;
				if (_v1.$ === 1) {
					return 1;
				} else {
					var hoverable = _v1.a;
					return hoverable;
				}
			}(),
			lP: function () {
				var _v2 = record.lP;
				if (_v2.$ === 1) {
					return 1;
				} else {
					var actualMode = _v2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			$elm$core$List$foldr,
			combine,
			{kW: $elm$core$Maybe$Nothing, k6: $elm$core$Maybe$Nothing, lP: $elm$core$Maybe$Nothing},
			options));
};
var $mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 0:
				var html = el.a;
				return html($mdgriffith$elm_ui$Internal$Model$asEl);
			case 1:
				var styles = el.a.nk;
				var html = el.a.k8;
				return A2(
					html,
					mode(styles),
					$mdgriffith$elm_ui$Internal$Model$asEl);
			case 2:
				var text = el.a;
				return $mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return $mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = $mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _v0 = options.lP;
			if (_v0 === 2) {
				return $mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var $mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			$mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			$mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-color-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'font-color-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontSize,
			$mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				$mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var $mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_v0, attrs, child) {
		var options = _v0.h4;
		return A3(
			$mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[$mdgriffith$elm_ui$Internal$Style$classes.iA, $mdgriffith$elm_ui$Internal$Style$classes.jP, $mdgriffith$elm_ui$Internal$Style$classes.m9]))),
				_Utils_ap($mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var $mdgriffith$elm_ui$Element$layout = $mdgriffith$elm_ui$Element$layoutWith(
	{h4: _List_Nil});
var $mdgriffith$elm_ui$Element$Lazy$embed = function (x) {
	switch (x.$) {
		case 0:
			var html = x.a;
			return html;
		case 1:
			var styled = x.a;
			return styled.k8(
				A2(
					$mdgriffith$elm_ui$Internal$Model$OnlyDynamic,
					{
						kW: {jV: $elm$core$Maybe$Nothing, j1: $elm$core$Maybe$Nothing, m5: $elm$core$Maybe$Nothing},
						k6: 1,
						lP: 1
					},
					styled.nk));
		case 2:
			var text = x.a;
			return $elm$core$Basics$always(
				$elm$virtual_dom$VirtualDom$text(text));
		default:
			return $elm$core$Basics$always(
				$elm$virtual_dom$VirtualDom$text(''));
	}
};
var $mdgriffith$elm_ui$Element$Lazy$apply1 = F2(
	function (fn, a) {
		return $mdgriffith$elm_ui$Element$Lazy$embed(
			fn(a));
	});
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $mdgriffith$elm_ui$Element$Lazy$lazy = F2(
	function (fn, a) {
		return $mdgriffith$elm_ui$Internal$Model$Unstyled(
			A3($elm$virtual_dom$VirtualDom$lazy3, $mdgriffith$elm_ui$Element$Lazy$apply1, fn, a));
	});
var $mdgriffith$elm_ui$Element$Lazy$apply3 = F4(
	function (fn, a, b, c) {
		return $mdgriffith$elm_ui$Element$Lazy$embed(
			A3(fn, a, b, c));
	});
var $elm$virtual_dom$VirtualDom$lazy5 = _VirtualDom_lazy5;
var $mdgriffith$elm_ui$Element$Lazy$lazy3 = F4(
	function (fn, a, b, c) {
		return $mdgriffith$elm_ui$Internal$Model$Unstyled(
			A5($elm$virtual_dom$VirtualDom$lazy5, $mdgriffith$elm_ui$Element$Lazy$apply3, fn, a, b, c));
	});
var $author$project$PAM$Message$CancelEditDetails = function (a) {
	return {$: 23, a: a};
};
var $author$project$PAM$Message$CancelLinkDetails = function (a) {
	return {$: 24, a: a};
};
var $author$project$PAM$Message$CancelNewObject = function (a) {
	return {$: 19, a: a};
};
var $author$project$PAM$Message$SaveAndLinkDetails = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$PAM$Message$SaveDetails = function (a) {
	return {$: 14, a: a};
};
var $author$project$PAM$Message$ToggleLeftSidebar = {$: 40};
var $author$project$PAM$UI$StandardElements$PrimaryAction = 0;
var $mdgriffith$elm_ui$Internal$Flag$fontWeight = $mdgriffith$elm_ui$Internal$Flag$flag(13);
var $mdgriffith$elm_ui$Element$Font$bold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.j0);
var $author$project$PAM$UI$StandardElements$buttonTextColor = F2(
	function (buttonType, palette) {
		if (!buttonType) {
			return palette.S.mB.eE;
		} else {
			return palette.S.fK.eE;
		}
	});
var $author$project$PAM$UI$StandardElements$buttonTypeColor = F2(
	function (buttonType, palette) {
		if (!buttonType) {
			return palette.S.mB.G;
		} else {
			return palette.S.fK.G;
		}
	});
var $author$project$PAM$UI$Basic$inputHeight = function (_v0) {
	var windowSize = _v0.f5;
	var device = _v0.eV;
	return $mdgriffith$elm_ui$Element$px(
		A3(
			$author$project$PAM$UI$Basic$adjustOnHeight,
			windowSize,
			device,
			_Utils_Tuple2(26, 30)));
};
var $author$project$PAM$UI$StandardElements$button = F4(
	function (buttonType, env, addlAttrs, settings) {
		var fade = (!_Utils_eq(settings.b8, $elm$core$Maybe$Nothing)) ? 1 : 0.5;
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(
						A2(
							$author$project$PAM$UI$Basic$fadeColor,
							fade,
							A2($author$project$PAM$UI$StandardElements$buttonTypeColor, buttonType, env.bE))),
						$mdgriffith$elm_ui$Element$Font$color(
						A2(
							$author$project$PAM$UI$Basic$fadeColor,
							fade,
							A2($author$project$PAM$UI$StandardElements$buttonTextColor, buttonType, env.bE))),
						$mdgriffith$elm_ui$Element$Font$bold,
						$mdgriffith$elm_ui$Element$Font$size(
						A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
						$mdgriffith$elm_ui$Element$height(
						$author$project$PAM$UI$Basic$inputHeight(env)),
						$author$project$PAM$UI$Basic$montserrat,
						A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
						$mdgriffith$elm_ui$Element$width(
						A2($mdgriffith$elm_ui$Element$minimum, 150, $mdgriffith$elm_ui$Element$shrink)),
						$mdgriffith$elm_ui$Element$Border$rounded(16)
					]),
				addlAttrs),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(3)
							]),
						settings.a8)),
				b8: settings.b8
			});
	});
var $author$project$PAM$cancelObjChangesBtn = F4(
	function (palette, env, maybeTxt, maybeMsg) {
		var txt = A2($elm$core$Maybe$withDefault, 'Cancel', maybeTxt);
		return A4(
			$author$project$PAM$UI$StandardElements$button,
			0,
			{eV: env.eV, bE: palette, f5: env.f5},
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			{
				a8: _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(txt)
					]),
				b8: maybeMsg
			});
	});
var $author$project$PAM$closeLink = F4(
	function (palette, env, maybeTxt, route) {
		var txt = A2($elm$core$Maybe$withDefault, 'Close', maybeTxt);
		return A2(
			$mdgriffith$elm_ui$Element$link,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
					$mdgriffith$elm_ui$Element$height(
					$author$project$PAM$UI$Basic$inputHeight(env)),
					$mdgriffith$elm_ui$Element$Border$rounded(16),
					$mdgriffith$elm_ui$Element$Font$bold,
					$author$project$PAM$UI$Basic$title(txt),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(160)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
							$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE),
							$author$project$PAM$UI$Basic$montserrat,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					$mdgriffith$elm_ui$Element$text(txt)),
				c8: $author$project$PAM$Route$toString(route)
			});
	});
var $author$project$PAM$editObjectLink = F4(
	function (palette, env, maybeTxt, route) {
		var txt = A2($elm$core$Maybe$withDefault, 'Edit', maybeTxt);
		return A2(
			$mdgriffith$elm_ui$Element$link,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(palette.S.fK.G),
					$mdgriffith$elm_ui$Element$height(
					$author$project$PAM$UI$Basic$inputHeight(env)),
					$mdgriffith$elm_ui$Element$Border$rounded(16),
					$mdgriffith$elm_ui$Element$Font$bold,
					$author$project$PAM$UI$Basic$title(txt),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(160)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
							$mdgriffith$elm_ui$Element$Font$color(palette.S.fK.eE),
							$author$project$PAM$UI$Basic$montserrat,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					$mdgriffith$elm_ui$Element$text(txt)),
				c8: $author$project$PAM$Route$toString(route)
			});
	});
var $author$project$PAM$Message$SetMapThemesCategoryTransparency = F2(
	function (a, b) {
		return {$: 39, a: a, b: b};
	});
var $author$project$PAM$Message$ToggleMapThemesCategory = function (a) {
	return {$: 35, a: a};
};
var $author$project$PAM$Message$ToggleMapThemesCategoryTransparency = function (a) {
	return {$: 38, a: a};
};
var $author$project$PlugMap$Plugins$Themes$categoryKeyToString = function (_v0) {
	var key = _v0;
	return key;
};
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $author$project$PlugMap$Plugins$Themes$getGroupsForCategory = F2(
	function (category, repo) {
		return A2(
			$elm$core$List$filterMap,
			$author$project$PlugMap$Plugins$Themes$getGroupByKey(repo),
			category.d5);
	});
var $mdgriffith$elm_ui$Internal$Flag$bgGradient = $mdgriffith$elm_ui$Internal$Flag$flag(10);
var $mdgriffith$elm_ui$Element$Background$gradient = function (_v0) {
	var angle = _v0.jO;
	var steps = _v0.fS;
	if (!steps.b) {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	} else {
		if (!steps.b.b) {
			var clr = steps.a;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$bgColor,
				A3(
					$mdgriffith$elm_ui$Internal$Model$Colored,
					'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
					'background-color',
					clr));
		} else {
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$bgGradient,
				A3(
					$mdgriffith$elm_ui$Internal$Model$Single,
					'bg-grad-' + A2(
						$elm$core$String$join,
						'-',
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$floatClass(angle),
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$formatColorClass, steps))),
					'background-image',
					'linear-gradient(' + (A2(
						$elm$core$String$join,
						', ',
						A2(
							$elm$core$List$cons,
							$elm$core$String$fromFloat(angle) + 'rad',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$formatColor, steps))) + ')')));
		}
	}
};
var $author$project$PAM$Message$ToggleMapThemesGroup = function (a) {
	return {$: 36, a: a};
};
var $author$project$PlugMap$Plugins$Themes$getLayersForGroup = F2(
	function (group, repo) {
		return A2(
			$elm$core$List$filterMap,
			$author$project$PlugMap$Plugins$Themes$getLayerByKey(repo),
			group.hE);
	});
var $author$project$PAM$UI$Basic$helvetica = $mdgriffith$elm_ui$Element$Font$family(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$typeface('Helvetica'),
			$mdgriffith$elm_ui$Element$Font$sansSerif
		]));
var $author$project$PAM$Message$ToggleMapThemesLayer = F2(
	function (a, b) {
		return {$: 37, a: a, b: b};
	});
var $author$project$PAM$linkImgToIcon = function (linkImg) {
	switch (linkImg.$) {
		case 2:
			var _class = linkImg.a;
			return $author$project$PAM$UI$Basic$centeredIcon(_class);
		case 1:
			var src = linkImg.a;
			return $mdgriffith$elm_ui$Element$none;
		default:
			return $mdgriffith$elm_ui$Element$none;
	}
};
var $mdgriffith$elm_ui$Element$Font$regular = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.ny);
var $author$project$PAM$UI$Basic$transparent = function (pct) {
	return A4($mdgriffith$elm_ui$Element$rgba255, 0, 0, 0, pct);
};
var $author$project$PAM$UI$Basic$transparent0 = $author$project$PAM$UI$Basic$transparent(0.0);
var $author$project$PAM$layerView = F5(
	function (palette, env, category, extraPadding, layer) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent0),
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
					$mdgriffith$elm_ui$Element$Font$regular,
					$author$project$PAM$UI$Basic$helvetica,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(30)),
					$mdgriffith$elm_ui$Element$paddingEach(
					_Utils_update(
						$author$project$PAM$UI$Basic$edges,
						{cR: 10 + extraPadding})),
					A2($mdgriffith$elm_ui$Element$spacingXY, 5, 0),
					$mdgriffith$elm_ui$Element$mouseOver(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$PAM$UI$Basic$fadeColor, 0.45, palette.cx.mB.G))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$px(36))
										]),
									A2($author$project$PlugMap$Plugins$Themes$isLayerSelected, layer.lw, category.m0) ? $author$project$PAM$linkImgToIcon(category.jA) : $mdgriffith$elm_ui$Element$none),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									$mdgriffith$elm_ui$Element$text(layer.fu))
								])),
						b8: $elm$core$Maybe$Just(
							A2($author$project$PAM$Message$ToggleMapThemesLayer, category, layer.lw))
					})
				]));
	});
var $author$project$PAM$groupView = F4(
	function (palette, env, category, group) {
		var groupHeader = function () {
			var _v2 = group.fu;
			if (!_v2.$) {
				var name = _v2.a;
				return A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(palette.cx.fK.G),
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 0),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(34))
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									$mdgriffith$elm_ui$Element$text(name)),
									function () {
									var _v3 = group.bD;
									if (!_v3) {
										return A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$px(36))
												]),
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-chevron-down'));
									} else {
										return A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$px(36))
												]),
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-chevron-left'));
									}
								}()
								])),
						b8: $elm$core$Maybe$Just(
							$author$project$PAM$Message$ToggleMapThemesGroup(group))
					});
			} else {
				return $mdgriffith$elm_ui$Element$none;
			}
		}();
		var extraPad = function () {
			var _v1 = group.fu;
			if (!_v1.$) {
				var name = _v1.a;
				return 15;
			} else {
				return 0;
			}
		}();
		var layerViews = function () {
			var _v0 = group.bD;
			if (!_v0) {
				return A2(
					$elm$core$List$map,
					A4($author$project$PAM$layerView, palette, env, category, extraPad),
					A2($author$project$PlugMap$Plugins$Themes$getLayersForGroup, group, env.h.s.lB));
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(palette.cx.fK.eE),
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
					$mdgriffith$elm_ui$Element$Font$regular,
					$author$project$PAM$UI$Basic$helvetica,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
				]),
			A2($elm$core$List$cons, groupHeader, layerViews));
	});
var $author$project$PlugMap$Plugins$Themes$isCategoryFullyTransparent = function (category) {
	return category.i8 === 0.0;
};
var $mdgriffith$elm_ui$Element$Input$HiddenLabel = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Element$Input$labelHidden = $mdgriffith$elm_ui$Element$Input$HiddenLabel;
var $mdgriffith$elm_ui$Internal$Model$Rotate = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$rotate = $mdgriffith$elm_ui$Internal$Flag$flag(24);
var $mdgriffith$elm_ui$Element$rotate = function (angle) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$rotate,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Rotate,
			_Utils_Tuple3(0, 0, 1),
			angle));
};
var $mdgriffith$elm_ui$Internal$Flag$active = $mdgriffith$elm_ui$Internal$Flag$flag(32);
var $mdgriffith$elm_ui$Internal$Model$LivePolite = {$: 6};
var $mdgriffith$elm_ui$Element$Region$announce = $mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$LivePolite);
var $mdgriffith$elm_ui$Element$Input$applyLabel = F3(
	function (attrs, label, input) {
		if (label.$ === 1) {
			var labelText = label.a;
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asColumn,
				$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
				attrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[input])));
		} else {
			var position = label.a;
			var labelAttrs = label.b;
			var labelChild = label.c;
			var labelElement = A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				labelAttrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[labelChild])));
			switch (position) {
				case 2:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						attrs,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
				case 3:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						attrs,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				case 0:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						attrs,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				default:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						attrs,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$focus = $mdgriffith$elm_ui$Internal$Flag$flag(31);
var $mdgriffith$elm_ui$Internal$Model$getHeight = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, acc) {
				if (!acc.$) {
					var x = acc.a;
					return $elm$core$Maybe$Just(x);
				} else {
					if (attr.$ === 8) {
						var len = attr.a;
						return $elm$core$Maybe$Just(len);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			}),
		$elm$core$Maybe$Nothing,
		attrs);
};
var $mdgriffith$elm_ui$Internal$Model$getSpacing = F2(
	function (attrs, _default) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A3(
				$elm$core$List$foldr,
				F2(
					function (attr, acc) {
						if (!acc.$) {
							var x = acc.a;
							return $elm$core$Maybe$Just(x);
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v2 = attr.b;
								var x = _v2.b;
								var y = _v2.c;
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}),
				$elm$core$Maybe$Nothing,
				attrs));
	});
var $mdgriffith$elm_ui$Internal$Model$getWidth = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, acc) {
				if (!acc.$) {
					var x = acc.a;
					return $elm$core$Maybe$Just(x);
				} else {
					if (attr.$ === 7) {
						var len = attr.a;
						return $elm$core$Maybe$Just(len);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			}),
		$elm$core$Maybe$Nothing,
		attrs);
};
var $mdgriffith$elm_ui$Internal$Model$Label = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute = function (label) {
	if (label.$ === 1) {
		var textLabel = label.a;
		return $mdgriffith$elm_ui$Internal$Model$Describe(
			$mdgriffith$elm_ui$Internal$Model$Label(textLabel));
	} else {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	}
};
var $mdgriffith$elm_ui$Element$Input$isHiddenLabel = function (label) {
	if (label.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $mdgriffith$elm_ui$Element$fillPortion = $mdgriffith$elm_ui$Internal$Model$Fill;
var $mdgriffith$elm_ui$Internal$Model$mapAttr = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Element$Input$viewHorizontalThumb = F3(
	function (factor, thumbAttributes, trackHeight) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackHeight)),
					$mdgriffith$elm_ui$Element$centerY
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$fillPortion(
								$elm$core$Basics$round(factor * 10000)))
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$centerY,
						A2(
							$elm$core$List$map,
							$mdgriffith$elm_ui$Internal$Model$mapAttr($elm$core$Basics$never),
							thumbAttributes)),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$fillPortion(
								$elm$core$Basics$round(
									$elm$core$Basics$abs(1 - factor) * 10000)))
						]),
					$mdgriffith$elm_ui$Element$none)
				]));
	});
var $mdgriffith$elm_ui$Element$Input$viewVerticalThumb = F3(
	function (factor, thumbAttributes, trackWidth) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width(
					A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$fillPortion(
								$elm$core$Basics$round(
									$elm$core$Basics$abs(1 - factor) * 10000)))
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$centerX,
						A2(
							$elm$core$List$map,
							$mdgriffith$elm_ui$Internal$Model$mapAttr($elm$core$Basics$never),
							thumbAttributes)),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$fillPortion(
								$elm$core$Basics$round(factor * 10000)))
						]),
					$mdgriffith$elm_ui$Element$none)
				]));
	});
var $mdgriffith$elm_ui$Element$Input$slider = F2(
	function (attributes, input) {
		var trackWidth = $mdgriffith$elm_ui$Internal$Model$getWidth(attributes);
		var trackHeight = $mdgriffith$elm_ui$Internal$Model$getHeight(attributes);
		var vertical = function () {
			var _v8 = _Utils_Tuple2(trackWidth, trackHeight);
			_v8$3:
			while (true) {
				if (_v8.a.$ === 1) {
					if (_v8.b.$ === 1) {
						var _v9 = _v8.a;
						var _v10 = _v8.b;
						return false;
					} else {
						break _v8$3;
					}
				} else {
					if ((!_v8.a.a.$) && (!_v8.b.$)) {
						switch (_v8.b.a.$) {
							case 0:
								var w = _v8.a.a.a;
								var h = _v8.b.a.a;
								return _Utils_cmp(h, w) > 0;
							case 2:
								return true;
							default:
								break _v8$3;
						}
					} else {
						break _v8$3;
					}
				}
			}
			return false;
		}();
		var factor = (input.aG - input.lN) / (input.lK - input.lN);
		var _v0 = input.nE;
		var thumbAttributes = _v0;
		var height = $mdgriffith$elm_ui$Internal$Model$getHeight(thumbAttributes);
		var thumbHeightString = function () {
			if (height.$ === 1) {
				return '20px';
			} else {
				if (!height.a.$) {
					var px = height.a.a;
					return $elm$core$String$fromInt(px) + 'px';
				} else {
					return '100%';
				}
			}
		}();
		var width = $mdgriffith$elm_ui$Internal$Model$getWidth(thumbAttributes);
		var thumbWidthString = function () {
			if (width.$ === 1) {
				return '20px';
			} else {
				if (!width.a.$) {
					var px = width.a.a;
					return $elm$core$String$fromInt(px) + 'px';
				} else {
					return '100%';
				}
			}
		}();
		var className = 'thmb-' + (thumbWidthString + ('-' + thumbHeightString));
		var thumbShadowStyle = _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', thumbWidthString),
				A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', thumbHeightString)
			]);
		var _v1 = A2(
			$mdgriffith$elm_ui$Internal$Model$getSpacing,
			attributes,
			_Utils_Tuple2(5, 5));
		var spacingX = _v1.a;
		var spacingY = _v1.b;
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(input.a8) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : A2($mdgriffith$elm_ui$Element$spacingXY, spacingX, spacingY),
					$mdgriffith$elm_ui$Element$Region$announce,
					$mdgriffith$elm_ui$Element$width(
					function () {
						if (trackWidth.$ === 1) {
							return $mdgriffith$elm_ui$Element$fill;
						} else {
							if (!trackWidth.a.$) {
								return $mdgriffith$elm_ui$Element$shrink;
							} else {
								var x = trackWidth.a;
								return x;
							}
						}
					}()),
					$mdgriffith$elm_ui$Element$height(
					function () {
						if (trackHeight.$ === 1) {
							return $mdgriffith$elm_ui$Element$shrink;
						} else {
							if (!trackHeight.a.$) {
								return $mdgriffith$elm_ui$Element$shrink;
							} else {
								var x = trackHeight.a;
								return x;
							}
						}
					}())
				]),
			input.a8,
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth)),
						$mdgriffith$elm_ui$Element$height(
						A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Element$px(20),
							trackHeight))
					]),
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName('input'),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(input.a8),
								A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$active,
								A2($mdgriffith$elm_ui$Internal$Model$Style, 'input[type=\"range\"].' + (className + '::-moz-range-thumb'), thumbShadowStyle)),
								A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$hover,
								A2($mdgriffith$elm_ui$Internal$Model$Style, 'input[type=\"range\"].' + (className + '::-webkit-slider-thumb'), thumbShadowStyle)),
								A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$focus,
								A2($mdgriffith$elm_ui$Internal$Model$Style, 'input[type=\"range\"].' + (className + '::-ms-thumb'), thumbShadowStyle)),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$class(className + ' focusable-parent')),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Events$onInput(
									function (str) {
										var _v4 = $elm$core$String$toFloat(str);
										if (_v4.$ === 1) {
											return input.h1(0);
										} else {
											var val = _v4.a;
											return input.h1(val);
										}
									})),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$type_('range')),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$step(
									function () {
										var _v5 = input.ni;
										if (_v5.$ === 1) {
											return 'any';
										} else {
											var step = _v5.a;
											return $elm$core$String$fromFloat(step);
										}
									}())),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$min(
									$elm$core$String$fromFloat(input.lN))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$max(
									$elm$core$String$fromFloat(input.lK))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$value(
									$elm$core$String$fromFloat(input.aG))),
								vertical ? $mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'orient', 'vertical')) : $mdgriffith$elm_ui$Internal$Model$NoAttribute,
								$mdgriffith$elm_ui$Element$width(
								vertical ? A2(
									$elm$core$Maybe$withDefault,
									$mdgriffith$elm_ui$Element$px(20),
									trackHeight) : A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth)),
								$mdgriffith$elm_ui$Element$height(
								vertical ? A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth) : A2(
									$elm$core$Maybe$withDefault,
									$mdgriffith$elm_ui$Element$px(20),
									trackHeight))
							]),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil)),
						A2(
						$mdgriffith$elm_ui$Element$el,
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width(
								A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth)),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height(
									A2(
										$elm$core$Maybe$withDefault,
										$mdgriffith$elm_ui$Element$px(20),
										trackHeight)),
								_Utils_ap(
									attributes,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$behindContent(
											vertical ? A3($mdgriffith$elm_ui$Element$Input$viewVerticalThumb, factor, thumbAttributes, trackWidth) : A3($mdgriffith$elm_ui$Element$Input$viewHorizontalThumb, factor, thumbAttributes, trackHeight))
										])))),
						$mdgriffith$elm_ui$Element$none)
					])));
	});
var $mdgriffith$elm_ui$Internal$Flag$borderStyle = $mdgriffith$elm_ui$Internal$Flag$flag(11);
var $mdgriffith$elm_ui$Element$Border$solid = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$borderStyle, $mdgriffith$elm_ui$Internal$Style$classes.j5);
var $mdgriffith$elm_ui$Element$Input$Thumb = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Element$Input$thumb = $elm$core$Basics$identity;
var $author$project$PAM$categoryView = F3(
	function (palette, env, category) {
		var transparencyView = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					A2($mdgriffith$elm_ui$Element$spacingXY, 5, 0),
					$mdgriffith$elm_ui$Element$paddingEach(
					_Utils_update(
						$author$project$PAM$UI$Basic$edges,
						{cX: 10})),
					$author$project$PAM$UI$Basic$title(
					A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, category.i8))
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$slider,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$behindContent(
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(6)),
										$mdgriffith$elm_ui$Element$centerY,
										$mdgriffith$elm_ui$Element$Background$gradient(
										{
											jO: $elm$core$Basics$pi / 2,
											fS: _List_fromArray(
												[$author$project$PAM$UI$Basic$transparent0, $author$project$PAM$UI$Basic$white])
										}),
										$mdgriffith$elm_ui$Element$Border$rounded(6)
									]),
								$mdgriffith$elm_ui$Element$none))
						]),
					{
						a8: $mdgriffith$elm_ui$Element$Input$labelHidden(
							'transparency_' + $author$project$PlugMap$Plugins$Themes$categoryKeyToString(category.lw)),
						lK: 1.0,
						lN: 0.0,
						h1: $author$project$PAM$Message$SetMapThemesCategoryTransparency(category),
						ni: $elm$core$Maybe$Nothing,
						nE: $mdgriffith$elm_ui$Element$Input$thumb(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(16)),
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(16)),
									$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent0),
									$mdgriffith$elm_ui$Element$Border$rounded(16),
									$mdgriffith$elm_ui$Element$Border$width(5),
									$mdgriffith$elm_ui$Element$Border$solid,
									$mdgriffith$elm_ui$Element$Border$color(palette.S.cd.G)
								])),
						aG: category.i8
					}),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PlugMap$Plugins$Themes$isCategoryFullyTransparent(category) ? $author$project$PAM$UI$Basic$title('Toggle On') : $author$project$PAM$UI$Basic$title('Toggle Off')
						]),
					{
						a8: $author$project$PlugMap$Plugins$Themes$isCategoryFullyTransparent(category) ? A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(36)),
									$mdgriffith$elm_ui$Element$Font$color(
									A2($author$project$PAM$UI$Basic$fadeColor, 0.5, palette.S.iI.G))
								]),
							$author$project$PAM$UI$Basic$centeredIcon('fa fa-eye-slash')) : A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(36))
								]),
							$author$project$PAM$UI$Basic$centeredIcon('fa fa-eye')),
						b8: $elm$core$Maybe$Just(
							$author$project$PAM$Message$ToggleMapThemesCategoryTransparency(category))
					})
				]));
		var groupViews = function () {
			var _v1 = category.bD;
			if (!_v1) {
				return A2(
					$elm$core$List$map,
					A3($author$project$PAM$groupView, palette, env, category),
					A2($author$project$PlugMap$Plugins$Themes$getGroupsForCategory, category, env.h.s.lz));
			} else {
				return _List_Nil;
			}
		}();
		var categoryHeader = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(palette.cx.mB.G),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(43))
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$spacingXY, 5, 0),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									function () {
									var _v0 = category.bD;
									if (!_v0) {
										return A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$px(30)),
													A2($mdgriffith$elm_ui$Element$paddingXY, 7, 0)
												]),
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-chevron-down'));
									} else {
										return A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$px(30)),
													$mdgriffith$elm_ui$Element$rotate(
													$elm$core$Basics$degrees(270)),
													$mdgriffith$elm_ui$Element$paddingEach(
													_Utils_update(
														$author$project$PAM$UI$Basic$edges,
														{cR: 7}))
												]),
											$author$project$PAM$UI$Basic$centeredIcon('fa fa-chevron-down'));
									}
								}(),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									$mdgriffith$elm_ui$Element$text(category.fu))
								])),
						b8: $elm$core$Maybe$Just(
							$author$project$PAM$Message$ToggleMapThemesCategory(category))
					}),
					category.lT ? transparencyView : $mdgriffith$elm_ui$Element$none
				]));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(palette.cx.mB.eE),
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
					$mdgriffith$elm_ui$Element$Font$bold,
					$author$project$PAM$UI$Basic$helvetica,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2($mdgriffith$elm_ui$Element$spacingXY, 0, 1)
				]),
			A2($elm$core$List$cons, categoryHeader, groupViews));
	});
var $author$project$Animation$Render$iePrefix = '-ms-';
var $author$project$Animation$Render$webkitPrefix = '-webkit-';
var $author$project$Animation$Render$prefix = function (stylePair) {
	var propValue = stylePair.b;
	var propName = stylePair.a;
	switch (propName) {
		case 'transform':
			return _List_fromArray(
				[
					stylePair,
					_Utils_Tuple2(
					_Utils_ap($author$project$Animation$Render$iePrefix, propName),
					propValue),
					_Utils_Tuple2(
					_Utils_ap($author$project$Animation$Render$webkitPrefix, propName),
					propValue)
				]);
		case 'transform-origin':
			return _List_fromArray(
				[
					stylePair,
					_Utils_Tuple2(
					_Utils_ap($author$project$Animation$Render$iePrefix, propName),
					propValue),
					_Utils_Tuple2(
					_Utils_ap($author$project$Animation$Render$webkitPrefix, propName),
					propValue)
				]);
		case 'filter':
			return _List_fromArray(
				[
					stylePair,
					_Utils_Tuple2(
					_Utils_ap($author$project$Animation$Render$iePrefix, propName),
					propValue),
					_Utils_Tuple2(
					_Utils_ap($author$project$Animation$Render$webkitPrefix, propName),
					propValue)
				]);
		default:
			return _List_fromArray(
				[stylePair]);
	}
};
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$offset = _VirtualDom_attribute('offset');
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$core$Basics$cos = _Basics_cos;
var $elm$core$Basics$sin = _Basics_sin;
var $author$project$Animation$Render$pathCmdValue = function (cmd) {
	var renderPoints = function (coords) {
		return A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				function (_v11) {
					var x = _v11.a;
					var y = _v11.b;
					return $elm$core$String$fromFloat(x.my) + (',' + $elm$core$String$fromFloat(y.my));
				},
				coords));
	};
	switch (cmd.$) {
		case 0:
			var x = cmd.a;
			var y = cmd.b;
			return 'm ' + ($elm$core$String$fromFloat(x.my) + (',' + $elm$core$String$fromFloat(y.my)));
		case 1:
			var x = cmd.a;
			var y = cmd.b;
			return 'M ' + ($elm$core$String$fromFloat(x.my) + (',' + $elm$core$String$fromFloat(y.my)));
		case 2:
			var x = cmd.a;
			var y = cmd.b;
			return 'l ' + ($elm$core$String$fromFloat(x.my) + (',' + $elm$core$String$fromFloat(y.my)));
		case 3:
			var x = cmd.a;
			var y = cmd.b;
			return 'L ' + ($elm$core$String$fromFloat(x.my) + (',' + $elm$core$String$fromFloat(y.my)));
		case 4:
			var a = cmd.a;
			return 'h ' + $elm$core$String$fromFloat(a.my);
		case 5:
			var a = cmd.a;
			return 'H ' + $elm$core$String$fromFloat(a.my);
		case 6:
			var a = cmd.a;
			return 'v ' + $elm$core$String$fromFloat(a.my);
		case 7:
			var a = cmd.a;
			return 'V ' + $elm$core$String$fromFloat(a.my);
		case 8:
			var control1 = cmd.a.cE;
			var control2 = cmd.a.cF;
			var point = cmd.a.aB;
			var _v1 = point;
			var p1x = _v1.a;
			var p1y = _v1.b;
			var _v2 = control2;
			var c2x = _v2.a;
			var c2y = _v2.b;
			var _v3 = control1;
			var c1x = _v3.a;
			var c1y = _v3.b;
			return 'c ' + ($elm$core$String$fromFloat(c1x.my) + (' ' + ($elm$core$String$fromFloat(c1y.my) + (', ' + ($elm$core$String$fromFloat(c2x.my) + (' ' + ($elm$core$String$fromFloat(c2y.my) + (', ' + ($elm$core$String$fromFloat(p1x.my) + (' ' + $elm$core$String$fromFloat(p1y.my)))))))))));
		case 9:
			var control1 = cmd.a.cE;
			var control2 = cmd.a.cF;
			var point = cmd.a.aB;
			var _v4 = point;
			var p1x = _v4.a;
			var p1y = _v4.b;
			var _v5 = control2;
			var c2x = _v5.a;
			var c2y = _v5.b;
			var _v6 = control1;
			var c1x = _v6.a;
			var c1y = _v6.b;
			return 'C ' + ($elm$core$String$fromFloat(c1x.my) + (' ' + ($elm$core$String$fromFloat(c1y.my) + (', ' + ($elm$core$String$fromFloat(c2x.my) + (' ' + ($elm$core$String$fromFloat(c2y.my) + (', ' + ($elm$core$String$fromFloat(p1x.my) + (' ' + $elm$core$String$fromFloat(p1y.my)))))))))));
		case 10:
			var control = cmd.a.cD;
			var point = cmd.a.aB;
			var _v7 = point;
			var p1x = _v7.a;
			var p1y = _v7.b;
			var _v8 = control;
			var c1x = _v8.a;
			var c1y = _v8.b;
			return 'q ' + ($elm$core$String$fromFloat(c1x.my) + (' ' + ($elm$core$String$fromFloat(c1y.my) + (', ' + ($elm$core$String$fromFloat(p1x.my) + (' ' + $elm$core$String$fromFloat(p1y.my)))))));
		case 11:
			var control = cmd.a.cD;
			var point = cmd.a.aB;
			var _v9 = point;
			var p1x = _v9.a;
			var p1y = _v9.b;
			var _v10 = control;
			var c1x = _v10.a;
			var c1y = _v10.b;
			return 'Q ' + ($elm$core$String$fromFloat(c1x.my) + (' ' + ($elm$core$String$fromFloat(c1y.my) + (', ' + ($elm$core$String$fromFloat(p1x.my) + (' ' + $elm$core$String$fromFloat(p1y.my)))))));
		case 12:
			var points = cmd.a;
			return 't ' + renderPoints(points);
		case 13:
			var points = cmd.a;
			return 'T ' + renderPoints(points);
		case 14:
			var points = cmd.a;
			return 's ' + renderPoints(points);
		case 15:
			var points = cmd.a;
			return 'S ' + renderPoints(points);
		case 16:
			var arc = cmd.a;
			var deltaAngle = arc.cI.my - arc.c_.my;
			if (_Utils_cmp(deltaAngle, 360 - 1.0e-6) > 0) {
				var dy = arc.cV.my * $elm$core$Basics$sin(
					$elm$core$Basics$degrees(arc.c_.my));
				var dx = arc.cV.my * $elm$core$Basics$cos(
					$elm$core$Basics$degrees(arc.c_.my));
				return 'A ' + ($elm$core$String$fromFloat(arc.cV.my) + (',' + ($elm$core$String$fromFloat(arc.cV.my) + (',0,1,1,' + ($elm$core$String$fromFloat(arc.dc.my - dx) + (',' + ($elm$core$String$fromFloat(arc.dd.my - dy) + (' A ' + ($elm$core$String$fromFloat(arc.cV.my) + (',' + ($elm$core$String$fromFloat(arc.cV.my) + (',0,1,1,' + ($elm$core$String$fromFloat(arc.dc.my + dx) + (',' + $elm$core$String$fromFloat(arc.dd.my + dy)))))))))))))));
			} else {
				return 'A ' + ($elm$core$String$fromFloat(arc.cV.my) + (',' + ($elm$core$String$fromFloat(arc.cV.my) + (' 0 ' + (((deltaAngle >= 180) ? '1' : '0') + (' ' + ('1' + (' ' + ($elm$core$String$fromFloat(
					arc.dc.my + (arc.cV.my * $elm$core$Basics$cos(
						$elm$core$Basics$degrees(arc.cI.my)))) + (',' + $elm$core$String$fromFloat(
					arc.dd.my + (arc.cV.my * $elm$core$Basics$sin(
						$elm$core$Basics$degrees(arc.cI.my))))))))))))));
			}
		case 17:
			var arc = cmd.a;
			var deltaAngle = arc.cI.my - arc.c_.my;
			if (_Utils_cmp(deltaAngle, 360 - 1.0e-6) > 0) {
				var dy = arc.cV.my * $elm$core$Basics$sin(
					$elm$core$Basics$degrees(arc.c_.my));
				var dx = arc.cV.my * $elm$core$Basics$cos(
					$elm$core$Basics$degrees(arc.c_.my));
				return 'A ' + ($elm$core$String$fromFloat(arc.cV.my) + (',' + ($elm$core$String$fromFloat(arc.cV.my) + (',0,1,0,' + ($elm$core$String$fromFloat(arc.dc.my - dx) + (',' + ($elm$core$String$fromFloat(arc.dd.my - dy) + (' A ' + ($elm$core$String$fromFloat(arc.cV.my) + (',' + ($elm$core$String$fromFloat(arc.cV.my) + (',0,1,1,' + ($elm$core$String$fromFloat(arc.dc.my + dx) + (',' + $elm$core$String$fromFloat(arc.dd.my + dy)))))))))))))));
			} else {
				return 'A ' + ($elm$core$String$fromFloat(arc.cV.my) + (',' + ($elm$core$String$fromFloat(arc.cV.my) + (' 0 ' + ((((arc.c_.my - arc.cI.my) >= 180) ? '1' : '0') + (' ' + ('0' + (' ' + ($elm$core$String$fromFloat(
					arc.dc.my + (arc.cV.my * $elm$core$Basics$cos(arc.cI.my))) + (',' + $elm$core$String$fromFloat(
					arc.dd.my + (arc.cV.my * $elm$core$Basics$sin(arc.cI.my)))))))))))));
			}
		default:
			return 'z';
	}
};
var $author$project$Animation$Render$propertyValue = F2(
	function (prop, delim) {
		switch (prop.$) {
			case 0:
				var value = prop.b;
				return value;
			case 1:
				var r = prop.b;
				var g = prop.c;
				var b = prop.d;
				var a = prop.e;
				return 'rgba(' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(r.my)) + (',' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(g.my)) + (',' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(b.my)) + (',' + ($elm$core$String$fromFloat(a.my) + ')')))))));
			case 2:
				var name = prop.a;
				var inset = prop.b;
				var shadow = prop.c;
				return (inset ? 'inset ' : '') + ($elm$core$String$fromFloat(shadow.ax.my) + ('px' + (' ' + ($elm$core$String$fromFloat(shadow.ay.my) + ('px' + (' ' + ($elm$core$String$fromFloat(shadow.as.my) + ('px' + (' ' + ((((name === 'text-shadow') || (name === 'drop-shadow')) ? '' : ($elm$core$String$fromFloat(shadow.aD.my) + ('px' + ' '))) + ('rgba(' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(shadow.ae.my)) + (', ' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(shadow.aa.my)) + (', ' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(shadow.Y.my)) + (', ' + ($elm$core$String$fromFloat(shadow.X.my) + ')'))))))))))))))))));
			case 3:
				var x = prop.b;
				return _Utils_ap(
					$elm$core$String$fromFloat(x.my),
					x.nO);
			case 4:
				var x = prop.b;
				var y = prop.c;
				return _Utils_ap(
					$elm$core$String$fromFloat(x.my),
					_Utils_ap(
						x.nO,
						_Utils_ap(
							delim,
							_Utils_ap(
								$elm$core$String$fromFloat(y.my),
								y.nO))));
			case 5:
				var x = prop.b;
				var y = prop.c;
				var z = prop.d;
				return _Utils_ap(
					$elm$core$String$fromFloat(x.my),
					_Utils_ap(
						x.nO,
						_Utils_ap(
							delim,
							_Utils_ap(
								$elm$core$String$fromFloat(y.my),
								_Utils_ap(
									y.nO,
									_Utils_ap(
										delim,
										_Utils_ap(
											$elm$core$String$fromFloat(z.my),
											z.nO)))))));
			case 6:
				var w = prop.b;
				var x = prop.c;
				var y = prop.d;
				var z = prop.e;
				return _Utils_ap(
					$elm$core$String$fromFloat(w.my),
					_Utils_ap(
						w.nO,
						_Utils_ap(
							delim,
							_Utils_ap(
								$elm$core$String$fromFloat(x.my),
								_Utils_ap(
									x.nO,
									_Utils_ap(
										delim,
										_Utils_ap(
											$elm$core$String$fromFloat(y.my),
											_Utils_ap(
												y.nO,
												_Utils_ap(
													delim,
													_Utils_ap(
														$elm$core$String$fromFloat(z.my),
														z.nO))))))))));
			case 7:
				var x = prop.b;
				return _Utils_ap(
					$elm$core$String$fromFloat(x.my),
					x.nO);
			case 8:
				var coords = prop.a;
				return A2(
					$elm$core$String$join,
					' ',
					A2(
						$elm$core$List$map,
						function (_v1) {
							var x = _v1.a;
							var y = _v1.b;
							return $elm$core$String$fromFloat(x.my) + (',' + $elm$core$String$fromFloat(y.my));
						},
						coords));
			default:
				var cmds = prop.a;
				return A2(
					$elm$core$String$join,
					' ',
					A2($elm$core$List$map, $author$project$Animation$Render$pathCmdValue, cmds));
		}
	});
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$Attributes$rx = _VirtualDom_attribute('rx');
var $elm$svg$Svg$Attributes$ry = _VirtualDom_attribute('ry');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$Animation$Render$renderAttrs = function (prop) {
	if (A2(
		$elm$core$String$startsWith,
		'attr:',
		$author$project$Animation$Model$propertyName(prop))) {
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$Attributes$attribute,
				A2(
					$elm$core$String$dropLeft,
					5,
					$author$project$Animation$Model$propertyName(prop)),
				A2($author$project$Animation$Render$propertyValue, prop, ' ')));
	} else {
		switch (prop.$) {
			case 8:
				var pts = prop.a;
				return $elm$core$Maybe$Just(
					$elm$svg$Svg$Attributes$points(
						A2($author$project$Animation$Render$propertyValue, prop, ' ')));
			case 9:
				var cmds = prop.a;
				return $elm$core$Maybe$Just(
					$elm$svg$Svg$Attributes$d(
						A2($author$project$Animation$Render$propertyValue, prop, ' ')));
			case 3:
				var name = prop.a;
				var m1 = prop.b;
				switch (name) {
					case 'x':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$x(
								A2($author$project$Animation$Render$propertyValue, prop, ' ')));
					case 'y':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$y(
								A2($author$project$Animation$Render$propertyValue, prop, ' ')));
					case 'cx':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$cx(
								A2($author$project$Animation$Render$propertyValue, prop, ' ')));
					case 'cy':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$cy(
								A2($author$project$Animation$Render$propertyValue, prop, ' ')));
					case 'rx':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$rx(
								A2($author$project$Animation$Render$propertyValue, prop, ' ')));
					case 'ry':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$ry(
								A2($author$project$Animation$Render$propertyValue, prop, ' ')));
					case 'r':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$r(
								A2($author$project$Animation$Render$propertyValue, prop, ' ')));
					case 'offset':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$offset(
								A2($author$project$Animation$Render$propertyValue, prop, ' ')));
					default:
						return $elm$core$Maybe$Nothing;
				}
			case 6:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				var m3 = prop.d;
				var m4 = prop.e;
				return (name === 'viewBox') ? $elm$core$Maybe$Just(
					$elm$svg$Svg$Attributes$viewBox(
						A2($author$project$Animation$Render$propertyValue, prop, ' '))) : $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Nothing;
		}
	}
};
var $author$project$Animation$Render$isAttr = function (prop) {
	return A2(
		$elm$core$String$startsWith,
		'attr:',
		$author$project$Animation$Model$propertyName(prop)) || function () {
		switch (prop.$) {
			case 8:
				return true;
			case 9:
				return true;
			case 3:
				var name = prop.a;
				return (name === 'cx') || ((name === 'cy') || ((name === 'x') || ((name === 'y') || ((name === 'rx') || ((name === 'ry') || ((name === 'r') || (name === 'offset')))))));
			case 6:
				var name = prop.a;
				return name === 'viewBox';
			default:
				return false;
		}
	}();
};
var $author$project$Animation$Render$isFilter = function (prop) {
	return A2(
		$elm$core$List$member,
		$author$project$Animation$Model$propertyName(prop),
		_List_fromArray(
			['filter-url', 'blur', 'brightness', 'contrast', 'grayscale', 'hue-rotate', 'invert', 'saturate', 'sepia', 'drop-shadow']));
};
var $author$project$Animation$Render$render3dRotation = function (prop) {
	if (prop.$ === 5) {
		var x = prop.b;
		var y = prop.c;
		var z = prop.d;
		return 'rotateX(' + ($elm$core$String$fromFloat(x.my) + (x.nO + (') rotateY(' + ($elm$core$String$fromFloat(y.my) + (y.nO + (') rotateZ(' + ($elm$core$String$fromFloat(z.my) + (z.nO + ')'))))))));
	} else {
		return '';
	}
};
var $author$project$Animation$Render$renderValues = function (_v0) {
	var model = _v0;
	var _v1 = A2($elm$core$List$partition, $author$project$Animation$Render$isAttr, model.iW);
	var attrProps = _v1.a;
	var styleProps = _v1.b;
	var _v2 = A3(
		$elm$core$List$foldl,
		F2(
			function (prop, _v3) {
				var myStyle = _v3.a;
				var myTransforms = _v3.b;
				var myFilters = _v3.c;
				return $author$project$Animation$Render$isTransformation(prop) ? _Utils_Tuple3(
					myStyle,
					_Utils_ap(
						myTransforms,
						_List_fromArray(
							[prop])),
					myFilters) : ($author$project$Animation$Render$isFilter(prop) ? _Utils_Tuple3(
					myStyle,
					myTransforms,
					_Utils_ap(
						myFilters,
						_List_fromArray(
							[prop]))) : _Utils_Tuple3(
					_Utils_ap(
						myStyle,
						_List_fromArray(
							[prop])),
					myTransforms,
					myFilters));
			}),
		_Utils_Tuple3(_List_Nil, _List_Nil, _List_Nil),
		styleProps);
	var style = _v2.a;
	var transforms = _v2.b;
	var filters = _v2.c;
	var renderedFilters = $elm$core$List$isEmpty(filters) ? _List_Nil : _List_fromArray(
		[
			_Utils_Tuple2(
			'filter',
			A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (prop) {
						var name = $author$project$Animation$Model$propertyName(prop);
						return (name === 'filter-url') ? ('url(\"' + (A2($author$project$Animation$Render$propertyValue, prop, ', ') + '\")')) : ($author$project$Animation$Model$propertyName(prop) + ('(' + (A2($author$project$Animation$Render$propertyValue, prop, ', ') + ')')));
					},
					filters)))
		]);
	var renderedStyle = A2(
		$elm$core$List$map,
		function (prop) {
			return _Utils_Tuple2(
				$author$project$Animation$Model$propertyName(prop),
				A2($author$project$Animation$Render$propertyValue, prop, ' '));
		},
		style);
	var renderedTransforms = $elm$core$List$isEmpty(transforms) ? _List_Nil : _List_fromArray(
		[
			_Utils_Tuple2(
			'transform',
			A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (prop) {
						return ($author$project$Animation$Model$propertyName(prop) === 'rotate3d') ? $author$project$Animation$Render$render3dRotation(prop) : ($author$project$Animation$Model$propertyName(prop) + ('(' + (A2($author$project$Animation$Render$propertyValue, prop, ', ') + ')')));
					},
					transforms)))
		]);
	return _Utils_Tuple2(
		_Utils_ap(
			renderedTransforms,
			_Utils_ap(renderedFilters, renderedStyle)),
		attrProps);
};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Animation$Render$render = function (animation) {
	var _v0 = $author$project$Animation$Render$renderValues(animation);
	var style = _v0.a;
	var attrProps = _v0.b;
	var otherAttrs = A2($elm$core$List$filterMap, $author$project$Animation$Render$renderAttrs, attrProps);
	var styleAttr = A2(
		$elm$core$List$map,
		function (_v1) {
			var prop = _v1.a;
			var val = _v1.b;
			return A2($elm$html$Html$Attributes$style, prop, val);
		},
		A2($elm$core$List$concatMap, $author$project$Animation$Render$prefix, style));
	return _Utils_ap(styleAttr, otherAttrs);
};
var $author$project$Animation$render = $author$project$Animation$Render$render;
var $author$project$PAM$UI$Basic$renderElAnimation = F2(
	function (animations, otherAttrs) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				$mdgriffith$elm_ui$Element$htmlAttribute,
				$author$project$Animation$render(animations)),
			otherAttrs);
	});
var $author$project$PAM$mapThemesView = F2(
	function (palette, config) {
		var buildMapThemes = A2(
			$elm$core$List$map,
			A2($author$project$PAM$categoryView, palette, config),
			config.h.s.ly);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			A2(
				$author$project$PAM$UI$Basic$renderElAnimation,
				config.R,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Background$color(
						A2($author$project$PAM$UI$Basic$fadeColor, 0.85, palette.cx.fW.G)),
						$mdgriffith$elm_ui$Element$scrollbarY,
						A2($mdgriffith$elm_ui$Element$spacingXY, 0, 1)
					])),
			buildMapThemes);
	});
var $mdgriffith$elm_ui$Element$map = $mdgriffith$elm_ui$Internal$Model$map;
var $mdgriffith$elm_ui$Internal$Flag$fontAlignment = $mdgriffith$elm_ui$Internal$Flag$flag(12);
var $mdgriffith$elm_ui$Element$Font$center = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.nq);
var $author$project$PAM$objectDetailsDefaultEmptyView = function (palette) {
	var defaultTxt = 'Click a feature on the Map or\n            click "View Details" from the grid\n            below to view info or Edit\n            ';
	return A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				$author$project$PAM$UI$Basic$montserrat,
				$mdgriffith$elm_ui$Element$Font$color(palette.cx.fW.eE),
				$mdgriffith$elm_ui$Element$Font$center,
				$mdgriffith$elm_ui$Element$Font$bold,
				$mdgriffith$elm_ui$Element$centerY
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$text(defaultTxt)
			]));
};
var $author$project$PAM$UI$LeftSidebarSearch$ClickGoButton = {$: 1};
var $author$project$PAM$UI$LeftSidebarSearch$EnterWasPressed = {$: 4};
var $author$project$PAM$UI$LeftSidebarSearch$LeftSidebarSearchChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$LeftSidebarSearch$onEnter = function (msg) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Events$on,
			'keyup',
			A2(
				$elm$json$Json$Decode$andThen,
				function (key) {
					return (key === 'Enter') ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
				},
				A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string))));
};
var $mdgriffith$elm_ui$Element$Input$Placeholder = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Input$placeholder = $mdgriffith$elm_ui$Element$Input$Placeholder;
var $author$project$PAM$UI$LeftSidebarSearch$defaultResultView = F3(
	function (palette, env, model) {
		var queryResult = function () {
			var _v0 = model.mG;
			if (_v0.$ === 3) {
				var rList = _v0.a;
				return rList;
			} else {
				return _List_Nil;
			}
		}();
		var defaultTxt5 = env.bR.lD.g$;
		var defaultTxt4 = env.bR.lD.g_;
		var defaultTxt3 = env.bR.lD.gZ;
		return A2(
			$mdgriffith$elm_ui$Element$textColumn,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(350)),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$padding(0),
					$mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$textColumn,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(0),
									$mdgriffith$elm_ui$Element$padding(0),
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(350))
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$alignLeft,
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
										]),
									$mdgriffith$elm_ui$Element$text('-')),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$paddingEach(
											_Utils_update(
												$author$project$PAM$UI$Basic$edges,
												{cR: 7, c3: 0}))
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(defaultTxt3)
										]))
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$textColumn,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(0),
									$mdgriffith$elm_ui$Element$padding(0),
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(350))
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$alignLeft,
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
										]),
									$mdgriffith$elm_ui$Element$text('-')),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$paddingEach(
											_Utils_update(
												$author$project$PAM$UI$Basic$edges,
												{cR: 7, c3: 0}))
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(defaultTxt4)
										]))
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$textColumn,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(0),
									$mdgriffith$elm_ui$Element$padding(0),
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(350))
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$alignLeft,
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
										]),
									$mdgriffith$elm_ui$Element$text('-')),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$paddingEach(
											_Utils_update(
												$author$project$PAM$UI$Basic$edges,
												{cR: 7, c3: 0}))
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(defaultTxt5)
										]))
								]))
						]))
				]));
	});
var $author$project$PAM$UI$LeftSidebarSearch$ResultItemSelected = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$UI$LeftSidebarSearch$listResultView = F3(
	function (palette, env, model) {
		var queryResult = function () {
			var _v0 = model.mG;
			if (_v0.$ === 3) {
				var rList = _v0.a;
				return rList;
			} else {
				return _List_Nil;
			}
		}();
		var returnCount = $elm$core$List$length(queryResult);
		var resultComment = (returnCount === 10) ? 'The first 10 results are shown below. Please choose the fire incident you wish to view.' : ((returnCount === 1) ? 'One result found. Please choose the fire incident to view.' : ((!returnCount) ? '' : ($elm$core$String$fromInt(returnCount) + ' results found. Please choose the fire incident you wish to view.')));
		return A2(
			$mdgriffith$elm_ui$Element$textColumn,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(350)),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$padding(0),
					$mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$textColumn,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(0),
									$mdgriffith$elm_ui$Element$padding(0),
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(350))
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$paddingEach(
											_Utils_update(
												$author$project$PAM$UI$Basic$edges,
												{cR: 7, c3: 0}))
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(resultComment)
										]))
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$textColumn,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(0),
									$mdgriffith$elm_ui$Element$padding(0),
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(350))
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Border$widthEach(
											_Utils_update(
												$author$project$PAM$UI$Basic$edges,
												{cz: 1})),
											$mdgriffith$elm_ui$Element$Border$color(
											A2($author$project$PAM$UI$Basic$fadeColor, 0.25, $author$project$PAM$UI$Basic$white))
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('')
										])),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$centerX,
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
										]),
									($elm$core$List$length(queryResult) > 0) ? A2(
										$mdgriffith$elm_ui$Element$textColumn,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
											]),
										A2(
											$elm$core$List$map,
											function (a) {
												return A2(
													$mdgriffith$elm_ui$Element$paragraph,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
															$mdgriffith$elm_ui$Element$Border$widthEach(
															_Utils_update(
																$author$project$PAM$UI$Basic$edges,
																{cz: 1})),
															$mdgriffith$elm_ui$Element$Border$color(
															A2($author$project$PAM$UI$Basic$fadeColor, 0.25, $author$project$PAM$UI$Basic$white)),
															$mdgriffith$elm_ui$Element$htmlAttribute(
															A2($elm$html$Html$Attributes$style, 'white-space', 'pre')),
															$mdgriffith$elm_ui$Element$spacing(3),
															$mdgriffith$elm_ui$Element$padding(5)
														]),
													_List_fromArray(
														[
															A2(
															$mdgriffith$elm_ui$Element$el,
															_List_Nil,
															A2(
																$mdgriffith$elm_ui$Element$row,
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$spacing(10)
																	]),
																_List_fromArray(
																	[
																		A2(
																		$mdgriffith$elm_ui$Element$paragraph,
																		_List_fromArray(
																			[
																				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
																				$mdgriffith$elm_ui$Element$paddingEach(
																				_Utils_update(
																					$author$project$PAM$UI$Basic$edges,
																					{cz: 10, cR: 0, cX: 0, c3: 5})),
																				$mdgriffith$elm_ui$Element$Font$size(
																				A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
																				$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
																			]),
																		_List_fromArray(
																			[
																				$mdgriffith$elm_ui$Element$text(a.fu)
																			])),
																		A2(
																		$mdgriffith$elm_ui$Element$el,
																		_List_fromArray(
																			[
																				$mdgriffith$elm_ui$Element$alignRight,
																				$mdgriffith$elm_ui$Element$Background$color(palette.S.fK.G),
																				A2($mdgriffith$elm_ui$Element$paddingXY, 5, 3),
																				$mdgriffith$elm_ui$Element$Border$rounded(5),
																				$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
																				$mdgriffith$elm_ui$Element$Events$onClick(
																				$author$project$PAM$UI$LeftSidebarSearch$ResultItemSelected(a)),
																				$mdgriffith$elm_ui$Element$pointer,
																				$mdgriffith$elm_ui$Element$mouseOver(
																				_List_fromArray(
																					[
																						$mdgriffith$elm_ui$Element$Background$color(
																						A2($author$project$PAM$UI$Basic$darkenColor, 0.15, palette.S.fK.G))
																					]))
																			]),
																		$mdgriffith$elm_ui$Element$text('View'))
																	]))),
															A2(
															$mdgriffith$elm_ui$Element$el,
															_List_Nil,
															A2(
																$mdgriffith$elm_ui$Element$row,
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$spacing(10)
																	]),
																_List_fromArray(
																	[
																		A2(
																		$mdgriffith$elm_ui$Element$paragraph,
																		_List_fromArray(
																			[
																				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
																				$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
																			]),
																		_List_fromArray(
																			[
																				$mdgriffith$elm_ui$Element$text('Fire ID: ' + a.f_)
																			]))
																	]))),
															A2(
															$mdgriffith$elm_ui$Element$el,
															_List_Nil,
															A2(
																$mdgriffith$elm_ui$Element$row,
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$spacing(10)
																	]),
																_List_fromArray(
																	[
																		A2(
																		$mdgriffith$elm_ui$Element$paragraph,
																		_List_fromArray(
																			[
																				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
																				$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
																			]),
																		_List_fromArray(
																			[
																				$mdgriffith$elm_ui$Element$text('IRWIN ID: ' + a.fi)
																			]))
																	])))
														]));
											},
											queryResult)) : $mdgriffith$elm_ui$Element$none)
								]))
						]))
				]));
	});
var $author$project$PAM$UI$LeftSidebarSearch$resultView = F3(
	function (palette, env, model) {
		var resultList = function () {
			var _v1 = model.mG;
			if (_v1.$ === 3) {
				var rList = _v1.a;
				return rList;
			} else {
				return _List_Nil;
			}
		}();
		var resultListLength = $elm$core$List$length(resultList);
		var selectedView = function () {
			if (!resultListLength) {
				return A3($author$project$PAM$UI$LeftSidebarSearch$defaultResultView, palette, env, model);
			} else {
				return A3($author$project$PAM$UI$LeftSidebarSearch$listResultView, palette, env, model);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			selectedView);
	});
var $mdgriffith$elm_ui$Element$Border$roundEach = function (_v0) {
	var topLeft = _v0.i2;
	var topRight = _v0.i3;
	var bottomLeft = _v0.gv;
	var bottomRight = _v0.gw;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + ($elm$core$String$fromInt(topLeft) + ('-' + ($elm$core$String$fromInt(topRight) + ($elm$core$String$fromInt(bottomLeft) + ('-' + $elm$core$String$fromInt(bottomRight)))))),
			'border-radius',
			$elm$core$String$fromInt(topLeft) + ('px ' + ($elm$core$String$fromInt(topRight) + ('px ' + ($elm$core$String$fromInt(bottomRight) + ('px ' + ($elm$core$String$fromInt(bottomLeft) + 'px'))))))));
};
var $mdgriffith$elm_ui$Element$Input$TextInputNode = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$Input$Padding = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Transparency = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$transparency = $mdgriffith$elm_ui$Internal$Flag$flag(0);
var $mdgriffith$elm_ui$Element$alpha = function (o) {
	var transparency = function (x) {
		return 1 - x;
	}(
		A2(
			$elm$core$Basics$min,
			1.0,
			A2($elm$core$Basics$max, 0.0, o)));
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Transparency,
			'transparency-' + $mdgriffith$elm_ui$Internal$Model$floatClass(transparency),
			transparency));
};
var $mdgriffith$elm_ui$Element$Input$autofill = A2(
	$elm$core$Basics$composeL,
	$mdgriffith$elm_ui$Internal$Model$Attr,
	$elm$html$Html$Attributes$attribute('autocomplete'));
var $mdgriffith$elm_ui$Element$rgb = F3(
	function (r, g, b) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, r, g, b, 1);
	});
var $mdgriffith$elm_ui$Element$Input$charcoal = A3($mdgriffith$elm_ui$Element$rgb, 136 / 255, 138 / 255, 133 / 255);
var $mdgriffith$elm_ui$Element$Input$darkGrey = A3($mdgriffith$elm_ui$Element$rgb, 186 / 255, 189 / 255, 182 / 255);
var $mdgriffith$elm_ui$Element$Input$defaultTextPadding = A2($mdgriffith$elm_ui$Element$paddingXY, 12, 12);
var $mdgriffith$elm_ui$Element$Input$white = A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1);
var $mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Input$defaultTextPadding,
		$mdgriffith$elm_ui$Element$Border$rounded(3),
		$mdgriffith$elm_ui$Element$Border$color($mdgriffith$elm_ui$Element$Input$darkGrey),
		$mdgriffith$elm_ui$Element$Background$color($mdgriffith$elm_ui$Element$Input$white),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$spacing(3),
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
	]);
var $mdgriffith$elm_ui$Internal$Model$filter = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (x, _v0) {
				var found = _v0.a;
				var has = _v0.b;
				switch (x.$) {
					case 0:
						return _Utils_Tuple2(found, has);
					case 3:
						var key = x.a;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 1:
						var attr = x.a;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 4:
						var style = x.b;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 7:
						var width = x.a;
						return A2($elm$core$Set$member, 'width', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'width', has));
					case 8:
						var height = x.a;
						return A2($elm$core$Set$member, 'height', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'height', has));
					case 2:
						var description = x.a;
						return A2($elm$core$Set$member, 'described', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'described', has));
					case 9:
						var location = x.a;
						var elem = x.b;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 6:
						return A2($elm$core$Set$member, 'align-x', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'align-x', has));
					case 5:
						return A2($elm$core$Set$member, 'align-y', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'align-y', has));
					default:
						return A2($elm$core$Set$member, 'transform', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'transform', has));
				}
			}),
		_Utils_Tuple2(_List_Nil, $elm$core$Set$empty),
		attrs).a;
};
var $mdgriffith$elm_ui$Internal$Model$get = F2(
	function (attrs, isAttr) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, found) {
					return isAttr(x) ? A2($elm$core$List$cons, x, found) : found;
				}),
			_List_Nil,
			$mdgriffith$elm_ui$Internal$Model$filter(attrs));
	});
var $mdgriffith$elm_ui$Element$Input$inheritablePlaceholderAttributes = function (attr) {
	_v0$3:
	while (true) {
		if (attr.$ === 4) {
			switch (attr.b.$) {
				case 7:
					var _v1 = attr.b;
					return true;
				case 6:
					var _v2 = attr.b;
					return true;
				case 10:
					return true;
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return false;
};
var $mdgriffith$elm_ui$Internal$Model$isContent = function (len) {
	isContent:
	while (true) {
		switch (len.$) {
			case 1:
				return true;
			case 4:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isContent;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isContent;
			default:
				return false;
		}
	}
};
var $elm$html$Html$Attributes$spellcheck = $elm$html$Html$Attributes$boolProperty('spellcheck');
var $mdgriffith$elm_ui$Element$Input$spellcheck = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$spellcheck);
var $mdgriffith$elm_ui$Internal$Flag$heightTextAreaContent = $mdgriffith$elm_ui$Internal$Flag$flag(47);
var $elm$core$String$lines = _String_lines;
var $mdgriffith$elm_ui$Element$Input$textHeightContent = F4(
	function (textValue, spacing, maybePadding, maybeBorder) {
		var topBottom = function (_v0) {
			var t = _v0.a;
			var b = _v0.c;
			return t + b;
		};
		var newlineCount = function (x) {
			return (x < 1) ? 1 : x;
		}(
			$elm$core$List$length(
				$elm$core$String$lines(textValue)));
		var additionalSpacing = (((newlineCount - 1) * spacing) + A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, topBottom, maybePadding))) + A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, topBottom, maybeBorder));
		var heightValue = function (count) {
			return 'calc(' + ($elm$core$String$fromInt(count) + ('em + ' + ($elm$core$String$fromInt(additionalSpacing) + 'px) !important')));
		};
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$heightTextAreaContent,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				'textarea-height-' + $elm$core$String$fromInt(newlineCount),
				'height',
				heightValue(newlineCount)));
	});
var $mdgriffith$elm_ui$Element$Input$value = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$value);
var $mdgriffith$elm_ui$Element$Input$textHelper = F3(
	function (textInput, attrs, textOptions) {
		var forNearby = function (attr) {
			if (attr.$ === 9) {
				return true;
			} else {
				return false;
			}
		};
		var behavior = _List_fromArray(
			[
				$mdgriffith$elm_ui$Internal$Model$Attr(
				$elm$html$Html$Events$onInput(textOptions.h1))
			]);
		var attributes = _Utils_ap($mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle, attrs);
		var attributesFromChild = A2(
			$mdgriffith$elm_ui$Internal$Model$get,
			attributes,
			function (attr) {
				_v21$7:
				while (true) {
					switch (attr.$) {
						case 7:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _v21$7;
							}
						case 8:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _v21$7;
							}
						case 6:
							return true;
						case 5:
							return true;
						case 4:
							switch (attr.b.$) {
								case 5:
									var _v22 = attr.b;
									return true;
								case 2:
									return true;
								case 1:
									var _v23 = attr.b;
									return true;
								default:
									break _v21$7;
							}
						default:
							break _v21$7;
					}
				}
				return false;
			});
		var forPlaceholder = A2($elm$core$List$filter, $mdgriffith$elm_ui$Element$Input$inheritablePlaceholderAttributes, attributes);
		var heightFillFromChild = A2(
			$mdgriffith$elm_ui$Internal$Model$get,
			attributes,
			function (attr) {
				if ((attr.$ === 8) && (attr.a.$ === 2)) {
					return true;
				} else {
					return false;
				}
			});
		var inputPadding = A2(
			$mdgriffith$elm_ui$Internal$Model$get,
			attributes,
			function (attr) {
				if ((attr.$ === 4) && (attr.b.$ === 7)) {
					var _v19 = attr.b;
					return true;
				} else {
					return false;
				}
			});
		var nearbys = A2(
			$mdgriffith$elm_ui$Internal$Model$get,
			attributes,
			function (attr) {
				if (attr.$ === 9) {
					return true;
				} else {
					return false;
				}
			});
		var noNearbys = A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, forNearby),
			attributes);
		var _v0 = function () {
			var _v1 = textInput.bi;
			if (!_v1.$) {
				var inputType = _v1.a;
				return _Utils_Tuple3(
					'input',
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Input$value(textOptions.eE),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$type_(inputType)),
								$mdgriffith$elm_ui$Element$Input$spellcheck(textInput.ap),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ab),
								function () {
								var _v2 = textInput.ak;
								if (_v2.$ === 1) {
									return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
								} else {
									var fill = _v2.a;
									return $mdgriffith$elm_ui$Element$Input$autofill(fill);
								}
							}()
							]),
						noNearbys),
					_List_Nil);
			} else {
				var _v3 = A3(
					$elm$core$List$foldr,
					F2(
						function (attr, found) {
							_v4$5:
							while (true) {
								switch (attr.$) {
									case 2:
										return found;
									case 8:
										var len = attr.a;
										var _v5 = found.dZ;
										if (_v5.$ === 1) {
											return _Utils_update(
												found,
												{
													W: A2($elm$core$List$cons, attr, found.W),
													dZ: $elm$core$Maybe$Just(
														$mdgriffith$elm_ui$Internal$Model$isContent(len))
												});
										} else {
											return found;
										}
									case 4:
										switch (attr.b.$) {
											case 6:
												var _v6 = attr.b;
												var t = _v6.b;
												var r = _v6.c;
												var b = _v6.d;
												var l = _v6.e;
												var _v7 = found.eb;
												if (_v7.$ === 1) {
													return _Utils_update(
														found,
														{
															W: A2($elm$core$List$cons, attr, found.W),
															eb: $elm$core$Maybe$Just(
																A4($mdgriffith$elm_ui$Element$Input$Padding, t, r, b, l))
														});
												} else {
													return found;
												}
											case 7:
												var _v8 = attr.b;
												var t = _v8.b;
												var r = _v8.c;
												var b = _v8.d;
												var l = _v8.e;
												var _v9 = found.ec;
												if (_v9.$ === 1) {
													return _Utils_update(
														found,
														{
															W: found.W,
															ec: $elm$core$Maybe$Just(
																A4($mdgriffith$elm_ui$Element$Input$Padding, t, r, b, l))
														});
												} else {
													return found;
												}
											case 5:
												var _v10 = attr.b;
												var x = _v10.b;
												var y = _v10.c;
												var _v11 = found.ed;
												if (_v11.$ === 1) {
													return _Utils_update(
														found,
														{
															W: A2($elm$core$List$cons, attr, found.W),
															ed: $elm$core$Maybe$Just(y)
														});
												} else {
													return found;
												}
											default:
												break _v4$5;
										}
									default:
										break _v4$5;
								}
							}
							return _Utils_update(
								found,
								{
									W: A2($elm$core$List$cons, attr, found.W)
								});
						}),
					{W: _List_Nil, dZ: $elm$core$Maybe$Nothing, eb: $elm$core$Maybe$Nothing, ec: $elm$core$Maybe$Nothing, ed: $elm$core$Maybe$Nothing},
					attributes);
				var maybePadding = _v3.ec;
				var heightContent = _v3.dZ;
				var maybeSpacing = _v3.ed;
				var adjustedAttributes = _v3.W;
				var maybeBorder = _v3.eb;
				var spacing = A2($elm$core$Maybe$withDefault, 5, maybeSpacing);
				return _Utils_Tuple3(
					'textarea',
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Input$spellcheck(textInput.ap),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ll),
								A2(
								$elm$core$Maybe$withDefault,
								$mdgriffith$elm_ui$Internal$Model$NoAttribute,
								A2($elm$core$Maybe$map, $mdgriffith$elm_ui$Element$Input$autofill, textInput.ak)),
								function () {
								if (maybePadding.$ === 1) {
									return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
								} else {
									var _v13 = maybePadding.a;
									var t = _v13.a;
									var r = _v13.b;
									var b = _v13.c;
									var l = _v13.d;
									return $mdgriffith$elm_ui$Element$paddingEach(
										{
											cz: A2($elm$core$Basics$max, 0, b - ((spacing / 2) | 0)),
											cR: l,
											cX: r,
											c3: A2($elm$core$Basics$max, 0, t - ((spacing / 2) | 0))
										});
								}
							}(),
								function () {
								if (heightContent.$ === 1) {
									return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
								} else {
									if (heightContent.a) {
										return A4($mdgriffith$elm_ui$Element$Input$textHeightContent, textOptions.eE, spacing, maybePadding, maybeBorder);
									} else {
										return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
									}
								}
							}()
							]),
						adjustedAttributes),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Internal$Model$unstyled(
							$elm$html$Html$text(textOptions.eE))
						]));
			}
		}();
		var inputNode = _v0.a;
		var inputAttrs = _v0.b;
		var inputChildren = _v0.c;
		var inputElement = A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$elm$core$List$concat(
					_List_fromArray(
						[
							nearbys,
							heightFillFromChild,
							function () {
							var _v15 = textOptions.mv;
							if (_v15.$ === 1) {
								return _List_Nil;
							} else {
								var _v16 = _v15.a;
								var placeholderAttrs = _v16.a;
								var placeholderEl = _v16.b;
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$inFront(
										A2(
											$mdgriffith$elm_ui$Element$el,
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Element$Input$defaultTextPadding,
												_Utils_ap(
													forPlaceholder,
													_Utils_ap(
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$Font$color($mdgriffith$elm_ui$Element$Input$charcoal),
																$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.hW + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.mu)),
																$mdgriffith$elm_ui$Element$Border$color(
																A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
																$mdgriffith$elm_ui$Element$Background$color(
																A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
																$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
																$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																$mdgriffith$elm_ui$Element$alpha(
																(textOptions.eE === '') ? 1 : 0)
															]),
														placeholderAttrs))),
											placeholderEl))
									]);
							}
						}()
						]))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName(inputNode),
						$elm$core$List$concat(
							_List_fromArray(
								[
									_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
										$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(textOptions.a8)
									]),
									inputAttrs,
									behavior
								])),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(inputChildren))
					])));
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.kt),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(textOptions.a8) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(5),
					A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Region$announce, attributesFromChild))),
			textOptions.a8,
			inputElement);
	});
var $mdgriffith$elm_ui$Element$Input$text = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		ak: $elm$core$Maybe$Nothing,
		ap: false,
		bi: $mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var $author$project$PAM$UI$LeftSidebarSearch$view = F3(
	function (palette, env, model) {
		var defaultTxt2 = env.bR.lD.gY;
		var defaultTxt1 = env.bR.lD.gX;
		return A2(
			$mdgriffith$elm_ui$Element$textColumn,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(350)),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$padding(0),
					$mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(defaultTxt1)
						])),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(defaultTxt2)
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(350)),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$px(350)),
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(30)),
								$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$Border$rounded(5),
								$mdgriffith$elm_ui$Element$Border$width(0),
								$mdgriffith$elm_ui$Element$centerY,
								$mdgriffith$elm_ui$Element$padding(0)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$Input$text,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(300)),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(7)),
										$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent0),
										$mdgriffith$elm_ui$Element$Border$width(0),
										$mdgriffith$elm_ui$Element$Border$roundEach(
										{gv: 5, gw: 0, i2: 5, i3: 0}),
										$mdgriffith$elm_ui$Element$Font$size(
										A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
										$author$project$PAM$UI$LeftSidebarSearch$onEnter($author$project$PAM$UI$LeftSidebarSearch$EnterWasPressed)
									]),
								{
									a8: $mdgriffith$elm_ui$Element$Input$labelHidden('Dont look at this'),
									h1: A2($elm$core$Basics$composeR, $author$project$PAM$UI$LeftSidebarSearch$LeftSidebarSearchChanged, $author$project$PAM$UI$LeftSidebarSearch$Internal),
									mv: $elm$core$Maybe$Just(
										A2(
											$mdgriffith$elm_ui$Element$Input$placeholder,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$px(14)),
													$mdgriffith$elm_ui$Element$Border$width(0),
													$mdgriffith$elm_ui$Element$paddingEach(
													_Utils_update(
														$author$project$PAM$UI$Basic$edges,
														{cR: 15, c3: 6}))
												]),
											$mdgriffith$elm_ui$Element$text('Enter Fire ID, IRWIN ID, or Fire Name'))),
									eE: function () {
										var _v0 = model.lC;
										if (!_v0.$) {
											var leftSearchInput = _v0.a;
											return leftSearchInput;
										} else {
											return '';
										}
									}()
								}),
								A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(30)),
										A2($mdgriffith$elm_ui$Element$paddingXY, 15, 0),
										$mdgriffith$elm_ui$Element$Border$rounded(5)
									]),
								{
									a8: A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE),
												$mdgriffith$elm_ui$Element$Font$bold,
												$author$project$PAM$UI$Basic$montserrat,
												$mdgriffith$elm_ui$Element$centerY
											]),
										$mdgriffith$elm_ui$Element$text('GO')),
									b8: $elm$core$Maybe$Just($author$project$PAM$UI$LeftSidebarSearch$ClickGoButton)
								})
							]))),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$orangeRoughy),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 0)),
							$mdgriffith$elm_ui$Element$Font$bold,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(model.kN)
						])),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(0),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$textColumn,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(0),
									$mdgriffith$elm_ui$Element$padding(0),
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(350))
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$alignLeft,
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
										]),
									A3($author$project$PAM$UI$LeftSidebarSearch$resultView, palette, env, model))
								]))
						]))
				]));
	});
var $author$project$PAM$objectDetailsEmptyView = F3(
	function (palette, env, config) {
		var defaultView = function () {
			var _v0 = env.bR.M.hZ;
			if ((!_v0.$) && (_v0.a === 'FireMAP')) {
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$PAM$leftSidebarSearchTranslator,
					A3($author$project$PAM$UI$LeftSidebarSearch$view, palette, env, config.aP));
			} else {
				return $author$project$PAM$objectDetailsDefaultEmptyView(palette);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$el,
			A2(
				$author$project$PAM$UI$Basic$renderElAnimation,
				config.bu,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Background$color(
						A2($author$project$PAM$UI$Basic$fadeColor, 0.85, palette.cx.fW.G)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{cz: 10, cR: 10, cX: 10, c3: 10}),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					])),
			defaultView);
	});
var $author$project$PAM$objectDetailsFooterEmptyView = F2(
	function (palette, config) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(config.fD.hG)),
					$mdgriffith$elm_ui$Element$Background$color(
					A2($author$project$PAM$UI$Basic$fadeColor, 0.85, palette.cx.fW.G))
				]),
			_List_Nil);
	});
var $author$project$PAM$objectDetailsFooterView = F3(
	function (palette, config, footerViews) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(config.fD.hG)),
					$mdgriffith$elm_ui$Element$Background$color(
					A2($author$project$PAM$UI$Basic$fadeColor, 0.85, palette.cx.fW.G))
				]),
			A2(
				$elm$core$List$map,
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						])),
				footerViews));
	});
var $author$project$PAM$objectDetailsHeaderView = F3(
	function (palette, config, children) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, config.f5, 1)),
					$mdgriffith$elm_ui$Element$Font$bold,
					$author$project$PAM$UI$Basic$montserrat,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(config.fD.lE)),
					$mdgriffith$elm_ui$Element$clip
				]),
			children);
	});
var $author$project$Fields$Shared$headerView = F2(
	function (palette, config) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$size(20)
				]),
			_List_fromArray(
				[
					$author$project$Fields$Shared$requiredMarker(config.lr),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(palette.S.fK.eE)
						]),
					$mdgriffith$elm_ui$Element$text(
						A2($elm$core$Maybe$withDefault, '', config.kT)))
				]));
	});
var $author$project$Fields$Shared$columnFieldLabel = F3(
	function (palette, config, fView) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(16),
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
					$mdgriffith$elm_ui$Element$paddingEach(
					{cz: 10, cR: 0, cX: 0, c3: 10}),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{cz: 0, cR: 0, cX: 0, c3: 1}),
					$mdgriffith$elm_ui$Element$Border$color(palette.S.fK.eE),
					$mdgriffith$elm_ui$Element$clip
				]),
			_List_fromArray(
				[
					A2($author$project$Fields$Shared$headerView, palette, config),
					fView
				]));
	});
var $author$project$Fields$GenericField$errorView = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_Nil,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$text('Cannot render field')
		]));
var $elm$html$Html$Attributes$maxlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'maxlength',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Fields$Shared$toMaybeString = function (input) {
	return (!$elm$core$String$length(input)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(input);
};
var $author$project$Fields$MaybeStringField$longView = F2(
	function (config, field) {
		var _v0 = field.hj(config.kS);
		var val = _v0.a;
		var _default = _v0.b;
		var displayValue = A2(
			$elm$core$Maybe$withDefault,
			config.lq ? _default : '',
			val);
		return $mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$textarea,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('input'),
						$elm$html$Html$Attributes$value(displayValue),
						$elm$html$Html$Events$onInput(
						function (a) {
							return A2(
								field.m3,
								config.kS,
								$author$project$Fields$Shared$toMaybeString(a));
						}),
						$elm$html$Html$Attributes$disabled(config.lq),
						$elm$html$Html$Attributes$maxlength(
						A2($elm$core$Maybe$withDefault, -1, field.hJ))
					]),
				_List_Nil));
	});
var $author$project$Fields$Shared$labelView = function (config) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(125)),
				$mdgriffith$elm_ui$Element$alignLeft
			]),
		_List_fromArray(
			[
				$author$project$Fields$Shared$requiredMarker(config.lr),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(
						A4($mdgriffith$elm_ui$Element$rgba255, 255, 255, 255, 1)),
						$mdgriffith$elm_ui$Element$alignLeft,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'text-align', 'left'))
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(
							A2($elm$core$Maybe$withDefault, '', config.kT)))
					]))
			]));
};
var $author$project$Fields$Shared$rowFieldLabel = F2(
	function (config, fView) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					A2($mdgriffith$elm_ui$Element$minimum, 30, $mdgriffith$elm_ui$Element$shrink)),
					$mdgriffith$elm_ui$Element$padding(2)
				]),
			_List_fromArray(
				[
					$author$project$Fields$Shared$labelView(config),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alignLeft,
							$mdgriffith$elm_ui$Element$padding(1),
							$mdgriffith$elm_ui$Element$Border$width(1),
							$mdgriffith$elm_ui$Element$Border$rounded(4),
							$mdgriffith$elm_ui$Element$Border$color(
							config.lt ? A4($mdgriffith$elm_ui$Element$rgba255, 0, 0, 0, 0) : A4($mdgriffith$elm_ui$Element$rgba255, 255, 0, 0, 1))
						]),
					_List_fromArray(
						[fView]))
				]));
	});
var $author$project$Fields$CustomField$view = F3(
	function (palette, config, field) {
		return A3(field.hj, config.lq, config.kS, palette);
	});
var $author$project$PAM$UI$Basic$id = function (idname) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2($elm$html$Html$Attributes$attribute, 'id', idname));
};
var $mdgriffith$elm_ui$Element$spaceEvenly = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$spacing, $mdgriffith$elm_ui$Internal$Style$classes.nb);
var $author$project$PAM$UI$DatePicker$ClosePicker = {$: 9};
var $author$project$PAM$UI$DatePicker$OpenPicker = {$: 8};
var $author$project$PAM$UI$DatePicker$SecondText = function (a) {
	return {$: 4, a: a};
};
var $author$project$PAM$UI$DatePicker$Text = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$UI$DatePicker$calendarDayHeight = $mdgriffith$elm_ui$Element$height(
	$mdgriffith$elm_ui$Element$px(18));
var $author$project$PAM$UI$DatePicker$calendarDayWidth = $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill);
var $author$project$PAM$UI$DatePicker$getPriorityDay = F5(
	function (focused, pickedDate, selection, today, mode) {
		if (!mode) {
			var _v1 = _Utils_Tuple2(focused, pickedDate);
			if (!_v1.a.$) {
				var day = _v1.a.a;
				return day;
			} else {
				if ((!_v1.b.$) && (_v1.b.a.$ === 1)) {
					var day = _v1.b.a.a;
					return day;
				} else {
					return today;
				}
			}
		} else {
			var _v2 = _Utils_Tuple3(focused, selection, pickedDate);
			_v2$0:
			while (true) {
				_v2$1:
				while (true) {
					_v2$2:
					while (true) {
						_v2$3:
						while (true) {
							if (_v2.b.$ === 2) {
								if ((!_v2.c.$) && (_v2.c.a.$ === 2)) {
									switch (_v2.b.a.$) {
										case 1:
											switch (_v2.c.a.a.$) {
												case 1:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$1;
													}
												case 2:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$1;
													}
												default:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$1;
													}
											}
										case 0:
											switch (_v2.c.a.a.$) {
												case 1:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$2;
													}
												case 2:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$2;
													}
												default:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$2;
													}
											}
										default:
											switch (_v2.c.a.a.$) {
												case 1:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$3;
													}
												case 2:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$3;
													}
												default:
													if (!_v2.a.$) {
														break _v2$0;
													} else {
														break _v2$3;
													}
											}
									}
								} else {
									switch (_v2.b.a.$) {
										case 1:
											if (!_v2.a.$) {
												break _v2$0;
											} else {
												break _v2$1;
											}
										case 0:
											if (!_v2.a.$) {
												break _v2$0;
											} else {
												break _v2$2;
											}
										default:
											if (!_v2.a.$) {
												break _v2$0;
											} else {
												break _v2$3;
											}
									}
								}
							} else {
								if ((!_v2.c.$) && (_v2.c.a.$ === 2)) {
									switch (_v2.c.a.a.$) {
										case 1:
											if (!_v2.a.$) {
												break _v2$0;
											} else {
												var start = _v2.c.a.a.a;
												return start;
											}
										case 2:
											if (!_v2.a.$) {
												break _v2$0;
											} else {
												var end = _v2.c.a.a.a;
												return end;
											}
										default:
											if (!_v2.a.$) {
												break _v2$0;
											} else {
												var _v4 = _v2.c.a.a;
												var start = _v4.a;
												var end = _v4.b;
												return start;
											}
									}
								} else {
									if (!_v2.a.$) {
										break _v2$0;
									} else {
										return today;
									}
								}
							}
						}
						var end = _v2.b.a.a;
						return end;
					}
					var _v3 = _v2.b.a;
					var start = _v3.a;
					var end = _v3.b;
					return start;
				}
				var start = _v2.b.a.a;
				return start;
			}
			var day = _v2.a.a;
			return day;
		}
	});
var $author$project$PAM$UI$DatePicker$groupDates = function (dates) {
	var go = F4(
		function (i, xs, racc, acc) {
			go:
			while (true) {
				if (!xs.b) {
					return $elm$core$List$reverse(acc);
				} else {
					var x = xs.a;
					var xxs = xs.b;
					if (i === 6) {
						var $temp$i = 0,
							$temp$xs = xxs,
							$temp$racc = _List_Nil,
							$temp$acc = A2(
							$elm$core$List$cons,
							$elm$core$List$reverse(
								A2($elm$core$List$cons, x, racc)),
							acc);
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					} else {
						var $temp$i = i + 1,
							$temp$xs = xxs,
							$temp$racc = A2($elm$core$List$cons, x, racc),
							$temp$acc = acc;
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					}
				}
			}
		});
	return A4(go, 0, dates, _List_Nil, _List_Nil);
};
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Weeks = 2;
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3($justinmimbs$date$Date$add, unit, step, current);
				var next = _v0;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0;
		var until = _v1;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2($justinmimbs$date$Date$ceiling, interval, start);
		var first = _v3;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $justinmimbs$date$Date$Friday = 8;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Saturday = 9;
var $justinmimbs$date$Date$Sunday = 10;
var $justinmimbs$date$Date$Thursday = 7;
var $justinmimbs$date$Date$Tuesday = 5;
var $justinmimbs$date$Date$Wednesday = 6;
var $author$project$PAM$UI$DatePicker$Date$weekdayToInterval = function (weekday) {
	switch (weekday) {
		case 0:
			return 4;
		case 1:
			return 5;
		case 2:
			return 6;
		case 3:
			return 7;
		case 4:
			return 8;
		case 5:
			return 9;
		default:
			return 10;
	}
};
var $author$project$PAM$UI$DatePicker$prepareDates = F2(
	function (date, firstDayOfWeek) {
		var weekdayAsInterval = $author$project$PAM$UI$DatePicker$Date$weekdayToInterval(firstDayOfWeek);
		var start = A2(
			$justinmimbs$date$Date$floor,
			weekdayAsInterval,
			A3(
				$justinmimbs$date$Date$fromCalendarDate,
				$justinmimbs$date$Date$year(date),
				$justinmimbs$date$Date$month(date),
				1));
		var firstOfMonth = A3(
			$justinmimbs$date$Date$fromCalendarDate,
			$justinmimbs$date$Date$year(date),
			$justinmimbs$date$Date$month(date),
			1);
		var end = A2(
			$justinmimbs$date$Date$ceiling,
			weekdayAsInterval,
			A3($justinmimbs$date$Date$add, 1, 1, firstOfMonth));
		return {
			dJ: A4($justinmimbs$date$Date$range, 11, 1, start, end),
			dL: date
		};
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $author$project$PAM$UI$Basic$azureRadiance = A3($mdgriffith$elm_ui$Element$rgb255, 0, 126, 255);
var $author$project$PAM$UI$DatePicker$viewDayEl = F7(
	function (settings, picked, isOtherMonth, isToday, isStart, isEnd, d) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$author$project$PAM$UI$DatePicker$calendarDayWidth,
					$author$project$PAM$UI$DatePicker$calendarDayHeight,
					$mdgriffith$elm_ui$Element$Border$width(1),
					isToday(d) ? $mdgriffith$elm_ui$Element$Border$rounded(3) : (isStart(d) ? $mdgriffith$elm_ui$Element$Border$roundEach(
					{gv: 3, gw: 0, i2: 3, i3: 0}) : (isEnd(d) ? $mdgriffith$elm_ui$Element$Border$roundEach(
					{gv: 0, gw: 3, i2: 0, i3: 3}) : $mdgriffith$elm_ui$Element$Border$rounded(0))),
					$mdgriffith$elm_ui$Element$Border$width(
					isToday(d) ? 1 : 0),
					$mdgriffith$elm_ui$Element$Background$color(
					function () {
						var _v0 = _Utils_Tuple2(
							picked(d),
							isOtherMonth(d));
						if (_v0.a) {
							return A2(
								$author$project$PAM$UI$Basic$fadeColor,
								(isStart(d) || isEnd(d)) ? 0.5 : 0.3,
								$author$project$PAM$UI$Basic$azureRadiance);
						} else {
							if (_v0.b) {
								return A2($author$project$PAM$UI$Basic$darkenColor, 0.1, $author$project$PAM$UI$Basic$white);
							} else {
								return $author$project$PAM$UI$Basic$white;
							}
						}
					}())
				]),
			settings.lp ? A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray),
							$mdgriffith$elm_ui$Element$padding(1),
							isToday(d) ? $mdgriffith$elm_ui$Element$Font$bold : $mdgriffith$elm_ui$Element$Font$regular
						]),
					$mdgriffith$elm_ui$Element$text(
						$elm$core$String$fromInt(
							$justinmimbs$date$Date$day(d))))) : A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				{
					a8: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$centerY,
								isToday(d) ? $mdgriffith$elm_ui$Element$Font$bold : $mdgriffith$elm_ui$Element$Font$regular
							]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(d)))),
					b8: $elm$core$Maybe$Just(
						$author$project$PAM$UI$DatePicker$Pick(d))
				}));
	});
var $author$project$PAM$UI$DatePicker$calendarDisplay = F3(
	function (pickedDate, settings, model) {
		var focused = model.K;
		var today = model.ah;
		var picked = function (d) {
			_v5$4:
			while (true) {
				if (!pickedDate.$) {
					switch (pickedDate.a.$) {
						case 1:
							var day = pickedDate.a.a;
							return _Utils_eq(
								$justinmimbs$date$Date$toRataDie(d),
								$justinmimbs$date$Date$toRataDie(day));
						case 2:
							switch (pickedDate.a.a.$) {
								case 1:
									var start = pickedDate.a.a.a;
									return _Utils_cmp(
										$justinmimbs$date$Date$toRataDie(d),
										$justinmimbs$date$Date$toRataDie(start)) > -1;
								case 2:
									var end = pickedDate.a.a.a;
									return _Utils_cmp(
										$justinmimbs$date$Date$toRataDie(d),
										$justinmimbs$date$Date$toRataDie(end)) < 1;
								default:
									var _v6 = pickedDate.a.a;
									var start = _v6.a;
									var end = _v6.b;
									return (_Utils_cmp(
										$justinmimbs$date$Date$toRataDie(d),
										$justinmimbs$date$Date$toRataDie(start)) > -1) && (_Utils_cmp(
										$justinmimbs$date$Date$toRataDie(d),
										$justinmimbs$date$Date$toRataDie(end)) < 1);
							}
						default:
							break _v5$4;
					}
				} else {
					break _v5$4;
				}
			}
			return false;
		};
		var isToday = function (d) {
			return _Utils_eq(
				$justinmimbs$date$Date$toRataDie(d),
				$justinmimbs$date$Date$toRataDie(today));
		};
		var isStart = function (d) {
			_v3$3:
			while (true) {
				if (!pickedDate.$) {
					switch (pickedDate.a.$) {
						case 1:
							var day = pickedDate.a.a;
							return _Utils_eq(
								$justinmimbs$date$Date$toRataDie(d),
								$justinmimbs$date$Date$toRataDie(day));
						case 2:
							switch (pickedDate.a.a.$) {
								case 1:
									var start = pickedDate.a.a.a;
									return _Utils_eq(
										$justinmimbs$date$Date$toRataDie(d),
										$justinmimbs$date$Date$toRataDie(start));
								case 0:
									var _v4 = pickedDate.a.a;
									var start = _v4.a;
									var end = _v4.b;
									return _Utils_eq(
										$justinmimbs$date$Date$toRataDie(d),
										$justinmimbs$date$Date$toRataDie(start));
								default:
									break _v3$3;
							}
						default:
							break _v3$3;
					}
				} else {
					break _v3$3;
				}
			}
			return false;
		};
		var isEnd = function (d) {
			_v1$3:
			while (true) {
				if (!pickedDate.$) {
					switch (pickedDate.a.$) {
						case 1:
							var day = pickedDate.a.a;
							return _Utils_eq(
								$justinmimbs$date$Date$toRataDie(d),
								$justinmimbs$date$Date$toRataDie(day));
						case 2:
							switch (pickedDate.a.a.$) {
								case 2:
									var end = pickedDate.a.a.a;
									return _Utils_eq(
										$justinmimbs$date$Date$toRataDie(d),
										$justinmimbs$date$Date$toRataDie(end));
								case 0:
									var _v2 = pickedDate.a.a;
									var start = _v2.a;
									var end = _v2.b;
									return _Utils_eq(
										$justinmimbs$date$Date$toRataDie(d),
										$justinmimbs$date$Date$toRataDie(end));
								default:
									break _v1$3;
							}
						default:
							break _v1$3;
					}
				} else {
					break _v1$3;
				}
			}
			return false;
		};
		var currentDate = A5($author$project$PAM$UI$DatePicker$getPriorityDay, focused, pickedDate, model.m0, today, settings.m1);
		var isOtherMonth = function (d) {
			return !_Utils_eq(
				$justinmimbs$date$Date$month(currentDate),
				$justinmimbs$date$Date$month(d));
		};
		var _v0 = A2($author$project$PAM$UI$DatePicker$prepareDates, currentDate, settings.cL);
		var currentMonth = _v0.dL;
		var currentDates = _v0.dJ;
		var weekList = A2(
			$elm$core$List$map,
			function (rowDays) {
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$spaceEvenly
						]),
					A2(
						$elm$core$List$map,
						A6($author$project$PAM$UI$DatePicker$viewDayEl, settings, picked, isOtherMonth, isToday, isStart, isEnd),
						rowDays));
			},
			$author$project$PAM$UI$DatePicker$groupDates(currentDates));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					_Utils_update(
						$author$project$PAM$UI$Basic$borders,
						{cz: 1, c3: 1})),
					$mdgriffith$elm_ui$Element$Border$color(
					A2($author$project$PAM$UI$Basic$darkenColor, 0.5, $author$project$PAM$UI$Basic$white))
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white)),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							_Utils_update(
								$author$project$PAM$UI$Basic$borders,
								{cz: 1})),
							$mdgriffith$elm_ui$Element$Border$color(
							A2($author$project$PAM$UI$Basic$darkenColor, 0.3, $author$project$PAM$UI$Basic$white)),
							$mdgriffith$elm_ui$Element$spaceEvenly
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$author$project$PAM$UI$DatePicker$calendarDayWidth, $author$project$PAM$UI$DatePicker$calendarDayHeight]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
								$mdgriffith$elm_ui$Element$text('S'))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$author$project$PAM$UI$DatePicker$calendarDayWidth, $author$project$PAM$UI$DatePicker$calendarDayHeight]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
								$mdgriffith$elm_ui$Element$text('M'))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$author$project$PAM$UI$DatePicker$calendarDayWidth, $author$project$PAM$UI$DatePicker$calendarDayHeight]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
								$mdgriffith$elm_ui$Element$text('T'))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$author$project$PAM$UI$DatePicker$calendarDayWidth, $author$project$PAM$UI$DatePicker$calendarDayHeight]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
								$mdgriffith$elm_ui$Element$text('W'))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$author$project$PAM$UI$DatePicker$calendarDayWidth, $author$project$PAM$UI$DatePicker$calendarDayHeight]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
								$mdgriffith$elm_ui$Element$text('T'))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$author$project$PAM$UI$DatePicker$calendarDayWidth, $author$project$PAM$UI$DatePicker$calendarDayHeight]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
								$mdgriffith$elm_ui$Element$text('F'))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$author$project$PAM$UI$DatePicker$calendarDayWidth, $author$project$PAM$UI$DatePicker$calendarDayHeight]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
								$mdgriffith$elm_ui$Element$text('S')))
						])),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
						]),
					weekList)
				]));
	});
var $author$project$PAM$UI$DatePicker$GoToToday = {$: 12};
var $author$project$PAM$UI$DatePicker$closeTodayControls = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$author$project$PAM$UI$Basic$title('Close'),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					_Utils_update(
						$author$project$PAM$UI$Basic$edges,
						{cX: 1})),
					$mdgriffith$elm_ui$Element$Background$color(
					A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white))
				]),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$padding(2)
						]),
					$mdgriffith$elm_ui$Element$text('Close')),
				b8: $elm$core$Maybe$Just($author$project$PAM$UI$DatePicker$ClosePicker)
			}),
			A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$author$project$PAM$UI$Basic$title('Go to today'),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Background$color(
					A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white))
				]),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$padding(2)
						]),
					$mdgriffith$elm_ui$Element$text('Today')),
				b8: $elm$core$Maybe$Just($author$project$PAM$UI$DatePicker$GoToToday)
			})
		]));
var $author$project$PAM$UI$Basic$modalShadow = $elm$core$Basics$append(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Border$shadow(
			{
				as: 10,
				G: $author$project$PAM$UI$Basic$black,
				lY: _Utils_Tuple2(5, 5),
				aD: 2
			})
		]));
var $author$project$PAM$UI$Basic$style = F2(
	function (name, value) {
		return $mdgriffith$elm_ui$Element$htmlAttribute(
			A2($elm$html$Html$Attributes$style, name, value));
	});
var $mdgriffith$elm_ui$Internal$Model$Padding = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Model$Spaced = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, _v0) {
				var pad = _v0.a;
				var spacing = _v0.b;
				return _Utils_Tuple2(
					function () {
						if (!pad.$) {
							var x = pad.a;
							return pad;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 7)) {
								var _v3 = attr.b;
								var name = _v3.a;
								var t = _v3.b;
								var r = _v3.c;
								var b = _v3.d;
								var l = _v3.e;
								return $elm$core$Maybe$Just(
									A5($mdgriffith$elm_ui$Internal$Model$Padding, name, t, r, b, l));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}(),
					function () {
						if (!spacing.$) {
							var x = spacing.a;
							return spacing;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v6 = attr.b;
								var name = _v6.a;
								var x = _v6.b;
								var y = _v6.c;
								return $elm$core$Maybe$Just(
									A3($mdgriffith$elm_ui$Internal$Model$Spaced, name, x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}());
			}),
		_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
		attrs);
};
var $mdgriffith$elm_ui$Element$wrappedRow = F2(
	function (attrs, children) {
		var _v0 = $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding(attrs);
		var padded = _v0.a;
		var spaced = _v0.b;
		if (spaced.$ === 1) {
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asRow,
				$mdgriffith$elm_ui$Internal$Model$div,
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cC + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a2 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f6)))),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							attrs))),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
		} else {
			var _v2 = spaced.a;
			var spaceName = _v2.a;
			var x = _v2.b;
			var y = _v2.c;
			var newPadding = function () {
				if (!padded.$) {
					var _v5 = padded.a;
					var name = _v5.a;
					var t = _v5.b;
					var r = _v5.c;
					var b = _v5.d;
					var l = _v5.e;
					return ((_Utils_cmp(r, (x / 2) | 0) > -1) && (_Utils_cmp(b, (y / 2) | 0) > -1)) ? $elm$core$Maybe$Just(
						$mdgriffith$elm_ui$Element$paddingEach(
							{cz: b - ((y / 2) | 0), cR: l - ((x / 2) | 0), cX: r - ((x / 2) | 0), c3: t - ((y / 2) | 0)})) : $elm$core$Maybe$Nothing;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			if (!newPadding.$) {
				var pad = newPadding.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asRow,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cC + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a2 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f6)))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								_Utils_ap(
									attrs,
									_List_fromArray(
										[pad]))))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
			} else {
				var halfY = -(y / 2);
				var halfX = -(x / 2);
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					attrs,
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asRow,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cC + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a2 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f6)))),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											A2(
												$elm$html$Html$Attributes$style,
												'margin',
												$elm$core$String$fromFloat(halfY) + ('px' + (' ' + ($elm$core$String$fromFloat(halfX) + 'px'))))),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Internal$Model$Attr(
												A2(
													$elm$html$Html$Attributes$style,
													'width',
													'calc(100% + ' + ($elm$core$String$fromInt(x) + 'px)'))),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$Attr(
													A2(
														$elm$html$Html$Attributes$style,
														'height',
														'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))),
												A2(
													$elm$core$List$cons,
													A2(
														$mdgriffith$elm_ui$Internal$Model$StyleClass,
														$mdgriffith$elm_ui$Internal$Flag$spacing,
														A3($mdgriffith$elm_ui$Internal$Model$SpacingStyle, spaceName, x, y)),
													_List_Nil))))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(children))
							])));
			}
		}
	});
var $author$project$PAM$UI$DatePicker$ChangeFocus = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$DatePicker$monthDisplay = F3(
	function (pickedDate, settings, model) {
		var focused = model.K;
		var today = model.ah;
		var currentDate = A5($author$project$PAM$UI$DatePicker$getPriorityDay, focused, pickedDate, model.m0, today, settings.m1);
		var _v0 = A2($author$project$PAM$UI$DatePicker$prepareDates, currentDate, settings.cL);
		var currentMonth = _v0.dL;
		var currentDates = _v0.dJ;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$spaceEvenly,
					$mdgriffith$elm_ui$Element$centerX,
					A2($mdgriffith$elm_ui$Element$paddingXY, 3, 0)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('Previous Month'),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(20))
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$alignLeft
								]),
							$author$project$PAM$UI$Basic$icon('fa fa-chevron-left')),
						b8: $elm$core$Maybe$Just(
							$author$project$PAM$UI$DatePicker$ChangeFocus(
								A3($justinmimbs$date$Date$add, 1, -1, currentDate)))
					}),
					$mdgriffith$elm_ui$Element$text(
					settings.fs(
						$justinmimbs$date$Date$month(currentMonth))),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('Next Month'),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(20))
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$alignRight
								]),
							$author$project$PAM$UI$Basic$icon('fa fa-chevron-right')),
						b8: $elm$core$Maybe$Just(
							$author$project$PAM$UI$DatePicker$ChangeFocus(
								A3($justinmimbs$date$Date$add, 1, 1, currentDate)))
					})
				]));
	});
var $author$project$PAM$UI$DatePicker$yearDisplay = F3(
	function (pickedDate, settings, model) {
		var focused = model.K;
		var today = model.ah;
		var currentDate = A5($author$project$PAM$UI$DatePicker$getPriorityDay, focused, pickedDate, model.m0, today, settings.m1);
		var _v0 = A2($author$project$PAM$UI$DatePicker$prepareDates, currentDate, settings.cL);
		var currentMonth = _v0.dL;
		var currentDates = _v0.dJ;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$Border$color(
					A2($author$project$PAM$UI$Basic$darkenColor, 0.25, $author$project$PAM$UI$Basic$white)),
					$mdgriffith$elm_ui$Element$spacing(15),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$centerY
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('Previous Year'),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
						]),
					{
						a8: $author$project$PAM$UI$Basic$icon('fa fa-chevron-left'),
						b8: $elm$core$Maybe$Just(
							$author$project$PAM$UI$DatePicker$ChangeFocus(
								A3($justinmimbs$date$Date$add, 0, -1, currentDate)))
					}),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
						]),
					$mdgriffith$elm_ui$Element$text(
						settings.f8(
							$justinmimbs$date$Date$year(currentMonth)))),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('Next Year'),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
						]),
					{
						a8: $author$project$PAM$UI$Basic$icon('fa fa-chevron-right'),
						b8: $elm$core$Maybe$Just(
							$author$project$PAM$UI$DatePicker$ChangeFocus(
								A3($justinmimbs$date$Date$add, 0, 1, currentDate)))
					})
				]));
	});
var $author$project$PAM$UI$DatePicker$yearMonthDisplay = F3(
	function (pickedDate, settings, model) {
		var focused = model.K;
		var today = model.ah;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$padding(3),
					$mdgriffith$elm_ui$Element$spacing(1)
				]),
			_List_fromArray(
				[
					A3($author$project$PAM$UI$DatePicker$monthDisplay, pickedDate, settings, model),
					A3($author$project$PAM$UI$DatePicker$yearDisplay, pickedDate, settings, model)
				]));
	});
var $author$project$PAM$UI$DatePicker$view = F3(
	function (env, settings, _v0) {
		var datepicker = _v0;
		var model = datepicker;
		var pickedDate = function () {
			var _v18 = model.m0;
			if (!_v18.$) {
				return $elm$core$Maybe$Nothing;
			} else {
				return $elm$core$Maybe$Just(model.m0);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
					$mdgriffith$elm_ui$Element$spaceEvenly,
					$author$project$PAM$UI$Basic$id(settings.hu),
					$mdgriffith$elm_ui$Element$below(
					model.em ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(3),
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
								$mdgriffith$elm_ui$Element$alignRight,
								$author$project$PAM$UI$Basic$id(settings.hu + '-picker')
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							$author$project$PAM$UI$Basic$modalShadow(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(175)),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
										$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$white),
										A2($author$project$PAM$UI$Basic$style, 'z-index', '100'),
										$mdgriffith$elm_ui$Element$Border$rounded(3),
										$mdgriffith$elm_ui$Element$Border$color(
										A2($author$project$PAM$UI$Basic$darkenColor, 0.9, $author$project$PAM$UI$Basic$white)),
										$mdgriffith$elm_ui$Element$Border$width(1),
										$mdgriffith$elm_ui$Element$Border$solid,
										$mdgriffith$elm_ui$Element$clip
									])),
							_List_fromArray(
								[
									A3($author$project$PAM$UI$DatePicker$yearMonthDisplay, pickedDate, settings, model),
									A3($author$project$PAM$UI$DatePicker$calendarDisplay, pickedDate, settings, model),
									$author$project$PAM$UI$DatePicker$closeTodayControls
								]))) : A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$author$project$PAM$UI$Basic$id(settings.hu + '-picker')
							]),
						$mdgriffith$elm_ui$Element$none))
				]),
			_Utils_ap(
				_List_fromArray(
					[
						settings.lp ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$height(
								$author$project$PAM$UI$Basic$inputHeight(env)),
								$mdgriffith$elm_ui$Element$Border$rounded(3),
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray),
								$mdgriffith$elm_ui$Element$Background$color(
								A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white)),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$centerY,
									$author$project$PAM$UI$Basic$id(settings.hu + '-input')
								]),
							$mdgriffith$elm_ui$Element$text(
								A2(
									$elm$core$Maybe$withDefault,
									settings.mv,
									A2(
										$elm$core$Maybe$map,
										function (val) {
											_v1$3:
											while (true) {
												switch (val.$) {
													case 1:
														var date = val.a;
														return settings.kv(date);
													case 2:
														switch (val.a.$) {
															case 1:
																var start = val.a.a;
																return settings.kv(start);
															case 0:
																var _v2 = val.a;
																var start = _v2.a;
																return settings.kv(start);
															default:
																break _v1$3;
														}
													default:
														break _v1$3;
												}
											}
											return '';
										},
										pickedDate))))) : (settings.dO ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$height(
								$author$project$PAM$UI$Basic$inputHeight(env)),
								$mdgriffith$elm_ui$Element$Border$rounded(3),
								$mdgriffith$elm_ui$Element$Background$color(
								A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white)),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$centerY,
									$author$project$PAM$UI$Basic$id(settings.hu + '-input')
								]),
							$mdgriffith$elm_ui$Element$text(
								A2(
									$elm$core$Maybe$withDefault,
									'mm/dd/yyyy',
									A2(
										$elm$core$Maybe$map,
										function (val) {
											_v3$3:
											while (true) {
												switch (val.$) {
													case 1:
														var date = val.a;
														return settings.kv(date);
													case 2:
														switch (val.a.$) {
															case 1:
																var start = val.a.a;
																return settings.kv(start);
															case 0:
																var _v4 = val.a;
																var start = _v4.a;
																return settings.kv(start);
															default:
																break _v3$3;
														}
													default:
														break _v3$3;
												}
											}
											return 'mm/dd/yyyy';
										},
										pickedDate))))) : A2(
						$mdgriffith$elm_ui$Element$el,
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$padding(2)
								]),
							function () {
								var _v5 = model.ab;
								if (_v5.$ === 1) {
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Border$color(
											A3($mdgriffith$elm_ui$Element$rgb255, 255, 0, 0)),
											$mdgriffith$elm_ui$Element$Border$width(1),
											$mdgriffith$elm_ui$Element$Border$rounded(3)
										]);
								} else {
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Border$color($author$project$PAM$UI$Basic$transparent0),
											$mdgriffith$elm_ui$Element$Border$width(1),
											$mdgriffith$elm_ui$Element$Border$rounded(3)
										]);
								}
							}()),
						A2(
							$mdgriffith$elm_ui$Element$Input$text,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									$author$project$PAM$UI$Basic$inputHeight(env)),
									$mdgriffith$elm_ui$Element$Border$rounded(3),
									A2($mdgriffith$elm_ui$Element$paddingXY, 5, 12),
									$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
								]),
							{
								a8: $mdgriffith$elm_ui$Element$Input$labelHidden('Start Date'),
								h1: $author$project$PAM$UI$DatePicker$Text,
								mv: $elm$core$Maybe$Just(
									A2(
										$mdgriffith$elm_ui$Element$Input$placeholder,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 3, 0),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
												$mdgriffith$elm_ui$Element$centerY
											]),
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_Nil,
											$mdgriffith$elm_ui$Element$text('mm/dd/yyyy')))),
								eE: function () {
									var _v6 = model.ab;
									switch (_v6.$) {
										case 2:
											var text = _v6.a;
											return text;
										case 1:
											var text = _v6.a;
											return text;
										default:
											return A2(
												$elm$core$Maybe$withDefault,
												'',
												A2(
													$elm$core$Maybe$map,
													function (val) {
														_v7$3:
														while (true) {
															switch (val.$) {
																case 1:
																	var date = val.a;
																	return settings.kv(date);
																case 2:
																	switch (val.a.$) {
																		case 1:
																			var start = val.a.a;
																			return settings.kv(start);
																		case 0:
																			var _v8 = val.a;
																			var start = _v8.a;
																			return settings.kv(start);
																		default:
																			break _v7$3;
																	}
																default:
																	break _v7$3;
															}
														}
														return '';
													},
													pickedDate));
									}
								}()
							})))
					]),
				_Utils_ap(
					function () {
						var _v9 = settings.m1;
						if (_v9 === 1) {
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$centerY,
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
											A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0)
										]),
									$mdgriffith$elm_ui$Element$text('to')),
									settings.lp ? A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
											$mdgriffith$elm_ui$Element$height(
											$author$project$PAM$UI$Basic$inputHeight(env)),
											$mdgriffith$elm_ui$Element$Border$rounded(3),
											$mdgriffith$elm_ui$Element$Background$color(
											A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white)),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$padding(5),
											$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
												$mdgriffith$elm_ui$Element$centerY,
												$author$project$PAM$UI$Basic$id(settings.hu + '-toInput')
											]),
										$mdgriffith$elm_ui$Element$text(
											A2(
												$elm$core$Maybe$withDefault,
												'mm/dd/yyyy',
												A2(
													$elm$core$Maybe$map,
													function (val) {
														_v10$3:
														while (true) {
															switch (val.$) {
																case 1:
																	var date = val.a;
																	return 'mm/dd/yyyy';
																case 2:
																	switch (val.a.$) {
																		case 2:
																			var end = val.a.a;
																			return settings.kv(end);
																		case 0:
																			var _v11 = val.a;
																			var end = _v11.b;
																			return settings.kv(end);
																		default:
																			break _v10$3;
																	}
																default:
																	break _v10$3;
															}
														}
														return 'mm/dd/yyyy';
													},
													pickedDate))))) : (settings.dO ? A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
											$mdgriffith$elm_ui$Element$height(
											$author$project$PAM$UI$Basic$inputHeight(env)),
											$mdgriffith$elm_ui$Element$Border$rounded(3),
											$mdgriffith$elm_ui$Element$Background$color(
											A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white)),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$padding(5),
											$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
												$mdgriffith$elm_ui$Element$centerY,
												$author$project$PAM$UI$Basic$id(settings.hu + '-toInput')
											]),
										$mdgriffith$elm_ui$Element$text(
											A2(
												$elm$core$Maybe$withDefault,
												'mm/dd/yyyy',
												A2(
													$elm$core$Maybe$map,
													function (val) {
														_v12$3:
														while (true) {
															switch (val.$) {
																case 1:
																	var date = val.a;
																	return settings.kv(date);
																case 2:
																	switch (val.a.$) {
																		case 2:
																			var end = val.a.a;
																			return settings.kv(end);
																		case 0:
																			var _v13 = val.a;
																			var end = _v13.b;
																			return settings.kv(end);
																		default:
																			break _v12$3;
																	}
																default:
																	break _v12$3;
															}
														}
														return 'mm/dd/yyyy';
													},
													pickedDate))))) : A2(
									$mdgriffith$elm_ui$Element$el,
									_Utils_ap(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$padding(2)
											]),
										function () {
											var _v14 = model.af;
											if (_v14.$ === 1) {
												return _List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Border$color(
														A3($mdgriffith$elm_ui$Element$rgb255, 255, 0, 0)),
														$mdgriffith$elm_ui$Element$Border$width(1),
														$mdgriffith$elm_ui$Element$Border$rounded(3)
													]);
											} else {
												return _List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Border$color($author$project$PAM$UI$Basic$transparent0),
														$mdgriffith$elm_ui$Element$Border$width(1),
														$mdgriffith$elm_ui$Element$Border$rounded(3)
													]);
											}
										}()),
									A2(
										$mdgriffith$elm_ui$Element$Input$text,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height(
												$author$project$PAM$UI$Basic$inputHeight(env)),
												$mdgriffith$elm_ui$Element$Border$rounded(3),
												A2($mdgriffith$elm_ui$Element$paddingXY, 5, 12),
												$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
											]),
										{
											a8: $mdgriffith$elm_ui$Element$Input$labelHidden('End Date'),
											h1: $author$project$PAM$UI$DatePicker$SecondText,
											mv: $elm$core$Maybe$Just(
												A2(
													$mdgriffith$elm_ui$Element$Input$placeholder,
													_List_fromArray(
														[
															A2($mdgriffith$elm_ui$Element$paddingXY, 3, 0),
															$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
															$mdgriffith$elm_ui$Element$centerY
														]),
													A2(
														$mdgriffith$elm_ui$Element$el,
														_List_Nil,
														$mdgriffith$elm_ui$Element$text('mm/dd/yyyy')))),
											eE: function () {
												var _v15 = model.af;
												switch (_v15.$) {
													case 2:
														var text = _v15.a;
														return text;
													case 1:
														var text = _v15.a;
														return text;
													default:
														return A2(
															$elm$core$Maybe$withDefault,
															'',
															A2(
																$elm$core$Maybe$map,
																function (val) {
																	_v16$2:
																	while (true) {
																		if (val.$ === 2) {
																			switch (val.a.$) {
																				case 2:
																					var end = val.a.a;
																					return settings.kv(end);
																				case 0:
																					var _v17 = val.a;
																					var end = _v17.b;
																					return settings.kv(end);
																				default:
																					break _v16$2;
																			}
																		} else {
																			break _v16$2;
																		}
																	}
																	return '';
																},
																pickedDate));
												}
											}()
										})))
								]);
						} else {
							return _List_Nil;
						}
					}(),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_fromArray(
								[
									$author$project$PAM$UI$Basic$title('Pick Date'),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
									A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0)
								]),
							{
								a8: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									$author$project$PAM$UI$Basic$centeredIcon('fa fa-calendar')),
								b8: $elm$core$Maybe$Just(
									(!model.em) ? $author$project$PAM$UI$DatePicker$OpenPicker : $author$project$PAM$UI$DatePicker$ClosePicker)
							})
						]))));
	});
var $author$project$Fields$DateField$view = F4(
	function (env, config, model, field) {
		var fieldData = config.kS;
		var isReadOnly = config.lq;
		var l = 1;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly,
					$author$project$PAM$UI$Basic$id('date-field-todo')
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
						]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						A2($elm$core$Basics$composeR, $author$project$Fields$DateField$PickerMsg, $author$project$Fields$DateField$Internal),
						A3(
							$author$project$PAM$UI$DatePicker$view,
							env,
							$author$project$Fields$DateField$pickerSettings(isReadOnly),
							model.ao)))
				]));
	});
var $author$project$Fields$DateRangeField$view = F4(
	function (env, config, model, field) {
		var fieldData = config.kS;
		var isReadOnly = config.lq;
		var l = 1;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
						]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						A2($elm$core$Basics$composeR, $author$project$Fields$DateRangeField$PickerMsg, $author$project$Fields$DateRangeField$Internal),
						A3(
							$author$project$PAM$UI$DatePicker$view,
							env,
							$author$project$Fields$DateRangeField$pickerSettings(isReadOnly),
							model.ao)))
				]));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Fields$FlagWithCommentField$view = F2(
	function (config, field) {
		var info = field.hj(config.kS);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('input wrap')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('centerVertical row noShrink pad-0-5em pad-left-0 radio')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('radio'),
											$elm$html$Html$Attributes$checked(
											_Utils_eq(
												info.aG,
												$elm$core$Maybe$Just(true))),
											$elm$html$Html$Events$onCheck(
											function (x) {
												return A3(
													field.m3,
													config.kS,
													(!config.lq) ? $elm$core$Maybe$Just(true) : info.aG,
													$elm$core$Maybe$Nothing);
											}),
											$elm$html$Html$Attributes$name(info.fu),
											$elm$html$Html$Attributes$disabled(
											config.lq && _Utils_eq(
												info.aG,
												$elm$core$Maybe$Just(false))),
											$elm$html$Html$Attributes$class('margin-0')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('pad-0-25em')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(info.jt)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('radio'),
											$elm$html$Html$Events$onCheck(
											function (x) {
												return A3(
													field.m3,
													config.kS,
													(!config.lq) ? $elm$core$Maybe$Just(false) : info.aG,
													$elm$core$Maybe$Nothing);
											}),
											$elm$html$Html$Attributes$name(info.fu),
											$elm$html$Html$Attributes$checked(
											_Utils_eq(
												info.aG,
												$elm$core$Maybe$Just(false))),
											$elm$html$Html$Attributes$disabled(
											config.lq && _Utils_eq(
												info.aG,
												$elm$core$Maybe$Just(true))),
											$elm$html$Html$Attributes$class('margin-0')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('pad-0-25em')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(info.hX)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fullWidth')
						]),
					_List_fromArray(
						[
							A2(
							function () {
								var _v0 = info.aG;
								if ((!_v0.$) && _v0.a) {
									return $elm$html$Html$textarea;
								} else {
									return $elm$html$Html$div;
								}
							}(),
							_List_fromArray(
								[
									$elm$html$Html$Attributes$disabled(
									config.lq || _Utils_eq(
										info.aG,
										$elm$core$Maybe$Just(false))),
									$elm$html$Html$Attributes$value(
									function () {
										var _v1 = info.gN;
										if (!_v1.$) {
											var val = _v1.a;
											return val;
										} else {
											return '';
										}
									}()),
									$elm$html$Html$Events$onInput(
									function (x) {
										return A3(
											field.m3,
											config.kS,
											info.aG,
											$author$project$Fields$Shared$toMaybeString(x));
									}),
									$elm$html$Html$Attributes$class('fullWidth margin-auto'),
									$elm$html$Html$Attributes$maxlength(
									A2($elm$core$Maybe$withDefault, -1, field.hJ))
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Fields$GeometryField$flappyFloat = function (im) {
	return $elm$core$Basics$round(im * 100) / 100;
};
var $author$project$Fields$GeometryField$areaViewEl = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text(
			'Total Acres: ' + A2(
				$elm$core$Maybe$withDefault,
				'-',
				A2(
					$elm$core$Maybe$map,
					$cuducos$elm_format_number$FormatNumber$format(
						_Utils_update(
							$cuducos$elm_format_number$FormatNumber$Locales$usLocale,
							{gW: model.bn.cG})),
					A2(
						$elm$core$Maybe$map,
						$author$project$Fields$GeometryField$flappyFloat,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.U;
							},
							model.av))))));
};
var $author$project$Fields$GeometryField$maybeAreaView = F3(
	function (palette, model, isReadOnly) {
		return (!_Utils_eq(model.av, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
			$author$project$Fields$GeometryField$areaViewEl(model)) : $elm$core$Maybe$Nothing;
	});
var $author$project$Fields$GeometryField$maybeEditNotice = F3(
	function (palette, model, isReadOnly) {
		return (!isReadOnly) ? $elm$core$Maybe$Just(
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(11),
						$mdgriffith$elm_ui$Element$spacing(4),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$mdgriffith$elm_ui$Element$textColumn,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$center]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Drag a vertex to move it. Hold Alt and click a vertex to delete it. Click and drag from a line segment to add a new vertex. Click Select in the table above to edit a specific part.')
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$center]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Each part is limited to 5,000 vertices. Parts over 5,000 vertices will be simplified. Flattened geometry is also limited to 5,000 vertices.')
								]))
						])))) : $elm$core$Maybe$Nothing;
	});
var $author$project$Fields$GeometryField$maybeGuide = F3(
	function (palette, model, isReadOnly) {
		return false ? $elm$core$Maybe$Just(
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(12),
						$mdgriffith$elm_ui$Element$Font$center
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Draw on the map, upload a shapefile, or selecting an existing feature from a reference layer to use as the geometry.')
					]))) : $elm$core$Maybe$Nothing;
	});
var $author$project$Fields$GeometryField$maybeSaveNotice = F3(
	function (palette, model, isReadOnly) {
		var _v0 = model.B;
		if (_v0.$ === 1) {
			return $elm$core$Maybe$Just(
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(11),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$center]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Use one of the tools below to draw. Multiple parts and overlapping areas will be merged into a single multi-part shape once you click the Save button.')
								]))
						])));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Fields$GeometryField$noShapeNotice = A2(
	$mdgriffith$elm_ui$Element$paragraph,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$size(12),
			$mdgriffith$elm_ui$Element$Font$center
		]),
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$text('No geometry has been created for this item.')
		]));
var $author$project$Fields$GeometryField$Delete = function (a) {
	return {$: 16, a: a};
};
var $author$project$Fields$GeometryField$SelectItem = function (a) {
	return {$: 15, a: a};
};
var $author$project$Fields$GeometryField$ZoomTo = F3(
	function (a, b, c) {
		return {$: 14, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Element$InternalIndexedColumn = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridPosition = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridTemplateStyle = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$asGrid = 3;
var $mdgriffith$elm_ui$Internal$Flag$gridPosition = $mdgriffith$elm_ui$Internal$Flag$flag(35);
var $mdgriffith$elm_ui$Internal$Flag$gridTemplate = $mdgriffith$elm_ui$Internal$Flag$flag(34);
var $mdgriffith$elm_ui$Element$tableHelper = F2(
	function (attrs, config) {
		var onGrid = F3(
			function (rowLevel, columnLevel, elem) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$StyleClass,
							$mdgriffith$elm_ui$Internal$Flag$gridPosition,
							$mdgriffith$elm_ui$Internal$Model$GridPosition(
								{gK: columnLevel, fb: 1, iC: rowLevel, eJ: 1}))
						]),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[elem])));
			});
		var columnWidth = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.eJ;
			} else {
				var colConfig = col.a;
				return colConfig.eJ;
			}
		};
		var columnHeader = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.fa;
			} else {
				var colConfig = col.a;
				return colConfig.fa;
			}
		};
		var maybeHeaders = function (headers) {
			return A2(
				$elm$core$List$all,
				$elm$core$Basics$eq($mdgriffith$elm_ui$Internal$Model$Empty),
				headers) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (col, header) {
							return A3(onGrid, 1, col + 1, header);
						}),
					headers));
		}(
			A2($elm$core$List$map, columnHeader, config.kn));
		var add = F3(
			function (cell, columnConfig, cursor) {
				if (!columnConfig.$) {
					var col = columnConfig.a;
					return _Utils_update(
						cursor,
						{
							a1: cursor.a1 + 1,
							aK: A2(
								$elm$core$List$cons,
								A3(
									onGrid,
									cursor.iC,
									cursor.a1,
									A2(
										col.f0,
										_Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? (cursor.iC - 1) : (cursor.iC - 2),
										cell)),
								cursor.aK)
						});
				} else {
					var col = columnConfig.a;
					return {
						a1: cursor.a1 + 1,
						aK: A2(
							$elm$core$List$cons,
							A3(
								onGrid,
								cursor.iC,
								cursor.a1,
								col.f0(cell)),
							cursor.aK),
						iC: cursor.iC
					};
				}
			});
		var build = F3(
			function (columns, rowData, cursor) {
				var newCursor = A3(
					$elm$core$List$foldl,
					add(rowData),
					cursor,
					columns);
				return {a1: 1, aK: newCursor.aK, iC: cursor.iC + 1};
			});
		var children = A3(
			$elm$core$List$foldl,
			build(config.kn),
			{
				a1: 1,
				aK: _List_Nil,
				iC: _Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? 1 : 2
			},
			config.ku);
		var _v0 = A2(
			$mdgriffith$elm_ui$Internal$Model$getSpacing,
			attrs,
			_Utils_Tuple2(0, 0));
		var sX = _v0.a;
		var sY = _v0.b;
		var template = A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$gridTemplate,
			$mdgriffith$elm_ui$Internal$Model$GridTemplateStyle(
				{
					kn: A2($elm$core$List$map, columnWidth, config.kn),
					mT: A2(
						$elm$core$List$repeat,
						$elm$core$List$length(config.ku),
						$mdgriffith$elm_ui$Internal$Model$Content),
					nc: _Utils_Tuple2(
						$mdgriffith$elm_ui$Element$px(sX),
						$mdgriffith$elm_ui$Element$px(sY))
				}));
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asGrid,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($elm$core$List$cons, template, attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				function () {
					if (maybeHeaders.$ === 1) {
						return children.aK;
					} else {
						var renderedHeaders = maybeHeaders.a;
						return _Utils_ap(
							renderedHeaders,
							$elm$core$List$reverse(children.aK));
					}
				}()));
	});
var $mdgriffith$elm_ui$Element$indexedTable = F2(
	function (attrs, config) {
		return A2(
			$mdgriffith$elm_ui$Element$tableHelper,
			attrs,
			{
				kn: A2($elm$core$List$map, $mdgriffith$elm_ui$Element$InternalIndexedColumn, config.kn),
				ku: config.ku
			});
	});
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Fields$GeometryField$shapeTableEl = F4(
	function (palette, windowConfig, model, isReadOnly) {
		return A2(
			$mdgriffith$elm_ui$Element$indexedTable,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					A2($author$project$PAM$UI$Basic$fadeColor, 0.32, palette.S.fK.G)),
					$mdgriffith$elm_ui$Element$Font$color(
					A4($mdgriffith$elm_ui$Element$rgba255, 255, 255, 255, 1)),
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$Border$rounded(3)
				]),
			{
				kn: _List_fromArray(
					[
						{
						fa: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$widthEach(
									{cz: 1, cR: 0, cX: 0, c3: 0}),
									$mdgriffith$elm_ui$Element$padding(4),
									$mdgriffith$elm_ui$Element$alignLeft
								]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignLeft]),
								$mdgriffith$elm_ui$Element$text('Parts'))),
						f0: function (index) {
							return function (spatial) {
								return A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$padding(4)
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignLeft]),
										$mdgriffith$elm_ui$Element$text(
											'Part ' + A2(
												$elm$core$Maybe$withDefault,
												'Unknown',
												A2($elm$core$Maybe$map, $elm$core$String$fromInt, spatial.r)))));
							};
						},
						eJ: $mdgriffith$elm_ui$Element$fill
					},
						{
						fa: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$widthEach(
									{cz: 1, cR: 0, cX: 0, c3: 0}),
									$mdgriffith$elm_ui$Element$padding(4)
								]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignLeft]),
								$mdgriffith$elm_ui$Element$text('Acres'))),
						f0: function (index) {
							return function (spatial) {
								return A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$padding(4)
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignLeft]),
										$mdgriffith$elm_ui$Element$text(
											A2(
												$cuducos$elm_format_number$FormatNumber$format,
												_Utils_update(
													$cuducos$elm_format_number$FormatNumber$Locales$usLocale,
													{gW: model.bn.cG}),
												$author$project$Fields$GeometryField$flappyFloat(spatial.U)))));
							};
						},
						eJ: $mdgriffith$elm_ui$Element$fill
					},
						{
						fa: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$widthEach(
									{cz: 1, cR: 0, cX: 0, c3: 0}),
									$mdgriffith$elm_ui$Element$padding(4),
									$mdgriffith$elm_ui$Element$alignLeft,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignLeft]),
								$mdgriffith$elm_ui$Element$text(' '))),
						f0: function (index) {
							return function (spatial) {
								var titledBtn = F2(
									function (txt, msg) {
										return A2(
											$mdgriffith$elm_ui$Element$Input$button,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$htmlAttribute(
													$elm$html$Html$Attributes$title(txt))
												]),
											{
												a8: $mdgriffith$elm_ui$Element$text(txt),
												b8: $elm$core$Maybe$Just(
													$author$project$Fields$GeometryField$Internal(msg))
											});
									});
								var spatialId = A2($elm$core$Maybe$withDefault, 1, spatial.r);
								var zoomBtn = A2(
									titledBtn,
									'Zoom',
									A3(
										$author$project$Fields$GeometryField$ZoomTo,
										spatialId,
										(!windowConfig.m8) ? windowConfig.fD.lG : 0,
										(!windowConfig.j8) ? (windowConfig.fD.j6 + windowConfig.fD.j7) : 0));
								var geomBtns = isReadOnly ? _List_fromArray(
									[zoomBtn]) : _List_fromArray(
									[
										A2(
										titledBtn,
										'Delete',
										$author$project$Fields$GeometryField$Delete(spatialId)),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_Nil,
										$mdgriffith$elm_ui$Element$text('|')),
										_Utils_eq(
										model.I,
										$elm$core$Maybe$Just(spatialId)) ? A2(
										titledBtn,
										'Deselect',
										$author$project$Fields$GeometryField$SelectItem(spatialId)) : A2(
										titledBtn,
										'Select',
										$author$project$Fields$GeometryField$SelectItem(spatialId)),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_Nil,
										$mdgriffith$elm_ui$Element$text('|')),
										zoomBtn
									]);
								return A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$padding(4),
											$mdgriffith$elm_ui$Element$alignLeft,
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$alignRight,
												$mdgriffith$elm_ui$Element$spacing(4)
											]),
										geomBtns));
							};
						},
						eJ: $mdgriffith$elm_ui$Element$fill
					}
					]),
				ku: model.O
			});
	});
var $author$project$Fields$GeometryField$maybeShapesTable = F4(
	function (windowConfig, palette, model, isReadOnly) {
		return ($elm$core$List$length(model.O) > 0) ? $elm$core$Maybe$Just(
			A4($author$project$Fields$GeometryField$shapeTableEl, palette, windowConfig, model, isReadOnly)) : $elm$core$Maybe$Just($author$project$Fields$GeometryField$noShapeNotice);
	});
var $author$project$Fields$GeometryField$Line = 2;
var $author$project$Fields$GeometryField$Point = 1;
var $author$project$Fields$GeometryField$Polygon = 3;
var $author$project$Fields$GeometryField$UpdateBuffer = function (a) {
	return {$: 11, a: a};
};
var $mdgriffith$elm_ui$Element$Input$defaultThumb = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$width(
		$mdgriffith$elm_ui$Element$px(16)),
		$mdgriffith$elm_ui$Element$height(
		$mdgriffith$elm_ui$Element$px(16)),
		$mdgriffith$elm_ui$Element$Border$rounded(8),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$Border$color(
		A3($mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5)),
		$mdgriffith$elm_ui$Element$Background$color(
		A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
	]);
var $author$project$Fields$GeometryField$bufferOptions = F2(
	function (palette, model) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$Border$color(palette.S.fK.G),
					$mdgriffith$elm_ui$Element$Border$rounded(5),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$size(18)
						]),
					$mdgriffith$elm_ui$Element$text(
						'Buffer: ' + ($elm$core$String$fromInt(model.cA) + 'ft'))),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$spacing(8),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('5'),
							A2(
							$mdgriffith$elm_ui$Element$Input$slider,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(30)),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$behindContent(
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height(
												$mdgriffith$elm_ui$Element$px(15)),
												$mdgriffith$elm_ui$Element$centerY,
												$mdgriffith$elm_ui$Element$Border$rounded(15),
												$mdgriffith$elm_ui$Element$Background$color(palette.S.fK.G)
											]),
										$mdgriffith$elm_ui$Element$none))
								]),
							{
								a8: $mdgriffith$elm_ui$Element$Input$labelHidden('Buffer'),
								lK: 500,
								lN: 5,
								h1: function (_new) {
									return $author$project$Fields$GeometryField$Internal(
										$author$project$Fields$GeometryField$UpdateBuffer(
											$elm$core$Basics$floor(_new)));
								},
								ni: $elm$core$Maybe$Just(1),
								nE: $mdgriffith$elm_ui$Element$Input$defaultThumb,
								aG: model.cA
							}),
							$mdgriffith$elm_ui$Element$text('500')
						]))
				]));
	});
var $author$project$Fields$GeometryField$drawTabToolOptions = F3(
	function (palette, model, mode) {
		switch (mode) {
			case 1:
				return A2($author$project$Fields$GeometryField$bufferOptions, palette, model);
			case 2:
				return A2($author$project$Fields$GeometryField$bufferOptions, palette, model);
			case 3:
				return $mdgriffith$elm_ui$Element$none;
			default:
				return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$Fields$GeometryField$StartDrawing = function (a) {
	return {$: 8, a: a};
};
var $author$project$Fields$GeometryField$drawingModeToText = function (mode) {
	switch (mode) {
		case 0:
			return 'Not Drawing';
		case 1:
			return 'Point';
		case 2:
			return 'Line';
		default:
			return 'Polygon';
	}
};
var $author$project$Fields$GeometryField$getSrcForDrawingModeIcon = function (targetMode) {
	switch (targetMode) {
		case 1:
			return '/PointIcon.svg';
		case 2:
			return '/LineIcon.svg';
		case 3:
			return '/PolygonIcon.svg';
		default:
			return '/Unknown.svg';
	}
};
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $mdgriffith$elm_ui$Element$image = F2(
	function (attrs, _v0) {
		var src = _v0.ne;
		var description = _v0.kD;
		var imageAttributes = A2(
			$elm$core$List$filter,
			function (a) {
				switch (a.$) {
					case 7:
						return true;
					case 8:
						return true;
					default:
						return false;
				}
			},
			attrs);
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.le),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName('img'),
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$src(src)),
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$alt(description))
								]),
							imageAttributes),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil))
					])));
	});
var $author$project$Fields$GeometryField$drawToolButton = F3(
	function (palette, targetMode, activeMode) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(2),
					$mdgriffith$elm_ui$Element$Background$color(
					(_Utils_eq(targetMode, activeMode) ? $author$project$PAM$UI$Basic$darkenColor(0.15) : $elm$core$Basics$identity)(palette.S.cd.G)),
					$mdgriffith$elm_ui$Element$Border$color(palette.S.cd.G),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Border$rounded(3),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(36)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(36)),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$title(
						$author$project$Fields$GeometryField$drawingModeToText(targetMode)))
				]),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(palette.S.cd.eE)
						]),
					{
						kD: $author$project$Fields$GeometryField$drawingModeToText(targetMode),
						ne: $author$project$Fields$GeometryField$getSrcForDrawingModeIcon(targetMode)
					}),
				b8: $elm$core$Maybe$Just(
					$author$project$Fields$GeometryField$Internal(
						$author$project$Fields$GeometryField$StartDrawing(targetMode)))
			});
	});
var $author$project$Fields$GeometryField$drawTabContent = F3(
	function (palette, model, mode) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(4),
							$mdgriffith$elm_ui$Element$padding(4)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Tools: '),
							A3($author$project$Fields$GeometryField$drawToolButton, palette, 1, mode),
							A3($author$project$Fields$GeometryField$drawToolButton, palette, 2, mode),
							A3($author$project$Fields$GeometryField$drawToolButton, palette, 3, mode)
						])),
					A3($author$project$Fields$GeometryField$drawTabToolOptions, palette, model, mode)
				]));
	});
var $author$project$Fields$GeometryField$AddSelectedReferenceFeatures = {$: 25};
var $author$project$Fields$GeometryField$CloseSelectLayerDrowdown = {$: 17};
var $author$project$PAM$UI$Palette$Quaternary = 3;
var $author$project$PAM$UI$Select$SelectDown = 0;
var $author$project$Fields$GeometryField$SelectedLayerChanged = function (a) {
	return {$: 18, a: a};
};
var $author$project$Fields$GeometryField$ToggleSelectLayerDropdown = {$: 19};
var $author$project$PAM$UI$Basic$lunarGreen = A3($mdgriffith$elm_ui$Element$rgb255, 63, 67, 61);
var $author$project$PAM$UI$Select$defaultButtonConfig = F2(
	function (size, args) {
		return {
			gp: $author$project$PAM$UI$Basic$lunarGreen,
			kY: $author$project$PAM$UI$Basic$white,
			hh: A2($author$project$PAM$UI$Basic$scaleFont, size, -1),
			fb: args.fb,
			r: args.r,
			b8: args.b8,
			h7: _List_Nil,
			fC: _Utils_update(
				$author$project$PAM$UI$Basic$edges,
				{cR: 6, cX: 6}),
			eJ: args.eJ
		};
	});
var $author$project$PAM$UI$Basic$Edges = F4(
	function (top, right, bottom, left) {
		return {cz: bottom, cR: left, cX: right, c3: top};
	});
var $author$project$PAM$UI$Basic$greenKelp = A3($mdgriffith$elm_ui$Element$rgb255, 53, 61, 34);
var $author$project$PAM$UI$Select$defaultOptionConfig = F2(
	function (size, args) {
		return {
			kX: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$greenKelp)
				]),
			fb: $mdgriffith$elm_ui$Element$fill,
			ld: _List_Nil,
			lR: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$greenKelp)
				]),
			h7: _List_Nil,
			fC: A4($author$project$PAM$UI$Basic$Edges, 8, 6, 8, 6),
			m$: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$woodland)
				]),
			nG: args.nG,
			eJ: $mdgriffith$elm_ui$Element$fill
		};
	});
var $author$project$PAM$UI$Select$defaultOptionListConfig = F2(
	function (config, args) {
		var maxHeight = ((config.fD.j7 - config.fD.gu) - config.fD.j6) - 10;
		return {
			gp: $author$project$PAM$UI$Basic$lunarGreen,
			kY: $author$project$PAM$UI$Basic$white,
			hh: A2($author$project$PAM$UI$Basic$scaleFont, config.f5, -1),
			fb: A2($mdgriffith$elm_ui$Element$maximum, maxHeight, $mdgriffith$elm_ui$Element$shrink),
			r: args.r,
			a8: args.a8,
			h1: args.h1,
			l9: args.l9,
			mp: _List_Nil,
			mq: _List_Nil,
			fC: _Utils_update(
				$author$project$PAM$UI$Basic$edges,
				{cz: 4, c3: 4}),
			I: args.I
		};
	});
var $author$project$PAM$UI$Select$defaultSelectConfig = F2(
	function (size, args) {
		return {j4: 3, kb: args.kb, kE: args.kE, lx: args.lx, mn: args.mn, mo: args.mo, h7: _List_Nil};
	});
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $mdgriffith$elm_ui$Internal$Model$Above = 0;
var $mdgriffith$elm_ui$Element$above = function (element) {
	return A2($mdgriffith$elm_ui$Internal$Model$Nearby, 0, element);
};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onLoseFocus = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onBlur);
var $author$project$PAM$UI$Palette$Action = 1;
var $author$project$PAM$UI$Basic$corners = {gv: 0, gw: 0, i2: 0, i3: 0};
var $author$project$PAM$UI$Palette$colorSetToAttributes = F2(
	function (darken, set) {
		return _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color(
				A2($author$project$PAM$UI$Basic$darkenColor, darken, set.G)),
				$mdgriffith$elm_ui$Element$Font$color(set.eE)
			]);
	});
var $author$project$PAM$UI$Palette$getColorSet = F2(
	function (selector, palette) {
		switch (selector) {
			case 0:
				return palette.mB;
			case 1:
				return palette.fK;
			case 2:
				return palette.fW;
			case 3:
				return palette.mF;
			case 4:
				return palette.cd;
			default:
				return palette.iI;
		}
	});
var $author$project$PAM$UI$Palette$getPalette = F2(
	function (selector, paletteSet) {
		if (!selector) {
			return paletteSet.cx;
		} else {
			return paletteSet.S;
		}
	});
var $author$project$PAM$UI$Palette$getColorAttributes = F3(
	function (palette, color, set) {
		return A2(
			$author$project$PAM$UI$Palette$colorSetToAttributes,
			0,
			A2(
				$author$project$PAM$UI$Palette$getColorSet,
				color,
				A2($author$project$PAM$UI$Palette$getPalette, palette, set)));
	});
var $author$project$PAM$UI$Select$selectLabel = F2(
	function (labelConfig, openness) {
		var theIcon = function () {
			if (!openness) {
				return labelConfig.mm;
			} else {
				return labelConfig.km;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				labelConfig.h7),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignLeft,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					$mdgriffith$elm_ui$Element$text(labelConfig.a8)),
					function () {
					if (!theIcon.$) {
						var icon = theIcon.a;
						return A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight]),
							$author$project$PAM$UI$Basic$centeredIcon(icon));
					} else {
						return $mdgriffith$elm_ui$Element$none;
					}
				}()
				]));
	});
var $author$project$PAM$UI$Select$selectButton = F4(
	function (palette, selector, _v0, openness) {
		var labelConfig = _v0.lx;
		var buttonConfig = _v0.kb;
		var borderRadius = _v0.j4;
		var direction = _v0.kE;
		var optionListConfig = _v0.mo;
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$PAM$UI$Basic$id(buttonConfig.r),
						$mdgriffith$elm_ui$Element$width(buttonConfig.eJ),
						$mdgriffith$elm_ui$Element$height(buttonConfig.fb),
						$mdgriffith$elm_ui$Element$paddingEach(buttonConfig.fC),
						$mdgriffith$elm_ui$Element$Font$size(buttonConfig.hh),
						function () {
						var _v1 = _Utils_Tuple2(direction, openness);
						if (!_v1.b) {
							if (!_v1.a) {
								var _v2 = _v1.a;
								var _v3 = _v1.b;
								return $mdgriffith$elm_ui$Element$Border$roundEach(
									_Utils_update(
										$author$project$PAM$UI$Basic$corners,
										{i2: borderRadius, i3: borderRadius}));
							} else {
								var _v4 = _v1.a;
								var _v5 = _v1.b;
								return $mdgriffith$elm_ui$Element$Border$roundEach(
									_Utils_update(
										$author$project$PAM$UI$Basic$corners,
										{gv: borderRadius, gw: borderRadius}));
							}
						} else {
							var _v6 = _v1.b;
							return $mdgriffith$elm_ui$Element$Border$rounded(borderRadius);
						}
					}()
					]),
				_Utils_ap(
					buttonConfig.h7,
					A3($author$project$PAM$UI$Palette$getColorAttributes, 1, selector, palette))),
			{
				a8: A2($author$project$PAM$UI$Select$selectLabel, labelConfig, openness),
				b8: buttonConfig.b8
			});
	});
var $mdgriffith$elm_ui$Element$Input$Column = 1;
var $mdgriffith$elm_ui$Element$Input$AfterFound = 2;
var $mdgriffith$elm_ui$Element$Input$BeforeFound = 1;
var $mdgriffith$elm_ui$Element$Input$Idle = 0;
var $mdgriffith$elm_ui$Element$Input$NotFound = 0;
var $mdgriffith$elm_ui$Element$Input$Selected = 2;
var $mdgriffith$elm_ui$Element$Input$column = F2(
	function (attributes, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					attributes)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Input$downArrow = 'ArrowDown';
var $mdgriffith$elm_ui$Element$Input$leftArrow = 'ArrowLeft';
var $mdgriffith$elm_ui$Element$Input$onKeyLookup = function (lookup) {
	var decode = function (code) {
		var _v0 = lookup(code);
		if (_v0.$ === 1) {
			return $elm$json$Json$Decode$fail('No key matched');
		} else {
			var msg = _v0.a;
			return $elm$json$Json$Decode$succeed(msg);
		}
	};
	var isKey = A2(
		$elm$json$Json$Decode$andThen,
		decode,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2($elm$html$Html$Events$on, 'keyup', isKey));
};
var $mdgriffith$elm_ui$Element$Input$rightArrow = 'ArrowRight';
var $mdgriffith$elm_ui$Element$Input$row = F2(
	function (attributes, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				attributes),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Input$space = ' ';
var $mdgriffith$elm_ui$Element$Input$tabindex = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$tabindex);
var $mdgriffith$elm_ui$Element$Input$upArrow = 'ArrowUp';
var $mdgriffith$elm_ui$Element$Input$radioHelper = F3(
	function (orientation, attrs, input) {
		var track = F2(
			function (opt, _v14) {
				var found = _v14.a;
				var prev = _v14.b;
				var nxt = _v14.c;
				var val = opt.a;
				switch (found) {
					case 0:
						return _Utils_eq(
							$elm$core$Maybe$Just(val),
							input.I) ? _Utils_Tuple3(1, prev, nxt) : _Utils_Tuple3(found, val, nxt);
					case 1:
						return _Utils_Tuple3(2, prev, val);
					default:
						return _Utils_Tuple3(found, prev, nxt);
				}
			});
		var renderOption = function (_v11) {
			var val = _v11.a;
			var view = _v11.b;
			var status = _Utils_eq(
				$elm$core$Maybe$Just(val),
				input.I) ? 2 : 0;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$pointer,
						function () {
						if (!orientation) {
							return $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink);
						} else {
							return $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill);
						}
					}(),
						$mdgriffith$elm_ui$Element$Events$onClick(
						input.h1(val)),
						function () {
						if (status === 2) {
							return $mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'aria-checked', 'true'));
						} else {
							return $mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'aria-checked', 'false'));
						}
					}(),
						$mdgriffith$elm_ui$Internal$Model$Attr(
						A2($elm$html$Html$Attributes$attribute, 'role', 'radio'))
					]),
				view(status));
		};
		var prevNext = function () {
			var _v5 = input.h4;
			if (!_v5.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var _v6 = _v5.a;
				var val = _v6.a;
				return function (_v7) {
					var found = _v7.a;
					var b = _v7.b;
					var a = _v7.c;
					switch (found) {
						case 0:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, val));
						case 1:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, val));
						default:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, a));
					}
				}(
					A3(
						$elm$core$List$foldl,
						track,
						_Utils_Tuple3(0, val, val),
						input.h4));
			}
		}();
		var optionArea = function () {
			if (!orientation) {
				return A2(
					$mdgriffith$elm_ui$Element$Input$row,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(input.a8),
						attrs),
					A2($elm$core$List$map, renderOption, input.h4));
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$Input$column,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(input.a8),
						attrs),
					A2($elm$core$List$map, renderOption, input.h4));
			}
		}();
		var events = A2(
			$mdgriffith$elm_ui$Internal$Model$get,
			attrs,
			function (attr) {
				_v3$3:
				while (true) {
					switch (attr.$) {
						case 7:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _v3$3;
							}
						case 8:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _v3$3;
							}
						case 1:
							return true;
						default:
							break _v3$3;
					}
				}
				return false;
			});
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$elm$core$Maybe$Just($mdgriffith$elm_ui$Element$alignLeft),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Element$Input$tabindex(0)),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Internal$Model$htmlClass('focus')),
							$elm$core$Maybe$Just($mdgriffith$elm_ui$Element$Region$announce),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'role', 'radiogroup'))),
							function () {
							if (prevNext.$ === 1) {
								return $elm$core$Maybe$Nothing;
							} else {
								var _v1 = prevNext.a;
								var prev = _v1.a;
								var next = _v1.b;
								return $elm$core$Maybe$Just(
									$mdgriffith$elm_ui$Element$Input$onKeyLookup(
										function (code) {
											if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$leftArrow)) {
												return $elm$core$Maybe$Just(
													input.h1(prev));
											} else {
												if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$upArrow)) {
													return $elm$core$Maybe$Just(
														input.h1(prev));
												} else {
													if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$rightArrow)) {
														return $elm$core$Maybe$Just(
															input.h1(next));
													} else {
														if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$downArrow)) {
															return $elm$core$Maybe$Just(
																input.h1(next));
														} else {
															if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space)) {
																var _v2 = input.I;
																if (_v2.$ === 1) {
																	return $elm$core$Maybe$Just(
																		input.h1(prev));
																} else {
																	return $elm$core$Maybe$Nothing;
																}
															} else {
																return $elm$core$Maybe$Nothing;
															}
														}
													}
												}
											}
										}));
							}
						}()
						])),
				events),
			input.a8,
			optionArea);
	});
var $mdgriffith$elm_ui$Element$Input$radio = $mdgriffith$elm_ui$Element$Input$radioHelper(1);
var $author$project$PAM$UI$Palette$getColorAttributesActive = F3(
	function (palette, color, set) {
		return A2(
			$author$project$PAM$UI$Palette$colorSetToAttributes,
			0.15,
			A2(
				$author$project$PAM$UI$Palette$getColorSet,
				color,
				A2($author$project$PAM$UI$Palette$getPalette, palette, set)));
	});
var $mdgriffith$elm_ui$Element$Input$Option = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Input$optionWith = F2(
	function (val, view) {
		return A2($mdgriffith$elm_ui$Element$Input$Option, val, view);
	});
var $author$project$PAM$UI$Select$selectOption = F4(
	function (palette, selector, optionConfig, option) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$optionWith,
			option,
			function (optState) {
				var stateAttrs = function () {
					switch (optState) {
						case 0:
							return optionConfig.ld;
						case 1:
							return optionConfig.kX;
						default:
							return A3($author$project$PAM$UI$Palette$getColorAttributesActive, 1, selector, palette);
					}
				}();
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(optionConfig.eJ),
								$mdgriffith$elm_ui$Element$height(optionConfig.fb),
								$mdgriffith$elm_ui$Element$alignLeft,
								$mdgriffith$elm_ui$Element$paddingEach(optionConfig.fC)
							]),
						_Utils_ap(optionConfig.h7, stateAttrs)),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(
							optionConfig.nG(option))
						]));
			});
	});
var $author$project$PAM$UI$Select$selectOptionList = F4(
	function (palette, selector, _v0, options) {
		var optionConfig = _v0.mn;
		var optionListConfig = _v0.mo;
		var borderRadius = _v0.j4;
		var direction = _v0.kE;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(optionListConfig.fb),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$clip,
						$mdgriffith$elm_ui$Element$Font$size(optionListConfig.hh),
						function () {
						if (!direction) {
							return $mdgriffith$elm_ui$Element$Border$roundEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$corners,
									{gv: borderRadius, gw: borderRadius}));
						} else {
							return $mdgriffith$elm_ui$Element$Border$roundEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$corners,
									{i2: borderRadius, i3: borderRadius}));
						}
					}()
					]),
				_Utils_ap(
					optionListConfig.mq,
					A3($author$project$PAM$UI$Palette$getColorAttributes, 1, selector, palette))),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$mdgriffith$elm_ui$Element$Input$radio,
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$PAM$UI$Basic$id(optionListConfig.r),
								$mdgriffith$elm_ui$Element$paddingEach(optionListConfig.fC),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Events$onLoseFocus(optionListConfig.l9)
							]),
						optionListConfig.mp),
					{
						a8: $mdgriffith$elm_ui$Element$Input$labelHidden(optionListConfig.a8),
						h1: optionListConfig.h1,
						h4: A2(
							$elm$core$List$map,
							A3($author$project$PAM$UI$Select$selectOption, palette, selector, optionConfig),
							options),
						I: optionListConfig.I
					})));
	});
var $author$project$PAM$UI$Select$select = F5(
	function (palette, selector, config, openness, options) {
		var openFn = function () {
			var _v1 = config.kE;
			if (!_v1) {
				return $mdgriffith$elm_ui$Element$below;
			} else {
				return $mdgriffith$elm_ui$Element$above;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$el,
			function () {
				if (!openness) {
					return _Utils_ap(
						_List_fromArray(
							[
								openFn(
								A4($author$project$PAM$UI$Select$selectOptionList, palette, selector, config, options))
							]),
						_Utils_ap(
							config.h7,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Events$onLoseFocus(config.mo.l9)
								])));
				} else {
					return _Utils_ap(
						config.h7,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Events$onLoseFocus(config.mo.l9)
							]));
				}
			}(),
			A4($author$project$PAM$UI$Select$selectButton, palette, selector, config, openness));
	});
var $inkuzmin$elm_multiselect$Multiselect$ClickOnComponent = {$: 2};
var $inkuzmin$elm_multiselect$Multiselect$Focused = 1;
var $inkuzmin$elm_multiselect$Multiselect$Toggle = {$: 4};
var $rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$batch = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles;
var $rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2($rtfeldman$elm_css$Css$property, key, arg.aG);
	});
var $rtfeldman$elm_css$Css$borderBottomWidth = $rtfeldman$elm_css$Css$prop1('border-bottom-width');
var $rtfeldman$elm_css$Css$borderColor3 = F3(
	function (c1, c2, c3) {
		var value = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[c1.aG, c2.aG, c3.aG]));
		return A2($rtfeldman$elm_css$Css$property, 'border-color', value);
	});
var $rtfeldman$elm_css$Css$borderLeftWidth = $rtfeldman$elm_css$Css$prop1('border-left-width');
var $rtfeldman$elm_css$Css$borderRightWidth = $rtfeldman$elm_css$Css$prop1('border-right-width');
var $rtfeldman$elm_css$Css$borderStyle = $rtfeldman$elm_css$Css$prop1('border-style');
var $rtfeldman$elm_css$Css$borderTopWidth = $rtfeldman$elm_css$Css$prop1('border-top-width');
var $rtfeldman$elm_css$Css$display = $rtfeldman$elm_css$Css$prop1('display');
var $rtfeldman$elm_css$Css$height = $rtfeldman$elm_css$Css$prop1('height');
var $rtfeldman$elm_css$Css$Structure$Compatible = 0;
var $rtfeldman$elm_css$Css$withPrecedingHash = function (str) {
	return A2($elm$core$String$startsWith, '#', str) ? str : A2($elm$core$String$cons, '#', str);
};
var $rtfeldman$elm_css$Css$erroneousHex = function (str) {
	return {
		X: 1,
		Y: 0,
		G: 0,
		aa: 0,
		ae: 0,
		aG: $rtfeldman$elm_css$Css$withPrecedingHash(str)
	};
};
var $elm$core$String$fromList = _String_fromList;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $rtfeldman$elm_css$Css$validHex = F5(
	function (str, _v0, _v1, _v2, _v3) {
		var r1 = _v0.a;
		var r2 = _v0.b;
		var g1 = _v1.a;
		var g2 = _v1.b;
		var b1 = _v2.a;
		var b2 = _v2.b;
		var a1 = _v3.a;
		var a2 = _v3.b;
		var toResult = A2(
			$elm$core$Basics$composeR,
			$elm$core$String$fromList,
			A2($elm$core$Basics$composeR, $elm$core$String$toLower, $rtfeldman$elm_hex$Hex$fromString));
		var results = _Utils_Tuple2(
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[r1, r2])),
				toResult(
					_List_fromArray(
						[g1, g2]))),
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[b1, b2])),
				toResult(
					_List_fromArray(
						[a1, a2]))));
		if ((((!results.a.a.$) && (!results.a.b.$)) && (!results.b.a.$)) && (!results.b.b.$)) {
			var _v5 = results.a;
			var red = _v5.a.a;
			var green = _v5.b.a;
			var _v6 = results.b;
			var blue = _v6.a.a;
			var alpha = _v6.b.a;
			return {
				X: alpha / 255,
				Y: blue,
				G: 0,
				aa: green,
				ae: red,
				aG: $rtfeldman$elm_css$Css$withPrecedingHash(str)
			};
		} else {
			return $rtfeldman$elm_css$Css$erroneousHex(str);
		}
	});
var $rtfeldman$elm_css$Css$hex = function (str) {
	var withoutHash = A2($elm$core$String$startsWith, '#', str) ? A2($elm$core$String$dropLeft, 1, str) : str;
	var _v0 = $elm$core$String$toList(withoutHash);
	_v0$4:
	while (true) {
		if ((_v0.b && _v0.b.b) && _v0.b.b.b) {
			if (!_v0.b.b.b.b) {
				var r = _v0.a;
				var _v1 = _v0.b;
				var g = _v1.a;
				var _v2 = _v1.b;
				var b = _v2.a;
				return A5(
					$rtfeldman$elm_css$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_v0.b.b.b.b.b) {
					var r = _v0.a;
					var _v3 = _v0.b;
					var g = _v3.a;
					var _v4 = _v3.b;
					var b = _v4.a;
					var _v5 = _v4.b;
					var a = _v5.a;
					return A5(
						$rtfeldman$elm_css$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_v0.b.b.b.b.b.b) {
						if (!_v0.b.b.b.b.b.b.b) {
							var r1 = _v0.a;
							var _v6 = _v0.b;
							var r2 = _v6.a;
							var _v7 = _v6.b;
							var g1 = _v7.a;
							var _v8 = _v7.b;
							var g2 = _v8.a;
							var _v9 = _v8.b;
							var b1 = _v9.a;
							var _v10 = _v9.b;
							var b2 = _v10.a;
							return A5(
								$rtfeldman$elm_css$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_v0.b.b.b.b.b.b.b.b && (!_v0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _v0.a;
								var _v11 = _v0.b;
								var r2 = _v11.a;
								var _v12 = _v11.b;
								var g1 = _v12.a;
								var _v13 = _v12.b;
								var g2 = _v13.a;
								var _v14 = _v13.b;
								var b1 = _v14.a;
								var _v15 = _v14.b;
								var b2 = _v15.a;
								var _v16 = _v15.b;
								var a1 = _v16.a;
								var _v17 = _v16.b;
								var a2 = _v17.a;
								return A5(
									$rtfeldman$elm_css$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _v0$4;
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
		} else {
			break _v0$4;
		}
	}
	return $rtfeldman$elm_css$Css$erroneousHex(str);
};
var $rtfeldman$elm_css$Css$inlineBlock = {eW: 0, aG: 'inline-block'};
var $rtfeldman$elm_css$Css$position = $rtfeldman$elm_css$Css$prop1('position');
var $rtfeldman$elm_css$Css$PxUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			ga: 0,
			gy: 0,
			b_: 0,
			hh: 0,
			cS: 0,
			b4: 0,
			a9: 0,
			b5: 0,
			b6: 0,
			bx: 0,
			by: 0,
			aQ: 0,
			bb: numericValue,
			cl: 0,
			co: unitLabel,
			c7: units,
			aG: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $rtfeldman$elm_css$Css$px = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var $rtfeldman$elm_css$Css$relative = {my: 0, aG: 'relative'};
var $rtfeldman$elm_css$Css$solid = {Z: 0, bK: 0, aG: 'solid'};
var $rtfeldman$elm_css$Css$transparent = {G: 0, aG: 'transparent'};
var $rtfeldman$elm_css$Css$width = $rtfeldman$elm_css$Css$prop1('width');
var $rtfeldman$elm_css$Css$UnitlessInteger = 0;
var $rtfeldman$elm_css$Css$zero = {cS: 0, b4: 0, a9: 0, b5: 0, b6: 0, bx: 0, by: 0, lW: 0, bb: 0, en: 0, co: '', c7: 0, aG: '0'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrow = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$hex('#999'),
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$transparent),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderTopWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderLeftWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderRightWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderBottomWidth(
			$rtfeldman$elm_css$Css$px(2.5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
		]));
var $rtfeldman$elm_css$Css$top = $rtfeldman$elm_css$Css$prop1('top');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowUpside = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$hex('#999')),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderTopWidth(
			$rtfeldman$elm_css$Css$px(2.5)),
			$rtfeldman$elm_css$Css$borderLeftWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderRightWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderBottomWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$px(-2.5))
		]));
var $rtfeldman$elm_css$Css$center = $rtfeldman$elm_css$Css$prop1('center');
var $rtfeldman$elm_css$Css$cursor = $rtfeldman$elm_css$Css$prop1('cursor');
var $rtfeldman$elm_css$Css$middle = $rtfeldman$elm_css$Css$prop1('middle');
var $rtfeldman$elm_css$Css$paddingRight = $rtfeldman$elm_css$Css$prop1('padding-right');
var $rtfeldman$elm_css$Css$pointer = {f: 0, aG: 'pointer'};
var $rtfeldman$elm_css$Css$tableCell = {eW: 0, aG: 'table-cell'};
var $rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, ':', str)));
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var first = _v2.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var $rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$verticalAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'verticalAlign',
		'vertical-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$tableCell),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(25)),
			$rtfeldman$elm_css$Css$paddingRight(
			$rtfeldman$elm_css$Css$px(5))
		]));
var $rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _v0) {
		var keyframesByName = _v0.a;
		var declarations = _v0.b;
		switch (declaration.$) {
			case 0:
				var _v2 = declaration.a;
				var properties = _v2.c;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					$elm$core$List$all,
					function (_v3) {
						var properties = _v3.c;
						return $elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.b;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return $elm$core$String$isEmpty(record.ky) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3($elm$core$Dict$insert, record.fu, record.ky, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					$elm$core$List$all,
					function (_v4) {
						var properties = _v4.b;
						return $elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
		}
	});
var $rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var name = _v0.a;
					var decl = _v0.b;
					return $rtfeldman$elm_css$Css$Structure$Keyframes(
						{ky: decl, fu: name});
				},
				$elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var $rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_v0) {
	var charset = _v0.gE;
	var imports = _v0.hp;
	var namespaces = _v0.hV;
	var declarations = _v0.kz;
	var _v1 = A3(
		$elm$core$List$foldr,
		$rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _v1.a;
	var compactedDeclarations = _v1.b;
	var finalDeclarations = A2($rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
	return {gE: charset, kz: finalDeclarations, hp: imports, hV: namespaces};
};
var $rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.g9 + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.aG)) + ')'));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				$elm$core$String$join,
				' and ',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + ($rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var $rtfeldman$elm_css$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
			mediaQueries));
};
var $rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $rtfeldman$elm_css$Css$Structure$Output$spaceIndent = '    ';
var $rtfeldman$elm_css$Css$Structure$Output$indent = function (str) {
	return _Utils_ap($rtfeldman$elm_css$Css$Structure$Output$spaceIndent, str);
};
var $rtfeldman$elm_css$Css$Structure$Output$noIndent = '';
var $rtfeldman$elm_css$Css$Structure$Output$emitProperty = function (str) {
	return str + ';';
};
var $rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeL, $rtfeldman$elm_css$Css$Structure$Output$indent, $rtfeldman$elm_css$Css$Structure$Output$emitProperty),
			properties));
};
var $elm$core$String$append = _String_append;
var $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				$elm$core$String$join,
				'',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator),
				$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return A2(
		$elm$core$String$append,
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
				segments)),
		pseudoElementsString);
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		var selectorStr = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Structure$Output$selectorToString,
				A2($elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					$rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return A2($rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, $rtfeldman$elm_css$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A2(
				$elm$core$String$join,
				',\n',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				$elm$core$String$join,
				'\n\n',
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeL,
						$rtfeldman$elm_css$Css$Structure$Output$indent,
						$rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock($rtfeldman$elm_css$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.fu;
			var declaration = decl.a.ky;
			return '@keyframes ' + (name + (' {\n' + (declaration + '\n}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.gE;
	var imports = _v0.hp;
	var namespaces = _v0.hV;
	var declarations = _v0.kz;
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$importToString, imports)),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					$elm$core$String$join,
					'\n\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var $rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2($rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$rtfeldman$elm_css$Css$Structure$mapLast,
									$rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _v5;
										return newDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _v9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $Skinney$murmur3$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {bV: charsProcessed, b2: hash, bH: seed, ch: shift};
	});
var $Skinney$murmur3$Murmur3$c1 = 3432918353;
var $Skinney$murmur3$Murmur3$c2 = 461845907;
var $Skinney$murmur3$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $Skinney$murmur3$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $Skinney$murmur3$Murmur3$finalize = function (data) {
	var acc = (!(!data.b2)) ? (data.bH ^ A2(
		$Skinney$murmur3$Murmur3$multiplyBy,
		$Skinney$murmur3$Murmur3$c2,
		A2(
			$Skinney$murmur3$Murmur3$rotlBy,
			15,
			A2($Skinney$murmur3$Murmur3$multiplyBy, $Skinney$murmur3$Murmur3$c1, data.b2)))) : data.bH;
	var h0 = acc ^ data.bV;
	var h1 = A2($Skinney$murmur3$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($Skinney$murmur3$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $Skinney$murmur3$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			$Skinney$murmur3$Murmur3$multiplyBy,
			5,
			A2(
				$Skinney$murmur3$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					$Skinney$murmur3$Murmur3$multiplyBy,
					$Skinney$murmur3$Murmur3$c2,
					A2(
						$Skinney$murmur3$Murmur3$rotlBy,
						15,
						A2($Skinney$murmur3$Murmur3$multiplyBy, $Skinney$murmur3$Murmur3$c1, k1))))) + 3864292196;
	});
var $Skinney$murmur3$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.b2 | ((255 & $elm$core$Char$toCode(c)) << data.ch);
		var _v0 = data.ch;
		if (_v0 === 24) {
			return {
				bV: data.bV + 1,
				b2: 0,
				bH: A2($Skinney$murmur3$Murmur3$mix, data.bH, res),
				ch: 0
			};
		} else {
			return {bV: data.bV + 1, b2: res, bH: data.bH, ch: data.ch + 8};
		}
	});
var $Skinney$murmur3$Murmur3$hashString = F2(
	function (seed, str) {
		return $Skinney$murmur3$Murmur3$finalize(
			A3(
				$elm$core$String$foldl,
				$Skinney$murmur3$Murmur3$hashFold,
				A4($Skinney$murmur3$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var $rtfeldman$elm_css$Hash$murmurSeed = 15739;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2($Skinney$murmur3$Murmur3$hashString, $rtfeldman$elm_css$Hash$murmurSeed, str)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return $elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				$elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var $rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				$rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v14 = _Utils_Tuple2(
				$elm$core$List$head(nextResult),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_v14.a.$) && (!_v14.b.$)) {
				var nextResultParent = _v14.a.a;
				var originalParent = _v14.b.a;
				return _Utils_ap(
					A2(
						$elm$core$List$take,
						$elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return $elm$core$List$concat(
				A2(
					$rtfeldman$elm_css$Css$Structure$mapLast,
					$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						$elm$core$List$map,
						$elm$core$List$singleton,
						A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _v4 = styles.a;
					var selector = _v4.a;
					var nestedStyles = _v4.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v5 = styles.a;
					var selectorCombinator = _v5.a;
					var snippets = _v5.b;
					var rest = styles.b;
					var chain = F2(
						function (_v9, _v10) {
							var originalSequence = _v9.a;
							var originalTuples = _v9.b;
							var originalPseudoElement = _v9.c;
							var newSequence = _v10.a;
							var newTuples = _v10.b;
							var newPseudoElement = _v10.c;
							return A3(
								$rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v7 = declaration.a;
								var firstSelector = _v7.a;
								var otherSelectors = _v7.b;
								var nestedStyles = _v7.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									$elm$core$List$map,
									A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var str = declaration.a;
								var properties = declaration.b;
								return _List_fromArray(
									[
										A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _v11 = styles.a;
					var pseudoElement = _v11.a;
					var nestedStyles = _v11.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = $rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						$elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$Keyframes(
								{ky: str, fu: name})
							]));
				case 4:
					var _v12 = styles.a;
					var mediaQueries = _v12.a;
					var nestedStyles = _v12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _v13 = $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v13.a;
							var otherSelectors = _v13.b;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									$elm$core$List$singleton(
										$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
	var firstSelector = _v2.a;
	var otherSelectors = _v2.b;
	var styles = _v2.c;
	return A2(
		$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			$rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2($elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				$elm$core$List$map,
				A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return _List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.gE;
	var imports = _v0.hp;
	var namespaces = _v0.hV;
	var snippets = _v0.iQ;
	var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {gE: charset, kz: declarations, hp: imports, hV: namespaces};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp = function (sheet) {
	return $rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		$rtfeldman$elm_css$Css$Structure$compactStylesheet(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (styles) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp, styles));
};
var $rtfeldman$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$murmurSeed = 15739;
var $rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {gE: $elm$core$Maybe$Nothing, hp: _List_Nil, hV: _List_Nil, iQ: snippets};
};
var $rtfeldman$elm_css$VirtualDom$Styled$getClassname = function (styles) {
	return $elm$core$List$isEmpty(styles) ? 'unstyled' : A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2(
				$Skinney$murmur3$Murmur3$hashString,
				$rtfeldman$elm_css$VirtualDom$Styled$murmurSeed,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
					$elm$core$List$singleton(
						$rtfeldman$elm_css$Css$Preprocess$stylesheet(
							$elm$core$List$singleton(
								A2(
									$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
									styles,
									$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil)))))))));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var classname = $rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classProperty = A2(
		$elm$virtual_dom$VirtualDom$property,
		'className',
		$elm$json$Json$Encode$string(classname));
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, styles, classname);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$css = $rtfeldman$elm_css$Html$Styled$Internal$css;
var $rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$Node;
var $rtfeldman$elm_css$Html$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$node;
var $rtfeldman$elm_css$Html$Styled$div = $rtfeldman$elm_css$Html$Styled$node('div');
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$on, eventName, handler),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $inkuzmin$elm_multiselect$Multiselect$withOptions = F2(
	function (options, decoder) {
		return A2(
			$elm$json$Json$Decode$map,
			function (m) {
				return {ee: m, ih: options.ih, iV: options.iV};
			},
			decoder);
	});
var $inkuzmin$elm_multiselect$Multiselect$onClickNoDefault = function (message) {
	var config = {ih: true, iV: true};
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$custom,
		'click',
		A2(
			$inkuzmin$elm_multiselect$Multiselect$withOptions,
			config,
			$elm$json$Json$Decode$succeed(message)));
};
var $rtfeldman$elm_css$VirtualDom$Styled$attribute = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$attribute, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$rel = $rtfeldman$elm_css$VirtualDom$Styled$attribute('rel');
var $inkuzmin$elm_multiselect$Multiselect$arrow = function (_v0) {
	var model = _v0;
	var arrowRel = (model.aE === 2) ? 'arrowUpside' : 'arrow';
	var arrowCss = (model.aE === 2) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowUpside]) : _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrow]);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowWrap])),
				$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault($inkuzmin$elm_multiselect$Multiselect$Toggle)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(arrowCss),
						$rtfeldman$elm_css$Html$Styled$Attributes$rel(arrowRel)
					]),
				_List_Nil)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$Clear = {$: 7};
var $rtfeldman$elm_css$Css$fontSize = $rtfeldman$elm_css$Css$prop1('font-size');
var $rtfeldman$elm_css$Css$lineHeight = $rtfeldman$elm_css$Css$prop1('line-height');
var $rtfeldman$elm_css$Css$UnitlessFloat = 0;
var $rtfeldman$elm_css$Css$num = function (val) {
	return {
		by: 0,
		aQ: 0,
		lW: 0,
		ei: 0,
		bb: val,
		co: '',
		c7: 0,
		aG: $elm$core$String$fromFloat(val)
	};
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$clear = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$px(18)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1))
		]));
var $rtfeldman$elm_css$Css$color = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'color', c.aG);
};
var $rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Css$pseudoClass = function (_class) {
	return $rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		$rtfeldman$elm_css$Css$Structure$PseudoClassSelector(_class));
};
var $rtfeldman$elm_css$Css$hover = $rtfeldman$elm_css$Css$pseudoClass('hover');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$clearWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(17)),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#999')),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$tableCell),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$color(
					$rtfeldman$elm_css$Css$hex('#D0021B'))
				]))
		]));
var $rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		$elm$virtual_dom$VirtualDom$text(str));
};
var $rtfeldman$elm_css$Html$Styled$text = $rtfeldman$elm_css$VirtualDom$Styled$text;
var $inkuzmin$elm_multiselect$Multiselect$clear = function (_v0) {
	var model = _v0;
	return (!$elm$core$List$isEmpty(model.I)) ? A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$clearWrap])),
				$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault($inkuzmin$elm_multiselect$Multiselect$Clear)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$clear]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('×')
					]))
			])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
};
var $rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'background-color', c.aG);
};
var $rtfeldman$elm_css$Css$prop3 = F4(
	function (key, argA, argB, argC) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.aG, argB.aG, argC.aG])));
	});
var $rtfeldman$elm_css$Css$border3 = $rtfeldman$elm_css$Css$prop3('border');
var $rtfeldman$elm_css$Css$borderRadius = $rtfeldman$elm_css$Css$prop1('border-radius');
var $rtfeldman$elm_css$Css$PercentageUnits = 0;
var $rtfeldman$elm_css$Css$pct = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var $rtfeldman$elm_css$Css$table = {eW: 0, aG: 'table'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$container = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('#ccc')),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(4)),
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$hex('#d9d9d9'),
			$rtfeldman$elm_css$Css$hex('#ccc'),
			$rtfeldman$elm_css$Css$hex('#b3b3b3')),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('#fff')),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#333')),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$px(34)),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$table)
		]));
var $rtfeldman$elm_css$Css$borderColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-color', c.aG);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$boxShadowCustom = function (p) {
	return A2($rtfeldman$elm_css$Css$property, 'box-shadow', p);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$focused = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderColor(
			$rtfeldman$elm_css$Css$hex('#007eff')),
			$inkuzmin$elm_multiselect$Multiselect$SelectCss$boxShadowCustom('inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)')
		]));
var $inkuzmin$elm_multiselect$Multiselect$Adjust = function (a) {
	return {$: 11, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Filter = function (a) {
	return {$: 10, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Shortcut = function (a) {
	return {$: 13, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$property, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$id = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('id');
var $rtfeldman$elm_css$Html$Styled$input = $rtfeldman$elm_css$Html$Styled$node('input');
var $rtfeldman$elm_css$Css$border = $rtfeldman$elm_css$Css$prop1('border');
var $rtfeldman$elm_css$Css$initial = {ge: 0, gh: 0, cy: 0, gk: 0, bS: 0, gl: 0, bT: 0, a_: 0, Z: 0, dw: 0, G: 0, f: 0, eW: 0, b_: 0, e7: 0, cM: 0, dT: 0, br: 0, hh: 0, b$: 0, p: 0, a5: 0, d1: 0, hB: 0, fj: 0, cS: 0, b4: 0, a9: 0, b5: 0, b6: 0, bx: 0, by: 0, aQ: 0, d7: 0, i: 0, g: 0, fw: 0, lW: 0, bb: 0, en: 0, b9: 0, aC: 0, eD: 0, ck: 0, bK: 0, cl: 0, cm: 0, bL: 0, ai: 0, co: '', c7: 0, aG: 'initial', db: 0, bk: 0};
var $rtfeldman$elm_css$Css$inherit = _Utils_update(
	$rtfeldman$elm_css$Css$initial,
	{aG: 'inherit'});
var $rtfeldman$elm_css$Css$int = function (val) {
	return {
		a5: 0,
		d1: 0,
		by: 0,
		aQ: 0,
		lW: 0,
		ei: 0,
		bb: val,
		co: '',
		c7: 0,
		aG: $elm$core$String$fromInt(val)
	};
};
var $rtfeldman$elm_css$Css$none = {bS: 0, gs: 0, Z: 0, f: 0, eW: 0, k7: 0, hr: 0, fj: 0, b6: 0, bx: 0, aQ: 0, i: 0, g: 0, fw: 0, en: 0, mw: 0, aC: 0, et: 0, mV: 0, ck: 0, bL: 0, ai: 0, q: 0, nQ: 0, aG: 'none'};
var $rtfeldman$elm_css$Css$outlineStyle = $rtfeldman$elm_css$Css$prop1('outline-style');
var $rtfeldman$elm_css$Css$padding = $rtfeldman$elm_css$Css$prop1('padding');
var $rtfeldman$elm_css$Css$paddingTop = $rtfeldman$elm_css$Css$prop1('padding-top');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$input = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$px(14)),
			$rtfeldman$elm_css$Css$outlineStyle($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$int(1)),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$paddingTop(
			$rtfeldman$elm_css$Css$px(8))
		]));
var $rtfeldman$elm_css$Css$absolute = {my: 0, aG: 'absolute'};
var $rtfeldman$elm_css$Css$fontStyle = $rtfeldman$elm_css$Css$prop1('font-style');
var $rtfeldman$elm_css$Css$fontWeight = function (_v0) {
	var value = _v0.aG;
	return A2($rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var $rtfeldman$elm_css$Css$left = $rtfeldman$elm_css$Css$prop1('left');
var $rtfeldman$elm_css$Css$normal = {e4: 0, b$: 0, a5: 0, fB: 0, aG: 'normal', bk: 0};
var $rtfeldman$elm_css$Css$overflow = $rtfeldman$elm_css$Css$prop1('overflow');
var $rtfeldman$elm_css$Css$scroll = {cy: 0, gs: 0, hr: 0, b9: 0, mW: 0, aG: 'scroll'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$inputMirrow = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$px(-100)),
			$rtfeldman$elm_css$Css$left(
			$rtfeldman$elm_css$Css$px(-100)),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$scroll),
			$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$int(1))
		]));
var $rtfeldman$elm_css$Css$marginLeft = $rtfeldman$elm_css$Css$prop1('margin-left');
var $rtfeldman$elm_css$Css$paddingBottom = $rtfeldman$elm_css$Css$prop1('padding-bottom');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$inputWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$paddingBottom(
			$rtfeldman$elm_css$Css$px(8))
		]));
var $debois$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $debois$elm_dom$DOM$previousSibling = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'previousSibling', decoder);
};
var $debois$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $inkuzmin$elm_multiselect$Multiselect$onKeyDown = function (tagger) {
	var domF = $debois$elm_dom$DOM$target(
		$debois$elm_dom$DOM$previousSibling($debois$elm_dom$DOM$offsetWidth));
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keypress',
		A2($elm$json$Json$Decode$map, tagger, domF));
};
var $rtfeldman$elm_css$Html$Styled$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $inkuzmin$elm_multiselect$Multiselect$onKeyPress = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$keyCode));
};
var $rtfeldman$elm_css$Html$Styled$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $inkuzmin$elm_multiselect$Multiselect$onKeyUp = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keyup',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue));
};
var $inkuzmin$elm_multiselect$Multiselect$Start = {$: 0};
var $inkuzmin$elm_multiselect$Multiselect$fromResult = function (result) {
	if (!result.$) {
		var successValue = result.a;
		return $elm$json$Json$Decode$succeed(successValue);
	} else {
		var errorMessage = result.a;
		return $elm$json$Json$Decode$fail(errorMessage);
	}
};
var $inkuzmin$elm_multiselect$Multiselect$preventDefaultButtons = function () {
	var options = {ih: true, iV: false};
	var filterKey = function (code) {
		return (_Utils_eq(code, $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow) || _Utils_eq(code, $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow)) ? $elm$core$Result$Ok(code) : $elm$core$Result$Err('ignored input');
	};
	var decoder = A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$always($inkuzmin$elm_multiselect$Multiselect$Start),
		A2(
			$elm$json$Json$Decode$andThen,
			A2($elm$core$Basics$composeR, filterKey, $inkuzmin$elm_multiselect$Multiselect$fromResult),
			$rtfeldman$elm_css$Html$Styled$Events$keyCode));
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$custom,
		'keydown',
		A2($inkuzmin$elm_multiselect$Multiselect$withOptions, options, decoder));
}();
var $rtfeldman$elm_css$Html$Styled$Attributes$property = $rtfeldman$elm_css$VirtualDom$Styled$property;
var $rtfeldman$elm_css$VirtualDom$Styled$style = F2(
	function (key, val) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$style, key, val),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$style = $rtfeldman$elm_css$VirtualDom$Styled$style;
var $inkuzmin$elm_multiselect$Multiselect$input = function (_v0) {
	var model = _v0;
	var w = $elm$core$String$fromFloat(model.fg + 23.0);
	var value = _Utils_eq(model.z, $inkuzmin$elm_multiselect$Multiselect$Utils$invisibleCharacter) ? A2(
		$rtfeldman$elm_css$Html$Styled$Attributes$property,
		'value',
		$elm$json$Json$Encode$string(model.z)) : A2(
		$rtfeldman$elm_css$Html$Styled$Attributes$property,
		'type',
		$elm$json$Json$Encode$string('text'));
	var inputStyle = A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', w + 'px');
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$inkuzmin$elm_multiselect$Multiselect$preventDefaultButtons,
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$inputWrap]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$inputMirrow]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(model.z)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$input,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('multiselectInput' + model.no),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$input])),
						$inkuzmin$elm_multiselect$Multiselect$onKeyDown($inkuzmin$elm_multiselect$Multiselect$Adjust),
						$inkuzmin$elm_multiselect$Multiselect$onKeyPress($inkuzmin$elm_multiselect$Multiselect$Shortcut),
						$inkuzmin$elm_multiselect$Multiselect$onKeyUp($inkuzmin$elm_multiselect$Multiselect$Filter),
						inputStyle,
						value
					]),
				_List_Nil)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$OnHover = function (a) {
	return {$: 12, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$OnSelect = function (a) {
	return {$: 5, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Utils$fst = $elm$core$Tuple$first;
var $rtfeldman$elm_css$Css$borderBottomLeftRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-left-radius');
var $rtfeldman$elm_css$Css$borderBottomRightRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-right-radius');
var $rtfeldman$elm_css$Css$borderTopColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-top-color', c.aG);
};
var $rtfeldman$elm_css$Css$prop4 = F5(
	function (key, argA, argB, argC, argD) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.aG, argB.aG, argC.aG, argD.aG])));
	});
var $rtfeldman$elm_css$Css$boxShadow4 = $rtfeldman$elm_css$Css$prop4('box-shadow');
var $rtfeldman$elm_css$Css$marginTop = $rtfeldman$elm_css$Css$prop1('margin-top');
var $rtfeldman$elm_css$Css$maxHeight = $rtfeldman$elm_css$Css$prop1('max-height');
var $rtfeldman$elm_css$Css$overflowY = $rtfeldman$elm_css$Css$prop1('overflow-y');
var $rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2($elm$core$String$join, ', ', args) + ')'));
	});
var $rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			X: alpha,
			Y: b,
			G: 0,
			aa: g,
			ae: r,
			aG: A2(
				$rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							$elm$core$String$fromFloat(alpha)
						])))
		};
	});
var $rtfeldman$elm_css$Css$zIndex = $rtfeldman$elm_css$Css$prop1('z-index');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menu = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderBottomRightRadius(
			$rtfeldman$elm_css$Css$px(4)),
			$rtfeldman$elm_css$Css$borderBottomLeftRadius(
			$rtfeldman$elm_css$Css$px(4)),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('#fff')),
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('#ccc')),
			$rtfeldman$elm_css$Css$borderTopColor(
			$rtfeldman$elm_css$Css$hex('#e6e6e6')),
			A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$zero,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$zero,
			A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.06)),
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$px(-1)),
			$rtfeldman$elm_css$Css$maxHeight(
			$rtfeldman$elm_css$Css$px($inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(1)),
			$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$scroll)
		]));
var $rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.aG, argB.aG])));
	});
var $rtfeldman$elm_css$Css$padding2 = $rtfeldman$elm_css$Css$prop2('padding');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#666')),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$px(8),
			$rtfeldman$elm_css$Css$px(10)),
			$rtfeldman$elm_css$Css$maxHeight(
			$rtfeldman$elm_css$Css$px($inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight))
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItemHovered = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.08)),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#333'))
		]));
var $rtfeldman$elm_css$Html$Styled$Events$onMouseOver = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $inkuzmin$elm_multiselect$Multiselect$menu = function (_v0) {
	var model = _v0;
	var _v1 = model.aE;
	if (_v1 === 2) {
		var hovered = function () {
			var _v3 = model.x;
			if (_v3.$ === 1) {
				return '';
			} else {
				var item = _v3.a;
				return $inkuzmin$elm_multiselect$Multiselect$Utils$fst(item);
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menu])),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('multiselectMenu' + model.no)
				]),
			A2(
				$elm$core$List$map,
				function (_v2) {
					var name = _v2.a;
					var value = _v2.b;
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_Utils_eq(name, hovered) ? _List_fromArray(
									[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItemHovered, $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem]) : _List_fromArray(
									[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem])),
								$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault(
								$inkuzmin$elm_multiselect$Multiselect$OnSelect(
									_Utils_Tuple2(name, value))),
								$rtfeldman$elm_css$Html$Styled$Events$onMouseOver(
								$inkuzmin$elm_multiselect$Multiselect$OnHover(
									_Utils_Tuple2(name, value)))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(value)
							]));
				},
				model.w));
	} else {
		return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
	}
};
var $rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$opened = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderBottomLeftRadius($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$borderBottomRightRadius($rtfeldman$elm_css$Css$zero)
		]));
var $inkuzmin$elm_multiselect$Multiselect$RemoveItem = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Html$Styled$span = $rtfeldman$elm_css$Html$Styled$node('span');
var $rtfeldman$elm_css$Css$border2 = $rtfeldman$elm_css$Css$prop2('border');
var $rtfeldman$elm_css$Css$EmUnits = 0;
var $rtfeldman$elm_css$Css$em = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tag = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#007eff')),
			A2(
			$rtfeldman$elm_css$Css$border2,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.24)),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(2)),
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.08)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$em(0.9)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1.4)),
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)
		]));
var $rtfeldman$elm_css$Css$borderRight3 = $rtfeldman$elm_css$Css$prop3('border-right');
var $rtfeldman$elm_css$Css$padding3 = $rtfeldman$elm_css$Css$prop3('padding');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagIcon = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor(
					$rtfeldman$elm_css$Css$hex('#d8eafd'))
				])),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A3(
			$rtfeldman$elm_css$Css$borderRight3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.24)),
			A3(
			$rtfeldman$elm_css$Css$padding3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$px(5),
			$rtfeldman$elm_css$Css$px(3)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagLabel = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$px(2),
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
		]));
var $inkuzmin$elm_multiselect$Multiselect$tag = F2(
	function (name, value) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tag]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagIcon])),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$inkuzmin$elm_multiselect$Multiselect$RemoveItem(
								_Utils_Tuple2(name, value)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('×')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagLabel]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(value)
						]))
				]));
	});
var $rtfeldman$elm_css$Css$inline = {eW: 0, aG: 'inline'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inline)
		]));
var $inkuzmin$elm_multiselect$Multiselect$tags = function (_v0) {
	var model = _v0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagWrap]))
			]),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var name = _v1.a;
				var value = _v1.b;
				return A2($inkuzmin$elm_multiselect$Multiselect$tag, name, value);
			},
			model.I));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$wrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100))
		]));
var $inkuzmin$elm_multiselect$Multiselect$styledView = function (_v0) {
	var model = _v0;
	var inputCss = (model.aE === 1) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container, $inkuzmin$elm_multiselect$Multiselect$SelectCss$focused]) : ((model.aE === 2) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container, $inkuzmin$elm_multiselect$Multiselect$SelectCss$opened]) : _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container]));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$wrap])),
				$rtfeldman$elm_css$Html$Styled$Events$onClick($inkuzmin$elm_multiselect$Multiselect$ClickOnComponent)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(inputCss)
					]),
				_List_fromArray(
					[
						$inkuzmin$elm_multiselect$Multiselect$tags(model),
						$inkuzmin$elm_multiselect$Multiselect$input(model),
						$inkuzmin$elm_multiselect$Multiselect$clear(model),
						$inkuzmin$elm_multiselect$Multiselect$arrow(model)
					])),
				$inkuzmin$elm_multiselect$Multiselect$menu(model)
			]));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_v0, styles) {
		var newStyles = _v0.b;
		var classname = _v0.c;
		return $elm$core$List$isEmpty(newStyles) ? styles : A3($elm$core$Dict$insert, classname, newStyles, styles);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = function (_v0) {
	var val = _v0.a;
	return val;
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_v6, _v7) {
		var key = _v6.a;
		var html = _v6.b;
		var pairs = _v7.a;
		var styles = _v7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v9 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v9.a;
				var finalStyles = _v9.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v10 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v10.a;
				var finalStyles = _v10.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v11 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v11.a;
				var finalStyles = _v11.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v12 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v12.a;
				var finalStyles = _v12.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _v0) {
		var nodes = _v0.a;
		var styles = _v0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v2 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v2.a;
				var finalStyles = _v2.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v3 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v3.a;
				var finalStyles = _v3.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v4 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v4.a;
				var finalStyles = _v4.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v5 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v5.a;
				var finalStyles = _v5.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp = F2(
	function (candidate, properties) {
		stylesFromPropertiesHelp:
		while (true) {
			if (!properties.b) {
				return candidate;
			} else {
				var _v1 = properties.a;
				var styles = _v1.b;
				var classname = _v1.c;
				var rest = properties.b;
				if ($elm$core$String$isEmpty(classname)) {
					var $temp$candidate = candidate,
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				} else {
					var $temp$candidate = $elm$core$Maybe$Just(
						_Utils_Tuple2(classname, styles)),
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties = function (properties) {
	var _v0 = A2($rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp, $elm$core$Maybe$Nothing, properties);
	if (_v0.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var _v1 = _v0.a;
		var classname = _v1.a;
		var styles = _v1.b;
		return A2($elm$core$Dict$singleton, classname, styles);
	}
};
var $rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair = function (_v0) {
	var classname = _v0.a;
	var styles = _v0.b;
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$ClassSelector(classname)
				])));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return $rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
		$elm$core$List$singleton(
			$rtfeldman$elm_css$Css$Preprocess$stylesheet(
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair,
					$elm$core$Dict$toList(dict)))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = function (styles) {
	return A3(
		$elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		$elm$core$List$singleton(
			$elm$virtual_dom$VirtualDom$text(
				$rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(styles))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyle = F3(
	function (elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _v1 = pairs.a;
				var str = _v1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _v1 = pairs.a;
				var firstKey = _v1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2($rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F2(
	function (allStyles, keyedChildNodes) {
		var styleNodeKey = A2($rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(allStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F3(
	function (elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A3(
			$elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F4(
	function (ns, elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A4(
			$elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F4(
	function (ns, elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A4(
			$elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyle, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, ns, elemType, properties, children);
	}
};
var $rtfeldman$elm_css$Html$Styled$toUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var $inkuzmin$elm_multiselect$Multiselect$view = A2($elm$core$Basics$composeR, $inkuzmin$elm_multiselect$Multiselect$styledView, $rtfeldman$elm_css$Html$Styled$toUnstyled);
var $author$project$Fields$GeometryField$referenceTabContent = F3(
	function (palette, config, model) {
		var options = _List_fromArray(
			['1', '2']);
		var optionListConfig = A2(
			$author$project$PAM$UI$Select$defaultOptionListConfig,
			config,
			{
				r: 'ahhhhhhhhhhhh',
				a8: 'Layers',
				h1: function (a) {
					return $author$project$Fields$GeometryField$Internal(
						$author$project$Fields$GeometryField$SelectedLayerChanged(a));
				},
				l9: $author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$CloseSelectLayerDrowdown),
				I: $elm$core$Maybe$Just(model.d.I)
			});
		var optionConfig = A2(
			$author$project$PAM$UI$Select$defaultOptionConfig,
			config.f5,
			{
				nG: function (a) {
					return A2(
						$elm$core$Maybe$withDefault,
						'Select a Layer',
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.fu;
							},
							a));
				}
			});
		var labelConfig = {
			km: $elm$core$Maybe$Just('fa fa-caret-down'),
			a8: A2(
				$elm$core$Maybe$withDefault,
				'Select a Layer',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.fu;
					},
					model.d.I)),
			mm: $elm$core$Maybe$Just('fa fa-times'),
			h7: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
				])
		};
		var buttonConfig = A2(
			$author$project$PAM$UI$Select$defaultButtonConfig,
			config.f5,
			{
				fb: $author$project$PAM$UI$Basic$inputHeight(config),
				r: 'nooooooooooo',
				b8: $elm$core$Maybe$Just(
					$author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$ToggleSelectLayerDropdown)),
				eJ: $mdgriffith$elm_ui$Element$fill
			});
		var selectConfig = function (a) {
			return _Utils_update(
				a,
				{
					h7: _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$white),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
						])
				});
		}(
			A2(
				$author$project$PAM$UI$Select$defaultSelectConfig,
				config.f5,
				{
					kb: _Utils_update(
						buttonConfig,
						{gp: $author$project$PAM$UI$Basic$white}),
					kE: 0,
					lx: labelConfig,
					mn: _Utils_update(
						optionConfig,
						{
							lR: _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color(
									A2($author$project$PAM$UI$Basic$fadeColor, 0.08, $author$project$PAM$UI$Basic$azureRadiance)),
									$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
								]),
							m$: _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color(
									A2($author$project$PAM$UI$Basic$fadeColor, 0.2, $author$project$PAM$UI$Basic$azureRadiance)),
									$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$azureRadiance)
								])
						}),
					mo: _Utils_update(
						optionListConfig,
						{
							gp: $author$project$PAM$UI$Basic$white,
							kY: $author$project$PAM$UI$Basic$black,
							fb: $mdgriffith$elm_ui$Element$px(150),
							mq: _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$widthEach(
									{cz: 1, cR: 0, cX: 0, c3: 0}),
									$mdgriffith$elm_ui$Element$Border$color($author$project$PAM$UI$Basic$black),
									$mdgriffith$elm_ui$Element$Border$shadow(
									{
										as: 4,
										G: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.5),
										lY: _Utils_Tuple2(0, 4),
										aD: 2
									})
								])
						})
				}));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(4),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Select a Layer'),
							A5(
							$author$project$PAM$UI$Select$select,
							palette,
							3,
							selectConfig,
							model.d.bD,
							_Utils_ap(
								_List_fromArray(
									[$elm$core$Maybe$Nothing]),
								A2($elm$core$List$map, $elm$core$Maybe$Just, model.bn.cW)))
						])),
					function () {
					if (!_Utils_eq(model.d.I, $elm$core$Maybe$Nothing)) {
						var _v0 = model.d.bz;
						switch (_v0) {
							case 2:
								return A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(4),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('Selected Geometries'),
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$paddingEach(
													{cz: 10, cR: 0, cX: 0, c3: 0})
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
														]),
													$mdgriffith$elm_ui$Element$html(
														A2(
															$elm$html$Html$map,
															function (a) {
																return $author$project$Fields$GeometryField$Internal(
																	$author$project$Fields$GeometryField$MultiMessage(a));
															},
															$inkuzmin$elm_multiselect$Multiselect$view(model.d.ac))))
												])),
											A2(
											$mdgriffith$elm_ui$Element$Input$button,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Background$color(
													((($elm$core$List$length(model.d.ag) > 0) && (!model.d.aA)) ? $author$project$PAM$UI$Basic$fadeColor(1) : $author$project$PAM$UI$Basic$fadeColor(0.25))(palette.S.cd.G)),
													A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
													$mdgriffith$elm_ui$Element$Border$rounded(16),
													$author$project$PAM$UI$Basic$title('Add'),
													$author$project$PAM$UI$Basic$montserrat,
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$px(28)),
													$mdgriffith$elm_ui$Element$width(
													A2($mdgriffith$elm_ui$Element$minimum, 100, $mdgriffith$elm_ui$Element$shrink))
												]),
											{
												a8: A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$color(palette.S.cd.eE),
															$mdgriffith$elm_ui$Element$Font$size(14),
															$author$project$PAM$UI$Basic$montserrat,
															$mdgriffith$elm_ui$Element$centerY,
															$mdgriffith$elm_ui$Element$centerX
														]),
													$mdgriffith$elm_ui$Element$text('Add')),
												b8: (($elm$core$List$length(model.d.ag) > 0) && (!model.d.aA)) ? $elm$core$Maybe$Just(
													$author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$AddSelectedReferenceFeatures)) : $elm$core$Maybe$Nothing
											}),
											function () {
											var _v1 = model.d.aA;
											switch (_v1) {
												case 0:
													return $mdgriffith$elm_ui$Element$none;
												case 1:
													return $mdgriffith$elm_ui$Element$text('Retreiving a shape');
												default:
													return $mdgriffith$elm_ui$Element$text(
														'Retreiving ' + ($elm$core$String$fromInt(model.d.aA) + ' shapes'));
											}
										}()
										]));
							case 1:
								return A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
											$mdgriffith$elm_ui$Element$Font$center,
											$mdgriffith$elm_ui$Element$Font$size(12),
											$mdgriffith$elm_ui$Element$padding(20),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('Loading features.')
										]));
							case 3:
								return A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
											$mdgriffith$elm_ui$Element$Font$center,
											$mdgriffith$elm_ui$Element$Font$size(12),
											$mdgriffith$elm_ui$Element$padding(20),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('Unable to load features for the selected layer. Please try again or use a different layer.')
										]));
							default:
								return $mdgriffith$elm_ui$Element$none;
						}
					} else {
						return A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
									$mdgriffith$elm_ui$Element$Font$center,
									$mdgriffith$elm_ui$Element$Font$size(12),
									$mdgriffith$elm_ui$Element$padding(20),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Select a layer above to display a list of features to choose from.')
								]));
					}
				}()
				]));
	});
var $author$project$Fields$GeometryField$ChooseFile = {$: 26};
var $author$project$PlugMap$Plugins$Upload$getStatusAsText = function (_v0) {
	var status = _v0.aE;
	switch (status.$) {
		case 0:
			return _List_Nil;
		case 1:
			return _List_fromArray(
				['Select a file to continue.']);
		case 2:
			return _List_fromArray(
				['Opening your file.']);
		case 3:
			return _List_fromArray(
				['Processing your file.']);
		case 4:
			return _List_fromArray(
				['Collecting features.']);
		case 5:
			return _List_fromArray(
				['Converting features.']);
		case 6:
			return _List_fromArray(
				['Processing features.']);
		case 7:
			return _List_fromArray(
				['Gathering some more information on the features.']);
		case 8:
			return _List_fromArray(
				['All processed. The features should appear shortly.']);
		default:
			var val = status.a;
			return _Utils_ap(
				_List_fromArray(
					['We ran into a problem with this file.']),
				val);
	}
};
var $author$project$Fields$GeometryField$uploadStatus = function (upld) {
	return A2(
		$mdgriffith$elm_ui$Element$textColumn,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$center,
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$spacing(5)
			]),
		A2(
			$elm$core$List$map,
			function (txt) {
				return A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$center]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(txt)
						]));
			},
			$author$project$PlugMap$Plugins$Upload$getStatusAsText(upld)));
};
var $author$project$PlugMap$Plugins$Upload$uploadableStatus = function (model) {
	var _v0 = model.aE;
	switch (_v0.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 8:
			return true;
		case 9:
			return true;
		default:
			return false;
	}
};
var $author$project$Fields$GeometryField$uploadTabContent = F3(
	function (palette, config, model) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					$author$project$PlugMap$Plugins$Upload$uploadableStatus(model.aF) ? A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
							A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
							$mdgriffith$elm_ui$Element$Border$rounded(16),
							$mdgriffith$elm_ui$Element$Font$bold,
							$author$project$PAM$UI$Basic$title('Choose File'),
							$author$project$PAM$UI$Basic$montserrat,
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(28)),
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$minimum, 100, $mdgriffith$elm_ui$Element$shrink)),
							$mdgriffith$elm_ui$Element$centerX
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color(palette.S.cd.eE),
									$mdgriffith$elm_ui$Element$Font$size(14),
									$author$project$PAM$UI$Basic$montserrat,
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$centerX
								]),
							$mdgriffith$elm_ui$Element$text('Choose File')),
						b8: $elm$core$Maybe$Just(
							$author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$ChooseFile))
					}) : $mdgriffith$elm_ui$Element$none,
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$center,
							$mdgriffith$elm_ui$Element$Font$size(10)
						]),
					$author$project$Fields$GeometryField$uploadStatus(model.aF))
				]));
	});
var $author$project$Fields$GeometryField$tabContent = F3(
	function (palette, config, model) {
		var _v0 = model.B;
		switch (_v0.$) {
			case 1:
				var mode = _v0.a;
				return A3($author$project$Fields$GeometryField$drawTabContent, palette, model, mode);
			case 3:
				return A3($author$project$Fields$GeometryField$referenceTabContent, palette, config, model);
			case 2:
				return A3($author$project$Fields$GeometryField$uploadTabContent, palette, config, model);
			default:
				return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$Fields$GeometryField$maybeTabContent = F4(
	function (config, palette, model, isReadOnly) {
		return (!isReadOnly) ? $elm$core$Maybe$Just(
			A3($author$project$Fields$GeometryField$tabContent, palette, config, model)) : $elm$core$Maybe$Nothing;
	});
var $author$project$Fields$GeometryField$DrawClick = {$: 2};
var $author$project$Fields$GeometryField$ReferenceLayerClicked = {$: 4};
var $author$project$Fields$GeometryField$UploadClick = {$: 3};
var $author$project$Fields$GeometryField$toolTab = F4(
	function (palette, labelText, isActive, action) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(6),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color(
					(isActive ? $author$project$PAM$UI$Basic$darkenColor(0.05) : $elm$core$Basics$identity)(palette.S.fW.G)),
					$mdgriffith$elm_ui$Element$Border$color(
					A2($author$project$PAM$UI$Basic$darkenColor, 0.1, palette.S.fW.G)),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Font$color(palette.S.fW.eE)
				]),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$mdgriffith$elm_ui$Element$text(labelText)),
				b8: action
			});
	});
var $author$project$Fields$GeometryField$toolTabs = F2(
	function (palette, model) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly,
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$padding(2)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$centerX
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A4(
								$author$project$Fields$GeometryField$toolTab,
								palette,
								'Draw',
								function () {
									var _v0 = model.B;
									if (_v0.$ === 1) {
										return true;
									} else {
										return false;
									}
								}(),
								$elm$core$Maybe$Just(
									$author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$DrawClick))),
								A4(
								$author$project$Fields$GeometryField$toolTab,
								palette,
								'Upload',
								function () {
									var _v1 = model.B;
									if (_v1.$ === 2) {
										return true;
									} else {
										return false;
									}
								}(),
								$elm$core$Maybe$Just(
									$author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$UploadClick)))
							]),
						($elm$core$List$length(model.bn.cW) > 0) ? _List_fromArray(
							[
								A4(
								$author$project$Fields$GeometryField$toolTab,
								palette,
								'Layers',
								function () {
									var _v2 = model.B;
									if (_v2.$ === 3) {
										return true;
									} else {
										return false;
									}
								}(),
								$elm$core$Maybe$Just(
									$author$project$Fields$GeometryField$Internal($author$project$Fields$GeometryField$ReferenceLayerClicked)))
							]) : _List_Nil))
				]));
	});
var $author$project$Fields$GeometryField$maybeTabs = F3(
	function (palette, model, isReadOnly) {
		return (!isReadOnly) ? $elm$core$Maybe$Just(
			A2($author$project$Fields$GeometryField$toolTabs, palette, model)) : $elm$core$Maybe$Nothing;
	});
var $author$project$Fields$GeometryField$view = F5(
	function (palette, windowConfig, config, model, field) {
		var isReadOnly = config.lq;
		var isRequired = config.lr;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(16),
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$inFront(
					model.cp ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Background$color(
								A2($author$project$PAM$UI$Basic$fadeColor, 0.25, $author$project$PAM$UI$Basic$black)),
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
								]),
							$mdgriffith$elm_ui$Element$text('Recalculating'))) : $mdgriffith$elm_ui$Element$none)
				]),
			A2(
				$elm$core$List$filterMap,
				function (a) {
					return A3(a, palette, model, isReadOnly);
				},
				_List_fromArray(
					[
						$author$project$Fields$GeometryField$maybeGuide,
						$author$project$Fields$GeometryField$maybeTabs,
						$author$project$Fields$GeometryField$maybeSaveNotice,
						$author$project$Fields$GeometryField$maybeTabContent(windowConfig),
						$author$project$Fields$GeometryField$maybeShapesTable(windowConfig),
						$author$project$Fields$GeometryField$maybeAreaView,
						$author$project$Fields$GeometryField$maybeEditNotice
					])));
	});
var $author$project$Fields$IntField$view = F3(
	function (env, config, field) {
		var fieldData = config.kS;
		var isReadOnly = config.lq;
		var _v0 = field.hj(fieldData);
		var val = _v0.a;
		var _default = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$height(
							$author$project$PAM$UI$Basic$inputHeight(env)),
							$mdgriffith$elm_ui$Element$Border$rounded(3),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white)),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
						]),
					isReadOnly ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$centerY
							]),
						$mdgriffith$elm_ui$Element$text(
							function () {
								switch (val.$) {
									case 1:
										var s = val.b;
										return s;
									case 2:
										var s = val.a;
										return s;
									default:
										return _default;
								}
							}())) : A2(
						$mdgriffith$elm_ui$Element$Input$text,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height(
								$author$project$PAM$UI$Basic$inputHeight(env)),
								$mdgriffith$elm_ui$Element$Border$rounded(3),
								A2($mdgriffith$elm_ui$Element$paddingXY, 5, 13),
								$mdgriffith$elm_ui$Element$Border$width(0),
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
							]),
						{
							a8: $mdgriffith$elm_ui$Element$Input$labelHidden('End Date'),
							h1: function (a) {
								var ms = $author$project$Fields$Shared$toMaybeString(a);
								var parsedValue = function () {
									if (!ms.$) {
										var s = ms.a;
										return $elm$core$String$toInt(s);
									} else {
										return $elm$core$Maybe$Nothing;
									}
								}();
								var _v2 = _Utils_Tuple2(ms, parsedValue);
								if (!_v2.a.$) {
									if (!_v2.b.$) {
										var text = _v2.a.a;
										var value = _v2.b.a;
										return A2(
											field.m3,
											fieldData,
											A2($author$project$Fields$Shared$Valid, value, text));
									} else {
										var text = _v2.a.a;
										return A2(
											field.m3,
											fieldData,
											$author$project$Fields$Shared$Invalid(text));
									}
								} else {
									return A2(field.m3, fieldData, $author$project$Fields$Shared$NotEntered);
								}
							},
							mv: $elm$core$Maybe$Just(
								A2(
									$mdgriffith$elm_ui$Element$Input$placeholder,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 3, 0),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
											$mdgriffith$elm_ui$Element$centerY
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_Nil,
										$mdgriffith$elm_ui$Element$text('0')))),
							eE: function () {
								switch (val.$) {
									case 0:
										return isReadOnly ? _default : '';
									case 1:
										var val_ = val.a;
										var text_ = val.b;
										return text_;
									default:
										var text_ = val.a;
										return text_;
								}
							}()
						}))
				]));
	});
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $author$project$Fields$MaybeFlagField$view = F2(
	function (config, field) {
		var _v0 = field.hj(config.kS);
		var val = _v0.a;
		var _default = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('input')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Events$onCheck(
								function (a) {
									return A2(
										field.m3,
										config.kS,
										$elm$core$Maybe$Just(a));
								}),
								$elm$html$Html$Attributes$disabled(config.lq)
							]),
						function () {
							if (!val.$) {
								var yesNo = val.a;
								return _List_fromArray(
									[
										$elm$html$Html$Attributes$checked(yesNo)
									]);
							} else {
								return _List_fromArray(
									[
										A2(
										$elm$html$Html$Attributes$property,
										'indeterminate',
										$elm$json$Json$Encode$string('yes'))
									]);
							}
						}()),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2(
								$elm$core$Maybe$withDefault,
								_default,
								A2(
									$elm$core$Maybe$andThen,
									function (a) {
										return $elm$core$Maybe$Just(
											a ? field.jt : field.hX);
									},
									val)))
						]))
				]));
	});
var $author$project$Fields$MaybeFloatField$view = F3(
	function (env, config, field) {
		var fieldData = config.kS;
		var isReadOnly = config.lq;
		var _v0 = field.hj(fieldData);
		var val = _v0.a;
		var _default = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly,
					$author$project$PAM$UI$Basic$id('date-field-todo')
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$height(
							$author$project$PAM$UI$Basic$inputHeight(env)),
							$mdgriffith$elm_ui$Element$Border$rounded(3),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$PAM$UI$Basic$darkenColor, 0.05, $author$project$PAM$UI$Basic$white)),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
						]),
					isReadOnly ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$centerY
							]),
						$mdgriffith$elm_ui$Element$text(
							function () {
								switch (val.$) {
									case 1:
										var s = val.b;
										return _Utils_ap(
											A2($elm$core$Maybe$withDefault, '', field.cc),
											s);
									case 2:
										var s = val.a;
										return _Utils_ap(
											A2($elm$core$Maybe$withDefault, '', field.cc),
											s);
									default:
										return _default;
								}
							}())) : A2(
						$mdgriffith$elm_ui$Element$Input$text,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height(
								$author$project$PAM$UI$Basic$inputHeight(env)),
								$mdgriffith$elm_ui$Element$Border$rounded(3),
								A2($mdgriffith$elm_ui$Element$paddingXY, 5, 13),
								$mdgriffith$elm_ui$Element$Border$width(0),
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
							]),
						{
							a8: $mdgriffith$elm_ui$Element$Input$labelHidden(''),
							h1: function (z) {
								var a = function () {
									var _v6 = field.cc;
									if (_v6.$ === 1) {
										return z;
									} else {
										var something = _v6.a;
										return A2($elm$core$String$startsWith, something, z) ? A2(
											$elm$core$String$right,
											$elm$core$String$length(z) - $elm$core$String$length(something),
											z) : z;
									}
								}();
								var ms = $author$project$Fields$Shared$toMaybeString(
									A2(
										$elm$core$Maybe$withDefault,
										a,
										A2(
											$elm$core$Maybe$map,
											function (v) {
												if (_Utils_cmp(
													$elm$core$String$length(a),
													v) > 0) {
													switch (val.$) {
														case 0:
															return isReadOnly ? _default : '';
														case 1:
															var val_ = val.a;
															var text_ = val.b;
															return A2(
																$elm$core$Maybe$withDefault,
																text_,
																A2(
																	$elm$core$Maybe$map,
																	function (r) {
																		var _v5 = _Utils_Tuple2(r.lN, r.lK);
																		if (!_v5.a.$) {
																			if (!_v5.b.$) {
																				var min = _v5.a.a;
																				var max = _v5.b.a;
																				return (_Utils_cmp(val_, min) < 0) ? $elm$core$String$fromFloat(min) : ((_Utils_cmp(val_, max) > 0) ? $elm$core$String$fromFloat(max) : text_);
																			} else {
																				var min = _v5.a.a;
																				return (_Utils_cmp(val_, min) < 0) ? $elm$core$String$fromFloat(min) : text_;
																			}
																		} else {
																			if (!_v5.b.$) {
																				var max = _v5.b.a;
																				return (_Utils_cmp(val_, max) > 0) ? $elm$core$String$fromFloat(max) : text_;
																			} else {
																				return text_;
																			}
																		}
																	},
																	field.mJ));
														default:
															var text_ = val.a;
															return text_;
													}
												} else {
													return a;
												}
											},
											field.hJ)));
								var parsedValue = function () {
									if (!ms.$) {
										var s = ms.a;
										return $elm$core$String$toFloat(s);
									} else {
										return $elm$core$Maybe$Nothing;
									}
								}();
								var _v2 = _Utils_Tuple2(ms, parsedValue);
								if (!_v2.a.$) {
									if (!_v2.b.$) {
										var text = _v2.a.a;
										var value = _v2.b.a;
										return A2(
											field.m3,
											fieldData,
											A2($author$project$Fields$Shared$Valid, value, text));
									} else {
										var text = _v2.a.a;
										return A2(
											field.m3,
											fieldData,
											$author$project$Fields$Shared$Invalid(text));
									}
								} else {
									return A2(field.m3, fieldData, $author$project$Fields$Shared$NotEntered);
								}
							},
							mv: $elm$core$Maybe$Just(
								A2(
									$mdgriffith$elm_ui$Element$Input$placeholder,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 3, 0),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
											$mdgriffith$elm_ui$Element$centerY
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_Nil,
										$mdgriffith$elm_ui$Element$text('0.0')))),
							eE: function () {
								switch (val.$) {
									case 0:
										return isReadOnly ? _default : '';
									case 1:
										var val_ = val.a;
										var text_ = val.b;
										return _Utils_ap(
											A2($elm$core$Maybe$withDefault, '', field.cc),
											text_);
									default:
										var text_ = val.a;
										return _Utils_ap(
											A2($elm$core$Maybe$withDefault, '', field.cc),
											text_);
								}
							}()
						}))
				]));
	});
var $author$project$Fields$MaybeStringField$view = F3(
	function (env, config, field) {
		var _v0 = field.hj(config.kS);
		var val = _v0.a;
		var _default = _v0.b;
		var displayValue = A2(
			$elm$core$Maybe$withDefault,
			config.lq ? _default : '',
			val);
		return A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$author$project$PAM$UI$Basic$inputHeight(env)),
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$disabled(config.lq))
				]),
			{
				a8: $mdgriffith$elm_ui$Element$Input$labelHidden(
					A2($elm$core$Maybe$withDefault, '', config.kT)),
				h1: function (a) {
					var _v1 = field.hJ;
					if (!_v1.$) {
						var num = _v1.a;
						return (_Utils_cmp(
							$elm$core$String$length(a),
							num) < 1) ? A2(
							field.m3,
							config.kS,
							$author$project$Fields$Shared$toMaybeString(a)) : A2(field.m3, config.kS, val);
					} else {
						return A2(
							field.m3,
							config.kS,
							$author$project$Fields$Shared$toMaybeString(a));
					}
				},
				mv: $elm$core$Maybe$Nothing,
				eE: displayValue
			});
	});
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$Fields$SingleChoiceField$view = F2(
	function (config, field) {
		return A2(
			$elm$html$Html$select,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('input'),
					A2(
					$elm$html$Html$Events$on,
					'change',
					A2(
						$elm$json$Json$Decode$map,
						function (newValue) {
							return A2(
								field.m3,
								config.kS,
								$elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (_v0) {
											var key = _v0.a;
											var value = _v0.b;
											return _Utils_eq(key, newValue);
										},
										field.h4)));
						},
						$elm$html$Html$Events$targetValue)),
					$elm$html$Html$Attributes$value(
					function (_v1) {
						var a = _v1.a;
						var b = _v1.b;
						return a;
					}(
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2('-1', 'Not Selected'),
							field.hj(config.kS)))),
					$elm$html$Html$Attributes$disabled(config.lq)
				]),
			A2(
				$elm$core$List$map,
				function (_v2) {
					var key = _v2.a;
					var val = _v2.b;
					return A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(key),
								$elm$html$Html$Attributes$selected(
								_Utils_eq(
									function (_v3) {
										var a = _v3.a;
										var b = _v3.b;
										return a;
									}(
										A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2('-1', 'Not Selected'),
											field.hj(config.kS))),
									key))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(val)
							]));
				},
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2('-1', 'Select an option'),
					field.h4)));
	});
var $author$project$Fields$SingleChoiceWithOtherField$ChangeSelection = function (a) {
	return {$: 1, a: a};
};
var $author$project$Fields$SingleChoiceWithOtherField$CloseDropdown = {$: 2};
var $author$project$Fields$SingleChoiceWithOtherField$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Fields$SingleChoiceWithOtherField$ToggleSelectLayerDropdown = {$: 3};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onFocus = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onFocus);
var $author$project$Fields$SingleChoiceWithOtherField$view = F5(
	function (palette, env, config, model, field) {
		var val = field.hj(config.kS);
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly,
					$mdgriffith$elm_ui$Element$Events$onLoseFocus(
					$author$project$Fields$SingleChoiceWithOtherField$Internal($author$project$Fields$SingleChoiceWithOtherField$CloseDropdown)),
					$mdgriffith$elm_ui$Element$Events$onFocus(
					$author$project$Fields$SingleChoiceWithOtherField$Internal($author$project$Fields$SingleChoiceWithOtherField$ToggleSelectLayerDropdown)),
					$mdgriffith$elm_ui$Element$padding(1)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$height(
							$author$project$PAM$UI$Basic$inputHeight(env)),
							$mdgriffith$elm_ui$Element$Border$rounded(3),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
						]),
					function () {
						var options = _List_fromArray(
							['1', '2']);
						var optionListConfig = A2(
							$author$project$PAM$UI$Select$defaultOptionListConfig,
							env,
							{
								r: 'options',
								a8: 'Layers',
								h1: A2($elm$core$Basics$composeR, $author$project$Fields$SingleChoiceWithOtherField$ChangeSelection, $author$project$Fields$SingleChoiceWithOtherField$Internal),
								l9: $author$project$Fields$SingleChoiceWithOtherField$Internal($author$project$Fields$SingleChoiceWithOtherField$CloseDropdown),
								I: val
							});
						var optionConfig = A2(
							$author$project$PAM$UI$Select$defaultOptionConfig,
							env.f5,
							{
								nG: function (a) {
									if (!a.$) {
										var _v7 = a.a;
										var l = _v7.a;
										var v = _v7.b;
										return v;
									} else {
										var t = a.a;
										return 'Other';
									}
								}
							});
						var labelConfig = {
							km: $elm$core$Maybe$Just('fa fa-caret-down'),
							a8: A2(
								$elm$core$Maybe$withDefault,
								'Select an option',
								A2(
									$elm$core$Maybe$map,
									function (a) {
										if (!a.$) {
											var _v5 = a.a;
											var l = _v5.a;
											var v = _v5.b;
											return v;
										} else {
											var t = a.a;
											return 'Other';
										}
									},
									val)),
							mm: $elm$core$Maybe$Just('fa fa-times'),
							h7: _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color(
									config.lq ? $author$project$PAM$UI$Basic$doveGray : $author$project$PAM$UI$Basic$black)
								])
						};
						var buttonConfig = A2(
							$author$project$PAM$UI$Select$defaultButtonConfig,
							env.f5,
							{
								fb: $author$project$PAM$UI$Basic$inputHeight(env),
								r: 'ashas',
								b8: config.lq ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
									$author$project$Fields$SingleChoiceWithOtherField$Internal($author$project$Fields$SingleChoiceWithOtherField$ToggleSelectLayerDropdown)),
								eJ: $mdgriffith$elm_ui$Element$fill
							});
						var selectConfig = function (a) {
							return _Utils_update(
								a,
								{
									h7: _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
										])
								});
						}(
							A2(
								$author$project$PAM$UI$Select$defaultSelectConfig,
								env.f5,
								{
									kb: _Utils_update(
										buttonConfig,
										{
											gp: config.lq ? A2($author$project$PAM$UI$Basic$darkenColor, 1.15, $author$project$PAM$UI$Basic$white) : $author$project$PAM$UI$Basic$white
										}),
									kE: 0,
									lx: labelConfig,
									mn: _Utils_update(
										optionConfig,
										{
											lR: _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Background$color(
													A2($author$project$PAM$UI$Basic$fadeColor, 0.08, $author$project$PAM$UI$Basic$azureRadiance)),
													$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
												]),
											m$: _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Background$color(
													A2($author$project$PAM$UI$Basic$fadeColor, 0.2, $author$project$PAM$UI$Basic$azureRadiance)),
													$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$azureRadiance)
												])
										}),
									mo: _Utils_update(
										optionListConfig,
										{
											gp: config.lq ? $author$project$PAM$UI$Basic$black : $author$project$PAM$UI$Basic$white,
											kY: config.lq ? $author$project$PAM$UI$Basic$white : $author$project$PAM$UI$Basic$white,
											fb: $mdgriffith$elm_ui$Element$px(150),
											mq: _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Border$widthEach(
													{cz: 1, cR: 0, cX: 0, c3: 0}),
													$mdgriffith$elm_ui$Element$Border$color($author$project$PAM$UI$Basic$black),
													$mdgriffith$elm_ui$Element$Border$shadow(
													{
														as: 4,
														G: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.5),
														lY: _Utils_Tuple2(0, 4),
														aD: 2
													})
												])
										})
								}));
						return A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							_List_fromArray(
								[
									function () {
									if (config.lq) {
										if (!val.$) {
											if (!val.a.$) {
												var _v1 = val.a.a;
												var t = _v1.a;
												var v = _v1.b;
												return A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
															$mdgriffith$elm_ui$Element$centerY,
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
														]),
													A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																$mdgriffith$elm_ui$Element$height(
																$author$project$PAM$UI$Basic$inputHeight(env)),
																$mdgriffith$elm_ui$Element$Border$rounded(3),
																A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0),
																$mdgriffith$elm_ui$Element$Border$width(0),
																$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
																$mdgriffith$elm_ui$Element$Background$color(
																A2($author$project$PAM$UI$Basic$darkenColor, 0.1, $author$project$PAM$UI$Basic$white))
															]),
														A2(
															$mdgriffith$elm_ui$Element$el,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
																	$mdgriffith$elm_ui$Element$centerY,
																	$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
																]),
															$mdgriffith$elm_ui$Element$text(v))));
											} else {
												var t = val.a.a;
												return A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
															$mdgriffith$elm_ui$Element$centerY,
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
														]),
													A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																$mdgriffith$elm_ui$Element$height(
																$author$project$PAM$UI$Basic$inputHeight(env)),
																$mdgriffith$elm_ui$Element$Border$rounded(3),
																A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0),
																$mdgriffith$elm_ui$Element$Border$width(0),
																$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
																$mdgriffith$elm_ui$Element$Background$color(
																A2($author$project$PAM$UI$Basic$darkenColor, 0.1, $author$project$PAM$UI$Basic$white))
															]),
														A2(
															$mdgriffith$elm_ui$Element$el,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
																	$mdgriffith$elm_ui$Element$centerY,
																	$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
																]),
															$mdgriffith$elm_ui$Element$text('Other'))));
											}
										} else {
											return A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
														$mdgriffith$elm_ui$Element$centerY,
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
													]),
												A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
															$mdgriffith$elm_ui$Element$height(
															$author$project$PAM$UI$Basic$inputHeight(env)),
															$mdgriffith$elm_ui$Element$Border$rounded(3),
															A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0),
															$mdgriffith$elm_ui$Element$Border$width(0),
															$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
															$mdgriffith$elm_ui$Element$Background$color(
															A2($author$project$PAM$UI$Basic$darkenColor, 0.1, $author$project$PAM$UI$Basic$white))
														]),
													A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
																$mdgriffith$elm_ui$Element$centerY,
																$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
															]),
														$mdgriffith$elm_ui$Element$text('Not Set'))));
										}
									} else {
										return A5(
											$author$project$PAM$UI$Select$select,
											palette,
											3,
											selectConfig,
											model.be,
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (_v2) {
														var id = _v2.a;
														var val_ = _v2.b;
														return $author$project$Fields$Shared$Choice(
															_Utils_Tuple2(id, val_));
													},
													field.h4),
												_List_fromArray(
													[
														$author$project$Fields$Shared$Other($elm$core$Maybe$Nothing)
													])));
									}
								}(),
									function () {
									if ((!val.$) && (val.a.$ === 1)) {
										var t = val.a.a;
										return A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
													$mdgriffith$elm_ui$Element$centerY,
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											config.lq ? A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height(
														$author$project$PAM$UI$Basic$inputHeight(env)),
														$mdgriffith$elm_ui$Element$Border$rounded(3),
														A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0),
														$mdgriffith$elm_ui$Element$Border$width(0),
														$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
														$mdgriffith$elm_ui$Element$Background$color(
														A2($author$project$PAM$UI$Basic$darkenColor, 0.1, $author$project$PAM$UI$Basic$white))
													]),
												A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
															$mdgriffith$elm_ui$Element$centerY,
															$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray)
														]),
													$mdgriffith$elm_ui$Element$text(
														A2($elm$core$Maybe$withDefault, 'Not Set', t)))) : A2(
												$mdgriffith$elm_ui$Element$Input$text,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height(
														$author$project$PAM$UI$Basic$inputHeight(env)),
														$mdgriffith$elm_ui$Element$Border$rounded(3),
														A2($mdgriffith$elm_ui$Element$paddingXY, 5, 13),
														$mdgriffith$elm_ui$Element$Border$width(0),
														$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black)
													]),
												{
													a8: $mdgriffith$elm_ui$Element$Input$labelHidden(''),
													h1: A2(
														$elm$core$Basics$composeR,
														$elm$core$Maybe$Just,
														A2(
															$elm$core$Basics$composeR,
															$author$project$Fields$Shared$Other,
															A2($elm$core$Basics$composeR, $author$project$Fields$SingleChoiceWithOtherField$ChangeSelection, $author$project$Fields$SingleChoiceWithOtherField$Internal))),
													mv: $elm$core$Maybe$Just(
														A2(
															$mdgriffith$elm_ui$Element$Input$placeholder,
															_List_fromArray(
																[
																	A2($mdgriffith$elm_ui$Element$paddingXY, 3, 0),
																	$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
																	$mdgriffith$elm_ui$Element$centerY
																]),
															A2(
																$mdgriffith$elm_ui$Element$el,
																_List_Nil,
																$mdgriffith$elm_ui$Element$text('Enter an option')))),
													eE: A2($elm$core$Maybe$withDefault, '', t)
												}));
									} else {
										return $mdgriffith$elm_ui$Element$none;
									}
								}()
								]));
					}())
				]));
	});
var $author$project$Fields$GenericField$getGenericView = F3(
	function (palette, env, data) {
		var fieldType = data.hb;
		var fieldModel = data.ha;
		var _v0 = _Utils_Tuple2(fieldType, fieldModel);
		_v0$14:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A2(
							$author$project$Fields$Shared$rowFieldLabel,
							data,
							A3($author$project$Fields$MaybeStringField$view, env, data, field)));
				case 1:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A2(
							$author$project$Fields$Shared$rowFieldLabel,
							data,
							A2($author$project$Fields$MaybeStringField$longView, data, field)));
				case 2:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A2(
							$author$project$Fields$Shared$rowFieldLabel,
							data,
							A3($author$project$Fields$IntField$view, env, data, field)));
				case 3:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A2(
							$author$project$Fields$Shared$rowFieldLabel,
							data,
							A3($author$project$Fields$MaybeFloatField$view, env, data, field)));
				case 4:
					if ((!_v0.b.$) && (_v0.b.a.$ === 1)) {
						var field = _v0.a.a;
						var model = _v0.b.a.a;
						return A2(
							$mdgriffith$elm_ui$Element$map,
							A2($author$project$Fields$GenericField$dateTranslator, data.kS, field),
							A2(
								$author$project$Fields$Shared$rowFieldLabel,
								data,
								A4($author$project$Fields$DateField$view, env, data, model, field)));
					} else {
						break _v0$14;
					}
				case 5:
					if ((!_v0.b.$) && (!_v0.b.a.$)) {
						var field = _v0.a.a;
						var model = _v0.b.a.a;
						return A2(
							$mdgriffith$elm_ui$Element$map,
							A2($author$project$Fields$GenericField$dateRangeTranslator, data.kS, field),
							A2(
								$author$project$Fields$Shared$rowFieldLabel,
								data,
								A4($author$project$Fields$DateRangeField$view, env, data, model, field)));
					} else {
						break _v0$14;
					}
				case 9:
					if ((!_v0.b.$) && (_v0.b.a.$ === 2)) {
						var field = _v0.a.a;
						var model = _v0.b.a.a;
						return A2(
							$mdgriffith$elm_ui$Element$map,
							A2($author$project$Fields$GenericField$singleChoiceWithOtherTranslator, data.kS, field),
							A2(
								$author$project$Fields$Shared$rowFieldLabel,
								data,
								A5($author$project$Fields$SingleChoiceWithOtherField$view, palette, env, data, model, field)));
					} else {
						break _v0$14;
					}
				case 10:
					if ((!_v0.b.$) && (_v0.b.a.$ === 3)) {
						var field = _v0.a.a;
						var model = _v0.b.a.a;
						return A2(
							$mdgriffith$elm_ui$Element$map,
							A3($author$project$Fields$GenericField$geometryTranslator, data.kS, field, model),
							A3(
								$author$project$Fields$Shared$columnFieldLabel,
								palette,
								data,
								A5($author$project$Fields$GeometryField$view, palette, env, data, model, field)));
					} else {
						break _v0$14;
					}
				case 6:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A2(
							$author$project$Fields$Shared$rowFieldLabel,
							data,
							$mdgriffith$elm_ui$Element$html(
								A2($author$project$Fields$SingleChoiceField$view, data, field))));
				case 12:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A2(
							$author$project$Fields$Shared$rowFieldLabel,
							data,
							A3($author$project$Fields$CustomField$view, palette, data, field)));
				case 13:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A3(
							$author$project$Fields$Shared$columnFieldLabel,
							palette,
							data,
							A3($author$project$Fields$CustomField$view, palette, data, field)));
				case 14:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A3($author$project$Fields$CustomField$view, palette, data, field));
				case 7:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A2(
							$author$project$Fields$Shared$rowFieldLabel,
							data,
							$mdgriffith$elm_ui$Element$html(
								A2($author$project$Fields$MaybeFlagField$view, data, field))));
				case 8:
					var field = _v0.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Fields$GenericField$ParentMsg,
						A2(
							$author$project$Fields$Shared$rowFieldLabel,
							data,
							$mdgriffith$elm_ui$Element$html(
								A2($author$project$Fields$FlagWithCommentField$view, data, field))));
				default:
					break _v0$14;
			}
		}
		return A2($mdgriffith$elm_ui$Element$map, $author$project$Fields$GenericField$ParentMsg, $author$project$Fields$GenericField$errorView);
	});
var $author$project$Fields$GenericField$view = F7(
	function (palette, env, fieldInfo, fieldType, model, validation, data) {
		var _v0 = function () {
			if (!validation.$) {
				return _Utils_Tuple2(false, true);
			} else {
				var rule = validation.a;
				return _Utils_Tuple2(
					true,
					rule(data));
			}
		}();
		var isRequired = _v0.a;
		var isValid = _v0.b;
		var fView = A3(
			$author$project$Fields$GenericField$getGenericView,
			palette,
			env,
			{
				kS: data,
				ha: model,
				kT: $elm$core$Maybe$Just(fieldInfo.fu),
				hb: fieldType,
				lq: fieldInfo.ir,
				lr: isRequired,
				lt: isValid
			});
		return fView;
	});
var $author$project$PAM$EditableFields$genericFieldView = F8(
	function (palette, env, field, model, editing, item, rowNum, colNum) {
		var obi = field.a$;
		var bi = _Utils_update(
			obi,
			{
				ir: editing ? field.a$.ir : true
			});
		return A2(
			$mdgriffith$elm_ui$Element$map,
			A2($author$project$PAM$EditableFields$genericFieldTranslator, rowNum, colNum),
			A7(
				$author$project$Fields$GenericField$view,
				palette,
				env,
				bi,
				field.bq(item),
				model,
				field.eI,
				item));
	});
var $author$project$PAM$EditableFields$editFieldRow = F7(
	function (palette, env, row, rowModels, editing, item, rowNum) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$indexedMap,
				function (index) {
					return function (field) {
						return A2(
							$elm$core$Maybe$withDefault,
							A8($author$project$PAM$EditableFields$genericFieldView, palette, env, field, $elm$core$Maybe$Nothing, editing, item, rowNum, index),
							A2(
								$elm$core$Maybe$andThen,
								function (model) {
									return $elm$core$Maybe$Just(
										A8($author$project$PAM$EditableFields$genericFieldView, palette, env, field, model, editing, item, rowNum, index));
								},
								$elm$core$List$head(
									A2($elm$core$List$drop, index, rowModels))));
					};
				},
				row));
	});
var $author$project$PAM$EditableFields$editFieldGrid = F6(
	function (palette, env, rows, rowsModels, editing, item) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(13),
					$mdgriffith$elm_ui$Element$paddingEach(
					{cz: 0, cR: 2, cX: 2, c3: 0}),
					$mdgriffith$elm_ui$Element$Font$size(14)
				]),
			A2(
				$elm$core$List$indexedMap,
				function (index) {
					return function (row) {
						return A2(
							$elm$core$Maybe$withDefault,
							A7($author$project$PAM$EditableFields$editFieldRow, palette, env, row, _List_Nil, editing, item, index),
							A2(
								$elm$core$Maybe$andThen,
								function (a) {
									return $elm$core$Maybe$Just(
										A7($author$project$PAM$EditableFields$editFieldRow, palette, env, row, a, editing, item, index));
								},
								$elm$core$List$head(
									A2($elm$core$List$drop, index, rowsModels))));
					};
				},
				rows));
	});
var $mdgriffith$elm_ui$Internal$Model$Heading = function (a) {
	return {$: 4, a: a};
};
var $mdgriffith$elm_ui$Element$Region$heading = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Describe, $mdgriffith$elm_ui$Internal$Model$Heading);
var $author$project$PAM$UI$Basic$transparentN = function (alpha) {
	return A4(
		$mdgriffith$elm_ui$Element$rgba,
		0,
		0,
		0,
		$elm$core$Basics$abs(alpha));
};
var $author$project$PAM$objectDetailsView = F3(
	function (palette, env, config) {
		var grid = $author$project$PAM$buildDetailLayout(config);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			A2(
				$author$project$PAM$UI$Basic$renderElAnimation,
				config.bu,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$PAM$UI$Basic$transparentN(0.85)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{cz: 10, cR: 4, cX: 4, c3: 10}),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$author$project$PAM$UI$Basic$id('object-details')
					])),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							_Utils_update(
								$author$project$PAM$UI$Basic$edges,
								{cz: 15, cR: 10})),
							$mdgriffith$elm_ui$Element$Region$heading(4),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$greenSmoke),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 3)),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
							$mdgriffith$elm_ui$Element$Font$bold,
							$author$project$PAM$UI$Basic$montserrat,
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$htmlAttribute(
							$elm$html$Html$Attributes$class('word-break'))
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(config.c2 + (config.n.bi.cZ + (': ' + config.n.fu)))
						])),
					A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$PAM$deetsTranslator,
					A6($author$project$PAM$EditableFields$editFieldGrid, palette, env, grid, config.e, config.cP, config.n))
				]));
	});
var $author$project$PAM$UI$StandardElements$SecondaryAction = 1;
var $author$project$PAM$saveObjChangesBtn = F4(
	function (palette, env, maybeTxt, maybeMsg) {
		var txt = A2($elm$core$Maybe$withDefault, 'Save', maybeTxt);
		return A4(
			$author$project$PAM$UI$StandardElements$button,
			1,
			{
				eV: env.eV,
				kJ: !_Utils_eq(maybeMsg, $elm$core$Maybe$Nothing),
				bE: palette,
				f5: env.f5
			},
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			{
				a8: _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(txt)
					]),
				b8: maybeMsg
			});
	});
var $author$project$PAM$Message$TurnOffMapThemes = {$: 33};
var $author$project$PAM$Message$TurnOnMapThemes = {$: 32};
var $author$project$PAM$sidePanelHeaderView = F3(
	function (palette, env, themesSelected) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(
						A2(
							$author$project$PAM$UI$Basic$darkenColor,
							themesSelected ? 0.20 : 0,
							palette.S.mB.G)),
						$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$author$project$PAM$UI$Basic$title('Map Themes')
					]),
				{
					a8: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						$mdgriffith$elm_ui$Element$text('Map Themes')),
					b8: $elm$core$Maybe$Just($author$project$PAM$Message$TurnOnMapThemes)
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(
						A2(
							$author$project$PAM$UI$Basic$darkenColor,
							(!themesSelected) ? 0.20 : 0,
							palette.S.mB.G)),
						$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$author$project$PAM$UI$Basic$title('Show Details')
					]),
				{
					a8: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						$mdgriffith$elm_ui$Element$text('Details')),
					b8: $elm$core$Maybe$Just($author$project$PAM$Message$TurnOffMapThemes)
				})
			]);
	});
var $author$project$PAM$userCanEditObject = F3(
	function (user, acls, object) {
		var objectSpecificACL = A3($author$project$PAM$User$getACLOrDefault, 'Object', object.bi.cZ, acls);
		var _v0 = objectSpecificACL.gb;
		switch (_v0) {
			case 0:
				return $elm$core$Maybe$Nothing;
			case 1:
				return $elm$core$Maybe$Nothing;
			case 2:
				return $elm$core$Maybe$Nothing;
			case 3:
				return _Utils_eq(object.gP.r, user.r) ? $elm$core$Maybe$Just(object) : $elm$core$Maybe$Nothing;
			case 4:
				return _Utils_eq(object.gP.r, user.r) ? $elm$core$Maybe$Just(object) : $elm$core$Maybe$Nothing;
			case 5:
				return $elm$core$Maybe$Just(object);
			default:
				return $elm$core$Maybe$Just(object);
		}
	});
var $author$project$PAM$EditableFields$getNotRequiredValidationForField = F2(
	function (object, field) {
		var value = (field.r >= 0) ? A2($author$project$PAM$Object$FieldValue$get, field, object.au) : $elm$core$Maybe$Just(
			$author$project$PAM$Object$FieldValue$FieldValue_Text(
				$author$project$PAM$EditableFields$stringToMaybeString(object.fu)));
		if (value.$ === 1) {
			return true;
		} else {
			var val = value.a;
			switch (val.$) {
				case 7:
					var date = val.a;
					switch (date.$) {
						case 1:
							var start = date.a;
							return true;
						case 2:
							return false;
						default:
							return true;
					}
				case 8:
					var rng = val.a;
					switch (rng.$) {
						case 0:
							return true;
						case 2:
							return false;
						default:
							var range = rng.a;
							var str = rng.b;
							var _v4 = _Utils_Tuple2(range.iT, range.e0);
							_v4$2:
							while (true) {
								switch (_v4.a.$) {
									case 2:
										return false;
									case 0:
										switch (_v4.b.$) {
											case 2:
												break _v4$2;
											case 0:
												var _v7 = _v4.a;
												var _v8 = _v4.b;
												return true;
											default:
												var _v10 = _v4.b;
												var end = _v10.a;
												return true;
										}
									default:
										switch (_v4.b.$) {
											case 2:
												break _v4$2;
											case 1:
												var _v5 = _v4.a;
												var start = _v5.a;
												var _v6 = _v4.b;
												var end = _v6.a;
												return true;
											default:
												var _v9 = _v4.a;
												var start = _v9.a;
												return true;
										}
								}
							}
							return false;
					}
				case 3:
					var intValue = val.a;
					if (intValue.$ === 2) {
						return false;
					} else {
						return true;
					}
				case 4:
					var naturalNumber = val.a;
					switch (naturalNumber.$) {
						case 1:
							var nat = naturalNumber.a;
							return (nat >= 0) ? true : false;
						case 2:
							return false;
						default:
							return true;
					}
				case 5:
					var v = val.a;
					switch (v.$) {
						case 1:
							return true;
						case 0:
							return true;
						default:
							return false;
					}
				case 9:
					var text = val.a;
					return true;
				case 2:
					var text = val.a;
					return true;
				case 6:
					var money = val.a;
					if (money.$ === 2) {
						return false;
					} else {
						return true;
					}
				case 11:
					var text = val.a;
					return true;
				case 12:
					var choice = val.a;
					return true;
				case 13:
					var obj = val.a;
					return true;
				case 14:
					var objs = val.a;
					return true;
				case 15:
					var year = val.a;
					switch (year.$) {
						case 1:
							var y = year.a;
							return (y >= 1970) && (y <= 2099);
						case 0:
							return true;
						default:
							return false;
					}
				case 16:
					var flag = val.a;
					if (!flag.$) {
						if (!flag.a.$) {
							var comment = flag.a.a;
							return !_Utils_eq(comment, $elm$core$Maybe$Nothing);
						} else {
							return true;
						}
					} else {
						return true;
					}
				case 18:
					var scores = val.a;
					return true;
				case 0:
					return false;
				case 19:
					return false;
				case 17:
					var v = val.a;
					switch (v.$) {
						case 1:
							return true;
						case 2:
							return false;
						default:
							return true;
					}
				case 1:
					return true;
				default:
					return true;
			}
		}
	});
var $author$project$PAM$Model$ModelInfo$matchLayoutAndModelFields = F2(
	function (layoutField, modelField) {
		return _Utils_eq(
			layoutField.hU,
			$elm$core$Maybe$Just(modelField.r));
	});
var $author$project$PAM$fieldIsValid = F3(
	function (model, object, field) {
		var _v0 = field.lP;
		switch (_v0) {
			case 2:
				var filter = A2(
					$elm$core$Maybe$withDefault,
					{
						hb: 1,
						r: -1,
						hJ: $elm$core$Maybe$Just(50),
						fu: 'Name',
						is: $elm$core$Maybe$Nothing
					},
					A2(
						$elm_community$list_extra$List$Extra$find,
						$author$project$PAM$Model$ModelInfo$matchLayoutAndModelFields(field),
						model));
				var fieldVal = A2($author$project$PAM$EditableFields$getValidationForField, object, filter);
				return fieldVal;
			case 0:
				var filter = A2(
					$elm$core$Maybe$withDefault,
					{
						hb: 1,
						r: -1,
						hJ: $elm$core$Maybe$Just(50),
						fu: 'Name',
						is: $elm$core$Maybe$Nothing
					},
					A2(
						$elm_community$list_extra$List$Extra$find,
						$author$project$PAM$Model$ModelInfo$matchLayoutAndModelFields(field),
						model));
				var fieldVal = A2($author$project$PAM$EditableFields$getNotRequiredValidationForField, object, filter);
				return fieldVal;
			default:
				return true;
		}
	});
var $author$project$PAM$fieldIsInvalid = F3(
	function (model, object, field) {
		return !A3($author$project$PAM$fieldIsValid, model, object, field);
	});
var $author$project$PAM$flattenLayout = function (detail) {
	if (detail.b) {
		var head = detail.a;
		var rest = detail.b;
		return A2(
			$elm$core$List$append,
			head,
			$author$project$PAM$flattenLayout(rest));
	} else {
		return _List_Nil;
	}
};
var $author$project$PAM$validateObject = F3(
	function (layout, model, object) {
		var errors = A2(
			$elm$core$List$filter,
			A2($author$project$PAM$fieldIsInvalid, model, object),
			$author$project$PAM$flattenLayout(layout));
		return $elm$core$List$isEmpty(errors) ? $elm$core$Maybe$Just(object) : $elm$core$Maybe$Nothing;
	});
var $mdgriffith$elm_ui$Internal$Model$OnRight = 2;
var $mdgriffith$elm_ui$Element$onRight = function (element) {
	return A2($mdgriffith$elm_ui$Internal$Model$Nearby, 2, element);
};
var $mdgriffith$elm_ui$Internal$Model$Top = 0;
var $mdgriffith$elm_ui$Element$alignTop = $mdgriffith$elm_ui$Internal$Model$AlignY(0);
var $author$project$PAM$UI$Basic$renderAnimation = F2(
	function (animations, otherAttrs) {
		return _Utils_ap(
			$author$project$Animation$render(animations),
			otherAttrs);
	});
var $author$project$PAM$UI$Basic$iconWith = F2(
	function (className, fx) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$minimum, 14, $mdgriffith$elm_ui$Element$shrink)),
					$mdgriffith$elm_ui$Element$height(
					A2($mdgriffith$elm_ui$Element$minimum, 14, $mdgriffith$elm_ui$Element$shrink))
				]),
			$mdgriffith$elm_ui$Element$html(
				A2(
					$elm$html$Html$i,
					A2(
						$author$project$PAM$UI$Basic$renderAnimation,
						fx,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class(className)
							])),
					_List_Nil)));
	});
var $author$project$PAM$UI$Basic$centeredIconWith = F2(
	function (className, fx) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
			A2($author$project$PAM$UI$Basic$iconWith, className, fx));
	});
var $author$project$PAM$UI$LeftSidebar$toggleView = F3(
	function (palette, env, onToggle) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
					$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE),
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(env.fD.lE)),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(env.fD.hH)),
					$mdgriffith$elm_ui$Element$Border$roundEach(
					_Utils_update(
						$author$project$PAM$UI$Basic$corners,
						{gw: 6})),
					$mdgriffith$elm_ui$Element$alignTop,
					$mdgriffith$elm_ui$Element$alignRight,
					$author$project$PAM$UI$Basic$title('Toggle Sidebar')
				]),
			{
				a8: A2($author$project$PAM$UI$Basic$centeredIconWith, 'fa fa-chevron-right', env.aY),
				b8: onToggle
			});
	});
var $author$project$PAM$UI$LeftSidebar$view = F3(
	function (palette, env, config) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			A2(
				$author$project$PAM$UI$Basic$renderElAnimation,
				env.aX,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						$mdgriffith$elm_ui$Element$alignLeft
					])),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$onRight(
							A3($author$project$PAM$UI$LeftSidebar$toggleView, palette, env, config.mj))
						]),
					_List_fromArray(
						[config.fa])),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(env.fD.lG))
						]),
					_List_fromArray(
						[config.j$])),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[config.a6]))
				]));
	});
var $author$project$PAM$leftSidebarView = F4(
	function (palette, config, user, acls) {
		var route = config.mS;
		var objectRepo = config.b;
		var modelInfo = config.a;
		var detailModels = config.e;
		var appSettings = config.bR;
		var leftSidebarSearchModel = config.aP;
		var themesParam = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.s;
			},
			$author$project$PAM$Route$tryGetQueryParams(route));
		var buildSidebar = function (views) {
			return A3(
				$author$project$PAM$UI$LeftSidebar$view,
				palette,
				config,
				{
					j$: views.j$,
					a6: views.a6,
					fa: views.fa,
					mj: $elm$core$Maybe$Just($author$project$PAM$Message$ToggleLeftSidebar)
				});
		};
		var emptyView = buildSidebar(
			{
				j$: A3(
					$author$project$PAM$objectDetailsEmptyView,
					palette,
					config,
					{bu: config.R, aP: config.aP}),
				a6: A2($author$project$PAM$objectDetailsFooterEmptyView, palette, config),
				fa: A3(
					$author$project$PAM$objectDetailsHeaderView,
					palette,
					config,
					A3($author$project$PAM$sidePanelHeaderView, palette, config, false))
			});
		var themesView = buildSidebar(
			{
				j$: A2($author$project$PAM$mapThemesView, palette, config),
				a6: A2($author$project$PAM$objectDetailsFooterEmptyView, palette, config),
				fa: A3(
					$author$project$PAM$objectDetailsHeaderView,
					palette,
					config,
					A3($author$project$PAM$sidePanelHeaderView, palette, config, true))
			});
		var buildView = F3(
			function (objTypeId, fn, maybeObject) {
				if ((!themesParam.$) && themesParam.a) {
					return themesView;
				} else {
					return A2(
						$elm$core$Maybe$withDefault,
						emptyView,
						A2(
							$elm$core$Maybe$map,
							buildSidebar,
							A5(
								$elm$core$Maybe$map4,
								fn,
								A2($author$project$PAM$Model$FieldChoices$get, objTypeId, modelInfo.y),
								A2($author$project$PAM$Model$DetailLayouts$get, objTypeId, modelInfo.C),
								A2($author$project$PAM$Model$ObjectModels$get, objTypeId, modelInfo.l),
								maybeObject)));
				}
			});
		var theView = function () {
			if ((!themesParam.$) && themesParam.a) {
				return themesView;
			} else {
				if (!route.$) {
					switch (route.a.$) {
						case 0:
							var params = route.a.a;
							return emptyView;
						case 1:
							var params = route.a.a;
							return emptyView;
						case 2:
							var _v2 = route.a;
							var objTypeKey = _v2.a;
							var params = _v2.b;
							var _v3 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, modelInfo.c);
							if (!_v3.$) {
								var _v4 = _v3.a;
								var objTypeId = _v4.a;
								var objType = _v4.b;
								return A3(
									buildView,
									objTypeId,
									F4(
										function (choices, layout, fields, newObj) {
											return {
												j$: A3(
													$author$project$PAM$objectDetailsView,
													palette,
													config,
													{t: choices, e: detailModels, u: fields, bu: config.R, cP: true, v: layout, n: newObj, c: modelInfo.c, mS: route, c2: 'New '}),
												a6: A3(
													$author$project$PAM$objectDetailsFooterView,
													palette,
													config,
													_List_fromArray(
														[
															A4(
															$author$project$PAM$cancelObjChangesBtn,
															palette,
															config,
															$elm$core$Maybe$Nothing,
															$elm$core$Maybe$Just(
																$author$project$PAM$Message$CancelNewObject(newObj))),
															A2(
															$elm$core$Maybe$withDefault,
															A4($author$project$PAM$saveObjChangesBtn, palette, config, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
															A2(
																$elm$core$Maybe$map,
																function (_v5) {
																	return A4(
																		$author$project$PAM$saveObjChangesBtn,
																		palette,
																		config,
																		$elm$core$Maybe$Nothing,
																		$elm$core$Maybe$Just(
																			$author$project$PAM$Message$SaveDetails(newObj)));
																},
																A3($author$project$PAM$validateObject, layout, fields, newObj)))
														])),
												fa: A3(
													$author$project$PAM$objectDetailsHeaderView,
													palette,
													config,
													A3($author$project$PAM$sidePanelHeaderView, palette, config, false))
											};
										}),
									$author$project$PAM$Object$Repo$getNewObject(objectRepo));
							} else {
								return emptyView;
							}
						case 3:
							var _v6 = route.a;
							var parentObjTypeKey = _v6.a;
							var parentObjId = _v6.b;
							var childObjTypeKey = _v6.c;
							var params = _v6.d;
							var _v7 = A2($author$project$PAM$Model$ObjectTypes$findByKey, childObjTypeKey, modelInfo.c);
							if (!_v7.$) {
								var _v8 = _v7.a;
								var objTypeId = _v8.a;
								var objType = _v8.b;
								return A3(
									buildView,
									objTypeId,
									F4(
										function (choices, layout, fields, newObj) {
											return {
												j$: A3(
													$author$project$PAM$objectDetailsView,
													palette,
													config,
													{t: choices, e: detailModels, u: fields, bu: config.R, cP: true, v: layout, n: newObj, c: modelInfo.c, mS: route, c2: 'Link New '}),
												a6: A3(
													$author$project$PAM$objectDetailsFooterView,
													palette,
													config,
													_List_fromArray(
														[
															A4(
															$author$project$PAM$cancelObjChangesBtn,
															palette,
															config,
															$elm$core$Maybe$Nothing,
															$elm$core$Maybe$Just(
																$author$project$PAM$Message$CancelLinkDetails(
																	_Utils_Tuple2(parentObjTypeKey, parentObjId)))),
															A2(
															$elm$core$Maybe$withDefault,
															A4(
																$author$project$PAM$saveObjChangesBtn,
																palette,
																config,
																$elm$core$Maybe$Just('Save/Link'),
																$elm$core$Maybe$Nothing),
															A2(
																$elm$core$Maybe$map,
																function (_v9) {
																	return A4(
																		$author$project$PAM$saveObjChangesBtn,
																		palette,
																		config,
																		$elm$core$Maybe$Just('Save/Link'),
																		$elm$core$Maybe$Just(
																			A2(
																				$author$project$PAM$Message$SaveAndLinkDetails,
																				_Utils_Tuple2(parentObjTypeKey, parentObjId),
																				newObj)));
																},
																A3($author$project$PAM$validateObject, layout, fields, newObj)))
														])),
												fa: A3(
													$author$project$PAM$objectDetailsHeaderView,
													palette,
													config,
													A3($author$project$PAM$sidePanelHeaderView, palette, config, false))
											};
										}),
									$author$project$PAM$Object$Repo$getNewObject(objectRepo));
							} else {
								return emptyView;
							}
						case 4:
							var _v10 = route.a;
							var objTypeKey = _v10.a;
							var objId = _v10.b;
							var params = _v10.c;
							var _v11 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, modelInfo.c);
							if (!_v11.$) {
								var _v12 = _v11.a;
								var objTypeId = _v12.a;
								return A3(
									buildView,
									objTypeId,
									F4(
										function (choices, layout, fields, newObj) {
											return {
												j$: A3(
													$author$project$PAM$objectDetailsView,
													palette,
													config,
													{t: choices, e: detailModels, u: fields, bu: config.R, cP: false, v: layout, n: newObj, c: modelInfo.c, mS: route, c2: 'View '}),
												a6: A3(
													$author$project$PAM$objectDetailsFooterView,
													palette,
													config,
													_List_fromArray(
														[
															A4(
															$author$project$PAM$closeLink,
															palette,
															config,
															$elm$core$Maybe$Nothing,
															A2($author$project$PAM$Route$initMap, $elm$core$Maybe$Nothing, route)),
															A2(
															$elm$core$Maybe$withDefault,
															$mdgriffith$elm_ui$Element$none,
															A2(
																$elm$core$Maybe$map,
																function (_v13) {
																	return A4(
																		$author$project$PAM$editObjectLink,
																		palette,
																		config,
																		$elm$core$Maybe$Nothing,
																		A4($author$project$PAM$Route$initEditObjectDetails, newObj.bi.lw, newObj.r, $elm$core$Maybe$Nothing, route));
																},
																A3($author$project$PAM$userCanEditObject, user, acls, newObj)))
														])),
												fa: A3(
													$author$project$PAM$objectDetailsHeaderView,
													palette,
													config,
													A3($author$project$PAM$sidePanelHeaderView, palette, config, false))
											};
										}),
									A2($author$project$PAM$Object$Repo$getObject, objId, objectRepo));
							} else {
								return emptyView;
							}
						default:
							var _v14 = route.a;
							var objTypeKey = _v14.a;
							var objId = _v14.b;
							var params = _v14.c;
							var _v15 = A2($author$project$PAM$Model$ObjectTypes$findByKey, objTypeKey, modelInfo.c);
							if (!_v15.$) {
								var _v16 = _v15.a;
								var objTypeId = _v16.a;
								return A3(
									buildView,
									objTypeId,
									F4(
										function (choices, layout, fields, newObj) {
											return {
												j$: A3(
													$author$project$PAM$objectDetailsView,
													palette,
													config,
													{t: choices, e: detailModels, u: fields, bu: config.R, cP: true, v: layout, n: newObj, c: modelInfo.c, mS: route, c2: 'Edit '}),
												a6: A3(
													$author$project$PAM$objectDetailsFooterView,
													palette,
													config,
													_List_fromArray(
														[
															A4(
															$author$project$PAM$cancelObjChangesBtn,
															palette,
															config,
															$elm$core$Maybe$Nothing,
															$elm$core$Maybe$Just(
																$author$project$PAM$Message$CancelEditDetails(
																	_Utils_Tuple2(objTypeKey, objId)))),
															A2(
															$elm$core$Maybe$withDefault,
															A4($author$project$PAM$saveObjChangesBtn, palette, config, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
															A2(
																$elm$core$Maybe$map,
																function (_v17) {
																	return A4(
																		$author$project$PAM$saveObjChangesBtn,
																		palette,
																		config,
																		$elm$core$Maybe$Nothing,
																		$elm$core$Maybe$Just(
																			$author$project$PAM$Message$SaveDetails(newObj)));
																},
																function (res) {
																	return res;
																}(
																	A3($author$project$PAM$validateObject, layout, fields, newObj))))
														])),
												fa: A3(
													$author$project$PAM$objectDetailsHeaderView,
													palette,
													config,
													A3($author$project$PAM$sidePanelHeaderView, palette, config, false))
											};
										}),
									A2($author$project$PAM$Object$Repo$getObject, objId, objectRepo));
							} else {
								return emptyView;
							}
					}
				} else {
					return emptyView;
				}
			}
		}();
		return theView;
	});
var $author$project$PAM$Map$AddHover = function (a) {
	return {$: 10, a: a};
};
var $author$project$PAM$Map$RemoveHover = function (a) {
	return {$: 11, a: a};
};
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onMouseEnter = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onMouseEnter);
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onMouseLeave = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onMouseLeave);
var $author$project$PAM$Map$legendItemToEl = function (key) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$inFront(
				key.ct ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
					A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$PAM$UI$Basic$convertColor(key.bp.kY)),
								$mdgriffith$elm_ui$Element$Font$bold
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(key.bp.fu)
							]))) : $mdgriffith$elm_ui$Element$none),
				$mdgriffith$elm_ui$Element$Background$color(
				$author$project$PAM$UI$Basic$convertColor(key.bp.G)),
				$mdgriffith$elm_ui$Element$width(
				key.ct ? A2(
					$mdgriffith$elm_ui$Element$minimum,
					300,
					$mdgriffith$elm_ui$Element$fillPortion(2)) : $mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height(
				key.ct ? $mdgriffith$elm_ui$Element$fill : $mdgriffith$elm_ui$Element$px(9)),
				$mdgriffith$elm_ui$Element$Events$onMouseEnter(
				$author$project$PAM$Map$Internal(
					$author$project$PAM$Map$AddHover(key.bp.fu))),
				$mdgriffith$elm_ui$Element$Events$onMouseLeave(
				$author$project$PAM$Map$Internal(
					$author$project$PAM$Map$RemoveHover(key.bp.fu)))
			]),
		_List_Nil);
};
var $author$project$PAM$Map$legendItemsToEl = $elm$core$List$map($author$project$PAM$Map$legendItemToEl);
var $author$project$PAM$Map$buildAndHighlightLegend = F2(
	function (model, legend) {
		return A2(
			$elm$core$Maybe$withDefault,
			$mdgriffith$elm_ui$Element$none,
			A2(
				$elm$core$Maybe$map,
				function (a) {
					return A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(10),
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 0)
							]),
						A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
									]),
								$mdgriffith$elm_ui$Element$text(a.fu)),
							$author$project$PAM$Map$legendItemsToEl(a.d6)));
				},
				legend));
	});
var $author$project$PlugMap$Plugins$Themes$categoriesWithLegend = function (categories) {
	return A2(
		$elm$core$List$filter,
		function ($) {
			return $.jg;
		},
		categories);
};
var $author$project$PlugMap$Plugins$Themes$getRasterLayer = function (model) {
	var selection = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.m0;
		},
		$elm$core$List$head(
			$author$project$PlugMap$Plugins$Themes$categoriesWithLegend(model.ly)));
	_v0$4:
	while (true) {
		if (!selection.$) {
			switch (selection.a.$) {
				case 0:
					if (!selection.a.a.$) {
						var key = selection.a.a.a;
						return A2($author$project$PlugMap$Plugins$Themes$getLayerByKey, model.lB, key);
					} else {
						break _v0$4;
					}
				case 1:
					if (selection.a.a.b) {
						var _v1 = selection.a.a;
						var head = _v1.a;
						return A2($author$project$PlugMap$Plugins$Themes$getLayerByKey, model.lB, head);
					} else {
						break _v0$4;
					}
				case 2:
					var key = selection.a.a;
					return A2($author$project$PlugMap$Plugins$Themes$getLayerByKey, model.lB, key);
				default:
					var _v2 = selection.a;
					var head = _v2.a;
					return A2($author$project$PlugMap$Plugins$Themes$getLayerByKey, model.lB, head);
			}
		} else {
			break _v0$4;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$PAM$Map$getActiveKeys = F2(
	function (maybeLayer, maybeColor) {
		var totalSlosh = 75;
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A3(
				$elm$core$Maybe$map2,
				function (layer) {
					return function (color) {
						var pixelColor = $avh4$elm_color$Color$toRgba(color);
						return A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								function (itm) {
									return A2(
										$elm$core$Maybe$withDefault,
										A2(
											$elm$core$Maybe$withDefault,
											_List_Nil,
											A2(
												$elm$core$Maybe$map,
												function (a) {
													return _List_fromArray(
														[a.fu]);
												},
												$elm$core$List$head(itm))),
										A2(
											$elm$core$Maybe$map,
											function (a) {
												return _List_fromArray(
													[a.fu]);
											},
											$elm$core$List$head(
												A2(
													$elm$core$List$sortBy,
													function (legendItem) {
														var legendItemColor = $avh4$elm_color$Color$toRgba(legendItem.G);
														return ($elm$core$Basics$abs(legendItemColor.ae - pixelColor.ae) + $elm$core$Basics$abs(legendItemColor.aa - pixelColor.aa)) + $elm$core$Basics$abs(legendItemColor.Y - pixelColor.Y);
													},
													A2(
														$elm$core$List$filter,
														function (legendItem) {
															var legendItemColor = $avh4$elm_color$Color$toRgba(legendItem.G);
															return _Utils_cmp(
																($elm$core$Basics$abs(legendItemColor.ae - pixelColor.ae) + $elm$core$Basics$abs(legendItemColor.aa - pixelColor.aa)) + $elm$core$Basics$abs(legendItemColor.Y - pixelColor.Y),
																totalSlosh) < 0;
														},
														itm)))));
								},
								layer.d6));
					};
				},
				maybeLayer,
				maybeColor));
	});
var $author$project$PAM$Map$LegendDisplay = F2(
	function (name, legend) {
		return {d6: legend, fu: name};
	});
var $author$project$PAM$Map$LegendEntryDisplay = F2(
	function (entry, active) {
		return {ct: active, bp: entry};
	});
var $author$project$PAM$Map$legendEntryToDisplay = F2(
	function (activeItems, entry) {
		return A2(
			$author$project$PAM$Map$LegendEntryDisplay,
			entry,
			A2($elm$core$List$member, entry.fu, activeItems));
	});
var $author$project$PAM$Map$legendToLegendDisplay = F3(
	function (name, activeItems, legend) {
		return A2(
			$author$project$PAM$Map$LegendDisplay,
			name,
			A2(
				$elm$core$List$map,
				$author$project$PAM$Map$legendEntryToDisplay(activeItems),
				legend));
	});
var $author$project$PAM$Map$layerToLegendDisplay = F2(
	function (_v0, layer) {
		var activeColor = _v0.cu;
		var activeHover = _v0.cv;
		var activeKeys = A2($author$project$PAM$Map$getActiveKeys, layer, activeColor);
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Maybe$Nothing,
			A2(
				$elm$core$Maybe$map,
				function (a) {
					var _v1 = a.d6;
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var legend = _v1.a;
						return $elm$core$Maybe$Just(
							A3(
								$author$project$PAM$Map$legendToLegendDisplay,
								a.fu,
								A2($elm$core$List$cons, activeHover, activeKeys),
								legend));
					}
				},
				layer));
	});
var $author$project$PAM$Map$legendView = function (model) {
	var _v0 = model.cj;
	if (_v0 === 1) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$author$project$PAM$Map$buildAndHighlightLegend,
				model,
				A2(
					$author$project$PAM$Map$layerToLegendDisplay,
					model,
					$author$project$PlugMap$Plugins$Themes$getRasterLayer(model.s))));
	} else {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$mdgriffith$elm_ui$Element$text('...'));
	}
};
var $mdgriffith$elm_ui$Internal$Model$Main = {$: 0};
var $mdgriffith$elm_ui$Element$Region$mainContent = $mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Main);
var $author$project$PAM$mapSearchSuggestionView = F2(
	function (env, _v0) {
		var displayName = _v0.eX;
		var data = _v0.ku;
		var markerAttrs = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(20)),
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(20))
			]);
		var searchMarker = A2(
			$mdgriffith$elm_ui$Element$el,
			markerAttrs,
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(15)),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(15))
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fa fa-search')
								]),
							_List_Nil))
					])));
		var singleMarker = A2(
			$mdgriffith$elm_ui$Element$el,
			markerAttrs,
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(15)),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(15))
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fa fa-map-marker')
								]),
							_List_Nil))
					])));
		var triMarker = A2(
			$mdgriffith$elm_ui$Element$el,
			markerAttrs,
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(15)),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(15))
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fa fa-map-marker multi-marker-1')
								]),
							_List_Nil)),
						$mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fa fa-map-marker multi-marker-2')
								]),
							_List_Nil)),
						$mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fa fa-map-marker multi-marker-3')
								]),
							_List_Nil))
					])));
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(500)),
					$mdgriffith$elm_ui$Element$height(
					A2($mdgriffith$elm_ui$Element$minimum, 55, $mdgriffith$elm_ui$Element$shrink)),
					$mdgriffith$elm_ui$Element$mouseOver(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(env.bE.cx.fK.G)
						])),
					$mdgriffith$elm_ui$Element$pointer,
					$mdgriffith$elm_ui$Element$padding(8)
				]),
			_List_fromArray(
				[
					function () {
					if (!data.$) {
						var sug = data.a;
						return sug.hz ? searchMarker : singleMarker;
					} else {
						var cand = data.a;
						return singleMarker;
					}
				}(),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 400, $mdgriffith$elm_ui$Element$fill)),
							$mdgriffith$elm_ui$Element$paddingEach(
							_Utils_update(
								$author$project$PAM$UI$Basic$edges,
								{cR: 15, cX: 15, c3: 2})),
							$mdgriffith$elm_ui$Element$centerY
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(displayName)
						]))
				]));
	});
var $author$project$PAM$UI$Palette$Background = 0;
var $author$project$PAM$UI$Palette$Secondary = 1;
var $author$project$Grid$Grid$ToggleColumn = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Grid$Grid$ToggleColumnSettings = {$: 13};
var $mdgriffith$elm_ui$Element$Input$checkbox = F2(
	function (attrs, _v0) {
		var label = _v0.a8;
		var icon = _v0.lb;
		var checked = _v0.kf;
		var onChange = _v0.h1;
		var attributes = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(label) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Events$onClick(
						onChange(!checked))),
					$mdgriffith$elm_ui$Element$Region$announce,
					$mdgriffith$elm_ui$Element$Input$onKeyLookup(
					function (code) {
						return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(
							onChange(!checked)) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(
							onChange(!checked)) : $elm$core$Maybe$Nothing);
					}),
					$mdgriffith$elm_ui$Element$Input$tabindex(0),
					$mdgriffith$elm_ui$Element$pointer,
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			attrs);
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			attributes,
			label,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Internal$Model$Attr(
						A2($elm$html$Html$Attributes$attribute, 'role', 'checkbox')),
						$mdgriffith$elm_ui$Internal$Model$Attr(
						A2(
							$elm$html$Html$Attributes$attribute,
							'aria-checked',
							checked ? 'true' : 'false')),
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(label),
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
					]),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[
							icon(checked)
						]))));
	});
var $author$project$PAM$UI$Basic$checkboxIcon = function (checked) {
	if (checked) {
		return $author$project$PAM$UI$Basic$icon('fa fa-check-square-o');
	} else {
		return $author$project$PAM$UI$Basic$icon('fa fa-square-o');
	}
};
var $mdgriffith$elm_ui$Element$Input$Label = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Element$Input$OnRight = 0;
var $mdgriffith$elm_ui$Element$Input$labelRight = $mdgriffith$elm_ui$Element$Input$Label(0);
var $mdgriffith$elm_ui$Internal$Model$MoveX = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$moveX = $mdgriffith$elm_ui$Internal$Flag$flag(25);
var $mdgriffith$elm_ui$Element$moveRight = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveX,
		$mdgriffith$elm_ui$Internal$Model$MoveX(x));
};
var $mdgriffith$elm_ui$Internal$Model$OnLeft = 3;
var $mdgriffith$elm_ui$Element$onLeft = function (element) {
	return A2($mdgriffith$elm_ui$Internal$Model$Nearby, 3, element);
};
var $author$project$PAM$UI$Basic$transparent70 = $author$project$PAM$UI$Basic$transparent(0.7);
var $author$project$Grid$Grid$columnTogglesView = F4(
	function (palette, config, model, enabled) {
		var scaleFont = $author$project$PAM$UI$Basic$scaleFont(config.f5);
		var checkboxRow = function (toggle) {
			return A2(
				$mdgriffith$elm_ui$Element$Input$checkbox,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 6, 8),
						$mdgriffith$elm_ui$Element$Font$size(
						scaleFont(1)),
						$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
						$mdgriffith$elm_ui$Element$width(
						A2($mdgriffith$elm_ui$Element$minimum, 268, $mdgriffith$elm_ui$Element$fill))
					]),
				{
					kf: toggle.eB,
					lb: $author$project$PAM$UI$Basic$checkboxIcon,
					a8: A2(
						$mdgriffith$elm_ui$Element$Input$labelRight,
						_List_Nil,
						$mdgriffith$elm_ui$Element$text(toggle.F)),
					h1: $author$project$Grid$Grid$ToggleColumn(toggle.i0)
				});
		};
		var checkboxes = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(200)),
					$mdgriffith$elm_ui$Element$clip
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(32)),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Border$roundEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$corners,
									{i2: 3, i3: 3})),
								$mdgriffith$elm_ui$Element$Font$size(
								scaleFont(2)),
								$mdgriffith$elm_ui$Element$alignLeft,
								A2($mdgriffith$elm_ui$Element$paddingXY, 8, 0)
							]),
						A3($author$project$PAM$UI$Palette$getColorAttributes, 0, 1, palette)),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Show/Hide Columns')
						])),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$scrollbarY]),
					A2($elm$core$List$map, checkboxRow, config.gM))
				]));
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				function () {
					var _v0 = _Utils_Tuple2(enabled, model.bX);
					if (_v0.a && (!_v0.b)) {
						var _v1 = _v0.b;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$above(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height(
											A2($mdgriffith$elm_ui$Element$minimum, 200, $mdgriffith$elm_ui$Element$fill)),
											$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent70),
											$mdgriffith$elm_ui$Element$moveRight(62),
											$mdgriffith$elm_ui$Element$onLeft(
											A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$Border$roundEach(
														_Utils_update(
															$author$project$PAM$UI$Basic$corners,
															{i2: 3, i3: 3})),
														$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent70)
													]),
												checkboxes))
										]),
									$mdgriffith$elm_ui$Element$none))
							]);
					} else {
						return _List_Nil;
					}
				}()),
			A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$author$project$PAM$UI$Basic$title('Show/Hide Columns'),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(62)),
						$author$project$PAM$UI$Basic$id($author$project$Grid$Grid$inputIds.iL)
					]),
				{
					a8: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								A2($mdgriffith$elm_ui$Element$paddingXY, 22, 4),
								$mdgriffith$elm_ui$Element$Border$widthEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$borders,
									{cX: 2})),
								$mdgriffith$elm_ui$Element$Border$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$Border$solid,
								$mdgriffith$elm_ui$Element$Font$color(
								((!enabled) ? $author$project$PAM$UI$Basic$fadeColor(0.25) : $elm$core$Basics$identity)(palette.cx.fK.eE))
							]),
						$author$project$PAM$UI$Basic$centeredIcon('fa fa-ellipsis-h')),
					b8: enabled ? $elm$core$Maybe$Just($author$project$Grid$Grid$ToggleColumnSettings) : $elm$core$Maybe$Nothing
				}));
	});
var $author$project$Grid$Grid$FilterTextChange = function (a) {
	return {$: 8, a: a};
};
var $author$project$Grid$Grid$ColumnFilter = function (title) {
	return {F: title};
};
var $author$project$Grid$Grid$FilterTypeChange = function (a) {
	return {$: 9, a: a};
};
var $author$project$PAM$UI$Palette$Tertiary = 2;
var $author$project$Grid$Grid$ToggleFilterDropdown = {$: 10};
var $author$project$PAM$UI$Basic$is1080p = function (size) {
	return (size.eJ >= 1920) || (size.fb >= 1080);
};
var $author$project$Grid$Grid$filterDropdown = F3(
	function (palette, config, _v0) {
		var filterDropdownOpenness = _v0.aL;
		var filterColumn = _v0.cK;
		var options = A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$author$project$Grid$Grid$ColumnFilter('All')
				]),
			A2(
				$elm$core$List$map,
				function (t) {
					return $author$project$Grid$Grid$ColumnFilter(t.F);
				},
				config.gM));
		var optionListConfig = A2(
			$author$project$PAM$UI$Select$defaultOptionListConfig,
			config,
			{
				r: $author$project$Grid$Grid$inputIds.dS,
				a8: 'Filter Options',
				h1: $author$project$Grid$Grid$FilterTypeChange,
				l9: $author$project$Grid$Grid$NoOp,
				I: $elm$core$Maybe$Just(
					A2(
						$elm$core$Maybe$withDefault,
						$author$project$Grid$Grid$ColumnFilter('All'),
						filterColumn))
			});
		var optionConfig = A2(
			$author$project$PAM$UI$Select$defaultOptionConfig,
			config.f5,
			{
				nG: function ($) {
					return $.F;
				}
			});
		var labelConfig = {
			km: $elm$core$Maybe$Just('fa fa-caret-down'),
			a8: A2(
				$elm$core$Maybe$withDefault,
				'All',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.F;
					},
					filterColumn)),
			mm: $elm$core$Maybe$Just('fa fa-times'),
			h7: _List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$spacingXY, 6, 0)
				])
		};
		var buttonConfig = A2(
			$author$project$PAM$UI$Select$defaultButtonConfig,
			config.f5,
			{
				fb: $author$project$PAM$UI$Basic$inputHeight(config),
				r: $author$project$Grid$Grid$inputIds.hc,
				b8: $elm$core$Maybe$Just($author$project$Grid$Grid$ToggleFilterDropdown),
				eJ: $author$project$PAM$UI$Basic$is1080p(config.f5) ? A2($mdgriffith$elm_ui$Element$minimum, 256, $mdgriffith$elm_ui$Element$fill) : A2($mdgriffith$elm_ui$Element$minimum, 220, $mdgriffith$elm_ui$Element$fill)
			});
		var selectConfig = A2(
			$author$project$PAM$UI$Select$defaultSelectConfig,
			config.f5,
			{kb: buttonConfig, kE: 0, lx: labelConfig, mn: optionConfig, mo: optionListConfig});
		return A5($author$project$PAM$UI$Select$select, palette, 2, selectConfig, filterDropdownOpenness, options);
	});
var $mdgriffith$elm_ui$Element$Input$search = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		ak: $elm$core$Maybe$Nothing,
		ap: false,
		bi: $mdgriffith$elm_ui$Element$Input$TextInputNode('search')
	});
var $mdgriffith$elm_ui$Element$Font$semiBold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.nA);
var $author$project$Grid$Grid$filterView = F3(
	function (palette, config, model) {
		var scaleFont = $author$project$PAM$UI$Basic$scaleFont(config.f5);
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(20)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color(palette.S.fK.eE),
									$mdgriffith$elm_ui$Element$Font$size(
									scaleFont(1)),
									$mdgriffith$elm_ui$Element$Font$semiBold,
									$author$project$PAM$UI$Basic$montserrat
								]),
							$mdgriffith$elm_ui$Element$text('Filter By')),
							A3($author$project$Grid$Grid$filterDropdown, palette, config, model)
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$mdgriffith$elm_ui$Element$Input$search,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$author$project$PAM$UI$Basic$is1080p(config.f5) ? A2($mdgriffith$elm_ui$Element$minimum, 256, $mdgriffith$elm_ui$Element$fill) : $mdgriffith$elm_ui$Element$shrink),
								$mdgriffith$elm_ui$Element$height(
								$author$project$PAM$UI$Basic$inputHeight(config)),
								$mdgriffith$elm_ui$Element$Font$size(
								scaleFont(1)),
								$mdgriffith$elm_ui$Element$Font$color(palette.S.fW.eE),
								$mdgriffith$elm_ui$Element$Background$color(palette.S.fW.G),
								$mdgriffith$elm_ui$Element$Border$color(palette.S.fW.G),
								$author$project$PAM$UI$Basic$id($author$project$Grid$Grid$inputIds.hd)
							]),
						{
							a8: $mdgriffith$elm_ui$Element$Input$labelHidden('Search By'),
							h1: $author$project$Grid$Grid$FilterTextChange,
							mv: $elm$core$Maybe$Just(
								A2(
									$mdgriffith$elm_ui$Element$Input$placeholder,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 12, 0),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
											$mdgriffith$elm_ui$Element$centerY,
											$mdgriffith$elm_ui$Element$Font$color(
											A2($author$project$PAM$UI$Basic$fadeColor, 0.5, palette.S.fW.eE))
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_Nil,
										$mdgriffith$elm_ui$Element$text('Enter a value to filter by')))),
							eE: A2($elm$core$Maybe$withDefault, '', model.cY)
						}))
				]));
	});
var $author$project$PAM$Message$ClickNewObject = function (a) {
	return {$: 25, a: a};
};
var $author$project$PAM$newObjectLink = F2(
	function (palette, data) {
		return A4(
			$author$project$PAM$UI$StandardElements$button,
			0,
			{eV: data.eV, bE: palette, f5: data.f5},
			_List_Nil,
			{
				a8: _List_fromArray(
					[
						$author$project$PAM$UI$Basic$centeredIcon('fa fa-plus'),
						$mdgriffith$elm_ui$Element$text(' New ' + data.N.cZ)
					]),
				b8: $elm$core$Maybe$Just(
					$author$project$PAM$Message$ClickNewObject(data.N.lw))
			});
	});
var $mdgriffith$elm_ui$Internal$Model$Bottom = 2;
var $mdgriffith$elm_ui$Element$alignBottom = $mdgriffith$elm_ui$Internal$Model$AlignY(2);
var $author$project$PAM$Model$ObjectTypes$all = function (_v0) {
	var types = _v0;
	return $elm$core$Dict$values(types);
};
var $author$project$PAM$Message$SelectObjectType = function (a) {
	return {$: 9, a: a};
};
var $author$project$PAM$objectSelectionTabView = F2(
	function (config, objectType) {
		return A2(
			$elm$core$Maybe$map,
			function (activeType) {
				var isActive = _Utils_eq(objectType.lw, activeType.lw);
				return A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$montserrat,
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, config.f5, 2)),
							$mdgriffith$elm_ui$Element$Font$color(config.bE.S.fK.eE),
							$author$project$PAM$UI$Basic$is1080p(config.f5) ? $mdgriffith$elm_ui$Element$padding(10) : A2($mdgriffith$elm_ui$Element$paddingXY, 10, 6),
							$mdgriffith$elm_ui$Element$Border$roundEach(
							_Utils_update(
								$author$project$PAM$UI$Basic$corners,
								{i2: 8, i3: 8})),
							$mdgriffith$elm_ui$Element$Background$color(
							(isActive ? $author$project$PAM$UI$Basic$darkenColor(0.15) : $elm$core$Basics$identity)(config.bE.S.fK.G)),
							$mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color(
									A2($author$project$PAM$UI$Basic$darkenColor, 0.05, config.bE.S.fK.G))
								]))
						]),
					{
						a8: $mdgriffith$elm_ui$Element$text(objectType.ie),
						b8: (!isActive) ? $elm$core$Maybe$Just(
							$author$project$PAM$Message$SelectObjectType(objectType)) : $elm$core$Maybe$Nothing
					});
			},
			config.N);
	});
var $author$project$PAM$objectSelectorView = function (model) {
	var modelInfo = model.a;
	var gridState = model.aN;
	var tabConfig = {eV: model.eV, N: gridState.N, bE: model.bE.bl, f5: model.f5};
	var tabs = A2(
		$elm$core$List$filterMap,
		$author$project$PAM$objectSelectionTabView(tabConfig),
		A2(
			$elm$core$List$filter,
			function ($) {
				return $.eW;
			},
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.kG;
				},
				$author$project$PAM$Model$ObjectTypes$all(modelInfo.c))));
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(2),
				$mdgriffith$elm_ui$Element$moveRight(5),
				$mdgriffith$elm_ui$Element$alignBottom
			]),
		tabs);
};
var $author$project$PAM$userCanCreateObject = F3(
	function (user, acls, maybeType) {
		if (!maybeType.$) {
			var t = maybeType.a;
			var objectSpecificACL = A3($author$project$PAM$User$getACLOrDefault, 'Object', t.cZ, acls);
			var _v1 = objectSpecificACL.gb;
			switch (_v1) {
				case 0:
					return $elm$core$Maybe$Nothing;
				case 1:
					return $elm$core$Maybe$Nothing;
				case 2:
					return $elm$core$Maybe$Just(t);
				case 3:
					return $elm$core$Maybe$Just(t);
				case 4:
					return $elm$core$Maybe$Just(t);
				case 5:
					return $elm$core$Maybe$Just(t);
				default:
					return $elm$core$Maybe$Just(t);
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$PAM$objectGridHeaderView = F5(
	function (model, state, columnToggles, user, acls) {
		var gridConfig = {gM: columnToggles, eV: model.eV, fD: model.fD, f5: model.f5};
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(model.fD.j6)),
					$mdgriffith$elm_ui$Element$Background$color(model.bE.bl.cx.mB.G),
					$mdgriffith$elm_ui$Element$Font$color(model.bE.bl.cx.mB.eE),
					A2($mdgriffith$elm_ui$Element$spacingXY, 15, 0)
				]),
			_List_fromArray(
				[
					$author$project$PAM$objectSelectorView(model),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					(!model.j8) ? A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$PAM$Message$GridMsg,
						A3($author$project$Grid$Grid$filterView, model.bE.bl, gridConfig, state.aM)) : $mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					function () {
						var _v0 = A3($author$project$PAM$userCanCreateObject, user, acls, state.N);
						if (!_v0.$) {
							var activeType = _v0.a;
							return A2(
								$author$project$PAM$newObjectLink,
								model.bE.iO,
								{eV: model.eV, N: activeType, f5: model.f5});
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}()),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$PAM$Message$GridMsg,
						A4($author$project$Grid$Grid$columnTogglesView, model.bE.bl, gridConfig, state.aM, !model.j8)))
				]));
	});
var $author$project$PAM$descending = F2(
	function (a, b) {
		var _v0 = A2($elm$core$Basics$compare, a.r, b.r);
		switch (_v0) {
			case 0:
				return 2;
			case 1:
				return 1;
			default:
				return 0;
		}
	});
var $author$project$PAM$Object$Repo$filterObjectsBy = F2(
	function (p, _v0) {
		var repo = _v0.a;
		return $elm$core$Dict$values(
			A2($elm$core$Dict$filter, p, repo));
	});
var $author$project$Grid$Grid$filterColumns = F3(
	function (model, columnDefs, data) {
		var _v0 = model.cY;
		if (!_v0.$) {
			var search = _v0.a;
			var lowerSearch = $elm$core$String$toLower(search);
			var columns = function () {
				var _v3 = model.cK;
				if (!_v3.$) {
					var column = _v3.a;
					return A2(
						$elm$core$List$filter,
						function (a) {
							return _Utils_eq(a.F, column.F);
						},
						columnDefs);
				} else {
					return columnDefs;
				}
			}();
			return A2(
				$elm$core$List$filter,
				function (item) {
					return A2(
						$elm$core$List$any,
						$elm$core$Basics$identity,
						A2(
							$elm$core$List$map,
							function (column) {
								return A2(
									$elm$core$String$contains,
									lowerSearch,
									function () {
										var _v1 = column.hj;
										switch (_v1.$) {
											case 0:
												var getter = _v1.a;
												return $elm$core$String$toLower(
													getter(item));
											case 1:
												var getter = _v1.a;
												return $elm$core$String$fromInt(
													getter(item));
											case 2:
												var getter = _v1.a;
												return $elm$core$String$fromFloat(
													getter(item));
											default:
												var getter = _v1.a;
												var _v2 = getter(item);
												var searchable = _v2.b;
												return $elm$core$String$toLower(searchable);
										}
									}());
							},
							columns));
				},
				data);
		} else {
			return data;
		}
	});
var $author$project$Grid$Grid$tableDataTitle = F2(
	function (getter, item) {
		switch (getter.$) {
			case 0:
				var get = getter.a;
				return get(item);
			case 1:
				var get = getter.a;
				return $elm$core$String$fromInt(
					get(item));
			case 2:
				var get = getter.a;
				return $elm$core$String$fromFloat(
					get(item));
			default:
				var get = getter.a;
				return get(item).b;
		}
	});
var $author$project$Grid$Grid$SpitOut = function (a) {
	return {$: 15, a: a};
};
var $author$project$Grid$Grid$tableDataUi = F2(
	function (getter, item) {
		switch (getter.$) {
			case 0:
				var get = getter.a;
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerY]),
					$mdgriffith$elm_ui$Element$text(
						get(item)));
			case 1:
				var get = getter.a;
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerY]),
					$mdgriffith$elm_ui$Element$text(
						$elm$core$String$fromInt(
							get(item))));
			case 2:
				var get = getter.a;
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerY]),
					$mdgriffith$elm_ui$Element$text(
						$elm$core$String$fromFloat(
							get(item))));
			default:
				var get = getter.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Grid$Grid$SpitOut,
					get(item).a);
		}
	});
var $author$project$Grid$Grid$tableCellUi = F6(
	function (config, rowHeight, sort, _v0, index, item) {
		var getter = _v0.hj;
		var bgColor = (!A2($elm$core$Basics$modBy, 2, index)) ? config.bE.cx.fW.G : config.bE.cx.mF.G;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$author$project$PAM$UI$Basic$title(
					A2($author$project$Grid$Grid$tableDataTitle, getter, item)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(rowHeight)),
					A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color(bgColor),
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$doveGray),
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, config.f5, -2)),
					$author$project$PAM$UI$Basic$helvetica
				]),
			A2($author$project$Grid$Grid$tableDataUi, getter, item));
	});
var $author$project$Grid$Grid$SortBy = function (a) {
	return {$: 1, a: a};
};
var $author$project$Grid$Grid$sortByIconClass = F2(
	function (sort, definition) {
		switch (sort.$) {
			case 0:
				return '';
			case 1:
				var col = sort.a;
				return _Utils_eq(definition.F, col) ? 'fa fa-sort-up' : '';
			default:
				var col = sort.a;
				return _Utils_eq(definition.F, col) ? 'fa fa-sort-down' : '';
		}
	});
var $author$project$Grid$Grid$tableHeaderUi = F4(
	function (config, rowHeight, sort, definition) {
		var sortClass = A2($author$project$Grid$Grid$sortByIconClass, sort, definition);
		var btnTitle = function () {
			switch (sort.$) {
				case 0:
					return 'Sort ascending (' + (definition.F + ')');
				case 1:
					var a = sort.a;
					return 'Sort descending (' + (definition.F + ')');
				default:
					var a = sort.a;
					return 'Clear sort (' + (definition.F + ')');
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(config.bE.cx.fK.G),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(rowHeight)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0),
					$author$project$PAM$UI$Basic$title(btnTitle)
				]),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							A2($mdgriffith$elm_ui$Element$spacingXY, 5, 0),
							$mdgriffith$elm_ui$Element$Font$color(config.bE.cx.fK.eE),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, config.f5, -1)),
							$author$project$PAM$UI$Basic$helvetica,
							$mdgriffith$elm_ui$Element$Font$bold
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(definition.F),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$centerY]),
							$author$project$PAM$UI$Basic$icon(sortClass))
						])),
				b8: $elm$core$Maybe$Just(
					$author$project$Grid$Grid$SortBy(definition.F))
			});
	});
var $author$project$Grid$Grid$columnDefToColumn = F3(
	function (config, sort, definition) {
		var rowHeight = A3(
			$author$project$PAM$UI$Basic$adjustOnHeight,
			config.f5,
			config.eV,
			_Utils_Tuple2(22, 31));
		return {
			fa: A4($author$project$Grid$Grid$tableHeaderUi, config, rowHeight, sort, definition),
			f0: A4($author$project$Grid$Grid$tableCellUi, config, rowHeight, sort, definition),
			eJ: $mdgriffith$elm_ui$Element$fill
		};
	});
var $author$project$Grid$Grid$getSorter = F3(
	function (model, columnDefs, by) {
		var _v0 = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (a) {
					return _Utils_eq(a.F, by);
				},
				columnDefs));
		if (!_v0.$) {
			var column = _v0.a;
			return $elm$core$Maybe$Just(column.bf);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Grid$Grid$getSortedDataFrom = F3(
	function (model, columnDefs, data) {
		var _v0 = model.bf;
		switch (_v0.$) {
			case 0:
				return data;
			case 1:
				var by = _v0.a;
				var gotted_sorter = A3($author$project$Grid$Grid$getSorter, model, columnDefs, by);
				if (!gotted_sorter.$) {
					var sorter = gotted_sorter.a;
					return sorter.fz(data);
				} else {
					return data;
				}
			default:
				var by = _v0.a;
				var gotted_sorter = A3($author$project$Grid$Grid$getSorter, model, columnDefs, by);
				return $elm$core$List$reverse(
					function () {
						if (!gotted_sorter.$) {
							var sorter = gotted_sorter.a;
							return sorter.fz(data);
						} else {
							return data;
						}
					}());
		}
	});
var $author$project$Grid$Grid$getRenderDataFrom = F3(
	function (model, columnDefs, data) {
		var r_drp = model.al * model.am;
		var l_drp = (_Utils_cmp(
			r_drp,
			$elm$core$List$length(data)) > -1) ? ($elm$core$List$length(data) - model.am) : r_drp;
		var drp = (l_drp < 0) ? 0 : l_drp;
		return A2(
			$elm$core$List$take,
			model.am,
			A2(
				$elm$core$List$drop,
				drp,
				A3($author$project$Grid$Grid$getSortedDataFrom, model, columnDefs, data)));
	});
var $mdgriffith$elm_ui$Element$scrollbars = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.mX);
var $author$project$Grid$Grid$gridBodyWithData = F2(
	function (config, model) {
		var renderData = A3($author$project$Grid$Grid$getRenderDataFrom, model, config.f1, config.he);
		var gridHeight = (config.fD.j7 - config.fD.gu) - config.fD.j6;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$indexedTable,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(gridHeight)),
							$mdgriffith$elm_ui$Element$clip,
							$mdgriffith$elm_ui$Element$scrollbars
						]),
					{
						kn: A2(
							$elm$core$List$map,
							A2($author$project$Grid$Grid$columnDefToColumn, config, model.bf),
							config.f1),
						ku: renderData
					})
				]));
	});
var $author$project$Grid$Grid$currentPageDisplay = F3(
	function (pageStart, pageEnd, filteredCount) {
		var ps = (_Utils_cmp(pageStart, filteredCount) > 0) ? filteredCount : pageStart;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
					$mdgriffith$elm_ui$Element$Font$size(14),
					$mdgriffith$elm_ui$Element$Font$semiBold,
					$author$project$PAM$UI$Basic$montserrat
				]),
			$mdgriffith$elm_ui$Element$text(
				$elm$core$String$fromInt(ps) + (' - ' + ($elm$core$String$fromInt(pageEnd) + (' of ' + $elm$core$String$fromInt(filteredCount))))));
	});
var $author$project$Grid$Grid$maxPageOf = F2(
	function (data, itemsPerPage) {
		var length = $elm$core$List$length(data) - 1;
		var max = $elm$core$Basics$floor(length / itemsPerPage);
		return max;
	});
var $author$project$Grid$Grid$GotoPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Grid$Grid$UpdatePage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Grid$Grid$pageNavigation = F4(
	function (pageMax, filteredCount, filteredMax, model) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
					$mdgriffith$elm_ui$Element$Font$size(14),
					$mdgriffith$elm_ui$Element$spacing(12)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('First page')
						]),
					{
						a8: $author$project$PAM$UI$Basic$centeredIcon('fa fa-step-backward'),
						b8: (model.al > 0) ? $elm$core$Maybe$Just(
							$author$project$Grid$Grid$GotoPage(0)) : $elm$core$Maybe$Nothing
					}),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('Previous page')
						]),
					{
						a8: $author$project$PAM$UI$Basic$centeredIcon('fa fa-caret-left'),
						b8: (model.al > 0) ? $elm$core$Maybe$Just(
							$author$project$Grid$Grid$UpdatePage(-1)) : $elm$core$Maybe$Nothing
					}),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('Next page')
						]),
					{
						a8: $author$project$PAM$UI$Basic$centeredIcon('fa fa-caret-right'),
						b8: (_Utils_cmp(pageMax, filteredCount) < 0) ? $elm$core$Maybe$Just(
							$author$project$Grid$Grid$UpdatePage(1)) : $elm$core$Maybe$Nothing
					}),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$title('Last page')
						]),
					{
						a8: $author$project$PAM$UI$Basic$centeredIcon('fa fa-step-forward'),
						b8: (_Utils_cmp(pageMax, filteredCount) < 0) ? $elm$core$Maybe$Just(
							$author$project$Grid$Grid$GotoPage(filteredMax)) : $elm$core$Maybe$Nothing
					})
				]));
	});
var $author$project$Grid$Grid$ClosePageSizeDropdownHack = {$: 7};
var $author$project$Grid$Grid$PageSizeChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$PAM$UI$Select$SelectUp = 1;
var $author$project$Grid$Grid$TogglePageSizeDropdown = {$: 5};
var $author$project$Grid$Grid$pageSizeSelector = F3(
	function (palette, config, _v0) {
		var itemsPerPageOpenness = _v0.a7;
		var itemsPerPage = _v0.am;
		var options = _List_fromArray(
			[10, 25, 50, 100]);
		var optionListConfig = A2(
			$author$project$PAM$UI$Select$defaultOptionListConfig,
			config,
			{
				r: $author$project$Grid$Grid$inputIds.eo,
				a8: 'Page Size Options',
				h1: $author$project$Grid$Grid$PageSizeChanged,
				l9: $author$project$Grid$Grid$ClosePageSizeDropdownHack,
				I: $elm$core$Maybe$Just(itemsPerPage)
			});
		var optionConfig = A2(
			$author$project$PAM$UI$Select$defaultOptionConfig,
			config.f5,
			{nG: $elm$core$String$fromInt});
		var labelConfig = {
			km: $elm$core$Maybe$Just('fa fa-caret-up'),
			a8: $elm$core$String$fromInt(itemsPerPage),
			mm: $elm$core$Maybe$Just('fa fa-times'),
			h7: _List_Nil
		};
		var buttonConfig = A2(
			$author$project$PAM$UI$Select$defaultButtonConfig,
			config.f5,
			{
				fb: $author$project$PAM$UI$Basic$inputHeight(config),
				r: $author$project$Grid$Grid$inputIds.h9,
				b8: $elm$core$Maybe$Just($author$project$Grid$Grid$TogglePageSizeDropdown),
				eJ: A2($mdgriffith$elm_ui$Element$minimum, 55, $mdgriffith$elm_ui$Element$shrink)
			});
		var selectConfig = A2(
			$author$project$PAM$UI$Select$defaultSelectConfig,
			config.f5,
			{kb: buttonConfig, kE: 1, lx: labelConfig, mn: optionConfig, mo: optionListConfig});
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, config.f5, 1)),
							$mdgriffith$elm_ui$Element$Font$semiBold,
							$author$project$PAM$UI$Basic$montserrat
						]),
					$mdgriffith$elm_ui$Element$text('Items Per Page')),
					A5($author$project$PAM$UI$Select$select, palette, 2, selectConfig, itemsPerPageOpenness, options)
				]));
	});
var $author$project$Grid$Grid$paginationControls = F4(
	function (palette, config, model, filteredData) {
		var totalCount = $elm$core$List$length(config.ku);
		var pageMax = (model.al + 1) * model.am;
		var p_start = (model.al * model.am) + 1;
		var page_start = (_Utils_cmp(
			p_start,
			$elm$core$List$length(filteredData)) > 0) ? ($elm$core$List$length(filteredData) - model.am) : p_start;
		var pageStart = (page_start < 1) ? 1 : page_start;
		var filteredMax = A2($author$project$Grid$Grid$maxPageOf, filteredData, model.am);
		var filteredCount = $elm$core$List$length(filteredData);
		var pageEnd = (_Utils_cmp(pageMax, filteredCount) < 1) ? pageMax : filteredCount;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A3($author$project$Grid$Grid$pageSizeSelector, palette, config, model),
					A3($author$project$Grid$Grid$currentPageDisplay, pageStart, pageEnd, filteredCount),
					A4($author$project$Grid$Grid$pageNavigation, pageMax, filteredCount, filteredMax, model)
				]));
	});
var $author$project$Grid$Grid$gridFooterWithData = F4(
	function (palette, config, model, filteredData) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignBottom,
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(config.fD.gu)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A4($author$project$Grid$Grid$paginationControls, palette, config, model, filteredData)
				]));
	});
var $author$project$Grid$Grid$gridView = F2(
	function (config, model) {
		var visibleColumns = A2(
			$elm$core$List$filter,
			function ($) {
				return $.eB;
			},
			config.gL);
		var filteredData = A3($author$project$Grid$Grid$filterColumns, model, config.gL, config.ku);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Grid$Grid$gridBodyWithData,
					{eV: config.eV, he: filteredData, bE: config.bE, fD: config.fD, f1: visibleColumns, f5: config.f5},
					model),
					A4($author$project$Grid$Grid$gridFooterWithData, config.bE, config, model, filteredData)
				]));
	});
var $author$project$PAM$objectGridView = F2(
	function (model, columnDefs) {
		var _v0 = model.aN.N;
		if (!_v0.$) {
			var activeType = _v0.a;
			var objects = A2(
				$author$project$PAM$Object$Repo$filterObjectsBy,
				F2(
					function (_v1, object) {
						return _Utils_eq(activeType.r, object.bi.r);
					}),
				model.b);
			var reversedObjects = A2($elm$core$List$sortWith, $author$project$PAM$descending, objects);
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$PAM$Message$GridMsg,
					A2(
						$author$project$Grid$Grid$gridView,
						{gL: columnDefs, ku: reversedObjects, eV: model.eV, bE: model.bE.bl, fD: model.fD, f5: model.f5},
						model.aN.aM)));
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$PAM$versionView = F2(
	function (env, version) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(env.bE.cx.fK.eE),
					$mdgriffith$elm_ui$Element$Font$size(12),
					$mdgriffith$elm_ui$Element$Font$bold,
					$mdgriffith$elm_ui$Element$alignRight
				]),
			$mdgriffith$elm_ui$Element$text('v ' + version));
	});
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $author$project$InPageOAuth$codeGetter = F2(
	function (model, state) {
		var _v0 = model.bn;
		if (!_v0.$) {
			var config = _v0.a;
			var auth = {
				a0: config.a0,
				aU: config.aU,
				cg: config.cg,
				cj: $elm$core$Maybe$Just('PAM.init'),
				c8: config.eM
			};
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Background$color(
						A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.5)),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2(
							$elm$html$Html$Attributes$style,
							'opacity',
							_Utils_eq(state, $author$project$InPageOAuth$NotStarted) ? '0' : '1'))
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'opacity', '0')),
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$px(0)),
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(0)),
								$mdgriffith$elm_ui$Element$behindContent(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$centerY,
												$mdgriffith$elm_ui$Element$Font$color(
												A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255))
											]),
										$mdgriffith$elm_ui$Element$text('Waiting for server reply'))))
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							$mdgriffith$elm_ui$Element$html(
								A2(
									$elm$html$Html$iframe,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											$elm$url$Url$toString(
												$truqu$elm_oauth2$OAuth$AuthorizationCode$makeAuthUrl(auth))),
											A2($elm$html$Html$Attributes$style, 'width', '100%'),
											A2($elm$html$Html$Attributes$style, 'height', '100%'),
											$elm$html$Html$Attributes$id('authFrame')
										]),
									_List_Nil)))),
						_Utils_eq(state, $author$project$InPageOAuth$Pending) ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$centerY,
								$mdgriffith$elm_ui$Element$Font$color(
								A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255))
							]),
						$mdgriffith$elm_ui$Element$text('Logging you in ...')) : $mdgriffith$elm_ui$Element$none
					]));
		} else {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_Nil,
				$mdgriffith$elm_ui$Element$text('No configuration provided'));
		}
	});
var $author$project$InPageOAuth$modal = function (els) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Background$color(
				A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.5)),
				$mdgriffith$elm_ui$Element$spacing(10),
				$mdgriffith$elm_ui$Element$padding(25)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
						$mdgriffith$elm_ui$Element$Border$rounded(4),
						$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
						$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$white),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$spacing(15),
						$mdgriffith$elm_ui$Element$padding(25),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(350))
					]),
				els)
			]));
};
var $author$project$InPageOAuth$errorView = F2(
	function (model, errorMsg) {
		return $author$project$InPageOAuth$modal(
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$Font$bold]),
					$mdgriffith$elm_ui$Element$text('Error Authenticating')),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$Font$center]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(errorMsg)
						]))
				]));
	});
var $author$project$InPageOAuth$gettingACLs = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$Background$color(
			A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.5))
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$Font$color(
					A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255))
				]),
			$mdgriffith$elm_ui$Element$text('Mapping permissions'))
		]));
var $author$project$InPageOAuth$gettingToken = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$Background$color(
			A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.5))
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$Font$color(
					A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255))
				]),
			$mdgriffith$elm_ui$Element$text('Getting token'))
		]));
var $author$project$InPageOAuth$gettingUserInfo = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$Background$color(
			A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.5))
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$Font$color(
					A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255))
				]),
			$mdgriffith$elm_ui$Element$text('Retreiving profile'))
		]));
var $author$project$InPageOAuth$OpenLogin = {$: 7};
var $author$project$InPageOAuth$unauthorized = function (model) {
	return $author$project$InPageOAuth$modal(
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$spacing(5),
						$mdgriffith$elm_ui$Element$Font$center,
						$mdgriffith$elm_ui$Element$Font$size(18)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('You are currently logged out.')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(
								'Please log in to access the ' + (A2(
									$elm$core$Maybe$withDefault,
									'application',
									A2(
										$elm$core$Maybe$map,
										function (a) {
											return a.fa.F.F;
										},
										model.bR)) + '.'))
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$orangeRoughy),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(25)),
						A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
						$mdgriffith$elm_ui$Element$Border$rounded(16),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(100)),
						$mdgriffith$elm_ui$Element$Font$bold,
						$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
						$mdgriffith$elm_ui$Element$centerX
					]),
				{
					a8: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						$mdgriffith$elm_ui$Element$text('Login')),
					b8: $elm$core$Maybe$Just(
						$author$project$InPageOAuth$Internal($author$project$InPageOAuth$OpenLogin))
				})
			]));
};
var $author$project$InPageOAuth$view = function (model) {
	var _v0 = model.cj;
	switch (_v0.$) {
		case 0:
			return A2($author$project$InPageOAuth$codeGetter, model, model.cj);
		case 1:
			return A2($author$project$InPageOAuth$codeGetter, model, model.cj);
		case 2:
			return $author$project$InPageOAuth$unauthorized(model);
		case 3:
			return $mdgriffith$elm_ui$Element$none;
		case 4:
			return $author$project$InPageOAuth$gettingToken;
		case 5:
			return $author$project$InPageOAuth$gettingUserInfo;
		case 6:
			return $author$project$InPageOAuth$gettingACLs;
		default:
			var err = _v0.a;
			return A2($author$project$InPageOAuth$errorView, model, err);
	}
};
var $author$project$PAM$Header$hasAnyRequiredRoles = F2(
	function (profile, link) {
		if ($elm$core$List$isEmpty(link.cf)) {
			return true;
		} else {
			var part = function (itm) {
				return A2($elm$core$List$member, itm, link.cf);
			};
			var _v0 = A2($elm$core$List$partition, part, profile.iz);
			var match = _v0.a;
			var nomatch = _v0.b;
			return A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty)(match);
		}
	});
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $mdgriffith$elm_ui$Element$newTabLink = F2(
	function (attrs, _v0) {
		var url = _v0.c8;
		var label = _v0.a8;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Attributes$target('_blank')),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dF + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.a2)),
									attrs)))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $author$project$PAM$Header$logoAndTitleView = F2(
	function (env, config) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$moveRight(32),
					A2($mdgriffith$elm_ui$Element$spacingXY, 24, 0),
					$mdgriffith$elm_ui$Element$centerY
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$newTabLink,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(50)),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(50)),
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$padding(0)
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$image,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(50)),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(50)),
									$mdgriffith$elm_ui$Element$centerY
								]),
							{kD: config.fn.jM, ne: config.fn.ne}),
						c8: config.fn.fd
					}),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$spacingXY, 0, 6),
							$author$project$PAM$UI$Basic$montserrat,
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1))
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color(env.bE.cx.mB.eE),
									$mdgriffith$elm_ui$Element$Font$bold
								]),
							$mdgriffith$elm_ui$Element$text(config.F.F)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color(env.bE.cx.fW.eE)
								]),
							$mdgriffith$elm_ui$Element$text(config.F.fT))
						]))
				]));
	});
var $author$project$PAM$Header$CloseUserMenu = 3;
var $author$project$PAM$Header$Internal = $elm$core$Basics$identity;
var $author$project$PAM$Header$OpenUserMenu = 2;
var $author$project$PAM$Header$ToggleUserMenu = 1;
var $mdgriffith$elm_ui$Internal$Model$MoveY = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$moveY = $mdgriffith$elm_ui$Internal$Flag$flag(26);
var $mdgriffith$elm_ui$Element$moveUp = function (y) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveY,
		$mdgriffith$elm_ui$Internal$Model$MoveY(-y));
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$PAM$Header$userMenuDropdownView = F4(
	function (env, model, profile, linkGroups) {
		var menuLinkDivider = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(1)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Background$color(env.bE.S.mB.eE)
					]),
				$mdgriffith$elm_ui$Element$none));
		var menuLink = function (l) {
			return A2(
				$mdgriffith$elm_ui$Element$link,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(
								A2($author$project$PAM$UI$Basic$darkenColor, 0.1, env.bE.S.mB.G))
							])),
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				{
					a8: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_Nil,
						$mdgriffith$elm_ui$Element$text(l.fu)),
					c8: l.S
				});
		};
		var menuLinkGroup = function (group) {
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$elm$core$List$map,
					menuLink,
					A2(
						$elm$core$List$filter,
						$author$project$PAM$Header$hasAnyRequiredRoles(profile),
						group)));
		};
		var _v0 = model.c9;
		if (!_v0) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$onLeft(
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color(env.bE.S.mB.G),
									$mdgriffith$elm_ui$Element$paddingEach(
									_Utils_update(
										$author$project$PAM$UI$Basic$edges,
										{cz: 6, c3: 6})),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Border$roundEach(
									_Utils_update(
										$author$project$PAM$UI$Basic$corners,
										{gv: 6, i2: 6})),
									$mdgriffith$elm_ui$Element$moveUp(6)
								]),
							A2(
								$elm$core$List$intersperse,
								menuLinkDivider,
								A2($elm$core$List$map, menuLinkGroup, linkGroups)))),
						$mdgriffith$elm_ui$Element$alignRight
					]),
				$mdgriffith$elm_ui$Element$none);
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$PAM$Header$userMenuView = F4(
	function (env, model, config, headerLinks) {
		var _v0 = env.aq;
		if (_v0.$ === 3) {
			var profile = _v0.a.mC;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$onLeft(headerLinks),
						$mdgriffith$elm_ui$Element$alignRight,
						$mdgriffith$elm_ui$Element$alignTop
					]),
				A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$montserrat,
							$mdgriffith$elm_ui$Element$Font$bold,
							$mdgriffith$elm_ui$Element$Font$color(env.bE.S.mB.eE),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
							$mdgriffith$elm_ui$Element$Border$roundEach(
							_Utils_update(
								$author$project$PAM$UI$Basic$corners,
								{gv: 6})),
							$mdgriffith$elm_ui$Element$Background$color(env.bE.S.mB.G),
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 8),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$below(
							A4($author$project$PAM$Header$userMenuDropdownView, env, model, profile, config.f$)),
							$mdgriffith$elm_ui$Element$Events$onMouseEnter(2),
							$mdgriffith$elm_ui$Element$Events$onMouseLeave(3),
							A2($author$project$PAM$UI$Basic$style, 'z-index', '40590')
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$spacingXY, 10, 0)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Hi, ' + profile.fu),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$moveUp(2)
										]),
									$author$project$PAM$UI$Basic$centeredIcon('fa fa-chevron-down'))
								])),
						b8: $elm$core$Maybe$Just(1)
					}));
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$PAM$Header$view = F5(
	function (env, headerElements, config, model, trans) {
		var prof = function () {
			var _v0 = env.aq;
			if (_v0.$ === 3) {
				var profile = _v0.a.mC;
				return {iz: profile.iz};
			} else {
				return {iz: _List_Nil};
			}
		}();
		var headerLink = function (l) {
			return A2(
				$mdgriffith$elm_ui$Element$link,
				_List_fromArray(
					[
						$author$project$PAM$UI$Basic$montserrat,
						$mdgriffith$elm_ui$Element$Font$center,
						$mdgriffith$elm_ui$Element$Border$solid,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						_Utils_update(
							$author$project$PAM$UI$Basic$edges,
							{cz: 2})),
						$mdgriffith$elm_ui$Element$Border$color($author$project$PAM$UI$Basic$transparent0),
						$mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Border$color(env.bE.S.mB.G)
							]))
					]),
				{
					a8: $mdgriffith$elm_ui$Element$text(l.fu),
					c8: l.S
				});
		};
		var headerLinks = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(env.fD.hO)),
					A2($mdgriffith$elm_ui$Element$paddingXY, 64, 0),
					$mdgriffith$elm_ui$Element$centerY
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$montserrat,
							$mdgriffith$elm_ui$Element$Font$color(env.bE.cx.mB.eE),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
							$mdgriffith$elm_ui$Element$Font$bold,
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$spacing(28)
						]),
					A2(
						$elm$core$List$map,
						headerLink,
						A2(
							$elm$core$List$filter,
							$author$project$PAM$Header$hasAnyRequiredRoles(prof),
							config.dY)))
				]));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(env.fD.hO)),
							$mdgriffith$elm_ui$Element$Background$color(env.bE.cx.mB.G),
							$mdgriffith$elm_ui$Element$inFront(
							A2(
								$mdgriffith$elm_ui$Element$map,
								trans,
								A2($author$project$PAM$Header$logoAndTitleView, env, config))),
							$mdgriffith$elm_ui$Element$inFront(
							A2(
								$mdgriffith$elm_ui$Element$map,
								trans,
								A4($author$project$PAM$Header$userMenuView, env, model, config, headerLinks)))
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(env.fD.nl)),
							$mdgriffith$elm_ui$Element$Background$color(env.bE.cx.fK.G),
							$mdgriffith$elm_ui$Element$paddingEach(
							{cz: 0, cR: 5, cX: 5, c3: 0})
						]),
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$centerY,
								$mdgriffith$elm_ui$Element$paddingEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$edges,
									{cR: 15, cX: 15}))
							]),
						headerElements))
				]));
	});
var $author$project$PAM$Map$ToggleStaticLegend = {$: 15};
var $mdgriffith$elm_ui$Element$moveDown = function (y) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveY,
		$mdgriffith$elm_ui$Internal$Model$MoveY(y));
};
var $mdgriffith$elm_ui$Element$moveLeft = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveX,
		$mdgriffith$elm_ui$Internal$Model$MoveX(-x));
};
var $author$project$PAM$UI$Basic$transparent25 = $author$project$PAM$UI$Basic$transparent(0.25);
var $author$project$PAM$Map$staticLegend = F2(
	function (env, openness) {
		var legendImg = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$moveLeft(5),
					$mdgriffith$elm_ui$Element$moveUp(2),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$color(
					A2($author$project$PAM$UI$Basic$fadeColor, 0.4, $author$project$PAM$UI$Basic$white)),
					$mdgriffith$elm_ui$Element$Border$rounded(4),
					$mdgriffith$elm_ui$Element$mouseOver(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$color(
							A2($author$project$PAM$UI$Basic$fadeColor, 0.6, $author$project$PAM$UI$Basic$white)),
							$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent25)
						]))
				]),
			A2(
				$mdgriffith$elm_ui$Element$image,
				_List_Nil,
				{kD: '\n                            Focus areas are orange. \n                            \n                            Projects and Actions can be in one of four phases: Planning, In Progress, On Hold, or Complete.\n\n                            Projects in Planning are very light blue. \n                            Projects In Progress are light blue. \n                            Projects On Hold are blue.\n                            Projects that are Complete are deep blue.\n\n                            Actions in Planning are very light purple.\n                            Actions In Progress are light purple.\n                            Actions On Hold are purple.\n                            Actions that are Complete are deep purple.                    \n                            ', ne: '/LegendHorizontal.png'}));
		var legendBtn = A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$color(
					A2($author$project$PAM$UI$Basic$fadeColor, 0.4, $author$project$PAM$UI$Basic$white)),
					$mdgriffith$elm_ui$Element$Border$rounded(4),
					$mdgriffith$elm_ui$Element$Background$color(
					A2($author$project$PAM$UI$Basic$fadeColor, 0.4, $author$project$PAM$UI$Basic$white)),
					$mdgriffith$elm_ui$Element$mouseOver(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$color(
							A2($author$project$PAM$UI$Basic$fadeColor, 0.6, $author$project$PAM$UI$Basic$white)),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$PAM$UI$Basic$fadeColor, 0.6, $author$project$PAM$UI$Basic$white))
						])),
					$author$project$PAM$UI$Basic$title('Map Legend'),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$onLeft(
					function () {
						if (!openness) {
							return legendImg;
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}())
				]),
			{
				a8: A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1)),
							$author$project$PAM$UI$Basic$montserrat,
							A2($mdgriffith$elm_ui$Element$spacingXY, 8, 0),
							$mdgriffith$elm_ui$Element$padding(6),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$PAM$UI$Basic$fadeColor, 0.5, $author$project$PAM$UI$Basic$asparagus)),
							$mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color(
									A2($author$project$PAM$UI$Basic$fadeColor, 0.7, $author$project$PAM$UI$Basic$asparagus))
								])),
							$mdgriffith$elm_ui$Element$Border$rounded(4)
						]),
					_List_fromArray(
						[
							$author$project$PAM$UI$Basic$icon('fa fa-map-o'),
							$mdgriffith$elm_ui$Element$text('Legend')
						])),
				b8: $elm$core$Maybe$Just(
					$author$project$PAM$Map$Internal($author$project$PAM$Map$ToggleStaticLegend))
			});
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignRight,
					$mdgriffith$elm_ui$Element$moveDown(70),
					$mdgriffith$elm_ui$Element$moveLeft(8)
				]),
			legendBtn);
	});
var $author$project$PlugMap$Core$MouseLeaveMap = {$: 1};
var $author$project$PlugMap$Core$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$author$project$PAM$UI$Basic$id('ol-map'),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Events$onMouseLeave($author$project$PlugMap$Core$MouseLeaveMap)
			]),
		_List_Nil);
};
var $author$project$PlugMap$Plugins$Identify$view = function (model) {
	return $mdgriffith$elm_ui$Element$none;
};
var $author$project$PAM$Map$view = F2(
	function (env, model) {
		var appLegendType = function () {
			var _v1 = env.bR.M.nf;
			if (!_v1.$) {
				var val = _v1.a;
				return val;
			} else {
				return '';
			}
		}();
		var _v0 = model.cj;
		switch (_v0) {
			case 1:
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$inFront(
								(appLegendType === 'hide') ? $mdgriffith$elm_ui$Element$none : A2($author$project$PAM$Map$staticLegend, env, model.c$))
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$map,
								$author$project$PAM$Map$Internal,
								A2(
									$mdgriffith$elm_ui$Element$map,
									$author$project$PAM$Map$MapMessage,
									$author$project$PlugMap$Core$view(model.h))),
								A2(
								$mdgriffith$elm_ui$Element$map,
								$author$project$PAM$Map$identifyTranslator,
								$author$project$PlugMap$Plugins$Identify$view(model.aO))
							])));
			case 0:
				return A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$Font$center,
							$mdgriffith$elm_ui$Element$padding(10)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Just a moment')
						]));
			default:
				return A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('The map encountered an error. Never fear, this is only development')
						]));
		}
	});
var $author$project$PAM$Modal$confirmModalView = F3(
	function (palette, env, confirmation) {
		var minWidth = 420;
		var minHeight = 160;
		var maxWidth = 420;
		var maxHeight = (env.f5.fb / 2) | 0;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent25),
					A2($author$project$PAM$UI$Basic$style, 'z-index', '1001'),
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1))
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						A2(
							$mdgriffith$elm_ui$Element$maximum,
							maxHeight,
							A2($mdgriffith$elm_ui$Element$minimum, minHeight, $mdgriffith$elm_ui$Element$shrink))),
						$mdgriffith$elm_ui$Element$width(
						A2(
							$mdgriffith$elm_ui$Element$maximum,
							maxWidth,
							A2($mdgriffith$elm_ui$Element$minimum, minWidth, $mdgriffith$elm_ui$Element$shrink))),
						$mdgriffith$elm_ui$Element$Border$rounded(6),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$author$project$PAM$UI$Basic$montserrat
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(46)),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Font$size(
								A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 3)),
								$mdgriffith$elm_ui$Element$Font$color(palette.cx.mB.eE),
								$mdgriffith$elm_ui$Element$Background$color(palette.cx.mB.G),
								$mdgriffith$elm_ui$Element$Border$roundEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$corners,
									{i2: 6, i3: 6})),
								A2($mdgriffith$elm_ui$Element$paddingXY, 15, 0)
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(confirmation.F)
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$padding(25)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$textColumn,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
										$mdgriffith$elm_ui$Element$Font$size(
										A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 2)),
										$author$project$PAM$UI$Basic$helvetica,
										$mdgriffith$elm_ui$Element$spacing(5),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(confirmation.ee)
											]))
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(54)),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$Border$roundEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$corners,
									{gv: 6, gw: 6}))
							]),
						function () {
							var _v0 = confirmation.bn;
							var cfg = _v0;
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									A2(
										$mdgriffith$elm_ui$Element$Input$button,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
												$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE),
												$mdgriffith$elm_ui$Element$height(
												$author$project$PAM$UI$Basic$inputHeight(env)),
												$mdgriffith$elm_ui$Element$width(
												A2($mdgriffith$elm_ui$Element$minimum, 160, $mdgriffith$elm_ui$Element$shrink)),
												A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
												$mdgriffith$elm_ui$Element$Border$rounded(16),
												$mdgriffith$elm_ui$Element$centerX
											]),
										{
											a8: A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$centerX]),
												$mdgriffith$elm_ui$Element$text(cfg.dy)),
											b8: $elm$core$Maybe$Just(cfg.bU)
										})),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									A2(
										$mdgriffith$elm_ui$Element$Input$button,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color(palette.S.fK.G),
												$mdgriffith$elm_ui$Element$Font$color(palette.S.fK.eE),
												$mdgriffith$elm_ui$Element$height(
												$author$project$PAM$UI$Basic$inputHeight(env)),
												$mdgriffith$elm_ui$Element$width(
												A2($mdgriffith$elm_ui$Element$minimum, 160, $mdgriffith$elm_ui$Element$shrink)),
												A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
												$mdgriffith$elm_ui$Element$Border$rounded(16),
												$mdgriffith$elm_ui$Element$centerX
											]),
										{
											a8: A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$centerX]),
												$mdgriffith$elm_ui$Element$text(cfg.ej)),
											b8: $elm$core$Maybe$Just(cfg.ek)
										}))
								]);
						}())
					])));
	});
var $author$project$PAM$Message$CloseLinkExistingDropdown = {$: 2};
var $author$project$PAM$Message$LinkExistingChange = function (a) {
	return {$: 4, a: a};
};
var $author$project$PAM$Message$ToggleLinkExistingDropdown = {$: 3};
var $author$project$PAM$Modal$linkExistingSelectView = F6(
	function (palette, env, openness, childObjType, maybeSelected, linkableObjects) {
		var optionConfig = {
			kX: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					A2($author$project$PAM$UI$Basic$fadeColor, 0.3, $author$project$PAM$UI$Basic$doveGray))
				]),
			fb: $mdgriffith$elm_ui$Element$fill,
			ld: _List_Nil,
			lR: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					A2($author$project$PAM$UI$Basic$fadeColor, 0.3, $author$project$PAM$UI$Basic$doveGray))
				]),
			h7: _List_Nil,
			fC: A4($author$project$PAM$UI$Basic$Edges, 8, 6, 8, 6),
			m$: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$asparagus),
					$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
				]),
			nG: function ($) {
				return $.fu;
			},
			eJ: $mdgriffith$elm_ui$Element$fill
		};
		var maxHeight = ((env.fD.j7 - env.fD.gu) - env.fD.j6) - 10;
		var optionListConfig = {
			gp: $author$project$PAM$UI$Basic$white,
			kY: $author$project$PAM$UI$Basic$doveGray,
			hh: A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1),
			fb: A2($mdgriffith$elm_ui$Element$maximum, maxHeight, $mdgriffith$elm_ui$Element$shrink),
			r: 'link_existing_options',
			a8: 'Link Options',
			h1: A2($elm$core$Basics$composeL, $author$project$PAM$Message$LinkExistingObjectMsg, $author$project$PAM$Message$LinkExistingChange),
			l9: $author$project$PAM$Message$LinkExistingObjectMsg($author$project$PAM$Message$CloseLinkExistingDropdown),
			mp: _List_Nil,
			mq: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Border$color($author$project$PAM$UI$Basic$doveGray)
				]),
			fC: _Utils_update(
				$author$project$PAM$UI$Basic$edges,
				{cz: 4, c3: 4}),
			I: maybeSelected
		};
		var labelConfig = {
			km: $elm$core$Maybe$Just('fa fa-caret-down'),
			a8: A2(
				$elm$core$Maybe$withDefault,
				'Select ' + (childObjType.cZ + ' to Link'),
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.fu;
					},
					maybeSelected)),
			mm: $elm$core$Maybe$Just('fa fa-times'),
			h7: _List_Nil
		};
		var buttonConfig = {
			gp: $author$project$PAM$UI$Basic$white,
			kY: $author$project$PAM$UI$Basic$doveGray,
			hh: A2($author$project$PAM$UI$Basic$scaleFont, env.f5, -1),
			fb: $author$project$PAM$UI$Basic$inputHeight(env),
			r: 'link_existing_btn',
			b8: $elm$core$Maybe$Just(
				$author$project$PAM$Message$LinkExistingObjectMsg($author$project$PAM$Message$ToggleLinkExistingDropdown)),
			h7: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$color($author$project$PAM$UI$Basic$doveGray),
					$mdgriffith$elm_ui$Element$Border$width(1)
				]),
			fC: _Utils_update(
				$author$project$PAM$UI$Basic$edges,
				{cR: 6, cX: 6}),
			eJ: $author$project$PAM$UI$Basic$is1080p(env.f5) ? A2($mdgriffith$elm_ui$Element$minimum, 256, $mdgriffith$elm_ui$Element$fill) : A2($mdgriffith$elm_ui$Element$minimum, 220, $mdgriffith$elm_ui$Element$fill)
		};
		var selectConfig = {
			j4: 3,
			kb: buttonConfig,
			kE: 0,
			lx: labelConfig,
			mn: optionConfig,
			mo: optionListConfig,
			h7: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				])
		};
		return A5($author$project$PAM$UI$Select$select, palette, 2, selectConfig, openness, linkableObjects);
	});
var $author$project$PAM$Modal$linkModalView = F3(
	function (palette, env, config) {
		var minWidth = 420;
		var minHeight = 160;
		var maxWidth = 420;
		var maxHeight = (env.f5.fb / 2) | 0;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent25),
					A2($author$project$PAM$UI$Basic$style, 'z-index', '1001'),
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1))
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						A2(
							$mdgriffith$elm_ui$Element$maximum,
							maxHeight,
							A2($mdgriffith$elm_ui$Element$minimum, minHeight, $mdgriffith$elm_ui$Element$shrink))),
						$mdgriffith$elm_ui$Element$width(
						A2(
							$mdgriffith$elm_ui$Element$maximum,
							maxWidth,
							A2($mdgriffith$elm_ui$Element$minimum, minWidth, $mdgriffith$elm_ui$Element$shrink))),
						$mdgriffith$elm_ui$Element$Border$rounded(6),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$author$project$PAM$UI$Basic$montserrat
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(46)),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Font$size(
								A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 3)),
								$mdgriffith$elm_ui$Element$Font$color(palette.cx.mB.eE),
								$mdgriffith$elm_ui$Element$Background$color(palette.cx.mB.G),
								$mdgriffith$elm_ui$Element$Border$roundEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$corners,
									{i2: 6, i3: 6})),
								A2($mdgriffith$elm_ui$Element$paddingXY, 15, 0)
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Link ' + config.gF.cZ)
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$padding(25)
							]),
						function () {
							var _v0 = config.gG;
							if (!_v0.$) {
								if (_v0.a.b) {
									var _v1 = _v0.a;
									var head = _v1.a;
									var tail = _v1.b;
									var sortedObjects = A2(
										$elm$core$List$sortBy,
										A2(
											$elm$core$Basics$composeL,
											$elm$core$String$toLower,
											function ($) {
												return $.fu;
											}),
										A2($elm$core$List$cons, head, tail));
									return _List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$centerX,
													$mdgriffith$elm_ui$Element$alignTop,
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											A6($author$project$PAM$Modal$linkExistingSelectView, palette, env, config.bm, config.gF, config.fM, sortedObjects))
										]);
								} else {
									return _List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$textColumn,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
													$mdgriffith$elm_ui$Element$Font$size(
													A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 2)),
													$author$project$PAM$UI$Basic$helvetica,
													$mdgriffith$elm_ui$Element$spacing(5),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$paragraph,
													_List_Nil,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$text('Loading...')
														]))
												]))
										]);
								}
							} else {
								var error = _v0.a;
								return _List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$textColumn,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$black),
												$mdgriffith$elm_ui$Element$Font$size(
												A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 2)),
												$author$project$PAM$UI$Basic$helvetica,
												$mdgriffith$elm_ui$Element$spacing(5),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_Nil,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text(
														A2($author$project$PAM$Helpers$httpErrorMsg, 'Error loading available ' + config.gF.ie, error))
													]))
											]))
									]);
							}
						}()),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(54)),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
								$mdgriffith$elm_ui$Element$Border$roundEach(
								_Utils_update(
									$author$project$PAM$UI$Basic$corners,
									{gv: 6, gw: 6}))
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color(palette.S.mB.G),
											$mdgriffith$elm_ui$Element$Font$color(palette.S.mB.eE),
											$mdgriffith$elm_ui$Element$height(
											$author$project$PAM$UI$Basic$inputHeight(env)),
											$mdgriffith$elm_ui$Element$width(
											A2($mdgriffith$elm_ui$Element$minimum, 160, $mdgriffith$elm_ui$Element$shrink)),
											A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
											$mdgriffith$elm_ui$Element$Border$rounded(16),
											$mdgriffith$elm_ui$Element$centerX
										]),
									{
										a8: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text(config.gA)),
										b8: config.bU
									})),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color(palette.S.fK.G),
											$mdgriffith$elm_ui$Element$Font$color(palette.S.fK.eE),
											$mdgriffith$elm_ui$Element$height(
											$author$project$PAM$UI$Basic$inputHeight(env)),
											$mdgriffith$elm_ui$Element$width(
											A2($mdgriffith$elm_ui$Element$minimum, 160, $mdgriffith$elm_ui$Element$shrink)),
											A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
											$mdgriffith$elm_ui$Element$Border$rounded(16),
											$mdgriffith$elm_ui$Element$centerX
										]),
									{
										a8: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text(config.hL)),
										b8: config.fm
									}))
							]))
					])));
	});
var $author$project$PAM$Modal$view = F3(
	function (palette, env, modalType) {
		switch (modalType.$) {
			case 1:
				var confirmation = modalType.a;
				return A3($author$project$PAM$Modal$confirmModalView, palette, env, confirmation);
			case 2:
				var linkExisting = modalType.a;
				return A3($author$project$PAM$Modal$linkModalView, palette, env, linkExisting);
			default:
				return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$PAM$UI$BottomDrawer$toggleView = F4(
	function (palette, windowSize, msg, fx) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$size(
					A2($author$project$PAM$UI$Basic$scaleFont, windowSize, 1)),
					A2($mdgriffith$elm_ui$Element$paddingXY, 20, 0),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$alignRight,
					$author$project$PAM$UI$Basic$title('Toggle Grid')
				]),
			{
				a8: A2($author$project$PAM$UI$Basic$centeredIconWith, 'fa fa-chevron-down', fx),
				b8: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$PAM$UI$BottomDrawer$view = F6(
	function (palette, config, toggleMsg, headerViews, childViews, legendView) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			A2(
				$author$project$PAM$UI$Basic$renderElAnimation,
				config.aI,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(config.fD.j7)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$alignBottom,
						$mdgriffith$elm_ui$Element$Background$color(palette.cx.mB.G),
						$mdgriffith$elm_ui$Element$Font$color(palette.cx.mB.eE),
						$mdgriffith$elm_ui$Element$above(
						A2(
							$mdgriffith$elm_ui$Element$row,
							A2(
								$author$project$PAM$UI$Basic$renderElAnimation,
								config.aV,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(23)),
										$mdgriffith$elm_ui$Element$Background$color(
										A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.8))
									])),
							_List_fromArray(
								[legendView])))
					])),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$htmlAttribute(
							A2($elm$html$Html$Attributes$style, 'z-index', '40'))
						]),
					$elm$core$List$reverse(
						A2(
							$elm$core$List$cons,
							A4($author$project$PAM$UI$BottomDrawer$toggleView, palette, config.f5, toggleMsg, config.aJ),
							headerViews))),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					childViews)
				]));
	});
var $author$project$PAM$UI$DateRangeFilter$ClickApplyButton = {$: 1};
var $author$project$PAM$UI$DateRangeFilter$Internal = function (a) {
	return {$: 0, a: a};
};
var $author$project$PAM$UI$DateRangeFilter$view = F2(
	function (model, env) {
		var l = 1;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(7),
					A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0),
					$mdgriffith$elm_ui$Element$height(
					$author$project$PAM$UI$Basic$inputHeight(env))
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$Font$color(env.bE.S.iI.G)
						]),
					$mdgriffith$elm_ui$Element$text('Date Range:')),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							A2($author$project$PAM$UI$Basic$style, 'align-self:', 'center'),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(125)),
							$mdgriffith$elm_ui$Element$Font$color(env.bE.S.iI.G),
							A2($author$project$PAM$UI$Basic$style, 'z-index', '21')
						]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						A2($elm$core$Basics$composeR, $author$project$PAM$UI$DateRangeFilter$PickerMsg, $author$project$PAM$UI$DateRangeFilter$Internal),
						A3(
							$author$project$PAM$UI$DatePicker$view,
							env,
							$author$project$PAM$UI$DateRangeFilter$pickerSettings(false),
							model.kw))),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$Font$color(env.bE.S.iI.G)
						]),
					$mdgriffith$elm_ui$Element$text('to')),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(125)),
							$mdgriffith$elm_ui$Element$Font$color(env.bE.S.iI.G),
							A2($author$project$PAM$UI$Basic$style, 'z-index', '21')
						]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						A2($elm$core$Basics$composeR, $author$project$PAM$UI$DateRangeFilter$ToPickerMsg, $author$project$PAM$UI$DateRangeFilter$Internal),
						A3(
							$author$project$PAM$UI$DatePicker$view,
							env,
							$author$project$PAM$UI$DateRangeFilter$toPickerSettings(false),
							model.nH))),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(env.bE.S.mB.G),
							$mdgriffith$elm_ui$Element$height(
							$author$project$PAM$UI$Basic$inputHeight(env)),
							A2($mdgriffith$elm_ui$Element$paddingXY, 15, 0),
							$mdgriffith$elm_ui$Element$Border$rounded(15)
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color(env.bE.S.mB.eE),
									$mdgriffith$elm_ui$Element$Font$size(14),
									$mdgriffith$elm_ui$Element$Font$bold,
									$author$project$PAM$UI$Basic$montserrat,
									$mdgriffith$elm_ui$Element$centerY
								]),
							$mdgriffith$elm_ui$Element$text('Apply')),
						b8: $elm$core$Maybe$Just($author$project$PAM$UI$DateRangeFilter$ClickApplyButton)
					}),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(env.bE.S.mB.G),
							$mdgriffith$elm_ui$Element$height(
							$author$project$PAM$UI$Basic$inputHeight(env)),
							A2($mdgriffith$elm_ui$Element$paddingXY, 15, 0),
							$mdgriffith$elm_ui$Element$Border$rounded(15)
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color(env.bE.S.mB.eE),
									$mdgriffith$elm_ui$Element$Font$size(14),
									$mdgriffith$elm_ui$Element$Font$bold,
									$author$project$PAM$UI$Basic$montserrat,
									$mdgriffith$elm_ui$Element$centerY
								]),
							$mdgriffith$elm_ui$Element$text('Clear')),
						b8: $elm$core$Maybe$Just($author$project$PAM$UI$DateRangeFilter$ClickClearButton)
					})
				]));
	});
var $author$project$PAM$UI$Search$EnterWasPressed = {$: 5};
var $author$project$PAM$UI$Search$Internal = function (a) {
	return {$: 1, a: a};
};
var $author$project$PAM$UI$Search$SearchClicked = {$: 0};
var $author$project$PAM$UI$Search$ClickClearSearchButton = {$: 4};
var $author$project$PAM$UI$Search$clearIconView = F2(
	function (model, env) {
		return ($elm$core$String$length(model.bd) > 0) ? A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$clip,
					$mdgriffith$elm_ui$Element$centerY
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							_Utils_update(
								$author$project$PAM$UI$Basic$edges,
								{cR: 10}))
						]),
					{
						a8: A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Font$color(
									A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.75))
								]),
							_List_fromArray(
								[
									$author$project$PAM$UI$Basic$centeredIcon('fa fa-times')
								])),
						b8: $elm$core$Maybe$Just($author$project$PAM$UI$Search$ClickClearSearchButton)
					})
				])) : A2(
			$mdgriffith$elm_ui$Element$el,
			_List_Nil,
			$mdgriffith$elm_ui$Element$text(''));
	});
var $author$project$PAM$UI$Search$onEnter = function (msg) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Events$on,
			'keyup',
			A2(
				$elm$json$Json$Decode$andThen,
				function (key) {
					return (key === 'Enter') ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
				},
				A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string))));
};
var $author$project$PAM$UI$Search$DismissSuggestions = {$: 2};
var $author$project$PAM$UI$Search$SuggestionSelected = function (a) {
	return {$: 3, a: a};
};
var $author$project$PAM$UI$Search$suggestionView = F3(
	function (model, env, sv) {
		var suggestions = function () {
			var _v1 = model.bg;
			switch (_v1.$) {
				case 1:
					var s = _v1.a;
					return s;
				case 2:
					var s = _v1.a;
					return s;
				default:
					return _List_Nil;
			}
		}();
		return (!model.k5) ? A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$paddingEach(
					{cz: 0, cR: 0, cX: 0, c3: 2})
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$PAM$UI$Basic$fadeColor, 0.9, $author$project$PAM$UI$Basic$black)),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(30)),
							$mdgriffith$elm_ui$Element$padding(5)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerY]),
							$mdgriffith$elm_ui$Element$text(
								function () {
									var _v0 = model.bg;
									switch (_v0.$) {
										case 1:
											return 'Suggestions';
										case 2:
											return 'Search Results';
										default:
											return '';
									}
								}())),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight]),
							A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								{
									a8: A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white)
											]),
										_List_fromArray(
											[
												$author$project$PAM$UI$Basic$centeredIcon('fa fa-times')
											])),
									b8: $elm$core$Maybe$Just(
										$author$project$PAM$UI$Search$Internal($author$project$PAM$UI$Search$DismissSuggestions))
								}))
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 550, $mdgriffith$elm_ui$Element$shrink)),
							$mdgriffith$elm_ui$Element$scrollbarY,
							$mdgriffith$elm_ui$Element$height(
							A2($mdgriffith$elm_ui$Element$maximum, 400, $mdgriffith$elm_ui$Element$shrink)),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$PAM$UI$Basic$fadeColor, 0.70, $author$project$PAM$UI$Basic$black)),
							$mdgriffith$elm_ui$Element$Font$color($author$project$PAM$UI$Basic$white),
							$mdgriffith$elm_ui$Element$Border$roundEach(
							{gv: 6, gw: 6, i2: 0, i3: 0}),
							$mdgriffith$elm_ui$Element$padding(4)
						]),
					($elm$core$List$length(suggestions) > 0) ? A2(
						$mdgriffith$elm_ui$Element$textColumn,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$elm$core$List$map,
							function (a) {
								return A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Events$onClick(
											$author$project$PAM$UI$Search$SuggestionSelected(a.ku)),
											$mdgriffith$elm_ui$Element$Border$widthEach(
											_Utils_update(
												$author$project$PAM$UI$Basic$edges,
												{cz: 1})),
											$mdgriffith$elm_ui$Element$Border$color(
											A2($author$project$PAM$UI$Basic$fadeColor, 0.25, $author$project$PAM$UI$Basic$white))
										]),
									_List_fromArray(
										[
											sv(a)
										]));
							},
							suggestions)) : (($elm$core$String$length(model.bd) > 0) ? A2(
						$mdgriffith$elm_ui$Element$textColumn,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$Border$widthEach(
										_Utils_update(
											$author$project$PAM$UI$Basic$edges,
											{cz: 1})),
										$mdgriffith$elm_ui$Element$Border$color(
										A2($author$project$PAM$UI$Basic$fadeColor, 0.25, $author$project$PAM$UI$Basic$white)),
										$mdgriffith$elm_ui$Element$padding(10)
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text('No results found.')
									]))
							])) : $mdgriffith$elm_ui$Element$none))
				])) : $mdgriffith$elm_ui$Element$none;
	});
var $author$project$PAM$UI$Search$view = F3(
	function (model, env, sv) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$minimum, 400, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$color(env.bE.S.fW.eE),
					$mdgriffith$elm_ui$Element$inFront(
					A2($author$project$PAM$UI$Search$clearIconView, model, env)),
					$mdgriffith$elm_ui$Element$inFront(
					A4(
						$author$project$PAM$UI$StandardElements$button,
						0,
						env,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						{
							a8: _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Search')
								]),
							b8: $elm$core$Maybe$Just($author$project$PAM$UI$Search$SearchClicked)
						})),
					$mdgriffith$elm_ui$Element$below(
					A3($author$project$PAM$UI$Search$suggestionView, model, env, sv)),
					$mdgriffith$elm_ui$Element$Border$rounded(1)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$paddingEach(
						_Utils_update(
							$author$project$PAM$UI$Basic$edges,
							{cX: 2}))
					]),
				A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$author$project$PAM$UI$Basic$inputHeight(env)),
							$mdgriffith$elm_ui$Element$clip,
							$mdgriffith$elm_ui$Element$Background$color(env.bE.S.fW.G),
							$mdgriffith$elm_ui$Element$Border$rounded(14),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$paddingEach(
							_Utils_update(
								$author$project$PAM$UI$Basic$edges,
								{cX: 154}))
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$Input$text,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$clip,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent0),
									$mdgriffith$elm_ui$Element$Border$width(0),
									$mdgriffith$elm_ui$Element$paddingEach(
									_Utils_update(
										$author$project$PAM$UI$Basic$edges,
										{cR: 25, c3: 1})),
									$author$project$PAM$UI$Search$onEnter($author$project$PAM$UI$Search$EnterWasPressed)
								]),
							{
								a8: $mdgriffith$elm_ui$Element$Input$labelHidden('Search'),
								h1: A2($elm$core$Basics$composeR, $author$project$PAM$UI$Search$SearchChanged, $author$project$PAM$UI$Search$Internal),
								mv: A2(
									$elm$core$Maybe$map,
									function (v) {
										return A2(
											$mdgriffith$elm_ui$Element$Input$placeholder,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
													$mdgriffith$elm_ui$Element$Background$color($author$project$PAM$UI$Basic$transparent0),
													$mdgriffith$elm_ui$Element$Border$width(0),
													$mdgriffith$elm_ui$Element$paddingEach(
													_Utils_update(
														$author$project$PAM$UI$Basic$edges,
														{cR: 8, c3: 1})),
													$mdgriffith$elm_ui$Element$centerY,
													$mdgriffith$elm_ui$Element$Font$size(
													A2($author$project$PAM$UI$Basic$scaleFont, env.f5, 1)),
													$mdgriffith$elm_ui$Element$Font$color(
													A2($author$project$PAM$UI$Basic$fadeColor, 0.5, env.bE.S.fW.eE))
												]),
											$mdgriffith$elm_ui$Element$text(v));
									},
									model.mv),
								eE: model.bd
							})
						]))));
	});
var $author$project$PAM$appLoadedView = function (model) {
	var modelInfo = model.a;
	var modal = model.j;
	var gridState = model.aN;
	var authModel = model.ar;
	var defaultToolView = function () {
		var _v4 = model.bR.M.hZ;
		if ((!_v4.$) && (_v4.a === 'FireMAP')) {
			return A2(
				$mdgriffith$elm_ui$Element$map,
				$author$project$PAM$dateRangeFilterTranslator,
				A2(
					$author$project$PAM$UI$DateRangeFilter$view,
					model.bo,
					{J: model.J, eV: model.eV, bE: model.bE.fa, fD: model.fD, aq: model.aq, f5: model.f5}));
		} else {
			return $author$project$PAM$blankFilterToolView;
		}
	}();
	return {
		j$: _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$layout,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(
							A2($author$project$PAM$UI$Basic$scaleFont, model.f5, 1)),
							$mdgriffith$elm_ui$Element$inFront(
							A3($author$project$PAM$Modal$view, model.bE.j, model, modal)),
							$mdgriffith$elm_ui$Element$inFront(
							A2(
								$mdgriffith$elm_ui$Element$map,
								$author$project$PAM$authTranslator,
								$author$project$InPageOAuth$view(authModel)))
						]),
					function () {
						var _v0 = _Utils_Tuple2(
							model.dx,
							$author$project$PAM$isSupportedBrowserType(model.J.bi));
						if ((!_v0.a) && (!_v0.b)) {
							return _List_Nil;
						} else {
							return _List_Nil;
						}
					}()),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							A2($author$project$PAM$UI$Basic$style, 'z-index', '0')
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
									$mdgriffith$elm_ui$Element$below(
									$author$project$PAM$directionDetailView(model))
								]),
							_List_fromArray(
								[
									A5(
									$author$project$PAM$Header$view,
									{J: model.J, eV: model.eV, bE: model.bE.fa, fD: model.fD, aq: model.aq, f5: model.f5},
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$alignLeft]),
											A2(
												$author$project$PAM$versionView,
												{J: model.J, eV: model.eV, bE: model.bE.fa, f5: model.f5},
												model.da)),
											defaultToolView,
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignRight,
													$mdgriffith$elm_ui$Element$paddingEach(
													_Utils_update(
														$author$project$PAM$UI$Basic$edges,
														{c3: 0}))
												]),
											A2($author$project$PAM$directionView, model.bE.fa, model)),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignRight,
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$px(550))
												]),
											A2(
												$mdgriffith$elm_ui$Element$map,
												$author$project$PAM$searchNewTranslator,
												A3(
													$author$project$PAM$UI$Search$view,
													model.L,
													{J: model.J, eV: model.eV, bE: model.bE.fa, fD: model.fD, aq: model.aq, f5: model.f5},
													$author$project$PAM$mapSearchSuggestionView(
														{J: model.J, eV: model.eV, bE: model.bE.fa, fD: model.fD, aq: model.aq, f5: model.f5}))))
										]),
									model.bR.fa,
									model.cN,
									$author$project$PAM$headerTranslator)
								])),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Region$mainContent,
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Background$color(
									A2($author$project$PAM$UI$Basic$fadeColor, 0.3, $author$project$PAM$UI$Basic$black))
								]),
							_List_fromArray(
								[
									function () {
									var _v1 = model.aq;
									switch (_v1.$) {
										case 3:
											var user = _v1.a;
											var maybeColumnDefs = A5(
												$elm$core$Maybe$map4,
												A2($author$project$PAM$GridColumns$buildColumns, user, model.bR.M.k$.eW.cG),
												A2(
													$elm$core$Maybe$map,
													function ($) {
														return $.r;
													},
													gridState.N),
												A2(
													$elm$core$Maybe$andThen,
													function (t) {
														return A2($author$project$PAM$Model$GridLayouts$get, t.r, modelInfo.dW);
													},
													gridState.N),
												A2(
													$elm$core$Maybe$andThen,
													function (t) {
														return A2($author$project$PAM$Model$FieldChoices$get, t.r, modelInfo.y);
													},
													gridState.N),
												A2(
													$elm$core$Maybe$andThen,
													function (t) {
														return A2($author$project$PAM$Model$ObjectModels$get, t.r, modelInfo.l);
													},
													gridState.N));
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$behindContent(
														A2(
															$mdgriffith$elm_ui$Element$map,
															$author$project$PAM$mapTranslater,
															A2($author$project$PAM$Map$view, model, model.h))),
														$mdgriffith$elm_ui$Element$inFront(
														function () {
															if (!maybeColumnDefs.$) {
																var _v3 = maybeColumnDefs.a;
																var columnDefs = _v3.a;
																var columnToggles = _v3.b;
																return A6(
																	$author$project$PAM$UI$BottomDrawer$view,
																	model.bE.bl,
																	model,
																	$author$project$PAM$Message$ToggleBottomDrawer,
																	_List_fromArray(
																		[
																			A5($author$project$PAM$objectGridHeaderView, model, gridState, columnToggles, user.mC, user.jz)
																		]),
																	_List_fromArray(
																		[
																			A2($author$project$PAM$objectGridView, model, columnDefs)
																		]),
																	A2(
																		$mdgriffith$elm_ui$Element$map,
																		$author$project$PAM$mapTranslater,
																		A2($mdgriffith$elm_ui$Element$Lazy$lazy, $author$project$PAM$Map$legendView, model.h)));
															} else {
																return $mdgriffith$elm_ui$Element$none;
															}
														}()),
														$mdgriffith$elm_ui$Element$inFront(
														A4(
															$mdgriffith$elm_ui$Element$Lazy$lazy3,
															$author$project$PAM$leftSidebarView(model.bE.iO),
															model,
															user.mC,
															user.jz))
													]),
												_List_Nil);
										case 4:
											var error = _v1.a;
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$el,
														_List_Nil,
														$mdgriffith$elm_ui$Element$text('Error logging in ')),
														A2(
														$mdgriffith$elm_ui$Element$el,
														_List_Nil,
														$mdgriffith$elm_ui$Element$text(error))
													]));
										default:
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_Nil,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$none]));
									}
								}()
								]))
						])))
			]),
		F: A2($elm$core$Maybe$withDefault, model.bR.fa.F.F, model.bR.fa.F.ms)
	};
};
var $author$project$PAM$view = function (app) {
	switch (app.$) {
		case 0:
			var model = app.a;
			return $author$project$PAM$appLoadedView(model);
		case 1:
			var err = app.a;
			return {
				j$: _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$Font$bold]),
										$mdgriffith$elm_ui$Element$text('Oh no!')),
										$mdgriffith$elm_ui$Element$text('We\'ve encountered a problem loading the Utah Focus Area Manager.'),
										$mdgriffith$elm_ui$Element$text(err)
									]))))
					]),
				F: 'Problem loading Utah Focus Area Manager'
			};
		default:
			return {
				j$: _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text('You are not authorized to use this application. Please contact your administrator.')
									]))))
					]),
				F: 'Unauthorized'
			};
	}
};
var $author$project$PAM$main = $elm$browser$Browser$application(
	{lj: $author$project$PAM$init, mk: $author$project$PAM$Message$UrlChanged, ml: $author$project$PAM$Message$LinkClicked, nn: $author$project$PAM$subscriptions, nP: $author$project$PAM$update, f0: $author$project$PAM$view});
_Platform_export({'PAM':{'init':$author$project$PAM$main($elm$json$Json$Decode$value)(0)}});}(this));