
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import {Vector as VectorLayer} from "ol/layer";
import VectorSource from "ol/source/Vector";
import Circle from "ol/style/Circle";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon"
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import * as extent from "ol/extent.js";
import Identify from "../Identify/index.js";


/**
 * Zoom to candidate from Search result.
 * scope - OL Map
 * @param {Object} candidate
 */


export default class Search {
  constructor() {
    this.core = null;
    this.filter = "NotSet";
    this.fmptLayerId = "lyr_fm_fire_incident_location";
    this.fmpolyLayerId = "lyr_fm_fire_incident_perimeter";
  }

  apply(core) {
    
    this.core = core;
    this.setupPorts(core);
  }
  
  getTargetDetails(selid, map){
    let identifyInstance = new Identify();
    identifyInstance.getTargetDetails(selid, map);
  }
  
  findAndZoom (target, _core) {  
  let _map = _core.getMap();
  window.identify_getTargetDetails = this.getTargetDetails.bind(this,_map);
    let fmptLayer;
    let _this=this;
    let fireMapCat = _map.getLayers().getArray().find(l => l.get('id') == "cat_fm");
    if(fireMapCat != undefined && fireMapCat.getLayers().getArray().find(l => l.get('id') == this.fmptLayerId) != undefined) {
          fmptLayer = fireMapCat.getLayers().getArray().find(l => l.get('id') == this.fmptLayerId);
          let qSource = fmptLayer.get("source");
          let qParams = qSource.getParams();
          let qLayer = qParams.LAYERS.replace ("show: ", "")
          let qToken = qParams.token;
          debugger;
          let qUrl = qSource.getUrl() + qLayer + "/query?where=irwinid=%27" + target + "%27&outFields=id%2Cname%2Cdatayear&f=pjson";
          var xhr = new XMLHttpRequest();
          let self = this;
          xhr.onreadystatechange = function() {
              // if (xhr.readyState == XMLHttpRequest.DONE) {
              if (xhr.readyState == 4 && xhr.status == 200) {
                  let jsonResponse = JSON.parse(xhr.response);
                  let x;
                  let y;
                  let pooId;
                  let pooName;
                  let dataYear;
                  if (jsonResponse.features.length > 0) {
                    x = jsonResponse.features[0].geometry.x;
                    y = jsonResponse.features[0].geometry.y;
                    pooId =  jsonResponse.features[0].attributes.id;
                    pooName =  jsonResponse.features[0].attributes.name;
                    dataYear = jsonResponse.features[0].attributes.datayear;
                  }
                  else {
                    alert ("Couldn't find the incident.");
                    return;
                  }
                  if (x != undefined && y != undefined){
                    _map.getView().setCenter([x, y]);  
                    _map.getView().setZoom(16);                    
                    
                    var popup = _map.getOverlayById ('popup');
                    var content = document.getElementById('popup-content');
                    content.innerHTML = "<div style=\"padding: 1em;font-family: Montserrat;font-size: 0.9em;text-overflow: ellipsis;white-space: pre-wrap;word-break: break-word;\"><div style=\"margin-bottom: 3px;\"><span class=\"identify_field\">Name: </span><span>"+ pooName + "</span></div>" +
                    "<div class=\"identify_field_row\"><span class=\"identify_field\">Type: </span><span>Fire Point of Origin</span></div>" +
                      "<div class=\"identify_details\"><br/><button style=\"background: transparent;color: #9AAC6D;border: 0;padding: 0;\" onclick=\"identify_getTargetDetails("+pooId+")\">Details</button></div></div>";
                
                    popup.content = content;
                    popup.setPosition([x, y]);
                    let d = new Date();
                    let currentYear = d.getFullYear();
                    if (parseInt(dataYear) < currentYear){
                      _this.clearFilter(_core);
                    } 

                    identify_getTargetDetails(pooId, _map);
                  }                  
              }
          }
          
          xhr.open('GET', qUrl, true);
          xhr.send(null);
    }
  }
  clearFilter(_core) {
    _core.emit("clearFilter", null);
  }
  
  setupPorts(core) {
    core.mapCmd("gotoSearchCandidates", val => {
      this.gotoSearchCandidates(val);      
    })
    core.mapCmd("gotoSearchCandidate", val => {
      this.gotoSearchCandidate(val);      
    })
     core.mapCmd("setPoints", val => {
      this.setPoints(this, val);
    })
    core.mapCmd("moveMapCenter", val => {
      this.moveMapCenter(val);      
    })
    core.mapCmd("updateZoomButtonBgCmd", this.updateZoomButtonBg.bind(this));
    core.mapCmd("clearSearchCmd", this.clearPins.bind(this));
    core.mapCmd("getRangeFilter", this.getRangeFilter.bind(this));
    core.mapCmd("updateLayerDefinitionCmd", this.updateLayerDefinition.bind(this));
    core.mapCmd("initLayerDefinitionCmd", this.updateLayerDefinition.bind(this));
    
  }

  updateZoomButtonBg(cls, _this=this){
    if (cls.length > 0){
      let zoomHtml = document.getElementsByClassName("ol-zoom-in");
      let zoomHtml1 = document.getElementsByClassName("ol-zoom-out");  
      zoomHtml[0].classList.add(cls);
      zoomHtml1[0].classList.add(cls);
    }
    
  }

  updateLayerDefinition(action, _this=this) {  
    if (document.getElementById("fromRangeFilter-input") == null){
      return;
    }
    setTimeout(function (){
      let fromDate = document.getElementById("fromRangeFilter-input").firstChild.innerHTML;
      let toDate = document.getElementById("toRangeFilter-input").firstChild.innerHTML;
      let fmptLayerDef = '';
      let fmpolyLayerDef = '';    
      _this.filter = 'NotSet';
      
      if (fromDate != "mm/dd/yyyy" && toDate != "mm/dd/yyyy"){
        let temp = "startdatetime BETWEEN timestamp '" + fromDate + " 00:00:00' AND timestamp '" + toDate + " 23:59:59'";
        fmptLayerDef = '{"0":"'+ temp + '"}';
        fmpolyLayerDef = '{"1":"'+ temp + '","2":"'+ temp + '"}';
        _this.filter = " AND " + temp;
      } 
      else {
        if (action == "apply"){
          alert ("Please select a Date Range.");
          return;
        }      
      }
      let fmptLayer;
      let fmpolyLayer;
      
      let fireMapCat = _this.core.getMap().getLayers().getArray().find(l => l.get('id')==="cat_fm");
      if(fireMapCat != undefined && fireMapCat.getLayers().getArray().find(l => l.get('id')===_this.fmptLayerId) != undefined) {
        fmptLayer = fireMapCat.getLayers().getArray().find(l => l.get('id')===_this.fmptLayerId);
        fmpolyLayer = fireMapCat.getLayers().getArray().find(l => l.get('id')===_this.fmpolyLayerId);

        let fmptLayerSource = fmptLayer.getSource(); 
        let fmptLayerParams = fmptLayerSource.getParams();
        fmptLayerParams.layerDefs = fmptLayerDef;

        let fmpolyLayerSource = fmpolyLayer.getSource(); 
        let fmpolyLayerParams = fmpolyLayerSource.getParams();
        fmpolyLayerParams.layerDefs = fmpolyLayerDef;

        fmptLayerSource.refresh();
        fmpolyLayerSource.refresh();
      }    
    }, 100);
  }


  getRangeFilter() {
    this.core.emit("setRangeFilters", this.filter);  
  }


  gotoSearchCandidate(candidate) {
    if (!candidate) return;
  
    let points = [];
    var marker = new Feature({
      geometry: new Point([candidate.location.x, candidate.location.y])
    });
    points.push(marker);
  
    let ext = this._transformSearchExtent(candidate.extent);
    
    this.core.data.map.getView().fit(ext, {
      size: this.core.data.map.getSize(),
      duration: 1000
    });
  
    setPoints(this, points);
  }

  
  moveMapCenter(feature) {
    if (!feature) return;
    window.identify_getTargetDetails = this.getTargetDetails.bind(this,this.core.data.map);     
    this.core.data.map.getView().setCenter([feature.x, feature.y]);  
    this.core.data.map.getView().setZoom(11);
    var popup = this.core.data.map.getOverlayById ('popup');
    var content = document.getElementById('popup-content');
    content.innerHTML = "<div style=\"padding: 1em;font-family: Montserrat;font-size: 0.9em;text-overflow: ellipsis;white-space: pre-wrap;word-break: break-word;\"><div style=\"margin-bottom: 3px;\"><span class=\"identify_field\">Name: </span><span>"+ feature.name + "</span></div>" +
    "<div class=\"identify_field_row\"><span class=\"identify_field\">Type: </span><span>Fire Point of Origin</span></div>" +
      "<div class=\"identify_details\"><br/><button style=\"background: transparent;color: #9AAC6D;border: 0;padding: 0;\" onclick=\"identify_getTargetDetails("+feature.id+")\">Details</button></div></div>";

    popup.content = content;
    popup.setPosition([feature.x, feature.y]);
    identify_getTargetDetails(feature.id, this.core.data.map);
  }
  
  setPoints(that, points) {
    let layer = this.getOrMakeLayer(that);
    var src = layer.getSource();
    src.clear();
    if (points && points.length) src.addFeatures(points);
  }
  
  clearPins() {
    let layer = this.getOrMakeLayer(this);
    var src = layer.getSource();
    src.clear();
  }

  gotoSearchCandidates(val) {
    
    let candidates = val.candidates;
    let paddingLeft = val.paddingLeft;
    let paddingBottom = val.paddingBottom;

    let pudding = [5, 5, paddingBottom + 5, paddingLeft + 5 ]

    if (!candidates || candidates.length < 1) return;
    let self = this;
    let ext = extent.createEmpty();
    let points = [];
    candidates.map(candidate => {
      console.log (candidate.extent);
      let transformed = this._transformSearchExtent(candidate.extent);
      console.log ("transformed");
      console.log (transformed);
      extent.extend(ext, transformed);
      var marker = new Feature({
        geometry: new Point([candidate.location.x, candidate.location.y])
      });
      points.push(marker);
    });
  
    this.setPoints(this, points);
  
    this.core.data.map.getView().fit(ext, {
      size: this.core.data.map.getSize(),
      duration: 1000,
      padding: pudding
    });
  }
  getOrMakeLayer(that) {
    if (!this.core.data.map.pinLayer) {
      let vectorSource = new VectorSource({
        features: []
      });
      var iconStyle = feat => {
        return  new Style({
          image: new Icon({
            src : "/MapMarker.svg",
            scale : 1
          })
        });
      }
    
      let vectorLayer = new VectorLayer({
        source: vectorSource,
        style: iconStyle
      });
      vectorLayer.setZIndex(99999);   
      vectorLayer.setProperties("name", "pin");
      vectorLayer.setProperties("banPixel", true);
      
      this.core.data.map.addLayer(vectorLayer);
      
      this.core.data.map.pinLayer = vectorLayer;
      
    }
  
    return this.core.data.map.pinLayer;
  }
  _transformSearchExtent(ext) {
    
    if (!ext) return [];
    console.log ("_transformSearchExtent");
    return [ext.xmin, ext.ymin, ext.xmax, ext.ymax];
  }
  
  init() {
  }
  render() {}
}

