import { buildWmtsLayer } from './layerHelpers'

import * as util from './layerHelpers.js'


const MAP_SERVER_URL = "MapServer/";

const PAM_MAP_SERVER_URL = "PAM/MapServer/";

const FAM_TOKEN = "Timmons Group AGS FAM";

const REF_TOKEN = "Timmons Group AGS REF";

const DEFAULT_EXTENT = [-13884991, 2870341, -7455066, 6338219];

const referenceActions = {
  key: "lyr_fam_actions_all",
  name: "Actions",
  opacity: 0.5,
  config: {
    esri:
      {
        url: PAM_MAP_SERVER_URL,
        tokenKey: FAM_TOKEN,
        layers: ["4", "3", "2", "1"],
        propertyNames: ["id", "name"],
        nameField: "name",
        idField: "id"
      , extent: DEFAULT_EXTENT
			}
  }
};

const referenceProjects = {
  key: "lyr_fam_projects_all",
  name: "Projects",
  opacity: 0.5,
  config: {
    esri:
      {
        url: PAM_MAP_SERVER_URL,
        tokenKey: FAM_TOKEN,
        layers: ["8", "7", "6", "5"],
        propertyNames: ["id", "name"],
        nameField: "name",
        idField: "id"
      , extent: DEFAULT_EXTENT
			}
  }
};

const referenceFocusAreas = {
  key: "lyr_fam_focus_areas",
  name: "Focus Areas",
  opacity: 0.5,
  config: {
    esri:
      {
        url: PAM_MAP_SERVER_URL,
        tokenKey: FAM_TOKEN,
        layers: ["9"],
        propertyNames: ["id", "name"],
        nameField: "name",
        idField: "id"
      , extent: DEFAULT_EXTENT
			}
  }
};

const referenceAoIs = {
  key: "lyr_aoi",
  name: "Areas of Interest",
  opacity: 0.5,
  config: {
    esri: 
      {
        url: PAM_MAP_SERVER_URL,
        tokenKey: FAM_TOKEN,
        layers: ["10"],
        propertyNames: ["ProjectAreaId", "Name"],
        nameField: "Name",
        idField: "ProjectAreaId",
        extent: DEFAULT_EXTENT
      },
  }
};

const referenceForestActionPlanPriorityArea = {
  key: "lyr_forest_act_plan_priority_areas",
  name: "Forest Action Plan Priority Area",
  opacity: 0.5,
  config: {
    esri: {
      url: MAP_SERVER_URL,
      tokenKey: REF_TOKEN,
      layers: ["11"],
      propertyNames: ["OBJECTID", "PriorityNa"],
      nameField: "PriorityNa",
      idField: "OBJECTID",
      extent: DEFAULT_EXTENT
    }
    , wmts: {
        esri:util.buildWmtsLayer("forestactionplanpriorityarea2014"),
        extent: util.defaultExtent
      },
  }
};

const referenceBLMFieldOffices = {
  key: "lyr_blm_fld_offices",
  name: "BLM Field Offices",
  opacity: 0.5,
  config: {
    esri:
      {
        url: MAP_SERVER_URL,
        tokenKey: REF_TOKEN,
        layers: ["13"],
        propertyNames: ["OBJECTID", "FO_NAME"],
        nameField: "FO_NAME",
        idField: "OBJECTID",
        extent: DEFAULT_EXTENT
      },
    extent: DEFAULT_EXTENT
  }
};

const referenceCongressionalDistricts = {
  key: "lyr_congress_districts",
  name: "Congressional Districts",
  opacity: 0.5,
  config: {
    esri:
      {
        url: MAP_SERVER_URL,
        tokenKey: REF_TOKEN,
        layers: ["2"],
        propertyNames: ["OBJECTID", "DISTRICT"],
        nameField: "DISTRICT",
        idField: "OBJECTID"
      , extent: DEFAULT_EXTENT
			}
  }
};

const referenceCountyBoundaries = {
  key: "lyr_county_bounds",
  name: "County Boundaries",
  opacity: 0.5,
  config: {
    esri:
      {
        url: MAP_SERVER_URL,
        tokenKey: REF_TOKEN,
        layers: ["0"],
        propertyNames: ["OBJECTID", "NAME"],
        nameField: "NAME",
        idField: "OBJECTID"
      , extent: DEFAULT_EXTENT
			}
  }
};

const referenceSageGrouseMgmtAreas = {
  key: "lyr_sage_grouse_mgmt_areas",
  name: "Sage Grouse Management Areas",
  opacity: 0.5,
  config: {
    esri:
      {
        url: MAP_SERVER_URL,
        tokenKey: REF_TOKEN,
        layers: ["5"],
        propertyNames: ["OBJECTID", "Area_name"],
        nameField: "Area_name",
        idField: "OBJECTID"
      , extent: DEFAULT_EXTENT
			}
  }
};

const referenceBLMFuelTreatments = {
  key: "lyr_blm_fuel_treat",
  name: "BLM Fuel Treatments",
  opacity: 0.5,
  config: {
    esri:
      {
        url: MAP_SERVER_URL,
        tokenKey: REF_TOKEN,
        layers: ["6"],
        propertyNames: ["OBJECTID", "TRTMNT_NM"],
        nameField: "TRTMNT_NM",
        idField: "OBJECTID"
      , extent: DEFAULT_EXTENT
			}
  }
};

const referenceMunicipalBoundaries = {
  key: "lyr_municip_bounds",
  name: "Municipal Boundaries",
  opacity: 0.5,
  config: {
    esri:
      {
        url: MAP_SERVER_URL,
        tokenKey: REF_TOKEN,
        layers: ["7"],
        propertyNames: ["OBJECTID", "name"],
        nameField: "NAME",
        idField: "OBJECTID"
      , extent: DEFAULT_EXTENT
			}
  }
};

const referenceHUC12watersheds = {
  key: "lyr_huc12_watersheds",
  name: "HUC12 Watersheds",
  opacity: 0.5,
  config: {
    esri:
      {
        url: MAP_SERVER_URL,
        tokenKey: REF_TOKEN,
        layers: ["4"],
        propertyNames: ["OBJECTID", "HU_12_NAME"],
        nameField: "HU_12_NAME",
        idField: "OBJECTID"
      , extent: DEFAULT_EXTENT
			}
  }
};

var referenceConfig = {
  layers: [
    referenceFocusAreas,
    referenceProjects,
    referenceActions,
    referenceAoIs,
    referenceForestActionPlanPriorityArea,
    referenceBLMFieldOffices,
    referenceCongressionalDistricts,
    referenceCountyBoundaries,
    referenceSageGrouseMgmtAreas,
    referenceBLMFuelTreatments,
    referenceMunicipalBoundaries,
    referenceHUC12watersheds
  ],
  referenceLayers: [
    referenceFocusAreas.key,
    referenceProjects.key,
    referenceActions.key,
    referenceAoIs.key,
    referenceForestActionPlanPriorityArea.key,
    referenceBLMFieldOffices.key,
    referenceCongressionalDistricts.key,
    referenceCountyBoundaries.key,
    referenceSageGrouseMgmtAreas.key,
    referenceBLMFuelTreatments.key,
    referenceMunicipalBoundaries.key,
    referenceHUC12watersheds.key
  ],
  fpConf: [
    referenceFocusAreas,
    referenceProjects,
    referenceActions,
    referenceAoIs,
    referenceBLMFieldOffices,
    referenceBLMFuelTreatments,
    referenceCongressionalDistricts,
    referenceCountyBoundaries,
    referenceForestActionPlanPriorityArea,
    referenceHUC12watersheds,
    referenceMunicipalBoundaries,
    referenceSageGrouseMgmtAreas,
  ]
};

export default referenceConfig;
