
/**
 * PROJECT MODEL, GRID, DETAIL VIEWS
 */

var projectModel = {
  County: { id: 1, name: "County", type: 7 },
  Geometry: { id: 16, name: "Geometry", type: 19 },
  "FFSL Area": { id: 0, name: "FFSL Area", type: 7 },
  "Project Code": { id: 3, name: "Project Code", type: 0, limit: 12 },
  "Funding Source": { id: 2, name: "Funding Source", type: 17 },
  "Project Status": { id: 9, name: "Project Status", type: 7 },
  "Start/End Date": { id: 7, name: "Start/End Date", type: 14 },
  "Project Category": { id: 4, name: "Project Category", type: 7 },
  "Year of Allocation": { id: 8, name: "Year of Allocation", type: 13 },
  "Maintenance required": {
    id: 14,
    name: "Maintenance required",
    type: 16,
    limit: 75
  },
  "Primary FFSL Contact": {
    id: 6,
    name: "Primary FFSL Contact",
    type: 0,
    limit: 30
  },
  "Estimated cost per acres": {
    id: 11,
    name: "Estimated cost per acres",
    type: 4,
    limit: 10
  },
  "Biomass utilization (tons)": {
    id: 12,
    name: "Biomass utilization (tons)",
    type: 3,
    limit: 10
  },
  "Name of CWPP or Preparedness Plan": {
    id: 5,
    name: "Name of CWPP or Preparedness Plan",
    type: 0,
    limit: 50
  },
  "Actions": { id: 17, name: "Actions", type: 10, reference: 3 },
  Scores: {
    id: 15,
    name: "Scores",
    type: 18
  },
};

var projectGridView = [
  {
    id: 0,
    modelId: null,
    displayName: "Edit",
    display: true,
    displayOrder: null
  },
  { id: 1, modelId: -2, displayName: "Name", display: true, displayOrder: 1 },
  {
    id: 2,
    modelId: 0,
    displayName: "FFSL Area",
    display: true,
    displayOrder: 2
  },
  { id: 3, modelId: 1, displayName: "County", display: true, displayOrder: 3 },
  {
    id: 4,
    modelId: 2,
    displayName: "Funding Source",
    display: true,
    displayOrder: 4
  },
  {
    id: 5,
    modelId: 3,
    displayName: "Project Code",
    display: true,
    displayOrder: 5
  },
  {
    id: 6,
    modelId: 4,
    displayName: "Project Category",
    display: true,
    displayOrder: 6
  },
  {
    id: 7,
    modelId: 5,
    displayName: "Name of CWPP or Preparedness Plan",
    display: true,
    displayOrder: 7
  },
  {
    id: 8,
    modelId: 6,
    displayName: "Primary FFSL Contact",
    display: true,
    displayOrder: 8
  },
  {
    id: 9,
    modelId: 7,
    displayName: "Project Start/End Date",
    display: false,
    displayOrder: 9
  },
  {
    id: 10,
    modelId: 8,
    displayName: "Year of Allocation",
    display: false,
    displayOrder: 10
  },
  {
    id: 11,
    modelId: 9,
    displayName: "Project Status",
    display: false,
    displayOrder: 11
  },
  {
    id: 18,
    modelId: 15,
    displayName: "Risk Score",
    display: true,
    displayOrder: 12
  },
  {
    id: 13,
    modelId: 16,
    displayName: "Acres",
    display: false,
    displayOrder: 13
  },
  {
    id: 14,
    modelId: 11,
    displayName: "Estimated Cost per Acre",
    display: false,
    displayOrder: 13
  },
  {
    id: 15,
    modelId: 12,
    displayName: "Biomass Utilization (Tons)",
    display: false,
    displayOrder: 13
  },
  {
    id: 16,
    modelId: 13,
    displayName: "Completion Date",
    display: false,
    displayOrder: 15
  },
  {
    id: 17,
    modelId: 14,
    displayName: "Maintenance Required",
    display: false,
    displayOrder: 16
  },
];

var projectDetailView = [
  [
    {
      id: 1,
      modelId: -1,
      displayName: "Name",
      display: true,
      displayOrder: 1,
      mode: 1
    }
  ],
  [
    {
      id: 2,
      modelId: 0,
      displayName: "FFSL Area",
      display: true,
      displayOrder: 2,
      mode: 1
    }
  ],
  [
    {
      id: 3,
      modelId: 1,
      displayName: "County",
      display: true,
      displayOrder: 3,
      mode: 1
    }
  ],
  [
    {
      id: 4,
      modelId: 2,
      displayName: "Funding Source",
      display: true,
      displayOrder: 4,
      mode: 1
    }
  ],
  [
    {
      id: 5,
      modelId: 3,
      displayName: "Project Code",
      display: true,
      displayOrder: 5,
      mode: 1
    }
  ],
  [
    {
      id: 6,
      modelId: 4,
      displayName: "Project Category",
      display: true,
      displayOrder: 6,
      mode: 0
    }
  ],
  [
    {
      id: 7,
      modelId: 5,
      displayName: "Name of CWPP or Preparedness Plan",
      display: true,
      displayOrder: 7,
      mode: 1
    }
  ],
  [
    {
      id: 8,
      modelId: 6,
      displayName: "Primary FFSL Contact",
      display: true,
      displayOrder: 8,
      mode: 1
    }
  ],
  [
    {
      id: 9,
      modelId: 7,
      displayName: "Project Start/End Date",
      display: true,
      displayOrder: 9,
      mode: 1
    }
  ],
  [
    {
      id: 10,
      modelId: 8,
      displayName: "Year of Allocation",
      display: true,
      displayOrder: 10,
      mode: 1
    }
  ],
  [
    {
      id: 11,
      modelId: 9,
      displayName: "Project Status",
      display: true,
      displayOrder: 11,
      mode: 1
    }
  ],
  [
    {
      id: 13,
      modelId: 11,
      displayName: "Estimated Cost per Acre",
      display: true,
      displayOrder: 13,
      mode: 1
    }
  ],
  [
    {
      id: 14,
      modelId: 12,
      displayName: "Biomass Utilization (Tons)",
      display: true,
      displayOrder: 14,
      mode: 1
    }
  ],
  [
    {
      id: 16,
      modelId: 14,
      displayName: "Maintenance Required",
      display: true,
      displayOrder: 16,
      mode: 1
    }
  ],
	[
		{
      id: 6,
      modelId: 15,
      displayName: "Risk Score",
      display: true,
      displayOrder: 17,
      mode: 2
    }
	],
  [
    {
      id: 18,
      modelId: 16,
      displayName: "Geometry",
      display: true,
      displayOrder: 18,
      mode: 1
    }
  ],
  [
    {
      id: 19,
      modelId: 17,
      displayName: "Actions",
      display: true,
      displayOrder: 19,
      mode: 0
    }
  ]
];

/**
 * ACTION MODEL, GRID, DETAIL VIEWS
 */

var actionModel = {
  "Start/End Date": { id: 21, name: "Start/End Date", type: 14 },
  "Type": { id: 2, name: "Type", type: 17 },
  "Phase": { id: 22, name: "Phase", type: 7 },
  "Status": { id: 23, name: "Status", type: 7 },
  "Acres": { id: 6, name: "Acres", type: 3, min: 0 },
	"Comments": { id: 14, name: "Comments", type: 1, limit: 256 },
  "Geometry": { id: 16, name: "Geometry", type: 11 },
};


var actionGridView = [
  {
    id: 0,
    modelId: null,
    displayName: "Edit",
    display: true,
    displayOrder: null
  },
  { id: 1, modelId: -2, displayName: "Name", display: true, displayOrder: 0 },
  { id: 2, modelId: 2, displayName: "Type", display: true, displayOrder: 1 },
  {
    id: 3,
    modelId: 21,
    displayName: "Start/End Date",
    display: true,
    displayOrder: 2
  },
  { id: 4, modelId: 22, displayName: "Phase", display: false, displayOrder: 3 },
  {
    id: 5,
    modelId: 23,
    displayName: "Status",
    display: false,
    displayOrder: 4
  },
  {
    id: 6,
    modelId: 16,
    displayName: "Acres",
    display: false,
    displayOrder: 5
  },
  {
    id: 7,
    modelId: 14,
    displayName: "Comments",
    display: false,
    displayOrder: 6
  }
];

var actionDetailView = [
  [
    {
      id: 0,
      modelId: -1,
      displayName: "Name",
      display: true,
      displayOrder: 1,
      mode: 1
    }
  ],
  [
    {
      id: 1,
      modelId: 2,
      displayName: "Type",
      display: true,
      displayOrder: 2,
      mode: 1
    }
  ],
  [
    {
      id: 2,
      modelId: 21,
      displayName: "Start/End Date",
      display: true,
      displayOrder: 3,
      mode: 1
    }
  ],
  [
    {
      id: 3,
      modelId: 22,
      displayName: "Phase",
      display: true,
      displayOrder: 4,
      mode: 0
    }
  ],
  [
    {
      id: 4,
      modelId: 23,
      displayName: "Status",
      display: true,
      displayOrder: 5,
      mode: 1
    }
  ],
  [
    {
      id: 6,
      modelId: 14,
      displayName: "Comments",
      display: true,
      displayOrder: 7,
      mode: 0
    }
  ],
  [
    {
      id: 7,
      modelId: 16,
      displayName: "Geometry",
      display: true,
      displayOrder: 8,
      mode: 0
    }
  ]
];

/**
 * CWMS MODEL, GRID, DETAIL VIEWS
 */

var cwmsModel = {
  "Participating Entity Name": {
    id: 100,
    name: "Participating Entity Name",
    type: 0
  },
  "Participating Entity Type": {
    id: 101,
    name: "Participating Entity Type",
    type: 7
  },
  "Action Type": { id: 102, name: "Action Type", type: 0 },
  Category: { id: 105, name: "Category", type: 7 },
  Status: { id: 106, name: "Status", type: 7 },
  "Start/End Date": {
    id: 107,
    name: "Start/End Date",
    type: 14
  },
  "Submit Date": {
    id: 108,
    name: "Submit Date",
    type: 5
  },
  "Estimated Cost": {
    id: 109,
    name: "Estimated Cost",
    type: 4
  },
  Description: {
    id: 110,
    name: "Description",
    type: 1
  },
  Acres: {
    id: 111,
    name: "Acres",
    type: 15
  },
  Hours: {
    id: 112,
    name: "Hours",
    type: 3
  },
  Biomass: {
    id: 113,
    name: "Biomass",
    type: 3
  },
  "Created By": {
    id: 114,
    name: "Created By",
    type: 0
  },
  "Last Modified By": {
    id: 115,
    name: "Last Modified By",
    type: 0
  },
  "Last Submitted By": {
    id: 116,
    name: "Last Submitted By",
    type: 0
  },
  "Last Submitted On": {
    id: 117,
    name: "Last Submitted On",
    type: 5
  },
  "Last Approval By": {
    id: 118,
    name: "Last Approval By",
    type: 0
  },
  "Approval Notes": {
    id: 119,
    name: "Approval Notes",
    type: 1
  },
  "Last Approved On": {
    id: 120,
    name: "Last Approved On",
    type: 5
  },
  Deleted: {
    id: 121,
    name: "Deleted",
    type: 6
  }
};

var cwmsActionGridView = [
  {
    id: 0,
    modelId: null,
    displayName: "Edit",
    display: true,
    displayOrder: null
  },
  {
    id: 1,
    modelId: 100,
    displayName: "Participating Entity Name",
    display: true,
    displayOrder: 10
  },
  {
    id: 2,
    modelId: 101,
    displayName: "Participating Entity Type",
    display: true,
    displayOrder: 20
  },
  {
    id: 3,
    modelId: 102,
    displayName: "Action Type",
    display: true,
    displayOrder: 30
  },
  {
    id: 0,
    modelId: -2,
    displayName: "Action Name",
    display: true,
    displayOrder: 40
  },
  {
    id: 5,
    modelId: 107,
    displayName: "Start/End Date",
    display: true,
    displayOrder: 50
  },
  {
    id: 6,
    modelId: 105,
    displayName: "Category",
    display: false,
    displayOrder: 60
  },
  {
    id: 7,
    modelId: 113,
    displayName: "Biomass Tons per Acre",
    display: false,
    displayOrder: 70
  },
  {
    id: 8,
    modelId: 111,
    displayName: "Acres",
    display: false,
    displayOrder: 80
  },
  {
    id: 9,
    modelId: 109,
    displayName: "Total Estimated Cost",
    display: false,
    displayOrder: 90
  },
  {
    id: 10,
    modelId: 112,
    displayName: "Hours",
    display: false,
    displayOrder: 100
  },
  {
    id: 11,
    modelId: 110,
    displayName: "Description",
    display: false,
    displayOrder: 110
  },
  {
    id: 13,
    modelId: 106,
    displayName: "Status",
    display: false,
    displayOrder: 120
  },
  {
    id: 14,
    modelId: 114,
    displayName: "Created By",
    display: false,
    displayOrder: 140
  },
  {
    id: 15,
    modelId: 116,
    displayName: "Submitted By",
    display: false,
    displayOrder: 150
  },
  {
    id: 15,
    modelId: 117,
    displayName: "Submitted On",
    display: false,
    displayOrder: 160
  },
  {
    id: 16,
    modelId: 118,
    displayName: "Approved By",
    display: false,
    displayOrder: 170
  },
  {
    id: 16,
    modelId: 120,
    displayName: "Approved On",
    display: false,
    displayOrder: 180
  },
  {
    id: 17,
    modelId: 119,
    displayName: "Approver Notes",
    display: false,
    displayOrder: 190
  }
];

var cwmsActionDetailView = [
  [
    {
      id: 0,
      modelId: 100,
      displayName: "Participating Entity Name",
      display: true,
      displayOrder: 1,
      mode: 2
    }
  ],
  [
    {
      id: 1,
      modelId: 101,
      displayName: "Participating Entity Type",
      display: true,
      displayOrder: 2,
      mode: 2
    }
  ],
  [
    {
      id: 2,
      modelId: 102,
      displayName: "Action Type",
      display: true,
      displayOrder: 3,
      mode: 2
    }
  ],
  [
    {
      id: 3,
      modelId: 103,
      displayName: "Action Name",
      display: true,
      displayOrder: 4,
      mode: 2
    }
  ],
  [
    {
      id: 4,
      modelId: 107,
      displayName: "Start/End Date",
      display: true,
      displayOrder: 5,
      mode: 2
    }
  ],
  [
    {
      id: 5,
      modelId: 105,
      displayName: "Category",
      display: true,
      displayOrder: 6,
      mode: 2
    }
  ],
  [
    {
      id: 6,
      modelId: 113,
      displayName: "Biomass Tons per Acre",
      display: true,
      displayOrder: 7,
      mode: 2
    }
  ],
  [
    {
      id: 7,
      modelId: 111,
      displayName: "Acres",
      display: true,
      displayOrder: 8,
      mode: 2
    }
  ],
  [
    {
      id: 8,
      modelId: 109,
      displayName: "Total Estimated Cost",
      display: true,
      displayOrder: 9,
      mode: 2
    }
  ],
  [
    {
      id: 9,
      modelId: 112,
      displayName: "Hours",
      display: true,
      displayOrder: 10,
      mode: 2
    }
  ],
  [
    {
      id: 10,
      modelId: 110,
      displayName: "Description",
      display: true,
      displayOrder: 11,
      mode: 2
    }
  ],
  [
    {
      id: 11,
      modelId: 108,
      displayName: "Submit Date",
      display: true,
      displayOrder: 12,
      mode: 2
    }
  ],
  [
    {
      id: 12,
      modelId: 106,
      displayName: "Status",
      display: true,
      displayOrder: 13,
      mode: 2
    }
  ],
  [
    {
      id: 13,
      modelId: 114,
      displayName: "Created By",
      display: true,
      displayOrder: 14,
      mode: 2
    }
  ],
  [
    {
      id: 14,
      modelId: 116,
      displayName: "Submitted By",
      display: true,
      displayOrder: 15,
      mode: 2
    }
  ],
  [
    {
      id: 15,
      modelId: 118,
      displayName: "Approved/Denied By",
      display: true,
      displayOrder: 16,
      mode: 2
    }
  ],
  [
    {
      id: 16,
      modelId: 119,
      displayName: "Approver Notes",
      display: true,
      displayOrder: 17,
      mode: 2
    }
  ]
];

/**
 * FOCUS AREA MODEL
 */

var focusAreaModel = {
  CWPP: {
    id: 0,
    name: "CWPP",
    type: 0,
    limit: 50
  },
  "Funding Source": {
    id: 1,
    name: "Funding Source",
    type: 17
  },
  Scores: {
    id: 2,
    name: "Scores",
    type: 18
  },
  Region: {
    id: 3,
    name: "Region",
    type: 7
  },
  Projects: {
    id: 4,
    name: "Projects",
    type: 10,
    reference: 2
  },
  Geometry: {
    id: 5,
    name: "Geometry",
    type: 19
  },
};

var focusAreaDetailView = [
  [
    {
      id: 0,
      modelId: -1,
      displayName: "Name",
      display: true,
      displayOrder: 0,
      mode: 1
    }
  ],
  [
    {
      id: 1,
      modelId: 3,
      displayName: "Region",
      display: true,
      displayOrder: 1,
      mode: 1
    }
  ],
  [
    {
      id: 2,
      modelId: 0,
      displayName: "CWPP",
      display: true,
      displayOrder: 2,
      mode: 1
    }
  ],
  [
    {
      id: 3,
      modelId: 1,
      displayName: "Funding Source",
      display: true,
      displayOrder: 3,
      mode: 1
    }
	],
	[
		{
      id: 6,
      modelId: 2,
      displayName: "Risk Score",
      display: true,
      displayOrder: 6,
      mode: 2
    }
	],
  [
    {
      id: 7,
      modelId: 5,
      displayName: "Geometry",
      display: true,
      displayOrder: 7,
      mode: 1
    }
  ],
  [
    {
      id: 8,
      modelId: 4,
      displayName: "Projects",
      display: true,
      displayOrder: 8,
      mode: 0
    }
  ]
];

var focusAreaGridView = [
  {
    "id": 0,
    "modelId": null,
    "displayName": "Edit",
    "display": true,
    "displayOrder": null
  },
  {
    "id": 1,
    "modelId": -1,
    "displayName": "ID",
    "display": true,
    "displayOrder": 0
  },
  {
    "id": 2,
    "modelId": -2,
    "displayName": "Name",
    "display": true,
    "displayOrder": 10
  },
  {
    "id": 4,
    "modelId": 3,
    "displayName": "Region",
    "display": true,
    "displayOrder": 20
  },
  {
    "id": 3,
    "modelId": 2,
    "displayName": "Risk Score",
    "display": true,
    "displayOrder": 30
  },
  {
    "id": 5,
    "modelId": 5,
    "displayName": "Acres",
    "display": true,
    "displayOrder": 40
  },
  {
    "id": 6,
    "modelId": 4,
    "displayName": "Number of Projects",
    "display": true,
    "displayOrder": 50
  },
  {
    "id": 7,
    "modelId": 0,
    "displayName": "CWPP",
    "display": false,
    "displayOrder": 50
  },
  {
    "id": 8,
    "modelId": 1,
    "displayName": "Funding Source",
    "display": false,
    "displayOrder": 60
  }
];

var projectChoiceRepo = [];
var actionChoiceRepo = [];
var cwmsActionChoiceRepo = [];
var focusAreaChoiceRepo = [];
var testChoiceRepo = [];

var focusAreaId = 1;
var projectId = 2;
var actionId = 3;
var cwsActionId = 4;


var choices_Hack = {
  99: testChoiceRepo
};

choices_Hack[projectId] = projectChoiceRepo;
choices_Hack[actionId] = actionChoiceRepo;
choices_Hack[cwsActionId] = cwmsActionChoiceRepo;
choices_Hack[focusAreaId] = focusAreaChoiceRepo;

function pushChoices(id, choices, tag, type) {
  var repo = choices_Hack[id];

  if(!repo)
    debugger;

  for (var choice of choices) {
    var id2 = repo.length;
    repo.push({ id: id2, name: choice, tag: tag, type: type });
  }
}

var peTypeChoices = ["County", "Municipality", "District"];

pushChoices(cwsActionId, peTypeChoices, "pe type", 101);

var cwmsActionCategory = [
  "Wildfire Mitigation Actions",
  "Wildfire Preparedness Actions",
  "Wildfire Prevention Actions"
];

pushChoices(cwsActionId, cwmsActionCategory, "cwms action category", 105);

var cwmsActionStatus = ["Not Submitted", "Submitted", "Approved", "Denied"];

pushChoices(cwsActionId, cwmsActionStatus, "cwms action category", 106);

var ffslChoices = [
  "Bear River Area",
  "Central Area",
  "Northeast Area",
  "Southeast Area",
  "Southwest Area",
  "Wasatch Front Area"
];

pushChoices(projectId, ffslChoices, "ffsl area", 0);
pushChoices(actionId, ffslChoices, "ffsl area", 14);
pushChoices(focusAreaId, ffslChoices, "region", 3);

var fundingChoices = [
  "SFA",
  "Stevens",
  "CatFire",
  "RCPP",
  "WRI",
  "LSR",
  "BLM",
  "PE funded"
];

pushChoices(projectId, fundingChoices, "funding source", 2);
pushChoices(focusAreaId, fundingChoices, "funding source", 1);

var projectCategoryChoices = ["Mitigation", "Preparedness", "Prevention"];

pushChoices(projectId, projectCategoryChoices, "project category", 4);
pushChoices(actionId, projectCategoryChoices, "action category", 1);

var statusChoices = ["Planning", "Complete", "In Progress", "On Hold"];

pushChoices(projectId, statusChoices, "project status", 9);

var countyChoices = [
  "Beaver",
  "Box Elder",
  "Cache",
  "Carbon",
  "Daggett",
  "Davis",
  "Duchesne",
  "Emery",
  "Garfield",
  "Grand",
  "Iron",
  "Juab",
  "Kane",
  "Millard",
  "Morgan",
  "Piute",
  "Rich",
  "Salt Lake",
  "San Juan",
  "Sanpete",
  "Sevier",
  "Summit",
  "Tooele",
  "Uintah",
  "Utah",
  "Wasatch",
  "Washington",
  "Wayne",
  "Weber"
];

pushChoices(projectId, countyChoices, "county", 1);
pushChoices(actionId, countyChoices, "county", 0);

var actionTypeChoices = [
  "Broadcast Burn",
  "Community Chipping",
  "Control Line Construction",
  "Green Stripping",
  "Hand Pile",
  "Hand Treatment",
  "Herbicide/Chemical",
  "Lop and Scatter",
  "Mechanical",
  "Mowing",
  "Pile Burn",
  "Seeding",
  "Thinning",
  "Timber Harvest"
];

pushChoices(actionId, actionTypeChoices, "type", 2);

var actionPhaseChoices = ["1", "2", "3", "4", "5", "6"];

pushChoices(actionId, actionPhaseChoices, "phase", 22);

var actionStatusChoices = ["Planning", "Complete", "In Progress", "On Hold"];
pushChoices(actionId, actionStatusChoices, "status", 23);

var models = { };
  models[projectId] = projectModel,
  models[actionId] = actionModel,
  models[cwsActionId] = cwmsModel,
  models[focusAreaId] = focusAreaModel


var views = {
  list: {}, details: {}
};

    views.list[projectId] = projectGridView;
    views.list[actionId] = actionGridView;
    views.list[cwsActionId] = cwmsActionGridView;
    views.list[focusAreaId] = focusAreaGridView;
  
  
    views.details[projectId] = projectDetailView;
    views.details[actionId] = actionDetailView;
    views.details[cwsActionId] = cwmsActionDetailView;
    views.details[focusAreaId] = focusAreaDetailView;

export var modelInfo = {
  types: { },
  models: models,
  gridViews: views.list,
  detailViews: views.details,
  choices: choices_Hack
};
    modelInfo.types[projectId] = {
      id: projectId,
      key: "project",
      singular_name: "Project",
      plural_name: "Projects",
      display: true,
      display_order: 100
    },
    modelInfo.types[actionId] = {
      id: actionId,
      key: "action",
      singular_name: "Action",
      plural_name: "Actions",
      display: true,
      display_order: 200
    },
    modelInfo.types[cwsActionId] = {
      id: cwsActionId,
      key: "cwms-action",
      singular_name: "CWMS Action",
      plural_name: "CWMS Actions",
      display: false,
      display_order: 300
    },
    modelInfo.types[focusAreaId] = {
      id: focusAreaId,
      key: "focus-area",
      singular_name: "Focus Area",
      plural_name: "Focus Areas",
      display: true,
      display_order: 50
    }
