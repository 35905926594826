import { Group as LayerGroup, Tile as TileLayer } from "ol/layer.js";
import XYZ from "ol/source/XYZ";
import * as proj from "ol/proj";
import * as extent from "ol/extent";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import OSM from 'ol/source/OSM.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import ImageLayer from "ol/layer/Image";
import { TileArcGISRest } from "ol/source";
import { ImageArcGISRest } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Circle, Icon } from "ol/style";
import { createXYZ } from "ol/tilegrid";
import EsriJSON from "ol/format/EsriJSON";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { tile as tileStrategy, bbox as bboxStrategy } from "ol/loadingstrategy";
import Search from "../Search/index.js"


export default class Themes {
  constructor() {
    this.pendingConfiguration = [];
    this.core = null;
    this.lastState = {}
    this.states = [];
  }
  
  apply(core) {
    this.core = core;

    core.mapCmd("addThemesCmd", this.addLayerCategories.bind(this));

    core.mapCmd("toggleSelectedThemesCmd", this.toggleSelectedThemes.bind(this));

    core.mapCmd("setCategoryTransparencyCmd", this.setCategoryTransparency.bind(this));

    core.on("setServicesCmd", this.processPending.bind(this));
  }

  save() {
    this.states.push(this.lastState);
  }

  revert() {
    this.toggleSelectedThemes(this.states.pop())
  }


  processPending() {
    let self = this;
    var itemsProcessed = 0;
    // console.log (this.pendingConfiguration);
    this.pendingConfiguration.forEach(item => {
      itemsProcessed++;      
      item.fn.apply(self, item.params);
      if(itemsProcessed === this.pendingConfiguration.length) {
        // this.checkTarget();
      }
    });
    
    
  }
  checkTarget (){
    // Check if url has target parameter 
    const queryString = window.location.search;    
    const target = this.urlParam('target');
    let searchInstance = new Search();
    if (target != null){
      // delete target parameter from url
      history.replaceState && history.replaceState(
        null, '', location.pathname + location.search.replace(/[\?&]target=[^&]+/, '').replace(/^&/, '?') + location.hash
      );
      searchInstance.findAndZoom(target, this.core);
    } 
    
    
  }
  urlParam (name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null){
       return null;
    }
    else {
       return decodeURI(results[1]) || 0;
    }
  }
  init() {}
  
  addLayerCategories(categories) {
    let self = this;
    let core = this.core;
    let map = core.getMap();
    let groups = categories.map(category => {
      // build out all the layers, none will be visible yet
      let layers = category.layers.map(layer => {
        return self.makeLayer.call(self, layer);
      });

      // show layers that are part of current selection
      // and hide ones that are not part of current selection
      self.setLayerVisibilities(category.selection, layers);

      // group category layers into a layer group
      let group = new LayerGroup({
        opacity: category.opacity,
        layers: layers
      });
      group.set('id', category.category_key);      
      return group;
    });
    let itemsProcessed =0;
      groups.forEach(group => { 
        map.addLayer(group);
        itemsProcessed++;     
        if(itemsProcessed === groups.length) {
          this.checkTarget();
        }
      });
  }

  toggleSelectedThemes(data) {
    if(!data)
      return;
    let self = this;
    let map = this.core.getMap();
    data.forEach(datum => {
      let category = 
        map
          .getLayers()
          .getArray()
          .find(l => l.get('id') === datum.category_key);

      let layers = category.getLayers().getArray();

      self.setLayerVisibilities(datum.selection, layers);
    });

    this.lastState = data;
  }

  setLayerVisibilities(selection, layers) {
    let toggleLayer = function(layer, isMatch) {
      if (layer instanceof LayerGroup) {
        layer.setVisible(isMatch);
        layer.getLayers().getArray().forEach(child => child.setVisible(isMatch));
      } else {
        layer.setVisible(isMatch);
      }
    };

    switch (selection.selection_type) {
      case "monoselection":
        layers.forEach(layer => {
          toggleLayer(layer, selection.selection_key === layer.get('id'));
        });  
        break;

      case "polyselection":
        layers.forEach(layer => {
          toggleLayer(layer, selection.selection_keys.includes(layer.get('id')));
        });
        break;

      default:
        break;
    }
  }

  setCategoryTransparency(data) {
    let map = this.core.getMap();
    let category = 
        map
          .getLayers()
          .getArray()
          .find(l => l.get('id') === data.category_key);
    if (category) {
      category.setOpacity(data.transparency);
    }
  }


  makeLayer(data) {
    // finalizes layer as either a layer group if it has multiple
    // endpoints or as a single layer if it only has one endpoint
    let groupLayers = function(layers) {
      if (layers.length > 1) {
        let group = new LayerGroup({ layers: layers });
        group.set('id', data.key);
        return group;
      } else if (layers.length === 1) {
        layers[0].set('id', data.key);
        if (data.zIndex > 0){
          layers[0].setZIndex(data.zIndex);          
        }
        return layers[0];
      } else {
        throw new Error(`Could not make layer for ${data.key}`);
      }
    };

    try {
      let self = this;
      let core = this.core;
      let layers = null;

      switch (data.config.type) {
        case "xyz":
          layers = data.config.value.endpoints.map(endpoint => {
            let lyr =  new TileLayer({
              visible: false,
              preload: 4,
              zIndex: endpoint.zIndex || 0,
              opacity: data.opacity || 1,
              source: new XYZ({
                crossOrigin: 'anonymous',
                url: endpoint.url,
                maxZoom: data.config.value.maxZoom || 26,
                minZoom: data.config.value.minZoom || 1,
                tileLoadFunction: (imageTile, src) => {
                  imageTile.getImage().src = src;
                }
              })   
            });
            lyr.set('id', data.key);
            lyr.set('name', data.name);
            return lyr;
          });

          return groupLayers(layers);
  
        case "wmts":
          var projection = proj.get("EPSG:3857"),
            projectionExtent = projection.getExtent(),
            size = extent.getWidth(projectionExtent) / 256,
            zooms = 15 + 1,
            resolutions = new Array(zooms),
            matrixIds = new Array(zooms);
          for (let z = 0; z < zooms; ++z) {
            resolutions[z] = size / Math.pow(2, z);
            matrixIds[z] = z;
          }
          
          layers = data.config.value.endpoints.map(endpoint => {
            let source = new WMTS({
              crossOrigin: 'anonymous',
              matrixSet: 'webmercator',
              format: 'image/png',
              projection: projection,
              requestEncoding: 'REST',
              tileGrid: new WMTSTileGrid({
                extent: data.config.value.extent,
                resolutions: resolutions,
                matrixIds: matrixIds
              }),
              style: 'default',
              opaque: false,
              transparent: true
            });
            let configureSource = function (tokenKey) {
              if (core.services && core.services[tokenKey]) {
                let tokenData = core.services[tokenKey];
                source.setUrl(`${tokenData.baseUrl || ""}${endpoint.url}`);
                source.setTileLoadFunction(function (imageTile, src) {
                  imageTile.getImage().src = `${src}?token=${tokenData.token || ""}`;
                });
              }
            }

            if (endpoint.tokenKey) {
              // if the token data has already been fetched and stored in core.services
              // go ahead and configure the source w/ the data, otherwise, postpone
              // the configuration until `setServicesCmd` has been triggered
              if (core.services && core.services[endpoint.tokenKey]) {
                configureSource(endpoint.tokenKey);
              } else {
                self.pendingConfiguration.push({
                  name: data.key,
                  fn: configureSource,
                  params: [ endpoint.tokenKey ]
                });
              }
            }

            let lyr = new TileLayer({
              visible: false,
              preload: 4,
              zIndex: endpoint.zIndex || 0,
              opacity: data.opacity || 1,
              source: source,
              opaque: false
            });
            lyr.set('id', data.key);
            lyr.set('name', data.name);
            return lyr;
          });

          return groupLayers(layers);

        case "wms":
          var projection = proj.get("EPSG:3857"),
              projectionExtent = projection.getExtent(),
              size = extent.getWidth(projectionExtent) / 256,
              zooms = 15 + 1,
              resolutions = new Array(zooms);
          for (let z = 0; z < zooms; ++z) {
            resolutions[z] = size / Math.pow(2, z);
          }

          layers = data.config.value.endpoints.map(endpoint => {
            //The random adds a random value to the parameter
            //essentually cache busting  
            let customParams = {
              get random() {
                return Math.random();
              }
            };

            let source = new ImageWMS({
              params: {'LAYERS': 'geonode:shapes'},
              ratio: 1,
              serverType: 'geoserver',
              resolutions: resolutions,
              projection: projection
            });

            let configureSource = function (tokenKey) {
              if (core.services && core.services[tokenKey]) {
                let tokenData = core.services[tokenKey];
                source.setUrl(`${tokenData.baseUrl || ""}${endpoint.url}`);
                if (tokenData.token) {
                  customParams["token"] = tokenData.token;
                }
                source.params_ = customParams;
              }
            }

            if (endpoint.tokenKey) {
              // if the token data has already been fetched and stored in core.services
              // go ahead and configure the source w/ the data, otherwise, postpone
              // the configuration until `setServicesCmd` has been triggered
              if (core.services && core.services[endpoint.tokenKey]) {
                configureSource(endpoint.tokenKey);
              } else {
                self.pendingConfiguration.push({
                  name: data.key,
                  fn: configureSource,
                  params: [ endpoint.tokenKey ]
                });
              }
            }

            let lyr = new ImageLayer({
              zIndex: endpoint.zIndex || 0,
              extent: data.config.value.extent,
              source: source
            });
            lyr.set('id', data.key);
            lyr.set('name', data.name);
            return lyr;
          })

          return groupLayers(layers);
  
        case "esriExport":          
            layers = data.config.value.endpoints.map(endpoint => {
              //The random adds a random value to the parameter
              //essentually cache busting  
              let customParams = {
                get random() {
                  return Math.random();
                }
              };
              let endpointUrl = endpoint.url.toString().toLowerCase();

              // IN CASE OF MAP SERVICE OR IMAGE SERVICE
              if (endpointUrl.indexOf("/mapserver") > -1 || endpointUrl.indexOf("mapserver/") > -1 || endpointUrl.indexOf("/imageserver") > -1 || endpointUrl.indexOf("imageserver/") > -1){
                if (endpoint.bbox) {
                  customParams["BBOX"] = endpoint.bbox;
                }
      
                if (endpoint.layersToShow) {
                  customParams["LAYERS"] = endpoint.layersToShow;
                }
      
                if (endpoint.layerDefs) {
                  customParams["LAYERDEFS"] = endpoint.layerDefs
                }
    
                let source = new ImageArcGISRest({
                  crossOrigin: 'anonymous',
                  ratio: 1,
                  maxZoom: 26,
                  tileLoadFunction: (image, src) => {
                    image.getImage().src = src;
                  }
                });
    
                let configureSource = function (tokenKey) {
                  if (core.services && core.services[tokenKey]) {
                    let tokenData = core.services[tokenKey];
                    source.setUrl(`${tokenData.baseUrl || ""}${endpoint.url}`);
                    if (tokenData.token) {
                      customParams["token"] = tokenData.token;
                    }
                    source.params_ = customParams;
                  }
                }
      
                if (endpoint.tokenKey) {
                  // if the token data has already been fetched and stored in core.services
                  // go ahead and configure the source w/ the data, otherwise, postpone
                  // the configuration until `setServicesCmd` has been triggered
                  if (core.services && core.services[endpoint.tokenKey]) {
                    configureSource(endpoint.tokenKey);
                  } else {
                    self.pendingConfiguration.push({
                      name: data.key,
                      fn: configureSource,
                      params: [ endpoint.tokenKey ]
                    });
                  }
                }
                else
                {
                  source.setUrl(endpoint.url);
                  source.params_ = customParams;
                }
      
                let lyr = new ImageLayer({
                  visible: false,
                  preload: 4,
                  zIndex: endpoint.zIndex || 0,
                  opacity: data.opacity || 1,
                  source: source,
                  extent: data.config.value.extent
                });
                console.log ("dynamicLayer6");
                lyr.set('id', data.key);
                return lyr;
              } 
              // IN CASE OF FEATURE SERVICE
              else if (endpointUrl.indexOf("/featureserver") > -1 || endpointUrl.indexOf("featureserver/") > -1){
      
                  let styleCacheGroup = {};   
                  let serviceUrl = endpoint.url;
                  let splUrl = serviceUrl.toString().split("/");
                  let featureLayerName = 
                  splUrl[splUrl.length - 3] + "_" + splUrl[splUrl.length - 1];
                  let layerDefs = endpoint.layerDefs;
                  let tokenKey = endpoint.tokenKey
                  if (endpoint.bbox) {
                    customParams["BBOX"] = endpoint.bbox;
                  }
        
                  if (endpoint.layersToShow) {
                    customParams["LAYERS"] = endpoint.layersToShow;
                  }
        
                  if (layerDefs) {
                    customParams["LAYERDEFS"] = layerDefs
                  }
      
                  //styleCache = {};
                  let styleFunction = function(feature) {
                    let styleCacheForLayer = styleCacheGroup[featureLayerName];
                    if (!styleCacheForLayer) {
                      return new Style({
                        fill: new Fill({
                          color: "rgba(255,0,0,0.5)"
                        }),
                        stroke: new Stroke({
                          color: "rgba(255,0,255,0.75)",
                          width: 4
                        })
                      });
                    } else {
                      let featureStyleValue = feature.get(styleCacheForLayer.field);
                      if (styleCacheForLayer.field.indexOf("simple-") == 0) {
                        featureStyleValue = styleCacheForLayer.field;
                      }
                      if (featureStyleValue != undefined && featureStyleValue != null) {
                        if (styleCacheForLayer.map[featureStyleValue]) {
                          if (
                            feature.getGeometry().getType() == "MultiPolygon" ||
                            feature.getGeometry().getType() == "Polygon"
                          ) {
                            return styleCacheForLayer.map[featureStyleValue];
                          } else if (feature.getGeometry().getType() == "Point") {
                            let returnValue = styleCacheForLayer.map[featureStyleValue];
                            if (returnValue.getImage().iconImage_ != undefined) {
                              //if image points
                              if (styleCacheForLayer.field.indexOf("simple-") == 0) {
                                return styleCacheForLayer.map[featureStyleValue];
                              }
                            } else {
                              let fillColor = returnValue.getImage().getFill().color_;
                              let outlineColor = returnValue.getImage().getStroke().color_;
                              let outlineWidth = returnValue.getImage().getStroke().width_;
                              let sizeInfoVariables = styleCacheForLayer.sizeInfoVariables;
      
                              if (styleCacheForLayer.field.indexOf("simple-") == 0) {
                                return styleCacheForLayer.map[featureStyleValue];
                              } else {
                                let radius = returnValue.getImage().getRadius();
                                if (sizeInfoVariables != null) {
                                  let dataMin = sizeInfoVariables
                                    ? sizeInfoVariables.minDataValue
                                    : 1;
                                  let dataMax = sizeInfoVariables
                                    ? sizeInfoVariables.maxDataValue
                                    : 10000;
                                  let sizeField = sizeInfoVariables
                                    ? sizeInfoVariables.field
                                    : "";
                                  let markerMinSize = sizeInfoVariables
                                    ? sizeInfoVariables.minSize
                                    : 6;
                                  let markerMaxSize = sizeInfoVariables
                                    ? sizeInfoVariables.maxSize
                                    : 20;
                                  let featureSize =
                                    feature.get(sizeField) > dataMax
                                      ? dataMax
                                      : feature.get(sizeField);
                                  featureSize = featureSize < dataMin ? dataMin : featureSize;
                                  radius =
                                    sizeField.length > 0
                                      ? (featureSize - dataMin) *
                                          (markerMaxSize - markerMinSize) /
                                          (dataMax - dataMin) +
                                        markerMinSize
                                      : returnValue.getImage().getRadius();
                                }
                                let markerCircle = new Circle({
                                  radius: radius * 0.7,
                                  fill: new Fill({
                                    color: fillColor
                                  }),
                                  stroke: new Stroke({
                                    color: outlineColor,
                                    width: outlineWidth || 1
                                  })
                                });
                                markerCircle.setOpacity(0.75);
                                let featureStyle = new Style({
                                  image: markerCircle,
                                  zIndex: Infinity
                                });
                                return featureStyle;
                              }
                            }
                          } else if (
                            feature.getGeometry().getType() == "LineString" ||
                            feature.getGeometry().getType() == "MultiLineString"
                          ) {
                            return styleCacheForLayer.map[featureStyleValue];
                          }
                        } else {
                          console.log("Cant find mapped value for " + featureStyleValue);
                          return new Style({
                            image: new Circle({
                              radius: radius,
                              fill: new Fill({
                                color: "rgba(255,0,0,0.5)"
                              }),
                              stroke: new Stroke({
                                color: "rgba(255,0,255,0.75)",
                                width: 1
                              })
                            })
                          });
                        }
                      }
                    }
                  };      
                  let getSizeField = function(visualVariables) {
                    return visualVariables.type == "sizeInfo";
                  };
                  let setImageScale = function(
                    rendType,
                    featureLayerName,
                    oldStyleCache,
                    width
                  ) {
                    var i = new Image();
      
                    i.onload = function() {
                      let newScale = width / i.width * 1.2 || 1;
                      let newStyleCacheMap = new Style({
                        image: new Icon({
                          src: i.src,
                          scale: newScale,
                          rotation:
                            oldStyleCache.map[rendType + "-" + featureLayerName].image_
                              .rotation_
                        }),
                        zIndex: Infinity
                      });
                      oldStyleCache.map = {};
                      oldStyleCache.map[rendType + "-" + featureLayerName] = newStyleCacheMap;
                      styleCacheGroup[featureLayerName] = oldStyleCache;
                    };
                    i.src =
                      oldStyleCache.map[
                        rendType + "-" + featureLayerName
                      ].image_.iconImage_.src_;
                  };
      
                  //This will grab the "styles" and attempt to apply them as per the def in the feature service
                  window
                  .fetch(serviceUrl + "?f=json")
                  .then(resp => {
                    var respJson = null;
                    respJson = resp.json();
                    return respJson;
                  })
                  .then(meta => {
                    console.log (meta.geometryType);
                    if (meta.geometryType == "esriGeometryPolygon") {
                      var rend =
                        (meta && meta.drawingInfo ? meta.drawingInfo.renderer : {}) || {};
                      var field = rend.field1;
                      let styleCache = {};
                      if (field != undefined) {
                        //if it has multiple categories(symbols)
      
                        styleCache.field = field;
                        styleCache.map = {};
                        for (var inf of rend.uniqueValueInfos) {
                          var sym = inf.symbol;
                          var fillColor = `rgba(0,0,0,0)`;
                          if (sym.color != null) {
                            fillColor = `rgba(${sym.color[0]},${sym.color[1]},${
                              sym.color[2]
                            },${sym.color[3] / 255})`;
                          }
      
                          var outlineColor = `rgba(0,0,0,0)`;
                          if (sym.outline.color != null) {
                            outlineColor = `rgba(${sym.outline.color[0]},${
                              sym.outline.color[1]
                            },${sym.outline.color[2]},${sym.outline.color[3] / 255})`;
                          }
                          styleCache.map[inf.value] = new Style({
                            fill: new Fill({
                              color: fillColor
                            }),
                            stroke: new Stroke({
                              color: outlineColor,
                              width: sym.outline.width || 1
                            })
                          });
                        }
                        styleCacheGroup[featureLayerName] = styleCache;
                      } else if (rend["type"] == "simple") {
                        styleCache.field = rend["type"] + "-" + featureLayerName;
                        styleCache.map = {};
                        var sym = rend.symbol;
                        var fillColor = `rgba(0,0,0,0.75)`;
                        if (sym.color != null) {
                          fillColor = `rgba(${sym.color[0]},${sym.color[1]},${
                            sym.color[2]
                          },${sym.color[3] / 255})`;
                        }
      
                        var outlineColor = `rgba(0,0,0,0.5)`;
                        if (sym.outline.color != null) {
                          outlineColor = `rgba(${sym.outline.color[0]},${
                            sym.outline.color[1]
                          },${sym.outline.color[2]},${sym.outline.color[3] / 255})`;
                        }
                        styleCache.map[rend["type"] + "-" + featureLayerName] = new Style({
                          fill: new Fill({
                            color: fillColor
                          }),
                          stroke: new Stroke({
                            color: outlineColor,
                            width: sym.outline.width || 1
                          })
                        });
                        styleCacheGroup[featureLayerName] = styleCache;
                      } else {
                        //alert("see console");
                        console.log("need to update code for single symbol feature service");
                      }
                    } else if (meta.geometryType == "esriGeometryPoint") {
                      var rend =
                        (meta && meta.drawingInfo ? meta.drawingInfo.renderer : {}) || {};
                      var field = rend.field1;
                      let styleCache = {};
                      if (field != undefined) {
                        //if it has multiple categories(symbols)
      
                        let sizeInfoVariables = null;
                        if (rend.visualVariables)
                          sizeInfoVariables = rend.visualVariables.find(getSizeField);
                        styleCache.sizeInfoVariables = sizeInfoVariables;
                        styleCache.field = field;
                        styleCache.map = {};
                        for (var inf of rend.uniqueValueInfos) {
                          var sym = inf.symbol;
                          var fillColor = `rgba(0,0,0,0.5)`;
                          if (sym.color != null) {
                            fillColor = `rgba(${sym.color[0]},${sym.color[1]},${
                              sym.color[2]
                            },${sym.color[3] / 255})`;
                          }
      
                          var outlineColor = `rgba(0,0,0,0.8)`;
                          if (sym.outline.color != null) {
                            outlineColor = `rgba(${sym.outline.color[0]},${
                              sym.outline.color[1]
                            },${sym.outline.color[2]},${sym.outline.color[3] / 255})`;
                          }
                          styleCache.map[inf.value] = new Style({
                            image: new Circle({
                              radius: 4,
                              fill: new Fill({
                                color: fillColor
                              }),
                              stroke: new Stroke({
                                color: outlineColor,
                                width: sym.outline.width || 1
                              })
                            }),
                            zIndex: Infinity
                          });
                        }
                        styleCacheGroup[featureLayerName] = styleCache;
                      } else if (rend["type"] == "simple") {
                        styleCache.field = rend["type"] + "-" + featureLayerName;
                        styleCache.map = {};
                        var sym = rend.symbol;
                        if (sym.imageData != undefined && sym.imageData.length > 0) {
                          styleCache.map[rend["type"] + "-" + featureLayerName] = new Style({
                            image: new Icon({
                              src: "data:image/png;base64, " + sym.imageData,
                              scale: 1,
                              rotation: sym.angle
                            }),
                            zIndex: Infinity
                          });
                          styleCacheGroup[featureLayerName] = styleCache;
                          setImageScale(
                            rend["type"],
                            featureLayerName,
                            styleCache,
                            sym.width
                          );
                        } else {
                          var fillColor = `rgba(0,0,0,0.5)`;
                          if (sym.color != null) {
                            fillColor = `rgba(${sym.color[0]},${sym.color[1]},${
                              sym.color[2]
                            },${sym.color[3] / 255})`;
                          }
      
                          var outlineColor = `rgba(0,0,0,0.8)`;
                          var outlineWidth = 1;
                          if (sym.outline && sym.outline.color != null) {
                            outlineColor = `rgba(${sym.outline.color[0]},${
                              sym.outline.color[1]
                            },${sym.outline.color[2]},${sym.outline.color[3] / 255})`;
                            outlineWidth = sym.outline.width;
                          }
                          styleCache.map[rend["type"] + "-" + featureLayerName] = new Style({
                            image: new Circle({
                              radius: 4,
                              fill: new Fill({
                                color: fillColor
                              }),
                              stroke: new Stroke({
                                color: outlineColor,
                                width: outlineWidth || 1
                              })
                            }),
                            zIndex: Infinity
                          });
                          styleCacheGroup[featureLayerName] = styleCache;
                        }
                      } else {
                        //alert("see console");
                        console.log("need to update code for single symbol feature service");
                      }
                    } else if (meta.geometryType == "esriGeometryPolyline") {
                      var rend =
                        (meta && meta.drawingInfo ? meta.drawingInfo.renderer : {}) || {};
                      var field = rend.field1;
                      let styleCache = {};
                      if (field != undefined) {
                        //if it has multiple categories(symbols)
      
                        styleCache.field = field;
                        styleCache.map = {};
                        for (var inf of rend.uniqueValueInfos) {
                          var sym = inf.symbol;
                          var fillColor = `rgba(0,0,0,0)`;
                          if (sym.color != null) {
                            fillColor = `rgba(${sym.color[0]},${sym.color[1]},${
                              sym.color[2]
                            },${sym.color[3] / 255})`;
                          }
                          styleCache.map[inf.value] = new Style({
                            stroke: new Stroke({
                              color: fillColor,
                              width: sym.width || 1
                            })
                          });
                        }
                        styleCacheGroup[featureLayerName] = styleCache;
                      } else if (rend["type"] == "simple") {
                        styleCache.field = rend["type"] + "-" + featureLayerName;
                        styleCache.map = {};
                        var sym = rend.symbol;
                        var fillColor = `rgba(0,0,0,0.7)`;
                        if (sym.color != null) {
                          fillColor = `rgba(${sym.color[0]},${sym.color[1]},${
                            sym.color[2]
                          },${sym.color[3] / 255})`;
                        }
      
                        styleCache.map[rend["type"] + "-" + featureLayerName] = new Style({
                          stroke: new Stroke({
                            color: fillColor,
                            width: sym.width || 1
                          })
                        });
                        styleCacheGroup[featureLayerName] = styleCache;
                      } else {
                        //alert("see console");
                        console.log("need to update code for single symbol feature service");
                      }
                    }
                  });
                  
                  let esrijsonFormat = new EsriJSON();

                  let source = new VectorSource({
                    loader: function(extent, resolution, projection) {
                      var nurl =
                        serviceUrl +
                        "/query/?f=json&" +
                        "returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=" +
                        encodeURIComponent(
                          '{"xmin":' +
                            extent[0] +
                            ',"ymin":' +
                            extent[1] +
                            ',"xmax":' +
                            extent[2] +
                            ',"ymax":' +
                            extent[3] +
                            ',"spatialReference":{"wkid":102100}}'
                        ) +
                        "&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*" +
                        "&outSR=102100";
                      
                      window
                        .fetch(nurl)
                        .then(response => {
                          return response.text();
                        })
                        .then(txt => {
                          var features = esrijsonFormat.readFeatures(txt, {
                            featureProjection: projection
                          });
                          if (features.length > 0) {
                            source.addFeatures(features);
                          }
                        });
                    },
                    strategy: tileStrategy(
                      createXYZ({
                        tileSize: 512
                      })
                    )
                  });
                  let configureSource = function (tokenKey) {
                    if (core.services && core.services[tokenKey]) {
                      let tokenData = core.services[tokenKey];
                      source.setUrl(`${tokenData.baseUrl || ""}${serviceUrl}`);
                      source.setTileLoadFunction(function (imageTile, src) {
                        imageTile.getImage().src = `${src}?token=${tokenData.token || ""}`;
                      });
                    }
                  }
      
                  if (tokenKey) {
                    // if the token data has already been fetched and stored in core.services
                    // go ahead and configure the source w/ the data, otherwise, postpone
                    // the configuration until `setServicesCmd` has been triggered
                    if (core.services && core.services[tokenKey]) {
                      configureSource(tokenKey);
                    } else {
                      self.pendingConfiguration.push({
                        name: data.key,
                        fn: configureSource,
                        params: [ tokenKey ]
                      });
                    }
                  }
      
      
                  let lyr = new VectorLayer({
                    visible: false,
                    source: source,
                    zIndex: endpoint.zIndex || 0,
                    style: function(feature) {
                      return styleFunction(feature);
                    }
                  });
                  
                  lyr.set('id', data.key);
      
                  return lyr;       
              }
              
            });
  
                 

          return groupLayers(layers);
  
        default: 
          throw new Error(`Layer type '${data.config.type}' has not been implemented.`);
      }
    }
    catch(err) {
      console.log(err);
    }
    
  }
  render() {}
}
