import * as util from './layerHelpers.js'



/**
 * BASE LAYERS
 */

const esriLightGray = {
  key: "lyr_esri_light_gray",
  name: "Light Gray",
  opacity: 1,
  xyz: {
    endpoints: [{
      "url": "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
      "z-index": 10
    }, {
      "url": "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer/tile/{z}/{y}/{x}",
      "z-index": 1000
    }],
    maxZoom: 21,
    minZoom: 3
  }
}

const esriWorldImage = {
  key: "lyr_esri_world_image",
  name: "Aerial",
  opacity: 1,
  xyz: {
    endpoints: [{
      "url":
        "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      "z-index": 10
    }, {
      "url":
        "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer/tile/{z}/{y}/{x}",
      "z-index": 1000
    }, {
      "url":
        "https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}",
      "z-index": 999
    }],
    maxZoom: 21,
    minZoom: 3
  }
};

const esriStreets = {
  key: "lyr_esri_streets",
  name: "Streets",
  opacity: 1,
  xyz: {
    endpoints: [{
      "url": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      "z-index": 10
    }],
    maxZoom: 21,
    minZoom: 3
  }
}

const esriTopographic = {
  key: "lyr_esri_topo",
  name: "Topo",
  xyz: {
    endpoints: [{
      "url": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
      "z-index": 10
    }],
    maxZoom: 21,
    minZoom: 3
  }
}



const wildfireRisk = {
  key: "lyr_wildfire_risk",
  name: "Wildfire Risk",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("fireriskindex")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren, or Water",
      color: {r:255,g:255,b:255}
    },
    {
      label: "Very Very Low",
      color: {r:150,g:173,b:127}
    },
    {
      label: "Very Low",
      color: {r:186,g:215,b:168}
    },
    {
      label: "Low",
      color: {r:215,g:207,b:158}
    },
    {
      label: "Low - Moderate",
      color: {r:255,g:255,b:190}
    },
    {
      label: "Moderate",
      color: {r:255,g:211,b:127}
    },
    {
      label: "Moderate - High",
      color: {r:255,g:170,b:0}
    },
    {
      label: "High",
      color: {r:255,g:85,b:0}
    },
    {
      label: "Very High",
      color: {r:207,g:0,b:0}
    },
    {
      label: "Extreme",
      color: {r:115,g:0,b:0}
    }
  ]
};

const wildfireThreat = {
  key: "lyr_wildfire_threat",
  name: "Wildfire Threat",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("firethreatindex")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    { label: "1 - Very Very Low", color: "#96ad7f" },
    { label: "2 - Very Low", color: "#bad7a8" },
    { label: "3 - Low", color: "#d7cf9e" },
    { label: "4 - Low - Moderate", color: "#ffffbe" },
    { label: "5 - Moderate", color: "#ffd37f" },
    { label: "6 - Moderate - High", color: "#ffaa00" },
    { label: "7 - High", color: "#ff5500" },
    { label: "8 - Very High", color: "#cf0000" },
    { label: "9 - Extreme", color: "#730000" }
  ]
};

const wildfireEffects = {
  key: "lyr_wildfire_effects",
  name: "Wildfire Effects",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("fireeffectsindex")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    { label: "1 - Very Very Low", color: "#96ad7f" },
    { label: "2 - Very Low", color: "#bad7a8" },
    { label: "3 - Low", color: "#d7cf9e" },
    { label: "4 - Low - Moderate", color: "#ffffbe" },
    { label: "5 - Moderate", color: "#ffd37f" },
    { label: "6 - Moderate - High", color: "#ffaa00" },
    { label: "7 - High", color: "#ff5500" },
    { label: "8 - Very High", color: "#cf0000" },
    { label: "9 - Extreme", color: "#730000" }
  ]
};

const suppressionDifficulty = {
  key: "lyr_suppress_diff",
  name: "Suppression Difficulty",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("suppressiondifficultyrating")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Fast Production Rate",
      color: "#96ad7f"
    },
    {
      label: "Med Production Rate 0-25% Slope",
      color: "#bad7a8"
    },
    {
      label: "Fast Production Rate 26-40% Slope",
      color: "#d7cf9e"
    },
    {
      label: "Slow Production Rate 0-25% Slope",
      color: "#ffffbe"
    },
    {
      label: "Med Production Rate 26-40% Slope",
      color: "#ffff73"
    },
    {
      label: "Slow Production Rate 26-40% Slope",
      color: "#ffff00"
    },
    {
      label: "Fast Production Rate 41-55% Slope",
      color: "#ffd37f"
    },
    {
      label: "Med Production Rate 41-55% Slope",
      color: "#ffaa00"
    },
    {
      label: "Slow Production Rate 41-55% Slope",
      color: "#e69800"
    },
    {
      label: "Med Production Rate 56-74% Slope",
      color: "#ff5500"
    },
    {
      label: "Fast Production Rate 56-74% Slope",
      color: "#FF0000"
    },
    {
      label: "Med Production Rate 75%+ Slope",
      color: "#cf0000"
    },
    {
      label: "Slow Production Rate 56-74% Slope",
      color: "#a80000"
    },
    {
      label: "Slow & Fast Production Rate 75%+ Slope",
      color: "#730000"
    }
  ]
};

const aggregateValueImpacts = {
  key: "lyr_agg_val_impacts",
  name: "Aggregate Value Impacts",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("valuesimpactedrating")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Outside All VI Areas",
      color: "#ffffff"
    },
    {
      label: "1 - Least Negative Impact",
      color: "#96ad7f"
    },
    {
      label: "2",
      color: "#bad7a8"
    },
    {
      label: "3",
      color: "#d7cf9e"
    },
    {
      label: "4",
      color: "#ffffbe"
    },
    {
      label: "5",
      color: "#ffd37f"
    },
    {
      label: "6",
      color: "#ffaa00"
    },
    {
      label: "7",
      color: "#ff5500"
    },
    {
      label: "8",
      color: "#cf0000"
    },
    {
      label: "9 - Most Negative Impact",
      color: "#730000"
    }
  ]
};

const wildlandDevelopmentAreaImpacts = {
  key: "lyr_wild_devarea_impacts",
  name: "Wildland Development Area Impacts",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("wildlanddevelopmentareasrfs")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Outside VI Area",
      color: "#ffffff"
    },
    {
      label: "1 - Least Negative Impact",
      color: "#96ad7f"
    },
    {
      label: "2",
      color: "#bad7a8"
    },
    {
      label: "3",
      color: "#d7cf9e"
    },
    {
      label: "4",
      color: "#ffffbe"
    },
    {
      label: "5",
      color: "#ffd37f"
    },
    {
      label: "6",
      color: "#ffaa00"
    },
    {
      label: "7",
      color: "#ff5500"
    },
    {
      label: "8",
      color: "#cf0000"
    },
    {
      label: "9 - Most Negative Impact",
      color: "#730000"
    }
  ]
};

const infrastructureImpacts = {
  key: "lyr_infra_impacts",
  name: "Infrastructure Impacts",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("infrastructurerfs")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Outside VI Area",
      color: "#ffffff"
    },
    {
      label: "1 Least Negative Impact",
      color: "#96ad7f"
    },
    {
      label: "2",
      color: "#bad7a8"
    },
    {
      label: "3",
      color: "#d7cf9e"
    },
    {
      label: "4",
      color: "#ffffbe"
    },
    {
      label: "5",
      color: "#ffd37f"
    },
    {
      label: "6",
      color: "#ffaa00"
    },
    {
      label: "7",
      color: "#ff5500"
    },
    {
      label: "8",
      color: "#cf0000"
    },
    {
      label: "9 Most Negative Impact",
      color: "#730000"
    }
  ]
};

const forestAssetsImpacts = {
  key: "lyr_frst_assets_impacts",
  name: "Forest Assets Impacts",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("forestassetsrfs")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Outside VI Area",
      color: "#ffffff"
    },
    {
      label: "1 Least Negative Impact",
      color: "#96ad7f"
    },
    {
      label: "2",
      color: "#bad7a8"
    },
    {
      label: "3",
      color: "#d7cf9e"
    },
    {
      label: "4",
      color: "#ffffbe"
    },
    {
      label: "5",
      color: "#ffd37f"
    },
    {
      label: "6",
      color: "#ffaa00"
    },
    {
      label: "7",
      color: "#ff5500"
    },
    {
      label: "8",
      color: "#cf0000"
    },
    {
      label: "9 Most Negative Impact",
      color: "#730000"
    }
  ]
};

const riparianAssetsImpacts = {
  key: "lyr_rip_assets_impacts",
  name: "Riparian Assets Impacts",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("riparianassetsrfs")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Outside VI Area",
      color: "#ffffff"
    },
    {
      label: "1 Least Negative Impact",
      color: "#96ad7f"
    },
    {
      label: "2",
      color: "#bad7a8"
    },
    {
      label: "3",
      color: "#d7cf9e"
    },
    {
      label: "4",
      color: "#ffffbe"
    },
    {
      label: "5",
      color: "#ffd37f"
    },
    {
      label: "6",
      color: "#ffaa00"
    },
    {
      label: "7",
      color: "#ff5500"
    },
    {
      label: "8",
      color: "#cf0000"
    },
    {
      label: "9 Most Negative Impact",
      color: "#730000"
    }
  ]
};

const drinkingWaterImpacts = {
  key: "lyr_drink_wat_impacts",
  name: "Drinking Water Impacts",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("drinkingwaterimportanceareasrfs")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "1 Least Negative Impact",
      color: "#96ad7f"
    },
    {
      label: "2",
      color: "#bad7a8"
    },
    {
      label: "3",
      color: "#d7cf9e"
    },
    {
      label: "4",
      color: "#ffffbe"
    },
    {
      label: "5",
      color: "#ffd37f"
    },
    {
      label: "6",
      color: "#ffaa00"
    },
    {
      label: "7",
      color: "#ff5500"
    },
    {
      label: "8",
      color: "#cf0000"
    },
    {
      label: "9 Most Negative Impact",
      color: "#730000"
    }
  ]
};

const surfaceFuels = {
  key: "lyr_surf_fuels",
  name: "Surface Fuels",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("surfacefuels")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "NB01 - 91 - Urban/Developed",
      color: "#b2b2b2"
    },
    {
      label: "NB02 - 92 - Snow/Ice",
      color: "#e1e1e1"
    },
    {
      label: "NB03 - 93 - Agriculture",
      color: "#ffbebe"
    },
    {
      label: "NB08 - 98 - Water",
      color: "#0070ff"
    },
    {
      label: "NB09 - 99 - Barren",
      color: "#000000"
    },
    {
      label: "GR01 - 101 - Short, sparse, dry climate grass",
      color: "#e8beff"
    },
    {
      label: "GR02 - 102 - Low load, dry climate grass",
      color: "#d69dbc"
    },
    {
      label: "GR03 - 103 - Low load, very coarse, humid climate grass",
      color: "#ff73df"
    },
    {
      label: "GR04 - 104 - Moderate load, dry climate grass",
      color: "#f57ab6"
    },
    {
      label: "GR05 - 105 - Low load, humid climate grass",
      color: "#e600a9"
    },
    {
      label: "GR06 - 106 - Moderate load, humid climate grass",
      color: "#cd6699"
    },
    {
      label: "GR07 - 107 - High load, dry climate grass",
      color: "#a80084"
    },
    {
      label: "GR08 - 108 - High load, very coarse, humid climate grass",
      color: "#894465"
    },
    {
      label: "GR09 - 109 - Very high load, humid climate grass",
      color: "#73004c"
    },
    {
      label: "GS01 - 121 - Low load, dry climate grass-shrub",
      color: "#ffebaf"
    },
    {
      label: "GS02 - 122 - Moderate load, dry climate grass-shrub",
      color: "#ffd37f"
    },
    {
      label: "GS03 - 123 - Moderate load, humid climate grass-shrub",
      color: "#e69800"
    },
    {
      label: "GS04 - 124 - High load, humid climate grass-shrub",
      color: "#a87000"
    },
    {
      label: "SH01 - 141 - Low load, dry climate shrub",
      color: "#ffebbe"
    },
    {
      label: "SH02 - 142 - Moderate load, dry climate shrub",
      color: "#f5a27a"
    },
    {
      label: "SH03 - 143 - Moderate load, humid climate shrub",
      color: "#ffa77f"
    },
    {
      label: "SH04 - 144 - Low load, humid climate timber-shrub",
      color: "#cd8966"
    },
    {
      label: "SH05 - 145 - High load, humid climate grass-shrub",
      color: "#ff5500"
    },
    {
      label: "SH06 - 146 - Low load, humid climate shrub",
      color: "#e64c00"
    },
    {
      label: "SH07 - 147 - Very high load, dry climate shrub",
      color: "#a83800"
    },
    {
      label: "SH08 - 148 - High load, humid climate shrub",
      color: "#732600"
    },
    {
      label: "SH09 - 149 - Very high load, humid climate shrub",
      color: "#e60000"
    },
    {
      label: "TU01 - 161 - Light load, dry climate timber-grass-shrub",
      color: "#d3ffbe"
    },
    {
      label: "TU02 - 162 - Moderate load, humid climate timber-shrub",
      color: "#b4d79e"
    },
    {
      label: "TU03 - 163 - Moderate load, humid climate timber-grass-shrub",
      color: "#a3ff73"
    },
    {
      label: "TU04 - 164 - Dwarf conifer understory",
      color: "#55ff00"
    },
    {
      label: "TU05 - 165 - Very high load, dry climate timber-shrub",
      color: "#5c8944"
    },
    {
      label: "TL01 - 181 - Low load, compact conifer litter",
      color: "#beffe8"
    },
    {
      label: "TL02 - 182 - Low load, broadleaf litter",
      color: "#73ffdf"
    },
    {
      label: "TL03 - 183 - Moderate load, conifer litter",
      color: "#7ab6f5"
    },
    {
      label: "TL04 - 184 - Small downed logs",
      color: "#00c5ff"
    },
    {
      label: "TL05 - 185 - High load, conifer litter",
      color: "#6699cd"
    },
    {
      label: "TL06 - 186 - Moderate load, broadleaf litter",
      color: "#00a9e6"
    },
    {
      label: "TL07 - 187 - Large downed logs",
      color: "#0084a8"
    },
    {
      label: "TL08 - 188 - Long-needle litter",
      color: "#9eaad7"
    },
    {
      label: "TL09 - 189 - Very high load, broadleaf litter",
      color: "#7a8ef5"
    },
    {
      label: "SB01 - 201 - Low load, activity fuel",
      color: "#d79e9e"
    },
    {
      label: "SB02 - 202 - Moderate load, activity fuel or low load, blowdown",
      color: "#f57a7a"
    },
    {
      label: "SB03 - 203 - High load, activity fuel or moderate load, blowdown",
      color: "#cd6666"
    },
    {
      label: "SB04 - 204 - High load, blowdown",
      color: "#894444"
    }
  ]
};

const percentSlope = {
  key: "lyr_perc_slope",
  name: "Percent Slope",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("slope")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "0-9%",
      color: "#ffffff"
    },
    {
      label: "10-25%",
      color: "#c7d79e"
    },
    {
      label: "26-40%",
      color: "#ffff73"
    },
    {
      label: "41-55%",
      color: "#ffaa00"
    },
    {
      label: "56-75%",
      color: "#e60000"
    },
    {
      label: ">75%",
      color: "#a80000"
    }
  ]
};

const weatherInfluenceZones = {
  key: "lyr_wthr_influ_zones",
  name: "Weather Influence Zones",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("weatherinfluenceareas")],
    extent: util.defaultExtent
  }
};

const weatherStations = {
  key: "lyr_wthr_stations",
  name: "Weather Stations",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("weatherstation")],
    extent: util.defaultExtent
  }
};

const vegetation = {
  key: "lyr_vegetation",
  name: "Vegetation",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("vegetation")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Agriculture",
      color: "#ffbebe"
    },
    {
      label: "Barren",
      color: "#000000"
    },
    {
      label: "Water",
      color: "#0070ff"
    },
    {
      label: "Developed",
      color: "#ffffff"
    },
    {
      label: "Sparse Vegetation",
      color: "#e8beff"
    },
    {
      label: "Grassland",
      color: "#e600a9"
    },
    {
      label: "Exotic Herb",
      color: "#ff73df"
    },
    {
      label: "Riparian",
      color: "#7ab6f5"
    },
    {
      label: "Hardwood",
      color: "#d1ff73"
    },
    {
      label: "Mixed Fir Forest",
      color: "#aaff00"
    },
    {
      label: "Pine Forest",
      color: "#41a800"
    },
    {
      label: "Subalpine Forest",
      color: "#267300"
    },
    {
      label: "Pinyon-Juniper",
      color: "#ff5500"
    },
    {
      label: "Mountain Mahogany",
      color: "#ffa77f"
    },
    {
      label: "Desert Scrub/Steppe",
      color: "#ffaa00"
    },
    {
      label: "Shrubland",
      color: "#a87000"
    },
    {
      label: "Gamble Oak",
      color: "#e60000"
    },
    {
      label: "Sage Shrub/Steppe",
      color: "#737300"
    },
    {
      label: "Chaparral",
      color: "#730000"
    }
  ]
};

const wildlandDevelopmentArea = {
  key: "lyr_wild_dev_area",
  name: "Wildland Development Areas (WUI)",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("wildlanddevelopmentareasreclassed")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Not in WDA",
      color: "#ffffff"
    },
    {
      label: "Urban not in WDA",
      color: "#e1e1e1"
    },
    {
      label: "Less than 1 house /40 acres",
      color: "#828282"
    },
    {
      label: "1 house /40 acres to 1 house /20 acres",
      color: "#73ffdf"
    },
    {
      label: "1 house /20 acres to 1 house /10 acres",
      color: "#00e6a9"
    },
    {
      label: "1 house /10 acres to 1 house /5 acres",
      color: "#ffbee8"
    },
    {
      label: "1 house /5 acres to 1 house /2 acres",
      color: "#ff00c5"
    },
    {
      label: "1 house /2 acres to 3 houses /acre",
      color: "#8116d9"
    },
    {
      label: "More than 3 houses /acre",
      color: "#071dad"
    }
  ]
};

const wherePeopleLive = {
  key: "lyr_where_ppl_live",
  name: "Where People Live",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("wherepeoplelive")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Less than 1 house / 40 acres",
      color: "#e1e1e1"
    },
    {
      label: "1 house / 40 acres to 1 house / 20 acres",
      color: "#73ffdf"
    },
    {
      label: "1 house / 20 acres to 1 house / 10 acres",
      color: "#00e6a9"
    },
    {
      label: "1 house / 10 acres to 1 house / 5 acres",
      color: "#ffbee8"
    },
    {
      label: "1 house / 5 acres to 1 house / 2 acres",
      color: "#ff00c5"
    },
    {
      label: "1 house / 2 acres to 3 houses / acre",
      color: "#8116d9"
    },
    {
      label: "More than 3 houses / acre",
      color: "#071dad"
    }
  ]
};

const infrastructureAssets = {
  key: "lyr_infra_assets",
  name: "Infrastructure Assets",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("infrastructureassets")],
    extent: util.defaultExtent
  }
};

const forestAssets = {
  key: "lyr_forest_assets",
  name: "Forest Assets",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("forestassets")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label:
        "Fire Response = Sensitive Canopy Cover = Closed Canopy Height = 0-10 meters",
      color: "#ff0000"
    },
    {
      label:
        "Fire Response = Sensitive Canopy Cover = Closed Canopy Height = 10+ meters",
      color: "#ff7300"
    },
    {
      label:
        "Fire Response = Sensitive Canopy Cover = Open Canopy Height = 0-10 meters",
      color: "#ffb300"
    },
    {
      label:
        "Fire Response = Sensitive Canopy Cover = Open Canopy Height = 10+ meters",
      color: "#ffdb00"
    },
    {
      label:
        "Fire Response = Resilient Canopy Cover = Closed Canopy Height = 0-10 meters",
      color: "#004da8"
    },
    {
      label:
        "Fire Response = Resilient Canopy Cover = Closed Canopy Height = 10+ meters",
      color: "#0070ff"
    },
    {
      label:
        "Fire Response = Resilient Canopy Cover = Open Canopy Height = 0-10 meters",
      color: "#00baff"
    },
    {
      label:
        "Fire Response = Resilient Canopy Cover = Open Canopy Height = 10+ meters",
      color: "#addeff"
    },
    {
      label:
        "Fire Response = Adaptive Canopy Cover = Closed Canopy Height = 0-10 meters",
      color: "#389400"
    },
    {
      label:
        "Fire Response = Adaptive Canopy Cover = Closed Canopy Height = 10+ meters",
      color: "#7fbf00"
    },
    {
      label:
        "Fire Response = Adaptive Canopy Cover = Open Canopy Height = 0-10 meters",
      color: "#bfeb00"
    },
    {
      label:
        "Fire Response = Adaptive Canopy Cover = Open Canopy Height = 10+ meters",
      color: "#deff78"
    }
  ]
};

const forestAssetsResponseToFire = {
  key: "lyr_forest_asset_resp_fire",
  name: "Forest Assets Response to Fire",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("forestassetsresponsetofire")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Sensitive",
      color: "#ff0000"
    },
    {
      label: "Resilient",
      color: "#004da8"
    },
    {
      label: "Adaptive",
      color: "#389400"
    }
  ]
};

const riparianAssets = {
  key: "lyr_rip_assets",
  name: "Riparian Assets",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("riparianassets")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Least Sensitive to Wildland Fires",
      color: "#55ff00"
    },
    {
      label: "Moderately Sensitive to Wildland Fires",
      color: "#ffaa00"
    },
    {
      label: "Most Sensitive to Wildland Fires",
      color: "#ff0000"
    }
  ]
};

const drinkingWaterAssets = {
  key: "lyr_drink_wat_assets",
  name: "Drinking Water Assets",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("drinkingwaterimportanceareas")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "1 - Lowest",
      color: "#ffd37f"
    },
    {
      label: "2",
      color: "#ffff00"
    },
    {
      label: "3",
      color: "#e6e600"
    },
    {
      label: "4",
      color: "#a3ff73"
    },
    {
      label: "5",
      color: "#79c900"
    },
    {
      label: "6",
      color: "#70a800"
    },
    {
      label: "7",
      color: "#beffe8"
    },
    {
      label: "8",
      color: "#73b2ff"
    },
    {
      label: "9",
      color: "#0084a8"
    },
    {
      label: "10 - Highest",
      color: "#004c73"
    }
  ]
};

const rateOfSpreadExpected = {
  key: "lyr_rate_spread_expct",
  name: "Rate of Spread - Expected",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("characteristicrateofspread")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Very Low < 5.5 ft. / min.",
      color: "#b9d7a8"
    },
    {
      label: "Low 5.5 to 10.9 ft. / min.",
      color: "#d7cf9e"
    },
    {
      label: "Low - Moderate 11.0 to 16.4 ft. / min.",
      color: "#ffffbe"
    },
    {
      label: "Moderate,  16.5 to 21.9 ft. / min.",
      color: "#ffd37f"
    },
    {
      label: "Moderate - High 22.0 to 32.9 ft. / min.",
      color: "#ffaa00"
    },
    {
      label: "High 33.0 to 43.9 ft. / min.",
      color: "#ff5500"
    },
    {
      label: "Very High 44.0 to 54.9 ft. / min.",
      color: "#cf0000"
    },
    {
      label: "Extreme  >= 55.0 ft. / min.",
      color: "#730000"
    }
  ]
};

const rateOfSpreadHigh = {
  key: "lyr_rate_spread_hi",
  name: "Rate of Spread - High",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("roshighweather")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Very Low < 5.5 ft. / min.",
      color: "#b9d7a8"
    },
    {
      label: "Low 5.5 to 10.9 ft. / min.",
      color: "#d7cf9e"
    },
    {
      label: "Low - Moderate 11.0 to 16.4 ft. / min.",
      color: "#ffffbe"
    },
    {
      label: "Moderate 16.5 to 21.9 ft. / min.",
      color: "#ffd37f"
    },
    {
      label: "Moderate - High 22.0 to 32.9 ft. / min.",
      color: "#ffaa00"
    },
    {
      label: "High 33.0 to 43.9 ft. / min.",
      color: "#ff5500"
    },
    {
      label: "Very High 44.0 to 54.9 ft. / min.",
      color: "#cf0000"
    },
    {
      label: "Extreme >= 55.0 ft. / min.",
      color: "#730000"
    }
  ]
};

const flameLengthExpected = {
  key: "lyr_flame_len_expct",
  name: "Flame Length - Expected",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("characteristicflamelength")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Very Low < 2.0 feet",
      color: "#d7cf9e"
    },
    {
      label: "Low 2.0 - 3.9 feet",
      color: "#ffffbe"
    },
    {
      label: "Moderate 4.0 - 7.9 feet",
      color: "#ffd37f"
    },
    {
      label: "High 8.0 - 11.9 feet",
      color: "#ffaa00"
    },
    {
      label: "Very High 12.0 - 19.9 feet",
      color: "#ff5500"
    },
    {
      label: "Very, Very High 20.0 - 29.9 feet",
      color: "#cf0000"
    },
    {
      label: "Extreme >= 30 feet",
      color: "#730000"
    }
  ]
};

const flameLengthHigh = {
  key: "lyr_flame_len_hi",
  name: "Flame Length - High",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("flhighweather")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Low, < 2.0",
      color: "#d7cf9e"
    },
    {
      label: "Low - Mod, 2.0 - 3.9",
      color: "#ffffbe"
    },
    {
      label: "Moderate, 4.0 - 7.9",
      color: "#ffd37f"
    },
    {
      label: "Mod - High, 8.0 - 11.9",
      color: "#ffaa00"
    },
    {
      label: "High, 12.0 - 19.9",
      color: "#ff5500"
    },
    {
      label: "Very High, 20.0 - 29.9",
      color: "#cf0000"
    },
    {
      label: "Extreme, >= 30",
      color: "#730000"
    }
  ]
};

const probabilityOfCanopyFire = {
  key: "lyr_prob_canopy_fire",
  name: "Probability of Canopy Fire",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("probabilityofcanopyfire")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "0",
      color: "#ffffff"
    },
    {
      label: "0.001 - 0.25",
      color: "#a3ff73"
    },
    {
      label: "0.25 - 0.5",
      color: "#ffffbf"
    },
    {
      label: "0.5 - 0.75",
      color: "#f59869"
    },
    {
      label: "0.75 - 1",
      color: "#a80000"
    }
  ]
};

const fireTypeHigh = {
  key: "lyr_fire_type_hi",
  name: "Fire Type - High",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("ftypehighweather")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Surface Fire Type",
      color: "#38a800"
    },
    {
      label: "Passive Fire Type",
      color: "#ffaa00"
    },
    {
      label: "Active Fire Type",
      color: "#e60000"
    }
  ]
};

const fireTypeExtreme = {
  key: "lyr_fire_type_xtrm",
  name: "Fire Type - Extreme",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("ftypeextremeweather")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Surface Fire Type",
      color: "#38a800"
    },
    {
      label: "Passive Fire Type",
      color: "#ffaa00"
    },
    {
      label: "Active Fire Type",
      color: "#e60000"
    }
  ]
};

const fireOccurrenceDensity = {
  key: "lyr_fire_occ_dense",
  name: "Fire Occurrence Density",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("fireoccurrencedensity")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Very Very Low",
      color: "#96ad7f"
    },
    {
      label: "Very Low",
      color: "#bad7a8"
    },
    {
      label: "Low",
      color: "#d7cf9e"
    },
    {
      label: "Low - Moderate",
      color: "#ffffbe"
    },
    {
      label: "Moderate",
      color: "#ffd37f"
    },
    {
      label: "Moderate - High",
      color: "#ffaa00"
    },
    {
      label: "High",
      color: "#ff5500"
    },
    {
      label: "Very High",
      color: "#cf0000"
    },
    {
      label: "Extreme",
      color: "#730000"
    }
  ]
};

const locationSpecificIgnitions = {
  key: "lyr_loc_spec_ignit",
  name: "Location Specific Ignition",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("firelocations")],
    extent: util.defaultExtent
  }
};

const ignitionsAndFireOccurrenceDensity = {
  key: "lyr_ignit_fire_occ_dense",
  name: "Ignitions & Fire Occurrence Density",
  opacity: 1.0,
  wmts: {
    endpoints: [
      util.buildWmtsLayer("fireoccurrencedensity"),
      util.buildWmtsLayer("firelocations")
    ],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Very Very Low",
      color: "#96ad7f"
    },
    {
      label: "Very Low",
      color: "#bad7a8"
    },
    {
      label: "Low",
      color: "#d7cf9e"
    },
    {
      label: "Low - Moderate",
      color: "#ffffbe"
    },
    {
      label: "Moderate",
      color: "#ffd37f"
    },
    {
      label: "Moderate - High",
      color: "#ffaa00"
    },
    {
      label: "High",
      color: "#ff5500"
    },
    {
      label: "Very High",
      color: "#cf0000"
    },
    {
      label: "Extreme",
      color: "#730000"
    }
  ]
};

const nfirsFireOccurrenceDensity = {
  key: "lyr_nfirs_fire_occ_dense",
  name: "NFIRS Fire Occurrence Density",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("nfirsfireoccurrencedensity")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Urban, Agriculture, Barren or Water",
      color: "#ffffff"
    },
    {
      label: "Very Very Low",
      color: "#96ad7f"
    },
    {
      label: "Very Low",
      color: "#bad7a8"
    },
    {
      label: "Low",
      color: "#d7cf9e"
    },
    {
      label: "Low - Moderate",
      color: "#ffffbe"
    },
    {
      label: "Moderate",
      color: "#ffd37f"
    },
    {
      label: "Moderate - High",
      color: "#ffaa00"
    },
    {
      label: "High",
      color: "#ff5500"
    },
    {
      label: "Very High",
      color: "#cf0000"
    },
    {
      label: "Extreme",
      color: "#730000"
    }
  ]
};

const landOwnershipOrManagement = {
  key: "lyr_land_own_mgmt",
  name: "Land Ownership or Management",
  opacity: 1.0,
  wmts: {
    endpoints: [util.buildWmtsLayer("landownershipormanagement")],
    extent: util.defaultExtent
  },
  legend: [
    {
      label: "Private",
      color: "#ffffff"
    },
    {
      label: "Bureau of Reclamation",
      color: "#ffffb2"
    },
    {
      label: "Bankhead-Jones Land Use Lands",
      color: "#fccdcf"
    },
    {
      label: "National Recreation Area",
      color: "#cd8966"
    },
    {
      label: "National Parks, Monuments & Historic Sites",
      color: "#caa6de"
    },
    {
      label: "National Forest",
      color: "#89cd66"
    },
    {
      label: "National Wilderness Area",
      color: "#267300"
    },
    {
      label: "National Wildlife Refuge",
      color: "#7fcca7"
    },
    {
      label: "Other Federal",
      color: "#e5ccb2"
    },
    {
      label: "Military Reservations & Corps of Engineers",
      color: "#fbb4ce"
    },
    {
      label: "Bureau of Land Management",
      color: "#fee679"
    },
    {
      label: "State Trust Lands",
      color: "#73b2ff"
    },
    {
      label: "State Sovereign Land",
      color: "#adc9de"
    },
    {
      label: "State Parks and Recreation",
      color: "#8893ba"
    },
    {
      label: "State Wildlife Reserve/Management Area",
      color: "#c2b88f"
    },
    {
      label: "Other State",
      color: "#beffe8"
    },
    {
      label: "Tribal Lands",
      color: "#fdb46c"
    }
  ]
};

/**
 * FAM LAYERS
 */
const famOutlines = {
  key: "lyr_fam_outlines",
  name: "Outlines",
  opacity: 1,
  esriExport: {
    endpoints: [
      {
        url: "MapServer/",
        "z-index": 100,
        tokenKey: "Timmons Group AGS FAM",
        layerDefs:
          '{"0":"1=1","1":"0=1","2":"0=1","3":"0=1","4":"0=1","5":"0=1","6":"0=1","7":"0=1","8":"0=1","9":"0=1"}'
      }
    ],
    extent: util.defaultExtent
  }
};

const famActions = {
  key: "lyr_fam_actions_all",
  name: "Actions",
  opacity: 1,
  esriExport: {
    endpoints: [
      // complete
      {
        url: "MapServer/",
        "z-index": 100,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:4",
      },
      // on hold
      {
        url: "MapServer/",
        "z-index": 100,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:3",
      },
      // in progress
      {
        url: "MapServer/",
        "z-index": 100,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:2",
      },
      // planning
      {
        url: "MapServer/",
        "z-index": 100,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:1",
      }
    ],    
    extent: util.defaultExtent
  },
  identify: {
    enabled: true,
    dataMapping: {
      title: { name: "Name", query: "name" },
      fields: [
        { name: "Name", query: "name" },
        { name: "Type", static: "Action" },
        { name: "Status", map: { query: "status", values: { "59":"Complete", "61":"On-Hold", "60":"In Progress", "58":"On Hold"  } } }
      ]
    },
    canViewDetails: true,
    queryLayerIds: [1,2,3,4]
  }
};

const famFocusAreas = {
  key: "lyr_fam_focus_areas",
  name: "Focus Areas",
  opacity: 1,
  esriExport: {
    endpoints: [
      {
        url: "MapServer/",
        "z-index": 80,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:9",
      }
    ],    
    extent: util.defaultExtent
  },
  identify: {
    enabled: true,
    dataMapping: {
      title: { name: "Name", query: "name" },
      fields: [
        { name: "Name", query: "name" },
        { name: "Type", static: "Focus Area" }
      ]
    },
    canViewDetails: true,
    queryLayerIds: [ 9 ]
  }
};

const famProjects = {
  key: "lyr_fam_projects_all",
  name: "Projects",
  opacity: 1,
  esriExport: {
    endpoints: [
      {
        // complete
        url: "MapServer/",
        "z-index": 90,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:8",
      },
      // on hold
      {
        url: "MapServer/",
        "z-index": 90,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:7",
      },
      // in progress
      {
        url: "MapServer/",
        "z-index": 90,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:6",
      },
      // planning
      {
        url: "MapServer/",
        "z-index": 90,
        tokenKey: "Timmons Group AGS FAM",
        layersToShow: "show:5",
      }
    ],
    
    extent: util.defaultExtent
  },
  identify: {
    enabled: true,
    dataMapping: {
      title: { name: "Name", query: "name" },
      fields: [
        { name: "Name", query: "name" },
        { name: "Type", static: "Project" },
        { name: "Status", map: { query: "status", values: { "18":"Complete","19":"In Progress","20":"On-Hold","17":"Planning" } } }
      ]
    },
    canViewDetails: true,
    queryLayerIds: [5,6,7,8]
  }
};

const cwsActions = {
  key: "lyr_cws_actions",
  name: "CWS Actions",
  opacity: 0.75,
  esriExport: {
    endpoints: [
      {
        url: "CWS/MapServer/",
        "z-index": 110,
        tokenKey: "Timmons Group AGS CWS",
        layerDefs: '{"0":"status=300 AND category=1","1":"0=1","2":"0=1"}'
      }
    ],
    extent: util.defaultExtent    
  }
};

/**
 * REFERENCE LAYERS
 */

 const countyBoundaries = {
   key: "lyr_county_bounds",
   name: "County Boundaries",
   opacity: 1.0,
   wmts: {
    endpoints: [util.buildWmtsLayer("countyboundaries", 200)],
    extent: util.defaultExtent
  }
 };

 const communitiesAtRisk = {
  key: "lyr_comms_at_risk",
  name: "Communities at Risk",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("2013communitiesatrisk", 200)],
   extent: util.defaultExtent
 }
};

const ffslAreas = {
  key: "lyr_ffsl_areas",
  name: "FFSL Areas",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("ffsldetailed", 200)],
   extent: util.defaultExtent
 }
};

const blmDistricts = {
  key: "lyr_blm_districts",
  name: "BLM Districts",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("blmdistricts", 200)],
   extent: util.defaultExtent
 }
};

const blmFieldOffices = {
  key: "lyr_blm_fld_offices",
  name: "BLM Field Offices",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("blmfldoffices", 200)],
   extent: util.defaultExtent
 }
};

const forestServiceBoundaries = {
  key: "lyr_forest_serv_bounds",
  name: "Forest Service Boundaries",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("forestservice", 200)],
   extent: util.defaultExtent
 }
};

const forestActionPlanPriorityAreas = {
  key: "lyr_forest_act_plan_priority_areas",
  name: "Forest Action Plan Priority Areas",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("forestactionplanpriorityarea2014", 200)],
   extent: util.defaultExtent,
   "z-index":800
 }
};

const wriProjects = {
  key: "lyr_wri_projs",
  name: "WRI Projects",
  opacity: 1.0,
  esriExport: {
    endpoints: [{
      "url": "https://dwrmapserv.utah.gov/arcgis/rest/services/WRI/WRI_Treatment_Areas/MapServer/",
      "z-index": 200
    }],
    extent: util.defaultExtent
  }
};

const congressionalDistricts = {
  key: "lyr_congress_districts",
  name: "Congressional Districts",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("congressionaldistricts", 200)],
   extent: util.defaultExtent
 }
};

const huc12Watersheds = {
  key: "lyr_huc12_watersheds",
  name: "HUC12 Watersheds",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("huc12watersheds", 200)],
   extent: util.defaultExtent
 }
};

const sageGrouseManagementAreas = {
  key: "lyr_sage_grouse_mgmt_areas",
  name: "Sage Grouse Management Areas",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("sagegrousemanagementareas", 200)],
   extent: util.defaultExtent
 }
};

const blmFuelsTreatments = {
  key: "lyr_blm_fuel_treat",
  name: "BLM Fuels Treatments",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("blmfueltreatment", 200)],
   extent: util.defaultExtent
 }
};

const municipalBoundaries = {
  key: "lyr_municip_bounds",
  name: "Municipal Boundaries",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("municipalboundaries", 200)],
   extent: util.defaultExtent
 }
};

const ffslFuelsProjects = {
  key: "lyr_ffsl_fuel_projs",
  name: "FFSL Fuels Projects",
  opacity: 1.0,
  esriExport: {
    endpoints: [
      // complete
    {
      "url": "MapServer/",
      "z-index": 200,
      "tokenKey": "Timmons Group AGS FAM",
      "layersToShow": "show:4",
    }],
    extent: util.defaultExtent
  }
};

const schools = {
  key: "lyr_schools",
  name: "Schools",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("schools", 200)],
   extent: util.defaultExtent
 }
};

const railroads = {
  key: "lyr_railroads",
  name: "Railroads",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("railroads", 200)],
   extent: util.defaultExtent
 }
};

const fireStations = {
  key: "lyr_fire_stations",
  name: "Fire Stations",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("firestations", 200)],
   extent: util.defaultExtent
 }
};

const historicFirePerimeters = {
  key: "lyr_hist_fire_perims",
  name: "Historic Fire Perimeters",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("historicfireperimeters", 200)],
   extent: util.defaultExtent
 }
};

const roadlessAreas = {
  key: "lyr_roadless_areas",
  name: "Roadless Areas",
  opacity: 1.0,
  wmts: {
   endpoints: [util.buildWmtsLayer("roadlessareas", 200)],
   extent: util.defaultExtent
 }
};

const treatmentFire = {
  key: "lyr_treat_fire",
  name: "Treatment: Fire",
  opacity: 1.0,
  esriExport: {
    endpoints: [{
      "url": "https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_HazardousFuelsTreatments_01/MapServer/",
      "z-index": 200,
      layersToShow: "exclude:0,1,4,5,6,7,9,10,11,12,13,14,15;show:3",
      layerDefs: "3:STATE_ABBR = 'UT'",
      bbox: "-12410964.89859, 4786063.28244,-12337585.3514, 4816905.6233"
    }],
    extent: util.defaultExtent    
  }
};

const treatmentAllOtherValues = {
  key: "lyr_treat_all_other",
  name: "Treatment: All Other Values",
  opacity: 1.0,  
  esriExport: {
    endpoints: [{
      "url": "https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_HazardousFuelsTreatments_01/MapServer/",
      "z-index": 200,
      layersToShow: "exclude:0,1,3,4,5,6,9,10,11,12,13,14,15;show:7",
      layerDefs: "7:STATE_ABBR = 'UT'",
      bbox: "-12410964.89859, 4786063.28244,-12337585.3514, 4816905.6233"
    }],
    extent: util.defaultExtent    
  }
};

const treatmentMechanical = {
  key: "lyr_treat_mech",
  name: "Treatment: Mechanical",
  opacity: 1.0,  
  esriExport: {
    endpoints: [{
      "url": "https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_HazardousFuelsTreatments_01/MapServer/",
      "z-index": 200,
      layersToShow: "exclude:0,1,3,4,6,7,9,10,11,12,13,14,15;show:5",
      layerDefs: "5:STATE_ABBR = 'UT'",
      bbox: "-12410964.89859, 4786063.28244,-12337585.3514, 4816905.6233"    
    }],
    extent: util.defaultExtent    
  }
};

const treatmentWildfireNonTreatment = {
  key: "lyr_treat_fire_non",
  name: "Treatment: Wildfire Non-Treatment",
  opacity: 1.0,  
  esriExport: {
    endpoints: [{
      "url": "https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_HazardousFuelsTreatments_01/MapServer/",
      "z-index": 200,
      layersToShow: "exclude:0,1,3,4,5,7,9,10,11,12,13,14,15;show:6",
      layerDefs: "6:STATE_ABBR = 'UT'",
      bbox: "-12410964.89859, 4786063.28244,-12337585.3514, 4816905.6233"    
    }],
    extent: util.defaultExtent    
  }
};

/**
 * LAYER GROUPS
 */

const wildfireGroup = {
  key: "grp_wildfire",
  name: "Wildfire",
  openness: "open",
  layers: [
    wildfireRisk.key,
    wildfireThreat.key,
    wildfireEffects.key,
    suppressionDifficulty.key
  ]
};

const valueImpactsGroup = {
  key: "grp_val_impacts",
  name: "Value Impacts",
  openness: "closed",
  layers: [
    aggregateValueImpacts.key,
    wildlandDevelopmentAreaImpacts.key,
    infrastructureImpacts.key,
    forestAssetsImpacts.key,
    riparianAssetsImpacts.key,
    drinkingWaterImpacts.key
  ]
};

const landscapeCharacteristicsGroup = {
  key: "grp_landscape_chars",
  name: "Landscape Characteristics",
  openness: "closed",
  layers: [
    surfaceFuels.key,
    percentSlope.key,
    weatherInfluenceZones.key,
    weatherStations.key,
    vegetation.key,
    wildlandDevelopmentArea.key,
    wherePeopleLive.key,
    infrastructureAssets.key,
    forestAssets.key,
    forestAssetsResponseToFire.key,
    riparianAssets.key,
    drinkingWaterAssets.key
  ]
};

const wildfireBehaviorGroup = {
  key: "grp_wildfire_behav",
  name: "Wildfire Behavior",
  openness: "closed",
  layers: [
    rateOfSpreadExpected.key,
    rateOfSpreadHigh.key,
    flameLengthExpected.key,
    flameLengthHigh.key,
    probabilityOfCanopyFire.key,
    fireTypeHigh.key,
    fireTypeExtreme.key
  ]
};

const historicalFireOccuranceGroup = {
  key: "grp_hist_fire_occ",
  name: "Historical Fire Occurrence",
  openness: "closed",
  layers: [
    fireOccurrenceDensity.key,
    locationSpecificIgnitions.key,
    ignitionsAndFireOccurrenceDensity.key,
    nfirsFireOccurrenceDensity.key
  ]
};

const boundariesGroup = {
  key: "grp_boundaries",
  name: "Boundaries",
  openness: "closed",
  layers: [
    landOwnershipOrManagement.key
  ]
};

const famGroup = {
  key: "grp_fam",
  openness: "open",
  layers: [
    famFocusAreas.key,
    famProjects.key,
    famActions.key,
    //famOutlines.key,
    //cwsActions.key
  ]
};

const basemapsGroup = {
  key: "grp_basemaps",
  openness: "open",
  layers: [
    esriLightGray.key,
    esriWorldImage.key, 
    esriStreets.key, 
    esriTopographic.key
  ]
};

const referenceGroup = {
  key: "grp_reference",
  openness: "open",
  layers: [
    countyBoundaries.key,
    communitiesAtRisk.key,
    ffslAreas.key,
    blmDistricts.key,
    blmFieldOffices.key,
    blmFuelsTreatments.key,
    forestServiceBoundaries.key,
    forestActionPlanPriorityAreas.key,
    wriProjects.key,
    congressionalDistricts.key,
    huc12Watersheds.key,
    sageGrouseManagementAreas.key,
    municipalBoundaries.key,
    ffslFuelsProjects.key,
    schools.key,
    railroads.key,
    fireStations.key,
    historicFirePerimeters.key,
    roadlessAreas.key
  ]
};

const usfsHazardousFuelsTreatmentsGroup = {
  key: "grp_usfs_haz_fuels_treat",
  name: "USFS Hazardous Fuels Treatments",
  openness: "closed",
  layers: [
    treatmentFire.key,
    treatmentAllOtherValues.key,
    treatmentMechanical.key,
    treatmentWildfireNonTreatment.key
  ]
};

/**
 * LAYER CATEGORIES
 */

const layersCategory = {
  key: "cat_layers",
  name: "Layers",
  selectiveness: "monoselective",
  mustHaveSelection: false, // can turn all off
  multiphasic: true,
  transparency: 1.0,
  openness: "open",
  usesRasterLegend: true,
  activeIcon: {
    iconClass: "fa fa-circle"
  },
  infoIcon: {
    iconClass: "fa fa-info-circle fa-lg"
  },
  defaultSelection: [ wildfireRisk.key ],
  layerGroups: [
    wildfireGroup.key,
    valueImpactsGroup.key,
    landscapeCharacteristicsGroup.key,
    wildfireBehaviorGroup.key,
    historicalFireOccuranceGroup.key,
    boundariesGroup.key
  ]
};

const famCategory = {
  key: "cat_fam",
  name: "Focus Area Manager",  
  selectiveness: "polyselective",
  mustHaveSelection: false, // can turn all off
  multiphasic: true,
  transparency: 1.0,
  openness: "open",
  usesRasterLegend: false,
  activeIcon: {
    iconClass: "fa fa-check"
  },
  infoIcon: {
    iconClass: "fa fa-info-circle fa-lg"
  },
  defaultSelection: [ famFocusAreas.key, famProjects.key, famActions.key ],
  layerGroups: [ famGroup.key ]
};

const basemapsCategory = {
  key: "cat_basemaps",
  name: "Basemaps",  
  selectiveness: "monoselective",
  mustHaveSelection: true, // must have at least one layer on at all times
  multiphasic: false,
  transparency: 1.0,
  openness: "open",
  usesRasterLegend: false,
  activeIcon: {
    iconClass: "fa fa-circle"
  },
  infoIcon: {
    iconClass: "fa fa-info-circle fa-lg"
  },
  defaultSelection: [ esriWorldImage.key ],
  layerGroups: [ basemapsGroup.key ]
};

const referenceCategory = {
  key: "cat_reference",
  name: "Reference",
  selectiveness: "polyselective",
  mustHaveSelection: false, // can turn all off
  multiphasic: true,
  transparency: 1.0,
  openness: "open",
  usesRasterLegend: false,
  activeIcon: {
    iconClass: "fa fa-check"
  },
  infoIcon: {
    iconClass: "fa fa-info-circle fa-lg"
  },
  layerGroups: [ referenceGroup.key, usfsHazardousFuelsTreatmentsGroup.key ]
};

/**
 * MAP THEMES CONFIG
 */

var mapConfig = {
  layers: [
    wildfireRisk,
    wildfireThreat,
    wildfireEffects,
    suppressionDifficulty,
    aggregateValueImpacts,
    wildlandDevelopmentAreaImpacts,
    infrastructureImpacts,
    forestAssetsImpacts,
    riparianAssetsImpacts,
    drinkingWaterImpacts,
    surfaceFuels,
    percentSlope,
    weatherInfluenceZones,
    weatherStations,
    vegetation,
    wildlandDevelopmentArea,
    wherePeopleLive,
    infrastructureAssets,
    forestAssets,
    forestAssetsResponseToFire,
    riparianAssets,
    drinkingWaterAssets,
    rateOfSpreadExpected,
    rateOfSpreadHigh,
    flameLengthExpected,
    flameLengthHigh,
    probabilityOfCanopyFire,
    fireTypeHigh,
    fireTypeExtreme,
    fireOccurrenceDensity,
    locationSpecificIgnitions,
    ignitionsAndFireOccurrenceDensity,
    nfirsFireOccurrenceDensity,
    landOwnershipOrManagement,
    esriLightGray,
    esriWorldImage, 
    esriStreets, 
    esriTopographic,
    //famOutlines,
    famActions,
    famFocusAreas,
    famProjects,
    cwsActions,
    countyBoundaries,
    communitiesAtRisk,
    ffslAreas,
    blmDistricts,
    blmFieldOffices,
    blmFuelsTreatments,
    forestServiceBoundaries,
    forestActionPlanPriorityAreas,
    wriProjects,
    congressionalDistricts,
    huc12Watersheds,
    sageGrouseManagementAreas,
    municipalBoundaries,
    ffslFuelsProjects,
    schools,
    railroads,
    fireStations,
    historicFirePerimeters,
    roadlessAreas,
    treatmentFire,
    treatmentAllOtherValues,
    treatmentMechanical,
    treatmentWildfireNonTreatment
  ],
  layerGroups: [
    wildfireGroup,
    valueImpactsGroup,
    landscapeCharacteristicsGroup,
    wildfireBehaviorGroup,
    historicalFireOccuranceGroup,
    boundariesGroup,
    famGroup,
    basemapsGroup,
    referenceGroup,
    usfsHazardousFuelsTreatmentsGroup
  ],
  layerCategories: [
    layersCategory,
    famCategory,
    basemapsCategory,
    referenceCategory
  ]
};

export default mapConfig;
